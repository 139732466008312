// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 9998; 
  } */
  /* .close-btn:hover {
    cursor: pointer;
  } */
.popup {
  position: fixed;
  top: 0;
  right: 0;
  transform: translate(100%, 0px);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  width: 500px;
  height: 100%;
  overflow-y: scroll;
  transition: .5s;
  overflow-x: hidden;
  }
  /* width */
.popup::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.popup::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent; 
  border-radius: 10px;
}
 
/* Handle */
.popup::-webkit-scrollbar-thumb {
  background: #e0e0d1; 
  border-radius: 10px;
}

/* Handle on hover */
.popup::-webkit-scrollbar-thumb:hover {
  background: #e0e0d1; 
}
  
  .popup.open {
    transition: .5s;
    transform: translate(0%, 0px);
  }

  .popup-content {
    display: flex; 
    justify-content: space-between;
    
    
  }
  
  .popup-content > div {
    margin-bottom: 10px; 
  }`, "",{"version":3,"sources":["webpack://./src/pages/common/appointment/DoctorNotification.css"],"names":[],"mappings":"AAAA;;;;;;;;KAQK;EACH;;KAEG;AACL;EACE,eAAe;EACf,MAAM;EACN,QAAQ;EACR,+BAA+B;EAC/B,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,wCAAwC;EACxC,aAAa;EACb,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB;EACA,UAAU;AACZ;EACE,UAAU;AACZ;;AAEA,UAAU;AACV;EACE,qCAAqC;EACrC,mBAAmB;AACrB;;AAEA,WAAW;AACX;EACE,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA,oBAAoB;AACpB;EACE,mBAAmB;AACrB;;EAEE;IACE,eAAe;IACf,6BAA6B;EAC/B;;EAEA;IACE,aAAa;IACb,8BAA8B;;;EAGhC;;EAEA;IACE,mBAAmB;EACrB","sourcesContent":["/* .overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.5); \n    z-index: 9998; \n  } */\n  /* .close-btn:hover {\n    cursor: pointer;\n  } */\n.popup {\n  position: fixed;\n  top: 0;\n  right: 0;\n  transform: translate(100%, 0px);\n  background-color: white;\n  padding: 20px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);\n  z-index: 9999;\n  width: 500px;\n  height: 100%;\n  overflow-y: scroll;\n  transition: .5s;\n  overflow-x: hidden;\n  }\n  /* width */\n.popup::-webkit-scrollbar {\n  width: 5px;\n}\n\n/* Track */\n.popup::-webkit-scrollbar-track {\n  box-shadow: inset 0 0 5px transparent; \n  border-radius: 10px;\n}\n \n/* Handle */\n.popup::-webkit-scrollbar-thumb {\n  background: #e0e0d1; \n  border-radius: 10px;\n}\n\n/* Handle on hover */\n.popup::-webkit-scrollbar-thumb:hover {\n  background: #e0e0d1; \n}\n  \n  .popup.open {\n    transition: .5s;\n    transform: translate(0%, 0px);\n  }\n\n  .popup-content {\n    display: flex; \n    justify-content: space-between;\n    \n    \n  }\n  \n  .popup-content > div {\n    margin-bottom: 10px; \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
