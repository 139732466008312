import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Form,
    FormGroup,
    FormText,
    Input,
    Label,

    Row,
} from "reactstrap";
import React, { useEffect, useState, useContext } from "react";
import { getFormData, getformdetail } from "../../../services/form-service";
import { useLocation, useNavigate, useSearchParams, useParams } from "react-router-dom";
import { createDoctor, getOneDoctor, updateDoctor } from "../../../services/doctor-service";

import profile from "../../../assets/images/doctor/profile.png";
import { RoleContext, AlertContext } from "../../../utils/contexts/RoleContext";
import { FolderPath, ImagePath } from "../../../services/auth.service";
import Spinner from 'react-bootstrap/Spinner';
import constants from "../../../config/constants";
import { capitalizeFirstLetter } from "../../../utils/utils";

const EditUser = () => {
    const [htmlFormUsers, sethtmlFormUsers] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [dragAllData, setDragAllData] = useState<any>([]);
    const [currentDoctor, getCurrentDoctor] = useState<any>({});
    const [dragEmpData, setDragEmpData] = useState<any>([]);
    const [personalData, setPersonalData] = useState<any>([]);
    const [getvalues, setGetvalues] = React.useState<any>({});
    const [formUsers, getFormUsers] = React.useState<any>([]);
    const navigate = useNavigate();
    const currentUser = useContext(RoleContext);
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const [getImage, setImage] = useState<any>("");
    const [roleId, setRoleId] = useState<any>("");
    const toast: any = useContext(AlertContext);
    const [progress, setProgress] = useState<any>()
    const [attachfile, setAttachedFile] = useState<any>(null)
    const [fileName, setFileName] = useState('');
    const { doctorId }: any = useParams();

    useEffect(() => {
        getFormusers();
        getEmployeeUser();

        // getPersonalUsers("Doctors Profile");
        if (doctorId != undefined) {
            getDoctor();
        }
        document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Edit User`;
        // setRoleId(searchParams.get('roleId'))
        // createOneDoctor();
    }, [currentUser, doctorId])


    const getDoctor = async () => {
        getOneDoctor(doctorId).then(
            (data) => {

                const allDoctor = data.allDoctors;
                setRoleId(allDoctor.roleId)
                // const obj1 = {
                const Basic_Information = {
                    firstName: { value: allDoctor?.firstName, id: "5", formId: "10" },
                    lastName: { value: allDoctor?.lastName, id: "5", formId: "10" },
                    email: { value: allDoctor?.email, id: "5", formId: "10" },
                    phoneNumber: { value: allDoctor?.phoneNumber, id: "5", formId: "10" },
                    profile: { value: allDoctor?.profile, id: "5", formId: "10" },
                    document: { value: allDoctor?.document, id: "5", formId: "10" },

                }
                // setAttachedFile(FolderPath + allDoctor?.document)
                // setFileName(allDoctor?.document.split('/')[3])
                // }

                setGetvalues((prevState: any) => ({
                    ...prevState, Basic_Information
                }))



                const userForm: any = {};
                // getCurrentDoctor(data.allDoctors)
                let array: any = [];
                allDoctor?.userdata?.map((item: any) => {
                    let objs: any = {}
                    objs.fieldKey = item?.fieldKey
                    objs.fieldValue = JSON.parse(item?.fieldValue)
                    objs.formId = item?.formId
                    objs.id = item?.fieldId
                    objs.label = item?.user_form_field?.label
                    objs.type = item?.user_form_field?.type
                    objs.defaultValue = item?.user_form_field?.defaultValue
                    objs.formName = item?.user_form?.formName
                    // userarray.push(objs)
                    // // obj.field = objs
                    // let obj: any = {
                    //     "formName": item?.user_form?.formName,
                    //     "user_form_fields": userarray
                    // }
                    // objarray.push(obj)
                    if (userForm[item?.user_form?.formName] === undefined) {
                        userForm[item?.user_form?.formName] = [objs]
                    } else {
                        userForm[item?.user_form?.formName] = [...userForm[item?.user_form?.formName], objs]
                    }

                    array.push(objs)
                    setGetvalues((prevState: any) => ({
                        ...prevState, [item?.user_form?.formName]: { ...prevState[item?.user_form?.formName], [item?.fieldKey]: { id: parseInt(item?.fieldId), value: JSON.parse(item?.fieldValue), formId: item?.formId } }
                    }))
                    // getCurrentDoctor(...currentDoctor,["fieldValue"]:JSON.parse(item.fieldValue))
                    // obj[item.fieldKey]=JSON.parse(item.fieldValue)

                })

                getFormUsers(array)
                // setPersonalData(array)
                //   setPersonalData(array)
                let userformarray: any = []
                let formarray: any = []
                allDoctor?.user_form_data?.map((item: any) => {
                    let obj: any = {}
                    // obj[item.formFieldKey] = item.formFieldValue
                    obj.fieldKey = item?.formFieldKey
                    obj.fieldValue = item?.formFieldValue
                    obj.formId = item?.formId
                    obj.id = item?.fieldId
                    obj.label = item?.user_form_field?.label
                    obj.type = item?.user_form_field?.type
                    obj.defaultValue = item?.user_form_field?.defaultValue
                    obj.formName = item?.user_form?.formName
                    userformarray.push(obj)
                    let objs: any = {
                        "formName": item?.user_form?.formName,
                        "user_form_fields": userformarray
                    }
                    formarray.push(objs)


                    // const groupedData:any = {};
                    // formarray.forEach((items:any) => {
                    //     items.user_form_fields.forEach((itemarray:any)=>{
                    //         const ageGroup = itemarray.formName;
                    //         if (!groupedData[ageGroup]) {
                    //           groupedData[ageGroup] = [];
                    //         }
                    //         groupedData[ageGroup].push(itemarray);
                    //       });
                    //     })



                    // // obj.field = objs
                    // const groupa:any=[]
                    // Object.keys(groupedData)?.map((items:any)=>{
                    //     if(items==item.formId){
                    //         let obj={
                    //             "userfield":Object.values(groupedData),
                    //             "formname":item?.user_form?.formName
                    //         }
                    //         groupa.push(obj)
                    //     }
                    // })
                    // objarray.push(objs)
                    if (userForm[item?.user_form?.formName] === undefined) {
                        userForm[item?.user_form?.formName] = [obj]
                    } else {
                        userForm[item?.user_form?.formName] = [...userForm[item?.user_form?.formName], obj]
                    }
                    if (item?.formFieldKey === "documentUploads") {
                        setAttachedFile(FolderPath + item?.formFieldValue)
                        setFileName(item?.formFieldValue?.split('/')[4])
                    }



                    // obj.field = objs
                    array.push(obj)
                    setGetvalues((prevState: any) => ({
                        ...prevState, [item?.user_form?.formName]: { ...prevState[item?.user_form?.formName], [item?.formFieldKey]: { id: item?.fieldId, value: item?.formFieldValue, formId: item?.formId } }
                    }))
                })
                // const uniqueUserData = objarray.filter((user: any, index: any, self: any) =>
                //     index === self.findIndex((t: any) => (
                //         t.formName === user.formName // Assuming 'id' is a unique identifier
                //     ))
                // );

                getFormUsers(array)
                // Object.keys(userForm)?.map((item: any) => {
                //     Object.values(userForm)?.map((items:any)=>{
                //         items
                //         let obj: any = {
                //             "formname": item,
                //             "formvalues":Object.values(userForm)

                //         }
                //     })

                // })
                const jarray: any = []
                for (let i in userForm) {
                    let obj: any = {
                        "formName": i,
                        "user_form_fields": userForm[i]
                    }
                    jarray.push(obj)
                }
                // sethtmlFormUsers(userForm)
                // sethtmlFormUsers(jarray)

                getCurrentDoctor(allDoctor);
                    let roleProfile: string;
                    switch (allDoctor.roleId) {
                        case 3:
                            roleProfile = 'Doctors Profile';
                            break;
                        case 5:
                            roleProfile = "Patients Profile";
                            break;
                        case 4:
                            roleProfile = "Staffs Profile";
                            break;
                        case 2:
                            roleProfile = "Hospitals Profile";
                            break;
                        default:
                            roleProfile = ""
                            break;
                    }
                    getPersonalUsers(roleProfile);

                // if (jarray?.length > 0) {
                //     sethtmlFormUsers(jarray)
                // }
                // else {
                //     let roleProfile: string;
                //     switch (allDoctor.roleId) {
                //         case 3:
                //             roleProfile = 'Doctors Profile';
                //             break;
                //         case 5:
                //             roleProfile = "Patients Profile";
                //             break;
                //         case 4:
                //             roleProfile = "Staffs Profile";
                //             break;
                //         case 2:
                //             roleProfile = "Hospitals Profile";
                //             break;
                //         default:
                //             roleProfile = ""
                //             break;
                //     }
                //     getPersonalUsers(roleProfile);
                // }



            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setLoading(false);
                setMessage(resMessage);
            }
        );


    };

    let user: string;
    switch (roleId) {
        case 3:
            user = 'Doctor';
            break;

        case 5:
            user = "Patient";
            break;

        case 4:
            user = "Staff";
            break;
        case 2:
            user = "Hospital";
            break;

        case "unknown":
            user = `User for ${searchParams.get('hospital')}`;
            break;

        default:
            user = `User for ${searchParams.get('hospital')}`;
    }

    const getPersonalUsers = async (Profile: string | null) => {
        getFormData(Profile).then(
            (data) => {
                console.log('hiiii', data.result)
                sethtmlFormUsers(data.result)
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setLoading(false);
                setMessage(resMessage);
            }
        );


    };


    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = attachfile;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleDeleteDocument = () => {
        setAttachedFile(null);
        setFileName("")
    };

    const FormOnChange = (event: any) => {
        const { name, value, id } = event.target;
        const formId = event?.target?.id?.split("/")[1]
        const fieldId = event?.target?.id?.split("/")[0]
        const formName = event?.target?.id?.split("/")[2]
        // const name = event.target.name;
        // const value = event.target.value;
        // const obj = {
        //   "field_id": event.target.id,
        //   "field_key": event.target.name,
        //   "field_value": event.target.value
        // };
        // setGetvalues({ ...getvalues, [name]: value, "field_id": event.target.id });
        setGetvalues((prevState: any) => ({
            ...prevState, [formName]: { ...prevState[formName], [name]: { id: fieldId, value: value, formId: formId } }
        }))
    }



    // const getPersonalUsers = async () => {

    //     getFormData("Personal_Information").then(
    //         (data) => {
    //             const checkvalue = data.formData;

    //             // sethtmlFormUsers(data)
    //             formdisplay2(data.formId);


    //         },
    //         (error) => {
    //             const resMessage =
    //                 (error.response &&
    //                     error.response.data &&
    //                     error.response.data.message) ||
    //                 error.message ||
    //                 error.toString();

    //             setLoading(false);
    //             setMessage(resMessage);
    //         }
    //     );


    // };

    const formdisplay2 = async (id: number) => {

        getformdetail(id).then(
            (data) => {
                setPersonalData(data)
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setLoading(false);
                setMessage(resMessage);
            }
        );


    };
    const getEmployeeUser = async () => {

        getFormData("Employment_Information").then(
            (data) => {
                const checkvalue = data.formData;

                // sethtmlFormUsers(data)
                formdisplay1(data.formId);


            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setLoading(false);
                setMessage(resMessage);
            }
        );


    };


    const formdisplay1 = async (id: number) => {

        getformdetail(id).then(
            (data) => {
                setDragEmpData(data)


            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setLoading(false);
                setMessage(resMessage);
            }
        );


    };

    const getFormusers = async () => {

        getFormData("doctor_Professional _Information").then(
            (data) => {
                const checkvalue = data.formData;

                // sethtmlFormUsers(data)
                formdisplay(data.formId);


            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setLoading(false);
                setMessage(resMessage);
            }
        );


    };

    const formdisplay = async (id: number) => {

        getformdetail(id).then(
            (data) => {
                setDragAllData(data)


            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setLoading(false);
                setMessage(resMessage);
            }
        );


    };


    const createOneDoctor = async () => {
        setLoading(true);
        if (getvalues['Basic_Information']?.firstName == undefined) {
            alert("please fill the firstname")
        } else if (getvalues['Basic_Information']?.lastName == undefined) {
            alert("please fill the lastname")
        } else if (getvalues['Basic_Information']?.email) {
            if (!validateEmail(getvalues['Basic_Information']?.email?.value)) {
                alert("please fill the email")
            }
        } else if (getvalues['Basic_Information']?.phoneNumber) {
            if (!validatePhone(getvalues['Basic_Information']?.phoneNumber?.value)) {
                alert("please fill the phonenumber")
            }

        }
        const formValues = { ...getvalues }
        for (let key in formValues) {
            for (let i in formValues[key]) {
                formValues[key][i].roleId = roleId
            }
        }


        updateDoctor(formValues, doctorId).then(
            (data) => {
                setLoading(false);
                if (data.message) {
                    // let route;
                    // switch (searchParams.get('profile')) {
                    //     case "Doctors Profile":
                    //         route = `/${currentUser}/doctordetaillist`;
                    //         break;

                    //     case "Patients Profile":
                    //         route = `/${currentUser}/patient-list`;
                    //         break;

                    //     case "Staffs Profile":
                    //         route = `/${currentUser}/stafflist`;
                    //         break;

                    //     case "unknown":
                    //         route = `/${currentUser}/getprofile`;
                    //         break;

                    //     default:
                    //         route = `/${currentUser}/doctordetaillist`;
                    // }
                    navigate(-1);
                    setTimeout(() => {
                        // navigate(route);
                        toast.setShowToast(data.message, "success");
                    }, 1000);
                }
                // const checkvalue = data.formData;

                // sethtmlFormUsers(data)
                // formdisplay2(data.formId);
                // navigate(`/${currentUser}/doctordetailist`)



            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setLoading(false);
                setMessage(resMessage);
                setTimeout(() => {
                    // navigate(route);
                    toast.setShowToast(resMessage, 'danger');
                }, 1000);
            }
        );


    };





    const validateEmail = (email: string) => {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(email)
    };

    const validatePhone = (phone: string) => {
        var phoneNum = phone.replace(/[^\d]/g, '');
        if (phoneNum.length > 6 && phoneNum.length < 11) {
            return true;
        }
        return false
    };

    const handleImageUpload = (post: any) => {
        let images: any = [];
        if (post.target.files && post.target.files.length > 0) {
            for (let i = 0; i < post.target.files.length; i++) {
                // setImage(post.target.files[i].name)
                let file = post.target.files[i];
                let reader = new FileReader();
                if (file) {
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        images.push({ source: reader.result });
                        // setImages([...images]);
                        // setGetvalues({ ...getvalues, ['profile']: images });
                        setImage(reader.result)
                        const profile = { value: images }
                        setGetvalues((prevState: any) => ({
                            ...prevState, ['Basic_Information']: { ...prevState['Basic_Information'], profile }
                        }))
                    };
                }
            }
        }
    };

    const handleFileUpload = (post: any, id: any, formid: any, key: any) => {
        const formName = key
        const formId = formid
        const fieldId = id
        if (post.target.files && post.target.files.length > 0) {
            for (let i = 0; i < post.target.files.length; i++) {
                let file = post.target.files[i];
                let reader = new FileReader();
                setFileName(file.name);
                if (file) {
                    const maxSize = 5 * 1024 * 1024;
                    if (file.size > maxSize) {
                        alert('Can not upload this file. Max Size is 5MB');
                        post.target.value = '';
                    } else {
                        // setAttachedFile(file);
                        const fileSize = file.size;
                        const chunkSize = 1024 * 1024; // 1MB chunk size (you can adjust this as needed)
                        const chunks = Math.ceil(fileSize / chunkSize);
                        let uploadedChunks = 0;
                        for (let i = 0; i < chunks; i++) {
                            const start = i * chunkSize;
                            const end = Math.min(fileSize, start + chunkSize);
                            // const chunk = file.slice(start, end);

                            // const formData = new FormData();
                            // formData.append('chunk', chunk);

                            uploadedChunks++;
                            //  const percentage= Math.floor((uploadedChunks * 100) / chunks)
                            const percentage = Math.min(100, Math.round((uploadedChunks / chunks) * 100));
                            setProgress(percentage)

                        }


                        reader.readAsDataURL(file);
                        reader.onload = () => {
                            setAttachedFile(reader.result);
                            // setAttachedFile(reader.result);

                            const result = { value: reader.result, fileName: file.name.split('.')[1] };
                            // setGetvalues((prevState: any) => ({
                            //     ...prevState, ['Basic_Information']: { ...prevState['Basic_Information'], result }
                            // }))
                            setGetvalues((prevState: any) => ({
                                ...prevState, [formName]: { ...prevState[formName], [post.target.name]: { id: fieldId, value: result, formId: formId } }
                            }))
                        };
                    }
                }
            }
        }
    };


    return (
        <Row>
            <div className="panel_top_section">
                <h1>Edit {user}</h1>
            </div>
            <Col className="mt-3 mb-4">
                <div className="add_doctor_form card_box col-sm-12 col-xl-9 mx-auto py-4 px-lg-4 mt-4">
                    <div className="add_doctor_fom px-4">
                        <div className="row justify-content-around border-bottom mb-4 pb-4">
                            <div className="col-lg-12 col-xl-4 d-flex align-items-center">
                                <p className=" text-black-50 fw-medium">Profile</p>
                            </div>
                            <div className="col-lg-12 col-xl-8">
                                <div className="profile_image">
                                    <div className="col-2 col-sm-2 col-lg-2 choose_file d-inline-block">
                                        {/* <input type="file"> */}
                                        {getImage != "" ?
                                            <span id="profile-image-upload"> <img className="clickable rounded-circle" src={getImage} alt="profile" /></span>
                                            :
                                            currentDoctor?.profile != 0 ?
                                                <span id="profile-image-upload"> <img className="clickable rounded-circle" src={`${ImagePath}` + 'profile/thumb/small_' + currentDoctor?.profile} alt="profile" /></span>
                                                :

                                                <span id="profile-image-upload"> <img className="clickable rounded-circle" src={profile} alt="profile" /></span>
                                        }

                                    </div>
                                    <input type="file" id="profile" name="profile" accept="image/*" hidden onChange={(e) => {
                                        handleImageUpload(e);
                                    }}></input>
                                    <label htmlFor="profile" className="d-inline-block m-0  px-2 px-xl-4 clickable" >Upload Profile Photo</label>
                                </div>
                            </div>
                        </div>

                        {/* personal-inoformation */}
                        <div className="row justify-content-around border-bottom mb-4 pb-4">
                            <div className="col-lg-12 col-xl-4">
                                <p className="text-black-50 fw-medium">Personal Information</p>
                            </div>
                            <div className="row  col-lg-12 col-xl-8">
                                <div className="col-lg-12 col-xl-6 mb-3">
                                    <label htmlFor="firsname" className="form-label">First Name<span className="required-color">*</span></label>
                                    <input type="text" className="form-control" id="1/10/Basic_Information" onChange={FormOnChange} name="firstName" defaultValue={currentDoctor?.firstName} required />
                                </div>
                                <div className="col-lg-12 col-xl-6 mb-3">
                                    <label htmlFor="Name" className="form-label">Last Name<span className="required-color">*</span></label>
                                    <input type="text" className="form-control" id="2/10/Basic_Information" onChange={FormOnChange} name="lastName" defaultValue={currentDoctor?.lastName} required />
                                </div>
                                {roleId != 5 &&
                                    <>
                                        <div className="col-lg-12 col-xl-6 mb-3">
                                            <label htmlFor="phone" className="form-label">Phone<span className="required-color">*</span></label>
                                            <input type="text" className="form-control" id="3/10/Basic_Information" onChange={FormOnChange} name="phoneNumber" defaultValue={currentDoctor?.phoneNumber} required />
                                        </div>
                                        <div className="col-lg-12 col-xl-6 mb-3">
                                            <label htmlFor="email" className="form-label">Email<span className="required-color">*</span></label>
                                            <input type="text" className="form-control" id="4/10/Basic_Information" onChange={FormOnChange} name="email" defaultValue={currentDoctor?.email} required />
                                        </div>
                                    </>
                                }
                                {htmlFormUsers?.map((keys: any) => {
                                    if (keys.formName === "Personal Information") {
                                        return (
                                            <>
                                                {
                                                    keys?.user_form_fields.map((item: any) => {
                                                        return (
                                                            <>
                                                                {item.type === "select" ?
                                                                    <div className="col-lg-12 col-xl-6 mb-3">
                                                                        <label htmlFor="phone" className="form-label">{item.label}</label>

                                                                        <select
                                                                            className="form-select"
                                                                            aria-label="Default select example"
                                                                            name={item.fieldKey}
                                                                            id={`${item.id + "/" + item.formId + "/" + keys.formName}`}
                                                                            onChange={e => FormOnChange(e)}
                                                                            value={getvalues[keys.formName]? getvalues[keys.formName][item.fieldKey]?.value: ""}
                                                                        >
                                                                        <option value="-1">Select...</option>
                                                                            {JSON.parse(item.defaultValue)?.map((item: any) => {
                                                                                return (
                                                                                    <option value={item.label} >{item.label}</option>
                                                                                )
                                                                            })}
                                                                        </select>


                                                                    </div> : null}
                                                                {item.type == "textfield" ?
                                                                    <div className="col-lg-12 col-xl-6 mb-3">
                                                                        <label htmlFor="license" className="form-label">{item.label}</label>
                                                                        <input
                                                                            type={item?.type}
                                                                            className="form-control"
                                                                            id={`${item.id + "/" + item.formId + "/" + keys.formName}`}
                                                                            onChange={FormOnChange} name={item.fieldKey} defaultValue={item.fieldValue}
                                                                            value={getvalues[keys.formName]? getvalues[keys.formName][item.fieldKey]?.value: ""}
                                                                        />
                                                                    </div> : null}

                                                                {item.type === "datetime" ?
                                                                    <div className="col-lg-12 col-xl-6 mb-3">
                                                                        <label htmlFor="phone" className="form-label">{item.label}</label>
                                                                        <input
                                                                            type="date"
                                                                            className="form-control"
                                                                            id={`${item.id + "/" + item.formId + "/" + keys.formName}`}
                                                                            onChange={FormOnChange}
                                                                            name={item.fieldKey}
                                                                            value={getvalues[keys.formName]? getvalues[keys.formName][item.fieldKey]?.value: ""}
                                                                        />
                                                                    </div> : null}

                                                                {item.type === "textarea" ?
                                                                    <div className="col-sm-12 mb-3 textarea">
                                                                        <label htmlFor="address" className="form-label">{item.label}</label>
                                                                        <textarea
                                                                            className="textarea_input form-control"
                                                                            id={`${item.id + "/" + item.formId + "/" + keys.formName}`}
                                                                            onChange={FormOnChange}
                                                                            name={item.fieldKey}
                                                                            value={getvalues[keys.formName]? getvalues[keys.formName][item.fieldKey]?.value: ""}
                                                                        ></textarea>
                                                                    </div> : null}
                                                            </>
                                                        )
                                                    })
                                                }
                                            </>
                                        )
                                    }
                                })}
                            </div>
                        </div>

                        {roleId == 5 &&
                            <div className="row justify-content-around border-bottom mb-4 pb-4">
                                <div className="col-lg-12 col-xl-4">
                                    <p className="text-black-50 fw-medium">Contact Information</p>
                                </div>
                                <div className="row  col-lg-12 col-xl-8">

                                    <div className="col-lg-12 col-xl-6 mb-3">
                                        <label htmlFor="phone" className="form-label">Phone<span className="required-color">*</span></label>
                                        <input type="text" className="form-control" id="3/10/Basic_Information" onChange={FormOnChange} name="phoneNumber" defaultValue={currentDoctor?.phoneNumber} />
                                    </div>
                                    <div className="col-lg-12 col-xl-6 mb-3">
                                        <label htmlFor="email" className="form-label">Email<span className="required-color">*</span></label>
                                        <input type="text" className="form-control" id="4/10/Basic_Information" onChange={FormOnChange} name="email" defaultValue={currentDoctor?.email} />
                                    </div>

                                </div>
                            </div>
                        }

                        <div className="row justify-content-around ">
                            {htmlFormUsers?.map((keys: any) => {
                                if (keys.formName !== "Personal Information" && keys.formName !== "undefined") {
                                    return (
                                        <div className="row justify-content-around border-bottom mb-4 pb-4">
                                            <div className="col-lg-12 col-xl-4">
                                                <p className="text-black-50 fw-medium">{keys.formName}</p>
                                            </div>
                                            <div className="row col-lg-12 col-xl-8">
                                                {keys.user_form_fields.map((item: any) => {
                                                    return (
                                                        <>
                                                            {item.type === "select" ?
                                                                <div className="col-lg-12 col-xl-6 mb-3">
                                                                    <label htmlFor="phone" className="form-label">{item.label}</label>
                                                                    <select
                                                                        className="form-select"
                                                                        aria-label="Default select example"
                                                                        name={item.fieldKey}
                                                                        id={`${item.id + "/" + item.formId + "/" + keys.formName}`}
                                                                        onChange={e => FormOnChange(e)}
                                                                        value={getvalues[keys.formName]? getvalues[keys.formName][item.fieldKey]?.value: ""}
                                                                    >
                                                                    <option value="-1">Select...</option>
                                                                        {JSON.parse(item.defaultValue)?.map((item: any) => {
                                                                            return (
                                                                                <option value={item.label}>{item.label}</option>
                                                                            )
                                                                        })}

                                                                    </select>


                                                                </div> : null}
                                                            {item.type == "textfield" ?
                                                                <div className="col-lg-12 col-xl-6 mb-3">
                                                                    <label htmlFor="license" className="form-label">{item.label}</label>
                                                                    <input
                                                                        type={item?.type}
                                                                        className="form-control"
                                                                        id={`${item.id + "/" + item.formId + "/" + keys.formName}`}
                                                                        onChange={FormOnChange}
                                                                        name={item.fieldKey}
                                                                        value={getvalues[keys.formName]? getvalues[keys.formName][item.fieldKey]?.value: ""}
                                                                    />
                                                                </div> : null}

                                                            {item.type === "datetime" ?
                                                                <div className="col-lg-12 col-xl-6 mb-3">
                                                                    <label htmlFor="phone" className="form-label">{item.label}</label>
                                                                    <input
                                                                        type="date"
                                                                        className="form-control"
                                                                        id={`${item.id + "/" + item.formId + "/" + keys.formName}`}
                                                                        onChange={FormOnChange}
                                                                        name={item.fieldKey}
                                                                        value={getvalues[keys.formName]? getvalues[keys.formName][item.fieldKey]?.value: ""}
                                                                    />
                                                                </div> : null}

                                                            {item.type === "textarea" ?
                                                                <div className="col-sm-12 mb-3 textarea">
                                                                    <label htmlFor="address" className="form-label">{item.label}</label>
                                                                    <textarea
                                                                        className="textarea_input form-control"
                                                                        id={`${item.id + "/" + item.formId + "/" + keys.formName}`}
                                                                        onChange={FormOnChange}
                                                                        name={item.fieldKey}
                                                                        value={getvalues[keys.formName]? getvalues[keys.formName][item.fieldKey]?.value: ""}
                                                                    >
                                                                    </textarea>
                                                                </div> : null}
                                                            {item.type === "file" ?
                                                                // <div className="row justify-content-around border-bottom mb-4 pb-4">
                                                                //     <div className="col-lg-12 col-xl-4">
                                                                //         <p className=" text-black-50 fw-medium">{item.label}</p>
                                                                //     </div>
                                                                //     <div className="row col-lg-12 col-xl-8">
                                                                //         <div className="col-sm-12">
                                                                //             <input type="file" id="upload" name={item.fieldKey} hidden onChange={(e) => {
                                                                //                 handleFileUpload(e, item.id, item.formId, keys);
                                                                //             }} accept=".csv, .pdf, .doc, .docx, .xlsx, .xls, text/plain, .jpeg" />
                                                                //             <label className="upload_btn" htmlFor="upload"><span className="up_arrow"><i className="bi bi-cloud-arrow-up-fill"></i></span>Upload</label>
                                                                //         </div>
                                                                //     </div>
                                                                // </div>
                                                                <div className="row justify-content-around border-bottom mb-4 pb-4">
                                                                    <div className="col-lg-12 col-xl-4">
                                                                        <p className=" text-black-50 fw-medium">{item.label}</p>
                                                                    </div>
                                                                    <div className=" col-lg-12 col-xl-8">
                                                                        <div className="col-xl-12 mb-4">
                                                                            <input type={item.type} id="upload" hidden name={item.fieldKey} onChange={(e) => {
                                                                                handleFileUpload(e, item.id, item.formId, keys.formName);
                                                                            }} accept=".csv, .pdf, .doc, .docx, .xlsx, .xls, text/plain" />
                                                                            <label className="upload_btn" htmlFor="upload"><span className="up_arrow"><i className="bi bi-cloud-arrow-up-fill clickable"></i></span>Upload</label>
                                                                        </div>
                                                                        <div className="col-xl-12">
                                                                            {attachfile != null &&

                                                                                <div className="border w-100 rounded float-start p-0 d-flex align-items-center">
                                                                                    <span className="fs-4 d-inline-block py-2 px-3  border-end"><i className="bi bi-file-earmark-text-fill"></i></span>
                                                                                    <span className="px-2 text_gray py-2 px-3 text-truncate ">{fileName}</span>
                                                                                    <button className=" px-3 fs-4 py-2 border-0 bg-transparent border-start" onClick={() => handleDownload()}><i className="bi bi-box-arrow-down"></i></button>
                                                                                    <button className=" px-3 fs-4 py-2 border-0 bg-transparent border-start" onClick={() => handleDeleteDocument()}><i className="bi bi-x-circle"></i></button>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                : null}
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                        {/* Professional Information*/}
                        {/* <div className="row justify-content-around border-bottom mb-4 pb-4">
                            <div className="col-lg-12 col-xl-4">
                                <p className="text-black-50 fw-medium">Professional Information</p>
                            </div>

                            <div className="row  col-lg-12 col-xl-8">
                                {dragAllData?.map((items: any) => {
                                    return (
                                        <>
                                            {items.type == "textfield" ?
                                                <div className="col-lg-12 col-xl-6 mb-3">
                                                    <label htmlFor="license" className="form-label">{items.label}</label>
                                                    <input type={items?.type} className="form-control" id={`${items.id + "/" + items.formId}`} onChange={FormOnChange} name={items.fieldKey} />
                                                </div> : null}
                                            {items.type === "select" ?
                                                <div className="col-lg-12 col-xl-6 mb-3">
                                                    <label htmlFor="phone" className="form-label" >{items.label}</label>

                                                    <select className="form-select" aria-label="Default select example" name={items.fieldKey} id={`${items.id + "/" + items.formId}`} onChange={e => FormOnChange(e)}>
                                                        {JSON.parse(items.defaultValue)?.map((item: any) => {
                                                            return (
                                                                <option value={item.value}
                                                                    selected>{item.label}</option>
                                                            )
                                                        })}

                                                    </select>


                                                </div> : null}
                                           
                                            {items.type === "textarea" ?
                                                <div className="col-sm-12 mb-3 textarea">
                                                    <label htmlFor="biography" className="form-label">{items.label}</label>
                                                    <textarea className="textarea_input form-control" id={`${items.id + "/" + items.formId}`} onChange={FormOnChange} name={items.fieldKey}></textarea>
                                                </div> : null}
                                        </>
                                    )
                                })}
                            </div>


                        </div> */}
                        {/* Employment Information*/}
                        {/* <div className="row justify-content-around border-bottom mb-4 pb-4">
                            <div className="col-lg-12 col-xl-4">
                                <p className=" text-black-50 fw-medium">Employment Information</p>
                            </div>
                            <div className="row col-lg-12 col-xl-8">
                                {dragEmpData?.map((items: any) => {
                                    return (

                                        <>
                                         
                                            {items.type === "select" ?
                                                <div className="col-lg-12 col-xl-6 mb-3">
                                                    <label htmlFor="phone" className="form-label">{items.label}</label>

                                                    <select className="form-select" aria-label="Default select example" name={items.fieldKey} id={`${items.id + "/" + items.formId}`} onChange={e => FormOnChange(e)}>
                                                        {JSON.parse(items.defaultValue)?.map((item: any) => {
                                                            return (
                                                                <option value={item.value} selected>{item.label}</option>
                                                            )
                                                        })}
                                                     
                                                    </select>


                                                </div> : null}
                                            {items.type === "datetime" ?
                                                <div className="col-lg-12 col-xl-6 mb-3">
                                                    <label htmlFor="phone" className="form-label">{items.label}</label>
                                                    <input type="date" className="form-control" id={`${items.id + "/" + items.formId}`} name={items.fieldKey} onChange={FormOnChange} />
                                                </div> : null}
                                          
                                        </>
                                    )
                                })}
                            </div>

                        </div> */}
                        {/* </>
                )
              })} */}
                        {/* Document Uploads*/}
                        {/* <>
                            {Object.keys(htmlFormUsers)?.map((keys: any) => {

                                return (


                                    htmlFormUsers[keys].map((item: any) => {
                                        return (
                                            <>

                                                {item.type == "type" ?
                                                    <div className="row justify-content-around border-bottom mb-4 pb-4">
                                                        <div className="col-lg-12 col-xl-4">
                                                            <p className=" text-black-50 fw-medium">{item.label}</p>
                                                        </div>
                                                        <div className="row col-lg-12 col-xl-8">
                                                            <div className="col-sm-12">
                                                                <input type="file" id="upload" name={item.fieldKey} hidden onChange={(e) => {
                                                                    handleFileUpload(e, item.id, item.formId, keys);
                                                                }} accept=".csv, .pdf, .doc, .docx, .xlsx, .xls, text/plain, .jpeg" />
                                                                <label className="upload_btn" htmlFor="upload"><span className="up_arrow"><i className="bi bi-cloud-arrow-up-fill"></i></span>Upload</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    : null}


                                            </>
                                        )
                                    })
                                )



                            })}
                        </> */}

                        {/* canceland-save*/}
                        <div className="row  mb-4 pb-4">
                            <div className="col-lg-12 col-xl-4">

                            </div>
                            <div className="form_bottom_btn d-flex justify-content-end">
                                <button className="delete_btn" type="button" onClick={() => navigate(-1)}>Cancel</button>
                                <button className="btn_gradian" type="button" onClick={() => createOneDoctor()}>
                                    Save
                                    <span className="ms-2">
                                        {loading &&
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        }
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default EditUser;
