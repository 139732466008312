
import profile from "../../../assets/images/doctor/profile.png";
import {
    Card,
    Row,
    Col,
    CardTitle,
    CardBody,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
} from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import SignaturePad from "react-signature-canvas";
import SignatureCanvas from "react-signature-canvas";
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getOneDoctor, } from "../../../services/doctor-service";
import { createprescription, getonprescription } from "../../../services/prescription-service";
import { RoleContext, AlertContext } from "../../../utils/contexts/RoleContext";
import { isArrayTypeNode } from "typescript";
import Modal from 'react-bootstrap/Modal';
import constants from "../../../config/constants";
import { capitalizeFirstLetter, getDateOnly } from "../../../utils/utils";
import { getValue } from "@testing-library/user-event/dist/utils";
import { SOCKET_DOMAIN } from "../../../config/constants";

const AddPrescription = () => {

    const [dataURL, setDataURL] = React.useState<string | null>(null);
    const [searchParams] = useSearchParams();
    const [currentDoctor, getCurrentDoctor] = useState<any>({});
    const [getvalues, setGetvalues] = React.useState<any>({});
    const [medicationValue, setMedicationValue] = useState<any>("");
    const [strengthValue, setStrengthValue] = useState<any>("");
    const [frequencyValue, setFrequencyValue] = useState<any>("");
    const [defaultMedicationValue, setDefaultMedicationValue] = useState<any>([]);
    const location = useLocation();
    const [show, setShow] = useState(false);
    const [deleteId, setDeleteId] = useState<string>("")
    const [allowBackNavigation, setallowBackNavigation] = useState(false);
    const [signedDate, setSignedDate] = useState('');

    const toast: any = useContext(AlertContext);
    const navigate = useNavigate();
    const [inputCount, setInputCount] = useState<any>(1);
    const [inputs, setInputs] = useState<any[]>([{ medication: '', strength: '', frequency: '' }]);
    let padRef = React.useRef<SignatureCanvas>(null);
    const currentUser = useContext(RoleContext);

    const clear = () => {
        padRef.current?.clear();
        setDataURL(null)
    };

    const disableBackButton = () => {
        // window.history.forward();
        console.log("checkingdisabled");
        handleShow()

    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //   let allowBackNavigation = false;

    useEffect(() => {
        // console.log("dyerher", searchParams.get('key'),location?.state)

        if (location?.state?.key != undefined) {
            getDoctor();
        }
        if (location?.state?.prescriptionId) {
            getOnePrescription();
        }
        const handleBackButton = (event: any) => {
            // Prevent the default behavior of the back button
            console.log("checkingsss")
            event.preventDefault();
            event.returnValue = '';
            handleShow();
            // Optionally, you can add custom logic here
        };
        // Add event listener for the popstate event (triggered when the back button is clicked)
        // window.addEventListener('popstate', handleBackButton);

        //   allowBackNavigation = false;
        setallowBackNavigation(false);

        //   return () => {
        //     allowBackNavigation = true;
        //   };
        //   return () => {
        //     window.removeEventListener('popstate', handleBackButton);
        //   };
        // disableBackButton();

        // window.onload = disableBackButton;

        // window.onpageshow = function(evt: PageTransitionEvent) {
        //   if (evt.persisted) {
        //     disableBackButton();
        //   }
        // };

        // // Cleanup function to reset the window.onload event handler
        // return () => {
        //     window.onpopstate = () => {
        //         if (allowBackNavigation === false) {
        //             console.log("returnchecks")
        //             // If back navigation is not allowed, prevent it by resetting the state
        //             window.history.forward(); // Forward to the current page, effectively disabling back navigation
        //             handleShow();
        //         }
        //     };
        // };

        // Add the event listener only once
        // document.addEventListener('click', handleClick);
        document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Add Prescription`;

    }, [])

    window.onpopstate = () => {
        if (allowBackNavigation === false) {
            // If back navigation is not allowed, prevent it by resetting the state
            window.history.forward(); // Forward to the current page, effectively disabling back navigation
            handleShow();
        }
    };

    const handleAddButtonClick = () => {
        // Add a new set of input fields
        setInputs([...inputs, { medication: '', strength: '', frequency: '' }]);
        setInputCount(inputCount + 1);
    };
    const handleSubmit = (indexs: any) => {
        const filteredItems = inputs.filter((_, index) => index !== indexs)
        setInputs(filteredItems);
        setGetvalues({ Prescription: filteredItems })
        // setGetvalues((prevElements:any)=>prevElements?.Prescription.filter((_:any, index:any) => index !== indexs));
        setInputCount(inputCount - 1);
    };

    const handleInputChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const Prescription = [...inputs];
        Prescription[index][name] = value;
        setInputs(Prescription);
        setGetvalues({ ...getvalues, Prescription })
    };


    const draftCreateOnePrescription = async () => {
        setallowBackNavigation(true)
        const formValues = { ...getvalues }
        formValues.status = 'draft'
        formValues.userId = location?.state?.key
        formValues.appointmentId = location?.state?.appointmentId
        formValues.doctorId = location?.state?.doctorId
        // Object.keys(formValues).length > 0
        //     ?
        if (formValues && Object.keys(getvalues).length > 0) {



            createprescription(formValues).then(
                (data) => {


                    // console.log("dtueiruerer", data);
                    // if (data.message) {

                    navigate(-1);
                    setTimeout(() => {

                        toast.setShowToast(data.message, "success");
                    }, 1000);

                    // }
                },
                (error) => {

                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();


                    // navigate(-1);
                    // setTimeout(() => {
                    //     // navigate(route);
                    //     toast.setShowToast("added successfully", "success");
                    // }, 1000);
                }
            )
        }
        else {
            navigate(-1);
        }




    };

    const createOnePrescription = async () => {
        setallowBackNavigation(true)
        const formValues = { ...getvalues }
        formValues.status = 'none';
        formValues.userId = location?.state?.key;
        formValues.appointmentId = location?.state?.appointmentId;
        formValues.doctorId = location?.state?.doctorId

        createprescription(formValues).then(
            (data) => {
                navigate(-1);
                setTimeout(() => {

                    toast.setShowToast(data.message, "success");
                }, 1000);
            },
            (error) => {

                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();


                // navigate(-1);
                // setTimeout(() => {
                //     // navigate(route);
                //     toast.setShowToast("added successfully", "success");
                // }, 1000);
            }
        );



    };

    async function getOnePrescription() {
        const response = await getonprescription(location?.state?.prescriptionId);
        if (response?.success) {
            const prescriptions = response?.allPrescription[0];
            console.log("prescriptions", prescriptions)
            // const url = `${SOCKET_DOMAIN}/prescription/${prescriptions?.signature}`
            const arraystore: any = [];

            prescriptions?.medications?.map((medication: any) => {
                let obj = {
                    medication: medication?.medication,
                    strength: medication?.strength,
                    frequency: medication?.frequency,
                    id: medication?.id
                }
                arraystore.push(obj)
            })
            // setGetvalues({ ...getvalues, signedDate: value, ['userId']: location?.state?.key })
            setInputCount(arraystore.length)
            setInputs(arraystore);
            setGetvalues({ Prescription: arraystore, signedDate: getDateOnly(new Date(prescriptions?.dateSigned)) });
            // setDataURL(url)
        }
    }

    const getDoctor = async () => {
        getOneDoctor(location?.state?.key).then(
            (data) => {

                const allDoctor = data?.allDoctors;
                // console.log("geuserss", allDoctor?.prescriptions);
                // setDefaultMedicationValue(allDoctor?.prescriptions?.medications);
                const arraystore: any = []
                var setvalue: any;
                // if (allDoctor?.prescriptions?.length > 0) {
                //     // setInputs([]);
                //     allDoctor?.prescriptions?.map((items: any) => {
                //         // console.log('itemsss',items)
                //         setvalue = items?.medications?.length;

                //         items?.medications?.map((medication: any) => {
                //             let obj = {
                //                 medication: medication?.medication,
                //                 strength: medication?.strength,
                //                 frequency: medication?.frequency,
                //                 id: medication?.id
                //             }
                //             arraystore.push(obj)
                //             // setInputs([...inputs, obj]);

                //         })
                //     })
                //     setInputCount(arraystore.length)
                //     setInputs(arraystore);


                // }




                let array: any = [];
                allDoctor?.userdata?.map((item: any) => {
                    // console.log("itemssssss",item)
                    // console.log("itemsss", item.fieldKey === "dateOfBirth")
                    if (item.fieldKey === "dateOfBirth") {
                        const date = new Date().getFullYear()
                        const age = JSON.parse(item?.fieldValue)
                        const agedate = age?.split('-')[0]
                        const patientAge = date - agedate
                        // console.log("dtuerer", age.split('-')[0], date)
                        getCurrentDoctor({ ...currentDoctor, ["age"]: patientAge, ["alldoctor"]: allDoctor })
                    }


                })






                // getCurrentDoctor({...currentDoctor,allDoctor})



            },
            (error) => {
                console.log("error", error)

            }
        );


    };

    // console.log("getucrrentdoctor", currentDoctor)

    const trim = () => {
        const url = padRef.current?.getTrimmedCanvas().toDataURL("image/png");
        if (url) setDataURL(url);
        setGetvalues({ ...getvalues, ['sign']: url })
    };

    // function handleClick(event: any) {
    //     if (event.target.classList.contains('remove')) {
    //         // Find the closest ancestor with class 'element' of the clicked remove button
    //         const elementToRemove = event.target.closest('.element');
    //         if (elementToRemove) {
    //             // Remove the found element
    //             elementToRemove.remove();
    //         }
    //     } else if (event.target.classList.contains('clone')) {
    //         // Find the closest ancestor with class 'wrapper' of the clicked element
    //         const wrapper = event.target.closest('.wrapper');
    //         // Clone the first element with class 'element' and append it to elements with class 'results'
    //         const clonedElement = wrapper.querySelector('.element').cloneNode(true);
    //         document?.querySelector('.results')?.appendChild(clonedElement);
    //     }
    // }
    const FormOnChange = (event: any) => {
        // const { name, value, id } = event.target;
        const name = event.target.name;
        const value = event.target.value;

        setGetvalues({ ...getvalues, [name]: value, ['userId']: location?.state?.key })
        // setGetvalues((prevState: any) => ({
        //     ...prevState, [formName]: { ...prevState[formName], [name]: { id: fieldId, value: value, formId: formId } }
        // }))
    }


    // const FormOnChangeRecord = (event?: any) => {
    //     // const { name, value, id } = event.target;

    //     const name = event?.target?.name;
    //     const value = event?.target?.value;
    //     const array: any = [];
    //     let userdata: any = {}
    //     let obj: any = {
    //         medication: medicationValue,
    //         strength: strengthValue,
    //         frequency: frequencyValue
    //     }
    //     array.push(obj)
    //     console.log("array", array)

    //     // obj.name = value;{}
    //     //         array.push(obj)
    //     // console.log("array",array)
    //     setGetvalues({ ...getvalues, ["prescription"]: array, ['userId']: searchParams.get('key') })

    // }

    console.log("gueiruergetvalues", getvalues, inputs, inputCount)

    return (

        <Row>

            <div className="panel_top_section">
                <h1>Add New Prescription</h1>

            </div>
            <div className="row  mb-4 pb-4">

                <div className="form_bottom_btn">
                    <button className="btn_gradian" type="button" onClick={() => draftCreateOnePrescription()}>Back</button>
                </div>
            </div>
            <Col className="mt-3 mb-4">
                <div className="add_doctor_form card_box col-sm-12 col-xl-9 mx-auto py-4 px-lg-4 mt-4">
                    <div className="add_doctor_fom px-4">
                        {/* personal-inoformation */}
                        <div className="row justify-content-around border-bottom mb-4 pb-4">
                            <div className="col-lg-12 col-xl-12">
                                <p className="text-black-50 fw-medium mb-2">Patient's Name:</p>
                            </div>
                            <div className="row  col-lg-12 col-xl-12">
                                <div className="col-lg-12 col-xl-6 mb-3">
                                    <label htmlFor="firsname" className="form-label">First Name</label>
                                    <input type="text" className="form-control" id="firstname" disabled defaultValue={currentDoctor?.alldoctor?.firstName} />
                                </div>
                                <div className="col-lg-12 col-xl-6 mb-3">
                                    <label htmlFor="Name" className="form-label">Last Name</label>
                                    <input type="text" className="form-control" id="lastname" disabled defaultValue={currentDoctor?.alldoctor?.lastName} />
                                </div>
                                <div className="col-lg-12 col-xl-12 mb-3">
                                    <label htmlFor="Name" className="form-label">Patient's Age</label>
                                    <input type="text" className="form-control" id="age" disabled defaultValue={currentDoctor?.age} />
                                </div>

                                <div className="col-lg-12 col-xl-12 mt-3">
                                    <p className="text-black-50 fw-medium mb-2">Treatment:</p>
                                </div>

                                {/* <div className=" row ">
                                    <div className="col-lg-12 col-xl-4">
                                        <label htmlFor="Name" className="form-label" >Medication</label>
                                    </div>
                                    <div className="col-lg-12 col-xl-4 ">
                                        <label htmlFor="Name" className="form-label"  >Strength</label>
                                    </div>
                                    <div className="col-lg-12 col-xl-4 ">
                                        <label htmlFor="Name" className="form-label" >Frequency</label>
                                    </div>
                                </div> */}
                                <div className="row d-flex table-responsive ms-2">
                                    <table>
                                        <thead>
                                            <tr className="">
                                                <th>Medication</th>
                                                <th>Strength</th>
                                                <th>Frequency</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {[...Array(inputCount)].map((_, index) => (
                                                <tr>
                                                    <td className="py-1 pe-1">
                                                        <input className="main_input form-control" type="text" id="box1" name="medication" value={inputs[index].medication}
                                                            onChange={(e) => handleInputChange(index, e)} />
                                                    </td>
                                                    <td className="py-1 pe-1">
                                                        <input id="box2" type="text" className="form-control" name="strength" value={inputs[index].strength}
                                                            onChange={(e) => handleInputChange(index, e)} />
                                                    </td>
                                                    <td className="py-1 pe-1">
                                                        <input id="box3" type="text" className="form-control" name="frequency" value={inputs[index].frequency}
                                                            onChange={(e) => handleInputChange(index, e)} />
                                                    </td>
                                                    <td className="d-flex align-items-center py-1">
                                                        <button className=" border-0 bg_gradiant rounded-1 text-white fs-5 mx-1" onClick={handleAddButtonClick}><i className="clone bi bi-plus-lg"></i></button>
                                                        <button className=" border-0 fs-5 mx-1 rounded-1" onClick={() => handleSubmit(index)}><i className="remove bi bi-x-lg"></i></button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                {/* <div className="wrapper p-0">
                                    {[...Array(inputCount)].map((_, index) => (
                                        <>
                                            <div className="element row">
                                                <div className="col-lg-12 col-xl-4 mb-3">
                                                    <input className="main_input form-control" type="text" id="box1" name="medication" value={inputs[index].medication}
                                                        onChange={(e) => handleInputChange(index, e)} />
                                                </div>
                                                <div className="col-lg-12 col-xl-4 mb-3">
                                                    <input id="box2" type="text" className="form-control" name="strength" value={inputs[index].strength}
                                                        onChange={(e) => handleInputChange(index, e)} />
                                                </div>
                                                <div className="col-lg-12 col-xl-2 mb-3">
                                                    <input id="box3" type="text" className="form-control" name="frequency" value={inputs[index].frequency}
                                                        onChange={(e) => handleInputChange(index, e)} />
                                                </div>
                                                <div className="col-lg-12 col-xl-2 mb-3">
                                                    <button className=" border-0 bg_gradiant rounded-1 text-white fs-5 mx-1" onClick={handleAddButtonClick}><i className="clone bi bi-plus-lg"></i></button>
                                                    <button className=" border-0 fs-5 mx-1 rounded-1" onClick={() => handleSubmit(index)}><i className="remove bi bi-x-lg"></i></button>
                                                </div>
                                            </div>
                                            <div className="results"></div>
                                        </>
                                    ))}
                                </div> */}


                                {location?.state?.prescriptionId &&
                                    <div className="col-lg-12 col-xl-12 mb-2 mt-3">
                                        <label htmlFor="Name" className="form-label text-black-50 fw-medium">Date Signed</label>
                                        <input type="date" className="form-control" id="date3" name="signdate" onChange={FormOnChange} value={getvalues?.signedDate} disabled />
                                </div>
                                }

                                <div className="col-lg-12 col-xl-12 mb-2 mt-3">
                                    <p className="text-black-50 fw-medium mb-3">Physician's Signature:</p>
                                    <div>
                                        {/* {!location?.state?.prescriptionId ? */}
                                        <>
                                        <SignaturePad ref={padRef} canvasProps={{ className: "sigCanvas" }} />
                                        <div className="sigPreview">
                                            <button className="delete_btn" onClick={clear}>Clear</button>
                                            <button className="btn_gradian" onClick={trim}>Ok</button>

                                            {dataURL ? (
                                                <img
                                                    className={"sigImage"}
                                                    src={dataURL}
                                                    alt="user generated signature"
                                                />
                                            ) : null}
                                        </div>
                                        </>
                                        {/* :
                                            <img
                                                className={"sigImage"}
                                                src={dataURL ? dataURL : ""}
                                                alt="user generated signature"
                                            />
                                        } */}
                                    </div>

                                </div>
                                {/* <div className="col-lg-12 col-xl-12 mb-3">
                                    <p className="text-black-50 fw-medium mb-3">Verification</p>
                                    <ReCAPTCHA
                                        sitekey="6LfEolQoAAAAANADwM45zEcDSTIngWTuHX-K1AW0 "
                                    // onChange={onChange}
                                    />
                                </div> */}



                            </div>
                        </div>

                        {/* canceland-save*/}
                        <div className="row  mb-4 pb-4">
                            <div className="col-lg-12 col-xl-4">

                            </div>
                            <div className="form_bottom_btn d-flex justify-content-end">
                                <button className="delete_btn" type="button" onClick={() => { navigate(-1); setallowBackNavigation(true) }}>Cancel</button>
                                <button className="btn_gradian" type="button" onClick={() => createOnePrescription()}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
            <Modal show={show} onHide={() => handleClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>Draft Mode</Modal.Title>
                </Modal.Header>
                <Modal.Body>Do you want save this draft mode ?</Modal.Body>
                <Modal.Footer>

                    <button className="bg_gray_500 rounded border-0 py-2 px-4" onClick={() => { handleClose(); navigate(-1); setallowBackNavigation(true) }}>
                        Cancel
                    </button>
                    <button className="bg_gradiant text-white rounded border-0 py-2 px-4" onClick={() => { draftCreateOnePrescription(); handleClose(); setallowBackNavigation(true) }} >
                        Save
                    </button>
                </Modal.Footer>
            </Modal>
        </Row>
    );
};

export default AddPrescription;