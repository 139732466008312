import { Row, Col } from "reactstrap";
import SearchInput from "../../components/SearchInput";
import { useState, useContext, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { RoleContext, AlertContext } from "../../utils/contexts/RoleContext";
import { getHospitalInvoices, deleteHospitalInvoice, getOneHospitalInvoice } from "../../services/billing-service";
import { decidePages } from "../../utils/utils";
import Pagination from "../../components/Pagination";
import CustomToolTip from "../../components/CustomToolTip";
import DeleteModal from "../../components/DeleteModal";

export default function Subscription() {
    const [loading, setLoading] = useState(false);
    const [invoices, setInvoices] = useState([]);
    const [showSearch, setShowSearch] = useState(false);
    const [pages, setPages] = useState<any>([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    const [rowData, setRowData] = useState([]);

    const navigate = useNavigate();
    const currentUser = useContext(RoleContext);
    const toast: any = useContext(AlertContext);

    useEffect(() => {
        fetchHospitalInvoices();
    }, [])

    async function fetchHospitalInvoices(page?: number) {
        setLoading(true);
        const pageCount = page != undefined ? page : 1;
        const response = await getHospitalInvoices(pageCount);
        if (response?.success) {
            setInvoices(response?.data?.rows);
            setRowData(response?.data?.rows);
            setLoading(false);
            const pages = decidePages(response?.data?.count);
            setPages(pages);
            setPageNumber(pageCount);
        }
    }

    async function deleteInvoice() {
        const response = await deleteHospitalInvoice(deleteId);
        if (response?.success) {
            toast.setShowToast("Data deleted successfully!", "success");
            fetchHospitalInvoices();
        } else {
            toast.setShowToast("Something went wrong on our side. Please try again later", "danger")
        }
    }

    async function handlePay(invoice: any) {
        const response = await getOneHospitalInvoice(invoice.id);
        if (response?.success) {
            navigate(`/${currentUser}/payment/${response?.data.maskedInvoice}`)
        }
    }

    return (
        <Row>
            <div className="panel_top_section position-relative">
                <h1>My Subscriptions</h1>
                {/* <div className="grid_icons d-flex gap-1 align-items-center float-end">
                    <button
                        type="button"
                        className="btn_gradian"
                        onClick={() => navigate(`/${currentUser}/add-invoice`)}
                    >
                        + Add Invoices
                    </button>
                    <div className="grid_list_icons icons_patients  d-flex align-items-center gap-2">
                        <SearchInput
                            visible={showSearch}
                            data={rowData}
                            setData={(value: any) => setInvoices(value)}
                        />
                        <span
                            className="filter_icons fs-3 px-1"
                            onClick={() => setShowSearch(!showSearch)}
                        >
                            <i className="bi bi-funnel-fill" />
                        </span>
                    </div>
                </div> */}
            </div>
            <Col>
                <div className="row d-flex table-responsive">
                    <table>
                        <thead>
                            <tr className="list_head">
                                <th>S.No.</th>
                                <th>Title</th>
                                <th>Invoice number</th>
                                <th>Payment Status</th>
                                <th>Description</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoices?.length > 0 ?
                                <>
                                    {invoices?.map((item: any, index: number) => {
                                        return (
                                            <tr className="list_detail" key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.invoiceTitle}</td>
                                                <td>{item.invoiceNumber}</td>
                                                <td>
                                                    <span
                                                        className="po_completed p-2 py-1 rounded-5 text_sm"
                                                        style={{ backgroundColor: item.invoiceStatus == 0 || item.invoiceStatus == 11 ? "orange" : item.invoiceStatus == 1 ? "green" : "red", color: 'white' }}
                                                    >
                                                        {item.invoiceStatus == 0 || item.invoiceStatus == 11 ? "Pending" : item.invoiceStatus == 1 ? "Completed" : "Failed"}
                                                    </span>
                                                </td>
                                                <td>{item.remarks}</td>
                                                <td>
                                                    <span className="action" >
                                                        <CustomToolTip title="Info">
                                                            <button className="edit_btn">
                                                                <i
                                                                    className="bi bi-exclamation-circle"
                                                                    onClick={() => {
                                                                        navigate(`/${currentUser}/invoice-info/${item.id}`)
                                                                    }}
                                                                />
                                                            </button>
                                                        </CustomToolTip>
                                                        {item.invoiceStatus != 1 &&
                                                            <CustomToolTip title="Pay">
                                                                <button className="edit_btn">
                                                                    <i
                                                                        className="bi bi-credit-card"
                                                                        onClick={() => {
                                                                            handlePay(item)
                                                                        }}
                                                                    />
                                                                </button>
                                                            </CustomToolTip>
                                                        }
                                                    </span>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </>
                                :
                                <tr className="list_detail">
                                    <td colSpan={10}>
                                        {!loading ? "No data found" : <Spinner />}
                                    </td>
                                </tr>
                            }
                        </tbody>
                        {pages?.length > 1 &&
                            <tfoot>
                                <Pagination
                                    pages={pages}
                                    currentPage={pageNumber}
                                    onClickPageNumber={(page: any) => {
                                        setPageNumber(page);
                                        fetchHospitalInvoices(page);
                                    }}
                                />
                            </tfoot>
                        }
                    </table>
                </div>
            </Col>
            <DeleteModal
                show={showDeleteModal}
                handleYes={() => {
                    deleteInvoice();
                    setDeleteId("");
                    setShowDeleteModal(false);
                }}
                handleClose={() => setShowDeleteModal(false)}
                body="Do you really want to delete this Data?"
                head="Delete Invoice"
            />
        </Row>
    )
}