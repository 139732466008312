import React from "react";
import { IoSearchOutline } from "react-icons/io5";
import { VscBell } from "react-icons/vsc";
import { Link } from "react-router-dom";
import {
  Navbar,
  Collapse,
  Nav,
  NavItem,
  NavbarBrand,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Button,
} from "reactstrap";
import { useNavigate, createSearchParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";


import { ReactComponent as LogoWhite } from "../assets/images/logos/amplelogowhite.svg";
import { RoleContext, NotificationContext, RoleUpdateContext, HospitalContext } from "../utils/contexts/RoleContext";

import { getCurrentUser } from "../services/auth.service";
import { getoneuser } from "../services/auth.service";
import { logout } from "../services/auth.service";
import user1 from "../assets/images/users/user1.jpg";
import { login, switchUser } from "../services/auth.service";
import { object } from "yup";
import DoctorNotification from "../pages/common/appointment/DoctorNotification";
import { getAllNotification } from "../services/notification-service";
import { socket } from "../index";
import { getOneDoctor } from "../services/doctor-service";
import MyProfile from "../pages/adminHospital/user/MyProfile";
import { ImagePath } from "../services/auth.service";
import { staff, doctor, patient, hospital, admin } from "./Sidebar";
import Logo from "./Logo";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import SearchableDropdown from "../components/SearchableDropdown";

const Header = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [getuser, setGetUser] = useState<any>({});
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [notifyCount, setNotifyCount] = useState(0);
  const [notifications, setNotifications] = useState<any>([]);
  const [myProfile, setMyProfile] = useState<any>({});
  const [search, setSearch] = useState("");
  const [menu, setMenu] = useState<any>([]);
  const [filteredMenu, setFilteredMenu] = useState<any>([]);
  const [showSearchBar, setShowSearchBar] = useState(false)

  const currentUser = useContext(RoleContext);
  const notification: any = useContext(NotificationContext);
  const roleUpdate: any = useContext(RoleUpdateContext)
  const hospitalContext: any = useContext(HospitalContext)

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const Handletoggle = () => {
    setIsOpen(!isOpen);
  };

  const handleBellClick = () => {
    setIsPopupOpen(!isPopupOpen);
  };


  useEffect(() => {
    setGetUser(getCurrentUser());
    getDoctor();
    switch (currentUser) {
      case "admin":
        setMenu(admin);
        break;
      case "hospital":
        setMenu(hospital);
        break;
      case "staff":
        setMenu(staff);
        break;
      case "doctor":
        setMenu(doctor);
        break;
      default:
        setMenu(patient);
    }
  }, [currentUser]);

  const getDoctor = async () => {
    const user = getCurrentUser()
    getOneDoctor(user?.id).then(
      (data) => {
        const response = data?.allDoctors;
        setMyProfile(response);
      },
      (error) => {
        console.log("error", error)
      }
    );
  };

  // const getNotifications = () => {
  //   getAllNotification().then(
  //     (data) => {


  //       setNotifyCount(data?.notify?.count);
  //       setNotifications(data.notify.rows)

  //     },
  //     (error) => {
  //       const resMessage =
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //         error.message ||
  //         error.toString();

  //       // setLoading(false);
  //       // setMessage(resMessage);
  //     }
  //   );

  // }
  const switchRole = (email: string, roleId: number, roleName: string) => {
    switchUser(email, roleId, roleName, getuser?.accessToken).then(
      (data) => {
        console.log("Dataaa", data);
        if (data.message) {

          // setErr(data.message)
          // setButtonDisable(data?.buttondisable)
        }
        else {
          const lastVisitedURL = localStorage.getItem("lastVisitedURL")
          if (lastVisitedURL) {
            window.location.href = JSON.parse(lastVisitedURL);
          } else {
            navigate(`/${data?.role}/dashboard`);
            window.location.reload();
          }
          localStorage.setItem("user", JSON.stringify(data));
          localStorage.removeItem("lastVisitedURL")
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        // setLoading(false);
        // setMessage(resMessage);
      }
    );

  }

  // console.log("currentuserss",getuser,Object.keys(getuser["switchUser"])?.length)

  const logOut = () => {
    socket.emit("user_status", {
      status: "OFFLINE",
      id: getCurrentUser()?.id
    });
    logout();
    roleUpdate(undefined);
    navigate(`/${currentUser}/login`)
    // window.location.reload();
  };
  const showMobilemenu = () => {
    document.getElementById("sidebarArea")?.classList.toggle("showSidebar");
  };

  function handleChange(e: any) {
    const { value } = e.target;
    const filteredMenu = menu.filter((item: any) => item.title.toLowerCase().match(value.toLowerCase()));
    setFilteredMenu(filteredMenu)
    setSearch(value);
  }

  function handleSelect(item: any) {
    hospitalContext.setHospital(item);
    const splittedPathName = window.location.pathname?.split("/");
    if (splittedPathName[splittedPathName.length - 1] === "hospital") {
      navigate(`/${currentUser}/hospital-dashboard`);
    }
  }

  return (
    <>
    <Navbar color="iboost-white" dark expand="md" className="header-nav">
        <div className="d-flex align-items-center">
        {/* <NavbarBrand href="/" className="d-lg-none">
          <LogoWhite />
        </NavbarBrand> */}
        <Button

          className="d-lg-none nav_green"
          onClick={() => showMobilemenu()}
        >
          <i className="bi bi-list"></i>
        </Button>
        </div>
      <div className="d-flex align-items-center headersearch_wrap">
        <div className="mobile-logo d-none">
          <Logo />
        </div>
        <div className="mobile-search-icon d-none">
          <IoSearchOutline onClick={() => notification?.setSearchOn(!notification?.searchOn)} />
          {notification.searchOn &&
            <div>
            <input
              type="text"
              value={search}
              onChange={handleChange}
                  className="form-control"
              // placeholder="Search for Files, Patients etc..." 
              placeholder="Search..."
            />
              {search !== "" &&
                <div className="position-absolute header-dropdown">
                  <Dropdown isOpen={search !== "" && filteredMenu?.length > 0 ? true : false} toggle={() => setSearch("")} className="me-3">
                    <DropdownMenu>
                      {filteredMenu?.map((item: any) => {
                        return (
                          <DropdownItem onClick={() => {
                            setSearch("");
                            navigate(item.href);
                            setDropdownOpen(false);
                          }}
                          >
                            {item.title}
                          </DropdownItem>
                        )
                      })}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              }
            </div>
          }
        </div>
        <div className="headersearch_section position-relative">
          <IoSearchOutline />
          <input
            type="text"
            value={search}
            onChange={handleChange}
            // placeholder="Search for Files, Patients etc..." 
            placeholder="Search..."
          />
          {search !== "" &&
            <div className="position-absolute">
              <Dropdown isOpen={search !== "" && filteredMenu?.length > 0 ? true : false} toggle={() => setSearch("")} className="me-3">
                <DropdownMenu>
                  {filteredMenu?.map((item: any) => {
                    return (
                      <DropdownItem onClick={() => {
                        setSearch("");
                        navigate(item.href);
                        setDropdownOpen(false);
                      }}
                      >
                        {item.title}
                      </DropdownItem>
                    )
                  })}
                </DropdownMenu>
              </Dropdown>
            </div>
          }
        </div>
        {currentUser === "admin" &&
            <div className="me-1 hospital-drop d-none d-sm-block">
              <div>
            <SearchableDropdown
              options={hospitalContext?.hospitals}
              label="name"
              id="id"
              selectedVal={hospitalContext?.selectedHospital?.id}
              handleChange={(val: any) => handleSelect(val)}
                optionName="Link Hospital"
            />
          </div>
          </div>
        }
        <div className="headernotify_section" style={{ position: 'relative', cursor: 'pointer' }} >
          <VscBell onClick={handleBellClick} />
          <span className="" style={{
            position: 'absolute', top: '-6px', right: '-6px', background: '#00929f', color: 'white', borderRadius: '50%', padding: '2px 6px', fontSize: '12px', width: '18px',
            height: '18px'
          }}>
            {notification.count > 9 ? "9+" : notification.count}
          </span>

          <DoctorNotification isOpen={isPopupOpen} togglePopup={() => setIsPopupOpen(!isPopupOpen)} />

        </div>
      </div>

      {/* <div className="d-flex align-items-center headersearch_wrap">
        <div className="headersearch_section">
          <IoSearchOutline />
          <input type="text" placeholder="Search for Files, Patients etc..." />
        </div>
        <div className="headernotify_section">
          <VscBell />
        </div>
      </div> */}
      {/* <Nav className="me-auto" navbar>
          <NavItem>
            <Link to="/starter" className="nav-link">
              Starter
            </Link>
          </NavItem>
          <NavItem>
            <Link to="/about" className="nav-link">
              About
            </Link>
          </NavItem>
          <UncontrolledDropdown inNavbar nav>
            <DropdownToggle caret nav>
              DD Menu
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem>Option 1</DropdownItem>
              <DropdownItem>Option 2</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Reset</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav> */}

      <div className="header-profile">
        <Dropdown isOpen={dropdownOpen} toggle={toggle} className="me-3">
          <DropdownToggle color="iboost-white d-flex align-items-center gap-2 user_login">
            <img
              src={(notification?.profile?.profile != "0" && notification?.profile?.profile != null) ? `${ImagePath}` + 'profile/' + notification?.profile?.profile : user1}
              alt="profile"
              className="login_img rounded-circle"
              width="60"
            ></img>
            <div className="d-none d-xl-flex admin_name">
              <strong className="text-capitalize">{notification?.profile?.firstName + " " + notification?.profile?.lastName}</strong>
              <span className="text-capitalize">{getuser?.role}</span>
            </div>
            <span><i className="d-none d-xl-block bi bi-chevron-down position-absolute top-0 fw-bold pt-2 mt-1"></i></span>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>Info</DropdownItem>
            <DropdownItem
              onClick={() => navigate(`/${currentUser}/my-profile/${getuser.id}`)}
            >
              My Account
            </DropdownItem>
            <DropdownItem
              // onClick={() => navigate({
              //   pathname: `/${currentUser}/editdoctor`,
              //   search: createSearchParams({
              //     key: getuser.id
              //   }).toString()
              // })
              onClick={() => navigate(`/${currentUser}/editprofile/${getuser.id}`)}
            >
              Edit Profile
            </DropdownItem>
            {getuser?.switchUser?.length > 0 &&
              getuser?.switchUser?.map((item: any) => {
                return (
                  <DropdownItem
                    onClick={() => switchRole(item?.email, item?.roleId, item?.roleName)
                    }
                  >
                    Switch Role  {item.roleName}
                  </DropdownItem>
                )

              })
            }
            <DropdownItem divider />
            {currentUser !== "staff" &&
              <DropdownItem>My Balance</DropdownItem>
            }
            <DropdownItem onClick={(() => navigate(`/${currentUser}/messages`))}>Inbox</DropdownItem>
            <DropdownItem onClick={() => logOut()}>Logout</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </Navbar>
      {currentUser === "admin" &&
        <div className={`d-sm-none mx-1 ${notification?.searchOn ? "mt-5" : "mt-1"}`}>
          <div>
            <SearchableDropdown
              options={hospitalContext?.hospitals}
              label="name"
              id="id"
              selectedVal={hospitalContext?.selectedHospital?.id}
              handleChange={(val: any) => handleSelect(val)}
              optionName="Link Hospital"
            />
          </div>
        </div>
      }
    </>
  );
};

export default Header;