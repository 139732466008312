import React, { useState } from "react";
// import { Typography, Box } from "@mui/material";
// import {
//   TextPlain,
//   Pdf,
//   Docx,
//   Xlsx,
//   Xls,
//   Csv,
// } from "../../constant/fileTypeExt";
// import { BaseUrl } from "../../constant/apipath";
import { FileIcon, defaultStyles } from "react-file-icon";
// import ArrowCircleDownOutlined from "@mui/icons-material/ArrowCircleDownOutlined";
// import JsFileDownloader from "js-file-downloader";
// import CircularProgress from "@mui/material/CircularProgress";

export default function FileRenderer(props: any) {
  const [fileId, setFileId] = useState("");
  const [downArrow, setDownArrowClient] = useState(false);
  //   const handleDownload = (data, filename) => {
  //     new JsFileDownloader({
  //       url: data,
  //       filename: filename,
  //     })
  //       .then(function (res) {
  //         if (res) {
  //           setTimeout(() => {
  //             setDownArrowClient(false);
  //           }, 500);
  //         }
  //       })
  //       .catch(function () {
  //         setDownArrowClient(false);
  //         alert("can not download file");
  //       });
  //   };

  //   const loadAnimation = (postImg, index) => {
  //     return (
  //       <>
  //         <div style={{ position: "absolute" }}>
  //           {fileId === postImg && downArrow ? (
  //             <Box sx={{ display: "flex", marginTop: "4px", marginLeft: "3px" }}>
  //               <CircularProgress sx={{ color: "#ffff" }} size={23} />
  //             </Box>
  //           ) : null}
  //         </div>
  //       </>
  //     );
  //   };

  function handleDownload() {
    const link = document.createElement('a');
    link.href = props.fileData?.source;
    link.download = props.fileData?.fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div>
      <div style={{ marginTop: "10px" }}>
        <div className="file-details-container">
          <div
            style={{
              height: 30,
              width: 30,
            }}
          >
            <FileIcon
              extension={props.fileData.ext}
            // {...defaultStyles[props.extension]}
            />
          </div>
          <div className="name-container">
            <p className="file-name" >
              {props.fileData.fileName}
              {/* {props.fileData.name
                  ? props.fileData.name
                  : postImg.original_name.substring(0, 4) +
                  ".." +
                  postImg.original_name.slice(-8)} */}
            </p>

            {props.fileData.size != undefined &&
              Math.round(Number(props.fileData.size) / 1024) <= 1024 ? (
              <p className="file-size" >
                {Math.round(props.fileData.size / 1024)} Kb
              </p>
            ) : (
              <p className="file-size">
                {
                  props.fileData.size / Number(Math.pow(1024, 2).toFixed(5))
                }{" "}
                Mb
              </p>
            )
            }


          </div>
          {props.pos != "0" &&
            <div className="download-btn clickable" onClick={handleDownload}>
              <div>
                <i className="bi bi-download"></i>
                {/* {loadAnimation(postImg.id)}
              <ArrowCircleDownOutlined
                onClick={() => {
                  setFileId(postImg.id);
                  setDownArrowClient(true);
                  handleDownload(
                    postImg.src
                      ? postImg.src
                      : BaseUrl +
                      "/images/" +
                      postImg.user_id +
                      "/" +
                      postImg.source,
                    postImg.user_id
                      ? postImg.original_name
                      : postImg.name
                  );
                }}
                style={{
                  fontSize: "24px",
                  cursor: "pointer",
                  position: "relative",
                  bottom: "0",
                  top: "1.5",
                  left: "1",
                }}
              /> */}
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

