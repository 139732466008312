import authHeader from "./auth-header";
import axios from "axios";
import { END_POINT } from "../config/constants";

// const APIS_URL = "http://localhost:5000/api/medicalrecord/";

const APIS_URL = `${END_POINT}medicalrecord/`

export const getallmedicalrecord = (user:any, hospitalId: number) => {
    return axios
      .get(APIS_URL + `get_medicalrecord?roleId=5&user=${user}&hospitalId=${hospitalId}`)
      .then((response) => {
        return response?.data;
      });
  }

  export const deleteonemedicalrecord = (id: any) => {
    return axios
      .delete(APIS_URL + "deleterecord/" + id, {
  
      })
      .then((response) => {
  
  
        return response?.data;
      });
  };

export const createMedicalRecord = (values: object, id: string | null, service: any) => {
  return axios
    .put(APIS_URL + "add_medicalrecord/" + id, {
      values,
      service
    })
    .then((response) => {
      return response?.data;
    });
};


export const editMedicalRecord = (values: object, id: string | null, service: any) => {
  console.log()
  return axios
    .put(APIS_URL + "edit_medicalrecord/" + id, {
      values,
      service
    })
    .then((response) => {
      return response?.data;
    });
};

export const getOnePatientRecords = () => {
  return axios
    .get(APIS_URL + `get-one-patient-records`)
    .then((response) => {
      return response?.data;
    });
}

