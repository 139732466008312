import React, { useState, useEffect, useContext } from "react";
import DoctorAvailability from "../../types/doctorAvailability.type";
import { saveAvailability, getOneTiming, updateOneTiming } from "../../services/doctoravailability-service";
import { useNavigate, useLocation } from 'react-router-dom';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
// import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { AlertContext } from "../../utils/contexts/RoleContext";

function DoctorAvailabilityForm(props: any) {
  const [formValues, setFormValues] = useState<any>({
    day: {
      value: "",
      error: ""
    },
    fromhours: {
      value: "",
      error: ""
    },
    tohours: {
      value: "",
      error: ""
    },
    consulttime: {
      value: "",
      error: ""
    }
  });

  const toast:any = useContext(AlertContext);

  useEffect(() => {
    if (props.availabilityId) {
      getMyOneTiming();
    }
  }, [props]);

  async function getMyOneTiming() {
    const response = await getOneTiming(props.availabilityId);
    if (response?.success) {
      const formCopy = { ...formValues };
      const data = response.data;
      formCopy.day.value = data.day;
      formCopy.fromhours.value = data.from_hours;
      formCopy.tohours.value = data.to_hours;
      formCopy.consulttime.value = data.consultation_duration;
      setFormValues(formCopy);
    }
  }


  const handleRegister = async () => {
    if (await handleValidation()) {
      props.handleClose();
      let obj = {
        day: formValues.day.value,
        fromhours: formValues.fromhours.value,
        tohours: formValues.tohours.value,
        consulttime: formValues.consulttime.value
      }
      saveAvailability(obj).then(
        (response) => {
          if (response.status === 201) {
            toast.setShowToast("The timing you have entered is overlapping with existing timing. Please select another time slots.", "success");
          } else if (response.status === 200) {
            props.handleSave();
            toast.setShowToast("Time slot has been created!.", "success");
          } else {
            alert('Internal Server Error')
          }
        },
        (error: any) => {
          console.log("error", error)
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    }
  };

  const updateTiming = async () => {
    if (await handleValidation()) {
      props.handleClose();
      let obj = {
        day: formValues.day.value,
        fromhours: formValues.fromhours.value,
        tohours: formValues.tohours.value,
        consulttime: formValues.consulttime.value
      }
      updateOneTiming(props.availabilityId, obj).then(
        (response) => {
          if (response.status === 201) {
            toast.setShowToast("The timing you have entered is overlapping with existing timing. Please select another time slots.", "success");
          } else if (response.status === 200) {
            props.handleSave();
            toast.setShowToast("Time slot has been updated!", "success");
          } else {
            alert('Internal Server Error')
          }
        },
        (error: any) => {
          console.log("error", error)
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    }
  }

  function handleValidation() {
    let formCopy = { ...formValues };
    let canCreate = true
    for (let key in formCopy) {
      if (!formCopy[key].value) {
        formCopy[key].error = "This is required field"
        canCreate = false
      }
    }
    setFormValues(formCopy);

    let splittedFromHr = formValues.fromhours.value.split(":");
    let splittedToHr = formValues.tohours.value.split(":");

    if (canCreate && (Number(splittedFromHr[0]) >= Number(splittedToHr[0]))) {
      alert("You have entered invalid time.")
      canCreate = false
    }

    return canCreate;
  }

  function handleChange(e: any) {
    const { name, value } = e.target;
    setFormValues((prevState: any) => ({ ...prevState, [name]: { ...prevState[name], value, error: "" } }))
  }


  return (
    <div className="col-md-12">
      <div className="card email-container">
        {/* <div style={{ fontWeight: "bold", fontSize: 23 }}>Doctor Availability</div> */}
        <div>
          <div className="mb-3 mt-3">
            <label htmlFor="date" className="mb-2"> Days<span className="required-color">*</span></label>
            <select name="day" className="form-control"
              // onChange={(event) => changeDays(event.target.value)}
              onChange={handleChange}
              value={formValues.day.value}>
              <option value="Select">Select...</option>
              <option value="Sun">Sun</option>
              <option value="Mon">Mon</option>
              <option value="Tue">Tue</option>
              <option value="Wed">Wed</option>
              <option value="Thu">Thu</option>
              <option value="Fri">Fri</option>
              <option value="Sat">Sat</option>
            </select>
            {formValues.day.error !== "" &&
              <div className="text-danger">
                {formValues.day.error}
              </div>
            }
          </div>
          <div className="form-group mb-3">
            <label htmlFor="fromhours" className="mb-2"> From Hours<span className="required-color">*</span> </label>
            <TimePicker
              clearIcon={null}
              className="form-control border-red-500"
              name="fromhours"
              format="HH:mm" // HH is used for 24-hour format
              onChange={(e) => setFormValues((prevState: any) => ({ ...prevState, fromhours: { ...prevState.fromhours, value: e, error: "" } }))}
              value={formValues.fromhours.value}
              disableClock
            />
            {formValues.fromhours.error &&
              <div className="text-danger">
                {formValues.fromhours.error}
              </div>
            }
          </div>
          <div className="form-group mb-3">
            <label htmlFor="tohours" className="mb-2"> To Hours<span className="required-color">*</span> </label>
            <TimePicker
              clearIcon={null}
              className="form-control border-red-500"
              format="HH:mm"
              name="tohours"
              onChange={(e) => setFormValues((prevState: any) => ({ ...prevState, tohours: { ...prevState.tohours, value: e, error: "" } }))}
              value={formValues.tohours.value}
              disableClock
            />
            {formValues.tohours.error &&
              <div className="text-danger">
                {formValues.tohours.error}
              </div>
            }
          </div>
          <div className="form-group mb-3">
            <label htmlFor="consulttime" className="mb-2"> Consult Time (in mins)<span className="required-color">*</span> </label>
            {/* <input
              name="consulttime"
              type="number"
              className="form-control"
              onChange={handleChange}
              value={formValues.consulttime.value}
            /> */}
            <select name="consulttime"
              className="form-control"
              // onChange={(event) => changeDays(event.target.value)}
              onChange={handleChange}
              value={formValues.consulttime.value}>
              <option value="Select">Select...</option>
              <option value="30">30</option>
              <option value="60">60</option>
            </select>
            {formValues.consulttime.error &&
              <div className="text-danger">
                {formValues.consulttime.error}
              </div>
            }
          </div>

          <div className="form-group">
            {props.availabilityId === 0 ?
              <button
                type="button"
                className="btn_gradian"
                onClick={handleRegister}
              >
                Save
              </button>
              :
              <button
                type="button"
                className="btn_gradian"
                onClick={updateTiming}
              >
                Update
              </button>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorAvailabilityForm;
