import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from "reactstrap";
import React, { useContext, useEffect, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import IUser from '../../../types/user.type';
import Modal from 'react-bootstrap/Modal';
import { RoleContext, AlertContext, HospitalContext } from '../../../utils/contexts/RoleContext';
import { createRoot } from 'react-dom/client';
import { deleteStaffData } from "../../../services/staff-service";
import { getallStaff } from "../../../services/staff-service";
import { deleteoneuser } from '../../../services/user.service';
import SearchInput from '../../../components/SearchInput';
import { importStaffs } from '../../../services/staff-service';
import constants from '../../../config/constants';
import { capitalizeFirstLetter, decidePages } from '../../../utils/utils';
import Spinner from 'react-bootstrap/Spinner';
import Pagination from '../../../components/Pagination';
import CustomToolTip from '../../../components/CustomToolTip';
import PhoneNumber from '../../../components/PhoneNumber';

// Row Data Interface
interface IRow {
  mission: string;
  company: string;
  location: string;
  date: string;
  time: string;
  rocket: string;
  price: number;
  successful: boolean;
}
const endPoint = "http://localhost:3000/";
// Create new GridExample component
const StaffForm = () => {
  const navigate = useNavigate()
  const currentUser = useContext(RoleContext);

  // Row Data: The data to be displayed.
  const [rowData, setRowData] = useState([]);
  const [show, setShow] = useState(false);
  const [more, setMore] = useState();
  const [staffs, setStaffs] = useState([])

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [deleteId, setDeleteId] = useState<string>("");
  const [popup, showPopup] = useState(false);
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [recordsToBeDeleted, setRecordsToBeDeleted] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState(1);

  const hospitalContext: any = useContext(HospitalContext);


  // Column Definitions: Defines & controls grid columns.
  const [colDefs] = useState<ColDef[]>([
    { field: 'FirstName' },
    { field: 'LastName' },
    { field: 'Email' },
    { field: 'PhoneNumber' },
    { field: "FieldUpdate", cellRenderer: totalValueRenderer },
    { field: "FieldDelete", cellRenderer: deleteForm }
    // { field: 'price' },
    // { field: 'successful' },
    // { field: 'rocket' },
  ]);

  const toast: any = useContext(AlertContext);
  // Fetch data & update rowData state
  useEffect(() => {
    getUsers()
    // fetch(`${endPoint}patient/get_patient`) // Fetch data from server
    //   .then((result) => result.json()) // Convert to JSON
    //   .then((rowData) => setRowData(rowData)); // Update state of `rowData`
    document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Staffs`;
  }, [hospitalContext.selectedHospital, currentUser]);

  const getUsers = (page?: number) => {
    setLoading(true);
    const pageCount = page != undefined ? page : 1;
    getallStaff(pageCount, hospitalContext.selectedHospital?.id).then(
      (response) => {
        setLoading(false);
        if (response?.success) {
        const staffData = response?.result;
        setStaffs(staffData)
          setRowData(staffData);
          const pages = decidePages(response?.staffCount);
          setPages(pages);
          setPageNumber(pageCount)
        }
      },
      (error) => {
        console.log("error", error)
        setLoading(false);
        // setMessage(resMessage);
      }
    );
  }

  function totalValueRenderer(props: any) {

    // setFieldId(props.data.Id)


    return (
      <span>

        <button className='btn btn-primary'
          // onClick={() => navigate('/edit')}
          onClick={() => navigate(`/${currentUser}/editstaff`, { state: { key: props.data.Id } })

          }
        >Edit</button>
      </span>
    );
  };


  function deleteForm(props: any, id: any) {

    // console.log("fieldiddd",props)

    return (
      <div>
        <span>
          <button className='btn btn-primary'
            onClick={() => {
              handleShow();
              setDeleteId(props.data.Id)
            }
            }
          // onClick={() => console.log("checkingsss",props)}
          >Delete</button>
        </span>
      </div>
    );
  };

  const onSearchChange = (e: any) => {
    if (e.target.value === null || e.target.value === "") {
      getUsers()
    }
    else {

      const targetvalue = e.target.value;
      // console.log("uesrsss",targetvalue)
      const values = rowData.filter((items: any, i: any) => Object.values(items).join(" ").toLowerCase().match(targetvalue.toLowerCase()));
      setRowData(values)
    }
  }

  const setPagination = (data: any) => {
    let pageLength: number = Math.floor(data?.length / 10) + 1;
    const arr = [];
    for (let i = 1; i < pageLength + 1; i++) {
      arr.push(i)
    }
    console.log("array", arr)
    // setpages(arr);
  }

  const handlePagination = (pageNumber: number) => {
    setPageNumber(pageNumber);
    setStaffs(rowData.slice(pageNumber * 10 - 9, pageNumber * 10 + 1))
    setPagination(rowData);
  }

  // const showForm=(id:any)=>{
  //   // setShow(true)
  //   handleShow()
  //   setDeleteId(id)
  //       }

  const showForm = (id?: any) => {
    // console.log("iddd", id === undefined, deleteId)
    if (id != undefined) {
      handleShow()
      setDeleteId(id)
    }
    else {

      handleShow()
      setDeleteId(deleteId)
    }
  }

  const handleDelete = async (id: any) => {

    deleteoneuser(id).then(
      (response) => {
        // const doctorData = response.result;
        console.log("responesss", response);
        getUsers();
        // setRowData(response.result)

        // navigate("/profile");
        // window.location.reload();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        // setLoading(false);
        // setMessage(resMessage);
      }
    );
  }

  const handleClick = (roleId: any) => {
    if(currentUser === "admin") {
      navigate(`/${currentUser}/hospital`, { state: { roleId: roleId, what: "user" } });
    } else {
    navigate(`/${currentUser}/add-user/${roleId}`, { state: { roleId: roleId } });
  }
  }

  function handleRadioChange(item: any) {
    const deleteArray = [...recordsToBeDeleted];
    const check = deleteArray.find(((element: any) => element.id == item.id));
    if (check === undefined) {
      setRecordsToBeDeleted((prevState: any) => ([...prevState, item]))
    } else {
      setRecordsToBeDeleted((prevState: any) => (prevState.filter((element: any) => element.id != item.id)));
    }
  }

  function decideStatus(item: any) {
    let decideCheck = false
    recordsToBeDeleted.forEach((element: any) => {
      if (element.id == item.id) {
        decideCheck = true;
        return false;
      }
    })
    return decideCheck;
  }

  const handleChange = (e: any) => {
    console.log("anyss", e)
    // setFile(e.target.files[0]);
    e.preventDefault();
    const files = e.target.files[0]

    if (files) {

      let reader = new FileReader();
      reader.onload = function (event: any) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      reader.readAsText(files);
    }
  };

  const csvFileToArray = (string: any) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = csvRows.map((i: any) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object: any, header: any, index: any) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });
    console.log("arrayaa", array)
    importStaffUser(array);
  };

  const importStaffUser = (arrayvalue: any) => {
    importStaffs(arrayvalue).then(
      (response) => {
        console.log("check", response);
        if (response.success) {
          setTimeout(() => {
            toast.setShowToast("Staff imported successfully!", "success");
          }, 1000);
          getUsers();
        } else {
          toast.setShowToast("Some user in your file is already registered. Please cross check the details!.", "danger");
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        // setLoading(false);
        // setMessage(resMessage);
      }
    );
  }

  // Container: Defines the grid's theme & dimensions.
  return (
    <Row>
      <div className="panel_top_section position-relative">
        <h1>Staffs</h1>
        <div className="grid_icons d-flex gap-1 align-items-center float-end">
          <button
            type="button"
            className="btn_gradian"
            onClick={() => handleClick('4')}
          >
            + Add New Staff
          </button>
          {/*<button type="button" className="btn_gradian dark_btn"> <span className="px-2"><i className="bi bi-download"></i></span>Import Staff</button>*/}
          <div className='btn_gradian dark_btn d-flex align-items-center' >
            <input type="file" multiple id="upload" hidden name=".csv" onChange={(e) => {
              // handleFileUpload(e, item.id, item.formId, keys.formName);
              handleChange(e)
            }} accept=".csv, .xlsx, .xls, text/plain" />
            <label htmlFor="upload"><span className="px-2"><i className="bi bi-download"></i></span>Import Staff</label>
          </div>
          <div className="grid_list_icons icons_patients  d-flex align-items-center gap-2">
            {recordsToBeDeleted?.length > 0 &&
              <button
                type="button"
                className="delet_patiens"
                onClick={() => {
                  showForm()
                }}>
                <i className="bi bi-trash" />
              </button>
            }
            {/* <button type="button" className="delet_patiens" onClick={() => showForm()}> <i className="bi bi-trash"></i> </button> */}
            <SearchInput
              visible={showSearch}
              data={rowData}
              setData={(value: any) => setStaffs(value)}
            />
            <span
              className="filter_icons fs-3 px-1"
              onClick={() => setShowSearch(!showSearch)}
            >
              <i className="bi bi-funnel-fill" />
            </span>
          </div>
        </div>
      </div>
      <Col>
          <div className="row d-flex table-responsive">
            <table>
              <thead>
                <tr className="list_head">
                  <th><span className="d-block float-start p-0 bg-transparent">
                    <input
                      className="float-start form-check-input"
                      type="checkbox"
                      name="name"
                      id="name"
                    checked={(recordsToBeDeleted?.length === staffs?.length && staffs?.length !== 0) ? true : false}
                      onChange={() => {
                        if (recordsToBeDeleted?.length === staffs?.length) {
                          setRecordsToBeDeleted([]);
                        } else {
                          const data = [...staffs];
                          setRecordsToBeDeleted(data);
                        }
                      }}
                    />
                  </span>
                    <label className="p-0 bg-transparent" htmlFor="name">Name</label>
                  </th>
                  <th>Email</th>
                  <th>Phone number</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
              {staffs?.length > 0 ?
                <>
                  {staffs?.map((staff: any, index: number) => {
                  return (
                    <tr className="list_detail" key={index + staff.firstName}>
                      <td><span className="px-1 d-block float-start">
                        <input
                          className="float-start form-check-input"
                          type="checkbox"
                          name="name"
                          id="name2"
                          // onClick={() => { setShowDeleteIcon(true); setDeleteId(staff.id) }} 
                          onChange={() => {
                            handleRadioChange(staff);
                            // setShowDeleteIcon(true);
                            // setDeleteId(item.id)
                          }}
                          checked={decideStatus(staff)}
                        />
                      </span>
                        <label htmlFor="name2">{staff.firstName + " " + staff.lastName}</label>
                      </td>
                      <td>{staff.email}</td>
                        <td> <PhoneNumber phoneNumber={staff.phoneNumber} allowCall={staff.allowCall} /></td>
                      <td>
                        {more !== staff.id ?
                          <span className="dost_btn" onClick={() => setMore(staff.id)}><i className="bi bi-three-dots-vertical"></i></span>
                          :
                          <span className="action" >
                              <CustomToolTip title="Edit">
                            <button
                              className="edit_btn"
                              onClick={() => {
                                    navigate(`/${currentUser}/edit-user/${staff?.id}`, { state: { key: staff.id } });
                              }}
                            >
                              <i className="bi bi-pen" />
                            </button>
                              </CustomToolTip>
                              <CustomToolTip title="Delete">
                            <button className="delete_btn_act" onClick={() => showForm(staff.id)}><i className="bi bi-trash"></i></button>
                              </CustomToolTip>
                            {/* <button className="exclamation_btn"><i className="bi bi-exclamation-circle"></i></button> */}
                            <button className="exclamation_btn position-relative" ><i className="bi bi-exclamation-circle " onClick={() => {
                              showPopup(!popup)
                            }}></i>
                              {/* {popup===true && */}
                              <ul className="drop_list m-0 p-0 bg-white p-2  text-left">
                                {/* <li><a href="#">Edit</a></li><li><a href="#">Delete</a></li> */}
                                <li><a>{staff.firstName}</a></li>

                                <li><a href="mailto:drethanbrown@mail.com">{staff.email}</a></li>
                                  <li><PhoneNumber phoneNumber={staff.phoneNumber} allowCall={staff.allowCall} /></li>
                                  <li>{staff.role?.roleName}</li>
                              </ul>
                              {/* } */}
                            </button>
                          </span>
                        }
                      </td>
                    </tr>
                  )
                })}
                </>
                :
                <tr className="list_detail">
                  <td colSpan={10}>
                    {!loading ? "No data found" : <Spinner />}
                  </td>
                </tr>
              }
              </tbody>
              {pages?.length > 1 &&
                <tfoot>
                <Pagination
                  pages={pages}
                  currentPage={pageNumber}
                  onClickPageNumber={(page: any) => {
                    setPageNumber(page);
                    getUsers(page);
                  }}
                />
                </tfoot>
              }
            </table>
          </div>
        <Modal show={show} onHide={() => handleClose()}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Staff</Modal.Title>
          </Modal.Header>
          <Modal.Body>Do you want delete this staff ?</Modal.Body>
          <Modal.Footer>
            <button className="bg_gray_500 rounded border-0 py-2 px-4" onClick={() => handleClose()}>
              Cancel
            </button>
            <button
              className="bg_gradiant text-white rounded border-0 py-2 px-4"
              onClick={() => {
                if (deleteId) {
                  handleDelete(deleteId);
                  setDeleteId('');
                  setRecordsToBeDeleted((prevState: any) => (prevState.filter((item: any) => item.id != deleteId)));
                } else {
                  recordsToBeDeleted.forEach((item: any) => {
                    handleDelete(item.id);
                  })
                }
                handleClose();
              }}>
              Delete
            </button>
          </Modal.Footer>
        </Modal>
      </Col>

    </Row>
  );
};
export default StaffForm;