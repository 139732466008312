import { useState, useRef, useEffect, useContext } from 'react';
import minimize from "../../../../assets/images/message/maximize.png";
import { useSearchParams } from 'react-router-dom';
import { getAppointments } from '../../../../services/scheduleappointment-service';
import { getOneBooking } from '../../../../services/scheduleappointment-service';
import { connect, createLocalVideoTrack, createLocalAudioTrack } from 'twilio-video'
import { RoleContext } from '../../../../utils/contexts/RoleContext';
import Participant from './Participant';
import { io } from "socket.io-client";
import { getCurrentUser } from "../../../../services/auth.service";
import { useNavigate } from "react-router-dom";
import Webcam from "react-webcam";
import { longDateGenerator } from "../../../../utils/utils";
import { saveMessage } from "../../../../services/messages-service";
import DeleteModal from '../../../../components/DeleteModal';
import FileRenderer from '../../../../components/FileRenderer';
import Timer from '../../../../components/Timer';
import { RailwayTimeGenerator } from '../../../../utils/utils';
import { getMessages, updateSeenStatus, getUnreadMessages } from '../../../../services/messages-service';
import { updateDuration } from '../../../../services/scheduleappointment-service';
import ImagePreview from '../../../../components/ImagePreview';
import { SOCKET_DOMAIN } from '../../../../config/constants';
import CustomAudioPlayer from '../../../../components/AudioPlayer';
import { getDateOnly } from '../../../../utils/utils';
import { socket } from '../../../../index';
import CustomToolTip from '../../../../components/CustomToolTip';

// const socket = io(SOCKET_DOMAIN, { transports: ['websocket'], path: "/my-custom-path/" });

const Video = require('twilio-video');

let interval = null;

export default function VideoCall(props) {
    const [mic, setMic] = useState(true);
    const [video, setVideo] = useState(true);
    const [tab, setTab] = useState('chats');
    const [room, setRoom] = useState(null);
    const [remoteParticipants, setRemoteParticipants] = useState([]);
    const [message, setMessage] = useState("");
    const [chats, setChats] = useState({});
    const [identity, setIdentity] = useState("");
    const [localVideo, setLocalVideo] = useState();
    const [showCam, setShowCam] = useState(true);
    const [appointmentDetails, setAppointmentDetails] = useState({});
    const [showPreview, setShowPreview] = useState(false);
    const [maximize, setMaximize] = useState(false);
    const [time, setTime] = useState(0);
    const [unReadMessages, setUnreadMessages] = useState({});
    const [previewData, setPreviewData] = useState({});
    const [unreadCount, setUnreadCount] = useState(0);
    const [duration, setDuration] = useState(0);
    const [audioSources, setAudioSources] = useState([]);
    const [selectedAudioSource, setSelectedAudiSource] = useState("");

    const unreadMessagesRef = useRef(null);

    const lobbyRef = useRef();
    const chatWindowRef = useRef(null);
    const currentUser = useContext(RoleContext);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();


    useEffect(() => {
        getBooking();
        // getAllMessages();
        return () => {
            clearInterval(interval);
        }
    }, []);

    useEffect(() => {
        socket.on(props.roomId, (data) => {
            let user = getCurrentUser();
            if (data.fromId != user.id) {
                if (maximize || unreadCount > 0) {
                    if (unReadMessages[data.sentDate] == undefined) {
                        setUnreadMessages((prevState: any) => ({ ...prevState, [data.sentDate]: [data] }))
                    } else {
                        setUnreadMessages((prevState: any) => ({
                            ...prevState,
                            [data.sentDate]: [...prevState[data.sentDate], data],
                        }));
                    }
                    setUnreadCount(unreadCount + 1);
                } else {
                    if (chats[data.sentDate] == undefined) {
                        setChats((prevState: any) => ({ ...prevState, [data.sentDate]: [data] }))
                    } else {
                        setChats((prevState: any) => ({
                            ...prevState,
                            [data.sentDate]: [...prevState[data.sentDate], data],
                        }));
                    }
                }
                handleAcknowledgement(
                    data,
                    !maximize && tab === "chats" ? 2 : 1
                );
            }
        });

        return () => {
            socket.off(props.roomId)
        }
    }, [tab, maximize, unReadMessages, unreadCount, chats]);

    function handleAcknowledgement(data: any, readStatus: number) {
        socket.emit("message_received", {
            chatId: data.chatId,
            readStatus,
            roomId: data.roomId,
            acknowledgeId: getCurrentUser()?.id,
            sentDate: data.sentDate,
        });
    }

    useEffect(() => {
        let user = getCurrentUser();
        socket.on(`chat_receive_${props.roomId}`, (data) => {
            if (data.acknowledgeId != user.id) {
                updateReadStatus(data.chatId, data.readStatus);
                const messagesObj = { ...chats };
                const messagesArr = [...messagesObj[data.sentDate]];
                messagesObj[data.sentDate] = messagesArr.map((item: any) => {
                    const chatObj = { ...item };
                    if (item.chatId == data.chatId) {
                        chatObj.readStatus = data.readStatus;
                    }
                    return chatObj;
                });
                setChats(messagesObj);
            }
        });
        return () => {
            socket.off(`chat_receive_${props.roomId}`)
        }
    }, [chats]);

    useEffect(() => {
        if (chatWindowRef.current) {
            chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
        }
    }, [chats, maximize, unReadMessages, room])

    async function getAllMessages() {
        let user = getCurrentUser();
        const response = await getMessages(props.roomId);
        const readMessages: any = [];
        const messagesObj = { ...response.data };
        Object.keys(messagesObj).forEach((key: any) => {
            const obj: any = {
                date: key,
                messages: [],
            };
            const messgaesArr: any = [];
            messagesObj[key].forEach((item: any) => {
                if (item.message === null) {
                    item.source = item.message_document?.source;
                    item.fileName = item.message_document?.fileName;
                    item.ext = item.message_document?.extension;
                    item.size = item.message_document?.size;
                }
                if (item.fromId == user.id) {
                    item.pos = 0;
                } else {
                    item.pos = 1;
                }
                messgaesArr.push(item);
            });
            messagesObj[key] = messgaesArr;
        });
        setChats(messagesObj);
    }

    async function getAllUnreadMessages() {
        let user = getCurrentUser();
        const unreadMessages: any = [];
        const response = await getUnreadMessages(props.roomId);
        const messagesObj = { ...response.data };
        Object.keys(messagesObj).forEach((key: any) => {
            const messgaesArr: any = [];
            messagesObj[key].forEach((item: any) => {
                if (item.message === null) {
                    item.source = item.message_document?.source;
                    item.fileName = item.message_document?.fileName;
                    item.ext = item.message_document?.extension;
                    item.size = item.message_document?.size;
                }
                if (item.fromId != user.id) {
                    item.pos = 1;
                    updateReadStatus(item.chatId, 2);
                    socket.emit("message_received", {
                        chatId: item.chatId,
                        readStatus: 2,
                        roomId: props.roomId,
                        acknowledgeId: user.id,
                        sentDate: item.sentDate,
                    });
                } else {
                    item.pos = 0;
                }
                messgaesArr.push(item);
            });
            messagesObj[key] = messgaesArr;
        });
        setUnreadCount(response.count);
        setUnreadMessages(messagesObj);
    }


    // useEffect(() => {
    //     setTimeout(() => {
    //         window.scrollTo(0, unreadMessagesRef.current?.offsetTop);
    //     }, 3000)
    //     // setUnreadMessages([]);
    // },[unreadMessagesRef.current,maximize])

    // useEffect(() => {
    //     setTimeout(() => {
    //         chatWindowRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    //     },5000)
    // }, [chatWindowRef.current, room, tab]);

    // async function getAllUnreadMessages() {
    //     let user = getCurrentUser();
    //     const response = await getUnreadMessages(props.roomId);
    //     response.data.forEach((item) => {
    //         if (item.fromId != user.id) {
    //             // item.pos = 0;
    //             updateReadStatus(item.chatId, 2);
    //             socket.emit("message_received", {
    //                 chatId: item.chatId,
    //                 readStatus: 2,
    //                 roomId: props.roomId,
    //                 acknowledgeId: user.id
    //             });
    //         }
    //         // arr.push(item);
    //     });
    //     // console.log("unread", response)
    // }



    async function updateReadStatus(id, status) {
        const response = await updateSeenStatus(id, status);
        console.log("res", response);
    }

    // async function getAllMessages(chat) {
    //     console.log("check chat", chat);
    //     let user = getCurrentUser();
    //     const response = await getMessages(props.roomId);
    //     const readMessages = [];
    //     const unReadMessages = [];
    //     response.data.forEach((item) => {
    //         if (item.message === null) {
    //             item.source = item.message_document?.source;
    //             item.fileName = item.message_document?.fileName;
    //             item.ext = item.message_document?.extension;
    //             item.size = item.message_document?.size
    //         }
    //         if (item.fromId == user.id) {
    //             item.pos = 0;
    //             readMessages.push(item);
    //         } else {
    //             item.pos = 1;
    //             if (item.readStatus == 0) {
    //                 updateReadStatus(item.chatId, 1);
    //                 socket.emit("message_received", {
    //                     chatId: item.chatId,
    //                     readStatus: 1,
    //                     roomId: props.roomId,
    //                     acknowledgeId: user.id
    //                 });
    //                 unReadMessages.push(item);
    //             } else {
    //                 readMessages.push(item);
    //             }
    //         }
    //     });
    //     setChats(readMessages);
    //     setUnreadMessages(unReadMessages);
    // }



    async function getBooking() {
        const response = await getOneBooking(props.appointmentId);
        const appointmentDetails = { ...response?.data }
        const longDate = longDateGenerator(appointmentDetails?.date)
        // const dateString = new Date(appointmentDetails?.date);
        // const longDate = dateString.getDate() + " " + months[dateString.getMonth()] + ", " + dateString.getFullYear();
        appointmentDetails.longDate = longDate;
        setAppointmentDetails(appointmentDetails);
        setDuration(response?.duration);
        let identity = currentUser === "patient" ? response?.data?.patientName : response?.data?.doctorName;
        setIdentity(identity);
        // addLocalTracks();
        // joinRoom(identity)
    }


    async function updateMeetingTime() {
        let user = getCurrentUser();
        if (user.id == appointmentDetails.doctorId) {
            const response = await updateDuration(props.roomId, time);
            console.log("res", response);
        }
    }


    function validateDateTime() {
        // const splittedTime = appointmentDetails.time.split(":");
        // const dateString = new Date(appointmentDetails.date)
        // dateString.setHours(splittedTime[0]);
        // dateString.setMinutes(splittedTime[1]);
        // const appointmentDate = new Date(dateString);
        // const startingTime = appointmentDate.getTime();
        // const endingTime = startingTime + (Number(duration) * 60 * 1000);
        // if (new Date().getTime() > startingTime && new Date().getTime() < endingTime) {
        //     joinRoom();
        //     getAllMessages();
        //     interval = setInterval(() => {
        //         setTime((time) => time + 1000)
        //     }, 1000);
        // }
        // else {
        //     if (new Date().getTime() < startingTime) {
        //         alert('Your meeting is not started yet. Come back later')
        //     } else if (new Date().getTime() > endingTime) {
        //         alert('Meeting has been ended')
        //     }
        //     navigate(`/${currentUser}/tele-detail/${props.roomId}`);
        // }

        joinRoom();
        getAllMessages();
        getAllUnreadMessages();
        interval = setInterval(() => {
            setTime((time) => time + 1000)
        }, 1000);
    }

    async function joinRoom() {
        try {
            const response = await fetch(`https://token-service-7278-dev.twil.io/token?identity=${identity}&room=${props.roomId}`);
            const data = await response.json();
            const room = await connect(data.accessToken, {
                name: props.roomId,
                audio: true,
                video: true
            });
            room.on('participantConnected', participant => addParticipant(participant));
            room.on('participantDisconnected', participant => removeParticipant(participant));
            console.log("room", Array.from(room?.localParticipant?.audioTracks?.values())[0]?.track?._currentDefaultDeviceInfo?.deviceId);
            setSelectedAudiSource(Array.from(room?.localParticipant?.audioTracks?.values())[0]?.track?._currentDefaultDeviceInfo?.deviceId)
            setRoom(room);
            getAvailableAudioSources();
            setRemoteParticipants(Array.from(room.participants.values()))
        } catch (err) {
            console.log(err);
        }
    }

    async function getAvailableAudioSources() {
        const allAudioSources = (await navigator.mediaDevices.enumerateDevices()).filter((item) => item.kind === "audioinput");
        setAudioSources(allAudioSources.reverse())
    }

    function leaveRoom() {
        room.disconnect();
        setRoom(null);
    }


    function addParticipant(participant) {
        console.log(`${participant.identity} has joined the room.`);
        setRemoteParticipants((previousState) => {
            return (
                [...previousState, participant]
            )
        })
    }

    function removeParticipant(participant) {
        console.log(`${participant.identity} has left the room`);
        setRemoteParticipants(remoteParticipants.filter(p => p.identity !== participant.identity))
    }




    function audioMuteUnmute() {
        if (!mic) {
            room.localParticipant.audioTracks.forEach(track => {
                track.track.enable();
            });
            setMic(true)
        } else {
            room.localParticipant.audioTracks.forEach(track => {
                track.track.disable();
            });
            setMic(false)
        }
        setMic(!mic)
    }

    function videoMuteUnmute() {
        if (!video) {
            room.localParticipant.videoTracks.forEach(track => {
                track.track.enable();
            });
        } else {
            room.localParticipant.videoTracks.forEach(track => {
                track.track.disable();
            });
        }
        setVideo(!video)
    }

    function handleTabs(tab) {
        setTab(tab)
    }

    // function handleSendMessage() {
    //     if (message.trim()) {
    //         const messageObj = {
    //             message,
    //             roomId: props.roomId,
    //             pos: 0,
    //             fromId: Number(currentUser === "patient" ? appointmentDetails.patientId : appointmentDetails.doctorId),
    //             toId: Number(currentUser === "patient" ? appointmentDetails.doctorId : appointmentDetails.patientId),
    //             messageType: "text",
    //             sentTime: RailwayTimeGenerator(),
    //             readStatus: 0,
    //             chatId: Date.now()
    //         }
    //         saveOneMessage(messageObj);
    //         if (unReadMessages.length > 0) {
    //             const readMessages = [...chats];
    //             const unreadMessages = [...unReadMessages];
    //             setChats((prevState) => prevState.concat(unreadMessages));
    //             setUnreadMessages([]);
    //         }
    //         setChats((prevState) => [...prevState, messageObj]);
    //         socket.emit('message', {
    //             socketID: socket.id,
    //             ...messageObj
    //         });
    //     }
    //     setMessage("");
    // }



    // const handleFileUpload = (post) => {
    //     // console.log("check", post.target.files[0])
    //     let files = [];
    //     if (post.target.files && post.target.files.length > 0) {
    //         for (let i = 0; i < post.target.files.length; i++) {
    //             let file = post.target.files[i];
    //             let type = "file";
    //             if (file.type.split("/")[0] === "image") {
    //                 type = "image"
    //             }
    //             const fileName = file.name;
    //             let reader = new FileReader();
    //             const fileExt =
    //                 fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length) ||
    //                 fileName;
    //             if (file) {
    //                 reader.readAsDataURL(file);
    //                 reader.onloadend = () => {
    //                     const fileObj = {
    //                         fileSource: file,
    //                         source: reader.result,
    //                         fileType: file.type,
    //                         fileName: file.name,
    //                         size: file.size,
    //                         ext: fileExt,
    //                         message: null,
    //                         roomId: props.roomId,
    //                         pos: 0,
    //                         fromId: Number(currentUser === "patient" ? appointmentDetails.patientId : appointmentDetails.doctorId),
    //                         toId: Number(currentUser === "patient" ? appointmentDetails.doctorId : appointmentDetails.patientId),
    //                         messageType: type,
    //                         sentTime: RailwayTimeGenerator(),
    //                         readStatus: 0,
    //                         chatId: Date.now()
    //                     }
    //                     if (unReadMessages.length > 0) {
    //                         const readMessages = [...chats];
    //                         const unreadMessages = [...unReadMessages];
    //                         setChats((prevState) => prevState.concat(unreadMessages));
    //                         setUnreadMessages([]);
    //                     }
    //                     setChats((prevState) => ([...prevState, fileObj]));
    //                     let fileObjCopy = { ...fileObj }
    //                     fileObjCopy.source = ""
    //                     handleEmit(fileObjCopy);
    //                 };
    //             }
    //         }
    //     }
    // };

    // function handleSendMessage() {
    //     let todayDate = getDateOnly(new Date());
    //     if (message.trim()) {
    //         const messageObj = {
    //             message,
    //             roomId: props.roomId,
    //             pos: 0,
    //             fromId: Number(currentUser === "patient" ? appointmentDetails.patientId : appointmentDetails.doctorId),
    //             toId: Number(currentUser === "patient" ? appointmentDetails.doctorId : appointmentDetails.patientId),
    //             messageType: "text",
    //             sentTime: RailwayTimeGenerator(),
    //             sentDate: getDateOnly(new Date()),
    //             readStatus: 0,
    //             chatId: Date.now(),
    //         };
    //         saveOneMessage(messageObj);
    //         if (unreadCount > 0) {
    //             const unReadMessagesObj = { ...unReadMessages };
    //             const readMessagesObj = { ...chats };
    //             for (let key in unReadMessagesObj) {
    //                 if (readMessagesObj[key] === undefined) {
    //                     readMessagesObj[key] = [
    //                         ...unReadMessagesObj[key],
    //                     ];

    //                 } else {
    //                     readMessagesObj[key] = [
    //                         ...readMessagesObj[key],
    //                         ...unReadMessagesObj[key],
    //                     ];
    //                 }
    //             }
    //             setChats(readMessagesObj);
    //             setUnreadMessages({});
    //             setUnreadCount(0);
    //         }
    //         handleLocalUpdate(messageObj)
    //         handleEmit(messageObj);
    //     }
    //     setMessage("");
    // }

    function handleSendMessage() {
        if (message.trim()) {
            const messageObj = {
                message,
                roomId: props.roomId,
                pos: 0,
                fromId: Number(currentUser === "patient" ? appointmentDetails.patientId : appointmentDetails.doctorId),
                toId: Number(currentUser === "patient" ? appointmentDetails.doctorId : appointmentDetails.patientId),
                messageType: "text",
                sentTime: RailwayTimeGenerator(),
                sentDate: getDateOnly(new Date()),
                readStatus: 0,
                chatId: Date.now(),
            };
            saveOneMessage(messageObj);
            handleMessageStructure(messageObj);
            handleEmit(messageObj);
        }
        setMessage("");
    }

    function handleLocalUpdate(messageObj) {
        let todayDate = getDateOnly(new Date());
        if (chats[todayDate] == undefined) {
            setChats((prevState) => ({ ...prevState, [todayDate]: [messageObj] }))
        } else {
            setChats((prevState) => ({
                ...prevState,
                [todayDate]: [...prevState[todayDate], messageObj],
            }));
        }
    }

    const handleFileUpload = (post: any) => {
        let todayDate = getDateOnly(new Date());
        let files = [];
        if (post.target.files && post.target.files.length > 0) {
            for (let i = 0; i < post.target.files.length; i++) {
                let file = post.target.files[i];
                let type = "file";
                if (file.type.split("/")[0] === "image") {
                    type = "image";
                }
                const fileName = file.name;
                let reader = new FileReader();
                const fileExt =
                    fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length) ||
                    fileName;
                if (file) {
                    reader.readAsDataURL(file);
                    reader.onloadend = () => {
                        const fileObj = {
                            fileSource: file,
                            source: reader.result,
                            fileType: file.type,
                            fileName: file.name,
                            size: file.size,
                            ext: fileExt,
                            message: null,
                            roomId: props.roomId,
                            pos: 0,
                            fromId: Number(currentUser === "patient" ? appointmentDetails.patientId : appointmentDetails.doctorId),
                            toId: Number(currentUser === "patient" ? appointmentDetails.doctorId : appointmentDetails.patientId),
                            messageType: type,
                            sentTime: RailwayTimeGenerator(),
                            sentDate: getDateOnly(new Date()),
                            readStatus: 0,
                            chatId: Date.now(),
                        };
                        handleMessageStructure(fileObj);
                        let fileObjCopy = { ...fileObj };
                        fileObjCopy.source = "";
                        handleEmit(fileObjCopy);
                    };
                }
            }
        }
    };

    function handleMessageStructure(messageObj: any) {
        if (unreadCount > 0) {
            const unReadMessagesObj = { ...unReadMessages };
            const readMessagesObj = { ...chats };
            for (let key in unReadMessagesObj) {
                if (readMessagesObj[key] === undefined) {
                    readMessagesObj[key] = [...unReadMessagesObj[key], messageObj];
                } else {
                    readMessagesObj[key] = [...readMessagesObj[key], ...unReadMessagesObj[key], messageObj];
                }
            }
            setChats(readMessagesObj);
            setUnreadMessages({});
            setUnreadCount(0);
        } else {
            handleLocalUpdate(messageObj)
        }
    }

    function handleEmit(messageObj) {
        socket.emit('message', {
            socketID: socket.id,
            ...messageObj
        });
    }

    async function saveOneMessage(payload) {
        const response = await saveMessage(payload);
        console.log("res", response);
    }

    function exitMeeting() {
        setShowCam(false);
        navigate(`/${currentUser}/tele-detail?room=${props.roomId}`);
    }

    async function handleAudioChange(e) {
        const { value } = e.target;
        const audioSource = audioSources.find((item) => item.deviceId === e.target.value);
        console.log("audioSource", audioSource)
        setSelectedAudiSource(value)
        console.log(value);

        const track = await createLocalAudioTrack({ deviceId: { exact: value } });
        room.localParticipant.audioTracks.forEach(publication => {
            publication.track.stop();
            room.localParticipant.unpublishTrack(publication.track);
        });
        room.localParticipant.publishTrack(track);
    }

    return (
        room !== null ?
            <div className="add_location col-sm-12 py-4 px-lg-2 mt-2">
                <div className="row m-0 justify-content-center">
                    <div className={`${maximize ? "col-xl-11" : "col-xl-8"} p-1 pb-2 radius_15 chat_box_animation`}>
                        <div className="float-start d-flex w-full">
                            <button className="border-0 fs-4  px-2 rounded-3 bg_green text-white" type="button"><i className="bi bi-camera-video-fill px-1"></i></button>
                            <div className="px-2 d-inline-block">
                                <span className="d-block text_sm text_gray text_sm ">{appointmentDetails.longDate}</span>
                                <p className="m-0 poppins-500 dark_blue text_base">{appointmentDetails.patientName + " " + appointmentDetails.reason} Consultation</p>
                            </div>
                        </div>
                        <div className="video_chat float-start w-100 box_shadow mt-3 radius_15">
                            <div className='radius_15'>
                                {room !== null &&
                                    <>
                                        <Participant key={room?.localParticipant.identity} localParticipant="true" participant={room?.localParticipant} remoteParticipants={remoteParticipants.length} />
                                        {
                                            remoteParticipants.map(participant => {
                                                return (
                                                    <Participant key={participant.identity} participant={participant} />
                                                )
                                            })
                                        }
                                        <div className="minimize_btn position-absolute top-0 px-4 pt-4 w-100">
                                            <div className="time_record float-start text-white px-4 py-1 radius_15 d-flex align-items-center gap-1 mx-4">
                                                <span className="red_dot d-inline-block bg-danger p-1 border-0 rounded-circle"></span>
                                                <Timer
                                                    time={time}
                                                // timeSetter={setTime((time) => time + 1000)}
                                                />
                                            </div>

                                            {!maximize ?
                                                <button
                                                    className="minimize_btn mx-2 float-end border-0 bg-transparent p-0"
                                                    onClick={() => setMaximize(true)}
                                                >
                                                    <img src={minimize} />
                                                </button>
                                                :
                                                <button
                                                    className="minimize_btn mx-2 float-end border-0 bg-transparent p-0 color-white"
                                                    onClick={() => setMaximize(false)}

                                                >
                                                    <div className='vidoe_button'>
                                                        <button className='video_full_screen'><i class="bi bi-fullscreen-exit"></i></button>
                                                    </div>
                                                </button>
                                            }
                                        </div>
                                        <div className="vidoe_button position-absolute bottom-0 w-100 text-center d-flex justify-content-center align-items-center mb-4">
                                            {video ?
                                                <button
                                                    className="video_call"
                                                    onClick={() => {
                                                        videoMuteUnmute();
                                                    }}
                                                >
                                                    <i className="bi bi-camera-video" />
                                                </button>
                                                :
                                                <button
                                                    className="video_call"
                                                    onClick={videoMuteUnmute}
                                                >
                                                    <i className="bi bi-camera-video-off" />
                                                </button>
                                            }
                                            <button
                                                className="call_phone call_attend"
                                                onClick={() => {
                                                    leaveRoom()
                                                    updateMeetingTime();
                                                    clearInterval(interval);
                                                    setTime(0);
                                                    setUnreadMessages([]);
                                                }}
                                            >
                                                <i className="bi bi-telephone-fill"></i>
                                            </button>
                                            {mic ?
                                                <button
                                                    className="voice_call"
                                                    onClick={() => {
                                                        audioMuteUnmute();
                                                    }}
                                                >
                                                    <i className="bi bi-mic" />
                                                </button>
                                                :
                                                <button
                                                    className="voice_call"
                                                    onClick={() => {
                                                        audioMuteUnmute();
                                                    }}
                                                >
                                                    <i className="bi bi-mic-mute-fill" />
                                                </button>
                                            }
                                            <select
                                                className='w-25'
                                                value={selectedAudioSource}
                                                onChange={handleAudioChange}
                                            >
                                                {audioSources.map((item) => {
                                                    return (
                                                        <option value={item.deviceId}>{item.label}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    {room !== null &&
                        <div className={`${maximize ? "col-xl-1" : "col-xl-4"} p-1 pb-2 mt-4 pt-3 chat_box_animation`}>
                            <div className={`video_chat_box inner_location float-start w-100 bg-white box_shadow p-4 ${maximize && "px-2"} pb-0 radius_15 mt-4`}>
                                {!maximize ?
                                    <>
                                        <div className="float-start w-100">
                                            <span className="close pb-3   fs-5 d-block dark_blue poppins-600 pt-2">
                                                Meeting Details
                                                <i className="bi bi-x-circle-fill float-end text_gray px-2 clickable"
                                                    onClick={() => {
                                                        setMaximize(true);
                                                    }}
                                                />
                                            </span>
                                        </div>
                                        <div className="chat_tab">
                                            <div className="bg_gray_500 float-start w-100 py-2 px-2 d-flex gap-2 rounded-4">
                                                <button
                                                    className={`${tab === "chats" && "active"} w-50 border-0 py-2  rounded-3 dark_blue  poppins-500`}
                                                    type="button"
                                                    onClick={() => {
                                                        handleTabs('chats');
                                                        getAllUnreadMessages();
                                                        getAllMessages();
                                                        // setUnreadMessages([]);
                                                    }}
                                                >
                                                    Chat
                                                </button>
                                                <button
                                                    className={`${tab === "participants" && "active"} w-50 border-0 py-2  rounded-3 dark_blue  poppins-500`}
                                                    type="button"
                                                    onClick={() => handleTabs('participants')}
                                                >
                                                    Participants
                                                </button>
                                            </div>
                                        </div>
                                        {tab === "chats" ?
                                            <>
                                                <div className="tele_messgage custom_scroll float-start w-100 py-4" id="chat-window" ref={chatWindowRef}>
                                                    <div className='read-messages'>
                                                        {Object.keys(chats).map((key: any) => {
                                                            return (
                                                                <>
                                                                    <div
                                                                        className="d-flex justify-content-center"
                                                                        style={{ clear: "both" }}
                                                                    >
                                                                        {key}
                                                                    </div>
                                                                    {chats[key].map((item: any) => {
                                                                        return (
                                                                            <div
                                                                                className={`col-sm-10 ${item.pos == "0"
                                                                                    ? "float-end"
                                                                                    : "float-start"
                                                                                    } `}
                                                                            >
                                                                                <div
                                                                                    className={`${item.pos == "0"
                                                                                        ? "chat_send  float-end bg_light_green"
                                                                                        : "float-start  chat_receive  bg_gray_500"
                                                                                        } px-3 py-2 my-1`}
                                                                                >
                                                                                    {item.messageType === "text" ? (
                                                                                        <p className="m-0 text_14 dark_blue">
                                                                                            {item.message}
                                                                                        </p>
                                                                                    ) : item.messageType === "image" ? (
                                                                                        <img
                                                                                            src={item.source}
                                                                                            alt="image"
                                                                                            style={{
                                                                                                width: "100px",
                                                                                                height: "100px",
                                                                                            }}
                                                                                            className="d-block clickable"
                                                                                            onClick={() => {
                                                                                                setPreviewData(item);
                                                                                                setShowPreview(true);
                                                                                            }}
                                                                                        />
                                                                                    ) : item.messageType === "file" ? (
                                                                                        <FileRenderer
                                                                                            fileData={item}
                                                                                            pos={item.pos}
                                                                                        />
                                                                                    ) : (
                                                                                        <CustomAudioPlayer
                                                                                            source={item.source}
                                                                                        />
                                                                                    )}
                                                                                    <span className="float-end px-1 text_sm text_gray">
                                                                                        {item.sentTime}
                                                                                    </span>
                                                                                    {item.pos == "0" && (
                                                                                        <span
                                                                                            className={`check_doubt d-block float-end text_14 ${item.readStatus == 2 &&
                                                                                                "textgreen"
                                                                                                }  text-center`}
                                                                                        >
                                                                                            {item.readStatus == 1 ||
                                                                                                item.readStatus == 2 ? (
                                                                                                <i className="bi bi-check2-all"></i>
                                                                                            ) : (
                                                                                                <i className="bi bi-check2"></i>
                                                                                            )}
                                                                                        </span>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </>
                                                            );
                                                        })}
                                                        {/* {chats.map((item) => {
                                                            return (
                                                                <div className={`col-sm-10 ${item.pos == "0" ? "float-end" : "float-start"} `}>
                                                                    <div className={`${item.pos == "0" ? "chat_send  float-end bg_light_green" : "float-start  chat_receive  bg_gray_500"} px-3 py-2 my-1`}>
                                                                        {item.messageType === "text" ?
                                                                            <p className="m-0 text_14 dark_blue">{item.message}</p>
                                                                            :
                                                                            item.messageType === "image" ?
                                                                                <img
                                                                                    src={item.source}
                                                                                    alt="image"
                                                                                    style={{ width: "100px", height: "100px" }}
                                                                                    className="d-block clickable"
                                                                                    onClick={() => {
                                                                                        setPreviewData(item);
                                                                                        setShowPreview(true)
                                                                                    }}
                                                                                />
                                                                                :
                                                                                <FileRenderer
                                                                                    fileData={item}
                                                                                    pos={item.pos}
                                                                                />
                                                                        }
                                                                        <span className="float-end px-1 text_sm text_gray">{item.sentTime}</span>
                                                                        {item.pos == "0" &&
                                                                            <span className={`check_doubt d-block float-end text_14 ${item.readStatus == 2 && "textgreen"}  text-center`}>
                                                                                {item.readStatus == 1 || item.readStatus == 2 ?
                                                                                    <i className="bi bi-check2-all"></i>
                                                                                    :
                                                                                    <i class="bi bi-check2"></i>
                                                                                }
                                                                            </span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })} */}
                                                    </div>
                                                    {unreadCount > 0 && (
                                                        <div
                                                            className="unread-messages"
                                                            style={{ clear: "both" }}
                                                            id="unread-messages"
                                                            ref={unreadMessagesRef}
                                                        >
                                                            <div
                                                                style={{ textAlign: "center", fontSize: "12px" }}
                                                            >
                                                                {unreadCount} Unread Messages
                                                            </div>
                                                            {Object.keys(unReadMessages).map((key: any) => {
                                                                return (
                                                                    <>
                                                                        {unReadMessages[key].map((item: any) => {
                                                                            return (
                                                                                <div
                                                                                    className={`col-sm-10 ${item.pos == "0"
                                                                                        ? "float-end"
                                                                                        : "float-start"
                                                                                        } `}
                                                                                >
                                                                                    <div
                                                                                        className={`${item.pos == "0"
                                                                                            ? "chat_send  float-end bg_light_green"
                                                                                            : "float-start  chat_receive  bg_gray_500"
                                                                                            } px-3 py-2 my-1`}
                                                                                    >
                                                                                        {item.messageType === "text" ? (
                                                                                            <p className="m-0 text_14 dark_blue">
                                                                                                {item.message}
                                                                                            </p>
                                                                                        ) : item.messageType === "image" ? (
                                                                                            <img
                                                                                                src={item.source}
                                                                                                alt="image"
                                                                                                style={{
                                                                                                    width: "100px",
                                                                                                    height: "100px",
                                                                                                }}
                                                                                                className="d-block clickable"
                                                                                                onClick={() => {
                                                                                                    setPreviewData(item);
                                                                                                    setShowPreview(true);
                                                                                                }}
                                                                                            />
                                                                                        ) : item.messageType === "file" ? (
                                                                                            <FileRenderer
                                                                                                fileData={item}
                                                                                                pos={item.pos}
                                                                                            />
                                                                                        ) : (
                                                                                            <CustomAudioPlayer
                                                                                                source={item.audioSource}
                                                                                            />
                                                                                        )}
                                                                                        <span className="float-end px-1 text_sm text_gray">
                                                                                            {item.sentTime}
                                                                                        </span>
                                                                                        {item.pos == "0" && (
                                                                                            <span
                                                                                                className={`check_doubt d-block float-end text_14 ${item.readStatus == 2 &&
                                                                                                    "textgreen"
                                                                                                    }  text-center`}
                                                                                            >
                                                                                                {item.readStatus == 1 ||
                                                                                                    item.readStatus == 2 ? (
                                                                                                    <i className="bi bi-check2-all"></i>
                                                                                                ) : (
                                                                                                    <i className="bi bi-check2"></i>
                                                                                                )}
                                                                                            </span>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </>
                                                                );
                                                            })}
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="type_message d-flex mt-3  float-start w-100 px-2 py-3 border-top m-0">
                                                    <div className="col-sm-9 px-0 type_message_input">
                                                        <textarea
                                                            className="border-0 bg-white text_gray w-100 py-2 px-2 rounded-5 outline-none  form-control"
                                                            type="text"
                                                            id="type_message"
                                                            placeholder="Type a message"
                                                            value={message}
                                                            onChange={(e) => setMessage(e.target.value)}
                                                            onKeyDown={(e) => {
                                                                if (e.key === "Enter" && !e.shiftKey) {
                                                                    e.preventDefault()
                                                                    handleSendMessage()
                                                                }
                                                            }}
                                                            style={{ resize: "none", overflow: "hidden" }}
                                                        />
                                                    </div>
                                                    <div className="col-sm-3 d-flex p-0 fs-4  justify-content-end other_icons text-end">
                                                        <button
                                                            className="border-0 bg-white textgreen"
                                                            type="button"
                                                        >
                                                            <input
                                                                hidden
                                                                type="file"
                                                                onChange={handleFileUpload}
                                                                id="upload"
                                                            // multiple
                                                            />
                                                            <label className="" htmlFor="upload"><span className="clickable"><i className="bi bi-paperclip" /></span></label>


                                                        </button>
                                                        <button
                                                            className="border-0 bg-white textgreen"
                                                            type="button"
                                                            onClick={() => handleSendMessage()}
                                                        >
                                                            <i className="bi bi-telegram"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <div className="participate float-start w-100 p-4 px-2">
                                                <ul>
                                                    <li
                                                        className="d-block py-2 px-4 bg_gray_500 rounded mb-2 "
                                                    >Me ({room?.localParticipant?.identity})</li>
                                                    {remoteParticipants.map((item) => <li
                                                        className="d-block py-2 px-4 bg_gray_500 rounded mb-2 clickable"
                                                        onClick={() => handleTabs('chats')}
                                                    >{item.identity}</li>)}
                                                </ul>
                                            </div>
                                        }
                                    </>
                                    :
                                    <div>
                                        <div className='col-sm-12 text-center pb-4'>
                                            <span
                                                title="Chats"
                                                className='d-inline-block text-center px-2 bg_green text-white rounded box_shadow clickable'
                                                onClick={() => {
                                                    handleTabs('chats');
                                                    setMaximize(false);
                                                    getAllUnreadMessages();
                                                    getAllMessages();
                                                    // setUnreadMessages([]);
                                                }}
                                            >
                                                <i class="bi bi-chat-text fs-4" />
                                                <span className="badge" style={{
                                                    position: 'absolute', top: '-6px', right: '-6px', background: 'grey', color: 'white', borderRadius: '50%', padding: '2px 6px', fontSize: '12px', width: '18px',
                                                    height: '18px'
                                                }}>
                                                    {unreadCount}
                                                </span>
                                            </span>
                                            <p className='m-0 text_sm dark_blue mb-2 text-center mt-1'>Chat</p>
                                            <span
                                                title="Participants"
                                                className='d-inline-block text-center px-2 bg_green text-white rounded box_shadow clickable'
                                                onClick={() => {
                                                    handleTabs('participants');
                                                    setMaximize(false)
                                                }}
                                            >
                                                <i class="bi bi-person fs-4" />
                                            </span>
                                            <p className='m-0 text_12 dark_blue mt-1 text-center text_sm text-break'>participants</p>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
                <ImagePreview
                    data={previewData}
                    show={showPreview}
                    handleClose={() => {
                        setShowPreview(false);
                        setPreviewData({});
                    }}
                />
            </div>
            :
            showCam ?
                <div id="lobby" ref={lobbyRef} className="position-relative">
                    <Webcam
                        audio={false}
                        height={720}
                        // screenshotFormat="image/jpeg"
                        width={1280}
                    // videoConstraints={videoConstraints}
                    />
                    <div className="vidoe_button position-absolute bottom-0 d-flex justify-content-center w-100 align-items-center mb-4 z-1">
                        <CustomToolTip title="Connect" placement="top">
                        <button
                            className="call_phone"
                            style={{ backgroundColor: 'green' }}
                            onClick={validateDateTime}
                        >
                            <i className="bi bi-telephone-fill"></i>
                        </button>
                        </CustomToolTip>
                        {/* <button
                            className="call_phone call_attend"
                            onClick={() => exitMeeting()}
                            style={{ backgroundColor: 'red' }}
                        >
                            <i className="bi bi-telephone-fill"></i>
                        </button> */}
                    </div>
                </div>
                :
                <></>

    );
}
