import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import CustomCarousel from "../../components/Carousel";
import { getDateOnly } from "../../utils/utils";
import 'pure-react-carousel/dist/react-carousel.es.css';


import {
  Row,
  Col,
} from "reactstrap";

import { useNavigate } from "react-router-dom";
import { RoleContext, AlertContext } from "../../utils/contexts/RoleContext";
import { useContext, useState, useEffect, useRef } from 'react';
import { getLocations, deleteLocation } from "../../services/location-service";
import DeleteModal from "../../components/DeleteModal";
import Pagination from "../../components/Pagination";
import { decidePages } from "../../utils/utils";
import './StaffDashboard.css';
import { getAllBookingAppointment } from "../../services/scheduleappointment-service";
import { getChatUsers } from "../../services/messages-service";

import Chart from 'chart.js/auto'
import { getallpatient } from "../../services/patient-service";
import { metaTitle } from '../../config/constants';
import { getAllRefillRequest } from '../../services/refillRequest-service';
import CustomToolTip from '../../components/CustomToolTip';
import { Spinner } from 'react-bootstrap';
import SearchInput from '../../components/SearchInput';



interface Appointment {
  date: string;
}
type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];


const StaffDashboard = () => {
  const [view, setView] = useState('grid');
  const [limit, setLimit] = useState(10);
  const [locations, setLocations] = useState([]);
  const [more, setMore] = useState('');
  const [deleteId, setDeleteId] = useState("");
  const [showsearch, setShowSearch] = useState(false);
  const [search, setSearch] = useState('');
  const [locationsCopy, setLocationsCopy] = useState([]);
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [pages, setPages] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState(1)
  const [allBooking, setAllBooking] = useState<any[]>([])
  const [allBookingCopy, setAllBookingCopy] = useState<any[]>([])
  const [selectedDates, setSelectedDates] = useState<any>([]);
  const [chatUsers, setChatUsers] = useState<any>([]);
  const [refillRequests, setRefillRequests] = useState([]);
  const [rowData, setRowData] = useState([]);


  // const [selectedDates, setSelectedDates] = useState<Date>([]);





  const navigate = useNavigate();
  const currentUser = useContext(RoleContext);
  const toast: any = useContext(AlertContext);

  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstance = useRef<Chart | null>(null);


  const settings = {
    dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    arrows: false,
  };

  useEffect(() => {
    getHospitalLocations(pageNumber);
    setPageNumber(1);
    getBookingData();
    fetchChatUsers();
    getRefillRequests();
  }, [view]);

  async function getRefillRequests() {
    const response = await getAllRefillRequest();
    if (response?.success) {
      const refilRequests = response?.allrefill?.rows?.slice(0, 8);
      refilRequests?.forEach((refillItem: any) => {
        let medications = "";
        const medicationArray = refillItem.refill_medications
        medicationArray.forEach((item: any, index: number) => {
          medications += `${item.medication}${index == medicationArray?.length - 1 ? "" : ", "}`
        });
        refillItem.medication = medications;
      });
      setRefillRequests(refilRequests);
      setRowData(refilRequests);
    }
  }



  const convertDateToMonthAbbreviation = (dateString: string | number | Date) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', { month: 'short' });
  };


  useEffect(() => {

    getallpatient().then(
      (response) => {
        const patientData = response?.result;


        // const labels = patientData.map((item: any) => convertDateToMonthAbbreviation(item.createdAt));
        // const data = patientData.map((item: { count: any; }) => item.count);

        const currentYear = new Date().getFullYear();

        // Filter data to include only entries from the current year
        const filteredData = patientData?.filter((item: { createdAt: string | number | Date; }) => {
          const year = new Date(item.createdAt)?.getFullYear();
          return year === currentYear;
        });

        const aggregatedData: any = {};


        filteredData?.forEach((item: any) => {
          const month = convertDateToMonthAbbreviation(item.createdAt);
          const count = 1;

          if (!aggregatedData[month]) {
            aggregatedData[month] = 0;
          }


          aggregatedData[month] += count;

        });

        const labels = Object.keys(aggregatedData);
        const data = labels?.map((month) => aggregatedData[month]);



        if (chartInstance.current) {
          chartInstance.current?.destroy();
          chartInstance.current = null;
        }


        if (chartRef.current) {
          const myChartRef = chartRef.current?.getContext("2d");

          if (myChartRef) {
            const gradient = myChartRef?.createLinearGradient(0, 0, 0, myChartRef.canvas.height);
            gradient?.addColorStop(0, '#a8edf3');
            // gradient.addColorStop(0.5, '#a8edf3');
            gradient?.addColorStop(1, '#00929f');

            const backgroundGradient = myChartRef?.createLinearGradient(0, 0, 0, myChartRef.canvas.height);
            backgroundGradient?.addColorStop(0, '#a8edf3');
            // backgroundGradient.addColorStop(0.5, '#a8edf3');
            backgroundGradient?.addColorStop(1, '#ffffff');

            chartInstance.current = new Chart(myChartRef, {
              type: "line",
              data: {
                labels: labels,
                datasets: [
                  {
                    label: "Patient ",
                    data: data,
                    fill: true,


                    // backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    // borderColor: 'rgb(75, 192, 192)',

                    backgroundColor: backgroundGradient,
                    borderColor: gradient,
                    borderWidth: 2,
                    tension: 0.3,
                  },
                ],
              },



              options: {
                responsive: true,
                // maintainAspectRatio: false,
                plugins: {
                  tooltip: {
                    backgroundColor: 'rgba(75, 192, 192, 0.8)',
                    // borderColor: 'rgb(0, 0, 0)', 
                    borderWidth: 1,
                    titleColor: 'rgb(255, 255, 255)',
                    bodyColor: 'rgb(255, 255, 255)'
                  },
                  //   legend: {
                  //     display: false
                  // },
                },
                // animations: {
                //   tension: {
                //     duration: 1000,
                //     easing: 'linear', 
                //     from: 1,
                //     to: 0,
                //     loop: true
                //   }
                // },

              }
            });
          }
        }
      },
      (error) => {
        console.error("error", error);
      }
    );

    document.title = `${metaTitle}Staff - Dashboard`

    // Cleanup function to destroy chart instance when component unmounts
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
        chartInstance.current = null;
      }
    };
  }, []);




  // useEffect(() => {
  //   getBookingData()
  // }, []);

  const getBookingData = (page?: number) => {
    const pageCount = page != undefined ? page : 1;
    getAllBookingAppointment(pageCount).then(
      (response) => {
        if (response?.success) {
          const bookingData = response.appointmentBooking.rows;
          setAllBooking(bookingData);
          setAllBookingCopy(bookingData);
          // const pages = decidePages(response.appointmentBooking.count);
          // setPages(pages);
          // setPageNumber(pageCount)

          const currentDate = new Date();
          const currentAppointments = bookingData.filter((appointment: any) => {
            const appointmentDate = new Date(appointment.date);
            return appointmentDate >= currentDate;

          });
        }
      },
      (error) => {
        console.log("error", error)
      }
    );
  }





  const tileContent = ({ date, view }: { date: Date; view: string }) => {
    const inputDate = new Date(date);

    const appointmentsOnDate = allBooking.filter((appointment: any) => {
      const appointmentDate = new Date(appointment.date);

      return (
        appointmentDate.getFullYear() === inputDate.getFullYear() &&
        appointmentDate.getMonth() === inputDate.getMonth() &&
        appointmentDate.getDate() === inputDate.getDate()
      );
    });


    const count = appointmentsOnDate.length;


    return view === 'month' && count > 0 ? <div className="cal_count"
    //   onClick={() => {
    //     navigate(`/${currentUser}/appointment`, { state: { inputDate } });
    // }}
    >{count}</div> : null;
  };


  const handleDateClick = (date: Date, view: string) => {
    const appointmentsOnDate = allBooking.filter((appointment: any) => {
      const appointmentDate = new Date(appointment.date);

      return (
        appointmentDate.getFullYear() === date.getFullYear() &&
        appointmentDate.getMonth() === date.getMonth() &&
        appointmentDate.getDate() === date.getDate()
      );
    });

    const count = appointmentsOnDate.length;
    if (count <= 0) {
      toast.setShowToast("No appointments available in selected date!", "success")
    } else {
      navigate(`/${currentUser}/appointment/${getDateOnly(date)}`,);
    }
  };







  async function getHospitalLocations(page?: number) {
    const pageCount = page != undefined ? page : 1;
    const locations = await getLocations(pageCount);
    if (locations?.success) {
      const pages = decidePages(locations.data?.count);
      setPages(pages);
      setPageNumber(pageCount)
      setLocations(locations.data.rows);
      setLocationsCopy(locations.data.rows);
    }
  }

  function handleMore(id: string) {
    setMore(id);
  }

  function navigateToEdit(id: string) {
    navigate(`/${currentUser}/edit-location`, { state: { id: id } })
  }

  async function deleteOneLocation(id: string) {
    const data = await deleteLocation(id);
    if (data.success) {
      setLocations(locations.filter((location: any) => location.id != id))
    }
  }

  function handleTrash(id: string) {
    setShowDeleteModal(true);
    setDeleteId(id)
  }

  function onSearchChange(e: any) {
    setSearch(e.target.value);
    const values = locationsCopy.filter((items: any) => Object.values(items).join(" ").toLowerCase().match(e.target.value.toLowerCase()));
    setLocations(values);
  }


  const isDateDisabledDate = (date: Date) => {
    const currentDate = new Date();
    return date < new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    // return date < currentDate;
  };

  const isDateDisabledMonth = (date: Date) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    const targetYear = date.getFullYear();
    const targetMonth = date.getMonth();

    return targetYear === currentYear && targetMonth < currentMonth;
  };

  const isDateDisabledYear = (date: Date) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const targetYear = date.getFullYear();

    return targetYear < currentYear;
  };

  const isDateDisabledDecade = (date: Date): boolean => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const targetYear = date.getFullYear();

    return Math.floor(currentYear / 10) > Math.floor(targetYear / 10);
  };

  const isDateDisabledCentury = (date: Date): boolean => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const targetYear = date.getFullYear();

    return Math.floor(currentYear / 100) > Math.floor(targetYear / 100);
  };

  const tileDisabled = ({ date, view }: { date: Date, view: string }) => {
    switch (view) {
      case 'month':
        return isDateDisabledDate(date)
      // return isDateDisabledMonth(date);
      case 'year':
        return isDateDisabledMonth(date);
      // return isDateDisabledYear(date);
      case 'decade':
        return isDateDisabledYear(date);
      // return isDateDisabledDecade(date);
      case 'century':
        return isDateDisabledDecade(date);
      // return isDateDisabledCentury(date);
      default:
        return isDateDisabledDate(date);
    }
  };

  async function fetchChatUsers() {
    const response = await getChatUsers();
    if (response?.success) {
      setChatUsers(response?.data?.slice(0, 2));
    }
  }


  return (
    <Row>
      <Col className="mt-3 mb-4">
        <div className="col-sm-12 row m-0 staff_top">
          <div className="col-12 col-xl-4 p-2">
            <div className="staff_calender calender_block doctor_calender">
              <Calendar
                tileContent={tileContent}
                value={selectedDates}
                // tileDisabled={tileDisabled}
                onClickDay={(date) => handleDateClick(date, "month")}
              />


              {/* <Calendar 
              // onChange={handleCalenderDateChange}
               value={selectedDates} 
              tileDisabled={tileDisabled}
              /> */}
            </div>
          </div>
          <div className="col-12 col-xl-8 dashpatient_wrap px-2 py-2">
            <div className="patientsname_sec p-0">
              <div className="col-sm-12 patientsname_top row py-3 px-4 align-items-center">
                <div className="col-sm-4 pb-0 patientsname_top_left d-flex p-0 align-items-center justify-content-between align-items-center">
                  <h2>Refill Requests</h2>

                </div>
                <div className="col-sm-7 patientsname_top_right p-0 row m-0 justify-content-end align-items-center">
                  <div className="col-xl-6 patientsearch_form p-0 mb-0 mt-2 mt-sm-0">
                    {/* <input className="w-100" type="text" placeholder="Search..." /> */}
                    <SearchInput
                      visible={true}
                      data={rowData}
                      setData={(value: any) => setRefillRequests(value)}
                      pos={true}
                    />
                  </div>
                  <div className="col-xl-3 text-end p-0 ">
                    <button
                      className="border-0 bg-transparent textgreen"
                      type="button"
                      onClick={() => navigate(`/${currentUser}/refill-request`)}
                    >
                      View All
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 patientstable_sec table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="text-center" scope="col">Patient Name</th>
                      <th scope="col">Refill Requested</th>
                      <th scope="col">Date</th>
                      <th scope="col">Time</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody className="table-group-divider">
                    {refillRequests?.length > 0 ?
                      <>
                        {refillRequests?.map((item: any) => {
                          const date = new Date(item?.updatedAt);
                          const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
                          const formattedDate = date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
                          return (
                            <tr onClick={() => navigate(`/${currentUser}/refilldetail/${item.id}`)}
                            >
                              <td className="text-center">{item?.user?.firstName} {item?.user?.lastName}</td>
                              <td>{item.medication}</td>
                              <td> {formattedDate}</td>
                              <td> {formattedTime}</td>
                              <td className={`${(item.status == 0 || item.status == 2) ? "text-pending" : "textgreen"}`}>
                                {item.status == 0 ? "Pending" : item.status == 11 ? "Sent To Pharmacy" : item.status == 1 ? "Confirmed" : "Rejected"}
                      </td>
                              <td>
                                <CustomToolTip title="Info">
                                  <span
                                    className="dost_btn bg_gray_light text-center py-1 fs-6 px-2 rounded-circle"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      navigate(`/${currentUser}/refilldetail/${item.id}`)
                                    }}
                                  >
                                    <i className="bi bi-exclamation-circle" />
                                  </span>
                                </CustomToolTip>
                      </td>
                            </tr>)
                        })
                        }
                      </>
                      :
                    <tr>
                        <td colSpan={10} className="text-center">No data found</td>
                        {/* {!loading ?
                          <td colSpan={10}>No data found</td>
                          :
                          <td colSpan={10}><Spinner /></td>
                        } */}
                    </tr>
                    }
                    {/* <tr>
                      <td className="text-center">Emily Rodriguez</td>
                      <td>Neuroquil</td>
                      <td>10 Jan 2024</td>
                      <td>08.53 AM</td>
                      <td><span className="textgreen">Completed</span></td>
                      <td>
                        <span className="dost_btn bg_gray_light text-center py-1 fs-6 px-2 rounded-circle"><i className="bi bi-info-circle"></i></span>
                      </td>
                    </tr>*/}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 staff_bottom row m-0">
          <div className="col-lg-12 col-xl-8 p-2">
            {/* biechard */}
            <div className="float-start w-100">
              <div className="inner_location float-start w-100 bg-white box_shadow   radius_15">
                <div className="m-0 border-bottom d-flex gap-3 align-items-center p-4 py-3">
                  {/* <span><img src={multiple} alt="user" /></span><span className="poppins-600 text_18">Patient Activity</span> */}
                  <span className="poppins-600 text_18">Patient Activity</span>
                </div>
                <div className="p-3 staff_graph">
                  <canvas ref={chartRef} />
                  {/* <img className="w-100 object-cover" src={activity} alt="activity" /> */}
                </div>
              </div>
            </div>
            {/* message */}
            <div className="float-start w-100 pt-2">
              <div className="inner_location float-start w-100 bg-white box_shadow  py-2 radius_15">
                <div className="d-flex align-items-center justify-content-between border-bottom p-3">
                  <h2 className="m-0 poppins-600 text_18">Messages</h2>
                  <button
                    className="border-0 bg-transparent textgreen clickable"
                    type="button"
                    onClick={() => navigate(`/${currentUser}/messages`)}
                  >
                    View All
                  </button>
                </div>
                <div className="col-sm-12 recentappoint_wrap mt-2">
                  {chatUsers?.length > 0 ?
                  <>
                  {chatUsers.map((item: any) => {
                    return (
                      <div className="col-sm-12 recentappoint_part position-relative">
                        <div className="d-flex gap-2">
                          <h4><i className="bi bi-chat-text"></i></h4>
                          <div className="recentappoint_name mes">
                            <strong>{item.name}</strong>
                            <p className="m-0 text_sm text_gray">
                              {item.lastMessage
                                ? item.lastMessageType === "text"
                                  ? item.lastMessage
                                  : item.lastMessageType
                                : "Tap to initiate chat"}
                            </p>
                          </div>
                        </div>
                        <div className="three_dots">
                          <i className="bi bi-three-dots-vertical clickable"></i>
                          <div
                            // className="drop_chat drop_list m-0 p-0 bg-white p-2  text-left clickable"
                            className="clickable chat_more drop_list m-0 p-0 bg-white p-2  text-left"
                            onClick={() => navigate(`/${currentUser}/messages`, { state: { chatRoomId: item.room } })}
                          >
                            View Messages
                          </div>
                        </div>
                      </div>
                    )
                  })}
                  </>
                  :
                  <div className='mt-3 text-center mb-2'>No data found.</div>
                }
                </div>
              </div>
            </div>
          </div>
          {/* location */}
          <div className="col-lg-12 col-xl-4 p-2">
            <div className="inner_location float-start w-100 bg-white box_shadow  radius_15 pb-4">
              {/* <div class="three_dots float-end position-relative">
                                  <button class="bg-transparent border-0 float-end p-0" type="button"><i class="bi bi-three-dots-vertical"></i></button>
                                  <ul class="drop_list m-0 p-0 bg-white p-2  text-left">
                                     <li><a href="#">Edit</a></li>
                                     <li><a href="#">Delete</a></li>
                                  </ul>
                              </div> */}
              <div className="d-flex align-items-center justify-content-between border-bottom p-3 pt-4">
                <h2 className="m-0 poppins-600 text_18">Locations</h2>

                <button
                  className="border-0 bg-transparent textgreen"
                  type="button"
                  onClick={() => navigate(`/${currentUser}/locations`)}
                >
                  View All
                </button>
              </div>
              <div className="float-start w-100 slider-container">
                {locations.length > 0 ?
                <CustomCarousel
                  data={locations}
                />
                  :
                  <div className="mt-3 text-center">No data found.</div>
                }
              </div>
            </div>




          </div>


        </div>

      </Col>
    </Row>
  );
};

export default StaffDashboard;
