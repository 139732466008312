import {
    Col,
    Row,
} from "reactstrap";
import AvailabilityModal from "../../components/AvailabilityModal";
import { useState, useEffect, useContext } from "react";
import { getDoctorAvailability, deleteDoctorAvailabilityData } from "../../services/doctoravailability-service";
import DeleteModal from "../../components/DeleteModal";
import { AlertContext } from "../../utils/contexts/RoleContext";
import constants from "../../config/constants";

export default function DoctorAvailability() {
    const [showAvailabilityModal, setShowAvailabilityModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [myTimings, setMyTimings] = useState([]);
    const [availabilityId, setAvailabilityId] = useState(0);
    const [deleteId, setDeleteId] = useState(0);

    const toast: any = useContext(AlertContext);

    useEffect(() => {
        getMyAvailability();
        document.title = `${constants.metaTitle}Doctor - Availability`;
    }, []);

    async function getMyAvailability() {
        const response = await getDoctorAvailability();
        if (response?.success) {
            setMyTimings(response?.data);
        }
    }

    async function deleteTiming() {
        const response = await deleteDoctorAvailabilityData(deleteId);
        if (response.success) {
            toast.setShowToast("Data deleted successfully!", "success");
            getMyAvailability();
        } else {
            toast.setShowToast("Error while deleting the data! Please try again later.", "danger");
        }
    }

    return (
        <Row>
            <div className="panel_top_section">
                <h1>My Availability</h1>
                <div className="grid_icons d-flex gap-3 align-items-center float-end">
                    <button
                        type="button"
                        className="btn_gradian"
                        onClick={() => {
                            setShowAvailabilityModal(true);
                            setAvailabilityId(0)
                        }}
                    >
                        + Add New Timing
                    </button>
                </div>
            </div>
            <Col>
                {myTimings.length > 0 ?
                    <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                        <div className="row d-flex table-responsive">
                            <table>
                                <thead>
                                    <tr className="list_head">
                                        <th>S.No.</th>
                                        <th>Day</th>
                                        <th>From Hours</th>
                                        <th>To hours</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {myTimings.map((item: any, index: number) => {
                                        return (
                                            <tr className="list_detail">
                                                <td className="text-center">{index + 1}</td>
                                                <td className="text-center">{item.day}</td>
                                                <td className="text-center">{item.from_hours}</td>
                                                <td className="text-center">{item.to_hours}</td>
                                                <td className="text-center">
                                                    <span className="action" >
                                                        <button
                                                            className="edit_btn"
                                                            onClick={() => {
                                                                setAvailabilityId(item.id);
                                                                setShowAvailabilityModal(true)
                                                            }}
                                                        >
                                                            <i className="bi bi-pen"></i>
                                                        </button>
                                                        <button
                                                            className="delete_btn_act"
                                                            onClick={() => {
                                                                setDeleteId(item.id);
                                                                setShowDeleteModal(true);
                                                            }}
                                                        >
                                                            <i className="bi bi-trash" />
                                                        </button>
                                                    </span>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                                {/* {pages?.length > 1 &&
                      <tfoot>
                        <tr className="list_detail pagination_list">
                          <td colSpan={5}>
                            <ul>
                              <li onClick={() => handlePagination(pageNumber - 1)}><a href="#"><i className="bi bi-arrow-left"></i></a></li>
                              {pages.map((page: number) => {
                                return (
                                  <li className={pageNumber == page ? "active" : ""} onClick={() => handlePagination(page)}><a>{page}</a></li>
                                )
                              })}
                              <li onClick={() => handlePagination(pageNumber + 1)}><a href="#"><i className="bi bi-arrow-right"></i></a></li>
                            </ul>
                          </td>
                        </tr>
                      </tfoot>
                    } */}
                            </table>
                        </div>
                    </div>
                    :
                    <div className="text-center mt-5">
                        No data found
                    </div>
                }
            </Col>
            <AvailabilityModal
                show={showAvailabilityModal}
                handleClose={() => setShowAvailabilityModal(false)}
                availabilityId={availabilityId}
                handleSave={getMyAvailability}
                title={availabilityId === 0 ? "Add Timing" : "Update Timing"}
            />
            <DeleteModal
                show={showDeleteModal}
                handleClose={() => setShowDeleteModal(false)}
                handleYes={() => {
                    deleteTiming();
                    setShowDeleteModal(false);
                }}
                head="Delete Time slot"
                body="Do you really want to delete this data?"
            />
        </Row>
    )
}