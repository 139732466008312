// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 
.slick-slider {
    position: relative; 
    padding-bottom: 30px; 
}


.slick-dots {
    position: absolute; 
    bottom: 0; 
    left: 50%; 
    transform: translateX(-50%); 
}


.slick-dots li button:before {
    color: #000; 
    font-size: 12px; 
}


.slick-dots li.slick-active button:before {
    color: #333; 
}


.slick-prev, .slick-next {
    position: absolute;
    top: 50%; 
    transform: translateY(-50%); 
    z-index: 1; 
}

.slick-prev {
    left: 10px; 
}

.slick-next {
    right: 10px; 
} */


.slick-dots li:nth-child(n + 4) {
    display: none; 
}



/* .swiper.swiper-initialized {
    width: 90%;
} */

`, "",{"version":3,"sources":["webpack://./src/pages/common/StaffDashboard.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GAuCG;;;AAGH;IACI,aAAa;AACjB;;;;AAIA;;GAEG","sourcesContent":["/* \r\n.slick-slider {\r\n    position: relative; \r\n    padding-bottom: 30px; \r\n}\r\n\r\n\r\n.slick-dots {\r\n    position: absolute; \r\n    bottom: 0; \r\n    left: 50%; \r\n    transform: translateX(-50%); \r\n}\r\n\r\n\r\n.slick-dots li button:before {\r\n    color: #000; \r\n    font-size: 12px; \r\n}\r\n\r\n\r\n.slick-dots li.slick-active button:before {\r\n    color: #333; \r\n}\r\n\r\n\r\n.slick-prev, .slick-next {\r\n    position: absolute;\r\n    top: 50%; \r\n    transform: translateY(-50%); \r\n    z-index: 1; \r\n}\r\n\r\n.slick-prev {\r\n    left: 10px; \r\n}\r\n\r\n.slick-next {\r\n    right: 10px; \r\n} */\r\n\r\n\r\n.slick-dots li:nth-child(n + 4) {\r\n    display: none; \r\n}\r\n\r\n\r\n\r\n/* .swiper.swiper-initialized {\r\n    width: 90%;\r\n} */\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
