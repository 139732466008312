import authHeader from "./auth-header";
import axios from "axios";
import { END_POINT } from "../config/constants";

// const APIS_URL = "http://localhost:5000/api/prescription/";
const APIS_URL = `${END_POINT}prescription/`

export const getallDoctor = () => {
  return axios
    .get(APIS_URL + "get_doctor?roleId=3", {

    })
    .then((response) => {
      return response?.data;
    });
};

export const deleteOnePrescription = (id: any) => {
  return axios
    .put(APIS_URL + `deleteoneprescription/${id}`, {

    })
    .then((response) => {


     return  response?.data;
      // window.location.reload();
    });
};

export const getonprescription = (key: any) => {
  return axios
    .get(APIS_URL + "getoneprescription/" + key, {

    })
    .then((response) => {


      return response?.data;
    });
};

export const updateprofile = (key: string, values: object) => {
  return axios
    .put(APIS_URL + "updateprofile/" + key, {
      values
    })
    .then((response) => {


      return response?.data;
    });
};

export const createprescription = (values: object) => {
  return axios
    .post(APIS_URL + "createprescription", {
      values
    })
    .then((response) => {
      return response?.data;
    });
};

export const updateDoctor = (values: object, id: string | null) => {
  return axios
    .put(APIS_URL + "edit_doctor/" + id, {
      values
    })
    .then((response) => {


      return response?.data;
    });
};

export const getOneDoctor = (key: string | null) => {
  console.log("dereieru",key)
  return axios
    .get(APIS_URL + "getonedoctor/" + key, {

    })
    .then((response) => {


      return response?.data;
    });
};

export const addMedication = (id: any, values: any) => {
  return axios
    .put(APIS_URL + "add-medication/" + id, {
      ...values
    })
    .then((response) => {
      return response?.data;
    });
};

export const getAppointmentPrescription = (id: any) => {
  return axios
    .get(`${APIS_URL}get-appointment-prescription/${id}`)
    .then((response) => {
      return response?.data;
    });
};