import {
      Row,
      Col
} from "reactstrap";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { getOneDoctor } from "../../../services/doctor-service";
import constants from "../../../config/constants";
import { capitalizeFirstLetter } from "../../../utils/utils";
import { RoleContext } from "../../../utils/contexts/RoleContext";
import { longDateGenerator } from "../../../utils/utils";
import { getQuestionnaireAnswers } from "../../../services/patient-service";
import { getCurrentUser } from "../../../services/auth.service";

const DoctorQuesDetail = () => {
      const [patient, setPatient] = useState<any>({});
      const [questionnaire, setQuestionnaire] = useState<any>([])

      const location = useLocation()
      const navigate = useNavigate();
      const currentUser = useContext(RoleContext)

      useEffect(() => {
            getPatient();
            getAnswers()
            document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Questionnaire Detail`;
      }, [currentUser]);

      async function getAnswers() {
            const response = await getQuestionnaireAnswers(getCurrentUser()?.id, location?.state?.patientId);
            if (response?.success) {
                  const filteredData = response?.data?.filter((item: any) => item?.formId == location?.state?.formId);
                  setQuestionnaire(filteredData);
            }
      }

      const getPatient = async () => {

            getOneDoctor(location?.state?.patientId).then(
                  (data) => {
                        const allDoctor = data?.allDoctors;
                        allDoctor?.userdata?.map((item: any) => {
                              if (item?.fieldKey === "dateOfBirth") {
                                    allDoctor.dateOfBirth = JSON.parse(item?.fieldValue)
                              }
                              if (item?.fieldKey === "address") {
                                    allDoctor.address = JSON.parse(item?.fieldValue)
                              }
                              if (item?.fieldKey === "gender") {
                                    allDoctor.gender = JSON.parse(item?.fieldValue)
                              }
                        });
                        setPatient(allDoctor);
                  },
                  (error) => {
                        const resMessage =
                              (error?.response &&
                                    error?.response?.data &&
                                    error?.response?.data?.message) ||
                              error?.message ||
                              error?.toString();

                        //   setLoading(false);
                        //   setMessage(resMessage);
                  }
            );


      };

      const getDoctor = async (value: any) => {
            // console.log("location?.state?.key", location?.state?.key)
            getOneDoctor(location?.state?.key).then(
                  (data) => {

                        const allDoctor = data.allDoctors;
                        console.log("geuserss", allDoctor);


                  },
                  (error) => {
                        const resMessage =
                              (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                              error.message ||
                              error.toString();

                        // setLoading(false);
                        // setMessage(resMessage);

                  }
            );


      };

      return (

            <Row>

                  <div className="panel_top_section">
                        <h1>Questionnaires</h1> <span className="textgreen poppins-500"><i className="bi bi-chevron-right"></i> {patient?.firstName + " " + patient?.lastName} - {location?.state?.reason} Survey </span>
                  </div>
                  <Col className="mt-3 mb-4">
                        <div className="add_location col-sm-12 py-4 px-lg-2 mt-2">
                              <div className="row m-0 justify-content-center">
                                    <div className="col-xl-6 p-1 pb-2">
                                          <div className="inner_location row float-start w-100 bg-white box_shadow p-4 radius_15">
                                                <div className="col-xl-12 float-start p-1 pb-2">
                                                      {/* patient information */}
                                                      <div className="float-start w-100 pb-4 px-xl-3">
                                                            <h4 className="px-1 textgreen poppins-600 fs-5  pb-4 mb-0">Patient Information</h4>
                                                            <div className="border-bottom pb-3">
                                                                  <div className="pat_group py-2 p d-flex justify-content-between">
                                                                        <div className="col-sm-4 row_1 px-1">
                                                                              <span className="d-block text_sm text_gray ">Full Name</span>
                                                                              <p className="m-0 poppins-500 dark_blue fs-6">{patient?.firstName + " " + patient?.lastName}</p>
                                                                        </div>
                                                                        <div className="col-sm-4 row_1 px-1">
                                                                              <span className="d-block text_sm text_gray ">Birth Date</span>
                                                                              <p className="m-0 poppins-500 dark_blue fs-6">{patient?.dateOfBirth ? longDateGenerator(patient?.dateOfBirth) : "-"}</p>
                                                                        </div>
                                                                        <div className="col-sm-4 row_1 px-1">
                                                                              <span className="d-block text_sm text_gray ">Gender</span>
                                                                              <p className="m-0 poppins-500 dark_blue fs-6">{patient?.gender}</p>
                                                                        </div>
                                                                  </div>
                                                                  <div className="pat_group py-2 p d-flex justify-content-between">
                                                                        <div className="col-sm-4 row_1 px-1">
                                                                              <span className="d-block text_sm text_gray ">Address</span>
                                                                              <p className="m-0 poppins-500 dark_blue fs-6">{patient?.address ? patient?.address : "-"}</p>
                                                                        </div>
                                                                        <div className="col-sm-4 row_1 px-1">
                                                                              <span className="d-block text_sm text_gray ">City</span>
                                                                              <p className="m-0 poppins-500 dark_blue fs-6">{patient?.city ? patient?.city : "-"}</p>
                                                                        </div>
                                                                        <div className="col-sm-4 row_1 px-1">
                                                                              <span className="d-block text_sm text_gray ">ZIP Code</span>
                                                                              <p className="m-0 poppins-500 dark_blue fs-6">{patient?.zipCode ? patient?.zipCode : "-"}</p>
                                                                        </div>
                                                                  </div>
                                                                  <div className="pat_group py-2 d-flex justify-content-between">
                                                                        <div className="col-sm-4 row_1 px-1">
                                                                              <span className="d-block text_sm text_gray ">Phone</span>
                                                                              <p className="m-0 poppins-500 dark_blue fs-6">{patient?.phoneNumber}</p>
                                                                        </div>
                                                                        <div className="col-sm-8  row_1 px-1">
                                                                              <span className="d-block text_sm text_gray ">Email</span>
                                                                              <p className="m-0 poppins-500 dark_blue fs-6 text-break">{patient?.email}</p>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                      {/* Medical History: */}
                                                      <div className="float-start w-100 pb-4 px-xl-3">
                                                            <h4 className="px-1 textgreen poppins-600 fs-5  pb-4 mb-0">{location?.state?.formName}:</h4>
                                                            {/* <div className="pat_group py-2 d-flex justify-content-between">
                                                                  <div className="col-sm-5 row_1 px-1">
                                                                        <span className="d-block text_sm text_gray ">Current Medications </span>
                                                                        <p className="m-0 poppins-500 dark_blue fs-6">Carvedilol</p>
                                                                  </div>
                                                                  <div className="col-sm-6 row_1 px-1">
                                                                        <span className="d-block text_sm text_gray ">Family Medical History</span>
                                                                        <p className="m-0 poppins-500 dark_blue fs-6">Father/ Stroke</p>
                                                                  </div>
                                                            </div> */}
                                                            <div className="pat_group py-2 d-flex justify-content-between flex-wrap">
                                                                  {questionnaire?.map((item: any) => {
                                                                        return (
                                                                              <div className="col-sm-5 row_1 px-1">
                                                                                    <span className="d-block text_sm text_gray ">{item.formFieldKey}</span>
                                                                                    <p className="m-0 poppins-500 dark_blue fs-6">{item.formFieldValue}</p>
                                                                              </div>
                                                                        )
                                                                  })}

                                                                  {/* <div className="col-sm-6 row_1 px-1">
                                                                        <span className="d-block text_sm text_gray ">Past Medical Conditions</span>
                                                                        <p className="m-0 poppins-500 dark_blue fs-6">-</p>
                                                                  </div> */}
                                                            </div>
                                                      </div>

                                                </div>

                                                {/* right-coloum */}
                                                {/* Current Symptoms: */}

                                                {/* <div className="col-xl-6 float-start p-1 pb-2"> */}
                                                {/* <div className="float-start w-100 pb-4 px-xl-3">
                                                            <h4 className="px-1 textgreen poppins-600 fs-5  pb-4 mb-0">Current Symptoms:</h4>
                                                            <div className="border-bottom pb-3">
                                                                  <div className="pat_group py-2 d-flex justify-content-between">
                                                                        <div className="col-sm-5 row_1 px-1">
                                                                              <span className="d-block text_sm text_gray ">Complaints</span>
                                                                              <p className="m-0 poppins-500 dark_blue fs-6">Headaches</p>
                                                                        </div>
                                                                        <div className="col-sm-7 row_1 px-1">
                                                                              <span className="d-block text_sm text_gray ">Duration of Symptoms</span>
                                                                              <p className="m-0 poppins-500 dark_blue fs-6">Every 2 days</p>
                                                                        </div>
                                                                  </div>
                                                                  <div className="pat_group py-2 d-flex justify-content-between">
                                                                        <div className="col-sm-12 row_1 px-1">
                                                                              <span className="d-block text_sm text_gray ">Factors That Alleviate Symptoms</span>
                                                                              <p className="m-0 poppins-500 dark_blue fs-6">Hydration, Massage</p>
                                                                        </div>

                                                                  </div>
                                                            </div>
                                                      </div> */}
                                                {/* Lifestyle Factors: */}
                                                {/* <div className="float-start w-100 pb-4 px-xl-3">
                                                            <h4 className="px-1 textgreen poppins-600 fs-5  pb-4 mb-0">Lifestyle Factors:</h4>
                                                            <div className="border-bottom pb-3">
                                                                  <div className="pat_group py-2 p d-flex justify-content-between">
                                                                        <div className="col-sm-5 row_1 px-1">
                                                                              <span className="d-block text_sm text_gray ">Smoking Status</span>
                                                                              <p className="m-0 poppins-500 dark_blue fs-6">Smoker</p>
                                                                        </div>
                                                                        <div className="col-sm-7 row_1 px-1">
                                                                              <span className="d-block text_sm text_gray ">Alcohol Consumption</span>
                                                                              <p className="m-0 poppins-500 dark_blue fs-6">Socially</p>
                                                                        </div>
                                                                  </div>
                                                                  <div className="pat_group py-2 d-flex justify-content-between">
                                                                        <div className="col-sm-5 row_1 px-1">
                                                                              <span className="d-block text_sm text_gray ">Physical Activity Level</span>
                                                                              <p className="m-0 poppins-500 dark_blue fs-6">Active</p>
                                                                        </div>
                                                                        <div className="col-sm-7  row_1 px-1">
                                                                              <span className="d-block text_sm text_gray ">Dietary Habits</span>
                                                                              <p className="m-0 poppins-500 dark_blue fs-6 text-break">Mediterranean</p>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div> */}
                                                {/* Health Goals and Concerns: */}
                                                {/* <div className="float-start w-100 pb-0 px-xl-3">
                                                            <h4 className="px-1 textgreen poppins-600 fs-5  pb-4 mb-0">Health Goals and Concerns:</h4>
                                                            <div className="pat_group py-2 p d-flex justify-content-between">
                                                                  <div className="col-sm-12 row_1 px-1">
                                                                        <span className="d-block text_sm text_gray ">Health Related Goals</span>
                                                                        <p className="m-0 poppins-500 dark_blue fs-6">Achieve and Maintain a Healthy Blood Pressure</p>
                                                                  </div>
                                                            </div>
                                                      </div> */}

                                                {/* </div> */}


                                          </div>
                                    </div>
                              </div>
                        </div>
                  </Col>
            </Row>
      );
};

export default DoctorQuesDetail;
