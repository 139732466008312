import patientimg from "../assets/images/doctor/doctor-image1.jpg";
import girl from "../assets/images/icons/girl.jpg";
import {
      Card,
      Row,
      Col,
      CardTitle,
      CardBody,
      Button,
      Form,
      FormGroup,
      Label,
      Input,
      FormText,
} from "reactstrap";
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { getOnePatient, getOnePatientQuestionnaries, requestQuestionnaireAccess, checkQuestionnaireAuth } from "../../../services/patient-service";
import { onePatientBookingAppointment, getPatientAppointments } from "../../../services/scheduleappointment-service"
import { SOCKET_DOMAIN } from "../../../config/constants";
import user1 from "../../../assets/images/users/user1.jpg"
import { createChatUser } from "../../../services/messages-service";
import { RoleContext, AlertContext } from "../../../utils/contexts/RoleContext";
import Spinner from 'react-bootstrap/Spinner';
import constants from "../../../config/constants";
import { capitalizeFirstLetter, groupBy } from "../../../utils/utils";
import { getQuestionnaireAnswers } from "../../../services/patient-service";
import { getCurrentUser } from "../../../services/auth.service";
import Accordion from 'react-bootstrap/Accordion';

const PatientDetail = () => {
      const { patientId }: any = useParams();
      const location = useLocation();
      const [patientDetail, setPatientDetail] = useState<any>({})
      const [patientBookingData, setPatientBookingData] = useState<any[]>([])
      const [patientBookingCount, setPatientBookingCount] = useState()
      const [registerDate, setRegisterDate] = useState('')

      const [isBlurDivVisible, setIsBlurDivVisible] = useState(true);
      const [isOtherDivVisible, setIsOtherDivVisible] = useState(false);
      const [activeTab, setActiveTab] = useState<string>('all');
      const [showQuestionnaire, setShowQuestionnaire] = useState(false);
      const [loading, setLoading] = useState(false);
      const [questionnaires, setQuestionnaires] = useState([]);
      const [doctors, setDoctors] = useState([]);

      const currentUser = useContext(RoleContext);
      const navigate = useNavigate();
      const toast: any = useContext(AlertContext);


      useEffect(() => {
            getUsers();
            getPatientBooking();
            authorizeUser();
      }, []);

      useEffect(() => {
            document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Patient Detail - ${patientDetail.firstName + ' ' + patientDetail.lastName}`;
      }, [currentUser, patientDetail])

      async function createChatUsers(id: number) {
            const response = await createChatUser(id);
            navigate(`/${currentUser}/messages`, { state: { chatRoomId: response.data.room } });
      }

      const getUsers = () => {
            setLoading(true);
            getOnePatient(patientId).then(
                  (response) => {
                        setLoading(false);
                        let patientData = { ...response?.result }
                        const date = new Date(patientData?.createdAt);
                        const year = date.getFullYear();
                        const month = String(date.getMonth() + 1)?.padStart(2, "0");
                        const day = String(date.getDate())?.padStart(2, "0");
                        const formattedDate = `${year}-${month}-${day}`;
                        setRegisterDate(formattedDate)
                        patientData?.userdata?.forEach((patient: any) => {
                              if (patient?.fieldKey === 'gender') {
                                    patientData.gender = patient?.fieldValue?.replace(/^"(.*)"$/, '$1');
                              } else if (patient?.fieldKey === 'dateOfBirth') {
                                    patientData.dateOfBirth = patient?.fieldValue?.replace(/^"(.*)"$/, '$1');
                              } else if (patient?.fieldKey === 'height') {
                                    patientData.height = patient?.fieldValue?.replace(/^"(.*)"$/, '$1');
                              } else if (patient?.fieldKey === 'weight') {
                                    patientData.weight = patient?.fieldValue?.replace(/^"(.*)"$/, '$1');
                              } else if (patient?.fieldKey === 'memberStatus') {
                                    patientData.memberStatus = patient?.fieldValue?.replace(/^"(.*)"$/, '$1');
                              } else if (patient?.fieldKey === 'address') {
                                    patientData.address = patient?.fieldValue?.replace(/^"(.*)"$/, '$1');
                              } else if (patient?.fieldKey === 'streetAddress') {
                                    patientData.street = patient?.fieldValue?.replace(/^"(.*)"$/, '$1');
                              } else if (patient?.fieldKey === 'city') {
                                    patientData.city = patient?.fieldValue?.replace(/^"(.*)"$/, '$1');
                              } else if (patient?.fieldKey === 'zipCode') {
                                    patientData.zipCode = patient?.fieldValue?.replace(/^"(.*)"$/, '$1');
                              } else if (patient?.fieldKey === 'bloodType') {
                                    patientData.bloodType = patient?.fieldValue?.replace(/^"(.*)"$/, '$1');
                              }
                        });
                        // let showQuestionnaire = false;
                        // patientDetail?.user_form_data?.forEach((item: any) => {
                        //       if (item?.formType === "Patient Questionnaire") {
                        //             showQuestionnaire = true;
                        //             return false;
                        //       }
                        // });
                        // setShowQuestionnaire(showQuestionnaire);
                        setPatientDetail(patientData);
                  },
                  (error) => {
                        setLoading(false);
                        console.log("err", error)

                  }
            );
      }


      async function getAppointments() {
            const response = await getPatientAppointments(patientId);
            if (response?.success) {
                  setDoctors(response.doctors)
            }
      }

      async function authorizeUser() {
            const response = await checkQuestionnaireAuth(patientId, getCurrentUser()?.id);
            if (response?.success) {
                  setShowQuestionnaire(true);
                  if (currentUser !== "doctor") {
                        getAppointments();
                  } else {
                        getAnswers();
                  }
            } else {
                  setShowQuestionnaire(false);
            }
      }

      async function getAnswers(doctor?: number) {
            let doctorId = currentUser === "doctor" ? getCurrentUser()?.id : doctor
            const response = await getOnePatientQuestionnaries(patientId, doctorId);
            if (response?.success) {
                  setQuestionnaires(response.data);
            }
      }

      const getPatientBooking = () => {
            onePatientBookingAppointment(patientId).then(
                  (response) => {
                        setPatientBookingData(response?.appointmentBooking)
                        setPatientBookingCount(response?.appointmentCount)
                  },
                  (error) => {
                        const resMessage =
                              (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                              error.message ||
                              error.toString();

                  }
            );
      }

      const currentDate = new Date();
      const upcomingAppointments = patientBookingData.filter(appointment => {
            const appointmentDate = new Date(appointment.date);
            return appointmentDate > currentDate;

      });



      const handleButtonClick = async () => {
            const response = await requestQuestionnaireAccess(patientId, getCurrentUser()?.id);
            if (response?.success) {
                  toast.setShowToast(response?.data, "success")
            } else if (response?.code === 201) {
                  toast.setShowToast(response?.data, "danger")
            } else {
                  toast.setShowToast("Something went wrong. Please try again later!", "danger")
            }
            window.scrollTo(0, 0)
      };


      const handleTabClick = (tab: string) => {
            setActiveTab(tab);
      };



      return (
            <>
                  {!loading ?
                        < Row >
                              <div className="panel_top_section">
                                    <h1
                                          className="clickable"
                                          onClick={() => navigate(-1)}
                                    >
                                          Patients
                                    </h1>
                                    <span className="fs-6 fst-normal textgreen poppins-500"> <i className="bi bi-chevron-right"></i> {patientDetail.firstName + ' ' + patientDetail.lastName} </span>

                              </div>
                              <Col >
                                    <div className="patient_detail_wrap col-sm-12 row m-0 px-lg-2 px-xl-3 py-4 my-2">
                                          <div className="col-xl-8 row patient_left p-0 m-0 ">
                                                <div className="col-xl-6 p-1">
                                                      <div className="pateint_left_inner bg-white text-center radius_15 px-3 py-4">
                                                            <div className="pateint_profile mb-3">
                                                                  <img src={patientDetail.profile != 0 ? `${SOCKET_DOMAIN}/profile/${patientDetail.profile}` : user1} alt="patient_img" />
                                                            </div>
                                                            <h2 className="dark_blue fs-4 poppins-700 mb-2">{patientDetail.firstName + ' ' + patientDetail.lastName}</h2>
                                                            <a className="text-decoration-none fs-6 text_gray mb-2 d-block" href="mail:isabella.clark@example.com">{patientDetail.email}</a>
                                                            <div className="patient_appointment text-center pt-2">
                                                                  <div className="col-sm-6 p-0 appointment d-inline-block">
                                                                        <span className="d-block fs-6 text_gray mb-1">Appointments</span>
                                                                        <p className="m-0 poppins-500 dark_blue fs-5">{patientBookingCount}</p>
                                                                  </div>
                                                                  <div className="col-sm-6 p-0 payment_made d-inline-block">
                                                                        <span className="d-block fs-6 text_gray mb-1">Register Date</span>
                                                                        <p className="m-0 poppins-500 dark_blue fs-5">{registerDate} </p>
                                                                  </div>
                                                                  <div className="row justify-content-between  mt-3  pb-1">
                                                                        <div className="col-sm-6 px-1 py-1 py-lg-0">

                                                                              <button
                                                                                    className="bg-white w-100 text_sm py-1 px-2  border-green rounded-1 textgreen d-flex align-items-center"
                                                                                    type="button"
                                                                                    onClick={() => createChatUsers(patientDetail.id)}
                                                                              >
                                                                                    <span className="d-inline-block px-1 fs-6"><i className="bi bi-chat-text"></i></span>Send Message</button>
                                                                        </div>

                                                                        <div className="col-sm-6 px-1 py-1 py-lg-0">
                                                                              {patientDetail?.allowCall ?
                                                                                    <a className="bg_gradiant w-100 d-block text-white text_sm py-2 px-2 rounded-1" href={`tel:${patientDetail.phoneNumber}`}><span className="d-inline-block px-1"><i className="bi bi-telephone-fill"></i></span>{patientDetail.phoneNumber}</a>
                                                                                    :
                                                                                    <a className="bg_gradiant w-100 d-block text-white text_sm py-2 px-2 rounded-1"><span className="d-inline-block px-1"><i className="bi bi-telephone-fill"></i></span>{patientDetail.phoneNumber}</a>
                                                                              }
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                </div>
                                                {/* box-2 */}


                                                <div className="col-xl-6 p-1">
                                                      <div className="pateint_left_inner bg-white text-start radius_15 px-4 py-4">


                                                            <div className="pat_group border-bottom py-3 d-flex justify-content-between">



                                                                  <div className="row_1">
                                                                        <span className="d-block text_sm text_gray ">Gender</span>
                                                                        <p className="m-0 poppins-500 dark_blue fs-6">{patientDetail.gender}</p>
                                                                  </div>



                                                                  <div className="row_1">
                                                                        <span className="d-block text_sm text_gray ">Birth Date</span>
                                                                        <p className="m-0 poppins-500 dark_blue fs-6">{patientDetail.dateOfBirth}</p>
                                                                  </div>

                                                                  <div className="row_1">
                                                                        <span className="d-block text_sm text_gray ">Disease</span>
                                                                        <p className="m-0 poppins-500 dark_blue fs-6">Asthma</p>
                                                                  </div>
                                                            </div>
                                                            <div className="pat_group border-bottom py-3 d-flex justify-content-between">
                                                                  <div className="row_1">
                                                                        <span className="d-block text_sm text_gray ">Height</span>
                                                                        <p className="m-0 poppins-500 dark_blue fs-6">{patientDetail.height}</p>
                                                                  </div>
                                                                  <div className="row_1">
                                                                        <span className="d-block text_sm text_gray ">Weight</span>
                                                                        <p className="m-0 poppins-500 dark_blue fs-6">{patientDetail.weight}</p>
                                                                  </div>
                                                                  <div className="row_1">
                                                                        <span className="d-block text_sm text_gray ">Blood Type</span>
                                                                        <p className="m-0 poppins-500 dark_blue fs-6">{patientDetail.bloodType ? patientDetail.bloodType : "-"}</p>
                                                                  </div>
                                                            </div>
                                                            <div className="pat_group border-bottom py-3 d-flex justify-content-between">
                                                                  <div className="row_1">
                                                                        <span className="d-block text_sm text_gray ">City</span>
                                                                        <p className="m-0 poppins-500 dark_blue fs-6">{patientDetail.city ? patientDetail.city : "-"}</p>
                                                                  </div>
                                                                  <div className="row_1">
                                                                        <span className="d-block text_sm text_gray ">Address</span>
                                                                        <p className="m-0 poppins-500 dark_blue fs-6">{patientDetail.street ? patientDetail.street : "-"}</p>
                                                                  </div>
                                                                  <div className="row_1">
                                                                        <span className="d-block text_sm text_gray ">ZIP Code</span>
                                                                        <p className="m-0 poppins-500 dark_blue fs-6">{patientDetail.zipCode ? patientDetail.zipCode : "-"}</p>
                                                                  </div>
                                                            </div>
                                                            <div className="pat_group py-3 d-flex justify-content-between">
                                                                  <div className="row_1">
                                                                        <span className="d-block text_sm text_gray ">Phone</span>
                                                                        <p className="m-0 poppins-500 dark_blue fs-6">{patientDetail.phoneNumber}</p>
                                                                  </div>
                                                                  <div className="row_1">
                                                                        <span className="d-block text_sm text_gray ">Member Status</span>
                                                                        <p className="m-0 poppins-500 dark_blue fs-6">{patientDetail.memberStatus}</p>
                                                                  </div>
                                                                  <div className="row_1">
                                                                        <span className="d-block text_sm text_gray ">Patient ID</span>
                                                                        <p className="m-0 poppins-500 dark_blue fs-6">#{patientDetail.id}</p>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                </div>



                                                {/* box-3 */}
                                                <div className="col-sm-12 p-1">
                                                      <div className="appoint_table px-3 py-3">
                                                            <h5 className="d-inline-block m-0 text dark_blue fs-5 poppins-600">Recent Appointments</h5>
                                                            {/* <button className="bg-transparent border-0 textgreen float-end" type="button">+ Add Appointment</button> */}
                                                      </div>
                                                      <div className="pateint_left_inner bg-white text-center radius_15 py-4">
                                                            <ul className="tab_navigation p-0 m-0 text-left d-md-flex border-bottom px-3">
                                                                  <li className={`list-unstyled d-md-inline-block py-2 px-2 ${activeTab === 'all' ? 'active' : ''}`} style={{ cursor: 'pointer' }}><a className="text-decoration-none text_sm text_gray fs-6 py-1 d-inline-block" onClick={() => handleTabClick('all')}>All Appointments</a></li>
                                                                  <li className={`list-unstyled d-md-inline-block py-2 px-2 ${activeTab === 'upcoming' ? 'active' : ''}`} style={{ cursor: 'pointer' }}><a className="text-decoration-none text_sm text_gray fs-6 py-1 d-inline-block" onClick={() => handleTabClick('upcoming')}>Upcoming</a></li>
                                                                  {/* <li className="list-unstyled d-md-inline-block py-2 px-2"><a className="text-decoration-none text_sm text_gray fs-6 py-1 d-inline-block" href="#">Medical Records</a></li> */}
                                                            </ul>
                                                            <div className="tab-content">
                                                                  <div className="appointment_table">
                                                                        <div className="row d-flex table-responsive m-0">
                                                                              {activeTab === 'all' && (
                                                                                    <table>
                                                                                          <tbody>
                                                                                                <tr style={{ textAlign: "left" }} className="border-bottom">
                                                                                                      <th className="py-3  px-3 text_14 text_gray">Date</th>
                                                                                                      <th className="py-3  px-3 text_14 text_gray">Time</th>
                                                                                                      <th className="py-3  px-3 text_14 text_gray">Type of Appointment</th>
                                                                                                      <th className="py-3  px-3 text_14 text_gray">Duration</th>
                                                                                                      <th className="py-3  px-3 text_14 text_gray">Status</th>
                                                                                                </tr>
                                                                                                {patientBookingData.length === 0 ? (
                                                                                                      <tr>
                                                                                                            <td colSpan={5}>No data found</td>
                                                                                                      </tr>
                                                                                                ) : (
                                                                                                      patientBookingData.map((booking: any, index: number) => (
                                                                                                            <tr style={{ textAlign: "left" }} key={index}>
                                                                                                                  <td className="pt-3 px-4">{booking.date}</td>
                                                                                                                  <td className="pt-3 px-4">{booking.time}</td>
                                                                                                                  <td className="pt-3 px-4">{booking.type}</td>
                                                                                                                  <td className="pt-3 px-4">1 Hour</td>
                                                                                                                  <td className="pt-3 px-4 textgreen"> {booking.status == 0 ? "Pending" : "Completed"}</td>
                                                                                                            </tr>
                                                                                                      ))
                                                                                                )}

                                                                                                {/* <tr style={{textAlign:"left"}}>
                                                      <td className="pt-3 px-4">20 Feb, 2024</td>
                                                      <td className="pt-3 px-4">3.30pm</td>
                                                      <td className="pt-3 px-4">Consultation</td>
                                                      <td className="pt-3 px-4">25 Minutes</td>
                                                      <td className="pt-3 px-4 textgreen">Completed</td>
                                                    </tr>
                                                    <tr style={{textAlign:"left"}}>
                                                      <td className="pt-3 px-4">20 Feb, 2024</td>
                                                      <td className="pt-3 px-4">3.30pm</td>
                                                      <td className="pt-3 px-4">Follow-up</td>
                                                      <td className="pt-3 px-4">-</td>
                                                      <td className="pt-3 px-4 ">Cancelled</td>
                                                    </tr> */}
                                                                                          </tbody>
                                                                                    </table>
                                                                              )}
                                                                              {activeTab === 'upcoming' && (
                                                                                    <table>
                                                                                          <tbody>
                                                                                                <tr style={{ textAlign: "left" }} className="border-bottom">
                                                                                                      <th className="py-3  px-3 text_14 text_gray">Date</th>
                                                                                                      <th className="py-3  px-3 text_14 text_gray">Time</th>
                                                                                                      <th className="py-3  px-3 text_14 text_gray">Type of Appointment</th>
                                                                                                      <th className="py-3  px-3 text_14 text_gray">Duration</th>
                                                                                                      <th className="py-3  px-3 text_14 text_gray">Status</th>
                                                                                                </tr>
                                                                                                {upcomingAppointments.length === 0 ? (
                                                                                                      <tr>
                                                                                                            <td colSpan={5}>No data found</td>
                                                                                                      </tr>
                                                                                                ) : (
                                                                                                      upcomingAppointments.map((booking: any, index: number) => (
                                                                                                            <tr style={{ textAlign: "left" }} key={index}>
                                                                                                                  <td className="pt-3 px-4">{booking.date}</td>
                                                                                                                  <td className="pt-3 px-4">{booking.time}</td>
                                                                                                                  <td className="pt-3 px-4">{booking.type}</td>
                                                                                                                  <td className="pt-3 px-4">1 Hour</td>
                                                                                                                  <td className="pt-3 px-4 textgreen"> {booking.status == 0 ? "Pending" : "Completed"}</td>
                                                                                                            </tr>
                                                                                                      ))
                                                                                                )}


                                                                                          </tbody>
                                                                                    </table>
                                                                              )}
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                          <div className="col-xl-4 patient_right p-2 position-relative">
                                                {!showQuestionnaire ?
                                                      <div className="requested_access radius_15 text-center position-absolute  h-100 end-0 start-0 top-0 bottom-0 w-100 d-flex align-items-center justify-content-center">
                                                            <div className="filter_blur radius_15 bg-white position-absolute bg-white h-100 end-0 start-0 top-0 bottom-0 w-100 d-flex align-items-center justify-content-center"></div>
                                                            <button
                                                                  className="border-0 bg_dark_blue position-relative text-white text_15 px-3 py-2 rounded-2 d-inline-block"
                                                                  type="button"
                                                                  onClick={handleButtonClick}
                                                            >
                                                                  Request Access
                                                            </button>
                                                      </div>
                                                      :
                                                      <div className="pateint_left_inner bg-white float-start w-100  radius_15 px-4 py-4">
                                                            {currentUser === "doctor" ?
                                                                  <>
                                                                        {questionnaires?.length > 0 ?
                                                                              <>
                                                                                    {questionnaires?.map((item: any) => {
                                                                                          return (
                                                                                                <div className="patient_detail_box float-start w-100 border-bottom py-2 pb-3">
                                                                                                      <h4 className="p-1 poppins-600 fs-5 dark_blue">{item.formName}</h4>
                                                                                                      {item.questionnaires?.map((element: any) => {
                                                                                                            return (
                                                                                                                  <div className="row_1 float-start col-lg-6 p-1 py-1">
                                                                                                                        <span className="d-block text_xs text_gray ">{element.formFieldKey}</span>
                                                                                                                        <p className="m-0 poppins-500 dark_blue fs-6">{element.formFieldValue}</p>
                                                                                                                  </div>
                                                                                                            )
                                                                                                      })}
                                                                                                </div>
                                                                                          )
                                                                                    })}
                                                                              </>
                                                                              :
                                                                              <div className="text-center">
                                                                                    No data found.
                                                                              </div>
                                                                        }
                                                                  </>
                                                                  :
                                                                  <>
                                                                        {doctors?.length > 0 ?
                                                                              <Accordion
                                                                              // defaultActiveKey="0"
                                                                              >
                                                                                    {doctors?.map((item: any, index: number) => {
                                                                                          return (
                                                                                                <Accordion.Item eventKey={`${index + 1}`}>
                                                                                                      <Accordion.Header onClick={() => getAnswers(item.doctorId)}>{item.doctorName}</Accordion.Header>
                                                                                                      <Accordion.Body>
                                                                                                            {questionnaires?.length > 0 ?
                                                                                                                  <>
                                                                                                                        {questionnaires?.map((item: any) => {
                                                                                                                              return (
                                                                                                                                    <div className="w-100 py-2 pb-3 row">
                                                                                                                                          <h4 className="p-1 poppins-600 fs-5 dark_blue">{item.formName}</h4>
                                                                                                                                          {item.questionnaires?.map((element: any) => {
                                                                                                                                                return (
                                                                                                                                                      <div className="col-lg-6 p-1 py-1">
                                                                                                                                                            <span className="d-block text_xs text_gray ">{element.formFieldKey}</span>
                                                                                                                                                            <p className="m-0 poppins-500 dark_blue fs-6">{element.formFieldValue}</p>
                                                                                                                                                      </div>
                                                                                                                                                )
                                                                                                                                          })}
                                                                                                                                    </div>
                                                                                                                              )
                                                                                                                        })}
                                                                                                                  </>
                                                                                                                  :
                                                                                                                  <div className="text-center">
                                                                                                                        No data found.
                                                                                                                  </div>
                                                                                                            }
                                                                                                      </Accordion.Body>
                                                                                                </Accordion.Item>
                                                                                          )
                                                                                    })}
                                                                              </Accordion>
                                                                              :
                                                                              <div className="text-center">
                                                                                    No data found.
                                                                              </div>
                                                                        }
                                                                  </>
                                                            }
                                                      </div>
                                                }
                                          </div>
                                    </div>

                              </Col>


                        </Row >
                        :
                        <div className='m-5 justify-content-center d-flex'>
                              <Spinner />
                        </div>
                  }
            </>
      );
};

export default PatientDetail;
