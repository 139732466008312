import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Form,
    FormGroup,
    FormText,
    Input,
    Label,
    Row,
    Toast,
} from "reactstrap";
import React, { useContext, useEffect, useState } from "react";
import { createDoctor, getOneDoctor } from "../../../services/doctor-service";
import { getFormData, getformdetail } from "../../../services/form-service";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { RoleContext, AlertContext } from "../../../utils/contexts/RoleContext";
import { getCurrentUser } from "../../../services/auth.service";
import profile from "../../../assets/images/doctor/profile.png";
import { Dropdown, ProgressBar } from 'react-bootstrap';
import { RequiredNumberSchema } from "yup/lib/number";
import DeleteModal from "../../../components/DeleteModal";

import { PatientQuestionnarieCreate, getPatientQuestionnarie, updatePatientQuestionnarie, getQuestionnnaireFields, addPatientQuestionnaire, getQuestionnaireAnswers, deletePatientQuestionnaire } from "../../../services/patient-service";
import constants from "../../../config/constants";
import { capitalizeFirstLetter } from "../../../utils/utils";

const roles = [
    {
        label: "Select...",
        value: "select/null"
    },
    {
        label: "Doctor",
        value: "Doctors Profile/3",
        roleId: "3"
    },
    {
        label: "Patient",
        value: "Patients Profile/5",
        roleId: "5"
    },
    {
        label: "Staff",
        value: "Staffs Profile/4",
        roleId: "4"
    },
    // {
    //   label: "Hospital",
    //   value: "Hospitals Profile/2",
    //   roleId: "2"

    // }
]


const PatientQuestionnaire = () => {

    const [htmlFormUsers, sethtmlFormUsers] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [dragAllData, setDragAllData] = useState<any>([]);
    const [currentDoctor, getCurrentDoctor] = useState<any>({});
    const [dragEmpData, setDragEmpData] = useState<any>([]);
    const [personalData, setPersonalData] = useState<any>("");
    const [getvalues, setGetvalues] = React.useState<any>({});
    const [updateValues, setUpdateValue] = React.useState<any>({});
    const [formUsers, getFormUsers] = React.useState<any>([]);
    const [dropDownValue, setDropDownValues] = useState("");
    const [roleId, setRoleId] = useState<any>("");
    const [getImage, setImage] = useState<any>("");
    const [generatePassword, setGeneratePassword] = useState(true)
    const navigate = useNavigate();
    const currentUser = useContext(RoleContext);
    const toast: any = useContext(AlertContext);
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const [roleName, setRoleName] = useState<string>("")
    const [progress, setProgress] = useState<any>()
    const [attachfile, setAttachedFile] = useState<any>(null)
    const [fileName, setFileName] = useState<any>('');
    const [checkBoxValues, setCheckBoxValues] = useState<any>([]);
    const [patientId, setPatientId] = useState<any>({});
    const [dob, setDOB] = useState<any>("");
    const [gender, setGender] = useState<any>("")
    const [address, setAddress] = useState<any>("")
    const [checkedState, setCheckedState] = useState<any>({});

    const [isCheckedYes, setIsCheckedYes] = useState(false);
    const [isCheckedNo, setIsCheckedNo] = useState(false);

    const [questionnaireFields, setQuestionnaireFields] = useState([]);
    const [formValues, setFormValues] = useState<any>({});
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    useEffect(() => {
        fetchQuestionnaireFields();
        getAnswers();
        document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Patient Questionnaire`;
    }, [currentUser]);


    async function getAnswers() {
        const response = await getQuestionnaireAnswers(location.state?.doctorId, location?.state?.patientId ? location?.state?.patientId : "undefined");
        if (response?.success) {
            const obj: any = {}
            response.data?.forEach((item: any) => {
                obj[item.formFieldKey] = { id: item.fieldId, formId: item.formId, value: item.formFieldValue }
            });
            setFormValues(obj);
        }
    }

    async function fetchQuestionnaireFields() {
        const response = await getQuestionnnaireFields(location?.state?.hospitalId, location?.state?.doctorId);
        if (response?.success) {
            const arr: any = [];
            response.data?.forEach((item: any) => {
                if (item?.doctorsLinked != null) {
                    JSON.parse(item.doctorsLinked)?.forEach((doctor: any) => {
                        if (doctor?.id == location?.state?.doctorId) {
                            arr.push(item);
                        }
                    })
                }
            })
            setQuestionnaireFields(arr)
        }
    }

    const handleChange = (event: any) => {
        const { name, value, id } = event.target;
        const formId = id?.split("/")[1];
        const fieldId = id?.split("/")[0];
        setFormValues((prevState: any) => ({
            ...prevState, [name]: { id: fieldId, value: value, formId: formId }
        }));
    }

    async function handleSubmit() {
        const response = await addPatientQuestionnaire(formValues, location.state.doctorId);
        if (response.success) {
            toast.setShowToast("Your response has been saved", "success")
        } else {
            toast.setShowToast("Something went wrong on our side. Please try again later.", "danger")
        }
        window.scrollTo(0, 0)
    }

    async function deleteQuestionnaire() {
        const response = await deletePatientQuestionnaire(location.state.hospitalId);
        if (response.success) {
            toast.setShowToast("Data deleted successfully", "success");
            setFormValues({});
        } else {
            toast.setShowToast("Something went wrong. Please try again later", "danger");
        }
        setShowDeleteModal(false);
    }




    useEffect(() => {
        getDoctor();
        // getPersonalUsers("Hospitals Profile");
        // if (currentUser === "patient") {
        //     setPatientId(getCurrentUser()?.id);
        // } else {
        //     setPatientId(location?.state?.patientId)
        // }
    }, [patientId]);







    const getOnePatientQuestionnarie = async () => {
        const id = patientId?.id
        try {
            const response = await getPatientQuestionnarie(id);
            const patientData = response.patientQuestionnarie;

            const updatedValues: any = {};

            patientData.forEach((formData: any) => {
                //   const { formFieldId , formFieldValue } = formData;
                //   updatedValues[formFieldId] = formFieldValue;

                const { formFieldId, formFieldValue, id } = formData;
                updatedValues[formFieldId] = { value: formFieldValue, patientQuesId: id };
            });
            //   setUpdateValue(updatedValues);

            setGetvalues(updatedValues);




        } catch (error: any) {
            const resMessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

        }


    };



    const editPatientQuestionnarie = async () => {

        try {

            const response = await updatePatientQuestionnarie(getvalues);
            const patientData = response;
            setTimeout(() => {
                toast.setShowToast(patientData.success, "success");
            }, 1000);


        } catch (error: any) {
            const resMessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

        }


    };



    const handleSelect = (event: any) => {

        const profile = event.split("/")[0];
        const roleId = event.split("/")[1];
        setRoleName(profile)
        setDropDownValues(event);
        if (profile !== "select") {
            getPersonalUsers(profile);
            setRoleId(roleId);
        } else {
            sethtmlFormUsers([]);
        }
    };

    const getDoctor = async () => {

        getOneDoctor(getCurrentUser()?.id).then(
            (data) => {
                const allDoctor = data?.allDoctors;
                allDoctor?.userdata?.map((item: any) => {
                    if (item.fieldKey === "dateOfBirth") {
                        setDOB(JSON.parse(item.fieldValue))
                    }
                    if (item.fieldKey === "address") {
                        setAddress(JSON.parse(item.fieldValue))
                    }
                    if (item.fieldKey === "gender") {
                        setGender(JSON.parse(item.fieldValue))
                    }
                    if (item.fieldKey === "city") {
                        allDoctor.city = JSON.parse(item.fieldValue);
                    }
                    if (item.fieldKey === "zipCode") {
                        allDoctor.zipCode = JSON.parse(item.fieldValue);
                    }
                })
                getCurrentDoctor(allDoctor)
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setLoading(false);
                setMessage(resMessage);
            }
        );


    };










    const FormOnChange = (event: any) => {
        const { name, value, id } = event.target;
        const [fieldId, formId, formName, checkboxLabel] = id.split("/");
        // Determine the new value based on the name of the checkbox
        const newValue = name === 'yes' ? 'yes' : 'no';

        // Update the checkedState accordingly

        setCheckedState((prevState: any) => ({
            ...prevState,
            [formId]: {
                ...prevState[formId],
                [fieldId]: {
                    ...prevState[formId]?.[fieldId],
                    [formName || checkboxLabel]: { [name]: newValue }
                }
            }
        }));


        const onChangeValues = name === 'yes' || name === 'no' || name === 'yes, regularly' || name === 'yes, occasionally' || name === 'no, i do not consume alcohol'
            ? { [checkboxLabel]: { id: fieldId, value, formId } }
            : { [formName]: { id: fieldId, value, formId } };

        setGetvalues((prevState: any) => ({
            ...prevState,
            ...onChangeValues
        }));



    };

    const getPersonalUsers = async (profile: string | null) => {
        getFormData("Hospitals Profile").then(
            (data) => {
                sethtmlFormUsers(data.result)
                let array: any = []
                const arrays: any = []
                data?.result?.map((item: any) => {

                    item?.user_form_fields.map((items: any) => {
                        array = JSON.parse(items.fieldJson);
                        array?.map((testing: any) => {
                            if (testing.title != "") {

                                testing?.components?.map((comp: any) => {
                                    if (comp.type === "checkbox") {
                                        let obj = {
                                            label: comp.label,
                                            type: comp.type,
                                            title: testing.title,


                                        }
                                        arrays.push(obj)

                                    }


                                })
                                const serarray: any = [];
                                arrays?.map((itemss: any) => {
                                    if (itemss.title === testing.title) {
                                        let obj = {
                                            title: itemss.title,
                                            value: [
                                                {
                                                    label: itemss.label
                                                }
                                            ]
                                        }
                                        serarray.push(obj)
                                    }
                                })

                                // sethtmlFormUsers([...htmlFormUsers, arrays, data.result])
                            }
                        })


                    })
                })
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setLoading(false);
                setMessage(resMessage);
            }
        );


    };

    const createOneDoctor = async () => {
        const id = patientId?.id
        try {
            const response = await PatientQuestionnarieCreate(getvalues, id);
            const patientData = response.data;
            // Handle response data as needed
        } catch (error: any) {
            const resMessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            // Handle error
        }
    };




    // const createOneDoctor = async () => {





    //     PatientQuestionnarieCreate(getvalues).then(
    //       (response) => {
    //         const patientData = response.data;
    //         // Handle response data as needed
    //       },
    //       (error) => {
    //         const resMessage =
    //           (error.response &&
    //             error.response.data &&
    //             error.response.data.message) ||
    //           error.message ||
    //           error.toString();
    //         // Handle error
    //       }
    //     );
    //   };




    //     const createOneDoctor = async () => {


    //         PatientQuestionnarieCreate(getvalues).then(

    //             (response) => {
    //                 const patientData = response.data;
    //                 // setTimeout(() => {
    //                 //   // navigate(route);
    //                 //   toast.setShowToast(patientData.message, "success");
    //                 // }, 1000);




    //               },
    //               (error) => {
    //                 const resMessage =
    //                   (error.response &&
    //                     error.response.data &&
    //                     error.response.data.message) ||
    //                   error.message ||
    //                   error.toString();

    //                 // setLoading(false);
    //                 // setMessage(resMessage);
    //               }
    //             );

    //  };



    let user: string;
    switch (searchParams.get('profile')) {
        case "Doctors Profile":
            user = 'Doctor';
            break;

        case "Patients Profile":
            user = "Patient";
            break;

        case "Staffs Profile":
            user = "Staff";
            break;
        case "Hospitals Profile":
            user = "Hospital";
            break;

        case "unknown":
            user = `User for ${searchParams.get('hospital')}`;
            break;

        default:
            user = `User for ${searchParams.get('hospital')}`;
    }

    const handleDownload = (indexes?: any) => {
        attachfile.map((item: any, index: number) => {

            if (index === indexes) {
                const link = document.createElement('a');
                link.href = item?.result;
                link.download = item?.fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        })

    };

    const handleDeleteDocument = (indexes?: any) => {
        //     attachfile.map((imtess:any,indexs:any)=>{
        // // if(indexs===index){
        // //   // setAttachedFile("");
        // // }
        //     })
        setAttachedFile(attachfile.filter((item: any, index: number) => index !== indexes))

        // setFileName("")

    };

    return (
        <Row>
            <div className="panel_top_section position-relative">
                <h1 className="ques-title">Questionnaire</h1> <span className="textgreen poppins-500 patient-ques"><i className="bi bi-chevron-right"></i> Patient Questionnaire </span>
                <div className="grid_icons d-lg-flex gap-1 align-items-center float-end">
                    {/* <button type="button" className="btn_gradian full_width"> + Add New Questionnaire </button> */}
                    {/* <button type="button" className="dark_btn text-white py-2 rounded-3 mx-1 px-2"> <span className="px-2 pl-0"><i className="bi bi-pen"></i></span>Edit</button> */}
                    {currentUser == "patient" &&
                        <div className="grid_list_icons  d-inline-block align-items-center gap-2">
                            <button
                                type="button"
                                className="delet_patiens"
                                onClick={() => setShowDeleteModal(true)}
                            >
                                <i className="bi bi-trash" />
                            </button>
                        </div>
                    }
                </div>
            </div>
            <Col className="mt-3 mb-4">
                <div className="add_doctor_form card_box col-sm-12 col-xl-9 mx-auto py-4 px-lg-4 mt-4">
                    <p className="m-0 text-center fs-4 mt-3 mb-4 poppins-500 pb-4">Patient Questionnaire</p>
                    <div className="add_doctor_fom px-2 px-lg-4">
                        {/* personal-inoformation */}
                        <div className="row justify-content-around mb-4 ">
                            <div className="col-lg-12 col-xl-12 mb-2">
                                <p className="textgreen poppins-600 fs-5 ">Personal Information</p>
                            </div>
                            <div className="row  col-lg-12 col-xl-12">
                                <div className="col-lg-12 col-xl-4 mb-4">
                                    <label htmlFor="firsname" className="form-label">First Name</label>
                                    <input type="text" className="form-control" id="firstname" disabled defaultValue={currentDoctor?.firstName} />
                                </div>
                                <div className="col-lg-12 col-xl-4 mb-4">
                                    <label htmlFor="phone" className="form-label">Date of Birth</label>
                                    <input type="date" className="form-control" id="phone" disabled defaultValue={dob} />
                                </div>
                                <div className="col-lg-12 col-xl-4 mb-4">
                                    <label htmlFor="Name" className="form-label">Gender</label>
                                    <div className="row mt-2">
                                        <div className="form-check col-6 col-lg-6">
                                            <input className="form-check-input" type="checkbox" value="" id="fenale" disabled checked={gender === "female" ? true : false} />
                                            <label className="form-check-label" htmlFor="female">
                                                Female
                                            </label>
                                        </div>
                                        <div className="form-check col-6 col-lg-6">
                                            <input className="form-check-input" type="checkbox" value="" id="male" disabled checked={gender === "male" ? true : false} />
                                            <label className="form-check-label" htmlFor="male">
                                                Male
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-xl-4 mb-4">
                                    <label htmlFor="address" className="form-label">Address</label>
                                    <input type="text" className="form-control" id="address" disabled defaultValue={address} />
                                </div>
                                <div className="col-lg-12 col-xl-4 mb-4">
                                    <label htmlFor="city" className="form-label">City</label>
                                    <input type="text" className="form-control" id="city" disabled value={currentDoctor?.city}/>
                                </div>
                                <div className="col-lg-12 col-xl-4 mb-4">
                                    <label htmlFor="zipcode" className="form-label">ZIP Code</label>
                                    <input type="text" className="form-control" id="zipcode" disabled value={currentDoctor?.zipCode}/>
                                </div>
                                <div className="col-lg-12 col-xl-4 mb-4">
                                    <label htmlFor="phone" className="form-label">Phone</label>
                                    <input type="text" className="form-control" id="phone" disabled defaultValue={currentDoctor?.phoneNumber} />
                                </div>
                                <div className="col-lg-12 col-xl-4 mb-4">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input type="text" className="form-control" id="email" disabled defaultValue={currentDoctor?.email} />
                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            {questionnaireFields.map((keys: any, index: number) => {
                                return (
                                    <div className="row justify-content-around  mb-4" key={index}>
                                        <div className="col-lg-12 col-xl-12 mb-2">
                                            <p className="textgreen poppins-600 fs-5 ">{keys.formName}</p>
                                        </div>
                                        {keys.user_form_fields.map((item: any, index: number) => {
                                            switch (item.type) {
                                                case "textfield":
                                                    return (
                                                        <div className="col-lg-12 col-xl-12 mb-4" key={index}>
                                                            <label className="form-label">{item.label}</label>
                                                            <input
                                                                className="form-control"
                                                                id={`${item.id}/${item.formId}`}
                                                                type="text"
                                                                onChange={handleChange}
                                                                name={item.label}
                                                                value={formValues[item.label]?.value}
                                                                disabled={location?.state?.patientId ? true : false}
                                                            />
                                                        </div>
                                                    )
                                                case "select":
                                                    return (
                                                        <div className="col-lg-12 col-xl-12 mb-4" key={index}>
                                                            <label className="form-label">{item.label}</label>
                                                            <select
                                                                className="form-select"
                                                                aria-label="Default select example"
                                                                defaultValue="-1"
                                                                onChange={handleChange}
                                                                id={`${item.id}/${item.formId}`}
                                                                name={item.label}
                                                                value={formValues[item.label]?.value}
                                                                disabled={location?.state?.patientId ? true : false}
                                                            >
                                                                <option value="-1">Select...</option>
                                                                {JSON.parse(item.defaultValue).map((option: any, index: number) => {
                                                                    return (
                                                                        <option value={option.label}>{option.label}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    )

                                                case "selectboxes":
                                                    return (
                                                        <div className="col-lg-12 col-xl-12 mb-4" key={index}>
                                                            <label className="form-label">{item.label}</label>
                                                            <div className="col-lg-12 row ms-2">
                                                                {JSON.parse(item.defaultValue)?.map((selectValue: any) => {
                                                                    return (
                                                                        <div className="d-flex align-items-center me-2 col-lg-12 ps-0">
                                                                            <input
                                                                                className="me-1"
                                                                                id={`${item.id}/${Number(item.formId)}`}
                                                                                type="radio"
                                                                                onChange={handleChange}
                                                                                name={item.label}
                                                                                value={selectValue.value}
                                                                                checked={formValues[item.label]?.value == selectValue.value}
                                                                                disabled={location?.state?.patientId ? true : false}
                                                                            />
                                                                            <span style={{ fontSize: "15px" }}>{selectValue.label}</span>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    )

                                                case "panel":
                                                    const fields = JSON.parse(item.fieldJson);
                                                    return (
                                                        <div className="mb-4">
                                                            {fields.map((panelItem: any, index: number) => {
                                                        switch (panelItem.type) {
                                                            case "textfield":
                                                                return (
                                                                            <div className="col-lg-12 col-xl-12 mb-2" key={index}>
                                                                        <label className="form-label">{panelItem.label}</label>
                                                                        <input
                                                                            className="form-control"
                                                                            id={`${panelItem.id}/${Number(item.formId)}`}
                                                                            type="text"
                                                                            onChange={handleChange}
                                                                            name={panelItem.label}
                                                                            value={formValues[panelItem.label]?.value}
                                                                            disabled={location?.state?.patientId ? true : false}
                                                                        />
                                                                    </div>
                                                                )
                                                            case "selectboxes":
                                                                return (
                                                                            <div className="col-lg-12 col-xl-12 mb-2" key={index}>
                                                                        <label className="form-label">{panelItem.label}</label>
                                                                        <div className="col-lg-6 row">
                                                                            {panelItem.values.map((selectValue: any) => {
                                                                                return (
                                                                                    <div className="d-flex align-items-center me-2 col-lg-3 ps-0">
                                                                                        <input
                                                                                            className="me-1"
                                                                                            id={`${panelItem.id}/${Number(item.formId)}`}
                                                                                            type="radio"
                                                                                            onChange={handleChange}
                                                                                            name={panelItem.label}
                                                                                            value={selectValue.value}
                                                                                            checked={formValues[panelItem.label]?.value == selectValue.value}
                                                                                            disabled={location?.state?.patientId ? true : false}
                                                                                        />
                                                                                        <span>{selectValue.label}</span>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                )

                                                                    case "select":
                                                                        return (
                                                                            <div className="col-lg-12 col-xl-12 mb-2" key={index}>
                                                                                <label className="form-label">{panelItem.label}</label>
                                                                                <select
                                                                                    className="form-select"
                                                                                    id={`${panelItem.id}/${Number(item.formId)}`}
                                                                                    onChange={handleChange}
                                                                                    name={panelItem.label}
                                                                                    value={formValues[panelItem.label]?.value}
                                                                                    disabled={location?.state?.patientId ? true : false}
                                                                                >
                                                                                    <option value={-1}>Select...</option>
                                                                                    {panelItem.data?.values?.map((option: any) => {
                                                                                        return (
                                                                                            <option value={option.label}>{option.label}</option>
                                                                                        )
                                                                                    })}
                                                                                </select>
                                                                            </div>
                                                                        )
                                                                }
                                                            })}
                                                        </div>
                                                    )

                                            }
                                        })}
                                        {/* <div className="row  col-lg-12 col-xl-12">
                                            <div className="col-lg-12 col-xl-12 mb-4">
                                                <label htmlFor="Name" className="form-label text_base">1. Do you have a family history of medical conditions? (e.g., heart disease, diabetes, cancer)</label>
                                                <div className="row mt-2">
                                                    <div className="form-check col-3 col-xl-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="no" />
                                                        <label className="form-check-label" htmlFor="no">
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div className="form-check col-3 col-xl-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="yes" />
                                                        <label className="form-check-label" htmlFor="yes">
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12">
                                                <div className="col-lg-12 col-xl-12 mb-4">
                                                    <label className="form-label">If yes, please provide details below.</label>
                                                    <textarea className="textarea_input form-control" id="address"></textarea>
                                                </div>
                                                <div className="col-lg-12 col-xl-12 mb-4">
                                                    <label className="form-label">2. Do you have any past medical conditions? (Please select all that apply)</label>
                                                    <div className="col-lg-12 col-xl-4">
                                                        <select className="form-select" aria-label="Default select example">
                                                            <option selected>Select</option>
                                                            <option value="1">Male</option>
                                                            <option value="2">Female</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-xl-12 mb-4">
                                                    <label className="form-label">Other (please specify)</label>
                                                    <textarea className="textarea_input form-control" id="specify"></textarea>
                                                </div>
                                            </div>

                                            <div className="col-sm-12">
                                                <div className="col-lg-12 col-xl-12 mb-4">
                                                    <label className="form-label">3. Do you have any allergies? (Please select all that apply)</label>
                                                    <div className="col-lg-12 col-xl-4">
                                                        <select className="form-select" aria-label="Default select example">
                                                            <option selected>Select</option>
                                                            <option value="1">Male</option>
                                                            <option value="2">Female</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-xl-12 mb-4">
                                                    <label className="form-label">Other (please specify)</label>
                                                    <textarea className="textarea_input form-control" id="specify"></textarea>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-xl-12 mb-4">
                                                <label htmlFor="Name" className="form-label text_base">4. Are you currently taking any medications?</label>
                                                <div className="row mt-2">
                                                    <div className="form-check col-3 col-xl-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="no" />
                                                        <label className="form-check-label" htmlFor="no">
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div className="form-check col-3 col-xl-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="yes" />
                                                        <label className="form-check-label" htmlFor="yes">
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 row">
                                                <div className="col-lg-12 col-xl-8 mb-4">
                                                    <label htmlFor="medications" className="form-label">If yes, please list all medications you are currently taking.</label>
                                                    <input type="text" className="form-control" id="medications" />
                                                </div>
                                                <div className="col-lg-12 col-xl-4 mb-4">
                                                    <label htmlFor="address" className="form-label">Frequency of Use</label>
                                                    <select className="form-select" aria-label="Default select example">
                                                        <option selected>Select</option>
                                                        <option value="1">Male</option>
                                                        <option value="2">Female</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                )
                            })}
                            {questionnaireFields?.length > 0 ?
                                <div className="form_bottom_btn d-flex justify-content-end">
                                    <button className="btn_gradian" type="button" onClick={handleSubmit}>
                                        Submit
                                    </button>
                                </div>
                                :
                                <div className="text-center text-danger">
                                    No questionnaires available regarding your appointment.
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Col>
            <DeleteModal
                show={showDeleteModal}
                handleYes={deleteQuestionnaire}
                handleClose={() => setShowDeleteModal(false)}
                body="Do you really want to delete this loaction?"
                head="Delete Location"
            />
        </Row>
    );
};

export default PatientQuestionnaire;