import {
      Row,
      Col,
} from "reactstrap";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RoleContext } from "../../../utils/contexts/RoleContext";
import { getAppointments } from "../../../services/scheduleappointment-service";
import { longDateGenerator } from "../../../utils/utils";
import constants from "../../../config/constants";
import { capitalizeFirstLetter } from "../../../utils/utils";

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

const TelehealthDetail = () => {
      const [appointmentDetails, setAppointmentDetails] = useState<any>({});
      const [patientDetails, setPatientDetails] = useState<any>({});
      const [doctorDetails, setDoctorDetails] = useState<any>({});

      const navigate = useNavigate()
      const currentUser = useContext(RoleContext);
      const { roomId }: any = useParams();

      useEffect(() => {
            checkAppointments();
            document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Telehealth Detail`;
      }, [currentUser]);

      async function checkAppointments() {
            const room = roomId;
            const response = await getAppointments(room);
            if (response?.success) {
                  const appointmentDetails = { ...response?.data?.appointmentDetails }
            // const dateString = new Date(appointmentDetails?.date);
            // const longDate = dateString.getDate() + " " + months[dateString.getMonth()] + ", " + dateString.getFullYear();
            const longDate = longDateGenerator(appointmentDetails?.date)
            appointmentDetails.formattedDate = longDate;
            setAppointmentDetails(appointmentDetails);
                  const docDetails = { ...response?.data.doctorDetails }
                  docDetails?.user_form_data?.forEach((item: any) => {
                  if (item.formFieldKey?.toLowerCase().includes("special") || item.formFieldKey?.toLowerCase().includes("department")) {
                        docDetails.specialty = item.formFieldValue
                  }
            });

            const patientDetails = { ...response.data.patientDetails };
                  patientDetails?.userdata?.forEach((item: any) => {
                  if (item.fieldKey?.toLowerCase()?.replace(".", "").includes("dateofbirth") || item.fieldKey?.toLowerCase()?.replace(".", "").includes("dob")) {
                        patientDetails.dob = longDateGenerator(JSON.parse(item.fieldValue));
                  }
            });
            setDoctorDetails(docDetails);
            setPatientDetails(patientDetails)
      }
      }

      function validateDateTime() {
            // const splittedTime = appointmentDetails.time.split(":");
            // const dateString = new Date(appointmentDetails.date)
            // dateString.setHours(splittedTime[0]);
            // dateString.setMinutes(splittedTime[1]);
            // const appointmentDate = new Date(dateString);
            // const startingTime = appointmentDate.getTime();
            // const endingTime = startingTime + (60 * 60 * 1000);
            // if (new Date().getTime() > startingTime && new Date().getTime() < endingTime) {
            //       navigate(`/${currentUser}/tele-video`, {
            //             state: {
            //                   room:searchParams.get('room'),
            //                   appointmentId: appointmentDetails.id
            //             }
            //       })
            // }
            // else {
            //       if (new Date().getTime() < startingTime) {
            //             alert('Your meeting is not started yet. Come back later')
            //       } else if (new Date().getTime() > endingTime) {
            //             alert('Meeting has been ended')
            //       }
            // }
            navigate(`/${currentUser}/tele-video`, {
                  state: {
                        room: roomId,
                        appointmentId: appointmentDetails.id
                  }
            });
      }

      return (
            <Row>
                  <div className="panel_top_section">
                        <h1>Telehealth</h1> <span className="textgreen poppins-500"><i className="bi bi-chevron-right"></i> Appointment Detail </span>
                        {/* <div className="grid_icons d-flex gap-1 align-items-center float-end">
                              <button
                                    type="button"
                                    className="btn_gradian"
                              // onClick={() => navigate(`/${currentUser}/schedule_appointment`)}
                              > + Schedule a Telehealth </button>
                              <button type="button" className="dark_btn text-white py-2 rounded-3 mx-1 px-2"> <span className="px-2 pl-0"><i className="bi bi-pen"></i></span>Edit</button>
                              <div className="grid_list_icons icons_patients  d-flex align-items-center gap-2">
                                    <button type="button" className="delet_patiens"> <i className="bi bi-trash"></i> </button>
                              </div>
                        </div> */}
                  </div>
                  <Col className="mt-3 mb-4">
                        <div className="add_location col-sm-12 py-4 px-lg-2 mt-2">
                              <div className="row m-0 justify-content-center">
                                    <div className="col-xl-5 p-1 pb-2">
                                          <div className="inner_location float-start w-100 bg-white box_shadow p-4 radius_15">
                                                {/* Appointment Details */}
                                                <div className="float-start w-100 pb-4 px-xl-3">
                                                      <h4 className="px-1 textgreen poppins-600 fs-5  border-bottom pb-4 mb-3">Appointment Details</h4>
                                                      <div className="pat_group py-2 d-flex justify-content-between">
                                                            <div className="col-sm-4 row_1 px-1">
                                                                  <span className="d-block text_sm text_gray ">Appointment Date</span>
                                                                  <p className="m-0 poppins-500 dark_blue fs-6">{appointmentDetails?.formattedDate}</p>
                                                            </div>
                                                            <div className="col-sm-4 row_1 px-1">
                                                                  <span className="d-block text_sm text_gray ">Appointment Time</span>
                                                                  <p className="m-0 poppins-500 dark_blue fs-6">{appointmentDetails?.time}</p>
                                                            </div>
                                                            <div className="col-sm-4 row_1 px-1">
                                                                  <span className="d-block text_sm text_gray ">Assigned Doctor</span>
                                                                  <p className="m-0 poppins-500 dark_blue fs-6">{appointmentDetails?.doctorName}</p>
                                                            </div>
                                                      </div>
                                                </div>
                                                {/* patient information */}
                                                <div className="float-start w-100 pb-4 px-xl-3">
                                                      <h4 className="px-1 textgreen poppins-600 fs-5  border-bottom pb-4 mb-3">Patient Information</h4>
                                                      <div className="pat_group py-2 p d-flex justify-content-between">
                                                            <div className="col-sm-4 row_1 px-1">
                                                                  <span className="d-block text_sm text_gray ">Full Name</span>
                                                                  <p className="m-0 poppins-500 dark_blue fs-6">{patientDetails?.firstName + " " + patientDetails?.lastName}</p>
                                                            </div>
                                                            <div className="col-sm-4 row_1 px-1">
                                                                  <span className="d-block text_sm text_gray ">Birth Date</span>
                                                                  <p className="m-0 poppins-500 dark_blue fs-6">{patientDetails.dob}</p>
                                                            </div>
                                                            <div className="col-sm-4 row_1 px-1">
                                                                  <span className="d-block text_sm text_gray ">Reason</span>
                                                                  <p className="m-0 poppins-500 dark_blue fs-6">{appointmentDetails?.reason}</p>
                                                            </div>
                                                      </div>
                                                      <div className="pat_group py-2 d-flex justify-content-between">
                                                            <div className="col-sm-4 row_1 px-1">
                                                                  <span className="d-block text_sm text_gray ">Phone</span>
                                                                  <p className="m-0 poppins-500 dark_blue fs-6">{patientDetails?.phoneNumber}</p>
                                                            </div>
                                                            <div className="col-sm-8  row_1 px-1">
                                                                  <span className="d-block text_sm text_gray ">Email</span>
                                                                  <p className="m-0 poppins-500 dark_blue fs-6 text-break">{patientDetails?.email}</p>
                                                            </div>
                                                      </div>
                                                </div>
                                                {/* Doctor Information */}
                                                <div className="float-start w-100 pb-0 px-xl-3">
                                                      <h4 className="px-1 textgreen poppins-600 fs-5  border-bottom pb-4 mb-3">Doctor Information</h4>
                                                      <div className="pat_group py-2 p d-flex justify-content-between">
                                                            <div className="col-sm-4 row_1 px-1">
                                                                  <span className="d-block text_sm text_gray ">Full Name</span>
                                                                  <p className="m-0 poppins-500 dark_blue fs-6">{doctorDetails?.firstName + " " + doctorDetails?.lastName}</p>
                                                            </div>
                                                            {doctorDetails.specialty &&
                                                            <div className="col-sm-8 row_1 px-1">
                                                                  <span className="d-block text_sm text_gray ">Specialty</span>
                                                                        <p className="m-0 poppins-500 dark_blue fs-6">{doctorDetails.specialty}</p>
                                                            </div>
                                                            }
                                                      </div>
                                                      <div className="pat_group py-2 d-flex justify-content-between">
                                                            <div className="col-sm-4 row_1 px-1">
                                                                  <span className="d-block text_sm text_gray ">Phone</span>
                                                                  <p className="m-0 poppins-500 dark_blue fs-6">{doctorDetails?.phoneNumber}</p>
                                                            </div>
                                                            <div className="col-sm-8  row_1 px-1">
                                                                  <span className="d-block text_sm text_gray ">Email</span>
                                                                  <p className="m-0 poppins-500 dark_blue fs-6 text-break">{doctorDetails?.email}</p>
                                                            </div>
                                                      </div>
                                                </div>
                                                {/* start telehealth */}
                                                <div className="start_telehealth float-start text-center w-100 mt-4 px-xl-3 ">
                                                      <button
                                                            className="bg_gradiant text-white rounded-3 poppins-500 py-2 w-100 fs-4 text-uppercase border-0 mb-2"
                                                            type="button"
                                                            onClick={validateDateTime}
                                                      >
                                                            START TELEHEALTH
                                                      </button>
                                                      <button
                                                            type="button"
                                                            className="bg-transparent border-0 text_gray cancel_btn"
                                                            onClick={() => navigate(`/${currentUser}/dashboard`)}
                                                      >
                                                            Cancel
                                                      </button>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </Col>
            </Row>
      );
};

export default TelehealthDetail;