import React, { useEffect, useState } from "react";

import { getPublicContent } from "../../services/user.service";
import constants from "../../config/constants";

const Home: React.FC = () => {
  const [content, setContent] = useState<string>("");

  useEffect(() => {
    getPublicContent().then(
      (response) => {
        setContent(response?.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setContent(_content);
      }
    );
    document.title = `${constants.metaTitle}Home`;
  }, []);

  return (
    <div className="container">
      <header className="jumbotron">
        {/* <h3>{content}</h3> */}
        <div >
        <h3 style={{textAlign:"center"}}>Welcome to Iboost Healthcare</h3>
        </div>
      </header>
    </div>
  );
};

export default Home;
