import axios from "axios";
import { END_POINT } from "../config/constants";


// const API_URL = "http://localhost:5000/api/doctor/";

// const APIS_URL="http://localhost:5000/api/dragdrop/"

const API_URL = `${END_POINT}doctor/`

const APIS_URL = `${END_POINT}dragdrop/`


export const getalldoctors = (page:number | undefined, formType?:string) => {
    return axios
      .get(`${API_URL}getdoctors?page=${page}&formType=${formType}`)
      .then((response) => {
        return response?.data;
      });
  };

  export const getonedoctor = (id:string) => {
    return axios
      .get(API_URL + "getdoctorId/"+id, {
      
      })
      .then((response) => {
     
  
        return response?.data;
      });
  };

  export const deleteoneform = (id:any) => {
    return axios
      .delete(APIS_URL + "deleteform/"+id, {
      
      })
      .then((response) => {
     
  
        return response?.data;
      });
  };

  export const updateform = (id:string,dragdropform:any) => {
    return axios
      .put(APIS_URL + "dragformupdate/"+id, {
        dragdropform
      
      })
      .then((response) => {
     
  
        return response?.data;
      });
  };

  export const updateformfield = (id:string,dragdroptable:any) => {
    return axios
      .put(APIS_URL + "user_form_fields_update/"+id, {
        dragdroptable
      
      })
      .then((response) => {
     
  
        return response?.data;
      });
  };

  export const createform = (dragdropform:any) => {
    return axios
      .post(APIS_URL + "dragformcreate", {
        dragdropform
      
      })
      .then((response) => {
     
  
        return response?.data;
      });
  };

  export const createformfield = (dragdroptable:any) => {
    return axios
      .post(APIS_URL + "user_form_fields", {
        dragdroptable
      
      })
      .then((response) => {
     
  
        return response?.data;
      });
  };

  export const getformdetail = (id:any) => {
    return axios
      .get(APIS_URL + "drag_all_data/"+id, {
      
      })
      .then((response) => {
     
  
        return response?.data;
      });
  };

  export const createformdata= (fieldvalue:any) => {
    return axios
      .post(APIS_URL + "user_form_data", {
        fieldvalue
      
      })
      .then((response) => {
     
  
        return response?.data;
      });
  };

  export const getFormData = (key:any) => {
    return axios
      .get(APIS_URL + "gerformdata/"+key, {
      
      })
      .then((response) => {
     
  
        return response?.data;
      });
  };

  export const getFormDetails = (id: number) => {
    return axios
      .get(APIS_URL + "get-form-details/"+id)
      .then((response) => {
        return response?.data;
      });
  };