import Modal from 'react-bootstrap/Modal';

export default function DeleteModal(props:any) {
    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                {/* <Modal.Title>{head}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{body}</Modal.Body>
            <Modal.Footer>
                <button onClick={handleClose}>
                    Cancel
                </button>
                <button onClick={handleYes}>
                    Delete
                </button>
            </Modal.Footer> */}
                <Modal.Title>{props.head}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.body}</Modal.Body>
        <Modal.Footer>

          <button className="bg_gray_500 rounded border-0 py-2 px-4" onClick={props.handleClose}>
            Cancel
          </button>
          <button className="bg_gradiant text-white rounded border-0 py-2 px-4" onClick={props.handleYes}>
            {props.buttonName ? props.buttonName : "Delete" }
          </button>
        </Modal.Footer>
        </Modal>
    )

}