import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import "../../../App.css"

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from "reactstrap";
import React, { useContext, useEffect, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';


import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import IUser from '../../../types/user.type';
import Modal from 'react-bootstrap/Modal';
import { RoleContext, AlertContext, HospitalContext } from '../../../utils/contexts/RoleContext';
import { createRoot } from 'react-dom/client';
import { deletePatientData } from "../../../services/patient-service";
import { deleteoneuser } from '../../../services/user.service';
import { getallpatient, importPatientCreate } from "../../../services/patient-service";
import SearchInput from '../../../components/SearchInput';
import { createChatUser } from '../../../services/messages-service';
import Pagination from '../../../components/Pagination';
import { decidePages } from '../../../utils/utils';
import Spinner from 'react-bootstrap/Spinner';
import CustomToolTip from '../../../components/CustomToolTip';
import constants from '../../../config/constants';
import { capitalizeFirstLetter } from '../../../utils/utils';
import { onePatientBookingAppointment } from '../../../services/scheduleappointment-service';
import { longDateGenerator } from '../../../utils/utils';
import PhoneNumber from '../../../components/PhoneNumber';

// Row Data Interface
interface IRow {
  mission: string;
  company: string;
  location: string;
  date: string;
  time: string;
  rocket: string;
  price: number;
  successful: boolean;
}
const endPoint = "http://localhost:3000/";
// Create new GridExample component
const PatientForm = () => {
  const currentUser = useContext(RoleContext);
  const navigate = useNavigate()

  const toast: any = useContext(AlertContext);

  // Row Data: The data to be displayed.
  const [rowData, setRowData] = useState([]);
  const [show, setShow] = useState(false);
  const [more, setMore] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [patients, setPatients] = useState([])
  const [deleteId, setDeleteId] = useState<string>("");
  const [pages, setPages] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [popup, showPopup] = useState(false);
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [recordsToBeDeleted, setRecordsToBeDeleted] = useState<any>([]);
  const [file, setFile] = useState<any>();
  const [array, setArray] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const hospitalContext: any = useContext(HospitalContext);



  // Column Definitions: Defines & controls grid columns.
  const [colDefs] = useState<ColDef[]>([
    { field: 'FirstName' },
    { field: 'LastName' },
    { field: 'Email' },
    { field: 'PhoneNumber' },
    { field: "FieldUpdate", cellRenderer: totalValueRenderer },
    { field: "FieldDelete", cellRenderer: deleteForm }
    // { field: 'price' },
    // { field: 'successful' },
    // { field: 'rocket' },
  ]);

  // Fetch data & update rowData state
  useEffect(() => {
    getUsers();
    document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Patients`;

    // fetch(`${endPoint}patient/get_patient`) // Fetch data from server
    //   .then((result) => result.json()) // Convert to JSON
    //   .then((rowData) => setRowData(rowData)); // Update state of `rowData`
  }, [currentUser, hospitalContext.selectedHospital]);

  const showForm = (id?: any) => {
    if (id != undefined) {
      handleShow()
      setDeleteId(id)
    }
    else {
      handleShow()
      setDeleteId(deleteId)
    }
  }


  const handleDelete = async (id: any) => {

    deleteoneuser(id).then(
      (response) => {
        // const doctorData = response.result;

        getUsers();
        // setRowData(response.result)

        // navigate("/profile");
        // window.location.reload();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        // setLoading(false);
        // setMessage(resMessage);
      }
    );
  }

  const getUsers = (page?: number) => {
    const pageCount = page != undefined ? page : 1;
    setLoading(true);
    getallpatient(pageCount, hospitalContext.selectedHospital?.id).then(
      async (response) => {
        let offset = (pageCount - 1) * 10;
        const patients = response?.result?.slice(offset, offset + 10);
        setLoading(false);
        if (response?.success) {
          const arr: any = [];
          var check: any;
          check = await Promise.all(patients?.map(async (item: any) => {
            const itemCopy = { ...item };
            itemCopy?.user_form_data?.forEach((element: any) => {
              if (element?.formFieldKey == "insuranceProvider") {
                itemCopy.insuranceProvider = element?.formFieldValue;
              }
            });
            itemCopy?.userdata?.forEach((data: any) => {
              switch (data.fieldKey) {
                case 'gender':
                  itemCopy.gender = data.fieldValue.replace(/^"(.*)"$/, '$1');
                  break;
                case 'dateOfBirth':
                  itemCopy.dateOfBirth = data.fieldValue.replace(/^"(.*)"$/, '$1');
                  break;
                case 'height':
                  itemCopy.height = data.fieldValue.replace(/^"(.*)"$/, '$1');
                  break;
                case 'weight':
                  itemCopy.weight = data.fieldValue.replace(/^"(.*)"$/, '$1');
                  break;
                case 'memberStatus':
                  itemCopy.memberStatus = data.fieldValue.replace(/^"(.*)"$/, '$1');
                  break;
                case 'address':
                  itemCopy.address = data.fieldValue.replace(/^"(.*)"$/, '$1');
                  break;
                default:
                  break;
              }
            });
            const patientAppointments = itemCopy.booking_appointments;
            itemCopy["lastAppointment"] = patientAppointments[patientAppointments?.length - 1];
            arr.push(itemCopy);
            return check;
          })
          );
          setPatients(arr);
          setRowData(arr);
          const pages = decidePages(response?.patientCount);
          setPages(pages);
          setPageNumber(pageCount)
        }
      },
      (error) => {
        setLoading(false);
        console.log("Error", error)

        // setLoading(false);
        // setMessage(resMessage);
      }
    );
  }

  const handlePagination = (pageNumber: number) => {
    setPageNumber(pageNumber);
    setPatients(rowData.slice(pageNumber * 10 - 9, pageNumber * 10 + 1))
    setPagination(rowData);
  }

  const setPagination = (data: any) => {
    let pageLength: number = Math.floor(data?.length / 10) + 1;
    const arr = [];
    for (let i = 1; i < pageLength + 1; i++) {
      arr.push(i)
    }
    setPages(arr);
  }

  function totalValueRenderer(props: any) {

    // setFieldId(props.data.Id)


    return (
      <span>

        <button className='btn btn-primary'
          // onClick={() => navigate('/edit')}
          onClick={() => navigate(`/${currentUser}/editpatient`, { state: { key: props.data.Id } })

          }
        >Edit</button>
      </span>
    );
  };


  function deleteForm(props: any, id: any) {


    return (
      <div>
        <span>
          <button className='btn btn-primary'
            onClick={() => {
              handleShow();
              setDeleteId(props.data.Id)
            }
            }
          // onClick={() => console.log("checkingsss",props)}
          >Delete</button>
        </span>
      </div>
    );
  };

  const onSearchChange = (e: any) => {
    if (e.target.value === null || e.target.value === "") {
      getUsers()
    }
    else {

      const targetvalue = e.target.value;
      const values = rowData.filter((items: any, i: any) => Object.values(items).join(" ").toLowerCase().match(targetvalue.toLowerCase()));
      setRowData(values)
    }


  }

  const handleClick = (roleId: any) => {
    if (currentUser === "admin") {
      navigate(`/${currentUser}/hospital`, { state: { roleId: roleId, what: "user" } });
    } else {
      navigate(`/${currentUser}/add-user/${roleId}`, { state: { roleId: roleId } });
    }
  }

  const extractPatientDetails = (patient: IUser) => {
    let patientData = { ...patient };

    patientData.userdata?.forEach((data: any) => {
      switch (data.fieldKey) {
        case 'gender':
          patientData.gender = data.fieldValue.replace(/^"(.*)"$/, '$1');
          break;
        case 'dateOfBirth':
          patientData.dateOfBirth = data.fieldValue.replace(/^"(.*)"$/, '$1');
          break;
        case 'height':
          patientData.height = data.fieldValue.replace(/^"(.*)"$/, '$1');
          break;
        case 'weight':
          patientData.weight = data.fieldValue.replace(/^"(.*)"$/, '$1');
          break;
        case 'memberStatus':
          patientData.memberStatus = data.fieldValue.replace(/^"(.*)"$/, '$1');
          break;
        case 'address':
          patientData.address = data.fieldValue.replace(/^"(.*)"$/, '$1');
          break;
        default:
          break;
      }
    });

    return patientData;
  };

  function handleRadioChange(item: any) {
    const deleteArray = [...recordsToBeDeleted];
    const check = deleteArray.find(((element: any) => element.id == item.id));
    if (check === undefined) {
      setRecordsToBeDeleted((prevState: any) => ([...prevState, item]))
    } else {
      setRecordsToBeDeleted((prevState: any) => (prevState.filter((element: any) => element.id != item.id)));
    }
  }

  function decideStatus(item: any) {
    let decideCheck = false
    recordsToBeDeleted.forEach((element: any) => {
      if (element.id == item.id) {
        decideCheck = true;
        return false;
      }
    })
    return decideCheck;
  }

  const handleChange = (e: any) => {
    // setFile(e.target.files[0]);
    e.preventDefault();
    const files = e.target.files[0]

    if (files) {

      let reader = new FileReader();
      reader.onload = function (event: any) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      reader.readAsText(files);
    }
  };

  const csvFileToArray = (string: any) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = csvRows.map((i: any) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object: any, header: any, index: any) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });
    setArray(array);
    importPatientUser(array);
  };

  const importPatientUser = (arrayvalue: any) => {
    importPatientCreate(arrayvalue).then(
      (response) => {
        if (response.success) {
          setTimeout(() => {
            toast.setShowToast("Patient imported successfully!", "success");
          }, 1000);
          getUsers();
        } else {
          toast.setShowToast("Some user in your file is already registered. Please cross check the details!.", "danger");
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        // setLoading(false);
        // setMessage(resMessage);
      }
    );
  }

  async function createChatUsers(id: number) {
    const response = await createChatUser(id);
    navigate(`/${currentUser}/messages`, { state: { chatRoomId: response.data.room } });
  }


  // Container: Defines the grid's theme & dimensions.
  return (
    <>
        <Row>
          <div className="panel_top_section position-relative">
            <h1>Patients</h1>
            <div className="grid_icons d-flex gap-1 align-items-center float-end">

              {/* <div> */}
              <button
                type="button"
                className="btn_gradian"
                onClick={() => handleClick('5')}
              >
                + Add New Patient
              </button>
              {/* <button type="button" className="btn_gradian dark_btn"> <span className="px-2"><i className="bi bi-download"></i></span>Import Patient</button> */}
              <div className='btn_gradian dark_btn d-flex align-items-center' >
                <input type="file" multiple id="upload" hidden name=".csv" onChange={(e) => {
                  // handleFileUpload(e, item.id, item.formId, keys.formName);
                  handleChange(e)
                }} accept=".csv, .xlsx, .xls, text/plain" />
                <label htmlFor="upload"><span className="px-2"><i className="bi bi-download"></i></span>Import Patient</label>
              </div>
              {/* </div> */}

              <div className="grid_list_icons icons_patients  d-flex align-items-center gap-2">
                {recordsToBeDeleted?.length > 0 &&
                  <button
                    type="button"
                    className="delet_patiens"
                    onClick={() => showForm()}
                  >
                    <i className="bi bi-trash"></i>
                  </button>
                }
                <SearchInput
                  visible={showSearch}
                  data={rowData}
                  setData={(value: any) => setPatients(value)}
                />
                <span
                  className="filter_icons fs-3 px-1"
                  onClick={() => setShowSearch(!showSearch)}
                >
                  <i className="bi bi-funnel-fill" />
                </span>
              </div>
            </div>
          </div>
          <Col>
            <div className="row d-flex table-responsive">
              <table>
                {currentUser === "staff" ? (
                  <thead>
                    <tr className="list_head">
                      <th><span className="d-block float-start p-0 bg-transparent">
                        <input
                          className="float-start form-check-input"
                          type="checkbox"
                          name="name"
                          id="name"
                          checked={(recordsToBeDeleted?.length === patients?.length && patients?.length !== 0) ? true : false}
                          onChange={() => {
                            if (recordsToBeDeleted?.length === patients?.length) {
                              setRecordsToBeDeleted([]);
                            } else {
                              const data = [...patients];
                              setRecordsToBeDeleted(data);
                            }
                          }}
                        />
                      </span>
                        <label className="p-0 bg-transparent" htmlFor="name">Name</label>
                      </th>
                      <th>Email</th>
                      <th>Insurance Provider</th>
                      <th>Date of Birth</th>
                      <th>Address</th>
                      <th>Phone number</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                ) : (
                  <thead>
                    <tr className="list_head">
                      <th><span className="d-block float-start p-0 bg-transparent">
                        <input
                          className="float-start form-check-input"
                          type="checkbox"
                          name="name"
                          id="name"
                          checked={recordsToBeDeleted?.length === patients?.length ? true : false}
                          onChange={() => {
                            if (recordsToBeDeleted?.length === patients?.length) {
                              setRecordsToBeDeleted([]);
                            } else {
                              const data = [...patients];
                              setRecordsToBeDeleted(data);
                            }
                          }}
                        />
                      </span>
                        <label className="p-0 bg-transparent" htmlFor="name">Name</label>
                      </th>
                      <th>Email</th>
                      <th>Doctors seen</th>
                      <th>Last Appointment</th>
                      <th>Address</th>
                      <th>Phone number</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                )}

                {currentUser === "staff" ? (
                  <tbody>
                    {patients?.length > 0 ?
                      <>
                        {patients?.map((patient: any, index: number) => {
                          return (
                            <tr className={`list_detail ${more === patient.id ? 'active' : ''}`} key={index + patient.firstName}
                              // onClick={() => setMore(patient.id)}
                              onClick={() => {
                                navigate(`/${currentUser}/patientdetail/${patient?.id}`, { state: { key: patient.id } });
                              }}
                            >

                              <td><span className="px-1 d-block float-start">
                                <input
                                  className="float-start form-check-input"
                                  type="checkbox"
                                  name="name"
                                  id="name2"
                                  onChange={() => {
                                    handleRadioChange(patient);
                                    // setShowDeleteIcon(true);
                                    // setDeleteId(item.id)
                                  }}
                                  checked={decideStatus(patient)}
                                // onClick={() => { setShowDeleteIcon(true); setDeleteId(patient.id) }}
                                />
                              </span>
                                <label htmlFor="name2">{patient.firstName + " " + patient.lastName}</label>
                              </td>
                              <td onClick={(e) => e.stopPropagation()}><a href={`mailto:${patient.email}`}>{patient.email}</a></td>
                              <td>{patient.insuranceProvider}</td>
                              <td>{patient.dateOfBirth}</td>
                              <td>{patient.address ? patient.address : "-"}</td>
                              <td onClick={(e) => e.stopPropagation()}><PhoneNumber phoneNumber={patient.phoneNumber} allowCall={patient.allowCall} /></td>

                              <td>


                                <span className="action" >
                                  <CustomToolTip title="chat">
                                    <button
                                      className="exclamation_btn position-relative"
                                    >
                                      <i className="bi bi-chat-text"
                                        onClick={(e: any) => {
                                          e.stopPropagation();
                                          createChatUsers(patient.id)
                                        }}
                                      ></i>
                                    </button>
                                  </CustomToolTip>
                                  <button className="exclamation_btn position-relative" ><i className="bi bi-exclamation-circle " onClick={() => {

                                    navigate(`/${currentUser}/patientdetail/${patient?.id}`, { state: { key: patient.id } });
                                    showPopup(!popup);
                                  }}></i>
                                    {/* {popup===true && */}
                                    <ul className="drop_list m-0 p-0 bg-white p-2  text-left">
                                      {/* <li><a href="#">Edit</a></li><li><a href="#">Delete</a></li> */}
                                      <li><a>{patient.firstName}</a></li>

                                      <li><a href="mailto:drethanbrown@mail.com">{patient.email}</a></li>
                                      <li> <PhoneNumber phoneNumber={patient.phoneNumber} allowCall={patient.allowCall} /></li>
                                      <li>{patient.role?.roleName}</li>
                                    </ul>
                                    {/* } */}
                                  </button>
                                </span>

                              </td>
                            </tr>
                          )
                        })}
                      </>
                      :
                      <tr className="list_detail">
                      <td colSpan={10}>{!loading ? "No data found" : <Spinner />}</td>
                      </tr>
                    }
                  </tbody>
                ) : (
                  <tbody>
                    {patients?.length > 0 ?
                      <>
                        {patients?.map((patient: any, index: number) => {
                          return (
                            <tr className={`list_detail ${more === patient.id ? 'active' : ''}`} key={index + patient.firstName}
                            onClick={() => {
                              navigate(`/${currentUser}/patientdetail/${patient?.id}`, { state: { key: patient.id } });
                            }}
                            >
                              <td>
                                <span className="px-1 d-block float-start">
                                  <input
                                    className="float-start form-check-input"
                                    type="checkbox"
                                    name="name"
                                    id="name2"
                                    // onClick={() => {
                                    //   setShowDeleteIcon(true);
                                    //   setDeleteId(patient.id);
                                    // }}
                                    onChange={() => {
                                      handleRadioChange(patient);
                                      // setShowDeleteIcon(true);
                                      // setDeleteId(item.id)
                                    }}
                                    checked={decideStatus(patient)}
                                  />
                                </span>
                                <label htmlFor="name2">{patient.firstName + " " + patient.lastName}</label>
                              </td>
                              <td><span className="fw-bold">{patient.email}</span></td>
                              <td>{patient.lastAppointment ? patient.lastAppointment?.doctorName : "-"}</td>
                              <td>{patient.lastAppointment ? longDateGenerator(patient.lastAppointment?.date) : "-"}</td>
                              <td>{patient.address ? patient.address : "-"}</td>
                              <td> <PhoneNumber phoneNumber={patient.phoneNumber} allowCall={patient.allowCall} /></td>
                              <td>
                                {more !== patient.id ?
                                  <span className="dost_btn" onClick={() => setMore(patient.id)}>
                                    <i className="bi bi-three-dots-vertical"></i>
                                  </span>

                                  :
                                  <span className="action" >
                                    <CustomToolTip title="edit">

                                      <button className="edit_btn">
                                        <i
                                          className="bi bi-pen"
                                          onClick={() => {
                                            navigate(`/${currentUser}/edit-user/${patient?.id}`, { state: { key: patient.id } });
                                          }}
                                        />
                                      </button>
                                    </CustomToolTip>
                                    <CustomToolTip title="delete">
                                      <button className="delete_btn_act" onClick={() => showForm(patient.id)} ><i className="bi bi-trash"></i></button>
                                    </CustomToolTip>



                                    {/* <button className="exclamation_btn"><i className="bi bi-exclamation-circle"></i></button> */}

                                    <button className="exclamation_btn position-relative" ><i className="bi bi-exclamation-circle " onClick={() => {

                                      navigate(`/${currentUser}/patientdetail/${patient?.id}`, { state: { key: patient.id } });
                                      showPopup(!popup);
                                    }}></i>
                                      {/* {popup===true && */}
                                      <ul className="drop_list m-0 p-0 bg-white p-2  text-left">
                                        {/* <li><a href="#">Edit</a></li><li><a href="#">Delete</a></li> */}
                                        <li><a>{patient.firstName}</a></li>

                                        <li><a href="mailto:drethanbrown@mail.com">{patient.email}</a></li>
                                        <li><PhoneNumber phoneNumber={patient.phoneNumber} allowCall={patient.allowCall} /></li>
                                        <li>{patient.role?.roleName}</li>
                                      </ul>
                                      {/* } */}
                                    </button>
                                  </span>
                                }
                              </td>
                            </tr>
                          )
                        })}
                      </>
                      :
                      <tr className="list_detail">
                      <td colSpan={10}>{!loading ? "No data found" : <Spinner />}</td>
                      </tr>
                    }
                  </tbody>
                )}

                {/* {pages?.length > 1 &&
                <tfoot>
                  <tr className="list_detail pagination_list">
                    <td colSpan={7}>
                      <ul>
                        <li onClick={() => handlePagination(pageNumber - 1)}><a href="#"><i className="bi bi-arrow-left"></i></a></li>
                        {pages.map((page: number) => {
                          return (
                            <li className={pageNumber == page ? "active" : ""} onClick={() => handlePagination(page)}><a>{page}</a></li>
                          )
                        })}
                        <li onClick={() => handlePagination(pageNumber + 1)}><a href="#"><i className="bi bi-arrow-right"></i></a></li>
                      </ul>
                    </td>
                  </tr>
                </tfoot>
              } */}
                {pages?.length > 1 &&
                  <tfoot>
                    <Pagination
                      pages={pages}
                      currentPage={pageNumber}
                      onClickPageNumber={(page: any) => {
                        setPageNumber(page);
                        getUsers(page);
                      }}
                    />
                  </tfoot>
                }
              </table>
            </div>

            <Modal show={show} onHide={() => handleClose()}>
              <Modal.Header closeButton>
                <Modal.Title>Delete Patient</Modal.Title>
              </Modal.Header>
              <Modal.Body>Do you want delete this patient ?</Modal.Body>
              <Modal.Footer>

                <button className="bg_gray_500 rounded border-0 py-2 px-4" onClick={() => handleClose()}>
                  Cancel
                </button>
                <button className="bg_gradiant text-white rounded border-0 py-2 px-4" onClick={() => {
                  if (deleteId) {
                    handleDelete(deleteId);
                    setDeleteId("");
                    setRecordsToBeDeleted((prevState: any) => (prevState.filter((item: any) => item.id != deleteId)))
                  } else {
                    recordsToBeDeleted.forEach((item: any) => {
                      handleDelete(item.id);
                    })
                  }
                  handleClose();
                }}>
                  Delete
                </button>
              </Modal.Footer>
            </Modal>
          </Col>

        </Row>
    </>
  );
};
export default PatientForm;