import Modal from 'react-bootstrap/Modal';

export default function ImagePreview(props: any) {
    function handleDownload() {
        const link = document.createElement('a');
        link.target = "_blank"
        link.href = props.data?.source;
        link.download = props.data?.fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            dialogClassName="modal-90w "
            aria-labelledby="example-custom-modal-styling-title"
            contentClassName="width_100"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {/* <p style={{
                        whiteSpace: 'nowrap',
                        width: '400px',
                        backgroundColor: 'red',
                        overflow: 'hidden',
                    }}
                    >
                        {props.data.fileName}
                    </p> */}
                    {props.data.fileName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <img
                    src={props.data.source}
                    style={{ height: "450px", width: "450px" }}
                />
            </Modal.Body>
            <Modal.Footer>
                <button className="bg_gray_500 rounded border-0 py-2 px-4" onClick={handleDownload}>
                    <i className="bi bi-download"></i>
                </button>
            </Modal.Footer>
        </Modal>
    )

}