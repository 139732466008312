import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme

import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { createadminsetting, deleteoneadmin, getalladmin, updateadminsetting } from '../../../services/subscription-service';
import { deleteoneuser, getalluser } from "../../../services/user.service";

import { AgGridReact } from 'ag-grid-react'; // React Grid Logic
import { ColDef } from 'ag-grid-community';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import { RoleContext } from '../../../utils/contexts/RoleContext';
import { useNavigate } from 'react-router-dom';
import userEvent from '@testing-library/user-event';

interface IRow {
    mission: string;
    company: string;
    location: string;
    date: string;
    time: string;
    rocket: string;
    price: number;
    successful: boolean;
}

function SubscriptionSales() {
    const currentUser = useContext(RoleContext);

    const [show, setShow] = useState(false);
    const [dropDownValue, setDropDownValues] = useState("")
    const [HospitalValue, setHospitalValue] = useState("")

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [deleteId, setDeleteId] = useState("")




    const navigate = useNavigate()


    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            filter: true,
            cellDataType: false,
        };
    }, []);



    const [rowData, setRowData] = useState<IRow[]>([])
    const [hospitalData, setHospitalData] = useState<IRow[]>([])


    const [fieldId, setFieldId] = useState("")
    const [loading, setLoading] = useState<boolean>(false);
    const [users, setUsers] = useState<any>([]);
    const [message, setMessage] = useState<string>("");
    const [colDefs, setColDefs] = useState<ColDef[]>([
        { field: "orderId" },
        { field: "order_date" },
        { field: "hospital_name" },
        { field: "payment_received" },
        { field: "total_amount" },
        { field: "invoice_number" },
        { field: "status" },

        {
            field: "FieldUpdate", cellRenderer: totalValueRenderer
        },
        // {
        //     field: "FieldDelete", cellRenderer: deleteForm
        // }
    ]);

    // const [colDefs, setColDefs] = useState([]);


    useEffect(() => {

        getAllUsers();
    }, [])




    const getAllUsers = () => {

        getalladmin().then(
            (data) => {
                const arr = [];
                const arr1 = [];
                // setUsers(data.allAdmins);


                for (let i = 0; i < data.allSubscription?.length; i++) {
                    // if(data.allAdmins[i].setting_type==="General"){

                    let obj: any = {}

                    obj.hospital_name = data.allSubscription[i].hospital_name
                    obj.total_amount = data.allSubscription[i].totalAmount
                    obj.payment_received = data.allSubscription[i].amountPaid
                    obj.status = data.allSubscription[i].orderStatus
                    obj.order_date=data.allSubscription[i].orderDate
                    // obj.due_date=data.allSubscription[i].due_date
                    obj.invoice_number=data.allSubscription[i].invoiceId
                    obj.orderId=data.allSubscription[i].orderId
                   

                    arr.push(obj)
                    // }




                }
                setRowData(arr)



            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setLoading(false);
                setMessage(resMessage);
            }
        );





    }

    const DeleteAllForms = (id: any) => {

        deleteoneadmin(id).then(
            () => {
                navigate(`/${currentUser}/sales/subscriptionsales`)
                handleClose();
                getAllUsers();
                // window.location.reload();
                
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setLoading(false);
                setMessage(resMessage);
            }
        );





    }
    function totalValueRenderer(props: any) {

        setFieldId(props.data.id)


        return (
            <span>

                <button className='btn btn-primary' disabled={props.data.status==="pending"?false:true}
                    onClick={() => navigate(`/${currentUser}/sales/editsubscriptionsales`, { state: { key: props.data.orderId} })}
                >View</button>
            </span>
        );
    };

    function deleteForm(props: any) {



        return (
            <span>

                <button className='btn btn-primary'
                    onClick={() => {
                        handleShow();
                        setDeleteId(props.data.id)
                    }}
                >Delete</button>
            </span>
        );
    };



    const onSearchChange = (e: any) => {
        if (e.target.value === null || e.target.value === "") {
            getAllUsers()
        }
        else {

            const targetvalue = e.target.value;
          
            const values = rowData.filter((items: any, i: any) => Object.values(items).join(" ").toLowerCase().match(targetvalue.toLowerCase()));
            setRowData(values)
        }


    }
   


    return (
        <div style={{ marginLeft: 50 }}>
            <div style={{ marginLeft: 50, fontWeight: "bold", fontSize: 22 }}>Subscription Sales</div>

            <div style={{ display: "flex", marginLeft: 50 }}>
                <label style={{ fontWeight: "bold", fontSize: 18 }}>search</label>
                <input type="search" onChange={(e) => onSearchChange(e)} style={{ marginLeft: 10, height: 30, marginTop: 10 }} />

                {/* <div className='add_button'>
                    <button
                        onClick={() => navigate({
                            pathname: "/settings/add_generalsetting"
                        })}
                    >
                        Add Form
                    </button>
                </div> */}


            </div>

            <div className="ag-theme-quartz whole-container" >

                <div className='ag-header-container grid-container' >





                    <AgGridReact rowData={rowData} columnDefs={colDefs} sideBar={'filters'}
                        defaultColDef={defaultColDef}
                    // onEditComplete={onEditComplete}
                    // editable={true}
                    //  onGridReady={onGridReady}
                    />

                </div>
            </div>

            <Modal show={show} onHide={() => handleClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Admin Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>Do you want delete this Admin settings?</Modal.Body>
                <Modal.Footer>
                    <button onClick={() => handleClose()}>
                        Cancel
                    </button>
                    <button onClick={() => DeleteAllForms(deleteId)}>
                        Delete
                    </button>
                </Modal.Footer>
            </Modal>
        </div>


    );
}
export default SubscriptionSales