import authHeader from "./auth-header";
import axios from "axios";
import { END_POINT } from "../config/constants";

// const APIS_URL = "http://localhost:5000/api/refillrequest/";
const APIS_URL = `${END_POINT}refillrequest/`

export const getallDoctor = () => {
  return axios
    .get(APIS_URL + "get_doctor?roleId=3", {

    })
    .then((response) => {
      return response?.data;
    });
};

export const deleteOnePrescription = (id: any) => {
  return axios
    .put(APIS_URL + `deleteoneprescription/${id}`, {

    })
    .then((response) => {


      return response?.data;
      // window.location.reload();
    });
};

export const getonprescription = (key: any) => {
  return axios
    .get(APIS_URL + "getoneprescription/" + key, {

    })
    .then((response) => {


      return response?.data;
    });
};

export const updateprofile = (key: string, values: object) => {
  return axios
    .put(APIS_URL + "updateprofile/" + key, {
      values
    })
    .then((response) => {
      return response?.data;
    });
};

export const createOneRefillrequest = (values: object,medication?:any) => {
  return axios
    .post(APIS_URL + "createonerefillrequest", {
      values,
      medication
    })
    .then((response) => {
      return response?.data;
    });
};

export const getNearPharmacyAddress = (key: any) => {
  return axios
    .get(APIS_URL + "getnearaddress/" + key, {

    })
    .then((response) => {


      return response?.data;
    });
};

export const createPharmacyDetails = (key: any) => {
  return axios
    .post(APIS_URL + "createpharmacydetail", {
      key
    })
    .then((response) => {


      return response?.data;
    });
};

export const getAllRefillRequest = (page?: any, hospitalId?: any) => {
  return axios
    .get(`${APIS_URL}getallrefillrequest?page=${page}&hospitalId=${hospitalId}`)
    .then((response) => {
      return response?.data;
    });
};

export const deleteRefill = (id: any) => {
  return axios
    .put(`${APIS_URL}delete-refill-request`, {
      id
    })
    .then((response) => {
      return response?.data;
    });
};

export const getOneRefill = (id: any)  => {
  return axios
    .get(`${APIS_URL}get-one-refill/${id}`)
    .then((response) => {
      return response?.data;
    });
};

export const updateOneRefill = (id: any, values: any) => {
  return axios
    .put(`${APIS_URL}update-refill-request/${id}`, {
      values
    })
    .then((response) => {
      return response?.data;
    });
};

export const sendRefill = (id: any, values: any) => {
  return axios
    .put(`${APIS_URL}send-refill/${id}`, {
      values
    })
    .then((response) => {
      return response?.data;
    });
};

export const updateRefillStatus = (id: any, status: any) => {
  return axios
    .put(`${APIS_URL}update-refill-status/${id}`, {
      status
    })
    .then((response) => {
      return response?.data;
    });
};
