import * as Yup from "yup";

import { ErrorMessage, Field, Form, Formik } from "formik";
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useState } from "react";
import { changepassword, getCurrentUser, resetpassword } from "../../services/auth.service";

import { RoleContext } from "../../utils/contexts/RoleContext";
import { getoneuser } from "../../services/user.service";
import { url } from "inspector";
import constants from "../../config/constants";
import { capitalizeFirstLetter } from "../../utils/utils";

type Props = {}

const ChangePassword: React.FC<Props> = () => {
  let navigate: NavigateFunction = useNavigate();
  let location = useLocation();
  const currentUser = useContext(RoleContext);


  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [urlToken, setUrlToken] = useState<string>("");
  const [users, setUsers] = useState<any>({});
  const [currentuser, setCurrentUser] = useState("");

  const initialValues: {
    password: string;
    confirmpassword: string;
    // url:string;

  } = {
    password: "",
    confirmpassword: "",
    // url:""

  };

  const validationSchema = Yup.object().shape({
    password: Yup.string().required("This field is required!"),
    confirmpassword: Yup.string().oneOf([Yup.ref('password'), null], "password mismatch"),

  });


  useEffect(() => {
    setCurrentUser(getCurrentUser()?.role)
    const userStr = localStorage.getItem("user");
    let user = null;
    if (userStr)
      user = JSON.parse(userStr);

    if (user && user?.accessToken) {
      // url=user.accessToken
      setUrlToken(user?.accessToken)


    }

    getoneuser(location?.state?.key).then(
      (response) => {
        setUsers(response?.allUsers);
      },
      (error) => {
        const resMessage =
          (error?.response &&
            error?.response?.data &&
            error?.response?.data?.message) ||
          error?.message ||

          error?.toString();

        setLoading(false);
        setMessage(resMessage);
      }
    );


    document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Change Password`;

  }, [currentUser])






  const handleLogin = (formValue: { password: string; confirmpassword: string; }) => {
    const { password, confirmpassword } = formValue;



    setMessage("");
    setLoading(true);

    changepassword(confirmpassword, users.id).then(
      (data) => {
        navigate(`/${currentUser}/getprofile`);
        // window.location.reload();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
      }
    );
  };

  return (
    <div className="col-md-12">
      <div className="card card-container">
        <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        />
        <p style={{ alignSelf: "center" }}>Change Password</p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleLogin}
        >
          <Form>
            <div className="form-group">
              <label htmlFor="password">New Password</label>
              <Field name="password" type="password" className="form-control" />
              <ErrorMessage
                name="password"
                component="div"
                className="alert alert-danger"
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirmpassword">Confirm Password</label>
              <Field name="confirmpassword" type="password" className="form-control" />
              <ErrorMessage
                name="confirmpassword"
                component="div"
                className="alert alert-danger"
              />
            </div>



            <div className="form-group">
              <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Submit</span>
              </button>
            </div>

            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default ChangePassword;
