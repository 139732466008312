
import profile from "../../../assets/images/doctor/profile.png";
import {
  Card,
  Row,
  Col,
  CardTitle,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import React, { useContext, useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getFormData, getformdetail } from "../../../services/form-service";
import { useSearchParams, useParams } from "react-router-dom";
import { createDoctor, getOneDoctor, updateDoctor } from "../../../services/doctor-service";
import { RoleContext, AlertContext, NotificationContext } from "../../../utils/contexts/RoleContext";
import { FolderPath, ImagePath } from "../../../services/auth.service";
import { getCurrentUser } from "../../../services/auth.service";
import { capitalizeFirstLetter } from "../../../utils/utils";

const roles = [
  {
    label: "Select...",
    value: "select/null"
  },
  {
    label: "Doctor",
    value: "Doctors Profile/3",
    roleId: "3"
  },
  {
    label: "Patient",
    value: "Patients Profile/5",
    roleId: "5"
  },
  {
    label: "Staff",
    value: "Staffs Profile/4",
    roleId: "4"
  },
  // {
  //   label: "Hospital",
  //   value: "Hospitals Profile/2",
  //   roleId: "2"

  // }
]

const DoctorSettingProfile = () => {

  const location = useLocation();
  const currentUser = useContext(RoleContext);
  const navigate = useNavigate();
  const [htmlFormUsers, sethtmlFormUsers] = useState<any>([])
  const [currentDoctor, getCurrentDoctor] = useState<any>({});
  const [getvalues, setGetvalues] = React.useState<any>({});
  const [searchParams] = useSearchParams();
  const [getImage, setImage] = useState<any>("");
  const [roleId, setRoleId] = useState<any>("");
  const toast: any = useContext(AlertContext);
  const [attachfile, setAttachedFile] = useState<any>(null)
  const [fileName, setFileName] = useState('');
  const { doctorId }: any = useParams();
  const [dragEmpData, setDragEmpData] = useState<any>([]);
  const [personalData, setPersonalData] = useState<any>([]);
  const [progress, setProgress] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [dragAllData, setDragAllData] = useState<any>([]);
  const [formUsers, getFormUsers] = React.useState<any>([]);
  const { role }: any = useParams();
  const [roleProfile, setRoleProfile] = useState<string>("");
  const [dropDownValue, setDropDownValues] = useState("");
  const [generatePassword, setGeneratePassword] = useState(true)

  const notification: any = useContext(NotificationContext);


  useEffect(() => {
    let user = getCurrentUser();
    if (currentUser != null) {
      let roleProfile;
      switch (currentUser) {
        case "doctor":
          roleProfile = 'Doctors Profile';
          break;
        case "patient":
          roleProfile = "Patients Profile";
          break;
        default:
          roleProfile = ""
          break;
      }
      setRoleProfile(roleProfile);
      setRoleId(role);
      getPersonalUsers(roleProfile);
      // getDoctor(user?.id)

    }
  }, [currentUser]);

  const getDoctor = async (user: any) => {
    // console.log("location?.state?.key", location?.state?.key)
    getOneDoctor(user).then(
      (data) => {

        const allDoctor = data?.allDoctors;
        setRoleId(allDoctor.roleId)
        // const obj1 = {
        const Basic_Information = {
          firstName: { value: allDoctor?.firstName, id: "5", formId: "10" },
          lastName: { value: allDoctor?.lastName, id: "5", formId: "10" },
          email: { value: allDoctor?.email, id: "5", formId: "10" },
          phoneNumber: { value: allDoctor?.phoneNumber, id: "5", formId: "10" },
          profile: { value: allDoctor?.profile, id: "5", formId: "10" },
          document: { value: allDoctor?.document, id: "5", formId: "10" },

        }
        // setAttachedFile(FolderPath + allDoctor?.document)
        // console.log("Dtheirer",allDoctor?.document)
        // setFileName(allDoctor?.document.split('/')[3])
        // }

        setGetvalues((prevState: any) => ({
          ...prevState, Basic_Information
        }))



        const userForm: any = {};
        // getCurrentDoctor(data.allDoctors)
        let array: any = [];
        allDoctor?.userdata?.map((item: any) => {
          console.log("itemsss", item)
          let objs: any = {}
          objs.fieldKey = item?.fieldKey
          objs.fieldValue = JSON.parse(item?.fieldValue)
          objs.formId = item?.formId
          objs.id = item?.user_form_field.id
          objs.label = item?.user_form_field.label
          objs.type = item?.user_form_field.type
          objs.defaultValue = item?.user_form_field?.defaultValue
          // console.log("check",userForm[item.user_form.formName])
          // obj.field = objs
          if (userForm[item?.user_form?.formName] === undefined) {
            userForm[item?.user_form?.formName] = [objs]
          } else {
            userForm[item?.user_form?.formName] = [...userForm[item?.user_form?.formName], objs]
          }

          array.push(objs)
          setGetvalues((prevState: any) => ({
            ...prevState, [item?.user_form?.formName]: { ...prevState[item?.user_form?.formName], [item?.fieldKey]: { id: item?.user_form_field?.id, value: JSON.parse(item?.fieldValue), formId: item?.formId } }
          }))
          // console.log("valuess",objs,personalData)
          // getCurrentDoctor(...currentDoctor,["fieldValue"]:JSON.parse(item.fieldValue))
          // obj[item.fieldKey]=JSON.parse(item.fieldValue)

        })
        // console.log("dhterietr",userForm)
        getFormUsers(array)
        // setPersonalData(array)
        //   setPersonalData(array)

        allDoctor?.user_form_data?.map((item: any) => {
          let obj: any = {}
          // obj[item.formFieldKey] = item.formFieldValue
          obj.fieldKey = item?.formFieldKey
          obj.fieldValue = item?.formFieldValue
          obj.formId = item?.formId
          obj.id = item?.user_form_field?.id
          obj.label = item?.user_form_field?.label
          obj.type = item?.user_form_field?.type
          obj.defaultValue = item?.user_form_field?.defaultValue

          // console.log("check",userForm[item.user_form.formName])
          // obj.field = objs
          if (userForm[item?.user_form?.formName] === undefined) {
            userForm[item?.user_form?.formName] = [obj]
          } else {
            userForm[item?.user_form?.formName] = [...userForm[item?.user_form?.formName], obj]
          }
          if (item?.formFieldKey === "documentUploads") {
            setAttachedFile(FolderPath + item.formFieldValue)
            setFileName(item.formFieldValue.split('/')[4])
          }



          // obj.field = objs
          array.push(obj)
          setGetvalues((prevState: any) => ({
            ...prevState, [item?.user_form?.formName]: { ...prevState[item?.user_form?.formName], [item?.formFieldKey]: { id: item?.user_form_field?.id, value: item?.formFieldValue, formId: item?.formId } }
          }))
        })
        getFormUsers(array)
        // sethtmlFormUsers(userForm)
        // console.log("objss", array,personalData)

        getCurrentDoctor(allDoctor)



      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
      }
    );


  };





  console.log('roleProfile', getvalues)

  // const handleSelect = (event: any) => {

  //   const profile = event.split("/")[0];
  //   const roleId = event.split("/")[1];

  //   setDropDownValues(event);
  //   if (roleProfile !== "select") {
  //     getPersonalUsers(roleProfile);
  //     setRoleId(role);
  //   } else {
  //     sethtmlFormUsers([]);
  //   }
  // };



  const FormOnChange = (event: any) => {
    const user = getCurrentUser();
    const { name, value, id } = event.target;
    const formName = event?.target?.id?.split("/")[2]
    const formId = event?.target?.id?.split("/")[1]
    const fieldId = event?.target?.id?.split("/")[0]
    // const name = event.target.name;
    // const value = event.target.value;
    // const obj = {
    //   "field_id": event.target.id,
    //   "field_key": event.target.name,
    //   "field_value": event.target.value
    // };
    // setGetvalues({ ...getvalues, [name]: value, "field_id": event.target.id });
    setGetvalues((prevState: any) => ({
      ...prevState, [formName]: { ...prevState[formName], [name]: { id: fieldId, value: value, formId: formId } }
    }))
  }

  const getPersonalUsers = async (Profile: string | null) => {
    getFormData(Profile).then(
      (data) => {
        console.log('hiiii', data.result)
        // data.result.map((item:any)=>{
        //   let obj={
        //  Object.keys()
        //   }
        // })


        sethtmlFormUsers(data.result)
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
      }
    );


  };



  const createOneDoctor = async () => {
    console.log("Dtjeuroeire", getvalues['Basic_Information']?.firstName?.value)
    if (getvalues['Basic_Information']?.firstName == undefined) {
      alert("please fill the firstname")
    } else if (getvalues['Basic_Information']?.lastName == undefined) {
      alert("please fill the lastname")
    } else if (getvalues['Basic_Information']?.email) {
      if (!validateEmail(getvalues['Basic_Information']?.email?.value)) {
        alert("please fill the email")
      }
    } else if (getvalues['Basic_Information']?.phoneNumber) {
      if (!validatePhone(getvalues['Basic_Information']?.phoneNumber?.value)) {
        alert("please fill the phonenumber")
      }

    }
    const formValues = { ...getvalues }
    for (let key in formValues) {
      for (let i in formValues[key]) {
        formValues[key][i].roleId = roleId
      }
    }

    console.log("roleiddd", roleId, formValues)

    updateDoctor(formValues, getCurrentUser()?.id).then(
      (data) => {
        // console.log("doctor", data)
        if (data.message) {
          // let route;
          // switch (searchParams.get('profile')) {
          //     case "Doctors Profile":
          //         route = `/${currentUser}/doctordetaillist`;
          //         break;

          //     case "Patients Profile":
          //         route = `/${currentUser}/patient-list`;
          //         break;

          //     case "Staffs Profile":
          //         route = `/${currentUser}/stafflist`;
          //         break;

          //     case "unknown":
          //         route = `/${currentUser}/getprofile`;
          //         break;

          //     default:
          //         route = `/${currentUser}/doctordetaillist`;
          // }
          notification.setProfile();
          setTimeout(() => {
            // navigate(route);
            toast.setShowToast(data.message, "success");
          }, 1000);
        }
        // const checkvalue = data.formData;
        // console.log("checkvaluess", JSON.parse(checkvalue))

        // sethtmlFormUsers(data)
        // formdisplay2(data.formId);
        // navigate(`/${currentUser}/doctordetailist`)



      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
        setTimeout(() => {
          // navigate(route);
          toast.setShowToast(resMessage, 'danger');
        }, 1000);
      }
    );


  };


  const validateEmail = (email: string) => {
    console.log("emailssss", email)
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email)
  };

  const validatePhone = (phone: string) => {
    var phoneNum = phone.replace(/[^\d]/g, '');
    if (phoneNum.length > 6 && phoneNum.length < 11) {
      return true;
    }
    return false
  };

  const handleFileUpload = (post: any, id: any, formid: any, formname: any) => {

    const formName = formname
    const formId = formid
    const fieldId = id
    if (post.target.files && post.target.files.length > 0) {
      const filearray: any = []
      const fileBase64: any = []
      const result: any = []
      for (let i = 0; i < post.target.files.length; i++) {
        console.log("postssss", post.target.files[i])
        let file = post.target.files[i];


        filearray.push(file.name)
        setFileName(filearray);

        let reader = new FileReader();
        if (file) {
          const maxSize = 5 * 1024 * 1024;
          if (file.size > maxSize) {
            alert('Can not upload this file. Max Size is 5MB');
            post.target.value = '';
          } else {


            const fileSize = file.size;
            const chunkSize = 1024 * 1024; // 1MB chunk size (you can adjust this as needed)
            const chunks = Math.ceil(fileSize / chunkSize);
            let uploadedChunks = 0;
            for (let i = 0; i < chunks; i++) {
              const start = i * chunkSize;
              const end = Math.min(fileSize, start + chunkSize);


              uploadedChunks++;
              //  const percentage= Math.floor((uploadedChunks * 100) / chunks)
              const percentage = Math.min(100, Math.round((uploadedChunks / chunks) * 100));
              // console.log("rpogieruer", percentage)
              setProgress(percentage)

            }



            reader.readAsDataURL(file);
            reader.onload = () => {
              // console.log("filess", file, reader)
              let obj = {
                fileName: file.name,
                result: reader.result
              }
              fileBase64.push(obj)
              // console.log("filebaseee", fileBase64)
              if (attachfile === null) {
                setAttachedFile(fileBase64);
              }
              else {
                const valueset = attachfile?.concat(fileBase64)
                setAttachedFile(valueset);
              }
              // setAttachedFile(reader.result);
              // let obj={}
              const results = { result: reader.result, fileName: file.name.split('.')[1] };

              result.push(results)
              // console.log("resultsss", result)
              // setGetvalues((prevState: any) => ({
              //   ...prevState, ['Basic Information']: { ...prevState['Basic Information'], result }
              // }))
              setGetvalues((prevState: any) => ({
                ...prevState, [formName]: { ...prevState[formName], [post.target.name]: { id: fieldId, value: result, formId: formId } }
              }))

            };
          }
        }
      }
    }
  };

  const handleImageUpload = (post: any) => {
    let images: any = [];
    if (post.target.files && post.target.files.length > 0) {
      for (let i = 0; i < post.target.files.length; i++) {
        // setImage(post.target.files[i].name)
        let file = post.target.files[i];
        let reader = new FileReader();
        if (file) {
          reader.readAsDataURL(file);
          reader.onload = () => {
            images.push({ source: reader.result });
            // setImages([...images]);
            // setGetvalues({ ...getvalues, ['profile']: images });
            setImage(reader.result);
            const profile = { value: images }
            setGetvalues((prevState: any) => ({
              ...prevState, ['Basic_Information']: { ...prevState['Basic_Information'], profile }
            }))
          };
        }
      }
    }
  };

  let user: string;

  switch (roleProfile) {
    case "Doctors Profile":
      user = 'Doctor';
      break;

    case "Patients Profile":
      user = "Patient";
      break;

    case "Staffs Profile":
      user = "Staff";
      break;
    case "Hospitals Profile":
      user = "Hospital";
      break;

    default:
      user = "";
      break;

  }


  const handleDownload = (indexes?: any) => {
    attachfile.map((item: any, index: number) => {

      if (index === indexes) {
        const link = document.createElement('a');
        link.href = item?.result;
        link.download = item?.fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    })

  };

  const handleDeleteDocument = (indexes?: any) => {
    // console.log("Dteireuuerer")
    //     attachfile.map((imtess:any,indexs:any)=>{
    // //       console.log("Dtjer",indexs===index)
    // // if(indexs===index){
    // //   // setAttachedFile("");
    // // }
    //     })
    setAttachedFile(attachfile.filter((item: any, index: number) => index !== indexes))

    // setFileName("")

  };











  return (
    <Row>
      <div className="panel_top_section border-0 pb-0 pt-2">
        <h1>Settings</h1>
      </div>
      <Col className="mt-3 mb-4">
        <div className="setting_form card_box col-sm-12 col-xl-12 mx-auto py-4 px-lg-4 mt-4 row">
          <div className="sidebar_left col-xl-4 border-end px-2 px-lg-0 mt-4">
            <button className=" d-flex align-items-center col-12 col-lg-9 bg-transparent text-start border-0 fs-6 px-2 py-1 rounded-pill mb-2" type="button"
              onClick={() => navigate(`/${currentUser}/password-settings`)}

            ><span className="d-inline-block px-2 fs-4"><i className="bi bi-shield-lock-fill"></i></span> Password Settings </button>
            <button className="active d-flex align-items-center col-12 col-lg-9 bg-transparent text-start border-0 fs-6 px-2 py-1 rounded-pill mb-2" type="button"
              onClick={() => navigate(`/${currentUser}/profile-settings`)}

            ><span className="d-inline-block px-2 fs-4"><i className="bi bi-person-fill-gear"></i></span>{`${capitalizeFirstLetter(currentUser)} Profile`}</button>
          </div>
          <div className="settging_right px-2 px-xl-0 col-xl-8 mt-3">
            <h2 className="poppins-400 fw-normel fs-4 dark_blue px-4 mx-2 mb-4">{`${capitalizeFirstLetter(currentUser)} Profile`}</h2>
            {/* profile */}
            <div className="row justify-content-around border-bottom mb-4 pb-4 px-lg-4">
              <div className="col-lg-12 col-xl-4 d-flex align-items-center">
                <p className=" text-black-50 fw-medium">Profile</p>
              </div>
              <div className="col-lg-12 col-xl-8">
                {/* <div className="profile_image">
                              <div className="col-2 col-sm-2 col-lg-2 choose_file d-inline-block">
                                <span id="profile-image-upload"> <img src={profile} alt="profile"/></span>
                              </div>  
                              <input id="profile-image-upload" className="d-none" type="file" ></input>
                              <label htmlFor="profile-image-upload" className="d-inline-block m-0  px-2 px-xl-4">Upload Profile Photo</label>
                            </div> */}
                <div className="profile_image">
                  <div className="col-2 col-sm-2 col-lg-2 choose_file d-inline-block">
                    {/* <span id="profile-image-upload"> 
                      <img src={getImage !== "" ? getImage : profile} alt="profile" className="clickable rounded-circle" onClick={(e) => handleImageUpload(e)} />
                      </span> */}
                    {getImage != "" ?
                      <span id="profile-image-upload"> <img className="clickable rounded-circle" src={getImage} alt="profile" /></span>
                      :
                      currentDoctor?.profile != 0 ?
                        <span id="profile-image-upload"> <img className="clickable rounded-circle" src={`${ImagePath}` + 'profile/thumb/small_' + currentDoctor?.profile} alt="profile" /></span>
                        :

                        <span id="profile-image-upload"> <img className="clickable rounded-circle" src={profile} alt="profile" /></span>
                    }
                  </div>
                  <input type="file" id="profile" name="profile" accept="image/*" hidden onChange={(e) => {
                    handleImageUpload(e);
                  }}></input>
                  <label htmlFor="profile" className="d-inline-block m-0  px-2 px-xl-4 clickable">Upload Profile Photo</label>
                </div>
              </div>
            </div>

            <div className="row justify-content-around border-bottom mb-4 pb-4">
              <div className="col-lg-12 col-xl-4">
                <p className="text-black-50 fw-medium">Personal Information</p>
              </div>
              <div className="row  col-lg-12 col-xl-8">
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="firsname" className="form-label">First Name<span className="required-color">*</span></label>
                  <input type="text" className="form-control" id="1/10/Basic_Information" onChange={FormOnChange} name="firstName" defaultValue={currentDoctor?.firstName} required />
                </div>
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="Name" className="form-label">Last Name<span className="required-color">*</span></label>
                  <input type="text" className="form-control" id="2/10/Basic_Information" onChange={FormOnChange} name="lastName" defaultValue={currentDoctor?.lastName} required />
                </div>
                {currentUser !== 'patient' &&
                  <>
                    <div className="col-lg-12 col-xl-6 mb-3">
                      <label htmlFor="phone" className="form-label">Phone<span className="required-color">*</span></label>
                      <input type="text" className="form-control" id="3/10/Basic_Information" onChange={FormOnChange} name="phoneNumber" defaultValue={currentDoctor?.phoneNumber} required />
                    </div>
                    <div className="col-lg-12 col-xl-6 mb-3">
                      <label htmlFor="email" className="form-label">Email<span className="required-color">*</span></label>
                      <input type="text" className="form-control" id="4/10/Basic_Information" onChange={FormOnChange} name="email" defaultValue={currentDoctor?.email} required />
                    </div>
                  </>
                }

                {htmlFormUsers?.map((keys: any) => {
                  if (keys.formName === "Personal Information") {
                    return (
                      <>
                        {keys.user_form_fields.map((item: any) => {
                          return (
                            <>
                              {item.type === "select" ?
                                <div className="col-lg-12 col-xl-6 mb-3">
                                  <label htmlFor="phone" className="form-label">{item.label}</label>
                                  <select className="form-select" aria-label="Default select example" name={item.fieldKey} id={`${item.id + "/" + item.formId + "/" + keys.formName}`} onChange={e => FormOnChange(e)}  >
                                    {JSON.parse(item.defaultValue)?.map((item: any, index: number) => {
                                      return (
                                        <option value={item.value} selected key={index + item.value}>{item.label}</option>
                                      )
                                    })}
                                  </select>
                                </div> : null}
                              {item.type == "textfield" ?
                                <div className="col-lg-12 col-xl-6 mb-3">
                                  <label htmlFor="license" className="form-label">{item.label}</label>
                                  <input type={item?.type} className="form-control" id={`${item.id + "/" + item.formId + "/" + keys.formName}`} onChange={FormOnChange} name={item.fieldKey} />
                                </div> : null}

                              {item.type === "datetime" ?
                                <div className="col-lg-12 col-xl-6 mb-3">
                                  <label htmlFor="phone" className="form-label">{item.label}</label>
                                  <input type="date" className="form-control" id={`${item.id + "/" + item.formId + "/" + keys.formName}`} onChange={FormOnChange} name={item.fieldKey} />
                                </div> : null}

                              {item.type === "textarea" ?
                                <div className="col-sm-12 mb-3 textarea">
                                  <label htmlFor="address" className="form-label">{item.label}</label>
                                  <textarea className="textarea_input form-control" id={`${item.id + "/" + item.formId + "/" + keys.formName}`} onChange={FormOnChange} name={item.fieldKey}></textarea>
                                </div> : null}
                            </>
                          )
                        })}
                      </>
                    )
                  }
                })}

                {/* {htmlFormUsers?.map((item: any) => {
                  if (item === "Personal Information") {
                            return (
                              <>
                                {item.type === "select" ?
                                  <div className="col-lg-12 col-xl-6 mb-3">
                                    <label htmlFor="phone" className="form-label">{item.label}</label>

                                    <select className="form-select" aria-label="Default select example" name={item.fieldKey} id={`${item.id + "/" + item.formId + "/" + keys}`} onChange={e => FormOnChange(e)} defaultValue={item.fieldValue} >
                              <option value="-1">Select...</option>
                                      {JSON.parse(item.defaultValue)?.map((item: any) => {
                                        return (
                                  <option value={item.value} >{item.label}</option>
                                        )
                                      })}

                                    </select>


                                  </div> : null}
                                {item.type == "textfield" ?
                                  <div className="col-lg-12 col-xl-6 mb-3">
                                    <label htmlFor="license" className="form-label">{item.label}</label>
                                    <input type={item?.type} className="form-control" id={`${item.id + "/" + item.formId + "/" + keys}`} onChange={FormOnChange} name={item.fieldKey} defaultValue={item.fieldValue} />
                                  </div> : null}

                                {item.type === "datetime" ?
                                  <div className="col-lg-12 col-xl-6 mb-3">
                                    <label htmlFor="phone" className="form-label">{item.label}</label>
                                    <input type="date" className="form-control" id={`${item.id + "/" + item.formId + "/" + keys}`} onChange={FormOnChange} name={item.fieldKey} defaultValue={item.fieldValue} />
                                  </div> : null}

                                {item.type === "textarea" ?
                                  <div className="col-sm-12 mb-3 textarea">
                                    <label htmlFor="address" className="form-label">{item.label}</label>
                                    <textarea className="textarea_input form-control" id={`${item.id + "/" + item.formId + "/" + keys}`} onChange={FormOnChange} name={item.fieldKey} defaultValue={item.fieldValue}></textarea>
                                  </div> : null}
                              </>
                            )
                  }
                })} */}

                {currentUser === "patient" &&
                  <div className="row justify-content-around border-bottom mb-4 pb-4">
                    <div className="col-lg-12 col-xl-4">
                      <p className="text-black-50 fw-medium">Contact Information</p>
                    </div>
                    <div className="row  col-lg-12 col-xl-8">

                      <div className="col-lg-12 col-xl-6 mb-3">
                        <label htmlFor="phone" className="form-label">Phone<span className="required-color">*</span></label>
                        <input type="text" className="form-control" id="3/10/Basic_Information" onChange={FormOnChange} name="phoneNumber" defaultValue={currentDoctor?.phoneNumber} />
                      </div>
                      <div className="col-lg-12 col-xl-6 mb-3">
                        <label htmlFor="email" className="form-label">Email<span className="required-color">*</span></label>
                        <input type="text" className="form-control" id="4/10/Basic_Information" onChange={FormOnChange} name="email" defaultValue={currentDoctor?.email} />
                      </div>

                    </div>
                  </div>
                }

                <div className="row justify-content-around ">
                  {htmlFormUsers?.map((keys: any) => {
                    if (keys.formName !== "Personal Information" && keys.formName !== "undefined") {
                      return (
                        <div className="row justify-content-around border-bottom mb-4 pb-4">
                          <div className="col-lg-12 col-xl-4">
                            <p className="text-black-50 fw-medium">{keys.formName}</p>
                          </div>
                          <div className="row col-lg-12 col-xl-8">
                            {keys.user_form_fields.map((item: any) => {
                              return (
                                <>
                                  {item.type === "select" ?
                                    <div className="col-lg-12 col-xl-6 mb-3">
                                      <label htmlFor="phone" className="form-label">{item.label}</label>
                                      <select
                                        className="form-select"
                                        aria-label="Default select example"
                                        name={item.fieldKey}
                                        id={`${item.id + "/" + item.formId + "/" + keys.formName}`}
                                        onChange={e => FormOnChange(e)}
                                        value={getvalues[keys.formName] ? getvalues[keys.formName][item.fieldKey]?.value : ""}
                                      >
                                        <option value="-1">Select...</option>
                                        {JSON.parse(item.defaultValue)?.map((item: any) => {
                                          return (
                                            <option value={item.label}>{item.label}</option>
                                          )
                                        })}

                                      </select>


                                    </div> : null}
                                  {item.type == "textfield" ?
                                    <div className="col-lg-12 col-xl-6 mb-3">
                                      <label htmlFor="license" className="form-label">{item.label}</label>
                                      <input
                                        type={item?.type}
                                        className="form-control"
                                        id={`${item.id + "/" + item.formId + "/" + keys.formName}`}
                                        onChange={FormOnChange}
                                        name={item.fieldKey}
                                        value={getvalues[keys.formName] ? getvalues[keys.formName][item.fieldKey]?.value : ""}
                                      />
                                    </div> : null}

                                  {item.type === "datetime" ?
                                    <div className="col-lg-12 col-xl-6 mb-3">
                                      <label htmlFor="phone" className="form-label">{item.label}</label>
                                      <input
                                        type="date"
                                        className="form-control"
                                        id={`${item.id + "/" + item.formId + "/" + keys.formName}`}
                                        onChange={FormOnChange}
                                        name={item.fieldKey}
                                        value={getvalues[keys.formName] ? getvalues[keys.formName][item.fieldKey]?.value : ""}
                                      />
                                    </div> : null}

                                  {item.type === "textarea" ?
                                    <div className="col-sm-12 mb-3 textarea">
                                      <label htmlFor="address" className="form-label">{item.label}</label>
                                      <textarea
                                        className="textarea_input form-control"
                                        id={`${item.id + "/" + item.formId + "/" + keys.formName}`}
                                        onChange={FormOnChange}
                                        name={item.fieldKey}
                                        value={getvalues[keys.formName] ? getvalues[keys.formName][item.fieldKey]?.value : ""}
                                      >
                                      </textarea>
                                    </div> : null}
                                  {item.type === "file" ?
                                    // <div className="row justify-content-around border-bottom mb-4 pb-4">
                                    //     <div className="col-lg-12 col-xl-4">
                                    //         <p className=" text-black-50 fw-medium">{item.label}</p>
                                    //     </div>
                                    //     <div className="row col-lg-12 col-xl-8">
                                    //         <div className="col-sm-12">
                                    //             <input type="file" id="upload" name={item.fieldKey} hidden onChange={(e) => {
                                    //                 handleFileUpload(e, item.id, item.formId, keys);
                                    //             }} accept=".csv, .pdf, .doc, .docx, .xlsx, .xls, text/plain, .jpeg" />
                                    //             <label className="upload_btn" htmlFor="upload"><span className="up_arrow"><i className="bi bi-cloud-arrow-up-fill"></i></span>Upload</label>
                                    //         </div>
                                    //     </div>
                                    // </div>
                                    <div className="row justify-content-around border-bottom mb-4 pb-4">
                                      <div className="col-lg-12 col-xl-4">
                                        <p className=" text-black-50 fw-medium">{item.label}</p>
                                      </div>
                                      <div className=" col-lg-12 col-xl-8">
                                        <div className="col-xl-12 mb-4">
                                          <input type={item.type} id="upload" hidden name={item.fieldKey} onChange={(e) => {
                                            handleFileUpload(e, item.id, item.formId, keys.formName);
                                          }} accept=".csv, .pdf, .doc, .docx, .xlsx, .xls, text/plain" />
                                          <label className="upload_btn" htmlFor="upload"><span className="up_arrow"><i className="bi bi-cloud-arrow-up-fill clickable"></i></span>Upload</label>
                                        </div>
                                        <div className="col-xl-12">
                                          {attachfile != null &&

                                            <div className="border w-100 rounded float-start p-0 d-flex align-items-center">
                                              <span className="fs-4 d-inline-block py-2 px-3  border-end"><i className="bi bi-file-earmark-text-fill"></i></span>
                                              <span className="px-2 text_gray py-2 px-3 text-truncate ">{fileName}</span>
                                              <button className=" px-3 fs-4 py-2 border-0 bg-transparent border-start" onClick={() => handleDownload()}><i className="bi bi-box-arrow-down"></i></button>
                                              <button className=" px-3 fs-4 py-2 border-0 bg-transparent border-start" onClick={() => handleDeleteDocument()}><i className="bi bi-x-circle"></i></button>
                                            </div>
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    : null}
                                </>
                              )
                            })}
                          </div>
                        </div>
                    )
                  }
                })}
                </div>



              </div>
            </div>
            {/* <div className="row justify-content-around border-bottom mb-4 pb-4 px-lg-4">
              <div className="col-lg-12 col-xl-4">
                <p className="text-black-50 fw-medium">Professional Information</p>
              </div>
              <div className="row  col-lg-12 col-xl-8">
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="license" className="form-label">Medical License Number</label>
                  <input type="text" className="form-control" id="license" />
                </div>
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="phone" className="form-label">Specialty/Department</label>
                  <select className="form-select" aria-label="Default select example">
                    <option selected>Select</option>
                    <option value="1">Specialty1</option>
                    <option value="2">Specialty2</option>
                  </select>
                </div>
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="attended" className="form-label">Medical School Attended</label>
                  <input type="text" className="form-control" id="attended" />
                </div>
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="training" className="form-label">Residency Training</label>
                  <input type="text" className="form-control" id="training" />
                </div>
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="experience" className="form-label">Years of Experience</label>
                  <input type="text" className="form-control" id="experience" />
                </div>
                <div className="col-sm-12 mb-3 textarea">
                  <label htmlFor="biography" className="form-label">Biography</label>
                  <textarea className="textarea_input form-control" id="biography"></textarea>
                </div>
              </div>
            </div>
        
            <div className="row justify-content-around border-bottom mb-4 pb-4 px-lg-4">
              <div className="col-lg-12 col-xl-4">
                <p className=" text-black-50 fw-medium">Employment Information</p>
              </div>
              <div className="row col-lg-12 col-xl-8">
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="status" className="form-label">Employment Status</label>
                  <select className="form-select" aria-label="Default select example">
                    <option selected>Select</option>
                    <option value="1">Status</option>
                    <option value="2">Status</option>
                  </select>
                </div>
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="phone" className="form-label">Start Date</label>
                  <input type="date" className="form-control" id="date2" />
                </div>
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="status" className="form-label">Job Title/Position</label>
                  <select className="form-select" aria-label="Default select example">
                    <option selected>Select</option>
                    <option value="1">Job1</option>
                    <option value="2">Job2</option>
                  </select>
                </div>
              </div>
            </div> */}

            {/* {htmlFormUsers?.map((keys: any, index: number) => {
              if (keys.formName !== "Personal Information" && keys.formName!=="Doctor Document") {
                return (
                  <div className="row justify-content-around border-bottom mb-4 pb-4" key={index + keys.formName}>
                    <div className="col-lg-12 col-xl-4">
                      <p className="text-black-50 fw-medium">{keys.formName}</p>
                    </div>
                    <div className="row  col-lg-12 col-xl-8">
                      {keys.user_form_fields.map((item: any) => {
                        return (
                          <>
                            {item.type === "select" ?
                              <div className="col-lg-12 col-xl-6 mb-3">
                                <label htmlFor="phone" className="form-label">{item.label}</label>

                                <select
                                  className="form-select"
                                  aria-label="Default select example"
                                  name={item.fieldKey} id={`${item.id + "/" + item.formId + "/" + keys.formName}`}
                                  onChange={e => FormOnChange(e)}
                                >
                                  {JSON.parse(item.defaultValue)?.map((item: any, index: number) => {
                                    return (
                                      <option value={item.value} selected key={index + item.value}>{item.label}</option>
                                    )
                                  })}

                                </select>


                              </div> : null}
                            {item.type == "textfield" ?
                              <div className="col-lg-12 col-xl-6 mb-3">
                                <label htmlFor="license" className="form-label">{item.label}</label>
                                <input type={item?.type} className="form-control" id={`${item.id + "/" + item.formId + "/" + keys.formName}`} onChange={FormOnChange} name={item.fieldKey} />
                              </div> : null}

                            {item.type === "datetime" ?
                              <div className="col-lg-12 col-xl-6 mb-3">
                                <label htmlFor="phone" className="form-label">{item.label}</label>
                                <input type="date" className="form-control" id={`${item.id + "/" + item.formId + "/" + keys.formName}`} onChange={FormOnChange} name={item.fieldKey} />
                              </div> : null}

                            {item.type === "textarea" ?
                              <div className="col-sm-12 mb-3 textarea">
                                <label htmlFor="address" className="form-label">{item.label}</label>
                                <textarea className="textarea_input form-control" id={`${item.id + "/" + item.formId + "/" + keys.formName}`} onChange={FormOnChange} name={item.fieldKey}></textarea>
                              </div> : null}
                          </>
                        )
                      })}
                    </div>
                  </div>
                )
              }
            })} */}
            {/* {Object.keys(htmlFormUsers)?.map((keys: any) => {
              if (keys !== "Personal Information") {
                return (
                  <div className="row justify-content-around border-bottom mb-4 pb-4">
                    <div className="col-lg-12 col-xl-4">
                      <p className="text-black-50 fw-medium">{keys}</p>
                    </div>
                    <div className="row col-lg-12 col-xl-8">
                      {htmlFormUsers[keys]?.map((item: any) => {
                        console.log("itemshteir", item.type)
                        return (
                          <>
                            {item.type === "select" ?
                              <div className="col-lg-12 col-xl-6 mb-3">
                                <label htmlFor="phone" className="form-label">{item.label}</label>

                                <select className="form-select" aria-label="Default select example" name={item.fieldKey} id={`${item.id + "/" + item.formId}`} onChange={e => FormOnChange(e)} defaultValue={item.fieldValue} >
                                  <option value="-1">Select...</option>
                                  {JSON.parse(item.defaultValue)?.map((item: any) => {
                                    return (
                                      <option value={item.value}>{item.label}</option>
                                    )
                                  })}

                                </select>


                              </div> : null}
                            {item.type == "textfield" ?
                              <div className="col-lg-12 col-xl-6 mb-3">
                                <label htmlFor="license" className="form-label">{item.label}</label>
                                <input type={item?.type} className="form-control" id={`${item.id + "/" + item.formId}`} onChange={FormOnChange} name={item.fieldKey} defaultValue={item.fieldValue} />
                              </div> : null}

                            {item.type === "datetime" ?
                              <div className="col-lg-12 col-xl-6 mb-3">
                                <label htmlFor="phone" className="form-label">{item.label}</label>
                                <input type="date" className="form-control" id={`${item.id + "/" + item.formId}`} onChange={FormOnChange} name={item.fieldKey} defaultValue={item.fieldValue} />
                              </div> : null}

                            {item.type === "textarea" ?
                              <div className="col-sm-12 mb-3 textarea">
                                <label htmlFor="address" className="form-label">{item.label}</label>
                                <textarea className="textarea_input form-control" id={`${item.id + "/" + item.formId}`} onChange={FormOnChange} name={item.fieldKey} defaultValue={item.fieldValue}></textarea>
                              </div> : null}
                            {item.type === "file" ?
                              // <div className="row justify-content-around border-bottom mb-4 pb-4">
                              //     <div className="col-lg-12 col-xl-4">
                              //         <p className=" text-black-50 fw-medium">{item.label}</p>
                              //     </div>
                              //     <div className="row col-lg-12 col-xl-8">
                              //         <div className="col-sm-12">
                              //             <input type="file" id="upload" name={item.fieldKey} hidden onChange={(e) => {
                              //                 handleFileUpload(e, item.id, item.formId, keys);
                              //             }} accept=".csv, .pdf, .doc, .docx, .xlsx, .xls, text/plain, .jpeg" />
                              //             <label className="upload_btn" htmlFor="upload"><span className="up_arrow"><i className="bi bi-cloud-arrow-up-fill"></i></span>Upload</label>
                              //         </div>
                              //     </div>
                              // </div>
                              <div className="row justify-content-around border-bottom mb-4 pb-4">
                                <div className="col-lg-12 col-xl-4">
                                  <p className=" text-black-50 fw-medium">{item.label}</p>
                                </div>
                                <div className=" col-lg-12 col-xl-8">
                                  <div className="col-xl-12 mb-4">
                                    <input type={item.type} id="upload" hidden name={item.fieldKey} onChange={(e) => {
                                      handleFileUpload(e, item.id, item.formId, keys);
                                    }} accept=".csv, .pdf, .doc, .docx, .xlsx, .xls, text/plain" />
                                    <label className="upload_btn" htmlFor="upload"><span className="up_arrow"><i className="bi bi-cloud-arrow-up-fill clickable"></i></span>Upload</label>
                                  </div>
                                  <div className="col-xl-12">
                                    {attachfile != null &&

                                      <div className="border w-100 rounded float-start p-0 d-flex align-items-center">
                                        <span className="fs-4 d-inline-block py-2 px-3  border-end"><i className="bi bi-file-earmark-text-fill"></i></span>
                                        <span className="px-2 text_gray py-2 px-3 text-truncate ">{fileName}</span>
                                        <button className=" px-3 fs-4 py-2 border-0 bg-transparent border-start" onClick={() => handleDownload()}><i className="bi bi-box-arrow-down"></i></button>
                                        <button className=" px-3 fs-4 py-2 border-0 bg-transparent border-start" onClick={() => handleDeleteDocument()}><i className="bi bi-x-circle"></i></button>
                                      </div>
                                    }
                                  </div>
                                </div>
                              </div>

                              : null}
                          </>
                        )
                      })}
                    </div>
                  </div>
                )
              }
            })} */}
            {/* canceland-save*/}
            <div className="row  mb-4 pb-4">
              <div className="col-lg-12 col-xl-4">

              </div>
              <div className="form_bottom_btn d-flex justify-content-end">
                {/* <button className="delete_btn" type="button">Cancel</button>
                <button className="btn_gradian" type="button">Save</button> */}
                <button className="delete_btn" type="button" onClick={() => navigate(-1)}>Cancel</button>
                <button className="btn_gradian" type="button" onClick={() => createOneDoctor()}>Save</button>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default DoctorSettingProfile;
