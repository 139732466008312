import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  Toast,
} from "reactstrap";
import React, { useContext, useEffect, useState } from "react";
import { createDoctor, getOneDoctor, updateDoctor } from "../../../services/doctor-service";
import { getFormData, getformdetail } from "../../../services/form-service";
import { useLocation, useNavigate, useSearchParams, useParams } from "react-router-dom";

import { RoleContext, AlertContext } from "../../../utils/contexts/RoleContext";
import { ImagePath, getCurrentUser } from "../../../services/auth.service";
import profile from "../../../assets/images/doctor/profile.png";
import { Dropdown, ProgressBar } from 'react-bootstrap';
import constants from "../../../config/constants";
import { capitalizeFirstLetter } from "../../../utils/utils";

const roles = [
  {
    label: "Select...",
    value: "select/null"
  },
  {
    label: "Doctor",
    value: "Doctors Profile/3",
    roleId: "3"
  },
  {
    label: "Patient",
    value: "Patients Profile/5",
    roleId: "5"
  },
  {
    label: "Staff",
    value: "Staffs Profile/4",
    roleId: "4"
  },
  // {
  //   label: "Hospital",
  //   value: "Hospitals Profile/2",
  //   roleId: "2"

  // }
]


const MyProfile = () => {
  const { doctorId }: any = useParams()

  const [htmlFormUsers, sethtmlFormUsers] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [dragAllData, setDragAllData] = useState<any>([]);
  const [currentDoctor, getCurrentDoctor] = useState<any>({});
  const [dragEmpData, setDragEmpData] = useState<any>([]);
  const [personalData, setPersonalData] = useState<any>("");
  const [getvalues, setGetvalues] = React.useState<any>({});
  const [formUsers, getFormUsers] = React.useState<any>([]);
  const [dropDownValue, setDropDownValues] = useState("");
  const [roleId, setRoleId] = useState<any>("");
  const [getImage, setImage] = useState<any>("");
  const [generatePassword, setGeneratePassword] = useState(true)
  const navigate = useNavigate();
  const currentUser = useContext(RoleContext);
  const toast: any = useContext(AlertContext);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [roleName, setRoleName] = useState<string>("")
  const [progress, setProgress] = useState<any>()
  const [attachfile, setAttachedFile] = useState<any>(null)
  const [fileName, setFileName] = useState<any>('');
  const { id }: any = useParams();
  const [roleProfile, setRoleProfile] = useState<string>("");

  // useEffect(() => {
  //   // console.log("deireuriererr",searchParams.get('hospitalId'))
  //   // if (searchParams.get('profile') !== "unknown") {
  //   //   console.log("heirere",searchParams.get('profile'));
  //   //   getPersonalUsers(searchParams.get('profile'));
  //   //   setRoleId(searchParams.get('roleId'))
  //   if (roleProfile !== "unknown") {
  //     setRoleId(role)
  //     getPersonalUsers(roleProfile);
  //   }
  //   // if (location?.state?.key != undefined) {
  //   //   getDoctor();
  //   // }
  //   // createOneDoctor();
  // }, []);



  useEffect(() => {

    if (currentUser !== null) {
      switch (currentUser) {
        case 'doctor':
          setRoleProfile('Doctors Profile');
          break;
        case 'patient':
          setRoleProfile("Patients Profile");
          break;
        case 'staff':
          setRoleProfile("Staffs Profile");
          break;
        case 'hospital':
          setRoleProfile("Hospitals Profile");
          break;
        default:
          setRoleProfile("");
          break;
      }


      if (roleProfile !== "unknown" && roleProfile !== "") {
        //   setRoleId(role);
        // getPersonalUsers(roleProfile);
        getDoctor();
      }
    }

    document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - My Profile`;

  }, [roleProfile, currentUser]);

  const getDoctor = async () => {
    // console.log("location?.state?.key", location?.state?.key)
    const user = getCurrentUser()
    getOneDoctor(user?.id).then(
      (data) => {

        const allDoctor = data?.allDoctors;
        setRoleId(allDoctor?.roleId)
        // const obj1 = {
        const Basic_Information = {
          firstName: { value: allDoctor?.firstName, id: "5", formId: "10" },
          lastName: { value: allDoctor?.lastName, id: "5", formId: "10" },
          email: { value: allDoctor?.email, id: "5", formId: "10" },
          phoneNumber: { value: allDoctor?.phoneNumber, id: "5", formId: "10" },
          profile: { value: allDoctor?.profile, id: "5", formId: "10" },
          document: { value: allDoctor?.document, id: "5", formId: "10" },

        }
        // setAttachedFile(FolderPath + allDoctor?.document)
        // console.log("Dtheirer",allDoctor?.document)
        // setFileName(allDoctor?.document.split('/')[3])
        // }

        setGetvalues((prevState: any) => ({
          ...prevState, Basic_Information
        }))



        const userForm: any = {};
        // getCurrentDoctor(data.allDoctors)
        let array: any = [];
        allDoctor?.userdata?.map((item: any) => {
          let objs: any = {}
          objs.fieldKey = item?.fieldKey
          objs.fieldValue = JSON.parse(item?.fieldValue)
          objs.formId = item?.formId
          objs.id = item?.fieldId
          objs.label = item?.user_form_field?.label
          objs.type = item?.user_form_field?.type
          objs.defaultValue = item?.user_form_field?.defaultValue
          // console.log("check",userForm[item.user_form.formName])
          // obj.field = objs
          if (userForm[item?.user_form?.formName] === undefined) {
            userForm[item?.user_form?.formName] = [objs]
          } else {
            userForm[item?.user_form?.formName] = [...userForm[item?.user_form?.formName], objs]
          }

          array.push(objs)
          setGetvalues((prevState: any) => ({
            ...prevState, [item?.user_form?.formName]: { ...prevState[item?.user_form?.formName], [item?.fieldKey]: { id: parseInt(item?.fieldId), value: JSON.parse(item?.fieldValue), formId: item?.formId } }
          }))
          // console.log("valuess",objs,personalData)
          // getCurrentDoctor(...currentDoctor,["fieldValue"]:JSON.parse(item.fieldValue))
          // obj[item.fieldKey]=JSON.parse(item.fieldValue)

        })
        // console.log("dhterietr",userForm)
        // getFormUsers(array)
        // setPersonalData(array)
        //   setPersonalData(array)

        allDoctor?.user_form_data?.map((item: any) => {
          let obj: any = {}
          // obj[item.formFieldKey] = item.formFieldValue
          obj.fieldKey = item?.formFieldKey
          obj.fieldValue = item?.formFieldValue
          obj.formId = item?.formId
          obj.id = item?.fieldId
          obj.label = item?.user_form_field?.label
          obj.type = item?.user_form_field?.type
          obj.defaultValue = item?.user_form_field?.defaultValue

          // console.log("check",userForm[item.user_form.formName])
          // obj.field = objs
          if (userForm[item?.user_form?.formName] === undefined) {
            userForm[item?.user_form?.formName] = [obj]
          } else {
            userForm[item?.user_form?.formName] = [...userForm[item?.user_form?.formName], obj]
          }
          if (item.formFieldKey === "documentUploads") {
            // setAttachedFile(FolderPath + item.formFieldValue)
            setFileName(item?.formFieldValue?.split('/')[4])
          }



          // obj.field = objs
          array.push(obj)
          setGetvalues((prevState: any) => ({
            ...prevState, [item?.user_form?.formName]: { ...prevState[item?.user_form?.formName], [item?.formFieldKey]: { id: parseInt(item?.fieldId), value: item?.formFieldValue, formId: item?.formId } }
          }))
        })
        // getFormUsers(array)
        // console.log("array", array, userForm)
        const jarray: any = []
        for (let i in userForm) {
          let obj: any = {
            "formName": i,
            "user_form_fields": userForm[i]
          }
          jarray.push(obj)
        }
        if (jarray?.length > 0) {
          sethtmlFormUsers(jarray)
        }
        else {
          getPersonalUsers(roleProfile);
        }
        // sethtmlFormUsers(userForm)

        // sethtmlFormUsers(userForm)
        // console.log("objss", array,personalData)

        getCurrentDoctor(allDoctor)



      },
      (error) => {
        const resMessage =
          (error?.response &&
            error?.response?.data &&
            error?.response?.data?.message) ||
          error?.message ||
          error?.toString();

        setLoading(false);
        setMessage(resMessage);
      }
    );


  };






  console.log('roleProfile', roleProfile)

  const handleSelect = (event: any) => {

    const profile = event.split("/")[0];
    const roleId = event.split("/")[1];

    setRoleName(profile)
    setDropDownValues(event);
    if (roleProfile !== "select") {
      getPersonalUsers(roleProfile);
      // setRoleId(role);
    } else {
      sethtmlFormUsers([]);
    }
  };



  const FormOnChange = (event: any) => {
    const user = getCurrentUser();
    const { name, value, id } = event.target;
    const formName = event?.target?.id?.split("/")[2]
    const formId = event?.target?.id?.split("/")[1]
    const fieldId = event?.target?.id?.split("/")[0]
    // const name = event.target.name;
    // const value = event.target.value;
    // const obj = {
    //   "field_id": event.target.id,
    //   "field_key": event.target.name,
    //   "field_value": event.target.value
    // };
    // setGetvalues({ ...getvalues, [name]: value, "field_id": event.target.id });
    setGetvalues((prevState: any) => ({
      ...prevState, [formName]: { ...prevState[formName], [name]: { id: fieldId, value: value, formId: formId } }
    }))
  }

  const getPersonalUsers = async (Profile: string | null) => {
    getFormData(Profile).then(
      (data) => {
        console.log('hiiii', data.result)
        sethtmlFormUsers(data.result)
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
      }
    );


  };








  const createOneDoctor = async () => {
    console.log("getvaluess", getvalues['Basic Information']?.email)
    if (getvalues['Basic Information']?.firstName == undefined) {

      alert("please fill the firstname")
    } else if (getvalues['Basic Information']?.lastName == undefined) {
      alert("please fill the lastname")
    } else if (getvalues['Basic Information']?.email) {
      if (!validateEmail(getvalues['Basic Information']?.email?.value)) {
        alert("please fill the email")
      }
    } else if (getvalues['Basic Information']?.phoneNumber) {
      if (!validatePhone(getvalues['Basic Information']?.phoneNumber?.value)) {
        alert("please fill the phonenumber")
      }

    }
    // else {
    console.log("dreiruer")
    const formValues = { ...getvalues }
    const user = getCurrentUser();
    for (let key in formValues) {
      for (let i in formValues[key]) {
        formValues[key][i].roleId = user.roleId
      }
    }

    console.log("formvaluess", formValues)
    createDoctor(formValues, searchParams.get('hospitalId'), generatePassword).then(
      (data) => {
        let route: any;
        switch (roleProfile) {
          case "Doctors Profile":
            route = `/${currentUser}/doctordetaillist`;
            break;

          case "Patients Profile":
            route = `/${currentUser}/patient-list`;
            break;

          case "Staffs Profile":
            route = `/${currentUser}/stafflist`;
            break;
          case "Hospitals Profile":
            route = `/${currentUser}/getprofile`;
            break;
          case "unknown":
            route = `/${currentUser}/getprofile`;
            break;

          default:

            route = `/${currentUser}/doctordetaillist`;
        }
        // const route = searchParams.get('profile') === 'Doctors Profile' ? `/${currentUser}/doctordetaillist` :

        // if (data.message) {

        navigate(route);
        setTimeout(() => {
          // navigate(route);
          toast.setShowToast(data.message, "success");
        }, 1000);
        // toast.setShowToast(data.message);
        // }
      },
      (error) => {
        console.log("diruer")
        let route: any;
        switch (roleProfile) {
          case "Doctors Profile":
            route = `/${currentUser}/doctordetaillist`;
            break;

          case "Patients Profile":
            route = `/${currentUser}/patient-list`;
            break;

          case "Staffs Profile":
            route = `/${currentUser}/stafflist`;
            break;
          case "Hospitals Profile":
            route = `/${currentUser}/getprofile`;
            break;
          case "unknown":
            route = `/${currentUser}/getprofile`;
            break;

          default:

            route = `/${currentUser}/doctordetaillist`;
        }
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
        // toast.setShowToast("added failed", "danger");
        navigate(route);
        setTimeout(() => {
          // navigate(route);
          toast.setShowToast("added successfully", "success");
        }, 1000);
      }
    );
    setTimeout(() => {
      // navigate(route);
      toast.setShowToast("added successfully", "success");
    }, 1000);
    navigate(-1);
    // }
    // }







  };

  const updateOneDoctor = async () => {
    // console.log("Dtjeuroeire", getvalues['Basic_Information']?.firstName?.value)
    // if (getvalues['Basic_Information']?.firstName == undefined) {
    //     alert("please fill the firstname")
    // } else if (getvalues['Basic_Information']?.lastName == undefined) {
    //     alert("please fill the lastname")
    // } else if (getvalues['Basic_Information']?.email) {
    //     if (!validateEmail(getvalues['Basic_Information']?.email?.value)) {
    //         alert("please fill the email")
    //     }
    // } else if (getvalues['Basic_Information']?.phoneNumber) {
    //     if (!validatePhone(getvalues['Basic_Information']?.phoneNumber?.value)) {
    //         alert("please fill the phonenumber")
    //     }

    // }
    const user = getCurrentUser();

    const formValues = { ...getvalues }
    for (let key in formValues) {
      for (let i in formValues[key]) {
        formValues[key][i].roleId = user.roleId
      }
    }

    // console.log("roleiddd", roleId, formValues)

    updateDoctor(formValues, doctorId).then(
      (data) => {
        // console.log("doctor", data)
        if (data.message) {
          // let route;
          // switch (searchParams.get('profile')) {
          //     case "Doctors Profile":
          //         route = `/${currentUser}/doctordetaillist`;
          //         break;

          //     case "Patients Profile":
          //         route = `/${currentUser}/patient-list`;
          //         break;

          //     case "Staffs Profile":
          //         route = `/${currentUser}/stafflist`;
          //         break;

          //     case "unknown":
          //         route = `/${currentUser}/getprofile`;
          //         break;

          //     default:
          //         route = `/${currentUser}/doctordetaillist`;
          // }
          navigate(-1);
          setTimeout(() => {
            // navigate(route);
            toast.setShowToast(data.message, "success");
          }, 1000);
        }
        // const checkvalue = data.formData;
        // console.log("checkvaluess", JSON.parse(checkvalue))

        // sethtmlFormUsers(data)
        // formdisplay2(data.formId);
        // navigate(`/${currentUser}/doctordetailist`)



      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
        setTimeout(() => {
          // navigate(route);
          toast.setShowToast(resMessage, 'danger');
        }, 1000);
      }
    );


  };

  const validateEmail = (email: string) => {
    console.log("emailssss", email)
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email)
  };

  const validatePhone = (phone: string) => {
    var phoneNum = phone.replace(/[^\d]/g, '');
    if (phoneNum.length > 6 && phoneNum.length < 11) {
      return true;
    }
    return false
  };

  const handleFileUpload = (post: any, id: any, formid: any, formname: any) => {

    const formName = formname
    const formId = formid
    const fieldId = id
    if (post.target.files && post.target.files.length > 0) {
      const filearray: any = []
      const fileBase64: any = []
      const result: any = []
      for (let i = 0; i < post.target.files.length; i++) {
        console.log("postssss", post.target.files[i])
        let file = post.target.files[i];


        filearray.push(file.name)
        setFileName(filearray);

        let reader = new FileReader();
        if (file) {
          const maxSize = 5 * 1024 * 1024;
          if (file.size > maxSize) {
            alert('Can not upload this file. Max Size is 5MB');
            post.target.value = '';
          } else {


            const fileSize = file.size;
            const chunkSize = 1024 * 1024; // 1MB chunk size (you can adjust this as needed)
            const chunks = Math.ceil(fileSize / chunkSize);
            let uploadedChunks = 0;
            for (let i = 0; i < chunks; i++) {
              const start = i * chunkSize;
              const end = Math.min(fileSize, start + chunkSize);


              uploadedChunks++;
              //  const percentage= Math.floor((uploadedChunks * 100) / chunks)
              const percentage = Math.min(100, Math.round((uploadedChunks / chunks) * 100));
              // console.log("rpogieruer", percentage)
              setProgress(percentage)

            }



            reader.readAsDataURL(file);
            reader.onload = () => {
              // console.log("filess", file, reader)
              let obj = {
                fileName: file.name,
                result: reader.result
              }
              fileBase64.push(obj)
              // console.log("filebaseee", fileBase64)
              if (attachfile === null) {
                setAttachedFile(fileBase64);
              }
              else {
                const valueset = attachfile?.concat(fileBase64)
                setAttachedFile(valueset);
              }
              // setAttachedFile(reader.result);
              // let obj={}
              const results = { result: reader.result, fileName: file.name.split('.')[1] };

              result.push(results)
              // console.log("resultsss", result)
              // setGetvalues((prevState: any) => ({
              //   ...prevState, ['Basic Information']: { ...prevState['Basic Information'], result }
              // }))
              setGetvalues((prevState: any) => ({
                ...prevState, [formName]: { ...prevState[formName], [post.target.name]: { id: fieldId, value: result, formId: formId } }
              }))

            };
          }
        }
      }
    }
  };

  // console.log("fileamee", attachfile)

  const handleImageUpload = (post: any) => {
    let images: any = [];
    if (post.target.files && post.target.files.length > 0) {
      for (let i = 0; i < post.target.files.length; i++) {
        // setImage(post.target.files[i].name)
        let file = post.target.files[i];
        let reader = new FileReader();
        if (file) {
          reader.readAsDataURL(file);
          reader.onload = () => {
            images.push({ source: reader.result });
            // setImages([...images]);
            // setGetvalues({ ...getvalues, ['profile']: images });
            setImage(reader.result)
            const profile = { value: images }
            setGetvalues((prevState: any) => ({
              ...prevState, ['Basic_Information']: { ...prevState['Basic_Information'], profile }
            }))
          };
        }
      }
    }
  };

  let user: string;

  switch (roleProfile) {
    case "Doctors Profile":
      user = 'Doctor';
      break;

    case "Patients Profile":
      user = "Patient";
      break;

    case "Staffs Profile":
      user = "Staff";
      break;
    case "Hospitals Profile":
      user = "Hospital";
      break;

    default:
      user = "";
      break;

  }


  const handleDownload = (indexes?: any) => {
    attachfile.map((item: any, index: number) => {

      if (index === indexes) {
        const link = document.createElement('a');
        link.href = item?.result;
        link.download = item?.fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    })

  };

  const handleDeleteDocument = (indexes?: any) => {
    // console.log("Dteireuuerer")
    //     attachfile.map((imtess:any,indexs:any)=>{
    // //       console.log("Dtjer",indexs===index)
    // // if(indexs===index){
    // //   // setAttachedFile("");
    // // }
    //     })
    setAttachedFile(attachfile.filter((item: any, index: number) => index !== indexes))

    // setFileName("")

  };
  console.log("htmlforuserss", htmlFormUsers)

  return (
    <Row>
      <Col className="mt-3 mb-4">
        <div className="add_doctor_form card_box col-sm-12 col-xl-9 mx-auto py-4 px-lg-4 mt-4">
          <div className="add_doctor_fom px-4">
            <div className="row justify-content-around border-bottom mb-4 pb-4">
              <div className="col-lg-12 col-xl-4 d-flex align-items-center">
                <p className=" text-black-50 fw-medium">Profile</p>
              </div>
              <div className="col-lg-12 col-xl-8">
                {/* <div className="profile_image">
                    <div className="col-2 col-sm-2 col-lg-2 choose_file d-inline-block">
                      <span id="profile-image-upload"> <img src={getImage !== "" ? getImage : profile} alt="profile" className="clickable rounded-circle" onClick={(e) => handleImageUpload(e)} /></span>
                    </div>
                    <input type="file" id="profile" name="profile" accept="image/*" hidden onChange={(e) => {
                      handleImageUpload(e);
                    }}></input>
                    <label htmlFor="profile" className="d-inline-block m-0  px-2 px-xl-4 clickable">Upload Profile Photo</label>
                  </div> */}
                <div className="profile_image">
                  <div className="col-2 col-sm-2 col-lg-2 choose_file d-inline-block">
                    {/* <input type="file"> */}
                    {getImage != "" ?
                      <span id="profile-image-upload"> <img className="clickable rounded-circle" src={getImage} alt="profile" /></span>
                      :
                      currentDoctor?.profile != 0 ?
                        <span id="profile-image-upload"> <img className="clickable rounded-circle" src={`${ImagePath}` + 'profile/' + currentDoctor?.profile} alt="profile" /></span>
                        :

                        <span id="profile-image-upload"> <img className="clickable rounded-circle" src={profile} alt="profile" /></span>
                    }

                  </div>
                  {/* <input type="file" id="profile" name="profile" accept="image/*" hidden onChange={(e) => {
                                          handleImageUpload(e);
                                      }}></input>
                                      <label htmlFor="profile" className="d-inline-block m-0  px-2 px-xl-4 clickable" >Upload Profile Photo</label> */}
                </div>
              </div>
            </div>

            {/* personal-inoformation */}
            <div className="row justify-content-around border-bottom mb-4 pb-4">
              <div className="col-lg-12 col-xl-4">
                <p className="text-black-50 fw-medium">Personal Information</p>
              </div>
              <div className="row  col-lg-12 col-xl-8">
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="firsname" className="form-label">First Name</label>
                  <p>{currentDoctor?.firstName}</p>
                  {/* <input type="text" className="form-control" id="1/10/Basic_Information" onChange={FormOnChange} name="firstName" defaultValue={currentDoctor?.firstName} required /> */}
                </div>
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="Name" className="form-label">Last Name<span className="required-color">*</span></label>
                  <p>{currentDoctor?.lastName}</p>
                </div>
                {roleProfile !== 'Patients Profile' &&
                  <>
                    <div className="col-lg-12 col-xl-6 mb-3">
                      <label htmlFor="phone" className="form-label">Phone<span className="required-color">*</span></label>
                      <p>{currentDoctor?.phoneNumber}</p>
                      {/* <input type="text" className="form-control" id="3/10/Basic_Information" onChange={FormOnChange} name="phoneNumber" defaultValue={currentDoctor?.phoneNumber} required /> */}
                    </div>
                    <div className="col-lg-12 col-xl-6 mb-3">
                      <label htmlFor="email" className="form-label">Email<span className="required-color">*</span></label>
                      <p>{currentDoctor?.email}</p>
                      {/* <input type="text" className="form-control" id="4/10/Basic_Information" onChange={FormOnChange} name="email" defaultValue={currentDoctor?.email} required /> */}
                    </div>
                  </>
                }

                {htmlFormUsers?.map((keys: any) => {
                  if (keys.formName === "Personal Information") {
                    return (
                      <>
                        {keys.user_form_fields.map((item: any) => {
                          return (
                            <div className="col-lg-12 col-xl-6 mb-3">
                              <label htmlFor="phone" className="form-label">{item.label}</label>
                              <p>{item.fieldValue ? item.fieldValue : "-"}</p>
                            </div>
                          )
                        })}
                      </>
                    )
                  }
                })}

                {roleProfile === 'unknown' &&
                  <div className="col-lg-12 col-xl-6 mb-3">
                    {/* <label htmlFor="phone" className="form-label">{item.label}</label> */}
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      // name={item.fieldKey} 
                      // id={`${item.id + "/" + item.formId + "/" + keys.formName}`} 
                      onChange={e => handleSelect(e.target.value)}
                      value={dropDownValue}
                    >
                      {roles.map((role, index: number) => <option value={role.value} selected key={index + role.value}>{role.label}</option>
                      )}
                      {/* {JSON.parse(item.defaultValue)?.map((item: any, index: number) => {
                            return (
                              <option value={item.value} selected key={index + item.value}>{item.label}</option>
                            )
                          })} */}
                    </select>
                  </div>
                }
                {/* <div className="field-container">
                      <input
                        name="generatePassword"
                        type="checkBox"
                        checked={generatePassword}
                        onChange={() => setGeneratePassword(!generatePassword)}
                      />
                      <span> Generate Password </span>
                    </div>
                    {!generatePassword &&
                      <>
                        <div className="col-lg-12 col-xl-6 mb-3">
                          <label htmlFor="phone" className="form-label">Password</label>
                          <input type="text" className="form-control" id="5/10/Basic Information" onChange={FormOnChange} name="password" />
                        </div>
                        <div className="col-lg-12 col-xl-6 mb-3">
                          <label htmlFor="phone" className="form-label">Confirm Password</label>
                          <input type="text" className="form-control" id="6/10/Basic Information" onChange={FormOnChange} name="confirmPassword" />
                        </div>
                      </>
                    } */}
              </div>
            </div>

            {
              roleProfile === "Patients Profile" &&
              <div className="row justify-content-around border-bottom mb-4 pb-4">
                <div className="col-lg-12 col-xl-4">
                  <p className="text-black-50 fw-medium">Contact Information</p>
                </div>
                <div className="row  col-lg-12 col-xl-8">
                  <div className="col-lg-12 col-xl-6 mb-3">
                    <label htmlFor="phone" className="form-label">Phone</label>
                    <p>{currentDoctor?.phoneNumber}</p>
                    {/* <input type="text" className="form-control" id="3/10/Basic_Information" onChange={FormOnChange} name="phoneNumber" defaultValue={currentDoctor?.phoneNumber} /> */}
                  </div>
                  <div className="col-lg-12 col-xl-6 mb-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <p>{currentDoctor?.email}</p>
                    {/* <input type="text" className="form-control" id="4/10/Basic_Information" onChange={FormOnChange} name="email" defaultValue={currentDoctor?.email} /> */}
                  </div>
                </div>
              </div>
            }

            {htmlFormUsers?.map((keys: any, index: number) => {
              if (keys.formName !== "Personal Information" && keys.formName !== "Medical History" && keys.formName !== "Patient Document"
                && keys.formName !== "Staff Document" && keys.formName !== "Doctor Document" && keys.formName !== "undefined" && keys.formName !== "undefined"

              ) {
                return (
                  <div className="row justify-content-around border-bottom mb-4 pb-4" key={index + keys.formName}>
                    <div className="col-lg-12 col-xl-4">
                      <p className="text-black-50 fw-medium">{keys.formName}</p>
                    </div>
                    <div className="row  col-lg-12 col-xl-8">
                      {keys.user_form_fields.map((item: any) => {
                        return (
                          <div className="col-lg-12 col-xl-6 mb-3">
                                <label htmlFor="phone" className="form-label">{item.label}</label>
                                <p>{item.fieldValue ? item.fieldValue : "-"}</p>
                              </div>
                        )
                      })}
                    </div>
                  </div>
                )
              }
            })}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default MyProfile;