import Modal from 'react-bootstrap/Modal';
import user from "../assets/images/users/user1.jpg";
import { SOCKET_DOMAIN } from '../config/constants';
import { useContext, useEffect, useState } from 'react';
import { RoleContext } from '../utils/contexts/RoleContext';

export default function UsersModal(props: any) {
    const currentUser = useContext(RoleContext);
    const [tab, setTab] = useState("patients");

    useEffect(() => {
        if (currentUser === "patient") {
            setTab("doctors");
        }
    }, [currentUser])

    function checkDecider(data: any) {
        for (let i = 0; i < props.doctorsLinked?.length; i++) {
            if (props.doctorsLinked[i].id == data.id) {
                return true;
            }
        }
        return false;
    }

    function handleTab(role: string) {
        setTab(role);
        const filteredUsers = props.dataCopy?.filter((item: any) => {
            switch (role) {
                case "patients":
                    return item.roleId == 5;

                case "doctors":
                    return item.roleId == 3;

                default:
                    return item.roleId != 3 && item.roleId != 5;
            }
        });
        props.handleTabs(filteredUsers)
    }

    return (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            dialogClassName="modal-90w "
            aria-labelledby="example-custom-modal-styling-title"
            contentClassName="width_100"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {props.chatUsers ? "Select User" : "Doctors"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.chatUsers == true ?
                    <div className='chat-users'>
                        <ul className="nav nav-tabs nav-fill">
                            {currentUser !== "patient" &&
                                <li className="nav-item" onClick={() => handleTab("patients")}>
                                    <a className={`nav-link ${tab === "patients" && "active"}`} aria-current="page">Patients</a>
                                </li>
                            }
                            {currentUser !== "doctor" &&
                                <li className="nav-item" onClick={() => handleTab("doctors")}>
                                    <a className={`nav-link ${tab === "doctors" && "active"}`}>Doctors</a>
                                </li>
                            }
                            {currentUser !== "admin" &&
                                <li className="nav-item" onClick={() => handleTab("others")}>
                                    <a className={`nav-link ${tab === "others" && "active"}`}>Others</a>
                                </li>
                            }
                        </ul>
                        {props.data.map((item: any) => {
                            return (
                                <div className="user_chat float-start w-100 p-2 py-0 px-3 rounded">
                                    <div className="float-start border-bottom w-100 py-3 chat_group">
                                        <div className="user_chat_img float-start">
                                            <img className="rounded" src={(item.profile != '0' && item.profile != null) ? `${SOCKET_DOMAIN}/profile/${item.profile}` : user} alt="user" />
                                        </div>
                                        <div className="user_chat_detail px-3 float-start">
                                            <span className="d-block text_base poppins-600 dark_blue pb-1 text-capitalize">{`${item.name} ${currentUser === "patient" ? ((item.roleId != 3 && item.roleId != 4) ? `(${item.role})` : `(${item.role} at ${item.hospitalName})`) : ""}`}</span>
                                            <button
                                                onClick={() => props.handleClick(item)}
                                            >
                                                Start Chat
                                            </button>
                                            {/* <p className="m-0 text_xs text_gray">Thank you, Doctor. I appreci...</p> */}
                                        </div>
                                        {/* <div className="float-end chat_count">
                                        <span className="chat_time d-block text-end text_gray">11.13</span>
                                        <span className="count d-block float-end text_sm text-white text-center">1</span>
                                    </div> */}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    :
                    <div>
                        <div style={{ height: '70vh', overflowY: "auto" }}>
                            {props?.data?.map((item: any) => {
                                return (
                                    <div className='d-flex align-items-center'>
                                        <input
                                            type="checkbox"
                                            checked={checkDecider(item) ? true : false}
                                            onChange={() => props.handleChange(item)}
                                        />
                                        <span className='ms-2'>{item.name}</span>
                                    </div>
                                )
                            })}
                        </div>
                        <div>
                            <button
                                className='btn_gradian float-right mt-3'
                                onClick={props.handleClick}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                }
            </Modal.Body>
        </Modal>
    )

}