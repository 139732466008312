import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./pages/styles/common.css";
import "./pages/styles/DragandDrop.css";
import "./pages/styles/FormNew.css";
import "././css/style.css";
import "././css/custom.scss";

import * as AuthService from "./services/auth.service";

import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import EventBus from "./common/EventBus";
import ForgotPassword from "./pages/common/ForgotPassword";
import FullLayout from "./layouts/FullLayout";
import IUser from './types/user.type';
import { IconContext } from 'react-icons';
import Login from "./pages/common/Login";
import React from "react";
import Register from "./pages/common/Register";
import ResetPassword from "./pages/common/ResetPassword";
import { RoleContext, RoleUpdateContext, AuthorizationContext } from "./utils/contexts/RoleContext";
import styled from 'styled-components';


const NavIcon = styled(Link)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 5rem;
    font-size: 2rem;
    margin-left: 2rem;
`;

const App = () => {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState<IUser | undefined>(undefined);
  const [userId, setUserId] = useState<IUser | undefined>(undefined);
  const [cmsurl, setcmsurl] = useState<any>("");


  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);

  const Authorization = useContext(AuthorizationContext)


  useEffect(() => {
    const url = window.location.pathname;
    const cmsUrl = url.split("/")[1];
    const cmsUrls = url.split("/");
    localStorage.setItem("role", JSON.stringify(cmsUrls[1]))
    setcmsurl(cmsUrl)
    const user = AuthService.getCurrentUser();
    console.log("uersss", Authorization)

    if (user) {
      setCurrentUser(user?.role);
      setUserId(user);
    } else {
      let splittedPath = window.location.pathname.split("/");
      const path = splittedPath[2];
      const role = splittedPath[1]
      if(path !== "login" && path !== "register" && path !== "forgotpassword" && path !== "resetpassword" && role !== "" && role !== "resetpassword") {
        localStorage.setItem("redirectPath", window.location.pathname);
        navigate(`/${role}/login`);
      }
    }
    requestPermission();

    EventBus.on("logout", logOut);

    return () => {
      EventBus.remove("logout", logOut);
    };
  }, []);

  const logOut = () => {
    AuthService.logout();
    setUserId(undefined);
  };

  function requestPermission() {
    console.log("Requesting permission...");
    Notification.requestPermission().then((permission) => {
      console.log("permission", permission);
      if (permission === "granted") {
        console.log("Notification permission granted.");
      }
    });
  }



  // async function requestPermission() {
  //   // messaging.requestPermission()
  //   //   .then(() => {
  //   //     return messaging.getToken();
  //   //   })
  //   //   .then((token:any) => {
  //   //     console.log('Token:', token);
  //   //   })
  //   //   .catch((error:any) => {
  //   //     console.error('Error:', error);
  //   //   });
  //   const messaging = getMessaging();
  //   // Add the public key generated from the console here.
  //   const token = await getToken(messaging, { vapidKey: "BNYXlpc5WtTZPeyLFevo7H2ED4jCYnLIdleWZMnouxg91fIeE8M6zFi9xwbEv5XxcYpV485Ps2Cil7Nr8G46mjI" });
  //   console.log("token", token);
  // }

  return (
    <RoleContext.Provider value={currentUser}>
      <RoleUpdateContext.Provider value={(role: any) => setCurrentUser(role)}>
        <div>
          {/* {!currentUser &&
            <nav className="navbar navbar-expand navbar-dark bg-dark">
              {currentUser ?
                <IconContext.Provider value={{ color: '#fff' }}>

                  <NavIcon to="#" onClick={showSidebar}>
                    <AiOutlineMenu />
                  </NavIcon>


                </IconContext.Provider> : null}

              <div className="navbar-nav mr-auto">
                {currentUser ?
                  <li className="nav-item">
                    <Link to={"/home"} className="nav-link">
                      Home
                    </Link>
                  </li> : null}


                {currentUser && (
                  <li className="nav-item">
                    <Link to={"/user"} className="nav-link">
                      User
                    </Link>
                  </li>
                )}
              </div>




              {currentUser ? (
                <div className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <span className="nav-link"
                      onClick={() => { navigate(`/${currentUser}/editprofile`, { state: { key: userId?.id } }) }}
                    >
                      My Profile
                    </span>
                  </li>
                  <li className="nav-item">
                    <a href={`/${currentUser}/login`} className="nav-link" onClick={logOut}>
                      LogOut
                    </a>
                  </li>
                </div>
              ) : (
                <div className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <Link to={`/${cmsurl}/login`} className="nav-link">
                      Login
                    </Link>
                  </li>
                  {cmsurl !== "admin" && cmsurl !== "staff" ?
                    <li className="nav-item">
                      <Link to={`/${cmsurl}/register`} className="nav-link">
                        Sign Up
                      </Link>
                    </li> : null}
                </div>
              )}

            </nav>
          } */}

          {currentUser ?
            <FullLayout />
            :
            <div>
              <Routes>
                <Route path={"/"} element={<Login />} />
                <Route path={"/" + cmsurl + "/login"} element={<Login />} />
                <Route path={`/patient/register`} element={<Register />} />
                <Route path={`/${cmsurl}/forgotpassword`} element={<ForgotPassword />} />
                <Route path={`/resetpassword`} element={<ResetPassword />} />
              </Routes>
            </div>
          }
        </div>
      </RoleUpdateContext.Provider>
    </RoleContext.Provider>
  );
};

export default App;