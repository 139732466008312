import { END_POINT } from "../config/constants";
import axios from "axios";

const API_URL = `${END_POINT}billing/`

export const createOrder = () => {
    return axios
        .post(`${API_URL}create-order`, {

        })
        .then((response) => {
            return response?.data;
        });
};

export const approveOrder = (orderId: string) => {
    return axios
        .post(`${API_URL}approve-order`, {
            orderId
        })
        .then((response) => {
            return response?.data;
        });
};

export const createPayment = () => {
    return axios
        .post(`${API_URL}create-payment`, {
        })
        .then((response) => {
            return response;
        });
};


export const makePayment = (data: any, invoiceId?: string) => {
    return axios
        .post(`${API_URL}make-payment`, {
            data,
            invoiceId
        })
        .then((response) => {
            return response;
        });
};

export const makePaymentThroughHospital = (data: any, invoiceId?: string) => {
    return axios
        .post(`${API_URL}make-payment-hospital`, {
            data,
            invoiceId
        })
        .then((response) => {
            return response;
        });
};

export const requestPayment = (patientId: number, invoiceId?: string) => {
    return axios
        .post(`${API_URL}request-payment`, {
            patientId,
            invoiceId
        })
        .then((response) => {
            return response;
        });
};

export const requestPaymentOtherServices = (patientId: any, totalAmount: any, service: string, hospitalId: number) => {
    return axios
        .post(`${API_URL}request-payment-other`, {
            patientId,
            totalAmount,
            service,
            hospitalId
        })
        .then((response) => {
            return response;
        });
};

export const chargePaymentOtherServices = (patientId: any, totalAmount: any, service: string, data: any, hospitalId: number) => {
    return axios
        .post(`${API_URL}charge-payment-other`, {
            patientId,
            totalAmount,
            service,
            data,
            hospitalId
        })
        .then((response) => {
            return response;
        });
};

export const createInvoice = (orderId: Number, patientId: number | null) => {
    return axios
        .post(`${API_URL}create-invoice`, {
            orderId,
            patientId
        })
        .then((response) => {
            return response;
        });
};

export const getInvoiceDetails = (id: string) => {
    return axios
        .get(`${API_URL}get-invoice-details/${id}`)
        .then((response) => {
            return response?.data;
        });
};

export const getHospitalInvoiceDetails = (id: string) => {
    return axios
        .get(`${API_URL}get-hospital-invoice-details/${id}`)
        .then((response) => {
            return response?.data;
        });
};

export const getorders = (page?:any,status?: any, hospitalId?: number) => {
    return axios
        .get(`${API_URL}get-orders?page=${page}&status=${status}&hospitalId=${hospitalId}`)
        .then((response) => {
            return response?.data;
        });
};

export const deleteOrder = (orderId:string,) => {
    return axios
        .put(`${API_URL}delete-order/${orderId}`)
        .then((response) => {
            return response?.data;
        });
};

export const getOneOrder = (id:number,) => {
    return axios
        .get(`${API_URL}get-one-order/${id}`)
        .then((response) => {
            return response?.data;
        });
};

export const getOneInvoice = (id:string,) => {
    return axios
        .get(`${API_URL}get-one-invoice/${id}`)
        .then((response) => {
            return response?.data;
        });
};

export const createHospitalInvoice = (formData: any, items: any) => {
    return axios
        .post(`${API_URL}create-hospital-invoice`, {
            formData,
            items
        })
        .then((response) => {
            return response.data;
        });
};

export const getHospitalInvoices = (page?:number) => {
    return axios
        .get(`${API_URL}get-hospital-invoices?page=${page}`)
        .then((response) => {
            return response?.data;
        });
};

export const getOneHospitalInvoice = (id:string | undefined) => {
    return axios
        .get(`${API_URL}get-one-hospital-invoice/${id}`)
        .then((response) => {
            return response?.data;
        });
};

export const updateHospitalInvoice = (id:string | undefined, formData: any, items: any) => {
    return axios
        .put(`${API_URL}update-hospital-invoice/${id}`, {
            formData,
            items
        })
        .then((response) => {
            return response?.data;
        });
};

export const deleteHospitalInvoice = (id:string | undefined) => {
    return axios
        .put(`${API_URL}delete-hospital-invoice/${id}`)
        .then((response) => {
            return response?.data;
        });
};

export const payHospitalSubscription = (data: any, invoiceId?: string) => {
    return axios
        .post(`${API_URL}pay-hospital-subscription`, {
            data,
            invoiceId
        })
        .then((response) => {
            return response;
        });
};

export const getSubscriptionDetails = () => {
    return axios
        .get(`${API_URL}get-subscription-details`)
        .then((response) => {
            return response?.data;
        });
};
