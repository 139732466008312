
import profile from "../../../assets/images/doctor/profile.png";
import {
    Card,
    Row,
    Col,
    CardTitle,
    CardBody,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
} from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import SignaturePad from "react-signature-canvas";
import SignatureCanvas from "react-signature-canvas";
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getOneDoctor, } from "../../../services/doctor-service";
import { createprescription } from "../../../services/prescription-service";
import { RoleContext, AlertContext } from "../../../utils/contexts/RoleContext";
import { isArrayTypeNode } from "typescript";
import { getallpatient } from "../../../services/patient-service";
import constants from "../../../config/constants";
import { capitalizeFirstLetter } from "../../../utils/utils";
import { getCurrentUser } from "../../../services/auth.service";
import { getBookingsOfOneDoctor } from "../../../services/scheduleappointment-service";
import { getOnePatient } from "../../../services/patient-service";

interface Patient {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
}


const AddRefillRequest = () => {
    const currentUser = useContext(RoleContext);
    const [dataURL, setDataURL] = React.useState<string | null>(null);
    const [searchParams] = useSearchParams();
    const [currentDoctor, getCurrentDoctor] = useState<any>(null);
    const [getvalues, setGetvalues] = React.useState<any>({});
    const [dropDownValue, setDropDownValues] = useState("");
    const [patients, setPatients] = useState<Patient[]>([]);
    const [prescription, setPrescription] = useState([]);
    const [more, setMore] = useState();
    const [specificDoctor, setSpecificDoctor] = useState<any>({})

    const toast: any = useContext(AlertContext);
    const navigate = useNavigate();
    const [inputCount, setInputCount] = useState(1);
    const [inputs, setInputs] = useState<any[]>([{ medication: '', strength: '', frequency: '' }]);
    let padRef = React.useRef<SignatureCanvas>(null);
    const location = useLocation();
    const [filteredPatient, setFilteredPatient] = useState<Patient[]>([]);
    const [searchInput, setSearchInput] = useState<string>("");
    const [searchInputPatient, setSearchInputPatient] = useState<string>("");
    const [isDropdownOpenPatient, setIsDropdownOpenPatient] = useState(false);
    const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState<number>(-1);
    const [selectedPatientId, setSelectedPatientId] = useState<number | null>(null);

    const clear = () => {
        padRef.current?.clear();
    };

    useEffect(() => {
        if(currentUser === "doctor") {
            getMyPatients();
        } else {
            getUsers();
        }
        document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Add Refill Request`;
        if (currentUser === "patient") {
            getDoctor(getCurrentUser()?.id);
        }
    }, [currentUser])

    const getUsers = () => {
        getallpatient().then(
            (response) => {
                const patientData = response?.result;
                setPatients(patientData);
            },
            (error) => {
                console.log("err", error)
            }
        );
    }

    async function getMyPatients() {
        const response = await getBookingsOfOneDoctor(getCurrentUser()?.id);
        if (response?.success) {
            const patients = response?.patients
            let check: any;
            const arr: any = [];
            check = await Promise.all(patients?.map(async (item: any) => {
                const patient = await getOnePatient(item?.patientId);
                const patientData = patient?.result;
                arr.push(patientData);
                return check;
            }));
            setPatients(arr);
        }
    }



    const handleSelect = (event: any) => {
        getCurrentDoctor(null)
        const patientvalue = event.target.value

        setDropDownValues(patientvalue);
        getDoctor(selectedPatientId);

    };





    const createOnePrescription = async () => {
        console.log("getlvuaerer", getvalues);


        createprescription(getvalues).then(
            (data) => {


                console.log("dtueiruerer", data);
                // if (data.message) {

                //     navigate(-1);
                //     setTimeout(() => {

                //         toast.setShowToast(data.message, "success");
                //     }, 1000);

                // }
            },
            (error) => {

                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();


                // navigate(-1);
                // setTimeout(() => {
                //     // navigate(route);
                //     toast.setShowToast("added successfully", "success");
                // }, 1000);
            }
        );



    };

    const getDoctor = async (value: any) => {
        // console.log("va8leirer", value)

        getOneDoctor(value).then(
            (data) => {

                const allDoctor = data.allDoctors;
                let array: any = [];
                allDoctor?.userdata?.map((item: any) => {
                    // console.log("itemsss", item.fieldKey === "dateOfBirth")
                    if (item.fieldKey === "dateOfBirth") {
                        const date = new Date().getFullYear()
                        const age = JSON.parse(item.fieldValue)
                        const agedate = age.split('-')[0]
                        const patientAge = date - agedate
                        // console.log("dtuerer", age.split('-')[0], date)
                        getCurrentDoctor({ ...currentDoctor, ["age"]: patientAge, ["alldoctor"]: allDoctor })
                    }


                })
                if (allDoctor?.prescriptions?.length > 0) {
                    // console.log("dererer",prescription)
                    const prescriptionArray: any = []
                    allDoctor?.prescriptions?.map((items: any) => {
                        let obj = {
                            appointmentId: items.appointmentId,
                            prescriptionId: items.prescriptionId,
                            datesigned: items.dateSigned,
                            medicationDetails: items.medications
                        }
                        if (items?.doctorId) {
                            getSpecificDoctor(items.doctorId);
                        }
                        prescriptionArray.push(obj)

                    })
                    setPrescription(prescriptionArray)
                }
                else {
                    setPrescription([]);
                }





            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

            }
        );


    };


    const getSpecificDoctor = async (value: any) => {
        // console.log("va8leirer", value)

        getOneDoctor(value).then(
            (data) => {

                const allDoctor = data.allDoctors;
                setSpecificDoctor(allDoctor);
                console.log("alldoctorss", allDoctor);







            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

            }
        );


    };



    const FormOnChange = (event: any) => {
        // const { name, value, id } = event.target;
        const name = event.target.name;
        const value = event.target.value;

        setGetvalues({ ...getvalues, [name]: value, ['userId']: searchParams.get('key') })
        // setGetvalues((prevState: any) => ({
        //     ...prevState, [formName]: { ...prevState[formName], [name]: { id: fieldId, value: value, formId: formId } }
        // }))
    }

    const handleChangePatient = async (e: any) => {
        setPrescription([]);
        const searchValue = e.target.value.toLowerCase();
        setSearchInputPatient(searchValue);
        setSelectedSuggestionIndex(-1);
        // if (!searchValue.trim()) {
        //   setValidationMessages({ ...validationMessages, patientName: 'Please enter a patient name' });
        //   // setFilteredPatient([]);
        // } else {
        //   setValidationMessages({ ...validationMessages, patientName: '' });
        // }

        if (searchValue.trim() === '') {
            // setPatients(filteredPatient); 
            setIsDropdownOpenPatient(false);

            return;
        }

        const [firstName, lastName, email] = searchValue.split(' ');
        // console.log("seraeiruerer",searchValue.split(' '));
        const filtered = patients.filter(
            (patient) =>
                patient?.firstName.toLowerCase().includes(firstName) ||
                patient?.lastName.toLowerCase().includes(lastName) ||
                patient?.email.toLowerCase().includes(firstName)
        );
        // console.log("filtereseter",filtered);
        setFilteredPatient(filtered);

        setIsDropdownOpenPatient(true);


    };

    const handlePatientClick = (firstName: string, lastName: string, id: number) => {
        setSearchInputPatient(`${firstName} ${lastName}`)
        setIsDropdownOpenPatient(false);
        setSelectedPatientId(id);
        setSelectedSuggestionIndex(-1);
        getDoctor(id);
        // setSelectedPatientIndex(-1);
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        switch (event.key) {
            case 'ArrowDown':
                event.preventDefault();
                setSelectedSuggestionIndex((prevIndex) => (prevIndex < filteredPatient.length - 1 ? prevIndex + 1 : prevIndex));
                break;
            case 'ArrowUp':
                event.preventDefault();
                setSelectedSuggestionIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
                break;
            case 'Enter':
                event.preventDefault();
                if (selectedSuggestionIndex !== -1) {
                    setSearchInputPatient(`${filteredPatient[selectedSuggestionIndex].firstName} ${filteredPatient[selectedSuggestionIndex].lastName}`);
                    setIsDropdownOpenPatient(false);
                    setSelectedPatientId(filteredPatient[selectedSuggestionIndex].id);
                    getDoctor(filteredPatient[selectedSuggestionIndex].id);
                    setSelectedSuggestionIndex(-1);
                }
                break;
            default:
                break;
        }
    };

    return (
        <Row>
            <div className="panel_top_section">
                <h1>Add New Refillrequest</h1>
            </div>
            <Col className="mt-3 mb-4">
                <div className="add_doctor_form card_box col-sm-12 col-xl-9 mx-auto py-4 px-lg-4 mt-4">
                    <div className="add_doctor_fom px-4">
                        {currentUser !== "patient" &&
                            <div className="row justify-content-around border-bottom mb-4 pb-4">
                                <div className="col-lg-12 col-xl-4 d-flex align-items-center">
                                    <p className=" text-black-50 fw-medium">Patient Name</p>
                                </div>
                                <div className="col-lg-12 col-xl-8">
                                    <div className="col-lg-12 col-xl-6 mb-3 position-relative">

                                        <input
                                            type="text"
                                            value={searchInputPatient}
                                            onChange={handleChangePatient}
                                            onKeyDown={handleKeyDown}
                                            className="form-control select_drop_down shadow-none text_gray rounded-0 border-0 border-bottom mb-3"
                                        />
                                        {isDropdownOpenPatient && (
                                            <div>
                                                {filteredPatient.length === 0 ? (
                                                    <div className="text_gray">No record found</div>
                                                ) : (
                                                    <ul className="patient-dropdown open pb-2 rounded-bottom border">
                                                        {filteredPatient.map((patient, index) => (
                                                            <li
                                                                key={index}
                                                                className={index === selectedSuggestionIndex ? 'selected' : ''}
                                                                style={{
                                                                    padding: '10px 20px',
                                                                    cursor: 'pointer',
                                                                    backgroundColor: index === selectedSuggestionIndex ? '#b0d7db' : ''
                                                                }}
                                                                onClick={() => handlePatientClick(patient.firstName, patient.lastName, patient.id)}
                                                            >
                                                                {`${patient.firstName} ${patient.lastName}`}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        }

                        {/* personal-inoformation */}
                        <div className="row justify-content-around border-bottom mb-4 pb-4">
                            {/* <div className="col-lg-12 col-xl-12">
                                <p className="text-black-50 fw-medium mb-3">Patient's Name:</p>
                            </div> */}
                            <div className="row  col-lg-12 col-xl-12">
                                {/* {currentDoctor != null &&
                                    <>
                                        <div className="col-lg-12 col-xl-6 mb-3">
                                            <label htmlFor="firsname" className="form-label">First Name</label>
                                            <input type="text" className="form-control" id="firstname" disabled defaultValue={currentDoctor?.alldoctor?.firstName} />
                                        </div>
                                        <div className="col-lg-12 col-xl-6 mb-3">
                                            <label htmlFor="Name" className="form-label">Last Name</label>
                                            <input type="text" className="form-control" id="lastname" disabled defaultValue={currentDoctor?.alldoctor?.lastName} />
                                        </div>
                                        <div className="col-lg-12 col-xl-12 mb-3">
                                            <label htmlFor="Name" className="form-label">Patient's Age</label>
                                            <input type="text" className="form-control" id="age" disabled defaultValue={currentDoctor?.age} />
                                        </div>
                                    </>
                                } */}

                                <div className="col-lg-12 col-xl-12">
                                    <p className="text-black-50 fw-medium mb-3">Prescription Lists:</p>
                                </div>
                                {/* <div className="mb-2 d-flex justify-content-end">
                                    {currentUser !== "patient" &&
                                        <button
                                            className="btn_gradian"
                                            type="button"
                                            onClick={() => {
                                                navigate(`/${currentUser}/add-medication`)
                                            }}
                                        >
                                            Add Medication
                                        </button>
                                    }
                                </div> */}

                                <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                    <div className="row d-flex table-responsive">
                                        <table>
                                            <thead>
                                                <tr className="list_head">
                                                    <th>PrescriptionId</th>
                                                    <th>DoctorName</th>
                                                    <th>Signed Date</th>
                                                    <th>Medication Count</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {prescription.length > 0 ?
                                                    <>
                                                        {prescription?.map((prescription: any, index: number) => {
                                                            return (
                                                                <tr className="list_detail" key={index + prescription.prescriptionId}>
                                                                    <td><p>{prescription.prescriptionId}</p></td>
                                                                    <td><p>{specificDoctor?.firstName}</p></td>
                                                                    <td><p>{prescription.datesigned}</p></td>
                                                                    <td><p>{prescription?.medicationDetails?.length}</p></td>
                                                                    <td>
                                                                        {more !== prescription.prescriptionId ?
                                                                            <span className="dost_btn" onClick={() => setMore(prescription.prescriptionId)}>
                                                                                <i className="bi bi-three-dots-vertical"></i>
                                                                            </span>
                                                                            :
                                                                            <span className="action" >
                                                                                <button
                                                                                    className="edit_btn"

                                                                                    onClick={() => navigate(`/${currentUser}/addrefill-medication`, { state: { key: prescription.prescriptionId, appointmentId: prescription.appointmentId, patientId: currentDoctor?.alldoctor?.id } })
                                                                                    }
                                                                                >
                                                                                    <i className="bi bi-pen"></i>
                                                                                </button>
                                                                                {currentUser !== "patient" &&
                                                                                    <button
                                                                                        className="edit_btn"
                                                                                        onClick={() => navigate(`/${currentUser}/add-medication`, { state: { prescriptionId: prescription.prescriptionId, patientId: currentDoctor?.alldoctor?.id } })}
                                                                                    >
                                                                                        <i className="bi bi-exposure"></i>
                                                                                    </button>
                                                                                }
                                                                            </span>
                                                                        }
                                                                    </td>

                                                                </tr>
                                                            )
                                                        })
                                                        }
                                                    </>
                                                    :
                                                    <tr className="list_detail">
                                                        <td colSpan={10}>No data found</td>
                                                    </tr>
                                                }
                                            </tbody>

                                        </table>
                                    </div>
                                </div>










                            </div>
                        </div>

                        {/* canceland-save*/}
                        <div className="row  mb-4 pb-4">
                            <div className="col-lg-12 col-xl-4">

                            </div>
                            <div className="form_bottom_btn d-flex justify-content-end">
                                <button className="delete_btn" type="button" onClick={() => navigate(-1)}>Cancel</button>
                                {/* <button className="btn_gradian" type="button" onClick={() => createOnePrescription()}>Save</button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default AddRefillRequest;