import "formiojs/dist/formio.full.css";
import { formIoData } from "../questionnaire/QuestionnaireConsts";

import './Questionnaire.css';

import * as AuthService from "../../../services/auth.service";

import { FormBuilder as FormBuilderIo, FormEdit } from "@formio/react";
// import { FormioComponentsProvider } from 'formiojs-react';
import React, { useContext, useEffect, useState, useRef } from "react";
import {
  createform,
  createformfield,
  getonedoctor,
  updateform,
  updateformfield,
} from "../../../services/form-service";
import { useLocation, useNavigate } from "react-router-dom";

import { RoleContext, AlertContext } from "../../../utils/contexts/RoleContext";

import axios from "axios";
import constants from "../../../config/constants";
import { capitalizeFirstLetter } from "../../../utils/utils";


type Props = {};



const Questionnaire: React.FC<Props> = () => {

  const location = useLocation();
  const currentUser = useContext(RoleContext);

  //   const [formIoData, setFormIoData] = useState<any>({
  //     display: "form",
  //     components: [],
  //   });




  const [formData, setFormData] = useState<any>(formIoData);
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [currentHospitalUser, setCurrentUser] = useState<any>({});
  const [finalFormValue, setFinalFormValue] = useState<any>({
    display: "form",
    components: [],
  });

  const [label, setLabel] = useState("");

  const navigate = useNavigate();
  const toast: any = useContext(AlertContext);

  const [formIoDataInitial, setFormIoData] = useState<any>({
    display: "form",
    components: [],
  });






  useEffect(() => {
    const value = AuthService?.getCurrentUser();
    // setCurrentUser(value?.role);
    const getvalue: any = localStorage?.getItem("user")
    // setFinalFormValue(formIoData)
    setCurrentUser(JSON.parse(getvalue))
    // getFieldDetails();
    document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Questionnaire`;
  }, [currentUser]);

  const getFieldDetails = () => {
    getonedoctor(location?.state?.key).then(
      (data) => {
        data.allDoctors.map((item: any) => {
          //   setFormData({
          //   ...formIoData,
          //   components: JSON.parse(item.formData),
          // });
        });
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
      }
    );
  };

  const printResult = async () => {
    let hospitalId = location.state?.hospitalId ? location.state?.hospitalId : currentHospitalUser?.id;
    const dragdropform = {
      data: finalFormValue.components,
      formName: finalFormValue.components[0].title,
      formLink: "Patients Profile",
      hospitalId,
      formType: "Patient Questionnaire"
    };

    createform(dragdropform).then((data) => {
      data?.formData?.map(
        async (item: any) => {
          const dragdroptable = {
            formid: data.formId,
            dragid: item.id,
            key: item.key,
            label: item?.components.length > 0 ? item.title : item.label,
            type: item.type,
            defaultValue: item.defaultValue,
            values: item.data?.values,
            fieldJson: item?.components
          };
          if (dragdroptable) {
            createformfield(dragdroptable).then((data) => { });
          }
        },
        (error: any) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          setMessage(resMessage);
        }
      );
    });

    navigate(`/${currentUser}/questionnaire-forms`);


    setTimeout(() => {
      toast.setShowToast("Questionnaire has been added", "success");
    }, 1000);

    // if (location?.state?.key === undefined) {

    // }
    //  else {
    //   updateform(location?.state?.key, dragdropform).then((data) => {
    //     data.map(
    //       async (item: any) => {
    //         const array: any = [];
    //         const JsonData = JSON.parse(item.formData);

    //         JsonData.map(async (items: any) => {
    //           const obj = {
    //             formid: item.formId,
    //             dragid: items.id,
    //             key: items.key,
    //             label: items.label,
    //             type: items.type,
    //             defaultValue: items.defaultValue,
    //             values: items.data?.values,
    //           };
    //           array.push(obj);
    //         });

    //         if (array) {
    //           updateformfield(location?.state?.key, array).then((data) => {});
    //         }
    //       },
    //       (error: any) => {
    //         const resMessage =
    //           (error.response &&
    //             error.response.data &&
    //             error.response.data.message) ||
    //           error.message ||
    //           error.toString();

    //         setLoading(false);
    //         setMessage(resMessage);
    //       }
    //     );
    //   });
    // }
  };

  const navigateForm = async () => {
    navigate(`/${currentUser}/form`, { state: { key: location?.state?.key } });
  };

  const onSubmitForm = async (data: any) => {
    // setFormData({ ...formIoData, components: data });
  };

  const [searchText, setSearchText] = useState('');

  const handleSearchChange = (e?: any) => {

    setSearchText(e.target.value);
  };
  var filteredFields: any = formIoData
  useEffect(() => {
    // setFinalFormValue(formIoData)
    filteredFields = formIoData?.components?.filter((field: any) => {
      // Modify the condition based on your field properties
      // if(searchText!=""){
      return field?.label?.toLowerCase()?.includes(searchText?.toLowerCase());
      // }
      // else{
      //   return formIoData
      // }

    });
    setFormData({ ...formData, components: filteredFields });

  }, [searchText])



  return (
    <div className="questionnaire-form-builder">
      <div className="clearfix">
        <button className="btn_gradian float-right" onClick={printResult}>
          Save Form
        </button>
      </div>
      <div>
        <div className="mt-3">
          {/* <div className="custom_search px-2">
            <input
              type="text"
              value={searchText}
              onChange={handleSearchChange}
              placeholder="Search Fields..."
              className="form-control"
            />
          </div> */}
          <FormBuilderIo
            // form={searchText === "" ? finalFormValue : formData}
            form={finalFormValue}
            options={{
              builder: {
                resource: false,
                advanced: false,
                premium: false,
                layout: false,
                data: false,


                basic: {
                  title: 'Field Options',
                  default: true,
                  components: {
                    textfield: {
                      title: 'Input',
                      key: 'textfield',
                      icon: 'bars',
                      schema: {
                        label: 'Input ',
                        type: 'textfield',
                        key: 'textfield',
                        input: true,
                      },
                    },
                    select: {
                      title: 'Dropdown',
                      key: 'select',
                      icon: 'angle-down',
                      schema: {
                        label: 'Dropdown',
                        type: 'select',
                        key: 'select',
                        input: true,
                      },
                    },
                    selectboxes: true,
                    checkbox: false,
                    // selectboxes: {
                    //   label: "Select Boxes",
                    //   optionsLabelPosition: "right",
                    //   tableView: false,
                    //   defaultValue: {
                    //     a: false,
                    //     b: false,
                    //     c: false
                    //   },
                    //   values: [
                    //     {
                    //       label: "A",
                    //       value: "a",
                    //       shortcut: ""
                    //     },
                    //     {
                    //       label: "B",
                    //       value: "b",
                    //       shortcut: ""
                    //     },
                    //     {
                    //       label: "C",
                    //       value: "c",
                    //       shortcut: ""
                    //     }
                    //   ],
                    //   key: "selectBoxes",
                    //   type: "selectboxes",
                    //   input: true,
                    //   inputType: "checkbox"
                    // },
                    date: {
                      title: 'Date Picker',
                      key: 'dateTime',
                      icon: 'calendar',
                      schema: {
                        label: 'Date Picker ',
                        type: 'datetime',
                        key: 'dateTime',
                        input: true,
                        format: 'yyyy-MM-dd',
                      }
                    },

                    // checkbox: {
                    //   title: 'checkbox',
                    //   key: 'checkboxGroup',
                    //   icon: 'check',
                    //   schema: {
                    //     type: 'checkbox',
                    //     label: 'Checkbox Group',
                    //     key: 'checkboxGroup',
                    //     values: [
                    //       {
                    //         label: 'Option 1',
                    //         value: 'option1'
                    //       },
                    //       {
                    //         label: 'Option 2',
                    //         value: 'option2'
                    //       },
                    //       // Add more options as needed
                    //     ]
                    //   }
                    // },

                    password: false,
                    number: false,
                    radio: false,
                    button: false,
                    textarea: false,
                    // selectboxes: true,
                    file: false,
                    // file: {
                    //   title: 'File',
                    //   key: 'file',
                    //   icon: 'file',
                    //   schema: {
                    //     label: 'File',
                    //     type: 'file',
                    //     key: 'file',
                    //     input: true,
                    //     storage: 'indexeddb',

                    //   }
                    // },
                    panel: {
                      title: 'Panel',
                      key: 'panel',
                      icon: 'list-alt',
                      schema: {
                        label: 'Panel',
                        type: 'panel',
                        key: 'panel',
                        input: false,

                      }
                    },

                  },
                },
              },




              editForm: {
                textfield: [
                  {
                    key: 'validation',
                    ignore: false
                  },
                  {
                    key: 'api',
                    ignore: true
                  },
                  {
                    key: 'data',
                    ignore: true
                  },
                  {
                    key: 'logic',
                    ignore: true
                  },
                  {
                    key: 'layout',
                    ignore: true
                  },
                  {
                    key: 'conditional',
                    ignore: false
                  },
                  {
                    key: 'display',
                    label: 'Basic',
                    components: [
                      {
                        key: 'editor',
                        ignore: true
                      },

                      {
                        key: 'autoExpand',
                        ignore: true

                      },
                      {
                        key: 'rows',
                        ignore: true

                      },
                      {
                        key: 'tooltip',
                        ignore: true
                      },
                      {
                        key: 'displayMask',
                        ignore: true
                      },
                      {
                        key: 'applyMaskOn',
                        ignore: true
                      },
                      {
                        key: 'prefix',
                        ignore: true
                      },
                      {
                        key: 'suffix',
                        ignore: true
                      },
                      {
                        key: 'inputMasks',
                        ignore: true
                      },
                      {
                        key: 'allowMultipleMasks',
                        ignore: true
                      },
                      {
                        key: 'description',
                        ignore: true
                      },
                      {
                        key: 'customClass',
                        ignore: true
                      },
                      {
                        key: 'inputMask',
                        ignore: true
                      },
                      {
                        key: 'tabindex',
                        ignore: true
                      },
                      {
                        key: 'type',
                        ignore: true
                      },
                      {
                        key: 'inputMaskPlaceholderChar',
                        ignore: true
                      },
                      // {
                      //     key: 'labelPosition',
                      //     ignore: true
                      // },
                      {
                        key: 'autocomplete',
                        ignore: true
                      },
                      {
                        key: 'widget.type',
                        ignore: true
                      },
                      {
                        key: 'hidden',
                        ignore: true
                      },
                      {
                        key: 'hideLabel',
                        ignore: true
                      },
                      {
                        key: 'showWordCount',
                        ignore: true
                      },
                      {
                        key: 'showCharCount',
                        ignore: true
                      },
                      {
                        key: 'mask',
                        ignore: true
                      },
                      {
                        key: 'autofocus',
                        ignore: true
                      },
                      {
                        key: 'disabled',
                        ignore: true
                      },
                      {
                        key: 'modalEdit',
                        ignore: true
                      },
                      {
                        key: 'spellcheck',
                        ignore: true
                      },
                      {
                        key: 'tableView',
                        ignore: true
                      }

                    ]
                  },

                  //   {
                  //     key: 'Advanced',
                  //     label: 'Advanced',
                  //     components: [
                  //     {
                  //         key: 'MandatoryFlag',
                  //         input: true,
                  //         label: 'Is this field Mandatory?',
                  //         type: 'radio',
                  //         defaultValue: "N",
                  //         values: [
                  //             {
                  //                 "label": "Yes",
                  //                 "value": "Y",
                  //                 "shortcut": ""
                  //             },
                  //             {
                  //                 "label": "No",
                  //                 "value": "N",
                  //                 "shortcut": ""
                  //             }
                  //         ],
                  //     },
                  //     {
                  //         key: 'SearchFlag',
                  //         input: true,
                  //         label: 'Search on this field?',
                  //         type: 'radio',
                  //         defaultValue: "N",
                  //         values: [
                  //             {
                  //                 "label": "Yes",
                  //                 "value": "Y",
                  //                 "shortcut": ""
                  //             },
                  //             {
                  //                 "label": "No",
                  //                 "value": "N",
                  //                 "shortcut": ""
                  //             }
                  //         ],
                  //     }
                  //     ]
                  // },


                  // {
                  //   key: 'validation',
                  //   label: 'validation',
                  //   components: [
                  //     {
                  //       key: 'validate.minWords',
                  //       ignore: true
                  //     },
                  //     {
                  //       key: 'validate.maxWords',
                  //       ignore: true
                  //     }
                  //   ]
                  // },



                ],

                datetime: [
                  {
                    key: 'validation',
                    ignore: false
                  },
                  {
                    key: 'api',
                    ignore: true
                  },

                  {
                    key: 'logic',
                    ignore: true
                  },
                  {
                    key: 'date',
                    ignore: true
                  },
                  {
                    key: 'time',
                    ignore: true
                  },
                  {
                    key: 'data',
                    ignore: true
                  },
                  {
                    key: 'layout',
                    ignore: true
                  },
                  {
                    key: 'conditional',
                    ignore: false
                  },
                  {
                    key: 'display',
                    label: 'Basic',
                    components: [
                      {
                        key: 'displayInTimezone',
                        ignore: true
                      },
                      {
                        key: 'useLocaleSettings',
                        ignore: true

                      },
                      {
                        key: 'allowInput',
                        ignore: true

                      },
                      {
                        key: 'tooltip',
                        ignore: true
                      },
                      {
                        key: 'shortcutButtons',
                        ignore: true
                      },

                      {
                        key: 'description',
                        ignore: true
                      },
                      {
                        key: 'customClass',
                        ignore: true
                      },

                      {
                        key: 'tabindex',
                        ignore: true
                      },

                      {
                        key: 'labelPosition',
                        ignore: true
                      },

                      {
                        key: 'hidden',
                        ignore: true
                      },
                      {
                        key: 'hideLabel',
                        ignore: true
                      },

                      {
                        key: 'autofocus',
                        ignore: true
                      },
                      {
                        key: 'disabled',
                        ignore: true
                      },
                      {
                        key: 'modalEdit',
                        ignore: true
                      },

                      {
                        key: 'tableView',
                        ignore: true
                      }

                    ]
                  },
                ],

                file: [
                  {
                    key: 'validation',
                    ignore: true
                  },
                  {
                    key: 'api',
                    ignore: true
                  },
                  {
                    key: 'file',
                    ignore: true
                  },
                  {
                    key: 'data',
                    ignore: true
                  },

                  {
                    key: 'logic',
                    ignore: true
                  },
                  {
                    key: 'layout',
                    ignore: true
                  },
                  {
                    key: 'conditional',
                    ignore: true
                  },
                  {
                    key: 'display',
                    label: 'Basic',
                    components: [
                      {
                        key: 'tooltip',
                        ignore: true
                      },
                      {
                        key: 'description',
                        ignore: true
                      },
                      {
                        key: 'customClass',
                        ignore: true
                      },
                      {
                        key: 'tabindex',
                        ignore: true
                      },

                      {
                        key: 'labelPosition',
                        ignore: true
                      },
                      {
                        key: 'hidden',
                        ignore: true
                      },
                      {
                        key: 'hideLabel',
                        ignore: true
                      },

                      {
                        key: 'autofocus',
                        ignore: true
                      },
                      {
                        key: 'disabled',
                        ignore: true
                      },
                      {
                        key: 'modalEdit',
                        ignore: true
                      },

                      {
                        key: 'tableView',
                        ignore: true
                      }

                    ]
                  },

                  {
                    key: 'file',
                    label: 'File',
                    components: [
                      {
                        key: 'filePattern',
                        ignore: true
                      },
                      {
                        key: 'fileMinSize',
                        ignore: true
                      },
                      {
                        key: 'fileMaxSize',
                        ignore: true
                      },
                      {
                        key: 'uploadOnly',
                        ignore: true
                      },

                      {
                        key: 'fileNameTemplate',
                        ignore: true
                      },
                      {
                        key: 'webcam',
                        ignore: true
                      },
                      {
                        key: 'image',
                        ignore: true
                      },
                      {
                        key: 'dir',
                        ignore: true
                      },
                      {
                        key: 'storage',
                        ignore: false,

                      },


                      {
                        key: 'fileTypes',
                        ignore: true
                      },

                    ]
                  },
                ],
                checkbox: [
                  {
                    key: 'validation',
                    ignore: true
                  },
                  {
                    key: 'api',
                    ignore: true
                  },
                  {
                    key: 'data',
                    ignore: true
                  },

                  {
                    key: 'logic',
                    ignore: true
                  },
                  {
                    key: 'layout',
                    ignore: true
                  },
                  {
                    key: 'conditional',
                    ignore: true
                  },
                  {
                    key: 'display',
                    label: 'Basic',
                    components: [
                      {
                        key: 'displayInTimezone',
                        ignore: true
                      },
                      {
                        key: 'useLocaleSettings',
                        ignore: true

                      },
                      {
                        key: 'allowInput',
                        ignore: true

                      },
                      {
                        key: 'labelWidth',
                        ignore: true

                      },
                      {
                        key: 'labelMargin',
                        ignore: true

                      },
                      {
                        key: 'shortcut',
                        ignore: true

                      },
                      {
                        key: 'tooltip',
                        ignore: true
                      },
                      {
                        key: 'shortcutButtons',
                        ignore: true
                      },

                      {
                        key: 'description',
                        ignore: true
                      },
                      {
                        key: 'customClass',
                        ignore: true
                      },

                      {
                        key: 'tabindex',
                        ignore: true
                      },

                      {
                        key: 'labelPosition',
                        ignore: true
                      },

                      {
                        key: 'hidden',
                        ignore: true
                      },
                      {
                        key: 'hideLabel',
                        ignore: true
                      },

                      {
                        key: 'autofocus',
                        ignore: true
                      },
                      {
                        key: 'disabled',
                        ignore: true
                      },
                      {
                        key: 'modalEdit',
                        ignore: true
                      },

                      {
                        key: 'tableView',
                        ignore: false
                      }

                    ]
                  },
                ],
                select: [
                  {
                    key: 'validation',
                    ignore: false
                  },
                  {
                    key: 'api',
                    ignore: true
                  },

                  {
                    key: 'logic',
                    ignore: true
                  },
                  {
                    key: 'layout',
                    ignore: true
                  },
                  {
                    key: 'conditional',
                    ignore: false
                  },

                  {
                    key: 'display',
                    label: 'Basic',
                    components: [
                      {
                        key: 'uniqueOptions',
                        ignore: true
                      },

                      {
                        key: 'tooltip',
                        ignore: true
                      },
                      {
                        key: 'widget',
                        ignore: true
                      },

                      {
                        key: 'description',
                        ignore: true
                      },
                      {
                        key: 'customClass',
                        ignore: true
                      },

                      {
                        key: 'tabindex',
                        ignore: true
                      },

                      {
                        key: 'labelPosition',
                        ignore: true
                      },

                      {
                        key: 'hidden',
                        ignore: true
                      },
                      {
                        key: 'hideLabel',
                        ignore: true
                      },

                      {
                        key: 'autofocus',
                        ignore: true
                      },
                      {
                        key: 'disabled',
                        ignore: true
                      },
                      {
                        key: 'modalEdit',
                        ignore: true
                      },

                      {
                        key: 'tableView',
                        ignore: true
                      },

                    ],

                  },



                  {
                    key: 'data',
                    label: 'Data',
                    components: [
                      {
                        key: 'template',
                        ignore: true
                      },

                      {
                        key: 'idPath',
                        ignore: true
                      },
                      {
                        key: 'dataType',
                        ignore: true
                      },

                      {
                        key: 'customDefaultValue',
                        ignore: true

                      },
                      {
                        key: 'calculateValue',
                        ignore: true,

                      },
                      {
                        key: 'defaultValue',
                        ignore: true
                      },

                      {
                        key: 'refreshOn',
                        ignore: true
                      },
                      {
                        key: 'calculateServer',
                        ignore: true
                      },
                      {
                        key: 'refreshOnBlur',
                        ignore: true
                      },

                      {
                        key: 'readOnlyValue',
                        ignore: true
                      },
                      {
                        key: 'clearOnRefresh',
                        ignore: true
                      },
                      {
                        key: 'selectThreshold',
                        ignore: true
                      },

                      {
                        key: 'customOptions',
                        ignore: true
                      },
                      {
                        key: 'useExactSearch',
                        ignore: true
                      },
                      {
                        key: 'searchEnabled',
                        ignore: true
                      },
                      {
                        key: 'allowCalculateOverride',
                        ignore: true
                      },
                      {
                        key: 'protected',
                        ignore: true
                      },
                      {
                        key: 'dbIndex',
                        ignore: true
                      },
                      {
                        key: 'clearOnHide',
                        ignore: true
                      },
                      {
                        key: 'persistent',
                        ignore: true
                      },
                      {
                        key: 'encrypted',
                        ignore: true
                      },
                      {
                        key: 'dataSrc',
                        ignore: true
                      },



                    ]
                  },
                ],
                panel: [
                  {
                    key: 'api',
                    ignore: true
                  },
                  {
                    key: 'logic',
                    ignore: true
                  },
                  {
                    key: 'layout',
                    ignore: true
                  },
                  {
                    key: 'conditional',
                    ignore: true
                  },

                  {
                    key: 'display',
                    label: 'Basic',
                    components: [

                      {
                        key: 'tooltip',
                        ignore: true
                      },
                      {
                        key: 'collapsible',
                        ignore: true
                      },
                      {
                        key: 'theme',
                        ignore: true
                      },
                      {
                        key: 'customClass',
                        ignore: true
                      },
                      {
                        key: 'hidden',
                        ignore: true
                      },
                      {
                        key: 'hideLabel',
                        ignore: true
                      },
                      {
                        key: 'disabled',
                        ignore: true
                      },
                      {
                        key: 'modalEdit',
                        ignore: true
                      },
                    ]
                  },
                ]
              },


              noDefaultSubmitButton: true,

            }}

            onSaveComponent={onSubmitForm}
            onChange={(data: any) => {
              // console.log("formData", data);
            }}
          />
        </div>
        <div style={{ display: "none" }}>
          <div id="formio-result" />
        </div>
      </div>
    </div>
  );
};
export default Questionnaire;