import React, { useContext, useEffect, useState } from 'react'
import ckeditor, { CKEditor } from '@ckeditor/ckeditor5-react';
import { getoneuser, updateprofile } from "../../../services/emailtemplates-service";
import { useLocation, useNavigate } from 'react-router-dom'

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { RoleContext } from '../../../utils/contexts/RoleContext';

function EditEmailTemplate() {

  const location = useLocation();

  const currentUser = useContext(RoleContext);
  const [err, setErr] = useState<string>("")
  const [values, setValues] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [content, setContent] = useState<string>('');
  const navigate = useNavigate();
  // const history = useHistory()

  useEffect(() => {
    getUserDetails(location?.state?.key);
  }, [])


  const getUserDetails = (key:string) => {

    getoneuser(key).then(
        (data) => {
           
            const user = data.allUsers;
          setValues({ ...user });
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
          setLoading(false);
          setMessage(resMessage);
        }
      );





 

  }

  const FormOnChange = (event:any) => {
    const name = event.target.name;
    const value = event.target.value;
    setValues({ ...values, [name]: value });
  }



  const clickSubmit = (e:any) => {
    e.preventDefault();
    values.content=content;

    updateprofile(location?.state?.key,values).then(
        (data) => {
            navigate(`/${currentUser}/email_templates`);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
          setLoading(false);
          setMessage(resMessage);
        }
      );

  

  }

  return (
    <div className="container pt-5">
        <div className="card email-container">
     <div style={{fontWeight:"bold",fontSize:23,marginBottom:40}}>Edit Email Template</div>
    
      {
        err ?
          <div className="alert alert-danger alert-dismissible fade show" role="alert">
            <strong>{err}</strong>
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div> : ""
      }


      <form onSubmit={clickSubmit} className='form-grid'>

        <div className="form-group">
          <label className="text-muted">Subject Title</label>
          <input type="text" className="form-control" name='subjecttitle' defaultValue={values.subject_title} onChange={FormOnChange} />
        </div>
        <div className="form-group">
          <label className="text-muted"> Key Word</label>
          <input type="text" className="form-control" name='keyword' defaultValue={values.key} onChange={FormOnChange} />
        </div>
        <div className="form-group">
          <label className="text-muted">Content</label>
          <CKEditor 
                  editor={ClassicEditor}
                  
                  data={values.content}
                  
                  onReady={ editor => {
                      // You can store the "editor" and use when it is needed.
                      // console.log( 'Editor is ready to use!', editor );
                  } }
                  onChange={(e, editor) => {
                    const texto = editor.getData();
                    setContent(texto)
                    // console.log('mmmmmm',{ e, editor, texto });
                    // FormOnChange(e) /* I need this function to pass only valores data */
                }}
                //   onChange={ ( editor,event ) => {
                //     //   formsubmission(event)
                //     setContent(event.getData())
                //     // console.log("events",event.getData())
                //   } }
                  onBlur={ ( event, editor ) => {
                      // console.log( 'Blur.', editor.getData() );
                    //   setContent(editor.getData())
                  } }
                  onFocus={ ( event, editor ) => {
                      // console.log( 'Focus.', editor.getData() );
                  } }


                  
                //   onInit ={editor => {

                //   }}
                  />
                  {/* <Field name="content" type="text" className="form-control" /> */}
                  
          {/* <input type="email" className="form-control" name='content' defaultValue={values.content} onChange={FormOnChange} /> */}
        </div>

       
        <button className="btn btn-primary">
          Update
        </button>
       
      </form>
      </div>
    </div>
  )
}

export default EditEmailTemplate











