import * as Yup from "yup";

import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useContext, useState } from "react";

import IEmailSetting from "../../../../types/emailSetting.type";
import { RoleContext } from "../../../../utils/contexts/RoleContext";
import { register } from "../../../../services/emailsetting-service";
import { useNavigate } from 'react-router-dom';

const CreateEmailSettings: React.FC = () => {
  const currentUser = useContext(RoleContext);
  const [successful, setSuccessful] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [content, setContent] = useState<string>('');
  const navigate=useNavigate()
  const initialValues: IEmailSetting = {
    fromemail: "",
    toemail: "",
    authuser: "",
    authpassword: "",
    port: "",
    secure: ""
  };

  const validationSchema = Yup.object().shape({
    // fromemail: Yup.string()
    //   .test(
    //     "len",
    //     "The subject title must be between 3 and 20 characters.",
    //     (val: any) =>
    //       val &&
    //       val.toString().length >= 3 &&
    //       val.toString().length <= 20
    //   )
    //   .required("This field is required!"),
    //   toemail: Yup.string()
    //   .test(
    //     "len",
    //     "The keyword must be between 3 and 20 characters.",
    //     (val: any) =>
    //       val &&
    //       val.toString().length >= 3 &&
    //       val.toString().length <= 20
    //   )
    //   .required("This field is required!"),
    
    //   content: Yup.string()
    //   .test(
    //     "len",
    //     "The content must be between 6 and 40 characters.",
    //     (val: any) =>
    //       val &&
    //       val.toString().length >= 6 &&
    //       val.toString().length <= 40
    //   )
    //   .required("This field is required!"),
   
  });

  const handleRegister = (formValue: IEmailSetting) => {
    const { fromemail, toemail, authuser, authpassword, port, secure } = formValue;
    register( fromemail, toemail, authuser, authpassword, port, secure).then(
      (response) => {
        // setMessage(response.data.message);
        setSuccessful(true);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        // setMessage(resMessage);
        setSuccessful(false);
      }
      
    );
    // navigate('/email_setting')
  };


  return (
    <div className="col-md-12">
      <div className="card email-container">
      <div>
    <button className='btn btn-primary'   onClick={()=>navigate(`/${currentUser}/email_setting`)}>
            Back
          </button>
    </div>
      <div style={{ fontWeight: "bold", fontSize: 23, marginBottom: 40 }}>Email Settings</div> 
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleRegister}
        >
          <Form>
            {!successful && (
              <div>
                <div className="form-group">
                  <label htmlFor="fromemail"> From Email </label>
                  <Field name="fromemail" type="email" className="form-control" />
                  <ErrorMessage
                    name="fromemail"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="toemail"> To Email </label>
                  <Field name="toemail" type="email" className="form-control" />
                  <ErrorMessage
                    name="toemail"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="authuser"> Auth User </label>
                  <Field name="authuser" type="text" className="form-control" />
                  <ErrorMessage
                    name="authuser"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="authpassword"> Auth Password </label>
                  <Field name="authpassword" type="password" className="form-control" />
                  <ErrorMessage
                    name="authpassword"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="port"> Port </label>
                  <Field name="port" type="text" className="form-control" />
                  <ErrorMessage
                    name="port"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>

                {/* <div className="form-group">
                  <label htmlFor="secure"> Secure </label>
                  <Field name="secure" type="checkbox" className="form-control" />
                  <ErrorMessage
                    name="secure"
                    component="div"
                    className="alert alert-danger"
                  />
                </div> */}

                

               


                <div className="form-group">
                  <button type="submit" className="btn btn-primary btn-block">Save</button>
                </div>
              </div>
            )}

            {/* {message && (
              <div className="form-group">
                <div
                  className={
                    successful ? "alert alert-success" : "alert alert-danger"
                  }
                  role="alert"
                >
                  {message}
                </div>
              </div>
            )} */}
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default CreateEmailSettings;
