import "../../App.css"

import * as AuthService from "../../services/auth.service";

import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import { RoleContext } from "../../utils/contexts/RoleContext";
import { createUser } from "../../services/user.service";
import { getCurrentUser } from "../../services/auth.service";

function AddHospitalUser() {

    const [fields, setFields] = useState<any>({
        firstName: {
            value: "",
            error: "",
            required: true
        },
        lastName: {
            value: "",
            error: "",
            required: true
        },
        email: {
            value: "",
            error: "",
            required: true
        },
        phoneNumber: {
            value: "",
            error: "",
            required: true
        },
        generatePassword: {
            value: true,
            error: ""
        },
        password: {
            value: "",
            error: "",
            required: false
        },
        confirmPassword: {
            value: "",
            error: "",
            required: false
        }
    });
    const [roleId, setRoleId] = useState<any>("");
    const [hospital, setHospital] = useState<any>("");
    const location = useLocation();
    const navigate = useNavigate();
    const currentUser = useContext(RoleContext);

    useEffect(() => {
        setRoleId(location.state.roleId);
        hospitalNameSetter();
    }, []);

    function hospitalNameSetter() {
        const currentUser = getCurrentUser();
        const hospitalName = currentUser.firstname + " " + currentUser.lastname;
        setHospital(hospitalName)
    }



    function handleChange(event: any) {
        const { name, type, value } = event.target
        if (type === "checkbox") {
            if (name === "generatePassword") {
                setFields((prevState: any) => ({
                    ...prevState,
                    password: {
                        ...prevState.password,
                        required: !prevState.password.required
                    },
                    confirmPassword: {
                        ...prevState.confirmPassword,
                        required: !prevState.password.required
                    }
                }));
            }
            setFields((prevState: any) => ({ ...prevState, [name]: { ...prevState[name], value: !prevState[name].value } }));
        } else {
            setFields((prevState: any) => ({ ...prevState, [name]: { ...prevState[name], value: value, error: "" } }))
        }
    }

    function handleSubmit(e: any) {
        e.preventDefault();
        let validationSuccess = true
        for (let key in fields) {
            if (fields[key].required && fields[key].value === '') {
                validationSuccess = false
                setFields((prevState: any) => ({ ...prevState, [key]: { ...prevState[key], error: "Please fill this field" } }));
            } else {
                if (key === "email") {
                    if (!validateEmail(fields[key].value)) {
                        setFields((prevState: any) => ({ ...prevState, [key]: { ...prevState[key], error: "Please enter the valid email" } }));
                        validationSuccess = false;
                    }
                } else if (key === "phoneNumber") {
                    if (!validatePhone(fields[key].value)) {
                        setFields((prevState: any) => ({ ...prevState, [key]: { ...prevState[key], error: "Please enter the valid Phone number" } }));
                        validationSuccess = false;
                    }
                } else if (key === "password" && fields[key].required) {
                    if (!(fields[key].value?.length >= 8)) {
                        setFields((prevState: any) => ({ ...prevState, [key]: { ...prevState[key], error: "Your password should at least contains 8 characters" } }));
                        validationSuccess = false;
                    } else if (fields[key].value !== fields.confirmPassword.value) {
                        setFields((prevState: any) => ({ ...prevState, [key]: { ...prevState[key], error: "Your password should match the confirm password" } }));
                        validationSuccess = false;
                    }
                }
            }
        }
        if (validationSuccess) {
            let obj: { roleId: string; hospitalName: string, [key: string]: string } = {
                roleId,
                hospitalName: hospital
            }
            for (let key in fields) {
                if (fields[key].required) {
                    obj[key] = fields[key].value
                }
            }
            createUser(obj).then(
                (response) => {
                    if (response.data?.success) {
                        navigate(`/${currentUser}/${location.state.path}`)
                    }
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
            // else {
            //     register(fields.firstName.value, fields.lastName.value, fields.email.value, fields.password.value, fields.phoneNumber.value, roleId).then(
            //         (response) => {
         
            //         },
            //         (error) => {
            //           const resMessage =
            //             (error.response &&
            //               error.response.data &&
            //               error.response.data.message) ||
            //             error.message ||
            //             error.toString();
        
            //         }
            //       );
            // }
        }
    }

    const validateEmail = (email: string) => {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(email)
    };

    const validatePhone = (phone: string) => {
        var phoneNum = phone.replace(/[^\d]/g, '');
        if (phoneNum.length > 6 && phoneNum.length < 11) {
            return true;
        }
        return false
    };

  

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="field-container">
                    <p>
                        <span>First Name</span>
                        <span className="required-star">*</span>
                    </p>
                    <input
                        style={{ width: '100%' }}
                        name="firstName"
                        type="text"
                        value={fields.firstName.value}
                        onChange={handleChange}
                    />
                    {fields.firstName.error !== "" &&
                        <p className="error-message">{fields.firstName.error}</p>
                    }
                </div>
                <div className="field-container">
                    <p>
                        <span>Last Name</span>
                        <span className="required-star">*</span>
                    </p>
                    <input
                        style={{ width: '100%' }}
                        name="lastName"
                        type="text"
                        value={fields.lastName.value}
                        onChange={handleChange}
                    />
                    {fields.lastName.error !== "" &&
                        <p className="error-message">{fields.lastName.error}</p>
                    }
                </div>
                <div className="field-container">
                    <p>
                        <span>Email</span>
                        <span className="required-star">*</span>
                    </p>
                    <input
                        style={{ width: '100%' }}
                        name="email"
                        type="text"
                        value={fields.email.value}
                        onChange={handleChange}
                    />
                    {fields.email.error !== "" &&
                        <p className="error-message">{fields.email.error}</p>
                    }
                </div>
                <div className="field-container">
                    <p>
                        <span>Phone Number</span>
                        <span className="required-star">*</span>
                    </p>
                    <input
                        style={{ width: '100%' }}
                        name="phoneNumber"
                        type="number"
                        value={fields.phoneNumber.value}
                        onChange={handleChange}
                    />
                    {fields.phoneNumber.error !== "" &&
                        <p className="error-message">{fields.phoneNumber.error}</p>
                    }
                </div>
                <div className="field-container">
                    <input
                        name="generatePassword"
                        type="checkBox"
                        checked={fields.generatePassword.value}
                        onChange={handleChange}
                    />
                    <span> Generate Password </span>
                </div>
                {!fields.generatePassword.value &&
                    <>
                        <div className="field-container">
                            <p>
                                <span>Password</span>
                                <span className="required-star">*</span>
                            </p>
                            <input
                                style={{ width: '100%' }}
                                name="password"
                                type="password"
                                value={fields.password.value}
                                onChange={handleChange}
                            />
                            {fields.password.error !== "" &&
                                <p className="error-message">{fields.password.error}</p>
                            }
                        </div>
                        <div className="field-container">
                            <p>
                                <span>Confirm Password</span>
                                <span className="required-star">*</span>
                            </p>
                            <input
                                style={{ width: '100%' }}
                                name="confirmPassword"
                                type="password"
                                value={fields.confirmPassword.value}
                                onChange={handleChange}
                            />
                            {fields.confirmPassword.error !== "" &&
                                <p className="error-message">{fields.confirmPassword.error}</p>
                            }
                        </div>
                    </>
                }
                <div className="field-container">
                    <button type="submit">Submit</button>
                </div>
            </form>
        </div>
    )
}

export default AddHospitalUser;