// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `label {
  display: block;
  /* margin-top: 10px; */
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  border-radius: 2px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  border-radius: 50%;
}

.add_button {
  /* display: 'inline-block' */
 margin-left:30px;
 margin-top:5px;
  /* margin-right: 80; */
}

.grid-container {
  width: 100%
  /* margin-top: 30px; */
}

.whole-container {
  height: 500px;
  margin-top: 30px;
}

/* Add user page */
.field-container {
  margin-top: 20px;
}

p {
  margin: 0px;
}

.error-message {
  font-size: 14px;
  color: red;
}

.required-star {
  color: red;
  margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,sBAAsB;AACxB;;AAEA;EACE,2BAA2B;EAC3B,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAGhB,kBAAkB;EAGlB,0CAA0C;AAC5C;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,cAAc;EAGd,kBAAkB;AACpB;;AAEA;EACE,4BAA4B;CAC7B,gBAAgB;CAChB,cAAc;EACb,sBAAsB;AACxB;;AAEA;EACE,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA,kBAAkB;AAClB;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,gBAAgB;AAClB","sourcesContent":["label {\n  display: block;\n  /* margin-top: 10px; */\n}\n\n.card-container.card {\n  max-width: 350px !important;\n  padding: 40px 40px;\n}\n\n.card {\n  background-color: #f7f7f7;\n  padding: 20px 25px 30px;\n  margin: 0 auto 25px;\n  margin-top: 50px;\n  -moz-border-radius: 2px;\n  -webkit-border-radius: 2px;\n  border-radius: 2px;\n  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);\n  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);\n  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);\n}\n\n.profile-img-card {\n  width: 96px;\n  height: 96px;\n  margin: 0 auto 10px;\n  display: block;\n  -moz-border-radius: 50%;\n  -webkit-border-radius: 50%;\n  border-radius: 50%;\n}\n\n.add_button {\n  /* display: 'inline-block' */\n margin-left:30px;\n margin-top:5px;\n  /* margin-right: 80; */\n}\n\n.grid-container {\n  width: 100%\n  /* margin-top: 30px; */\n}\n\n.whole-container {\n  height: 500px;\n  margin-top: 30px;\n}\n\n/* Add user page */\n.field-container {\n  margin-top: 20px;\n}\n\np {\n  margin: 0px;\n}\n\n.error-message {\n  font-size: 14px;\n  color: red;\n}\n\n.required-star {\n  color: red;\n  margin-left: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
