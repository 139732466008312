import * as Yup from "yup";

import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";

import IUser from "../../types/user.type";
import { RoleContext, AlertContext } from "../../utils/contexts/RoleContext";
import logo from "../../images/signup/logo-image.webp";
import { register } from "../../services/auth.service";
import shield from "../../images/signup/signup-shieldimage.webp";
import shield2 from "../../images/signup/signup-shieldimage2.webp";
import { useNavigate } from "react-router-dom";
import constants from "../../config/constants";
import Toast from 'react-bootstrap/Toast';
import { Spinner } from "react-bootstrap";


const Register: React.FC = () => {
  const currentUser = useContext(RoleContext);

  const [successful, setSuccessful] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const [firstName, setFirstName] = useState("")
  const [password, setPasword] = useState("")
  const [email, setEmail] = useState("")
  const [lastName, setLastName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [err, setErr] = useState("")
  const [fnameErr, setFnameErr] = useState("")
  const [lnameErr, setLnameErr] = useState("")
  const [emailErr, setEmailErr] = useState("")
  const [passwordErr, setPasswordErr] = useState("")
  const [phonenumberErr, setPhonenumberErr] = useState("")
  const [role, setRole] = useState<any>("");
  const navigate = useNavigate();
  const [cmsurl, setcmsurl] = useState<any>("");
  const [show, setShow] = useState(false);
  const [successValue, setSuccessValue] = useState("");
  const [loading, setLoading] = useState<boolean>(false);


  const toast: any = useContext(AlertContext)

  useEffect(() => {
    const settingvalue: any = localStorage.getItem("role");
    document.title = `${constants.metaTitle}Signup`;
    setRole(JSON.parse(settingvalue))
    const url = window.location.pathname;

    const cmsUrl = url?.split("/")[1];
    // const cmsUrls = url.split("/");
    // localStorage.setItem("role", JSON.stringify(cmsUrls[1]))

    setcmsurl(cmsUrl)
  }, [])

  // const initialValues: IUser = {
  //   firstname: "",
  //   lastname: "",
  //   email: "",
  //   password: "",
  //   phonenumber: ""
  // };

  const validationSchema = Yup.object().shape({
    firstname: Yup.string()
      .test(
        "len",
        "The username must be between 3 and 20 characters.",
        (val: any) =>
          val &&
          val.toString().length >= 3 &&
          val.toString().length <= 20
      )
      .required("This field is required!"),
    lastname: Yup.string()
      .test(
        "len",
        "The username must be between 3 and 20 characters.",
        (val: any) =>
          val &&
          val.toString().length >= 3 &&
          val.toString().length <= 20
      )
      .required("This field is required!"),
    email: Yup.string()
      .email("This is not a valid email.")
      .required("This field is required!"),
    password: Yup.string()
      .test(
        "len",
        "The password must be between 6 and 40 characters.",
        (val: any) =>
          val &&
          val.toString().length >= 6 &&
          val.toString().length <= 40
      )
      .required("This field is required!"),
    phonenumber: Yup.string()
      .test(
        "len",
        "The password must be between 6 and 15 characters.",
        (val: any) =>
          val &&
          val.toString().length >= 6 &&
          val.toString().length <= 15
      )
      .required("This field is required!"),
  });

  function setShowToast(message: string, value: string) {
    setSuccessValue(value)
    setShow(true);
    setMessage(message);
    setTimeout(() => setShow(false), 5000)
  }

  const handleRegister = (e: any) => {
    // const { firstname, lastname, email, password, phonenumber } = formValue;
    e.preventDefault();
    setFnameErr("")
    setLnameErr("")
    setEmailErr("")
    setPasswordErr("")
    setPhonenumberErr("")
    if (firstName === "") {
      setFnameErr("please enter firstname")
    }
    else if (lastName === "") {
      setLnameErr("please enter lastname")
    }
    else if (email === "") {
      setEmailErr("please enter email")
    }
    else if (password === "") {
      setPasswordErr("please enter password")
    }
    else if (phoneNumber === "") {
      setPhonenumberErr("please enter phonenumber")
    }
    else {
      setLoading(true);
      register(firstName, lastName, email, password, phoneNumber, role).then(
        (response) => {
          setLoading(false);
          if (response.data.success) {
            setShowToast(response?.data?.message, "success");
            window.scrollTo(0, 0);
            setTimeout(() => {
            navigate(`/${cmsurl}/login`)
            }, 1000)
          }
          else {
            toast.setShowToast("Something went wrong. Please try again later.")
          }

          setSuccessful(true);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setErr(resMessage);
          setSuccessful(false);
          setLoading(false);
        }
      );
    }
  };

  return (
    <div className="signuppage_wrapper">
      <div className="container">
        <div className="col-sm-12 signuppage_logo">
          <a href="#"><img src={logo} alt="logo-image" /></a>
        </div>
        <div className="col-sm-12 signuppage_section">
          <div className="col-sm-5 signuppage_left_part">
            <h1>Health Insights at Your Fingertips!</h1>
            <div className="signuppage_left_content">
              <div className="signuppage_left_content_image">
                <img src={shield} alt="shield-image" />
              </div>
              <div className="signuppage_left_box">
                <h4>Fast & Efficient</h4>
                <p>Revolutionize your healthcare experience with our Fast & Efficient dashboard – where speed meets precision and every click counts!</p>
              </div>
            </div>
            <div className="signuppage_left_content">
              <div className="signuppage_left_content_image">
                <img src={shield2} alt="shield-image" />
              </div>
              <div className="signuppage_left_box">
                <h4>Real-Time Data Access</h4>
                <p>Stay ahead of the curve, make informed choices, and transform healthcare delivery with up-to-the-minute insights at your fingertips!</p>
              </div>
            </div>
          </div>
          <div className="col-sm-5 signuppage_right_part">
            <h2>Sign Up!</h2>
            <form onSubmit={handleRegister}>
              <div className="col-sm-12 form-group">
                <span>First Name</span>
                <span style={{ color: "red" }}>*</span>
                <input type="text" className="form-control" name="fname" placeholder="First Name" value={firstName} onChange={(e) => { setFnameErr(""); setFirstName(e.target.value) }} />
                {
                  fnameErr ?
                    <div >
                      <p style={{ color: "red" }}>{fnameErr}</p>

                    </div> : ""
                }
              </div>
              <div className="col-sm-12 form-group">
                <span>Last Name</span>
                <span style={{ color: "red" }}>*</span>
                <input type="text" className="form-control" name="lname" placeholder="Last Name" value={lastName} onChange={(e) => { setLnameErr(""); setLastName(e.target.value) }} />
                {
                  lnameErr ?
                    <div >
                      <p style={{ color: "red" }}>{lnameErr}</p>

                    </div> : ""
                }
              </div>
              <div className="col-sm-12 form-group email">
                <span>E-mail adress</span>
                <span style={{ color: "red" }}>*</span>
                <input type="email" className="form-control" name="email" placeholder="E-mail adress" value={email} onChange={(e) => { setEmailErr(""); setEmail(e.target.value) }} />
                {
                  emailErr ?
                    <div >
                      <p style={{ color: "red" }}>{emailErr}</p>

                    </div> : ""
                }
              </div>
              <div className="col-sm-12 form-group">
                <span>Password</span>
                <span style={{ color: "red" }}>*</span>
                <input type="password" className="form-control" name="lname" placeholder="Password" value={password} onChange={(e) => { setPasswordErr(""); setPasword(e.target.value) }} />
                {
                  passwordErr ?
                    <div >
                      <p style={{ color: "red" }}>{passwordErr}</p>

                    </div> : ""
                }
              </div>
              <div className="col-sm-12 form-group">
                <span>Phone Number</span>
                <span style={{ color: "red" }}>*</span>
                <input type="number" className="form-control" name="phone" placeholder="Phone Number" value={phoneNumber} onChange={(e) => { setPhonenumberErr(""); setPhoneNumber(e.target.value) }} />
                {
                  phonenumberErr ?
                    <div >
                      <p style={{ color: "red" }}>{phonenumberErr}</p>

                    </div> : ""
                }
              </div>
              <div className="col-sm-12 signup_btn">
                <button type="button" id="form_cancel" onClick={() => navigate(`/${currentUser}/login`)}>Cancel</button>
                <button
                  type="submit"
                  id="form_signup"
                >
                  Signup
                  <span className="ms-2">
                    {loading &&
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    }
                  </span>
                </button>
              </div>
              {
                err ?
                  <div >
                    <p style={{ color: "red" }}>{err}</p>

                  </div> : ""
              }
              <strong>Already have account? <a onClick={() => navigate(`/${currentUser}/login`)}>Log in</a></strong>
            </form>
          </div>
        </div>
        <div className="col-sm-12 termsprivacy_section">
          <p>By login you agree to our <a href="#">Terms of Service</a> and <a href="#">Privacy Policy.</a></p>
        </div>
      </div>
      {show &&
        <Toast className={successValue === "success" ? "float-start end-0 position-absolute show bg-success z-3" : "float-start end-0 position-absolute show bg-danger z-3"} style={{ top: "0px" }}>
          <Toast.Header className={`show ${successValue === "success" ? "bg-success" : "bg-danger"}`}>
            <strong className="me-auto text-white">Success Message</strong>
          </Toast.Header>
          <div aria-live="polite" aria-atomic="true" className="d-flex justify-content-center align-items-center w-100 mb-10 text-white bg-transparent">
            <div className="fade toast show  bg-transparent" role="alert" aria-live="assertive" aria-atomic="true">
              <div className="fade toast show bg-transparent p-4 text-white">
                {message}
              </div>
            </div>
          </div>
        </Toast>
      }
    </div>
  );
};

export default Register;
