import { Row, Col } from "reactstrap";
import SearchInput from "../../../components/SearchInput";
import { useState, useContext, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { RoleContext, AlertContext } from "../../../utils/contexts/RoleContext";
import { getHospitals } from "../../../services/user.service";
import SearchableDropdown from "../../../components/SearchableDropdown";
import CustomToolTip from "../../../components/CustomToolTip";
import { hospital } from "../../../layouts/Sidebar";
import { createHospitalInvoice, getOneHospitalInvoice, updateHospitalInvoice } from "../../../services/billing-service";
import { getOneSetting } from "../../../services/adminsetting-service";

export default function AddInvoice() {
    const [hospitals, setHospitals] = useState([]);
    const [items, setItems] = useState<any>([]);
    const [item, setItem] = useState<any>({
        itemName: "",
        quantity: "",
        price: ""
    });
    const [error, setError] = useState<any>({
        hospitalId: "",
        hospitalEmail: "",
        streetAddress: "",
        city: "",
        state: "",
        country: "",
        pincode: "",
        invoiceTitle: "",
        invoiceDueDate: "",
        remarks: "",
        product: "",
    });
    const [formData, setFormData] = useState<any>({
        hospitalId: "",
        hospitalName: "",
        hospitalEmail: "",
        streetAddress: "",
        city: "",
        state: "",
        country: "",
        pincode: "",
        invoiceTitle: "Hospital Subscription",
        invoiceDueDate: "",
        remarks: "",
        subTotal: "",
        totalTax: "",
        grandTotal: ""
    });
    const [taxPercentage, setTaxPercentage] = useState(0);

    const navigate = useNavigate();
    const toast: any = useContext(AlertContext);
    const currentUser = useContext(RoleContext);
    const { invoiceId } = useParams();

    useEffect(() => {
        fetchHospitals();
        fetchTax();
        if (invoiceId) {
            fetchOneHospitalInvoice();
        }
    }, [invoiceId]);

    async function fetchOneHospitalInvoice() {
        const response = await getOneHospitalInvoice(invoiceId);
        if (response?.success) {
            const formData = response.data;
            console.log("formdata", formData)
            const items = [...JSON.parse(formData.items)];
            // const items = [ ...formData.items ]
            delete formData.items;
            // console.log("items", items)
            setFormData(formData);
            setItems(items);
        }
    }

    async function fetchTax() {
        const response = await getOneSetting('tax');
        if (response?.success) {
            setTaxPercentage(Number(response?.data?.settingsValue))
        }
    }

    async function fetchHospitals() {
        const response = await getHospitals();
        if (response?.success) {
            setHospitals(response?.data)
        }
    }

    function handleSelect(item: any) {
        if (item) {
            let hospital: any = hospitals.find((hospital: any) => hospital.id === item.id);
            let streetAddress: string = "";
            let city: string = "";
            let state: string = "";
            let country: string = "";
            let pincode: string = "";
            hospital.userdata?.forEach((item: any) => {
                switch (item.fieldKey) {
                    case "Street Address":
                        streetAddress = JSON.parse(item.fieldValue);
                        break;
                    case "City":
                        city = JSON.parse(item.fieldValue);
                        break;
                    case "State":
                        state = JSON.parse(item.fieldValue);
                        break;
                    case "country":
                        country = JSON.parse(item.fieldValue);
                        break;
                    case "pincode":
                        pincode = JSON.parse(item.fieldValue);
                        break;
                    default:
                        break;
                }
            })
            setFormData((prevState: any) => ({
                ...prevState,
                hospitalId: hospital.id,
                hospitalName: hospital.name,
                hospitalEmail: hospital.email,
                streetAddress,
                city,
                state,
                country,
                pincode
            }));
            if (error.hospitalId !== "" || error.hospitalEmail !== "") {
                setError((prevState: any) => ({ ...prevState, hospitalId: "", hospitalEmail: "" }))
            }
        }
    }

    function handleAddItems() {
        let validationSuccess = true
        for (let key in item) {
            if (item[key] === "") {
                setError((prevState: any) => ({ ...prevState, [key]: "Please fill this field!" }));
                validationSuccess = false;
            }
        }
        if (validationSuccess) {
            const { quantity, price } = item;
            const overAllSubTotal = Number(formData.subTotal);
            const subTotal = Number(quantity) * Number(price);
            const currentSubTotal = overAllSubTotal + subTotal;
            const totalTax = currentSubTotal * (taxPercentage / 100);
            const grandTotal = currentSubTotal + totalTax
            setFormData((prevState: any) => ({ ...prevState, subTotal: currentSubTotal, grandTotal, totalTax }))
            const products = [...items];
            products.push({ ...item, total: Number(item.price) * Number(item.quantity), price: Number(item.price) });
            setItems(products);
            setItem({
                itemName: "",
                quantity: "",
                price: "",
            });
            if (error.product !== "") {
                setError((prevState: any) => ({ ...prevState, product: "" }))
            }
        }
    }

    function handleItemChange(e: any) {
        const { name, value } = e.target;
        setItem((prevState: any) => ({ ...prevState, [name]: value }));
        if (error[name] !== "") {
            setError((prevState: any) => ({ ...prevState, [name]: "" }))
        }
    }

    function handleFormChange(e: any) {
        const { name, value } = e.target;
        setFormData((prevState: any) => ({ ...prevState, [name]: value }));
        if (error[name] !== "") {
            setError((prevState: any) => ({ ...prevState, [name]: "" }))
        }
    }

    function handleDelete(indexNumber: number) {
        setItems(items.filter((item: any, index: number) => {
            if (index === indexNumber) {
                const currentSubTotal = Number(formData.subTotal) - Number(item.total);
                const totalTax = currentSubTotal * (taxPercentage / 100);
                const grandTotal = currentSubTotal + totalTax;
                setFormData((prevState: any) => ({ ...prevState, subTotal: currentSubTotal, grandTotal, totalTax }))
            }
            return index !== indexNumber
        }));
    }

    async function handleSubmit() {
        if (validate()) {
            if (invoiceId) {
                updateInvoice();
            } else {
                createInvoice();
            }
        }
    }

    async function createInvoice() {
        const response = await createHospitalInvoice(formData, items)
        if (response?.success) {
            toast.setShowToast("Invoice created Successfully", "success");
            navigate(`/${currentUser}/view-invoices`);
        } else {
            toast.setShowToast("Something went wrong on our side. Please try again later", "danger")
        }
    }

    async function updateInvoice() {
        const response = await updateHospitalInvoice(invoiceId,formData, items)
        if (response?.success) {
            toast.setShowToast("Invoice updated Successfully", "success");
            navigate(`/${currentUser}/view-invoices`);
        } else {
            toast.setShowToast("Something went wrong on our side. Please try again later", "danger")
        }
    }

    function validate() {
        let validationSuccess = true;
        for (let key in formData) {
            if (formData[key] === "") {
                setError((prevState: any) => ({ ...prevState, [key]: "Please fill this field!" }));
                validationSuccess = false;
            }
        }
        if (items.length == 0) {
            setError((prevState: any) => ({ ...prevState, product: "Please Enter the product details!" }));
        }
        return validationSuccess
    }

    return (
        <Row>
            <div className="panel_top_section position-relative">
                <h1>{invoiceId ? "Update Invoice" : "New Invoice"}</h1>
            </div>
            <Col>
                <div>
                    <div className="mt-3 col-lg-6">
                        <p className="fs-3">To :</p>
                        <div className="mt-2">
                            <p>Hospital Name: </p>
                            <div className="mt-2">
                                <SearchableDropdown
                                    options={hospitals}
                                    label="name"
                                    id="id"
                                    selectedVal={formData?.hospitalId}
                                    handleChange={(val: any) => handleSelect(val)}
                                />
                            </div>
                            {error.hospitalId !== "" &&
                                <p className="text-danger">{error.hospitalId}</p>
                            }
                        </div>
                        <div className="mt-2">
                            <p>Email: </p>
                            <input
                                className="form-control mt-2"
                                value={formData?.hospitalEmail}
                                onChange={handleFormChange}
                                name="hospitalEmail"
                                type="email"
                            />
                            {error.hospitalEmail !== "" &&
                                <p className="text-danger">{error.hospitalEmail}</p>
                            }
                        </div>
                        <div className="mt-2">
                            <p>Address: </p>
                            <div className="row">
                                <div className="col-xl-6 mt-3">
                                    <p>Street Address:</p>
                                    <input
                                        className="mt-2 form-control"
                                        name="streetAddress"
                                        onChange={handleFormChange}
                                        value={formData.streetAddress}
                                    />
                                    {error.streetAddress !== "" &&
                                        <p className="text-danger">{error.streetAddress}</p>
                                    }
                                </div>
                                <div className="col-xl-6 mt-3">
                                    <p>City:</p>
                                    <input
                                        className="mt-2 form-control"
                                        name="city"
                                        onChange={handleFormChange}
                                        value={formData.city}
                                    />
                                    {error.city !== "" &&
                                        <p className="text-danger">{error.city}</p>
                                    }
                                </div>
                                <div className="col-xl-6 mt-3">
                                    <p>State / Province:</p>
                                    <input
                                        className="mt-2 form-control"
                                        name="state"
                                        onChange={handleFormChange}
                                        value={formData.state}
                                    />
                                    {error.state !== "" &&
                                        <p className="text-danger">{error.state}</p>
                                    }
                                </div>
                                <div className="col-xl-6 mt-3">
                                    <p>Country:</p>
                                    <input
                                        className="mt-2 form-control"
                                        name="country"
                                        onChange={handleFormChange}
                                        value={formData.country}
                                    />
                                    {error.country !== "" &&
                                        <p className="text-danger">{error.country}</p>
                                    }
                                </div>
                                <div className="col-xl-6 mt-3">
                                    <p>Pincode:</p>
                                    <input
                                        className="mt-2 form-control"
                                        name="pincode"
                                        onChange={handleFormChange}
                                        value={formData.pincode}
                                    />
                                    {error.pincode !== "" &&
                                        <p className="text-danger">{error.pincode}</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3">
                        <p>Invoice Title</p>
                        <input
                            className="mt-2 form-control"
                            value={formData.invoiceTitle}
                            disabled
                        />
                    </div>
                    <div className="mt-3">
                        <p>Invoice Due Date</p>
                        <input
                            className="mt-2 form-control"
                            type="date"
                            name="invoiceDueDate"
                            onChange={handleFormChange}
                            value={formData.invoiceDueDate}
                        />
                        {error.invoiceDueDate !== "" &&
                            <p className="text-danger">{error.invoiceDueDate}</p>
                        }
                    </div>
                    <div className="mt-4">
                        <h4>Item Form:</h4>
                        <div className="d-xl-flex align-items-center justify-content-between mt-2 ">
                            <div>
                                <p>Item Name</p>
                                <input
                                    className="mt-2 form-control"
                                    name="itemName"
                                    onChange={handleItemChange}
                                    value={item.itemName}
                                />
                                {error.itemName !== "" &&
                                    <p className="text-danger">{error.itemName}</p>
                                }
                            </div>
                            <div className="mt-xl-0 mt-sm-3">
                                <p>Quantity</p>
                                <input
                                    className="mt-2 form-control"
                                    name="quantity"
                                    onChange={handleItemChange}
                                    value={item.quantity}
                                    type="number"
                                />
                                {error.quantity !== "" &&
                                    <p className="text-danger">{error.quantity}</p>
                                }
                            </div>
                            <div className="mt-xl-0 mt-sm-3">
                                <p>Price</p>
                                <input
                                    className="mt-2 form-control"
                                    name="price"
                                    onChange={handleItemChange}
                                    value={item.price}
                                    type="number"
                                />
                                {error.price !== "" &&
                                    <p className="text-danger">{error.price}</p>
                                }
                            </div>
                            <div className="mt-4">
                                <button
                                    className="btn_gradian"
                                    onClick={handleAddItems}
                                >
                                    +Add
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex table-responsive mt-3">
                        <table>
                            <thead>
                                <tr className="list_head">
                                    <th>Item Name</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                    <th>Total</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items?.length > 0 ?
                                    <>
                                        {items?.map((item: any, index: number) => {
                                            return (
                                                <tr className="list_detail" key={index}>
                                                    <td>{item.itemName}</td>
                                                    <td>{item.quantity}</td>
                                                    <td>$ {item.price?.toFixed(2)}</td>
                                                    <td>$ {item.total?.toFixed(2)}</td>
                                                    <td>
                                                        <span className="action" >
                                                            <CustomToolTip title="Delete">
                                                                <button
                                                                    className="exclamation_btn position-relative"
                                                                >
                                                                    <i className="bi bi-trash"
                                                                        onClick={(e: any) => {
                                                                            e.stopPropagation();
                                                                            handleDelete(index)
                                                                        }}
                                                                    ></i>
                                                                </button>
                                                            </CustomToolTip>
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        <tr className="list_detail">
                                            <td colSpan={4} className="text-end">Sub Total</td>
                                            <td>$ {Number(formData?.subTotal)?.toFixed(2)}</td>
                                        </tr>
                                        <tr className="list_detail">
                                            <td colSpan={4} className="text-end">Tax ({taxPercentage} %)</td>
                                            <td>$ {Number(formData?.totalTax)?.toFixed(2)}</td>
                                        </tr>
                                        <tr className="list_detail">
                                            <td colSpan={4} className="text-end">Grand Total</td>
                                            <td>$ {Number(formData?.grandTotal)?.toFixed(2)}</td>
                                        </tr>
                                    </>
                                    :
                                    <tr className="list_detail">
                                        <td colSpan={5}>No items added yet.</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                        {error.product !== "" &&
                            <p className="text-danger">{error.product}</p>
                        }
                    </div>
                    <div className="mt-4">
                        <p>Remarks</p>
                        <textarea
                            className="form-control mt-2 textarea_input"
                            value={formData.remarks}
                            name="remarks"
                            onChange={handleFormChange}
                        />
                        {error.remarks !== "" &&
                            <p className="text-danger">{error.remarks}</p>
                        }
                    </div>
                    <div className="mt-4 d-flex float-right">
                        <button
                            className="delete_btn"
                            onClick={() => navigate(-1)}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn_gradian"
                            onClick={handleSubmit}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </Col>
        </Row>
    )
}