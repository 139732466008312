import './index.css';
import "./assets/scss/style.scss";

// import { triggerInterceptors, triggerResponseInterceptors } from './services/axios-service';

import App from './App';
import { BrowserRouter, useLocation } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { useEffect, useState } from 'react';
import { AuthorizationContext } from './utils/contexts/RoleContext';
import axios, { AxiosRequestConfig } from 'axios';
import { getCurrentUser } from "./services/auth.service";
import { io } from "socket.io-client";
import { SOCKET_DOMAIN } from './config/constants';
import { Manager } from "socket.io-client";

// export const socket = io(SOCKET_DOMAIN, { transports: ['websocket'], path: "/chat/" });
const manager = new Manager(SOCKET_DOMAIN);
export const socket = manager.socket("/");

// import * as serviceWorker from './serviceWorker';
// import { subscribeUser } from './subscription';

// serviceWorker.register();
// subscribeUser()

// const API_URL = "http://localhost:5000/api/auth/";
const API_URL = "https://api.iboostmd.com/api/auth/"



interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}

const refreshAndRetryQueue: RetryQueueItem[] = [];

let isRefreshing = false;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


const AppWrapper = ({ children }: any) => {
  const [tokenInvalid, setTokenInvalid] = useState(false);
  const [message, setMessage] = useState('');
  const location = useLocation();

  useEffect(() => {
    triggerInterceptors();
    triggerResponseInterceptors();
  }, []);

  useEffect(() => {
    if (getCurrentUser()?.id) {
      socket.emit("user_status", {
        status: "IDLE",
        id: getCurrentUser()?.id
      });
      window.onbeforeunload = function () {
        socket.emit("user_status", {
          status: "OFFLINE",
          id: getCurrentUser()?.id
        });
      };
    document.addEventListener("visibilitychange", () => {
      // it could be either hidden or visible
        // if (document.visibilityState == "visible") {
        //   socket.emit("user_status", {
        //     status: "ONLINE",
        //     id: getCurrentUser()?.id
        //   });
        // } else {
        //   socket.emit("user_status", {
        //     status: "IDLE",
        //     id: getCurrentUser()?.id
        //   });
        // }
        // if (document.visibilityState == "hidden") {
        //   socket.emit("user_status", {
        //     status: "IDLE",
        //     id: getCurrentUser()?.id
        //   });
        // } 
        // let userStatus = "IDLE";
        // if (document.visibilityState == "visible" && location.pathname.split("/")[2] === "messages") {
        //   userStatus = "ONLINE";
        // }
        // socket.emit("user_status", {
        //   status: userStatus,
        //   id: getCurrentUser()?.id
        // });
    });
    }

    
    return () => {
      document.removeEventListener("visibilitychange", () =>
        console.log("removed")
      );
    };

    // return () => {
    //   socket.off("user_status");
    //   socket.emit("user_status", "offline");
    // };
  }, []);

  function triggerInterceptors() {
    axios.interceptors.request.use(
      (config: any) => {
        const userStr: any = localStorage.getItem("user");
        const token = userStr !== null ? JSON.parse(userStr).accessToken : null;
        if (token) {
          config.headers['Authorization'] = 'Bearer ' + token
        }

        return config
      },
      error => {
        Promise.reject(error)
      }
    )
  }

  function triggerResponseInterceptors() {
    axios.interceptors.response.use(
      (response: any) => {
        return response;
      },
      async (error: any) => {
        const originalRequest: AxiosRequestConfig = error.config;

        switch (error.response?.status) {
          case 401: {
            if (!isRefreshing) {
              isRefreshing = true;
              try {
                // Refresh the access token
                const newAccessToken = await getResponseToken();

                // Update the request headers with the new access token
                error.config.headers['Authorization'] = `Bearer ${newAccessToken}`;

                // Retry all requests in the queue with the new token
                refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
                  axios
                    .request(config)
                    .then((response) => resolve(response))
                    .catch((err) => reject(err));
                });

                // Clear the queue
                refreshAndRetryQueue.length = 0;

                // Retry the original request
                return axios(originalRequest);
              } catch (refreshError) {
                // Handle token refresh error
                // You can clear all storage and redirect the user to the login page
                throw refreshError;
              } finally {
                isRefreshing = false;
              }
            }

            // Add the original request to the queue
            return new Promise<void>((resolve, reject) => {
              refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
            });
          }
          case 406: {
            setTokenInvalid(true);
            setMessage(error.response.data?.message)
            break;
          }
          default: return Promise.reject(error);
        }
      }
    );
  }

  async function getResponseToken() {
    const currentUser = getCurrentUser()
    const response = await axios.post(`${API_URL}refresh`, {
      jwt: currentUser.refreshToken,
    });
    const obj = { ...currentUser, accessToken: response.data.accessToken };
    localStorage.setItem("user", JSON.stringify(obj));
    return response.data.accessToken;
  }

  if (tokenInvalid) {
    socket.emit("user_status", {
      status: "OFFLINE",
      id: getCurrentUser()?.id
    });
    alert(message)
    window.location.href = `/${getCurrentUser().role}/login`
    localStorage.removeItem("user");
  }

  return (
    <AuthorizationContext.Provider value={tokenInvalid}>
      {children}
    </AuthorizationContext.Provider>
  );
}

root.render(
  <BrowserRouter>
    <AppWrapper>
      <App />
    </AppWrapper>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
