export default function Pagination(props: any) {
    function handlePrevNext(arrow: string) {
        if (arrow === 'prev') {
            props.onClickPageNumber(props.currentPage - 1);
        } else {
            props.onClickPageNumber(props.currentPage + 1);
        }
    }

    return (
        <tr className={`${props.view === "grid" && "col-12 d-flex justify-content-center"} list_detail pagination_list`}>
            <td colSpan={10}>
                <ul>
                    {props.currentPage != 1 &&
                        <li>
                            <a onClick={() => {
                                handlePrevNext('prev');
                            }}>
                                <i className="bi bi-arrow-left"></i>
                            </a>
                        </li>
                    }
                    {props.pages?.map((page: number) => {
                        return (
                            <li
                                className={props.currentPage == page ? "active" : ""}
                                onClick={() => props.onClickPageNumber(page)}
                            >
                                <a>{page}</a>
                            </li>
                        )
                    })}
                    {props.currentPage != props.pages?.length &&
                        <li>
                            <a onClick={() => {
                                handlePrevNext('next');
                            }}
                            >
                                <i className="bi bi-arrow-right"></i>
                            </a>
                        </li>
                    }
                </ul>
            </td>
        </tr>
    )
}