import authHeader from "./auth-header";
import axios from "axios";
import { END_POINT } from "../config/constants";

// const APIS_URL = "http://localhost:5000/api/patient/";
// const APIQue_URL = "http://localhost:5000/api/Questionnarie/";

const APIS_URL = `${END_POINT}patient/`

const APIQue_URL = `${END_POINT}questionnarie/`

export const getallpatient = (page?: any, hospitalId?: number) => {
  return axios
    .get(`${APIS_URL}get_patient?roleId=5&page=${page}&hospitalId=${hospitalId}`)
    .then((response) => {
      return response?.data;
    });
};



export const deletePatientData = (id: any) => {
  return axios
    .delete(APIS_URL + `patient_delete/${id}`, {

    })
    .then((response) => {


      //  response?.data;
      window.location.reload();
    });
};

export const getOnePatient = (key: string) => {
  return axios
    .get(APIS_URL + "get-One-User/" + key, {

    })
    .then((response) => {


      return response?.data;
    });
};



export const getoneuser = (key: string) => {
  return axios
    .get(APIS_URL + "getOneUser/" + key, {

    })
    .then((response) => {


      return response?.data;
    });
};

export const updateprofile = (key: string, values: object) => {
  return axios
    .put(APIS_URL + "updateprofile/" + key, {
      values
    })
    .then((response) => {


      return response?.data;
    });
};

export const importPatientCreate = (values: any) => {
  return axios
    .post(APIS_URL + "import_patient_create", {
      values,
    })
    .then((response) => {


      return response?.data;
    });
};

export const getIncreasedPatientPercentage = (hospitalId: number) => {
  return axios
    .get(`${APIS_URL}get-patient-percentage?hospitalId=${hospitalId}`)
    .then((response) => {
      return response?.data;
    });
};


export const PatientQuestionnarieCreate = (values: any, key: any) => {
  return axios
    .post(APIQue_URL + "patient_questionnarie_create", {
      values,
      key
    })
    .then((response) => {
      return response?.data;
    });
};

export const getPatientQuestionnarie = (key: string) => {
  return axios
    .get(APIQue_URL + "patient_questionnarie/" + key, {

    })
    .then((response) => {


      return response?.data;
    });
};


export const updatePatientQuestionnarie = (values: any) => {
  return axios
    .put(APIQue_URL + "update_patient_questionnarie", {
      values
    })
    .then((response) => {


      return response?.data;
    });
};

export const getallpatientdoctorlist = (key: any) => {
  return axios
    .get(APIS_URL + "getallpatient_doctorlist/" + key)
    .then((response) => {


      return response?.data;
    });
};

export const getQuestionnnaireFields = (id: number, doctorId: number) => {
  return axios
    .get(`${APIQue_URL}get-questionnaire-fields/${id}?doctorId=${doctorId}`)
    .then((response) => {
      return response?.data;
    });
};

export const addPatientQuestionnaire = (values: any, hospitalId: number) => {
  return axios
    .post(APIQue_URL + "add-patient-questionnaire", {
      values, hospitalId
    })
    .then((response) => {
      return response?.data;
    });
};

export const getQuestionnaireAnswers = (id: number, patientId?: number) => {
  return axios
    .get(`${APIQue_URL}get-questionnaire-answers/${id}?patientId=${patientId}`)
    .then((response) => {
      return response?.data;
    });
};

export const deletePatientQuestionnaire = (hospitalId: number) => {
  return axios
    .put(APIQue_URL + "delete-patient-questionnaire", {
      hospitalId
    })
    .then((response) => {
      return response?.data;
    });
};

export const linkDoctors = (doctors: any, formId: number) => {
  return axios
    .put(`${APIQue_URL}link-doctors/${formId}`, {
      doctors
    })
    .then((response) => {
      return response?.data;
    });
};

export const getDoctorsQuestionnaire = () => {
  return axios
    .get(`${APIQue_URL}get-doctors=questionnaire`)
    .then((response) => {
      return response?.data;
    });
};

export const getOnePatientQuestionnaries = ( patientId: number, doctorId: number) => {
  return axios
    .get(`${APIQue_URL}get-patient-questionnaire?patientId=${patientId}&doctorId=${doctorId}`)
    .then((response) => {
      return response?.data;
    });
};

export const requestQuestionnaireAccess = (patientId: number, doctorId: number) => {
  return axios
    .post(`${APIQue_URL}add-questionnaire-request`, {
      patientId,
      doctorId
    })
    .then((response) => {
      return response?.data;
    });
};

export const updateQuestionnaireAccess = (id: number, status: number) => {
  return axios
    .put(`${APIQue_URL}update-questionnaire-request`, {
      id,
      status
    })
    .then((response) => {
      return response?.data;
    });
};

export const checkQuestionnaireAuth = ( patientId: number, requesterId: number) => {
  return axios
    .get(`${APIQue_URL}check-questionnaire-authorization?patientId=${patientId}&requesterId=${requesterId}`)
    .then((response) => {
      return response?.data;
    });
};



