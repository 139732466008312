import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Form,
    FormGroup,
    FormText,
    Input,
    Label,
    Row,
  } from "reactstrap";
  import { NavigateFunction, createSearchParams, useNavigate } from 'react-router-dom';
  import React, { useContext, useEffect, useState } from "react";
  import profile from "../../../assets/images/doctor/profile.png";
  
  import { AgGridReact } from "ag-grid-react";
  import { ColDef } from 'ag-grid-community';
  import IUser from "../../../types/user.type";
  import { ImagePath, getCurrentUser } from "../../../services/auth.service";
  import Modal from 'react-bootstrap/Modal';
  import { RoleContext } from "../../../utils/contexts/RoleContext";
  import award from "../../../assets/images/doctor/award.png";
  import call from "../../../assets/images/doctor/call.png";
  import certificate from "../../../assets/images/doctor/certificate.png";
  import { deleteDoctorData } from "../../../services/doctor-service";
  import { deleteoneuser } from "../../../services/user.service";
  import doctor_image from "../../../assets/images/doctor/doctor-image1.jpg";
  import { getallDoctor } from "../../../services/doctor-service";
  import mail from "../../../assets/images/doctor/mail.png";
  import SearchInput from "../../../components/SearchInput";
  import { getallpatientdoctorlist } from "../../../services/patient-service";
import user1 from "../../../assets/images/users/user1.jpg";
import { createChatUser } from "../../../services/messages-service";
import constants from "../../../config/constants";
import { capitalizeFirstLetter } from "../../../utils/utils";
import PhoneNumber from "../../../components/PhoneNumber";
import { Spinner } from "react-bootstrap";
  // REACT_APP_API_ADDRESS="http://localhost:5000"
  
  // import call from "../../../assets/images/doctor"
  
  interface IRow {
    Name: string;
    Specialty: string;
    Email: string;
    PhoneNumber: string;
  }
  
  
  
  const DoctorsGrid = () => {
    const navigate = useNavigate();
    const [rowData, setRowData] = useState([]);
    const [show, setShow] = useState(false);

    const [deleteId, setDeleteId] = useState<string>("")
    const [doctors, setDoctors] = useState([]);
    const [more, setMore] = useState();
    const [view, setView] = useState('grid');
    const [pages, setpages] = useState<any>([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [popup, showPopup] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
  const [loading, setLoading] = useState(false);

  
  
  
    const currentUser = useContext(RoleContext);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    useEffect(() => {
      getUsers();
    document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - My Doctors`;
  }, [currentUser]);
  
   
  
  
    const handleDelete = async (id: any) => {
  
      deleteoneuser(id).then(
        (response) => {
          // const doctorData = response.result;
          handleClose();
  
          getUsers();
          // setRowData(response.result)
  
          // navigate("/profile");
          // window.location.reload();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
          // setLoading(false);
          // setMessage(resMessage);
        }
      );
    }
  
    const getUsers = async () => {
    setLoading(true);
    let user = getCurrentUser()
      getallpatientdoctorlist(user.id).then(
        (response) => {
        setLoading(false);
        const doctorData = response?.result;
        const array: any = []
        doctorData?.forEach((item: any) => {
          const obj = { ...item.user }
          item?.user?.user_form_data?.forEach((element: any) => {
            if (element?.formFieldKey == "specialtyDepartment") {
              obj.specialty = element?.formFieldValue;
            }
          })
          array.push(obj);
          })
          setDoctors(array);
          setRowData(array);
          setPagination(array);
        },
        (error) => {
        setLoading(false);
        console.log("Err", error)
  
          // setLoading(false);
          // setMessage(resMessage);
        }
      );
    }
  
    const setPagination = (data: any) => {
      let pageLength: number = Math.floor(data.length / 10) + 1;
      const arr = [];
      for (let i = 1; i < pageLength + 1; i++) {
        arr.push(i)
      }
      console.log("array", arr)
      setpages(arr);
    }
  
    const handlePagination = (pageNumber: number) => {
      setPageNumber(pageNumber);
      setDoctors(rowData.slice(pageNumber * 10 - 9, pageNumber * 10 + 1))
      setPagination(rowData);
    }
  
    const showForm = (id: any) => {
      // setShow(true)
      handleShow()
      setDeleteId(id)
    }
  
    const handleClick = (roleId: any) => {
    navigate(`/${currentUser}/add-user/${roleId}`, { state: { roleId: roleId } });
    }

  async function createChatUsers(id: number) {
    const response = await createChatUser(id);
    navigate(`/${currentUser}/messages`, { state: { chatRoomId: response.data.room } });
  }

    return (
  
      <Row>
  
        <div className="panel_top_section">
          <h1>My Doctors</h1>
       
        </div>
        <Col>
          {/* --------------------------------------------------------------------------------*/}
          {/* Card-1*/}
          {/* --------------------------------------------------------------------------------*/}
          <div className="tab-content" id="pills-tabContent">
            {/* grid-tab */}
          
                <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
              <div className="row d-flex table-responsive">
                    <table>
                      <thead>
                        <tr className="list_head">
                          <th>Name</th>
                          <th>Specialty</th>
                          <th>Email</th>
                          <th>Phone number</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                  {doctors?.length > 0 ?
                    <>
                    {doctors.map((doctor: any, index: number) => {
                          return (
                          <tr className="list_detail" key={index + doctor.firstName}
                          onClick={() => {
                            navigate(`/${currentUser}/doctor-detail/${doctor?.id}`, { state: { key: doctor.id } });
                          }}
                          >
                            <td style={{ textAlign: 'left' }}><span>
                            <img src={doctor.profile ? user1 : ImagePath + 'profile/' + doctor.profile} alt="doctor_image" />
                              </span><p>Dr. {doctor.firstName + " " + doctor.lastName}</p></td>
                          <td>{doctor.specialty}</td>
                              <td><a href={`mailto:${doctor.email}`}>{doctor.email}</a></td>
                            <td><PhoneNumber phoneNumber={doctor.phoneNumber} allowCall={doctor.allowCall} /></td>
                            <td onClick={(e) => e.stopPropagation()}>
                                {more !== doctor.id ?
                                  <span className="dost_btn" onClick={() => setMore(doctor.id)}>
                                    <i className="bi bi-three-dots-vertical"></i>
                                  </span>
                                  :
                                  <span className="action" >
                                   
                                <button className="delete_btn_act" 
                                onClick={() => createChatUsers(doctor.id)}
                                >
                                  <i className="bi bi-chat-text" />
                                  </button>
                                    {/* <button className="exclamation_btn"><i className="bi bi-exclamation-circle"></i></button> */}
                                    <button className="exclamation_btn position-relative" 
                                       onClick={() => {
                                    navigate(`/${currentUser}/doctor-detail/${doctor?.id}`, { state: { key: doctor.id } });
                                      }}
                                    ><i className="bi bi-exclamation-circle " onClick={() => {
                                      showPopup(!popup)
                                    }}></i>
                                      {/* {popup===true && */}
                                      <ul className="drop_list m-0 p-0 bg-white p-2  text-left">
                                        {/* <li><a href="#">Edit</a></li><li><a href="#">Delete</a></li> */}
                                        <li><a>{doctor.firstName}</a></li>
  
                                        <li><a href="mailto:drethanbrown@mail.com">{doctor.email}</a></li>
                                      <li><PhoneNumber phoneNumber={doctor.phoneNumber} allowCall={doctor.allowCall} /></li>
                                      <li>{doctor.role?.roleName}</li>
                                      </ul>
                                      {/* // } */}
                                    </button>
                                  </span>
                                }
                              </td>
                            </tr>
                          )
                        })}
                    </>
                    :
                    <>
                      <tr className="list_detail">
                        {!loading ?
                          <td colSpan={10}>No data found</td>
                          :
                          <td colSpan={10}><Spinner /></td>
                        }
                      </tr>
                    </>
                  }
                      </tbody>
                      {pages?.length > 1 &&
                        <tfoot>
                          <tr className="list_detail pagination_list">
                            <td colSpan={5}>
                              <ul>
                                <li onClick={() => handlePagination(pageNumber - 1)}><a href="#"><i className="bi bi-arrow-left"></i></a></li>
                                {pages.map((page: number) => {
                                  return (
                                    <li className={pageNumber == page ? "active" : ""} onClick={() => handlePagination(page)}><a>{page}</a></li>
                                  )
                                })}
                                <li onClick={() => handlePagination(pageNumber + 1)}><a href="#"><i className="bi bi-arrow-right"></i></a></li>
                              </ul>
                            </td>
                          </tr>
                        </tfoot>
                      }
                    </table>
                  </div>
                </div>
          
            
          </div>
        </Col>
        <Modal show={show} onHide={() => handleClose()}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Doctor</Modal.Title>
          </Modal.Header>
          <Modal.Body>Do you want delete this doctor ?</Modal.Body>
          <Modal.Footer>
  
            <button className="bg_gray_500 rounded border-0 py-2 px-4" onClick={() => handleClose()}>
              Cancel
            </button>
            <button className="bg_gradiant text-white rounded border-0 py-2 px-4" onClick={() => handleDelete(deleteId)}>
              Delete
            </button>
          </Modal.Footer>
        </Modal>
      </Row>
    );
  };
  
  export default DoctorsGrid;