import doctor_image from "../assets/images/doctor/doctor-image1.jpg";
import award from "../assets/images/doctor/award.png";
import certificate from "../assets/images/doctor/certificate.png";
import mail from "../assets/images/doctor/mail.png";
import call from "../assets/images/doctor/call.png";
import {
  Card,
  Row,
  Col,
  CardTitle,
  CardBody,
  Button,
  // htmlForm,
  // htmlFormGroup,
  Label,
  Input,
  // htmlFormText,
} from "reactstrap";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { getAllBookingAppointment, deleteOneBooking, requestAppointmentCancellation, bookingNotificationUpdate } from "../../../services/scheduleappointment-service";
import Header from "../../../layouts/Header";
import { NotificationContext } from "../../../utils/contexts/RoleContext";
import DeleteModal from "../../../components/DeleteModal";
import { date } from "yup/lib/locale";
import { RoleContext, AlertContext, HospitalContext } from '../../../utils/contexts/RoleContext';
import { createSearchParams } from 'react-router-dom';
import Pagination from "../../../components/Pagination";
import { decidePages, removeDuplicates } from "../../../utils/utils";
import SearchInput from "../../../components/SearchInput";
import { createChatUser } from "../../../services/messages-service";
import Spinner from 'react-bootstrap/Spinner';
import CustomToolTip from "../../../components/CustomToolTip";
import constants from "../../../config/constants";
import { capitalizeFirstLetter } from "../../../utils/utils";
import { createInvoice } from "../../../services/billing-service";
import { getQuestionnaireAnswers } from "../../../services/patient-service";
import { patient } from "../../../layouts/Sidebar";

const Appointment = () => {
  const navigate = useNavigate();
  const [allBooking, setAllBooking] = useState<any[]>([])
  const [allBookingCopy, setAllBookingCopy] = useState<any[]>([])
  const [more, setMore] = useState();
  const notificationCount: any = useContext(NotificationContext)
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const currentUser = useContext(RoleContext);
  const [pages, setPages] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [showSearch, setShowSearch] = useState(false);
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const [recordsToBeDeleted, setRecordsToBeDeleted] = useState<any>([]);
  const [dateFilter, setDateFilter] = useState<any>({
    fromDate: "undefined",
    toDate: "undefined"
  });
  const [loading, setLoading] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [cancelAppointment, setCancelAppointment] = useState<any>({});
  const [doctors, setDoctors] = useState([]);
  const [doctorId, setDoctorId] = useState<any>("undefined");

  const toast: any = useContext(AlertContext);
  const { date } = useParams();
  const location = useLocation();
  const hospitalContext: any = useContext(HospitalContext);


  useEffect(() => {
    if (date !== undefined) {
      setShowSearch(true);
      setDateFilter({
        fromDate: date,
        toDate: date
      })
    } else {
      if (location?.state?.doctorId) {
        getBookingData(1, undefined, undefined, location.state?.doctorId);
        setShowSearch(true);
        setDoctorId(location?.state?.doctorId);
      } else {
        getBookingData();
      }
    }
  }, [date, location?.state?.doctorId, hospitalContext.selectedHospital?.id]);

  const getBookingData = (page?: number, from?: string, to?: string, doctorId?: number) => {
    setLoading(true)
    const pageCount = page != undefined ? page : 1;
    getAllBookingAppointment(pageCount, from, to, doctorId, hospitalContext.selectedHospital?.id).then(
      (response) => {
        setLoading(false);
        if (response?.success) {
          let bookingData = response?.appointmentBooking.rows;
          const arr: any = [];
          let check: any;
          check = Promise.all(bookingData?.map(async (item: any) => {
            item.user?.user_form_data?.forEach((data: any) => {
            if (data.formFieldKey == "specialtyDepartment") {
                item.specialty = data.formFieldValue;
            }
          })
            let dateObject = new Date(item?.date);
          const time = item.time;
            const splittedTime = time?.split(":");
          dateObject.setHours(Number(splittedTime[0]));
          dateObject.setMinutes(Number(splittedTime[1]));
            item.dateMs = dateObject.getTime()
          const currentDate = new Date().getTime();
          if (currentDate < dateObject.getTime() && item.status == 1 && item.cancelRequested === false) {
            item.showCancelButton = true;
          } else {
            item.showCancelButton = false;
          }
            arr.push(item);
            const questionnaires = await getQuestionnaireAnswers(item.doctorId, item.patientId)
            if (questionnaires?.data?.length > 0) {
              item.questionnaires = questionnaires?.data
            }
            return check;
          })
          )
        setDoctors(response?.doctors)
        // setDoctors(removeDuplicates(doctors, "id"));
          setAllBooking(arr);
          setAllBookingCopy(arr);
          const pages = decidePages(response?.appointmentBooking?.count);
        setPages(pages);
        setPageNumber(pageCount)
        }
      },
      (error) => {
        setLoading(false);
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }
  // const statusZeroCount = allBooking.filter(item => item.status == 0).length;
  const handleDelete = async (id: number) => {

    deleteOneBooking(id).then(
      (response) => {
        // const doctorData = response.result;
        console.log("responesss", response);
        // if(response.status === 200) {
        //   alert('delete booking data conform')
        // } else {
        //   alert('Internal Server Error')
        // }
        getBookingData();

      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  function handleSetDateFilter(e: any) {
    const { name, value } = e.target;
    setDateFilter((prevState: any) => ({ ...prevState, [name]: value }));
  }

  useEffect(() => {
    if (dateFilter.fromDate !== "undefined" && dateFilter.toDate !== "undefined") {
      getBookingData(1, dateFilter.fromDate, dateFilter.toDate)
    }
  }, [dateFilter]);

  useEffect(() => {
    document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Appointments`;
  }, [currentUser])

  function handleTrash(id: string) {
    setShowDeleteModal(true);
    setDeleteId(id)
  }

  async function deleteBooking(id: any) {
    deleteOneBooking(id).then(
      (response) => {
        console.log("responesss", response);
        getBookingData();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  function handleRadioChange(item: any) {
    const deleteArray = [...recordsToBeDeleted];
    const check = deleteArray.find(((element: any) => element.id == item.id));
    if (check === undefined) {
      setRecordsToBeDeleted((prevState: any) => ([...prevState, item]))
    } else {
      setRecordsToBeDeleted((prevState: any) => (prevState.filter((element: any) => element.id != item.id)));
    }
  }

  function decideStatus(item: any) {
    let decideCheck = false
    recordsToBeDeleted.forEach((element: any) => {
      if (element.id == item.id) {
        decideCheck = true;
        return false;
      }
    })
    return decideCheck;
  }

  async function createChatUsers(id: number) {
    const response = await createChatUser(id);
    navigate(`/${currentUser}/messages`, { state: { chatRoomId: response.data.room } });
  }

  async function handleCancellation() {
    const response = await requestAppointmentCancellation(cancelAppointment.id, cancelAppointment.doctorId, cancelAppointment.patientId);
    if (response.success) {
      getBookingData();
      setCancelAppointment({});
      toast.setShowToast(response.data, "success");
    } else {
      toast.setShowToast("Something went wrong on our side. Please try again later.", "danger");
    }
  }

  function handleSelect(e: any) {
    const { value } = e.target;
    if (value == "undefined") {
      getBookingData(1, dateFilter.fromDate, dateFilter.toDate, undefined);
    } else {
      getBookingData(1, dateFilter.fromDate, dateFilter.toDate, value);
    }
    setDoctorId(value);
  }

  const handleSubmit = async (appointment: any, status: number) => {
    try {
      generateInvoice(appointment.orderId, appointment.patientId);
      const response = await bookingNotificationUpdate(appointment.id, status)
      if (response.status === 200) {
        getBookingData()
        setTimeout(() => {
          toast.setShowToast(status == 1 ? "Appointment confirmed" : "Appointment cancelled", "success")
        }, 1000)
      } else {
        alert('Internal Server Error')
      }


    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const generateInvoice = async (orderId: number, selectedPatientId: number) => {
    const response = await createInvoice(orderId, selectedPatientId);
    // console.log("response", response);
  }

  return (
    <>
        <Row>
        <div className="panel_top_section border-0 position-relative appointment-container">
            <h1>{currentUser === "patient" ? "My Appointments" : "Appointments"}</h1>
          <div className={`${(currentUser !== "doctor") && "grid_icons"} d-flex gap-1 align-items-center float-end`}>
              {(currentUser != "doctor") &&
                <button type="button" className="btn_gradian" onClick={() => {
                  if (currentUser === "patient") {
                    navigate(`/patient/patient-appointment`)
                } else if (currentUser === "admin") {
                  navigate(`/${currentUser}/hospital`, { state: { what: "appointment" } });
                } else {
                    navigate(`/${currentUser}/schedule_appointment`)
                  }
                }
                } >
                  + Schedule an Appointment
                </button>}
              <div className="grid_list_icons icons_patients  d-flex align-items-center gap-2">
                {recordsToBeDeleted.length > 0 &&
                  <button
                    type="button"
                    className="bg_dark_blue text-white px-3 py-2 rounded-3"
                    onClick={() => setShowDeleteModal(true)}
                  >
                    <i className="bi bi-trash" />
                  </button>
                }
                {currentUser !== "patient" &&
                  <>
                    <SearchInput
                      visible={showSearch}
                      data={allBookingCopy}
                      setData={(value: any) => setAllBooking(value)}
                    />
                    <span
                      className="filter_icons fs-3 px-1"
                      onClick={() => setShowSearch(!showSearch)}
                    >
                      <i className="bi bi-funnel-fill"></i>
                    </span>
                  </>
                }
              </div>
            </div>
          </div>
          {(currentUser !== "patient" && showSearch) &&
          <div className="row mb-3 col-xl-9 ms-auto" style={{ clear: "both" }}>
            <div className="col-md-4 d-md-flex align-items-center">
              <span className="ms-1 me-1">From:</span>
              <input
                type="date"
                className="form-control"
                id="from-date"
                onChange={handleSetDateFilter}
                name={"fromDate"}
                value={dateFilter.fromDate}
              />
              <span className="ms-2 me-1">-</span>
            </div>
            <div className="col-md-4 d-md-flex align-items-center">
              <span className="ms-1 me-1">To:</span>
              <input
                type="date"
                className="form-control"
                id="to-date"
                onChange={handleSetDateFilter}
                name="toDate"
                value={dateFilter.toDate}
              />
            </div>
            <div className="col-md-4 mt-2 mt-md-0">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={handleSelect}
                  value={doctorId}
                >
                  <option value="undefined">All Doctors</option>
                  {doctors?.map((item: any, index: number) => {
                    return (
                      <option value={item.doctorId} key={index + item.doctorId}>{item.doctorName}</option>
                    )
                  })}
                </select>
              </div>
            </div>
          }
          <Col>
            <div className="row d-flex table-responsive m-0 appoint">
                  <table>
                <thead>
                    <tr className="list_head">
                  {/* {currentUser !== "patient" &&
                    <th>
                      {currentUser !== "doctor" &&
                          <input
                            className="float-start htmlForm-check-input"
                            type="checkbox"
                            name="name"
                            id="name"
                          checked={(recordsToBeDeleted.length === allBooking.length && allBooking.length != 0) ? true : false}
                            onChange={() => {
                              if (recordsToBeDeleted.length === allBooking.length) {
                                setRecordsToBeDeleted([]);
                              } else {
                                const data = [...allBooking];
                                setRecordsToBeDeleted(data);
                              }
                            }}
                          />
                      }
                        </th>
                  } */}
                  <th>
                    {currentUser !== "doctor" &&
                      <span className="px-3 d-block float-start">
                        <input
                          // className="float-start htmlForm-check-input"
                          className="float-start form-check-input"
                          type="checkbox"
                          name="name"
                          id="name"
                          checked={(recordsToBeDeleted.length === allBooking.length && allBooking.length != 0) ? true : false}
                          onChange={() => {
                            if (recordsToBeDeleted.length === allBooking.length) {
                              setRecordsToBeDeleted([]);
                            } else {
                              const data = [...allBooking];
                              setRecordsToBeDeleted(data);
                            }
                          }}
                        />
                      </span>
                    }
                    ID
                  </th>
                  <th>Appointment Date</th>
                  <th>Time</th>
                  <th>Name</th>
                  {currentUser !== "doctor" &&
                    <th>Assigned Doctor</th>
                  }
                  <th>
                    {currentUser === "doctor" ? "Diagnosis" : "Specialty"}
                  </th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                  {allBooking.length > 0 ?
                    <>
                    {allBooking.map((item: any) => {
                      // console.log("itemss",item)
                      const splitTime = item?.time?.split(':')
                      const getTime = splitTime[0]
                      // console.log("timechking",getTime>new Date().getHours(),getTime>new Date().getHours() && new Date(item.date)<=new Date())
                      // console.log("timechking",item.time,new Date().getHours(),new Date(item.time).getTime(),new Date(item.date),new Date())
                      return (
                        <tr
                          className="list_detail"
                          onClick={() => {
                            if(currentUser === "patient") {
                              navigate(`/${currentUser}/appointment-detail/${item.id}`)
                            } else {
                              navigate(`/${currentUser}/appointment/view/${item.id}`, { state: { key: item.id, doctorId: item.doctorId } })
                            }
                            // props.togglePopup();
                          }}
                        >
                          {/* {currentUser !== "patient" &&
                            <td>
                              {currentUser !== "doctor" &&
                              <input
                                className="float-start htmlForm-check-input"
                                type="checkbox"
                                name="name"
                                onChange={() => {
                                  handleRadioChange(item);
                                  // setShowDeleteIcon(true);
                                  // setDeleteId(item.id)
                                }}
                                checked={decideStatus(item)}
                              />
                              }
                            </td>
                          } */}
                          <td>
                            {currentUser !== "doctor" &&
                              <span className="px-3 d-block float-start">
                                <input
                                  className="float-start form-check-input"
                                  type="checkbox"
                                  name="name"
                                  onChange={() => {
                                    handleRadioChange(item);
                                    // setShowDeleteIcon(true);
                                    // setDeleteId(item.id)
                                  }}
                                  checked={decideStatus(item)}
                                />
                              </span>
                            }
                            <span>#{item.id}</span>
                          </td>
                          <td><span className="poppins-600">{item.date}</span></td>
                          <td>{item.time}</td>
                          <td>{item.patientName}</td>
                          {currentUser !== "doctor" &&
                            <td>{item.doctorName}</td>
                          }
                          <td><span>{currentUser === "doctor" ? item.reason : item.specialty}</span></td>
                          <td><span className={`po_completed p-2 py-1 rounded-5 text_sm text-white`} style={{ backgroundColor: item.status == 1 ? "green" : item.status == 2 ? 'red' : 'orange' }}>
                            {item.status == 1 ? 'Confirmed' : item.status == 2 ? 'Cancelled by doctor' : 'Pending'}
                          </span></td>
                          <td onClick={(e) => e.stopPropagation() }>
                            {more !== item.id ?
                              <span className="dost_btn" onClick={() => setMore(item.id)}>
                                <i className="bi bi-three-dots-vertical"></i>
                              </span>
                              :
                              <>
                                {currentUser !== "patient" ?
                                  <span className="action" >
                                    {(currentUser === "hospital" || currentUser === "staff" || currentUser === "admin") && (item.status != 1) &&
                                        <CustomToolTip title="Edit">
                                      <button
                                        className="edit_btn"
                                        onClick={() => navigate(`/${currentUser}/schedule_appointment`, { state: { key: item.id, doctorId: item.doctorId } })}

                                      >
                                        <i className="bi bi-pen"></i>
                                      </button>
                                      </CustomToolTip>
                                    }
                                    {(currentUser === "hospital" || currentUser === "admin") &&
                                        <CustomToolTip title="Delete">
                                      <button
                                        className="delete_btn_act"
                                        onClick={() => handleTrash(item.id)}
                                      >
                                          <i className="bi bi-trash"></i>
                                        </button>
                                      </CustomToolTip>
                                    }
                                    {/* {(currentUser === "staff" || currentUser === "doctor") && */}
                                        <CustomToolTip title="View">
                                      <button
                                          className={currentUser === "doctor" ? "fs-5" : ""}
                                        onClick={() => {
                                        navigate(`/${currentUser}/appointment/view/${item.id}`, { state: { key: item.id, doctorId: item.doctorId } })
                                        // props.togglePopup();
                                          }}
                                        > <i className="bi bi-eye"></i>
                                      </button>
                                      </CustomToolTip>
                                    {/* } */}
                                    {(currentUser === "doctor" && item.questionnaires) &&
                                      <CustomToolTip title="Questionnaires">
                                        <button
                                          className={currentUser === "doctor" ? "fs-5" : ""}
                                          onClick={() => {
                                            navigate(`/${currentUser}/patient-questionnaire`, { state: { hospitalId: item.hospitalId, doctorId: item.doctorId, patientId: item.patientId } })
                                            // props.togglePopup();
                                          }}
                                        > <i className="bi bi-clipboard-check"></i>
                                        </button>
                                      </CustomToolTip>
                                    }
                                    {(currentUser === "doctor" && Date.now() < item.dateMs) &&
                                      <>
                                        {item.status == 0 ?
                                          <>
                                            <CustomToolTip title="Accept">
                                              <button
                                                className="fs-4"
                                                onClick={() => {
                                                  handleSubmit(item, 1)
                                                }}
                                              >
                                                <i className="bi bi-check" />
                                              </button>
                                            </CustomToolTip>
                                            <CustomToolTip title="Reject">
                                              <button
                                                className="fs-4"
                                                onClick={() => {
                                                  handleSubmit(item, 2)
                                                }}
                                              > <i className="bi bi-x" />
                                              </button>
                                            </CustomToolTip>
                                          </>
                                          :
                                          <CustomToolTip title="New Appointment">
                                            <button
                                              className="fs-4"
                                              onClick={() => {
                                                navigate(`/${currentUser}/schedule_appointment`)
                                              }}
                                            > <i className="bi bi-arrow-counterclockwise" />
                                            </button>
                                          </CustomToolTip>
                                        }
                                      </>
                                    }
                                      {(Date.now() > item.dateMs && item.status == 1) && currentUser != "patient" ?
                                        <CustomToolTip title="Prescription">
                                      <button
                                          className={`${currentUser !== "doctor" ? "edit_btn" : "fs-5"}`}
                                        onClick={() => {
                                            navigate(`/${currentUser}/prescriptionlist/${item.id}`, { state: { key: item.patientId, doctorName: item.doctorName, appointmentId: item.id, doctorId: item.doctorId } })
                                          }}
                                      >
                                        <i className="bi bi-exposure"></i>
                                        </button>
                                      </CustomToolTip>
                                      : null}
                                  </span>
                                  :
                                  <>
                                    <span className="action" >
                                        <CustomToolTip title="Info">
                                      <button
                                        className="edit_btn"
                                        onClick={() => navigate(`/${currentUser}/appointment-detail/${item.id}`)}
                                      >
                                        <i className="bi bi-exclamation-circle"></i>
                                      </button>
                                      </CustomToolTip>
                                        <CustomToolTip title="Chat">
                                          <button
                                            className="delete_btn_act"
                                        onClick={() => createChatUsers(item.doctorId)}
                                      >
                                        <i className="bi bi-chat-text"></i>
                                      </button>
                                      </CustomToolTip>
                                      {item.showCancelButton &&
                                          <CustomToolTip title="Request Cancellation">
                                            <button
                                              className="delete_btn_act"
                                          onClick={() => {
                                            setCancelAppointment(item);
                                            setShowCancelModal(true)
                                          }}
                                        >
                                          <i className="bi bi-x"></i>
                                        </button>
                                        </CustomToolTip>
                                      }
                                    </span>
                                  </>
                                }
                              </>

                            }
                            {/* <span className="dost_btn"><i className="bi bi-three-dots-vertical"></i></span>
                          <span className="action" >
                            <button className="edit_btn"><i className="bi bi-pen"></i></button>
                            <button className="exclamation_btn"><i className="bi bi-chat-text"></i></button>
                            <button className="delete_btn_act"><i className="bi bi-trash"></i></button>
                            
                          </span> */}

                          </td>
                        </tr>
                      )
                    })}
                    </>
                    :
                    <tr className="list_detail">
                    <td colSpan={10}>
                      {!loading ?
                        "No data found"
                        :
                        <Spinner />
                      }
                    </td>
                    </tr>
                  }
                </tbody>
                    {pages.length > 1 &&
                      <tfoot>
                        <Pagination
                          pages={pages}
                          currentPage={pageNumber}
                          onClickPageNumber={(page: any) => {
                            setPageNumber(page);
                            getBookingData(page, dateFilter.fromDate, dateFilter.toDate, doctorId);
                          }}
                        />
                      </tfoot>
                    }
                  </table>

                </div>
                <DeleteModal
                  show={showDeleteModal}
                  handleYes={() => {
                    if (deleteId) {
                      deleteBooking(deleteId);
                      setDeleteId("");
                      setRecordsToBeDeleted((prevState: any) => (prevState.filter((item: any) => item.id != deleteId)))
                    } else {
                      recordsToBeDeleted.forEach((item: any) => {
                        deleteBooking(item.id);
                      })
                    }
                    setShowDeleteModal(false)
                  }}
                  handleClose={() => setShowDeleteModal(false)}
                  body="Do you really want to delete this appointment?"
                  head="Delete Location"
                />
                <DeleteModal
                  show={showCancelModal}
                  handleYes={() => {
                    handleCancellation();
                    setShowCancelModal(false);
                  }}
                  handleClose={() => setShowCancelModal(false)}
                  body="Do you really want to cancel this appointment?"
                  head="Request Cancellation"
                  buttonName="Proceed"
                />

          </Col>

      </Row>
    </>
  );
};

export default Appointment;
