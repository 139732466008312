import {
    Row,
    Col
} from "reactstrap";
import { useState, useEffect, useContext } from "react";
import { getOneUserSettings, getProfileSettings, saveUserSettings } from "../../../services/adminsetting-service";
import { AlertContext, RoleContext } from "../../../utils/contexts/RoleContext";

export default function OtherSettings() {
    const [settings, setSettings] = useState<any>({});
    const [errors, setErrors] = useState<any>({});
    const [allSettings, setAllSettings] = useState<any>([]);

    const toast: any = useContext(AlertContext);
    const currentUser = useContext(RoleContext);

    useEffect(() => {
        getSettings();
        fetchOneUserSettings()
    }, []);

    async function getSettings() {
        const response = await getProfileSettings(currentUser);
        if (response?.success) {
            const data = response?.data?.map((item: any) => {
                const value = item.settingsValue?.split(":");
                if (value?.length > 1) {
                    item.type = "select";
                    item.options = value;
                    item.settingsValue = value[0];
                }
                return item;
            })
            setAllSettings(data)
        }
    }

    async function fetchOneUserSettings() {
        const response = await getOneUserSettings();
        if (response?.success) {
            const obj: any = {};
            response?.data?.map((item: any) => {
                obj[item.settingsKey] = item.settingsValue;
            });
            setSettings(obj)
        }
    }


    function handleChange(e: any) {
        const { name, value } = e.target;
        setSettings((prevState: any) => ({ ...prevState, [name]: value }));
        if (errors[name]) {
            const errorsCopy = { ...errors };
            delete errorsCopy[name];
            setErrors(errorsCopy);
        }
    }

    function validate() {
        let success = true;
        for (let key in settings) {
            if (settings[key] === "") {
                setErrors((prevState: any) => ({ ...prevState, [key]: "This field cannot be empty." }));
                success = false;
            }
        }
        return success;
    }

    async function handleSubmit() {
        if (validate()) {
            const response = await saveUserSettings(settings);
            if (response.success) {
                toast.setShowToast("Setting saved successfully!", "success")
            } else {
                toast.setShowToast("Something went wrong on our side. Please try again later!", "danger")
            }
        }
    }

    return (
        <Row className="p-2">
            <Col className="mb-4">
                {allSettings?.length > 0 ?
                    <div>
                        <div className="col-sm-12 col-xl-12 row">
                            {allSettings.map((item: any) => {
                                return (
                                    <div className="col-sm-6 col-xl-6 mb-2">
                                        <label>{item.settingsLabel}</label>
                                        {item.type === "select" ?
                                            <select
                                                className="form-select mt-1"
                                                name={item.settingsKey}
                                                value={settings[item.settingsKey]}
                                                defaultValue={item.settingsValue}
                                                onChange={handleChange}
                                            >
                                                {item.options?.map((option: any) => {
                                                    return (
                                                        <option value={option}>{option}</option>
                                                    )
                                                })}
                                            </select>
                                            :
                                            <input
                                                type="text"
                                                className="form-control mt-1"
                                                value={settings[item.settingsKey]}
                                                onChange={handleChange}
                                                name={item.settingsKey}
                                                defaultValue={item.settingsValue}
                                            />
                                        }
                                        {errors[item?.settingsKey] &&
                                            <p className="text-danger">{errors[item?.settingsKey]}</p>
                                        }
                                    </div>
                                )
                            })}
                        </div>
                        <button
                            className="btn_gradian ms-3 mt-3"
                            onClick={handleSubmit}
                        >
                            Save
                        </button>
                    </div>
                    :
                    <div className="text-center">No data found.</div>
                }
            </Col>
        </Row>
    );
};
