import * as Yup from "yup";

import { ErrorMessage, Field, Form, Formik } from "formik";
import { NavigateFunction, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { getoneuser, resetpassword } from "../../services/auth.service";
import constants from "../../config/constants";
import Toast from 'react-bootstrap/Toast';
import { Spinner } from "react-bootstrap";
// import { getoneuser } from "../services/user.service";


type Props = {}

const ResetPassword: React.FC<Props> = () => {
  let navigate: NavigateFunction = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [urlToken, setUrlToken] = useState<string>("");
  const [users, setUsers] = useState<any>({});
  const [show, setShow] = useState(false);
  const [successValue, setSuccessValue] = useState("");
  const [toastMessage, setToastMessage] = useState<string>("");

  const initialValues: {
    password: string;
    confirmpassword: string;
    // url:string;

  } = {
    password: "",
    confirmpassword: "",
    // url:""

  };

  const validationSchema = Yup.object().shape({
    password: Yup.string().required("This field is required!"),
    // confirmpassword: Yup.string().oneOf([Yup.ref('password'), null], "password mismatch"),
    confirmpassword: Yup.string().required("This field is required!"),

  });


  useEffect(() => {

    // const userStr = localStorage.getItem("user");
    // let user = null;

    // if (userStr)
    //   user = JSON.parse(userStr);

    // if (user && user?.accessToken) {
    //   // url=user.accessToken
    //   setUrlToken(user?.accessToken)


    // }
    let token = "";
    if (typeof (window) !== 'undefined') {
      const url = window.location.search;
      const cmsUrl = url?.split("=")[1];
      token = cmsUrl;
      setUrlToken(cmsUrl)
    }

    getoneuser(token).then(
      (response) => {
        setUsers(response?.getoneuser);
      },
      (error) => {
        const resMessage =
          (error?.response &&
            error?.response?.data &&
            error?.response?.data?.message) ||
          error?.message ||
          error?.toString();

        setLoading(false);
        setMessage(resMessage);
      }
    );
    document.title = `${constants.metaTitle}Reset Password`;



  }, [])



  useEffect(() => {
    let date1: any = new Date();
    let date2: any = new Date(users.tokenCreatedAt);

    let diffTime = date2 - date1;
    let diffMins = diffTime / (600 * 60);

    // if (diffMins < -3) {
    //   setMessage("your link is expired, Please try again")

    // }
  }, [users]);

  function setShowToast(message: string, value: string) {
    setSuccessValue(value)
    setShow(true);
    setToastMessage(message);
    setTimeout(() => setShow(false), 5000)
  }


  const handleLogin = (formValue: { password: string; confirmpassword: string; }) => {
    const { password, confirmpassword } = formValue;
    if(password != confirmpassword) {
      setMessage("Password should match with confirm password.");
      return;
    }

    setMessage("");
    setLoading(true);

    resetpassword(password, urlToken).then(
      (data) => {
        if (data?.isAuth) {
          setShowToast(data.message, "success");
          setTimeout(() => {
            navigate(`/${data?.user?.role}/login`);
        window.location.reload();
          },2000)
        } else {
          setShowToast("Something went wrong. Please try again later.", "danger");
        }
        setLoading(false)
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
      }
    );
  };

  return (
    <div className="col-md-12">
      <div className="card card-container">
        <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        />
        <p style={{ alignSelf: "center" }}>Reset Password</p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleLogin}
        >
          <Form>
            <div className="form-group mt-3">
              <label htmlFor="password">New Password</label>
              <Field name="password" type="password" className="form-control mt-2" />
              <ErrorMessage
                name="password"
                component="div"
                className="alert alert-danger"
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="confirmpassword">Confirm Password</label>
              <Field name="confirmpassword" type="password" className="form-control mt-2" />
              <ErrorMessage
                name="confirmpassword"
                component="div"
                className="alert alert-danger"
              />
            </div>



            <div className="form-group mt-4">
              <button type="submit" className="btn_gradian ms-0" disabled={loading}>
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Submit</span>
              </button>
            </div>

            {message && (
              <div className="form-group mt-3">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
          </Form>
        </Formik>
      </div>
      {show &&
        <Toast className={successValue === "success" ? "float-start end-0 position-absolute show bg-success z-3" : "float-start end-0 position-absolute show bg-danger z-3"} style={{ top: "0px" }}>
          <Toast.Header className={`show ${successValue === "success" ? "bg-success" : "bg-danger"}`}>
            <strong className="me-auto text-white">Success Message</strong>
          </Toast.Header>
          <div aria-live="polite" aria-atomic="true" className="d-flex justify-content-center align-items-center w-100 mb-10 text-white bg-transparent">
            <div className="fade toast show  bg-transparent" role="alert" aria-live="assertive" aria-atomic="true">
              <div className="fade toast show bg-transparent p-4 text-white">
                {toastMessage}
              </div>
            </div>
          </div>
        </Toast>
      }
    </div>
  );
};

export default ResetPassword;
