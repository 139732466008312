import React, { useContext, useEffect, useState, useRef } from "react";
import './DoctorNotification.css';
import { IoIosCloseCircle } from "react-icons/io";
import { getAllBookingAppointment } from "../../../services/scheduleappointment-service";
import { useNavigate } from "react-router-dom";
import { RoleContext, NotificationContext } from "../../../utils/contexts/RoleContext";
import { changeNotificationStatus } from "../../../services/notification-service";
import { updateSeenStatus } from "../../../services/messages-service";
import { socket } from "../../../index";
import { updateQuestionnaireAccess } from "../../../services/patient-service";

const roles = ["Admin", "Hospital Admin", "Doctor", "Staff", "Patient"];

const DoctorNotification = (props: any) => {
  const navigate = useNavigate();
  const currentUser = useContext(RoleContext);
  const notification: any = useContext(NotificationContext);
  const [allBooking, setAllBooking] = useState<any[]>([]);

  // useEffect(() => {
  //   getBookingData();
  // }, []);

  // const getBookingData = () => {
  //   getAllBookingAppointment().then(
  //     (response) => {
  //       const bookingData = response.appointmentBooking;
  //       setAllBooking(bookingData.rows);
  //     },
  //     (error) => {
  //       const resMessage =
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //         error.message ||
  //         error.toString();
  //     }
  //   );
  // }

  async function changeStatus(id: string) {
    const response = await changeNotificationStatus(id);
    if (response.success) {
      // notification.setCount(notification.count - 1);
      notification.setNotifications();
    }
  }

  async function changeQuestionnaireStatus(id: number, status: number) {
    const response = await updateQuestionnaireAccess(id, status);
    if (response.success) {
      // notification.setCount(notification.count - 1);
      notification.setNotifications();
    }
  }

  async function updateReadStatus(id: string, status: number) {
    const response = await updateSeenStatus(id, status);
    notification.setNotifications();
  }

  function clearAll() {
    notification?.notifications?.forEach((item: any) => {
      if (item.notificationLinkTo === "messages") {
        item.messages?.forEach((message: any) => {
          updateReadStatus(message.chatId, 2)
        });
        socket.emit("bulk_messages_received", { roomId: item.messages[0]?.room })
      } else if(item.notificationLinkTo === "appointment" || item.notificationLinkTo === "cancel-appointment") {
        changeStatus(item.id);
      } else {
        changeQuestionnaireStatus(item.id, 2);
      }
    });
    notification.clearAll()
  }

  return (
    <>
      <div className={`popup ${props.isOpen ? "open" : ""}`}  >
        <div className="popup-content">
          <h6>Notifications</h6>
          <div >
            <span
              style={{ fontSize: "12px" }}
              className="me-2 clickable"
              onClick={clearAll}
            >Clear All</span>
            <IoIosCloseCircle style={{ fontSize: '24px', cursor: 'pointer' }} onClick={props.togglePopup} />
          </div>
        </div>
        {/* <div
          className="card p-2 mt-2 mb-2"
                    onClick={() => {
                      navigate(`/${currentUser}/messages`);
                      item.messages?.forEach((message: any) => {
                        updateReadStatus(message.chatId, 2)
                      });
                      notification.setCount(notification.count - 1);
                      props.togglePopup();
                      navigate(`/${currentUser}/messages`, { state: { chatRoomId: item.messages[0]?.room } })
                      notification.setNotifications(notification.notifications.filter((element: any) => element.id != item.id && item.notificationLinkTo === "messages"));
                      socket.emit("bulk_messages_received", { roomId: item.messages[0]?.room })
                    }}
                  >
                    <div className="d-flex align-item-center">
                      <div className="d-flex justify-content-center align-items-center ms-3">
                        <i className="bi bi-snapchat"></i>
                      </div>
                      <div className="ms-4">
                        <p className="fw-bolder">New Messages from {item.name}</p>
                        <div className="mt-2">
                          <div>
                            <ul>
                      {item.messages?.map((message: any) => {
                        return (
                        <li>{message.message === null ? "Media" : message.message}</li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {notification.notifications?.length > 0 ?
          <>
            {notification.notifications.map((item: any) => {
              if (item.notificationLinkTo === "messages") {
                return (
                  <div
                    className="card p-2 mt-2 mb-2 bg-white rounded"
                    onClick={() => {
                      // navigate(`/${currentUser}/messages`);
                      item.messages?.forEach((message: any) => {
                        updateReadStatus(message.chatId, 2)
                      });
                      // notification.setCount(notification.count - 1);
                      props.togglePopup();
                      navigate(`/${currentUser}/messages`, { state: { chatRoomId: item.messages[0]?.room } })
                      // notification.setNotifications(notification.notifications.filter((element: any) => element.id != item.id && item.notificationLinkTo === "messages"));
                      socket.emit("bulk_messages_received", { roomId: item.messages[0]?.room })
                    }}
                  >
                    <div className="d-flex align-item-center">
                      <div className="d-flex justify-content-center align-items-center ms-3">
                        <i className="bi bi-snapchat"></i>
                      </div>
                      <div className="ms-4">
                        <p className="fw-bolder">New Messages from {item.name}</p>
                        <div className="mt-2">
                          <div>
                            <ul>
                              {item.messages?.map((message: any) => {
                                return (
                                  <li style={{ fontSize: "13px" }}>{message.message === null ? "Media" : message.message}</li>
                                )
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              } else if (item.notificationLinkTo === "appointment" || item.notificationLinkTo === "cancel-appointment") {
                return (
                  <div className="card p-2 mt-3 mb-3 bg-white rounded"
                    style={{ opacity: item.status == 1 ? "0.5" : "1" }}
                    onClick={(e) => {
                      // if (item.status == 0) {
                      //   changeStatus(item.id);
                      // }
                      changeStatus(item.id);
                      if (currentUser !== "patient") {
                        navigate(`/${currentUser}/appointment/view/${item.booking_appointment.id}`, { state: { key: item.booking_appointment.id, doctorId: item.booking_appointment.doctorId } })
                      } else {
                        navigate(`/${currentUser}/appointment-detail/${item.booking_appointment.id}`)
                      }
                      props.togglePopup();
                    }}
                  >
                    <div className="d-flex align-item-center">
                      <div className="d-flex justify-content-center align-items-center ms-3">
                        <i className="bi bi-snapchat"></i>
                      </div>
                      <div className="ms-4">
                        <p className="fw-bolder">{item.notificationLinkTo === "appointment" ? "Appointment Notification" : "Appointment Cancellation Request"}</p>
                        <div className="mt-2 d-flex align-items-center">
                          <div>
                            <p className="fw-light notification-body"><span className="fw-bolder">{currentUser !== "patient" ? "Patient Name:" : "Doctor Name:"}</span> {currentUser !== "patient" ? item.booking_appointment.patientName : item.booking_appointment.doctorName}</p>
                            <p className="fw-light notification-body"><span className="fw-bolder">Appointment Date:</span> {item.booking_appointment.date}</p>
                            <p className="fw-light notification-body"><span className="fw-bolder">Appointment Time:</span> {item.booking_appointment.time}</p>
                          </div>
                          {currentUser !== "patient" &&
                          <div className="ms-3">
                            <span className="action" >
                              <button className="edit_btn"
                                  onClick={(e) => {
                                  // if (item.status == 0) {
                                  //   changeStatus(item.id);
                                  // }
                                    e.stopPropagation();
                                  changeStatus(item.id);
                                  navigate(`/${currentUser}/appointment/view/${item.booking_appointment.id}`, { state: { key: item.booking_appointment.id, doctorId: item.booking_appointment.doctorId } })
                                  props.togglePopup();
                                  }}
                              >View</button>
                            </span>
                          </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                )
              } else {
                const user = item.user;
                return (
                  <div className="card p-2 mt-3 mb-3 bg-white rounded"
                    // style={{ opacity: item.status == 1 ? "0.5" : "1" }}
                    onClick={(e) => {
                      props.togglePopup();
                    }}
                  >
                    <div className="d-flex align-item-center">
                      <div className="d-flex justify-content-center align-items-center ms-3">
                        <i className="bi bi-snapchat"></i>
                      </div>
                      <div className="ms-4">
                        <p className="fw-bolder">Access Request</p>
                        <div className="mt-2">
                          <div>
                            <p className="fw-light notification-body">{roles[user?.roleId - 1]} <span className="text-capitalize fw-bolder">{user?.firstName + " " + user.lastName}</span>  want to see your questionnaire details regarding your appointment.</p>
                          </div>
                          <div className="d-flex mt-1">
                              <button
                                className="btn_gradian"
                                onClick={() => changeQuestionnaireStatus(item.id, 1)}
                              >
                                Accept
                              </button>
                              <button
                                className="delete_btn"
                                onClick={() => changeQuestionnaireStatus(item.id, 2)}
                              >
                                Deny
                              </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            })}
          </>
          :
          <div>
            <p style={{ textAlign: 'center' }}>No Notifications yet.</p>
          </div>
        }

      </div>
    </>

  );
};

export default DoctorNotification;

