
import React, { useState } from "react";
// import "./StopWatch.css";
// import Timer from "../Timer";
// import ControlButtons from "../ControlButtons/ControlButtons";

function StopWatch(props:any) {

    // React.useEffect(() => {
    //     let interval: any = null;
    //     interval = setInterval(() => {
    //         props.timeSetter();
    //     }, 1000);
    //     // if (isActive && isPaused === false) {
    //     //     interval = setInterval(() => {
    //     //         setTime((time) => time + 10);
    //     //     }, 10);
    //     // } else {
    //     //     clearInterval(interval);
    //     // }
    //     return () => {
    //         clearInterval(interval);
    //     };
    // }, []);

    return (
        <div className="stop-watch">
            <div className="timer">
                <span className="digits">
                    {("0" + Math.floor((props.time / 60000) % 60)).slice(-2)}:
                </span>
                <span className="digits">
                    {("0" + Math.floor((props.time / 1000) % 60)).slice(-2)}
                </span>
            </div>
        </div>
    );
}

export default StopWatch;