import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import "../../../App.css"

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from "reactstrap";
import React, { useContext, useEffect, useState } from 'react';
import { createSearchParams, useNavigate, useSearchParams, useLocation, useParams } from 'react-router-dom';

import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import IUser from '../../../types/user.type';
import Modal from 'react-bootstrap/Modal';
import { RoleContext } from '../../../utils/contexts/RoleContext';
import { createRoot } from 'react-dom/client';
import { deletePatientData } from "../../../services/patient-service";
import { deleteoneuser } from '../../../services/user.service';
import { getallpatient } from "../../../services/patient-service";
import { getonprescription, deleteOnePrescription, getAppointmentPrescription } from '../../../services/prescription-service';
import { loadavg } from 'os';
import Spinner from 'react-bootstrap/Spinner';
import constants from '../../../config/constants';
import { capitalizeFirstLetter } from '../../../utils/utils';
import CustomToolTip from '../../../components/CustomToolTip';


// Row Data Interface
interface IRow {
  mission: string;
  company: string;
  location: string;
  date: string;
  time: string;
  rocket: string;
  price: number;
  successful: boolean;
}
const endPoint = "http://localhost:3000/";
// Create new GridExample component
const PrescriptionList = () => {
  const currentUser = useContext(RoleContext);
  const navigate = useNavigate()
  // Row Data: The data to be displayed.
  const [rowData, setRowData] = useState([]);
  const [show, setShow] = useState(false);
  const [more, setMore] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [patients, setPatients] = useState([])
  const [deleteId, setDeleteId] = useState<string>("");
  const [pages, setpages] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [popup, showPopup] = useState(false);
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const [searchParams] = useSearchParams();
  const [prescriptionId, setPrescriptionId] = useState<any>("")
  const [userId, setUserId] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const [prescription, setPrescription] = useState<any>({})

  const location = useLocation();
  const { appointmentId } = useParams();



  // Fetch data & update rowData state
  useEffect(() => {
    // getUsers();
    getPrescription();
    if (location?.state?.key != undefined) {
      // getUsers();
      setUserId(location?.state?.key);
    }
    document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Prescriptions`;
  }, [currentUser]);

  const showForm = (id?: any) => {
    console.log("dheirere", id != undefined)
    // console.log("iddd", id === undefined, deleteId)
    if (id != undefined) {
      console.log("Dtjerere")
      handleShow()
      setDeleteId(id)
    }
    else {

      handleShow()
      setDeleteId(deleteId)
    }
  }

  async function getPrescription() {
    setLoading(true);
    const response = await getAppointmentPrescription(appointmentId);
    if (response?.success) {
      setPrescription(response?.data)
    }
    setLoading(false);
  }


  const handleDelete = async (id: any) => {

    deleteOnePrescription(id).then(
      (response) => {
        // const doctorData = response.result;
        console.log("responesss", response);
        getUsers();
        handleClose();
        // setRowData(response.result)

        // navigate("/profile");
        // window.location.reload();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        // setLoading(false);
        // setMessage(resMessage);
      }
    );
  }

  const getUsers = () => {
    setLoading(true);
    getonprescription(location?.state?.key).then(
      (response) => {
        setLoading(false);
        const patientData = response?.allPayments;
        // const newRecord=response.allPayments;
        // setPrescriptionId(patientData[0].medicationId)
        // setUserId(newRecord)
        // setPrescriptionUserId(newRe)
        setPatients(patientData);
        setRowData(patientData);
        setPagination(patientData);
      },
      (error) => {
        console.log("error", error)

        // setLoading(false);
        // setMessage(resMessage);
      }
    );
  }

  const handlePagination = (pageNumber: number) => {
    setPageNumber(pageNumber);
    setPatients(rowData.slice(pageNumber * 10 - 9, pageNumber * 10 + 1))
    setPagination(rowData);
  }

  const setPagination = (data: any) => {
    let pageLength: number = Math.floor(data.length / 10) + 1;
    const arr = [];
    for (let i = 1; i < pageLength + 1; i++) {
      arr.push(i)
    }
    setpages(arr);
  }



  // Container: Defines the grid's theme & dimensions.
  return (
    <Row>
      <div className="panel_top_section position-relative">
        <h1>Prescription Lists</h1>
        <div className="grid_icons d-flex gap-1 align-items-center float-end">
          {!prescription &&
            <button
              type="button"
              className="btn_gradian"
              // onClick={() => navigate(`/${currentUser}/addpatient`, { state: { roleId: "5", path: "patientlist" } })}
              // onClick={() => navigate({
              //   pathname: `/${currentUser}/addprescription`,
              //   search: createSearchParams({
              //     key: userId

              //   }).toString()
              // })}
              onClick={() => navigate(`/${currentUser}/addprescription`, { state: { key: location?.state?.key, appointmentId: appointmentId, doctorId: location?.state?.doctorId } })
              }

            >
              + Add New Prescription
            </button>
          }

        </div>
        {/* <div className="row col-lg-12 col-xl-6 mb-3">
          <label htmlFor="Name" className="form-label">Prescription No</label>
          <input type="text" className="form-control" id="age" disabled  />
        </div> */}

      </div>
      <Col>
            <div className="row d-flex table-responsive">
              <table>
                <thead>
                  <tr className="list_head">
                    <th>PrescriptionId</th>
                    <th>Doctor Name</th>
                    <th>Patient Name</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
              {/* {patients?.map((patient: any, index: number) => {
                    // setPrescriptionId(patient?.medicationId)
                    // console.log("patinetsss",patient,currentUser)
                    return (
                      patient?.prescriptions.length > 0 && patient?.prescriptions.map((item: any, index: number) => {
                        return (
                          <tr className="list_detail" key={index + patient.firstName}>
                            <td><span className="fw-bold">{item.prescriptionId}</span></td>
                            <td>{location?.state?.doctorName}</td>
                            <td>{patient.firstName}</td>
                            <td>{item.status === 1 ? "draft" : "complete"}</td>
                            <td>
                              {more !== item.prescriptionId ?
                                <span className="dost_btn" onClick={() => setMore(item.prescriptionId)}>
                                  <i className="bi bi-three-dots-vertical"></i>
                                </span>
                                :
                                <span className="action" >
                                      <CustomToolTip title="Edit">
                                  <button
                                    className="edit_btn"
                                          onClick={() => navigate(`/${currentUser}/addprescription`, { state: { key: patient.id, appointmentId: appointmentId, doctorId: location?.state?.doctorId, prescriptionId: item.id } })
                                    }
                                  >
                                    <i className="bi bi-pen"></i>
                                  </button>
                                      </CustomToolTip>
                                  {currentUser === "hospital" &&
                                        <CustomToolTip title="Delete">
                                          <button className="delete_btn_act" onClick={() => showForm(item.prescriptionId)} ><i className="bi bi-trash"></i></button>
                                        </CustomToolTip>
                                      }

                                </span>
                              }
                            </td>
                          </tr>
                        )
                      })
                    )
                      })} */}
              {prescription ?
                <tr className="list_detail">
                  <td><span className="fw-bold">{prescription.prescriptionId}</span></td>
                  <td>{location?.state?.doctorName}</td>
                  <td>{location?.state?.doctorName}</td>
                  <td>{prescription.status === 1 ? "draft" : "complete"}</td>
                  <td>
                    <span className="action" >
                      <CustomToolTip title="Edit">
                        <button
                          className="edit_btn"
                          onClick={() => navigate(`/${currentUser}/addprescription`, { state: { key: prescription.userId, appointmentId: appointmentId, doctorId: location?.state?.doctorId, prescriptionId: prescription.id } })
                          }
                        >
                          <i className="bi bi-pen"></i>
                        </button>
                      </CustomToolTip>
                      {currentUser === "hospital" &&
                        <CustomToolTip title="Delete">
                          <button className="delete_btn_act" onClick={() => showForm(prescription.prescriptionId)} ><i className="bi bi-trash"></i></button>
                        </CustomToolTip>
                      }
                    </span>
                  </td>
                </tr>
                :
                <tr className="list_detail">
                  <td colSpan={10}>
                    {!loading ?
                      "No data found"
                      :
                      <Spinner />
                    }
                  </td>
                </tr>
              }
                </tbody>
                {pages?.length > 1 &&
                  <tfoot>
                    <tr className="list_detail pagination_list">
                      <td colSpan={7}>
                        <ul>
                          <li onClick={() => handlePagination(pageNumber - 1)}><a href="#"><i className="bi bi-arrow-left"></i></a></li>
                          {pages.map((page: number) => {
                            return (
                              <li className={pageNumber == page ? "active" : ""} onClick={() => handlePagination(page)}><a>{page}</a></li>
                            )
                          })}
                          <li onClick={() => handlePagination(pageNumber + 1)}><a href="#"><i className="bi bi-arrow-right"></i></a></li>
                        </ul>
                      </td>
                    </tr>
                  </tfoot>
                }
              </table>
            </div>


        <Modal show={show} onHide={() => handleClose()}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Prescription</Modal.Title>
          </Modal.Header>
          <Modal.Body>Do you want delete this Prescription ?</Modal.Body>
          <Modal.Footer>

            <button className="bg_gray_500 rounded border-0 py-2 px-4" onClick={() => handleClose()}>
              Cancel
            </button>
            <button className="bg_gradiant text-white rounded border-0 py-2 px-4" onClick={() => handleDelete(deleteId)}>
              Delete
            </button>
          </Modal.Footer>
        </Modal>
      </Col>

    </Row>
  );
};
export default PrescriptionList;