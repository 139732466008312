import axios from "axios";
import { END_POINT } from "../config/constants";

const API_URL = `${END_POINT}doctoravailability/`;



export const getAllDoctorList = () => {
  return axios
    .get(API_URL + "all_doctor_list", {

    })
    .then((response) => {


      return response?.data;
    });
};
export const saveAvailability = (availability:any) => {
  return axios.post(`${API_URL}doctor_Availability_form`, availability)
};

export const getAllDoctorAvailability = () => {
  return axios
    .get(API_URL + "all_doctor_availability", {

    })
    .then((response) => {


      return response?.data;
    });
};

export const deleteDoctorAvailabilityData = (id: any) => {
  return axios
    .delete(API_URL + `doctor_availability_delete/${id}`, {

    })
    .then((response) => {
      return response?.data
    });
};

export const getoneuser = (key: string) => {
  return axios
    .get(API_URL + "getOneUser/" + key, {

    })
    .then((response) => {


      return response?.data;
    });
};

export const updateprofile = (key: string, values: object) => {
  return axios
    .put(API_URL + "updateprofile/" + key, {
      values,

    })
    .then((response) => {


      return response?.data;
    });
};

export const getDoctorAvailability = () => {
  return axios
    .get(`${API_URL}get-doctor-availability`)
    .then((response) => {
      return response?.data;
    });
};

export const getOneTiming = (id:number) => {
  return axios
    .get(`${API_URL}get-one-timing/${id}`)
    .then((response) => {
      return response?.data;
    });
};

export const updateOneTiming = (id:number, availability:any) => {
  return axios
    .put(`${API_URL}update-one-timing/${id}`, availability)
};