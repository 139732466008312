import axios from "axios";
import { END_POINT } from "../config/constants";

// const API_URL = "http://localhost:5000/api/appointment/";
const API_URL = `${END_POINT}appointment/`



export const getDoctorAppointment = (id: number, limit: number) => {
  return axios
    .get(API_URL + `doctor_schedule_appointment?doc_id=${id}&limit=${limit}`, {

    })
    .then((response) => {


      return response?.data;
    });
};

export const doctorBookingFormCreate = (doctorid: any, doctorName: string, patientId: any, patientName: string, date: any, time: any, reason: string, status: any, appointmentType: string, hospitalId?: number) => {
  return axios.post(API_URL + "doctor_booking_appointment", {
    doctorid,
    doctorName,
    patientId,
    patientName,
    date,
    time,
    reason,
    status,
    appointmentType,
    hospitalId
  });
};


export const doctorBookingFormUpdate = async (id: number, doctorId: any, doctorName: string, patientId: any, patientName: string, date: any, time: any, reason: string, status: any, appointmentType: string) => {
  try {
    const response = await axios.put(API_URL + `edit_booking_appointment/${id}`, {
      doctorId,
      doctorName,
      patientId,
      patientName,
      date,
      time,
      reason,
      status,
      appointmentType
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const bookingNotificationUpdate = async (id: number, status: any) => {
  try {
    const response = await axios.put(API_URL + `edit_booking_notification/${id}`, {
      status
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAppointments = (room: string | null) => {
  return axios
    .get(API_URL + `check-appointments?room=${room}`)
    .then((response) => {
      return response?.data;
    });
};

export const getAppointmentDetails = (room: string) => {
  return axios
    .get(`${API_URL}get-appintment-details?room=${room}`)
    .then((response) => {
      return response?.data;
    });
};


// export const doctorBookingFormUpdate = async (doctorId: any, doctorName: string, patientId: any, patientName: string, date: any, time: any, reason: string, status: any) => {
//   // return axios.put(API_URL + "edit_booking_appointment", {
//   //   doctorid,
//   //   doctorName,
//   //   patientId,
//   //   patientName,
//   //   date,
//   //   reason,
//   //   time,
//   //   status
//   // });
//   try {
//     const response = await axios.put(API_URL + `edit_booking_appointment/doctorId=${doctorId}`, {
//         doctorId,
//         doctorName,
//         patientId,
//         patientName,
//         date,
//         time,
//         reason,
//         status
//     });
//     return response;
// } catch (error) {
//     throw error;
// }
// };


export const getAllBookingAppointment = (page?: number, fromDate?: string, toDate?: string, doctorId?: any, hospitalId?: number) => {
  return axios
    .get(`${API_URL}all_booking_appointment?page=${page}&from=${fromDate}&to=${toDate}&doctorId=${doctorId}&hospitalId=${hospitalId}`)
    .then((response) => {
      return response?.data;
    });
};

export const deleteOneBooking = (id: number) => {
  return axios
    .delete(API_URL + `one_booking_delete?id=${id}`, {

    })
    .then((response) => {
      return response?.data;
    });
};

export const oneDoctorBookingFormUpdate = (id: number) => {
  return axios.get(API_URL + `one_booking_appointment?id=${id}`, {

  })
    .then((response) => {


      return response?.data;
    });
};
export const onePatientBookingAppointment = (key: number, doctorId?: number) => {
  return axios.get(`${API_URL}one_patient_booking_appointment/${key}?doctorId=${doctorId}`)
    .then((response) => {
      return response?.data;
    });
};


export const getOnlineAppointments = (page: number | undefined, hospitalId: number) => {
  return axios
    .get(`${API_URL}get-online-appointments?page=${page}&hospitalId=${hospitalId}`)
    .then((response) => {
      return response?.data;
    });
};

export const getOneBooking = (id: string) => {
  return axios
    .get(`${API_URL}get-one-booking/${id}`)
    .then((response) => {
      return response?.data;
    });
};

export const getBookingsOfOneDoctor = (id: string) => {
  return axios
    .get(`${API_URL}get-all-booking-doctor/${id}`)
    .then((response) => {
      return response?.data;
    });
};

export const updateDuration = (roomId: string, time: number) => {
  return axios
    .put(`${API_URL}update-duration/${roomId}`, {
      time: time
    })
    .then((response) => {
      return response?.data;
    });
};

export const getPendingAppointments = (patientId: number | null) => {
  return axios
    .get(`${API_URL}get-pending-appointments/${patientId}`)
    .then((response) => {
      return response?.data;
    });
};


export const getAppointmentUsers = () => {
  return axios
    .get(`${API_URL}get-appointment-users`)
    .then((response) => {
      return response?.data;
    });
};

export const getLastAppointmentOfUser = (id: number) => {
  return axios
    .get(`${API_URL}get-users-last-appointment/${id}`)
    .then((response) => {
      return response?.data;
    });
};

export const getPatientAppointments = (id: any, page?: number) => {
  return axios
    .get(`${API_URL}get-patient-appointments/${id}?page=${page}`)
    .then((response) => {
      return response?.data;
    });
};

export const getSpecialties = (keyword: string) => {
  return axios
    .get(`${API_URL}get-specialties?keyword=${keyword}`)
    .then((response) => {
      return response?.data;
    });
};

export const getSpecialists = (specialty: string) => {
  return axios
    .get(`${API_URL}get-specialists?specialty=${specialty}`)
    .then((response) => {
      return response?.data;
    });
};

export const getAppointmentInfo = (id: number) => {
  return axios
    .get(`${API_URL}get-appointment-detail/${id}`)
    .then((response) => {
      return response?.data;
    });
};

export const requestAppointmentCancellation = (appointmentId: number, doctorId: number, patientId: number) => {
  return axios
    .post(`${API_URL}request-cancellation`, {
      appointmentId,
      doctorId,
      patientId
    })
    .then((response) => {
      return response?.data;
    });
};

export const getAppointmentsPercentage = () => {
  return axios
    .get(`${API_URL}get-appointments-percentage`)
    .then((response) => {
      return response?.data;
    });
};

