// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    font-family: sans-serif;
    text-align: center;
    overflow-x: hidden;
  }
  
  .float-right {
    float: right;
  }
  .float-left {
    float: left;
  }
  /* button {
    border: none;
    padding: 8px;
    margin-right: 2px;
  } */
  .blue {
    background-color: blue;
    color: white;
    border: none;
    padding: 8px;
  }
  .green {
    background-color: darkolivegreen;
    color: white;
  }
  .btn-toolbar {
    padding-bottom: 10px;
    /* margin: auto; */
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/styles/DragandDrop.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,YAAY;EACd;EACA;IACE,WAAW;EACb;EACA;;;;KAIG;EACH;IACE,sBAAsB;IACtB,YAAY;IACZ,YAAY;IACZ,YAAY;EACd;EACA;IACE,gCAAgC;IAChC,YAAY;EACd;EACA;IACE,oBAAoB;IACpB,kBAAkB;EACpB","sourcesContent":[".App {\r\n    font-family: sans-serif;\r\n    text-align: center;\r\n    overflow-x: hidden;\r\n  }\r\n  \r\n  .float-right {\r\n    float: right;\r\n  }\r\n  .float-left {\r\n    float: left;\r\n  }\r\n  /* button {\r\n    border: none;\r\n    padding: 8px;\r\n    margin-right: 2px;\r\n  } */\r\n  .blue {\r\n    background-color: blue;\r\n    color: white;\r\n    border: none;\r\n    padding: 8px;\r\n  }\r\n  .green {\r\n    background-color: darkolivegreen;\r\n    color: white;\r\n  }\r\n  .btn-toolbar {\r\n    padding-bottom: 10px;\r\n    /* margin: auto; */\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
