import { useState, useEffect, useContext } from "react";
import { RoleContext } from "../../../utils/contexts/RoleContext";
import { getPatientAppointments } from "../../../services/scheduleappointment-service";
import { useNavigate } from "react-router-dom";
import { decideUserNavigation, getCurrentUser } from "../../../services/auth.service";
import constants from "../../../config/constants";
import { capitalizeFirstLetter } from "../../../utils/utils";
import Pagination from "../../../components/Pagination";
import { decidePages } from "../../../utils/utils";
import Spinner from 'react-bootstrap/Spinner'

export default function AppointmentDisplay() {
    const [myAppointments, setMyAppointments] = useState([]);
    const [pages, setPages] = useState<any>([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [loading, setLoading] = useState(false);


    const currentUser = useContext(RoleContext);
    const navigate = useNavigate();
    useEffect(() => {
        getMyAppointments();
        document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Appointments`;
    }, [currentUser]);

    async function userNavigationDecider() {
        // const pathName = window.location.pathname;
        const response = await decideUserNavigation();
        console.log("log res", response)
    }

    async function getMyAppointments(page?: any) {
        setLoading(true);
        const pageCount = page != undefined ? page : 1;
        const response = await getPatientAppointments(getCurrentUser()?.id, pageCount);
        if (response?.success) {
            setMyAppointments(response?.data?.rows);
        }
        const pages = decidePages(response?.data?.count);
        setPages(pages);
        setPageNumber(pageCount);
        setLoading(false);
    }

    return (
        <div>
            <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                <div className="row d-flex table-responsive">
                    <table>
                        <thead>
                            <tr className="list_head">
                                <th>Appointment Date</th>
                                <th>Appointment Time</th>
                                <th>Doctor</th>
                                <th>Hospital</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!loading ?
                                <>
                                    {myAppointments.length > 0 ?
                                        <>
                                            {myAppointments.map((item: any, index: number) => {
                                                return (
                                                    <tr className="list_detail" key={index + item.date}>
                                                        <td>{item.date}</td>
                                                        <td>{item.time}</td>
                                                        <td>{item.doctorName}</td>
                                                        <td>{item.user?.firstName + " " + item.user?.lastName}</td>
                                                        <td>
                                                            {/* <button
                                                className="delete_btn_act"
                                            // onClick={()}
                                            > */}
                                                            <i
                                                                className="bi bi-clipboard-check"
                                                                onClick={() => {
                                                                    navigate(`/${currentUser}/patient-questionnaire`, { state: { hospitalId: item.hospitalId, doctorId: item.doctorId } })
                                                                }}
                                                            />
                                                            {/* </button> */}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </>
                                        :
                                        <tr className="list_detail">
                                            <td colSpan={10}>No data found</td>
                                        </tr>
                                    }
                                </>
                                :
                                <tr className="list_detail">
                                    <td colSpan={10}>
                                        <Spinner />
                                    </td>
                                </tr>
                            }
                        </tbody>
                        {pages?.length > 1 &&
                            <tfoot>
                                <Pagination
                                    pages={pages}
                                    currentPage={pageNumber}
                                    onClickPageNumber={(page: any) => {
                                        setPageNumber(page);
                                        getMyAppointments(page)
                                    }}
                                />
                            </tfoot>
                        }
                    </table>
                </div>
            </div>
        </div>
    )
}