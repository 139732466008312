import {
    Row,
    Col
} from "reactstrap";
import { useState, useEffect, useContext } from "react";
import { addSetting, getAllSettings, updateSetting } from "../../services/adminsetting-service";
import { AlertContext } from "../../utils/contexts/RoleContext";

export default function AdminSettings() {
    const [settings, setSettings] = useState<any>({
        settingLabel: {
            value: "",
            error: ""
        },
        settingKey: {
            value: "",
            error: ""
        },
        settingValue: {
            value: "",
            error: ""
        }
    });
    const [allSettings, setAllSettings] = useState([]);

    const toast: any = useContext(AlertContext);

    useEffect(() => {
        getSettings();
    }, []);

    async function getSettings() {
        const response = await getAllSettings();
        if (response.success) {
            setAllSettings(response.data)
        }
    }

    function handleChange(e: any) {
        const { name, value } = e.target;
        setSettings((prevState: any) => ({ ...prevState, [name]: { ...prevState[name], value, error: "" } }));
    }

    function validate() {
        let success = true;
        for (let key in settings) {
            if (settings[key].value === "") {
                setSettings((prevState: any) => ({ ...prevState, [key]: { ...prevState[key], error: "This is required field!." } }));
                success = false;
            }
        }
        return success;
    }

    async function handleSubmit() {
        if (await validate()) {
            const response = await addSetting(settings);
            if (response.success) {
                getSettings();
                toast.setShowToast("Setting Added successfully!", "success")
            } else if (response.code == 201) {
                toast.setShowToast(response.message, "danger")
            } else {
                toast.setShowToast("Something went wrong on our side. Please try again later!", "danger")
            }
        }
    }

    function handleEditChange(e: any) {
        const { id, value } = e.target;
        setAllSettings((prevState: any) => (prevState.map((item: any) => {
            const itemCopy = { ...item };
            if (item.id == id) {
                itemCopy.settingsValue = value;
            }
            return itemCopy;
        })))
    }

    async function handleUpdate() {
        const arr: any = [];
        allSettings.forEach((item: any) => {
            const obj = {
                id: item.id,
                value: item.settingsValue
            }
            arr.push(obj);
            // const response = await updateSetting(item.id, item.settingsValue);
        });
        const response = await updateSetting(arr);
        if (response.success) {
            toast.setShowToast("Setting updated successfully!", "success")
        } else {
            toast.setShowToast("Something went wrong on our side. Please try again later!", "danger")
        }
    }

    return (
        <Row>
            <div className="panel_top_section border-0 pb-0 pt-2">
                <h1>Settings</h1>
            </div>
            <Col className="mt-3 mb-4">
                {allSettings?.length > 0 &&
                    <div>
                        <div className="col-sm-12 col-xl-12 row">
                            {allSettings.map((item: any) => {
                                return (
                                    <div className="col-sm-6 col-xl-6 mb-2">
                                        <label>{item.settingsLabel}</label>
                                        <input
                                            type="text"
                                            className="form-control mt-1"
                                            value={item.settingsValue}
                                            onChange={handleEditChange}
                                            id={item.id}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                        <button
                            className="btn_gradian ms-3 mt-3"
                            onClick={handleUpdate}
                        >
                            Update
                        </button>
                    </div>
                }
                <div className="mt-5">
                    <div className="col-sm-12 col-xl-12 row">
                        <div className="col-sm-6 col-xl-6">
                            <label>Setting Label</label>
                            <input
                                type="text"
                                className="form-control"
                                name="settingLabel"
                                value={settings.settingLabel.value}
                                onChange={handleChange}
                            />
                            {settings.settingLabel.error &&
                                <div className="text-danger">{settings.settingLabel.error}</div>
                            }
                        </div>
                        <div className="col-sm-6 col-xl-6">
                            <label>Setting Key</label>
                            <input
                                type="text"
                                className="form-control"
                                name="settingKey"
                                value={settings.settingKey.value}
                                onChange={handleChange}
                            />
                            {settings.settingKey.error &&
                                <div className="text-danger">{settings.settingKey.error}</div>
                            }
                        </div>
                        <div className="col-sm-6 col-xl-6 mt-2">
                            <label>Setting Value</label>
                            <input
                                type="text"
                                className="form-control"
                                name="settingValue"
                                value={settings.settingValue.value}
                                onChange={handleChange}
                            />
                            {settings.settingValue.error &&
                                <div className="text-danger">{settings.settingValue.error}</div>
                            }
                        </div>
                    </div>
                    <button
                        className="btn_gradian ms-3 mt-3"
                        onClick={handleSubmit}
                    >
                        Add
                    </button>
                </div>
            </Col>
        </Row>
    );
};
