
import minimize from "../../../assets/images/message/maximize.png";
import VideoCall from "./videocall/VideoCall";


import {
      Row,
      Col,
} from "reactstrap";
import { useState, useEffect, useContext } from "react";
import { getOneBooking } from "../../../services/scheduleappointment-service";
import { useLocation, useSearchParams } from "react-router-dom";
import { RoleContext } from "../../../utils/contexts/RoleContext";
import { getCurrentUser } from "../../../services/auth.service";
import { saveMessage } from "../../../services/messages-service";
import FileRenderer from "../../../components/FileRenderer";
import { ENVIRONMENT } from "../../../config/constants";
import constants from "../../../config/constants";
import { capitalizeFirstLetter } from "../../../utils/utils";

const TelehealthVideo = () => {
      const currentUser = useContext(RoleContext)
      const location = useLocation();
      useEffect(() => {
            document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Videocall`;
      }, [currentUser])

      return (
            <Row>
                  <div className="panel_top_section">
                        <h1>Telehealth</h1>
                  </div>
                  <Col className="mt-3 mb-4">
                        <VideoCall
                              roomId={location.state.room}
                              appointmentId={location.state.appointmentId}
                        />
                  </Col>
            </Row>
      );
};

export default TelehealthVideo;