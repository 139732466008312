import { useEffect, useState, useContext } from "react";
import { getClusters } from "../../../services/user.service";
import IUser from "../../../types/user.type";
import { useNavigate, createSearchParams, useLocation } from "react-router-dom";
import { RoleContext, HospitalContext } from "../../../utils/contexts/RoleContext";
import Pagination from "../../../components/Pagination"
import { decidePages } from "../../../utils/utils";

export default function Hospital() {
    const [hospitals, setHospitals] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pages, setPages] = useState<any>([]);

    const navigate = useNavigate();
    const location = useLocation();
    const currentUser = useContext(RoleContext);
    const hospitalContext: any = useContext(HospitalContext);

    useEffect(() => {

        getHospital();
        // getClusters('2').then((response) => {
        //     console.log("daterer", response.data)
        //     setHospitals(response.data?.rows)
        //     setPagination(response.data?.count);

        // })
        //     .catch(e => console.log(e));
    }, []);

    const getHospital = (page?: any) => {
        const pageCount = page != undefined ? page : 1;
        getClusters('2', pageCount).then((response) => {
            if (response?.success) {
                setHospitals(response?.data?.rows);
                const pages = decidePages(response?.data?.count);
                setPages(pages);
                setPageNumber(pageCount);
            }
        }).catch(e => console.log(e));
    }

    const setPagination = (data: any) => {
        console.log("Dataaa", data)
        let pageLength: number = Math.floor(data / 3) + 1;
        // console.log("hieyeirer",Math.floor(data.length / 11) + 1);
        const arr = [];
        for (let i = 1; i < pageLength + 1; i++) {
            arr.push(i)
        }
        console.log("array", arr)
        // setpages(arr);
    }

    function handleNavigation(hospital: IUser) {
        let path = "";
        switch (location.state?.what) {
            case "location":
                path = "add-location";
                break;

            case "user":
                path = `add-user/${location?.state?.roleId}`
                break;

            case "medicalRecord":
                path = "addmedical-records"
                break;

            case "EditMedicalRecord":
                path = `edit-medical-records/${location.state?.patientId}`
                break;

            case "appointment":
                path = "schedule_appointment";
                break;

            case "newPayment":
                path = "billing-payment";
                break;

            case "questionnaire":
                path = "questionnaire";
                break;

            default:
                hospitalContext.setHospital({ id: hospital.id, name: hospital.firstName + " " + hospital.lastName });
                path = "hospital-dashboard"
        }
        navigate(`/${currentUser}/${path}`, {
            state: {
                hospitalId: hospital.id,
            }
        })
    }

    return (
        <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
            <div className='grid_icons d-flex gap-3 align-items-center'>
                <button
                    className="btn_gradian"

                    onClick={() => navigate(`/${currentUser}/add-user/2`)}
                >
                    Create Hospital
                </button>
            </div>
            <div className="row d-flex ">
                <table>
                    <thead>
                        <tr className="list_head">
                            <th>S. NO.</th>
                            <th>Name</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {hospitals.map((hospital: IUser, index: number) => {
                            return (
                                <tr
                                    className="list_detail clickable"
                                    key={index + hospital.firstName}
                                    onClick={() => {
                                        if (hospitalContext.selectedHospital?.id == hospital?.id || hospitalContext.selectedHospital?.id === -1) {
                                        handleNavigation(hospital);
                                        }
                                    }}
                                    style={{ opacity: (hospitalContext.selectedHospital?.id == hospital?.id || hospitalContext.selectedHospital?.id === -1) ? 1 : 0.5 }}
                                >
                                    <td>{index + 1}</td>
                                    <td>{hospital.firstName + " " + hospital.lastName}</td>
                                    <td><span><i className="bi-chevron-right"></i></span></td>
                                </tr>
                            )
                        })}
                    </tbody>
                    {/* <tfoot>
                        <tr className="list_detail pagination_list">
                            <td colSpan={5}>
                                <ul>
                                    <li><a href="#"><i className="bi bi-arrow-left"></i></a></li>
                                    <li className="active"><a href="#">1</a></li>
                                    <li><a href="#">2</a></li>
                                    <li><a href="#">3</a></li>
                                    <li><a href="#">4</a></li>
                                    <li><a href="#">5</a></li>
                                    <li><a href="#"><i className="bi bi-arrow-right"></i></a></li>
                                </ul>
                            </td>
                        </tr>
                    </tfoot> */}
                    {pages?.length > 1 &&
                        <tfoot>
                            <Pagination
                                pages={pages}
                                currentPage={pageNumber}
                                onClickPageNumber={(page: any) => {
                                    setPageNumber(page);
                                    getHospital(page)
                                }}
                            />
                        </tfoot>
                    }
                </table>
            </div>
        </div>
    )
}