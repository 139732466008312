import axios from "axios";

import { END_POINT } from "../config/constants";

const API_URL = `${END_POINT}messages`

export const saveMessage = (payload: any) => {
  return axios
    .post(`${API_URL}/save`,
      payload
    )
    .then((response) => {
      return response?.data;
    });
};


export const getMessages = (room: string) => {
  return axios
    .get(`${API_URL}/get-messages/${room}`)
    .then((response) => {
      return response?.data;
    });
};

export const getUnreadMessages = (room: string) => {
  return axios
    .get(`${API_URL}/get-unread-messages/${room}`)
    .then((response) => {
      return response?.data;
    });
};

export const updateSeenStatus = (id: string, status: number) => {
  return axios
    .put(`${API_URL}/update-seen-status/${id}`, {
      readStatus: status
    })
    .then((response) => {
      return response?.data;
    });
};

export const createChatUser = (receiverId: number) => {
  return axios
    .post(`${API_URL}/create-chat-users`,
      {
        receiverId
      }
    )
    .then((response) => {
      return response?.data;
    });
};

export const getChatUsers = () => {
  return axios
    .get(`${API_URL}/get-chat-users`)
    .then((response) => {
      return response?.data;
    });
};

export const getImages = (roomId: string, type: string) => {
  return axios
    .get(`${API_URL}/get-images/${roomId}?type=${type}`)
    .then((response) => {
      return response?.data;
    });
};

export const clearChat = (roomId: string) => {
  return axios
    .put(`${API_URL}/clear-chat`,{
      roomId
    })
    .then((response) => {
      return response?.data;
    });
};