import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';

export default function CustomCarousel(props: any) {

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBrwbVt6abHnoZjzNWYWhYhQLwL45mO2hA",
    nonce: "map",
    libraries: ["places"],
  });

  return (
    <CarouselProvider
      naturalSlideWidth={100}
      naturalSlideHeight={125}
      totalSlides={props.data?.length}
    >
      <Slider>
        {props.data?.map((location: any, index: number) => (
          <Slide index={index}>
            <div className="float-start w-full col-sm-12" key={index}>
              <div className="float-start w-full location_detail_grid p-3 pt-4">
                <h4 className="d-inline-block fs-6 textgreen poppins-600 mb-3">{location.locationName}</h4>
                <ul className="float-start w-100 p-0 m-0">
                  <li className="w-100 float-start d-block list-unstyled py-1">
                    <div className="col-sm-10 right_box float-start px-3">
                      <h5 className="poppins-600 fs-6 dark_blue mb-1">Address</h5>
                      <p className="text_sm mb-1">{location.streetAddress}</p>
                    </div>
                  </li>
                  <li className="w-100 float-start d-block list-unstyled py-1">
                    <div className="col-sm-10 right_box float-start px-3">
                      <h5 className="poppins-600 fs-6 dark_blue mb-1">Phone </h5>
                      <a className="text_sm mb-1 text-decoration-none dark_blue" href={`tel:${location.phoneNumber}`}>{location.phoneNumber}</a>
                    </div>
                  </li>
                  <li className="w-100 float-start d-block list-unstyled py-1">
                    <div className="col-sm-10 right_box float-start px-3">
                      <h5 className="poppins-600 fs-6 dark_blue mb-1">Website</h5>
                      <a className="text_sm mb-1 text-decoration-none textgreen" href={`mailto:${location.emailAddress}`}>{location.emailAddress}</a>
                    </div>
                  </li>

                </ul>
              </div>

              <div className="location_map location_map_staff float-start w-100 mt-3 px-4  mb-3">
                {isLoaded &&
                  <GoogleMap
                    mapContainerStyle={{ width: '100%', height: '300px' }}
                    center={{
                      lat: location.latitude,
                      lng: location.longitude
                    }}
                    zoom={10}
                  // onLoad={onLoad}
                  // onUnmount={onUnmount}
                  >
                    <></>
                  </GoogleMap>
                }

              </div>
            </div>
          </Slide>
        ))}
        {/* <Slide index={0}>I am the first Slide.</Slide>
        <Slide index={1}>I am the second Slide.</Slide>
        <Slide index={2}>I am the third Slide.</Slide> */}
      </Slider>
    </CarouselProvider>
  )
}