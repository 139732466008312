import React, { useContext, useEffect, useState } from 'react';
import { createadminsetting, deleteoneadmin, getalladmin, updateadminsetting } from '../../../services/adminsetting-service';

import { AiFillDelete } from "react-icons/ai";
import Button from 'react-bootstrap/Button';
import { Dropdown } from 'react-bootstrap';
import { FaEdit } from "react-icons/fa";
import { RoleContext } from '../../../utils/contexts/RoleContext';
import { useNavigate } from 'react-router-dom';

const GeneralSetting = (props:any) => {
    const [values, setValues] = React.useState<any>("");
    const [adminId, setAdminId] = useState<any>([])
    const [admin, setAdmin] = useState<any>([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [dropDownValue, setDropDownValues] = useState("")
    const currentUser = useContext(RoleContext);

    useEffect(() => {
       getAllAdminSettings();
    }, []);

    const getAllAdminSettings = () => {
       
    
        setMessage("");
        setLoading(true);
    
        getalladmin().then(
          (response) => {
         
           setAdmin(response.allAdmins);
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
    
            setLoading(false);
            setMessage(resMessage);
          }
        );
      };

    const FormOnChange = (event:any,id:any) => {
       
        const name = event.target.name;
        const value = event.target.value;
        setValues({ ...values, [name]: value });
        var obj:any = {}
        obj['id'] = id
        obj['setting_value'] = value
        setAdminId([...adminId, obj])
    }

    const FormSubmit = async (e:any) => {
      
        values.setting_type=dropDownValue

        e.preventDefault();
        e.persist();
        createadminsetting(values).then(
            (response) => {

                // getAllAdminSettings();
              navigate(`/${currentUser}/settings/generalsetting`);
            //   window.location.reload();
            },
            (error) => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
      
              setLoading(false);
              setMessage(resMessage);
            }
          );
     
     
        setValues({ ...values, setting_key: '', setting_label: '', setting_value: '' })
        // navigate("/block")
    }

    const handleDelete = async (item:any) => {
        deleteoneadmin(item.id).then(
            (response) => {
           getAllAdminSettings();
            },
            (error) => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
      
              setLoading(false);
              setMessage(resMessage);
            }
          );
        
    }

   

    const handleSelect = (event:any) => {
      setDropDownValues(event)
  
  };
   
  

  


  

   

    return (
        <div style={{marginLeft:70}}>
            {/* <h4> ADMIN SETTINGS</h4>
            <hr /> */}
            <h6>GENERAL SETTINGS</h6>
            <hr />
            {/* <div className="col-sm-12 row" >
                {admin.map((item:any) => {
                    return (
                        <div className="form-group col-md-6" style={{ width: "50%", margin: "10px 0;" }}  >
                            <label style={{ fontWeight: "bold", margin: "10px 0" }}>{item.setting_label}</label>
                            <input type="text" name={item.setting_key} onChange={(e) => FormOnChange(e, item.id)}  className="form-control" id="exampleInputEmail1" defaultValue={item.setting_value} ></input><br></br>
                            <Button variant="danger" size="sm" onClick={() => handleDelete(item)}> <AiFillDelete /> Delete</Button>
                        </div>
                    )
                })}

            </div><hr></hr>
            <Button variant="primary" size="sm" onClick={() => handleEdit()} > <FaEdit /> Update</Button><hr></hr> */}
            <div className="form-group col-md-6" style={{ width: "50%", margin: "10px 0;" }}  >
                <label  style={{ fontWeight: "bold", margin: "10px 0" }}>Setting Label</label>
                <input type="text" name='setting_label' value={values.setting_label} className="form-control" id="exampleInputEmail1" onChange={(event:any)=>FormOnChange(event,event)}  ></input><br></br>
                <label  style={{ fontWeight: "bold", margin: "10px 0" }}>Setting Key</label>
                <input type="text" name='setting_key' value={values.setting_key}  className="form-control" id="exampleInputEmail1" onChange={(event:any)=>FormOnChange(event,event)} ></input><br></br>
                <label  style={{ fontWeight: "bold", margin: "10px 0" }}>Setting Value</label>
                <input type="text" name='setting_value' value={values.setting_value} className="form-control" id="exampleInputEmail1"onChange={(event:any)=>FormOnChange(event,event)} ></input><br></br>
               
            </div>
            <Dropdown
                    onSelect={handleSelect}
                    >
                        <Dropdown.Toggle  id="dropdown-basic" style={{width:"15%",backgroundColor:"white",color:"black",borderColor:"black",marginLeft:15}}>
                            {dropDownValue===""? "select Types":dropDownValue}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                        <Dropdown.Item eventKey={"General"} >General</Dropdown.Item>
                        <Dropdown.Item eventKey={"Payment"}>Payment</Dropdown.Item>
                       
                        </Dropdown.Menu>
                    </Dropdown>
            <Button
                variant="danger" size="sm" onClick={FormSubmit} style={{marginTop:20}}>Add </Button>{' '}
        </div>
    )
}


export default GeneralSetting;