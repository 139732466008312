import axios from "axios";
import { END_POINT } from "../config/constants";

// const API_URL = "http://localhost:5000/api/location/";
const API_URL = `${END_POINT}location/`

export const addLocation = (locationData: any, otherDetails: any, document: any, hospitalId?: string) => {
  return axios
    .post(`${API_URL}add?hospitalId=${hospitalId}`, { locationData, otherDetails, document })
    .then((response) => {
      return response?.data;
    });
};

export const getLocations = (page?:number, ) => {
  return axios
    .get(`${API_URL}get-all-locations?page=${page}`)
    .then((response) => {
      return response?.data;
    });
};

export const getOneLocation = (id:string) => {
  return axios
    .get(`${API_URL}get-one-location/${id}`)
    .then((response) => {
      return response?.data;
    });
};

export const updateLocation = (id:string,locationData: any, otherDetails: any, document: any) => {
  return axios
    .put(`${API_URL}edit/${id}`, { locationData, otherDetails, document })
    .then((response) => {
      return response?.data;
    });
};

export const deleteLocation = (id:string) => {
  return axios
    .put(`${API_URL}delete/${id}`, { })
    .then((response) => {
      return response?.data;
    });
};