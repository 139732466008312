export const logo = require("../images/signup/logo-image.webp")
export const shield = require("../images/signup/signup-shieldimage.webp")
export const shield2 = require("../images/signup/signup-shieldimage2.webp")

//dev
// export const END_POINT = "http://localhost:5000/api/";
// export const SOCKET_DOMAIN = "http://localhost:5000"

//Production
export const END_POINT = "https://api.iboostmd.com/api/";
export const SOCKET_DOMAIN = "https://api.iboostmd.com"

export const ENVIRONMENT = "dev"
export const metaTitle = "iBoost Healthcare | ";

export default {
    logo,
    shield,
    shield2,
    END_POINT,
    SOCKET_DOMAIN,
    ENVIRONMENT,
    metaTitle
}


