
import locationImg from "../../../assets/images/location/location.png";
import link from "../../../assets/images/location/link.png";
import call from "../../../assets/images/location/call.png";
import location_img from "../../../assets/images/location/location_img.jpg";
import location_big from "../../../assets/images/location/loccation_big.jpg";
import { getOneLocation, deleteLocation } from "../../../services/location-service";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import {
      Row,
      Col
} from "reactstrap";
import { RoleContext } from "../../../utils/contexts/RoleContext";
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import DeleteModal from "../../../components/DeleteModal";
import Spinner from 'react-bootstrap/Spinner';
import constants from "../../../config/constants";
import { capitalizeFirstLetter } from "../../../utils/utils";

const LocationDetail = () => {
      const [locationData, setLocationData] = useState({
            locationName: "",
            streetAddress: "",
            city: "",
            state: "",
            pincode: "",
            country: "",
            phoneNumber: "",
            emailAddress: "",
            faxNumber: "",
            latitude: "",
            longitude: ""
      });
      const [otherDetails, setOtherdetails] = useState({
            rooms: "",
            numberOfBeds: "",
            doctors: "",
            nurses: "",
            staffs: "",
            facilities: [],
            medicalServices: [],
            specializedProcedures: [],
            medicalEquipment: [],
            infrastructure: [],
            document: ""
      });
      const [showDeleteModal, setShowDeleteModal] = useState(false);
      const [loading, setLoading] = useState(false);

      const center = {
            lat: -3.745,
            lng: -38.523
      };

      const { isLoaded } = useJsApiLoader({
            id: 'google-map-script',
            googleMapsApiKey: "AIzaSyBrwbVt6abHnoZjzNWYWhYhQLwL45mO2hA",
            nonce: "map",
            libraries: ["places"],
      })


      const navigate = useNavigate();
      const location = useLocation();
      const currentUser = useContext(RoleContext);
      const { locationId }: any = useParams();

      useEffect(() => {
            if (locationId) {
                  getSingleLocation();
            }
            document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Location Detail - ${locationId}`;
      }, [currentUser]);

      async function getSingleLocation() {
            setLoading(true);
            const locationData = await getOneLocation(locationId);
            if (locationData?.success) {
                  setLocationData(locationData?.data);
                  const otherDetails = locationData?.data?.locationdatum;
            for (let key in otherDetails) {
                  if (key === "infrastructure" || key === "medicalServices" || key === "medicalEquipment" || key === "specializedProcedures" || key === "facilities") {
                        otherDetails[key] = JSON.parse(otherDetails[key])
                  }
            }
            setOtherdetails(otherDetails);
            }
            setLoading(false);
      }

      function navigateToEdit(id: string) {
            // navigate(`/${currentUser}/edit-location`, { state: { id: id } })
            navigate(`/${currentUser}/edit-location/${id}`, { state: { id: id } });
      }

      async function deleteOneLocation(id: string) {
            const data = await deleteLocation(id);
            if (data.success) {
                  navigate(`/${currentUser}/locations`)
            }
      }

      return (
            <>
                  {!loading ?
            <Row>
                  <div className="panel_top_section">
                        <h1>Locations</h1> <span className="textgreen poppins-500"><i className="bi bi-chevron-right"></i>{locationData.city}</span>
                        <div className="grid_icons d-flex gap-1 align-items-center float-end">
                              <button
                                    type="button"
                                    className="btn_gradian"
                                    onClick={() => navigate(`/${currentUser}/add-location`)}
                              >
                                    + Add New Location
                              </button>
                              <button
                                    type="button"
                                    className="dark_btn text-white py-2 rounded-3 mx-1 px-2"
                                    onClick={() => navigateToEdit(locationId)}
                              >
                                    <span className="px-2 pl-0">
                                          <i className="bi bi-pen"></i>
                                    </span>
                                    Edit
                              </button>
                              <div className="grid_list_icons icons_patients  d-flex align-items-center gap-2">
                                    <button
                                          type="button"
                                          className="delet_patiens"
                                          onClick={() => setShowDeleteModal(true)}
                                    // onClick={() => deleteOneLocation(location.state?.id)}
                                    > <i className="bi bi-trash"></i> </button>
                              </div>
                        </div>
                  </div>
                  <Col className="mt-3 mb-4">
                        <div className="add_location col-sm-12 py-4 px-lg-2 mt-2">
                              <div className="row m-0">
                                    <div className="col-xl-6 p-1 pb-2">
                                          <div className="inner_location float-start w-100 bg-white box_shadow p-3 pt-4 radius_15">
                                                {/* map */}
                                                <div className="location_map float-start w-100 mb-3">
                                                      <img width="100%" height="200" className="img-fluid w-100 radius_15" src={location_img} alt="map" />

                                                </div>
                                                <h2 className="d-inline-block fs-5 textgreen poppins-600 mb-2">{locationData.locationName}</h2>
                                                <div className="float-start w-full location_detail_grid">
                                                      <ul className="float-start w-100 p-0 m-0">
                                                            <li className="w-100 float-start d-block list-unstyled py-1">
                                                                  <span className="float-start"><img src={locationImg} alt="award" /></span>
                                                                  <div className="col-sm-10 right_box float-start px-3">
                                                                        <h5 className="poppins-600 fs-6 dark_blue mb-1">Address</h5>
                                                                        <p className="text_sm mb-1">{locationData.streetAddress}</p>
                                                                  </div>
                                                            </li>
                                                            <li className="w-100 float-start d-block list-unstyled py-1">
                                                                  <span className="float-start"><img src={call} alt="call" /></span>
                                                                  <div className="col-sm-10 right_box float-start px-3">
                                                                        <h5 className="poppins-600 fs-6 dark_blue mb-1">Phone </h5>
                                                                        <a className="text_sm mb-1 text-decoration-none dark_blue" href={`tel:${locationData.phoneNumber}`}>{locationData.phoneNumber}</a>
                                                                  </div>
                                                            </li>
                                                            <li className="w-100 float-start d-block list-unstyled py-1">
                                                                  <span className="float-start"><img src={link} alt="mail" /></span>
                                                                  <div className="col-sm-10 right_box float-start px-3">
                                                                        <h5 className="poppins-600 fs-6 dark_blue mb-1">Website</h5>
                                                                        <a className="text_sm mb-1 text-decoration-none textgreen" href={`mailto:${locationData.emailAddress}`}>{locationData.emailAddress}</a>
                                                                  </div>
                                                            </li>

                                                      </ul>
                                                </div>

                                          </div>
                                    </div>
                                    <div className="col-xl-6 p-1 pb-2">
                                          <div className="inner_location float-start w-100 bg-white box_shadow p-3 pt-4 radius_15">

                                                {/* map */}
                                                <div className="location_map_image float-start w-100 ">
                                                      {isLoaded &&
                                                            <GoogleMap
                                                                  mapContainerStyle={{ width: '100%', height: '415px' }}
                                                                  center={{
                                                                        lat: Number(locationData.latitude),
                                                                        lng: Number(locationData.longitude)
                                                                  }}
                                                                  zoom={10}
                                                            // onLoad={onLoad}
                                                            // onUnmount={onUnmount}
                                                            >
                                                                  { /* Child components, such as markers, info windows, etc. */}
                                                                  <></>
                                                            </GoogleMap>
                                                      }
                                                      {/* <img width="100%" height="200" className="img-fluid w-100 radius_15" src={location_big} alt="mail" /> */}
                                                </div>
                                          </div>
                                    </div>
                                    {/* hospital-details */}
                                    <div className="col-xl-4 p-1 pb-2">
                                          <div className="inner_location float-start w-100 bg-white box_shadow p-4 pt-4 radius_15">
                                                <h4 className="textgreen poppins-600 fs-5">Hospital Details:</h4>
                                                <div className="pat_group border-bottom py-3 d-flex justify-content-between">
                                                      <div className="row_1">
                                                            <span className="d-block text_sm text_gray ">Rooms</span>
                                                            <p className="m-0 poppins-500 dark_blue fs-6">{otherDetails.rooms}</p>
                                                      </div>
                                                      <div className="row_1">
                                                            <span className="d-block text_sm text_gray ">Number of Beds</span>
                                                            <p className="m-0 poppins-500 dark_blue fs-6">{otherDetails.numberOfBeds}</p>
                                                      </div>
                                                      <div className="row_1">
                                                            <span className="d-block text_sm text_gray ">Square Footage</span>
                                                            <p className="m-0 poppins-500 dark_blue fs-6">50,000</p>
                                                      </div>
                                                </div>
                                                <div className="pat_group border-bottom py-3 d-flex justify-content-between">
                                                      <div className="row_1">
                                                            <span className="d-block text_sm text_gray ">Doctors</span>
                                                            <p className="m-0 poppins-500 dark_blue fs-6">{otherDetails.doctors}</p>
                                                      </div>
                                                      <div className="row_1">
                                                            <span className="d-block text_sm text_gray ">Nurses</span>
                                                            <p className="m-0 poppins-500 dark_blue fs-6">{otherDetails.nurses}</p>
                                                      </div>
                                                      <div className="row_1">
                                                            <span className="d-block text_sm text_gray ">Other Staff</span>
                                                            <p className="m-0 poppins-500 dark_blue fs-6">{otherDetails.staffs}</p>
                                                      </div>
                                                </div>
                                                {/* Specialized */}
                                                <div className="pat_group  border-bottom py-3 d-flex justify-content-between">
                                                      <div className="row_1">
                                                            <span className="d-block text_sm text_gray ">Specialized Departments or Units</span>
                                                            <p className="m-0 poppins-500 dark_blue fs-6">Specialized Departments or Units</p>
                                                            <p className="m-0 poppins-500 dark_blue fs-6">Emergency Room</p>
                                                      </div>
                                                </div>
                                                {/* Facilities and Amenities */}
                                                <div className="pat_group py-3 d-flex justify-content-between">
                                                      <div className="row_1">
                                                            <span className="d-block text_sm text_gray ">Facilities and Amenities</span>
                                                            {otherDetails.facilities?.map((item: any) => <p className="m-0 poppins-500 dark_blue fs-6">{item.label}</p>)}
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                                    {/* Medical Services: */}
                                    <div className="col-xl-4 p-1 pb-2">
                                          <div className="inner_location float-start w-100 bg-white box_shadow p-4 pt-4 radius_15">
                                                <h4 className="textgreen poppins-600 fs-5"> Medical Services: </h4>
                                                {/* Medical Services */}
                                                <div className="pat_group  border-bottom py-3 d-flex justify-content-between">
                                                      <div className="row_1">
                                                            <span className="d-block text_sm text_gray pb-1"> List of Medical Services Offered </span>
                                                            {otherDetails.medicalServices?.map((item: any) => <p className="m-0 poppins-500 dark_blue fs-6">{item.label}</p>)}
                                                            {/* <p className="m-0 poppins-500 dark_blue fs-6">Obstetrics and Gynecology</p>
                                                            <p className="m-0 poppins-500 dark_blue fs-6">Orthopedics</p>
                                                            <p className="m-0 poppins-500 dark_blue fs-6">Infectious Disease</p>
                                                            <p className="m-0 poppins-500 dark_blue fs-6">Cardiology</p>
                                                            <p className="m-0 poppins-500 dark_blue fs-6">Gastroenterology</p> */}
                                                      </div>
                                                </div>
                                                {/* Specialized Procedures Available */}
                                                <div className="pat_group py-3 d-flex justify-content-between">
                                                      <div className="row_1">
                                                            <span className="d-block text_sm text_gray pb-1">Specialized Procedures Available </span>
                                                            {otherDetails.specializedProcedures?.map((item: any) => <p className="m-0 poppins-500 dark_blue fs-6">{item.label}</p>)}
                                                            {/* <p className="m-0 poppins-500 dark_blue fs-6">Coronary Bypass Surgery</p>
                                                            <p className="m-0 poppins-500 dark_blue fs-6">Endoscopy</p>
                                                            <p className="m-0 poppins-500 dark_blue fs-6">Radiation Therapy</p> */}
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                                    {/* Technology and Equipment: */}
                                    <div className="col-xl-4 p-1 pb-2">
                                          <div className="inner_location float-start w-100 bg-white box_shadow p-4 pt-4 radius_15">
                                                <h4 className="textgreen poppins-600 fs-5"> Technology and Equipment: </h4>
                                                {/* Medical Services */}
                                                <div className="pat_group  border-bottom py-3 d-flex justify-content-between">
                                                      <div className="row_1">
                                                            <span className="d-block text_sm text_gray pb-1"> Medical Equipment Available </span>
                                                            {otherDetails.medicalEquipment?.map((item: any) => <p className="m-0 poppins-500 dark_blue fs-6">{item.label}</p>)}
                                                            {/* <p className="m-0 poppins-500 dark_blue fs-6">{otherDetails.medicalEquipment}</p> */}
                                                            {/* <p className="m-0 poppins-500 dark_blue fs-6">Linear Accelerator (LINAC)</p>
                                                            <p className="m-0 poppins-500 dark_blue fs-6">Robotic Surgical Systems</p>
                                                            <p className="m-0 poppins-500 dark_blue fs-6">Ultrasound Machine</p>
                                                            <p className="m-0 poppins-500 dark_blue fs-6">Electrocardiogram (ECG or EKG)</p>
                                                            <p className="m-0 poppins-500 dark_blue fs-6">Ventilators</p> */}
                                                      </div>
                                                </div>
                                                {/* Information Technology Infrastructure */}
                                                <div className="pat_group py-3 d-flex justify-content-between">
                                                      <div className="row_1">
                                                            <span className="d-block text_sm text_gray pb-1">Information Technology Infrastructure </span>
                                                            {otherDetails.infrastructure?.map((item: any) => <p className="m-0 poppins-500 dark_blue fs-6">{item.label}</p>)}
                                                            {/* <p className="m-0 poppins-500 dark_blue fs-6">{otherDetails.infrastructure}</p> */}
                                                            {/* <p className="m-0 poppins-500 dark_blue fs-6">Health Information Exchange (HIE)</p>
                                                            <p className="m-0 poppins-500 dark_blue fs-6">Telemedicine </p>
                                                            <p className="m-0 poppins-500 dark_blue fs-6">Laboratory Information (LIS)</p> */}
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </Col>
                  <DeleteModal
                        show={showDeleteModal}
                        handleYes={() => deleteOneLocation(location.state?.id)}
                        handleClose={() => setShowDeleteModal(false)}
                        body="Do you really want to delete this loaction?"
                        head="Delete Location"
                  />
            </Row>
                        :
                        <div className='m-5 justify-content-center d-flex'>
                              <Spinner />
                        </div>
                  }
            </>
      );
};

export default LocationDetail;