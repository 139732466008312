import { useState } from "react";
export default function SearchInput(props: any) {
    const [search, setSearch] = useState("");

    function onSearchChange(e: any) {
        setSearch(e.target.value);
        const values = props.data.filter((items: any) => Object.values(items).join(" ").toLowerCase().match(e.target.value.toLowerCase()));
        props.setData(values);
    }

    return (
        props.visible &&
        <span>
            <input
                type="text"
                className="form-control"
                id={props.pos ? "input" : "search_input"}
                onChange={(e) => onSearchChange(e)}
                value={search}
                placeholder="Search..."
            />
        </span>
    )
}