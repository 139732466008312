

import user from "../../../assets/images/message/user1.jpg";
import {
      Row,
      Col,
} from "reactstrap";
import constants from "../../../config/constants";
import { RoleContext } from "../../../utils/contexts/RoleContext";
import { useContext, useEffect } from "react";
import { capitalizeFirstLetter } from "../../../utils/utils";

const MessagesSecure = () => {
      const currentUser = useContext(RoleContext);
      useEffect(() => {
            document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Secure Messages`;
      }, [currentUser]);
      return (
            <Row>
                  <div className="panel_top_section border-0 px-4 position-relative">
                        <h1>Messages</h1><span className="textgreen poppins-500"> <i className="bi bi-chevron-right"></i> Secure Messages </span>
                  </div>
                  <Col className="mt-3 mb-4">
                        <div className="message_box col-sm-12 py-4 pt-0 px-lg-2 ">
                              <div className="row m-0">
                                    <div className="col-xl-4 p-1 pb-2">

                                          <div className="inner_location float-start w-100 bg-white box_shadow p-2 pt-4 radius_15">
                                                {/* search */}
                                                <div className="d-flex align-items-center gap-2 px-2 mb-3">

                                                      <div className="input-group bg_gray_500 rounded">
                                                            <span className="input-group-text bg-transparent border-0" id="inputGroupPrepend"><i className="bi bi-search"></i></span>
                                                            <input type="text" className="form-control bg-transparent border-0 shadow-none pl-0" placeholder="Search message..." id="search" />
                                                            <div className="invalid-feedback">
                                                                  Please choose a username.
                                                            </div>
                                                      </div>
                                                      <div className="add_button d-flex">
                                                            <button className="border-0 fs-5 py-1 px-3 rounded-2 bg_green text-white" type="button"><i className="bi bi-plus-lg"></i></button>
                                                            <button type="button" className="dark_btn text-white py-2 rounded-2 mx-1 px-2"> <span className="px-2 pl-0"><i className="bi bi-trash"></i></span></button>
                                                      </div>
                                                </div>
                                                {/* user */}
                                                <div className="left_user custom_scroll">
                                                      <div className="user_chat float-start w-100 p-2 py-0 px-3 rounded">
                                                            <div className="float-start border-bottom w-100 py-3 chat_group">
                                                                  <div className="user_chat_img chat_detail_img float-start">
                                                                        <span className="text_15 text-center textgreen"><img className="rounded" src={user} alt="user" /></span>
                                                                  </div>
                                                                  <div className="user_chat_detail px-2 float-start pt-1">
                                                                        <span className="d-block text_sm dark_blue pb-1">Lucy Black</span>
                                                                  </div>
                                                                  <div className="float-end chat_count">
                                                                        <span className="chat_time d-block text-end text_gray text_sm">Mar, 5</span>
                                                                  </div>
                                                                  <div className="descriptions float-start w-100 mt-2">
                                                                        <div className="form-check">
                                                                              <input className="form-check-input shadow-none" type="checkbox" value="" id="value_0" />
                                                                              <label className="form-check-label poppins-600 darkblue text_sm" htmlFor="value_0">
                                                                                    Follow-Up Appointment Request
                                                                              </label>
                                                                              <span className="text_gray text_sm d-block">I hope this message finds you well. I am writing to request a new...</span>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                      <div className="user_chat float-start w-100 p-2 py-0 px-3 rounded">
                                                            <div className="float-start border-bottom w-100 py-3 chat_group">
                                                                  <div className="user_chat_img chat_detail_img float-start">
                                                                        <span className="text_15 text-center  textgreen"><img className="rounded" src={user} alt="user" /></span>
                                                                  </div>
                                                                  <div className="user_chat_detail px-2 float-start pt-1">
                                                                        <span className="d-block text_sm dark_blue pb-1">Mia Brown</span>
                                                                  </div>
                                                                  <div className="float-end chat_count">
                                                                        <span className="chat_time d-block text-end text_gray text_sm">Mar, 4</span>
                                                                  </div>
                                                                  <div className="descriptions float-start w-100 mt-2">
                                                                        <div className="form-check">
                                                                              <input className="form-check-input shadow-none" type="checkbox" value="" id="value_1" />
                                                                              <label className="form-check-label poppins-600 darkblue text_sm" htmlFor="value_1">
                                                                                    Follow-Up Appointment Request
                                                                              </label>
                                                                              <span className="text_gray text_sm d-block">I'd like to schedule a follow-up appointment to review my treatment...</span>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                      <div className="user_chat float-start w-100 p-2 py-0 px-3 rounded">
                                                            <div className="float-start border-bottom w-100 py-3 chat_group">
                                                                  <div className="user_chat_img chat_detail_img float-start">
                                                                        <span className="text_15 text-center  textgreen"><img className="rounded" src={user} alt="user" /></span>
                                                                  </div>
                                                                  <div className="user_chat_detail px-2 float-start pt-1">
                                                                        <span className="d-block text_sm dark_blue pb-1">Sam Lee</span>
                                                                  </div>
                                                                  <div className="float-end chat_count">
                                                                        <span className="chat_time d-block text-end text_gray text_sm">Mar, 4</span>
                                                                  </div>
                                                                  <div className="descriptions float-start w-100 mt-2">
                                                                        <div className="form-check">
                                                                              <input className="form-check-input shadow-none" type="checkbox" value="" id="value_2" />
                                                                              <label className="form-check-label poppins-600 darkblue text_sm" htmlFor="value_2">
                                                                                    Medication Inquiry
                                                                              </label>
                                                                              <span className="text_gray text_sm d-block">I have a question about my medication dosage. Could you please advise...</span>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                      <div className="user_chat float-start w-100 p-2 py-0 px-3 rounded">
                                                            <div className="float-start border-bottom w-100 py-3 chat_group">
                                                                  <div className="user_chat_img chat_detail_img float-start">
                                                                        <span className="text_15 text-center  textgreen"><img className="rounded" src={user} alt="user" /></span>
                                                                  </div>
                                                                  <div className="user_chat_detail px-2 float-start pt-1">
                                                                        <span className="d-block text_sm dark_blue pb-1">Ben Taylor</span>
                                                                  </div>
                                                                  <div className="float-end chat_count">
                                                                        <span className="chat_time d-block text-end text_gray text_sm">Mar, 4</span>
                                                                  </div>
                                                                  <div className="descriptions float-start w-100 mt-2">
                                                                        <div className="form-check">
                                                                              <input className="form-check-input shadow-none" type="checkbox" value="" id="value_3" />
                                                                              <label className="form-check-label poppins-600 darkblue text_sm" htmlFor="value_3">
                                                                                    Quick Check-In
                                                                              </label>
                                                                              <span className="text_gray text_sm d-block">Just wanted to update you on how I've been feeling since our last...</span>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                      <div className="user_chat float-start w-100 p-2 py-0 px-3 rounded">
                                                            <div className="float-start border-bottom w-100 py-3 chat_group">
                                                                  <div className="user_chat_img chat_detail_img float-start">
                                                                        <span className="text_15 text-center  textgreen"><img className="rounded" src={user} alt="user" /></span>
                                                                  </div>
                                                                  <div className="user_chat_detail px-2 float-start pt-1">
                                                                        <span className="d-block text_sm dark_blue pb-1">Lily Davis</span>
                                                                  </div>
                                                                  <div className="float-end chat_count">
                                                                        <span className="chat_time d-block text-end text_gray text_sm">Mar, 3</span>
                                                                  </div>
                                                                  <div className="descriptions float-start w-100 mt-2">
                                                                        <div className="form-check">
                                                                              <input className="form-check-input shadow-none" type="checkbox" value="" id="value_4" />
                                                                              <label className="form-check-label poppins-600 darkblue text_sm" htmlFor="value_4">
                                                                                    Appointment Rescheduling
                                                                              </label>
                                                                              <span className="text_gray text_sm d-block">I need to reschedule my upcoming appointment due to a scheduling conflict....</span>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                      <div className="user_chat float-start w-100 p-2 py-0 px-3 rounded">
                                                            <div className="float-start border-bottom w-100 py-3 chat_group">
                                                                  <div className="user_chat_img chat_detail_img float-start">
                                                                        <span className="text_15 text-center  textgreen">ZC</span>
                                                                  </div>
                                                                  <div className="user_chat_detail px-2 float-start pt-1">
                                                                        <span className="d-block text_sm dark_blue pb-1">Zoe Clark</span>
                                                                  </div>
                                                                  <div className="float-end chat_count">
                                                                        <span className="chat_time d-block text-end text_gray text_sm">Mar, 3</span>
                                                                  </div>
                                                                  <div className="descriptions float-start w-100 mt-2">
                                                                        <div className="form-check">
                                                                              <input className="form-check-input shadow-none" type="checkbox" id="value_5" value="" />
                                                                              <label
                                                                                    htmlFor="value_1"
                                                                                    className="form-check-label poppins-600 darkblue text_sm"
                                                                              // htmlFor="value_5"
                                                                              >
                                                                                    Test Results Inquiry
                                                                              </label>
                                                                              <span className="text_gray text_sm d-block">I recently had some tests done, and I'm eager to discuss the results. Can we schedule a follow-up appointment?</span>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>

                                                </div>
                                                {/* enduser */}
                                          </div>
                                    </div>
                                    <div className="row m-0 col-xl-8 p-1 px-2 pb-2 ">
                                          <div className="inner_location p-0 float-start w-100 bg-white box_shadow radius_15 px-4 pt-4 ">
                                                <div className="col-xl-12  float-start pt-xl-3 px-xl-3">
                                                      <p className="m-0 fs-5 poppins-600 darkblue px-1 pb-4">Prescription Request for Asthma Management</p>
                                                      {/* chat-top */}
                                                      <div className="chat_top float-end border-top col-12 col-xl-12 pt-4 mb-4 mt-1">
                                                            <div className="float-start  w-100 py-3 px-1 pr-0 chat_group pt-0 pb-2">
                                                                  <div className="user_chat_img float-start">
                                                                        <img className="rounded-circle" src={user} alt="user" />
                                                                  </div>
                                                                  <div className="user_chat_detail px-3 float-start pt-2">
                                                                        <span className=" text_base poppins-600 dark_blue ">Lucy Black</span><span className="d-block d-xl-inline-block px-xl-2 text_gray text_sm">lucy.black@example.com</span>
                                                                        <p className="m-0 text_gray text_sm "><i className="bi bi-clock"></i> Mar, 5 2024 - 03.12 PM</p>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                      {/* message content */}
                                                      <div className="chat_message chat_msg_content custom_scroll float-end w-100 px-2">
                                                            <p><strong>Hi, Mr. Brown</strong></p>

                                                            <p>I hope this message finds you well. I am writing to request a new prescription for my asthma medication.</p>

                                                            <p>Since our last appointment, I have noticed changes in my symptoms, including increased shortness of breath during physical activities and nighttime coughing. I believe it may be time to reassess my treatment plan and explore potential adjustments or new medication options.Could we schedule a follow-up appointment at your earliest convenience to discuss this further? I would greatly appreciate your guidance in managing my asthma effectively.</p>

                                                            <p><strong>Thank you for your attention to this request.</strong></p>

                                                            <div className="regards">
                                                                  <p>Best regards,</p>
                                                                  <p>Lucy Black.</p>
                                                            </div>

                                                            <div className="attachement_pdf float-start mt-2">
                                                                  <div className="bg_light_green_500  float-start py-3 w-100 rounded-3 px-2 textgreen text_14 my-1">
                                                                        <span className="border-0 fs-5 py-2 px-3 rounded bg_green text-white"><i className="bi bi-file-earmark-text"></i></span>
                                                                        <span className="px-3 d-inline-block"> Prescription Request.pdf</span>
                                                                        <span className="px-2 dark_blue d-inline-block"><i className="bi bi-download"></i></span>
                                                                  </div>
                                                            </div>


                                                      </div>
                                                      {/* type-message */}
                                                      <div className="type_message align-items-center mt-3 row float-start w-100 px-3 py-4 border-top m-0">
                                                            <div className="col-sm-9 px-2 type_message_input">
                                                                  Click here to <a className="textgreen text-decoration-none" href="#">Reply</a> or <a className="textgreen text-decoration-none" href="#">Forward</a> Lucy Black message.
                                                            </div>
                                                            <div className="col-sm-3 p-0 fs-4 d-flex justify-content-end other_icons text-end">
                                                                  <button className="border-0 bg-white textgreen" type="button"><i className="bi bi-paperclip"></i></button>
                                                                  <button className="border-0 bg-white textgreen" type="button"><i className="bi bi-telegram"></i></button>
                                                            </div>
                                                      </div>
                                                </div>

                                          </div>
                                    </div>
                              </div>
                        </div>
                  </Col>
            </Row>
      );
};

export default MessagesSecure;
