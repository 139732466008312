import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from "reactstrap";
import { NavigateFunction, createSearchParams, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useState } from "react";
import profile from "../../../assets/images/doctor/profile.png";

import { AgGridReact } from "ag-grid-react";
import { ColDef } from 'ag-grid-community';
import IUser from "../../../types/user.type";
import { ImagePath } from "../../../services/auth.service";
import Modal from 'react-bootstrap/Modal';
import { RoleContext, HospitalContext } from "../../../utils/contexts/RoleContext";
import award from "../../../assets/images/doctor/award.png";
import call from "../../../assets/images/doctor/call.png";
import certificate from "../../../assets/images/doctor/certificate.png";
import { deleteDoctorData } from "../../../services/doctor-service";
import { deleteoneuser } from "../../../services/user.service";
import doctor_image from "../../../assets/images/doctor/doctor-image1.jpg";
import { getallDoctor } from "../../../services/doctor-service";
import mail from "../../../assets/images/doctor/mail.png";
import SearchInput from "../../../components/SearchInput";
import Pagination from "../../../components/Pagination";
import { decidePages } from "../../../utils/utils";
import Spinner from 'react-bootstrap/Spinner';
import CustomToolTip from "../../../components/CustomToolTip";
import { useCookie } from "../../../components/useCookie";
import constants from "../../../config/constants";
import { capitalizeFirstLetter } from "../../../utils/utils";
import PhoneNumber from "../../../components/PhoneNumber";
// import {meta}

// REACT_APP_API_ADDRESS="http://localhost:5000"

// import call from "../../../assets/images/doctor"

interface IRow {
  Name: string;
  Specialty: string;
  Email: string;
  PhoneNumber: string;
}




const Doctors = () => {
  const navigate = useNavigate();
  const [rowData, setRowData] = useState([]);
  const [show, setShow] = useState(false);
  const [colDefs] = useState<ColDef[]>([
    { field: 'Name' },
    { field: 'Specialty' },
    { field: 'Email' },
    { field: 'PhoneNumber' },
    { field: 'Actions', cellRenderer: totalValueRenderer }
  ]);
  const [deleteId, setDeleteId] = useState<string>("")
  const [doctors, setDoctors] = useState([]);
  const [more, setMore] = useState();
  const [view, setView] = useState('list');
  const [pages, setPages] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [popup, showPopup] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [loading, setLoading] = useState(false);

  const [displayView, setDisplayView] = useCookie("displayView");

  const currentUser = useContext(RoleContext);
  const hospitalContext: any = useContext(HospitalContext);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getUsers();
    document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Doctors`;
  }, [currentUser, hospitalContext.selectedHospital]);

  useEffect(() => {
    setView(displayView);
  }, [displayView])


  function totalValueRenderer(props: any) {


    // setFieldId(props.data.Id)


    return (
      <>
        <span className="dost_btn"><i className="bi bi-three-dots-vertical"></i></span>
        <span className="action" >
          <button className="edit_btn"><i className="bi bi-pen"></i></button>
          <button className="delete_btn_act"><i className="bi bi-trash"></i></button>
          <button className="exclamation_btn"><i className="bi bi-exclamation-circle"></i></button>
        </span>
      </>
      // <span>

      //   <button className='btn btn-primary'
      //     // onClick={() => navigate('/edit')}
      //     onClick={() => navigate(`/${currentUser}/editdoctor`, { state: { key: props.data.Id } })

      //     }
      //   >Edit</button>
      // </span>
    );
  };


  function deleteForm(props: any, id: any) {



    return (
      <div>
        <span>
          <button className='btn btn-primary'
            onClick={() => {
              handleShow();
              setDeleteId(props.data.Id)
            }
            }
          >Delete</button>
        </span>
      </div>
    );
  };


  const handleDelete = async (id: any) => {

    deleteoneuser(id).then(
      (response) => {
        // const doctorData = response.result;
        handleClose();

        getUsers();
        // setRowData(response.result)

        // navigate("/profile");
        // window.location.reload();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        // setLoading(false);
        // setMessage(resMessage);
      }
    );
  }


  const getUsers = async (page?: any) => {
    setLoading(true);
    const pageCount = page != undefined ? page : 1;
    getallDoctor(pageCount,hospitalContext.selectedHospital?.id).then(
      (response) => {
        setLoading(false);
        const doctorData = response?.result;
        const arr: any = [];
        doctorData?.forEach((doctor: any) => {
          const obj: any = { ...doctor }
          doctor?.user_form_data?.forEach((item: any) => {
            switch (item?.formFieldKey) {
              case "specialtyDepartment":
                obj.specialty = item?.formFieldValue;
                break;
              case "medicalSchoolAttended":
                obj.medicalSchoolAttended = item?.formFieldValue;
                break;
              case "jobTitlePosition":
                obj.jobTitlePosition = item?.formFieldValue;
                break;
              default:
                break
            }
          });
          doctor?.userdata?.forEach((item: any) => {
            switch (item?.fieldKey) {
              case "address":
                obj.address = JSON.parse(item?.fieldValue);
                break;
              default:
                break
            }
          });
          arr.push(obj);
        });
        setDoctors(arr);
        setRowData(arr);
        const pages = decidePages(response?.count);
        setPages(pages);
        setPageNumber(pageCount)
        // setPagination(doctorData);
      },
      (error) => {
        console.log("error", error)

        // setLoading(false);
        // setMessage(resMessage);
      }
    );
  }

  // const setPagination = (data: any) => {
  //   let pageLength: number = Math.floor(data.length / 10) + 1;
  //   const arr = [];
  //   for (let i = 1; i < pageLength + 1; i++) {
  //     arr.push(i)
  //   }
  //   console.log("array", arr)
  //   setpages(arr);
  // }

  const handlePagination = (pageNumber: number) => {
    setPageNumber(pageNumber);
    setDoctors(rowData.slice(pageNumber * 10 - 9, pageNumber * 10 + 1))
    // setPagination(rowData);
  }

  const showForm = (id: any) => {
    handleShow()
    setDeleteId(id)
  }

  const handleClick = (roleId: any) => {
    if(currentUser === "admin") {
      navigate(`/${currentUser}/hospital`, { state: { roleId: roleId, what: "user" } });
    } else {
    navigate(`/${currentUser}/add-user/${roleId}`, { state: { roleId: roleId } });
  }
  }

  return (
        <Row>
          <div className="panel_top_section position-relative">
            <h1>Doctors</h1>
            <div className="grid_icons d-flex gap-3 align-items-center float-end">
              <button
                type="button"
                className="btn_gradian"
                onClick={() => handleClick('3')}
              >
                + Add a Doctor
              </button>
              <div className="grid_list_icons d-flex align-items-center gap-2 nav nav-pills1" id="pills-tab" role="tablist">
                <span className="list_icons fs-2 nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true"
                  onClick={() => {
                    setDisplayView("list");
                    setView("list")
                  }}
                >
                  <i className="bi bi-list-ul"></i>
                </span >
                <span className="grid_icons fs-3 nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false"
                  onClick={() => {
                    setDisplayView("grid");
                    setView("grid")
                  }}
                >
                  <i className="bi bi-grid-fill"></i>
                </span>
                <SearchInput
                  visible={showSearch}
                  data={rowData}
                  setData={(value: any) => setDoctors(value)}
                />
                <span
                  className="filter_icons fs-3"
                  onClick={() => setShowSearch(!showSearch)}
                >
                  <i className="bi bi-funnel-fill"></i>
                </span>
              </div>

            </div>
          </div>
          <Col>
            {/* --------------------------------------------------------------------------------*/}
            {/* Card-1*/}
            {/* --------------------------------------------------------------------------------*/}
            <div className="tab-content" id="pills-tabContent">
              {/* grid-tab */}
              {view === "grid" ?
                <div className="tab-pane fade show active " id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                  <div className="row d-flex doctor_grid_section">
                {doctors?.length > 0 ?
                  <>
                    {doctors.map((doctor: any, index: number) => {
                      return (
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-4 p-2 py-3" key={index + " " + doctor.firstName}>
                          <div className="docotr_innder_section bg-white text-center position-relative p-4">
                            <span className="position-absolute end-0 d-inline-block text_gray top-0 p-3 clickable"
                              onClick={() => {
                                navigate(`/${currentUser}/edit-user/${doctor?.id}`, { state: { key: doctor.id } });
                              }}
                            ><i className="bi bi-pencil-square"></i></span>
                            <div className="doctor_image mb-2">
                              {doctor?.profile != 0 ?
                                <img src={`${ImagePath}` + 'profile/' + doctor?.profile} alt="doctor_image" />
                                :
                                <img src={profile} alt="doctor_image" />

                              }
                            </div>
                            <div className="doctor_detail">
                              <h4>Dr. {doctor.firstName + " " + doctor.lastName}</h4>
                              <p>{doctor.address}</p>
                              <div className="profossional">
                                <span>{doctor.specialty}</span>
                              </div>
                              <ul>
                                <li>
                                  <span><img src={award} alt="award" /></span>
                                  <p>{doctor.medicalSchoolAttended}</p>
                                </li>
                                <li>
                                  <span><img src={certificate} alt="certificate" /></span>
                                  <p>{doctor.jobTitlePosition} </p>
                                </li>
                                <li>
                                  <span><img src={mail} alt="mail" /></span>
                                  <a href={`mailto:${doctor.email}`}>{doctor.email}</a>
                                </li>
                                <li>
                                  <span><img src={call} alt="call" /></span>
                                  <PhoneNumber phoneNumber={doctor.phoneNumber} allowCall={doctor.allowCall} />
                                </li>
                              </ul>
                              <div className="col-sm-12 card_bottom justify-content-between">
                                <button className="delete_btn m-0" type="button" onClick={() => showForm(doctor.id)} >Delete</button>
                                <button
                                  className="btn_gradian m-0"
                                  type="button"
                                  onClick={() => navigate(`/${currentUser}/doctor-detail/${doctor.id}`)}
                                >
                                  View Profile
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </>
                  :
                  <div className="text-center mt-5">
                    No data found.
                  </div>
                }
                  </div>
                  {pages.length > 1 &&
                    <div>
                      <Pagination
                        pages={pages}
                        currentPage={pageNumber}
                        onClickPageNumber={(page: any) => {
                          setPageNumber(page);
                          getUsers(page);
                          // getBookingData(page, dateFilter.fromDate, dateFilter.toDate);
                        }}
                        view={view}
                      />
                    </div>
                  }
                </div>
                :
                  <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                  <div className="row d-flex table-responsive">
                      <table>
                        <thead>
                          <tr className="list_head">
                            <th>Name</th>
                            <th>Specialty</th>
                            <th>Email</th>
                            <th>Phone number</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                        {doctors.length > 0 ?
                          <>
                          {doctors.map((doctor: any, index: number) => {
                            return (
                              <tr className="list_detail" key={index + doctor.firstName}>
                                <td className="" style={{ textAlign: "left" }}>
                                  <span>
                                    <img src={doctor?.profile == 0 ? `${profile}` : ImagePath + 'profile/' + doctor.profile} alt="doctor_image" />
                                  </span>
                                  <p>Dr. {doctor.firstName + " " + doctor.lastName}</p>
                                </td>
                                  <td>{doctor?.specialty ? doctor?.specialty : "-"}</td>
                                <td><a href={`mailto:${doctor.email}`}>{doctor.email}</a></td>
                              <td> <PhoneNumber phoneNumber={doctor.phoneNumber} allowCall={doctor.allowCall} /></td>
                                <td>
                                  {more !== doctor.id ?
                                    <span className="dost_btn" onClick={() => setMore(doctor.id)}>
                                      <i className="bi bi-three-dots-vertical"></i>
                                    </span>
                                    :
                                    <span className="action" >
                                    {(currentUser == "hospital" || currentUser == "admin")&&
                                        <>
                                          <CustomToolTip title="edit">
                                            <button
                                              data-bs-toggle="edit"
                                              className="edit_btn"
                                              onClick={() => {
                                              navigate(`/${currentUser}/edit-user/${doctor?.id}`, { state: { key: doctor.id } });
                                              }}
                                            >
                                              <i className="bi bi-pen" />
                                            </button>
                                          </CustomToolTip>
                                          <CustomToolTip title="delete">
                                            <button
                                              className="delete_btn_act"
                                              onClick={() => showForm(doctor.id)}>
                                              <i className="bi bi-trash" />
                                            </button>
                                          </CustomToolTip>
                                        </>
                                      }
                                      <button className="exclamation_btn position-relative" ><i className="bi bi-exclamation-circle " onClick={() => {
                                        showPopup(!popup)
                                      }}></i>
                                        {/* {popup===true && */}
                                        <ul className="drop_list m-0 p-0 bg-white p-2  text-left">
                                          {/* <li><a href="#">Edit</a></li><li><a href="#">Delete</a></li> */}
                                          <li><a>{doctor.firstName}</a></li>

                                          <li><a href="mailto:drethanbrown@mail.com">{doctor.email}</a></li>
                                        <li> <PhoneNumber phoneNumber={doctor.phoneNumber} allowCall={doctor.allowCall} /></li>
                                        <li>{doctor.role?.roleName}</li>
                                        </ul>
                                        {/* // } */}
                                      </button>
                                      <CustomToolTip title="appointment">
                                        <button
                                          type="button"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="bottom"
                                          className="edit_btn"
                                          onClick={() => {
                                            navigate(`/${currentUser}/appointment`, { state: { doctorId: doctor.id } });
                                          }}
                                        >
                                          <i className="bi bi-calendar"></i>
                                        </button>
                                      </CustomToolTip>
                                    </span>
                                  }
                                </td>
                              </tr>
                            )
                          })}
                          </>
                          :
                          <tr className="list_detail">
                        <td colSpan={10}>
                          {!loading ? "No data found" : <Spinner />}
                        </td>
                          </tr>
                        }
                        </tbody>
                        {pages.length > 1 &&
                          <Pagination
                            pages={pages}
                            currentPage={pageNumber}
                            onClickPageNumber={(page: any) => {
                              setPageNumber(page);
                              getUsers(page);
                              // getBookingData(page, dateFilter.fromDate, dateFilter.toDate);
                            }}
                          />
                        }
                      </table>
                    </div>
                  </div>
              }
            </div>
          </Col>
          <Modal show={show} onHide={() => handleClose()}>
            <Modal.Header closeButton>
              <Modal.Title>Delete Doctor</Modal.Title>
            </Modal.Header>
            <Modal.Body>Do you want delete this doctor ?</Modal.Body>
            <Modal.Footer>

              <button className="bg_gray_500 rounded border-0 py-2 px-4" onClick={() => handleClose()}>
                Cancel
              </button>
              <button className="bg_gradiant text-white rounded border-0 py-2 px-4" onClick={() => handleDelete(deleteId)}>
                Delete
              </button>
            </Modal.Footer>
          </Modal>
        </Row>
  );
};

export default Doctors;