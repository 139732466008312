import {
    Row,
    Col,
} from "reactstrap";

import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getOneDoctor, } from "../../../services/doctor-service";
import { addMedication, createprescription, getonprescription } from "../../../services/prescription-service";
import { RoleContext, AlertContext } from "../../../utils/contexts/RoleContext";
import { isArrayTypeNode } from "typescript";
import { getallpatient } from "../../../services/patient-service";
import { createOneRefillrequest, getOneRefill, deleteRefill, sendRefill } from "../../../services/refillRequest-service";
import { createPharmacyDetails } from "../../../services/refillRequest-service";
import constants from "../../../config/constants";
import { capitalizeFirstLetter, longDateGenerator } from "../../../utils/utils";
import { error } from "console";

const NewMedication = () => {
    const location = useLocation();
    // const [medicationDetail, setMedicationDetail] = useState<any>([]);
    const [patients, setPatients] = useState<any>({})
    const [dob, setDob] = useState<any>("");
    const [disease, setDisease] = useState<any>("");
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [medicationDetail, setMedicationDetail] = useState<any>({
        medication: {
            value: "",
            error: ""
        },
        strength: {
            value: "",
            error: ""
        },
        frequency: {
            value: "",
            error: ""
        },
        reason: {
            value: "",
            error: ""
        }
    });

    const navigate = useNavigate();
    const currentUser = useContext(RoleContext);
    const toast: any = useContext(AlertContext);

    useEffect(() => {
        document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Refill Detail`;
    }, [currentUser]);


    function handleChange(e: any) {
        const { name, value } = e.target;
        setMedicationDetail((prevState: any) => {
            let obj = { ...prevState[name], value }
            if (prevState[name].error !== "") {
                obj = { ...obj, error: "" }
            }
            return { ...prevState, [name]: obj }
        })
    }

    function validate() {
        let validationSuccess = true;
        for (let key in medicationDetail) {
            if (medicationDetail[key].value === "") {
                setMedicationDetail((prevState: any) => ({ ...prevState, [key]: { ...prevState[key], error: "This field is required!" } }));
                validationSuccess = false
            }
        }
        return validationSuccess;
    }

    function handleSubmit() {
        if (validate()) {
            const obj: any = {};
            for (let key in medicationDetail) {
                obj[key] = medicationDetail[key].value
            }
            addOneMedication(obj);
        }
    }

    async function addOneMedication(obj: any) {
        if(location?.state?.prescriptionId) {
            const response = await addMedication(location?.state?.prescriptionId, obj);
            if(response?.success) {
                toast.setShowToast(response?.message, "success");
                setTimeout(() => {
                    navigate(`/${currentUser}/addrefill-medication`, { state: { key: location?.state?.prescriptionId, patientId: location?.state?.patientId, reason: obj.reason } })
                }, 1000)
            } else {
                toast.setShowToast("Something went wrong. Please try again later!", "danger");
            }
        }
    }

    return (
        <Row>
            <div className="panel_top_section">
                <h1>Refill Requests </h1> <span className="textgreen poppins-500"><i className="bi bi-chevron-right"></i>Request a Refill </span>
            </div>
            <Col className="mt-3 mb-4">
                <div className="add_location col-sm-12 py-4 px-lg-2 mt-2">
                    <div className="row m-0 justify-content-center">
                        <div className="col-xl-6 p-1 pb-2">
                            <div className="inner_location float-start w-100 bg-white box_shadow p-4 radius_15">
                                {/* Appointment Details */}
                                <div className="float-start w-100 pb-4 px-xl-3">
                                    <h4 className="px-1 textgreen poppins-600 fs-5">Prescription Details</h4>
                                    <div className="py-2 row">
                                        <div className="col-xl-6 px-1">
                                            <span className="d-block text_sm">Medication Name</span>
                                            <input
                                                className="form-control mt-1"
                                                value={medicationDetail?.medication?.value}
                                                onChange={handleChange}
                                                type="text"
                                                name="medication"
                                            />
                                            {medicationDetail?.medication?.error &&
                                                <p className="text-danger">{medicationDetail?.medication?.error}</p>
                                            }
                                        </div>
                                        <div className="col-xl-6 px-1 mt-xl-0 mt-2">
                                            <span className="d-block text_sm">Strength</span>
                                            <input
                                                className="form-control mt-1"
                                                value={medicationDetail?.strength?.value}
                                                onChange={handleChange}
                                                type="text"
                                                name="strength"
                                            />
                                            {medicationDetail?.strength?.error &&
                                                <p className="text-danger">{medicationDetail?.strength?.error}</p>
                                            }
                                        </div>
                                        <div className="col-xl-6 px-1 mt-2">
                                            <span className="d-block text_sm">Frequency</span>
                                            <input
                                                className="form-control mt-1"
                                                value={medicationDetail?.frequency?.value}
                                                onChange={handleChange}
                                                type="text"
                                                name="frequency"
                                            />
                                            {medicationDetail?.frequency?.error &&
                                                <p className="text-danger">{medicationDetail?.frequency?.error}</p>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="float-start w-100 pb-4 px-xl-3">
                                    <h4 className="px-1 textgreen poppins-600 fs-5 mb-3">Refill Request Details</h4>
                                    <div className="px-1 mt-2">
                                        <span className="d-block text_sm">Reason for Refill</span>
                                        <input
                                            className="form-control mt-1"
                                            value={medicationDetail?.reason?.value}
                                            onChange={handleChange}
                                            type="text"
                                            name="reason"
                                            style={{ height: "60px" }}
                                        />
                                        {medicationDetail?.reason?.error &&
                                            <p className="text-danger">{medicationDetail?.reason?.error}</p>
                                        }
                                    </div>
                                    <div className="start_telehealth float-start text-center w-100 mt-5">
                                        <button
                                            className="bg_gradiant text-white rounded-3 poppins-500 py-2 w-100 fs-4 text-uppercase border-0 mb-2"
                                            type="button"
                                            onClick={handleSubmit}
                                        >
                                            Add Medication
                                        </button>
                                        <button
                                            type="button"
                                            className="bg-transparent border-0 text_gray cancel_btn"
                                            onClick={() => navigate(-1)}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default NewMedication;