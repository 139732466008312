import axios from "axios";
import { END_POINT } from "../config/constants";

// const API_URL = "http://localhost:5000/api/email/";
const API_URL = `${END_POINT}email/`
// const APIS_URL = "http://localhost:5000/api/patient/";

export const register = (subjecttitle: string,keyword: string, content: string) => {
  return axios.post(API_URL + "email_template_form", {
    subjecttitle,
    keyword,
    content,
  });
};

export const getAllEmailTemplate = () => {
    return axios
      .get(API_URL + "all_email_template", {
     
      })
      .then((response) => {
      
  
        return response?.data;
      });
  };

  export const getoneuser = (key:string) => {
    return axios
      .get(API_URL + "getOneUser/"+key, {
      
      })
      .then((response) => {
     
  
        return response?.data;
      });
  };
  
  export const updateprofile = (key:string,values:object) => {
    return axios
      .put(API_URL + "updateprofile/"+key, {
      values,
      
      })
      .then((response) => {
     
  
        return response?.data;
      });
  };

// export const login = (email: string, password: string) => {
//   return axios
//     .post(API_URL + "login", {
//       email,
//       password,
//     })
//     .then((response) => {
//       if (response?.data.accessToken) {
//         localStorage.setItem("user", JSON.stringify(response?.data));
//       }

//       return response?.data;
//     });
// };


// export const logout = () => {
//   localStorage.removeItem("user");
// };

// export const getCurrentUser = () => {
//   const userStr = localStorage.getItem("user");
//   if (userStr) return JSON.parse(userStr);

//   return null;
// };
