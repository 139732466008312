import * as Yup from "yup";

import { ErrorMessage, Field, Form, Formik } from "formik";
import { NavigateFunction, useNavigate } from 'react-router-dom';
import React, { useContext, useState, useEffect } from "react";

import { RoleContext } from "../../utils/contexts/RoleContext";
import { forgotpassword } from "../../services/auth.service";
import logo from "../../images/signup/logo-image.webp";
import shield from "../../images/signup/signup-shieldimage.webp";
import shield2 from "../../images/signup/signup-shieldimage2.webp";
import constants from "../../config/constants";
import { capitalizeFirstLetter } from "../../utils/utils";
import Toast from 'react-bootstrap/Toast';
import { Spinner } from "react-bootstrap";


type Props = {}

const ForgotPassword: React.FC<Props> = () => {
  let navigate: NavigateFunction = useNavigate();
  const currentUser = useContext(RoleContext);


  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [email, setEmail] = useState("")
  const [err, setErr] = useState("");
  const [show, setShow] = useState(false);
  const [successValue, setSuccessValue] = useState("");

  useEffect(() => {
    document.title = `${constants.metaTitle} - Forgot Password`;
  }, [currentUser])

  const initialValues: {
    email: string;

  } = {
    email: "",

  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("This field is required!"),

  });

  function setShowToast(message: string, value: string) {
    setSuccessValue(value)
    setShow(true);
    setMessage(message);
    setTimeout(() => setShow(false), 5000)
  }

  const handleLogin = (e: any) => {
    e.preventDefault();
    setMessage("");
    if (email === "") {
      setErr("please enter email")
    }
    else {
      setLoading(true);
      forgotpassword(email).then(
        (data) => {
          setLoading(false);
          if (data.message) {
            setShowToast(data.message, "danger");
            setErr(data.message)
          }
          else {
            setShowToast("Please click the link that has been sent to your Email to reset the password.", "success");
            setEmail("");
            const url = window.location.pathname;
            const cmsUrl = url?.split("/")[1];
            setTimeout(() => {
              navigate(`/${cmsUrl}/login`)
            }, 3000)
          }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          setMessage(resMessage);
        }
      );
    }
  };

  return (
    <div className="signuppage_wrapper">
      <div className="container">
        <div className="col-sm-12 signuppage_logo">
          <a href="#"><img src={logo} alt="logo-image" /></a>
        </div>
        <div className="col-sm-12 signuppage_section">
          <div className="col-sm-5 signuppage_left_part">
            <h1>Health Insights at Your Fingertips!</h1>
            <div className="signuppage_left_content">
              <div className="signuppage_left_content_image">
                <img src={shield} alt="shield-image" />
              </div>
              <div className="signuppage_left_box">
                <h4>Fast & Efficient</h4>
                <p>Revolutionize your healthcare experience with our Fast & Efficient dashboard – where speed meets precision and every click counts!</p>
              </div>
            </div>
            <div className="signuppage_left_content">
              <div className="signuppage_left_content_image">
                <img src={shield2} alt="shield-image" />
              </div>
              <div className="signuppage_left_box">
                <h4>Real-Time Data Access</h4>
                <p>Stay ahead of the curve, make informed choices, and transform healthcare delivery with up-to-the-minute insights at your fingertips!</p>
              </div>
            </div>
          </div>
          <div className="col-sm-5 signuppage_right_part">
            <h2>Forgot Password?</h2>
            <p>Enter your registered email below to receive password reset instruction.</p>
            <form onSubmit={handleLogin}>
              <div className="col-sm-12 form-group email mt-3">
                <span>E-mail adress</span>
                <span style={{ color: "red" }}>*</span>
                <input type="email" className="form-control mt-2" name="phone" placeholder="E-mail adress" value={email} onChange={(e) => setEmail(e.target.value)} />
                {
                  err ?
                    <div >
                      <p style={{ color: "red" }}>{err}</p>

                    </div> : ""
                }
              </div>
              <div className="col-sm-12 signup_btn">
                <button type="button" id="form_cancel" onClick={() => navigate(`/${currentUser}/login`)}>Cancel</button>
                <button type="submit" id="form_signup">
                  Submit
                  {loading &&
                    <span className="ms-2">
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </span>
                  }
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="col-sm-12 termsprivacy_section">
          <p>By login you agree to our <a href="#">Terms of Service</a> and <a href="#">Privacy Policy.</a></p>
        </div>
      </div>
      {show &&
        <Toast className={successValue === "success" ? "float-start end-0 position-absolute show bg-success z-3" : "float-start end-0 position-absolute show bg-danger z-3"} style={{ top: "0px" }}>
          <Toast.Header className={`show ${successValue === "success" ? "bg-success" : "bg-danger"}`}>
            <strong className="me-auto text-white">Success Message</strong>
          </Toast.Header>
          <div aria-live="polite" aria-atomic="true" className="d-flex justify-content-center align-items-center w-100 mb-10 text-white bg-transparent">
            <div className="fade toast show  bg-transparent" role="alert" aria-live="assertive" aria-atomic="true">
              <div className="fade toast show bg-transparent p-4 text-white">
                {message}
              </div>
            </div>
          </div>
        </Toast>
      }
    </div>
  );
};

export default ForgotPassword;
