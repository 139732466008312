import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {
  Row,
  Col,
} from "reactstrap";
import Select from 'react-select';
import { getallpatient } from '../../../services/patient-service';
import { useEffect, useState } from 'react';
import IUser from '../../../types/user.type';

const TelehealthAppointment = () => {

  const [patients, setPatients] = useState([])

  useEffect(() => {
    getPatients();
  }, []);

  async function getPatients() {
    const data = await getallpatient();
    setPatients(data.result);
  }

  return (
    <Row>
      <div className="panel_top_section">
        <h1>Telehealth </h1> <span className="textgreen poppins-500"><i className="bi bi-chevron-right"></i> Schedule a Telehealth </span>
      </div>
      <Col>
        <div className="row m-0 schedule_payment col-lg-11 mt-4 pt-2">
          <div className="col-xl-6 p-1">
            <h2 className="fs-5 poppins-600 mb-3">Schedule Details</h2>
            <div className="schedule_payment_inner bg-white radius_15 px-4 py-4">
              <div className="row_1 mt-3 px-lg-3">
                <span className="poppins-600 textgreen">Patient</span>
                <Select
                  // defaultValue={[colourOptions[2], colourOptions[3]]}
                  name="patients"
                  options={patients}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
                {/* {patients.map((patient:IUser) => {
                  return(

                  )
                })} */}
                {/* <select className="form-select select_drop_down shadow-none text_gray rounded-0 pl-0 border-0 border-bottom mb-3" aria-label="Default select example">
                  <option>Select</option>
                  <option value="1">option</option>
                  <option value="2">option</option>
                </select> */}
              </div>
              <div className="row_1 px-lg-3">
                <span className="poppins-600 textgreen">Reasons</span>
                <input type="text" placeholder="" className="form-control shadow-none rounded-0 border-0 border-bottom mb-3" id="reason" />
              </div>
              <div className="row_1 mb-4 px-lg-3">
                <span className="poppins-600 textgreen">Doctor</span>
                <select className="form-select select_drop_down shadow-none text_gray rounded-0 pl-0 border-0 border-bottom mb-3" aria-label="Default select example">
                  <option>See Available Doctors</option>
                  <option value="1">option</option>
                  <option value="2">option</option>
                </select>
              </div>
            </div>
          </div>
          {/* calendar */}
          <div className="col-xl-6 p-1 calender_block">
            <h2 className="fs-5 poppins-600 mb-3">Select a Date</h2>
            <Calendar />
          </div>
          {/* Select a Time */}
          <div className="col-xl-12 p-1 mt-4">
            <h4 className="fs-5 poppins-600 mb-3">Select a Time</h4>
            <div className="date_btn bg-white radius_15 px-4 py-4">
              <button type="button">08.00 AM</button>
              <button type="button">08.00 AM</button>
              <button type="button">08.00 AM</button>
              <button className="active" type="button">09.30 AM</button>
              <button type="button">10.30 AM</button>
              <button type="button">10.30 AM</button>
              <button type="button">11.00 AM</button>
            </div>
          </div>
          <div className="row col-xl-12 align-items-center p-1 m-0 mt-2 ">
            <p className="col-lg-6 fs-5 p-0 d-inline-block m-0">You selected March 8th Thursday at 08.30 AM.</p>
            <div className="form_bottom_btn col-lg-6 p-0 d-flex justify-content-end pt-2">
              <button className="delete_btn" type="button">Cancel</button>
              <button className="btn_gradian" type="button">SCHEDULE A TELEHEALTH</button>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default TelehealthAppointment;
