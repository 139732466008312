import {
  Row,
  Col
} from "reactstrap";
import React, { useContext, useEffect, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { RoleContext, AlertContext, HospitalContext } from "../../../utils/contexts/RoleContext";
import { getAllRefillRequest, deleteRefill, updateRefillStatus } from "../../../services/refillRequest-service";
import Pagination from "../../../components/Pagination";
import { decidePages } from "../../../utils/utils";
import SearchInput from '../../../components/SearchInput';
import constants from "../../../config/constants";
import { capitalizeFirstLetter } from "../../../utils/utils";
import CustomToolTip from "../../../components/CustomToolTip";
import { Spinner } from "react-bootstrap";
import DeleteModal from "../../../components/DeleteModal";

const Refill = () => {
  const navigate = useNavigate();
  const currentUser = useContext(RoleContext);
  const hospitalContext: any = useContext(HospitalContext);
  const toast: any = useContext(AlertContext)

  const [allRefillRequest, setAllRefillRequest] = useState([]);
  const [more, setMore] = useState();
  const [pages, setPages] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [showSearch, setShowSearch] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);

  useEffect(() => {
    getRefillRequest();
    document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Refill Request`;
  }, [currentUser, hospitalContext?.selectedHospital?.id])

  const getRefillRequest = (page?: any) => {
    setLoading(true);
    const pageCount = page != undefined ? page : 1;
    getAllRefillRequest(page, hospitalContext?.selectedHospital?.id).then(
      (data) => {
        setLoading(false);
        if (data?.success) {
          const refill = data?.allrefill?.rows;
          console.log("refill", refill)
          refill?.forEach((refillItem: any) => {
            let medications = "";
            const medicationArray = refillItem.refill_medications
            medicationArray.forEach((item: any, index: number) => {
              medications += `${item.medication}${index == medicationArray?.length - 1 ? "" : ", "}`
            });
            refillItem.medication = medications
          });
          // const result = groupBy(refill, 'appointmentId')
          // const array: any = []
          // for (let i in result) {
          //   let medicationValue: string = ""
          //   let strength: string = ""
          //   let frequency: string = ""
          //   const array: any = []
          //   for (let key in result) {
          //     const results = groupBy(result[key], 'appointmentId')
          //     result[key]?.map((item: any) => {
          //       if (item?.appointmentId == key) {
          //         let obj = {
          //           medicationValue: medicationValue === "" ? item?.medication : medicationValue + ',' + item?.medication,
          //           strength: strength === "" ? item?.strength : strength + ',' + item?.strength,
          //           frequency: frequency === "" ? item?.frequency : frequency + ',' + item?.frequency
          //         }
          //         array.push(obj)
          //       }
          //     })
          //   }
          // }
          const pages = decidePages(data?.allrefill?.count);
          setPages(pages);
          setPageNumber(pageCount)
          setAllRefillRequest(refill);
          setRowData(refill);
        }
      },
      (error) => {
        setLoading(false);
        console.log("error", error)
      }
    );
  }

  function groupBy(array: any, key: any) {
    return array?.reduce((result: any, currentValue: any) => {
      const group = currentValue[key];
      if (!result[group]) {
        result[group] = [];
      }
      result[group].push(currentValue);
      return result;
    }, {});
  }

  async function handleDelete() {
    const response = await deleteRefill(deleteId);
    if (response?.success) {
      getRefillRequest();
      setDeleteId(0);
      toast.setShowToast(response?.data, "success");
    } else if (response?.code == 201) {
      toast.setShowToast(response?.data, "danger");
    } else {
      toast.setShowToast("Something went wrong. Please try again later.", "danger");
    }
  }

  async function updateOneRefillstatus(id: number, status: number) {
    const response = await updateRefillStatus(id, status);
    if (response?.success) {
      toast.setShowToast(response?.message, "success");
      getRefillRequest();
    } else {
      toast.setShowToast("Something went wrong. Please try again later!", "danger");
    }
  }


  return (
    <Row>
      <div className="panel_top_section position-relative border-0">
        <h1>Refill Requests</h1>
        <div className={`${(currentUser === "doctor" || currentUser === "patient") && "grid_icons"} d-flex gap-1 align-items-center float-end`}>
          {(currentUser == "doctor" || currentUser == "patient") &&
            <button
              type="button"
              className="btn_gradian"
              onClick={() => navigate(`/${currentUser}/addrefill-request`)}
            >
              + Add New Refill Request
            </button>
          }
          <div className="grid_list_icons  d-flex align-items-center gap-2">
            <SearchInput
              visible={showSearch}
              data={rowData}
              setData={(value: any) => setAllRefillRequest(value)}
            />
            <span
              className="filter_icons fs-3 px-1"
              onClick={() => setShowSearch(!showSearch)}
            >
              <i className="bi bi-funnel-fill" />
            </span>
          </div>
        </div>
      </div>
      <Col>
        <div className="row d-flex table-responsive m-0">
          <table >
            <thead>
              <tr className="list_head_new text-start bg_green_table">
                <th className="text_base px-2 px-xl-4 py-4">Patient Name</th>
                <th className="text_base px-2 px-xl-4 py-4">Refill Requested</th>
                <th className="text_base px-2 px-xl-4 py-4">Date</th>
                <th className="text_base px-2 px-xl-4 py-4">Time </th>
                <th className="text_base px-2 px-xl-4 py-4">Status </th>
                <th className="text_base px-2 px-xl-4 py-4 text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {allRefillRequest?.length > 0 ?
                <>
                  {allRefillRequest?.map((item: any) => {
                    const date = new Date(item?.updatedAt);
                    const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
                    const formattedDate = date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
                    return (
                      <tr
                        className="list_detail_new text-start bg-white border-bottom cursor-pointer"
                        onClick={() => navigate(`/${currentUser}/refilldetail/${item.id}`)}
                      >
                        <td className="text_base px-2 px-xl-4 py-4">{item?.user?.firstName} {item?.user?.lastName}</td>
                        <td className="text_base px-2 px-xl-4 py-4 poppins-500"> <span className="col-lg-12 col-xl-8">{item.medication}</span></td>
                        <td className="text_base px-2 px-xl-4 py-4"> {formattedDate}</td>
                        <td className="text_base px-2 px-xl-4 py-4"> {formattedTime}</td>
                        <td className="text_base px-2 px-xl-4 py-4">
                          <span className={`po_completed p-2 py-1 ${(item.status == 0 || item.status == 2) ? "text-pending-back" : "bg_light_green"} rounded-5 text_sm ${(item.status == 0 || item.status == 2) ? "text-pending" : "textgreen"}`}>{item.status == 0 ? "Pending" : item.status == 11 ? "Sent To Pharmacy" : item.status == 1 ? "Confirmed" : "Rejected"}</span>
                        </td>
                        <td className="text_base px-2 px-xl-4 py-4">
                          {more !== item.id ?
                            <span className="dost_btn" onClick={(e) => {
                              e.stopPropagation();
                              setMore(item.id)
                            }}
                            >
                              <i className="bi bi-three-dots-vertical"></i>
                            </span>
                            :
                            <span className={currentUser === "doctor" ? "refill-action" : "action"} >
                              {currentUser !== "doctor" ?
                                <>
                                  {item.status == 0 &&
                                    <>
                                      <CustomToolTip title="Edit">
                                        <button
                                          className="edit_btn"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            navigate(`/${currentUser}/update-refill-medication/${item.id}`)
                                          }}
                                        >
                                          <i className="bi bi-pen" />
                                        </button>
                                      </CustomToolTip>
                                      <CustomToolTip title="Delete">
                                        <button
                                          className="delete_btn_act"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setShowDeleteModal(true);
                                            setDeleteId(item.id);
                                          }}
                                        >
                                          <i className="bi bi-trash" />
                                        </button>
                                      </CustomToolTip>
                                    </>
                                  }
                                </>
                                :
                                <>
                                  {item.status == 0 &&
                                    <>
                                      <CustomToolTip title="Accept">
                                        <button
                                          className="accept-btn"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            updateOneRefillstatus(item.id, 1);
                                          }}
                                        >
                                          <i className="bi bi-check-lg"></i>
                                        </button>
                                      </CustomToolTip>
                                      <CustomToolTip title="Reject">
                                        <button
                                          className="reject-btn"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            updateOneRefillstatus(item.id, 2);
                                          }}
                                        >
                                          <i className="bi bi-x-lg"></i>
                                        </button>
                                      </CustomToolTip>
                                    </>
                                  }
                                </>
                              }
                              <CustomToolTip title="Info">
                                <button
                                  className="exclamation_btn"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    navigate(`/${currentUser}/refilldetail/${item.id}`)
                                  }}
                                >
                                  <i className="bi bi-exclamation-circle" />
                                </button>
                              </CustomToolTip>
                            </span>
                          }
                        </td>
                      </tr>)
                  })
                  }
                </>
                :
                <tr className="list_detail">
                  {!loading ?
                    <td colSpan={10}>No data found</td>
                    :
                    <td colSpan={10}><Spinner /></td>
                  }
                </tr>
              }
            </tbody>
            {pages?.length > 1 &&
              <tfoot>
                <Pagination
                  pages={pages}
                  currentPage={pageNumber}
                  onClickPageNumber={(page: any) => {
                    setPageNumber(page);
                    getRefillRequest(page);
                  }}
                />
              </tfoot>
            }
          </table>
        </div>
      </Col>
      <DeleteModal
        show={showDeleteModal}
        handleYes={() => {
          handleDelete();
          setShowDeleteModal(false);
        }}
        handleClose={() => setShowDeleteModal(false)}
        body="Do you really want to delete this data?"
        head="Delete Refill Request"
      />
    </Row>
  );
};

export default Refill;
