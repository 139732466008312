


import location from "../../../assets/images/refil/hospital.png";
import call from "../../../assets/images/refil/phone.png";
import map from "../assets/images/refil/map.jpg";

import {
  Card,
  Row,
  Col,
  CardTitle,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import GooglePlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { getNearPharmacyAddress } from "../../../services/refillRequest-service";
import { GoogleMap, Marker, useJsApiLoader, LoadScript } from '@react-google-maps/api';
import { RoleContext, AlertContext } from "../../../utils/contexts/RoleContext";
import constants from "../../../config/constants";
import { capitalizeFirstLetter } from "../../../utils/utils";

const RefillSend = () => {
  const [place, setPlace] = useState();
  const [locationData, setLocationData] = useState<any>({
    locationName: "",
    streetAddress: "search address",
    city: "",
    state: "",
    // stateCode: "",
    country: "",
    // countryCode: "",
    // latitude: 0,
    // longitude: 0,
    pincode: "",
    phoneNumber: "",
    emailAddress: "",
    faxNumber: ""
  });
  const currentUser = useContext(RoleContext);
  const [pharDetails, setPharmacyDetails] = useState<any>([]);
  const [latlng, setLatLng] = useState<any>([]);
  const locations = useLocation();

  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBrwbVt6abHnoZjzNWYWhYhQLwL45mO2hA",
    nonce: "map",
    libraries: ["places"],
  });
  const [activeMarker, setActiveMarker] = useState(null);
  const navigate = useNavigate();
  function handlePlaceChange(e: any) {
    const { label, value } = e;
    geocodeByPlaceId(value.place_id)
      .then((results: any) => {
        getLatLng(results[0]).then((data: any) =>
          setLocationData((prevState: any) => ({ ...prevState, latitude: data.lat, longitude: data.lng }))
        );
        // console.log(results);
        const details = results[0];
        let city: string = "",
          country: string = "",
          state: string = "",
          pincode: string = "",
          administrative_area_level_1: string = "",
          administrative_area_level_1_short_code = "",
          administrative_area_level_2: string = "",
          administrative_area_level_2_short_code = "",
          locality: string = "",
          administrative_area_level_3: string = "",
          stateCode: "",
          countryCode: "";
        details.address_components.map((item: any) => {
          item.types.map((res: any) => {
            if (res == "locality") {
              locality = item.long_name
            }
            if (res == "country") {
              country = item.long_name;
              countryCode = item.short_name;
            }
            if (res == "administrative_area_level_1") {
              administrative_area_level_1 = item.long_name;
              administrative_area_level_1_short_code = item.short_name
            }
            if (res == "administrative_area_level_2") {
              administrative_area_level_2 = item.long_name;
              administrative_area_level_2_short_code = item.short_name
            }
            if (res == "administrative_area_level_3") {
              administrative_area_level_3 = item.long_name
            }
            if (res == "postal_code") {
              pincode = item.long_name
            }
          });
        });
        state = administrative_area_level_1 !== "" ? administrative_area_level_1 : administrative_area_level_2;
        city = administrative_area_level_3 !== "" ? administrative_area_level_3 : locality;
        setLocationData((prevState: any) => ({
          ...prevState,
          city,
          state,
          country,
          pincode,
          streetAddress: label,
          countryCode,
          stateCode: administrative_area_level_1_short_code !== "" ? administrative_area_level_1_short_code : administrative_area_level_2_short_code
        }));
        setPlace(e);
        getNearAddress(details.formatted_address);
        // if (pincode === "") {
        //   alert("Select the exact location");
        // } else {
        //   setLocationData((prevState: any) => ({ ...prevState, city, state, country, pincode, streetAddress: label }));
        //   setPlace(e);
        // }
      })
      .catch((error: any) => console.error(error));
  }

  useEffect(() => {
    document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Send Refill`;
  }, [currentUser])

  const getNearAddress = (e: any) => {
    // console.log("placess", place)
    getNearPharmacyAddress(e).then(
      (data) => {

        const allStoreDetails = data.result;
        console.log("storedetails", allStoreDetails);
        const array: any = []
        const latarray: any = []
        allStoreDetails?.map((item: any) => {
          let obj: any = {}
          console.log("itemsss", item.latitude)
          // console.log("itemseier", item.store)
          // setPharmacyDetails(item.)
          obj.lat = Number(item.latitude)
          obj.lng = Number(item.longitude)
          latarray.push(obj)
          array.push(item.store)
        })
        //     console.log("geuserss", allDoctor);
        setLatLng(latarray);
        setPharmacyDetails(array);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        // setLoading(false);
        // setMessage(resMessage);
      }
    );

  }

  const handleActiveMarker = (marker: any) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map: any) => {
    const bounds = new google.maps.LatLngBounds();
    latlng.forEach((position: any) => bounds.extend(position));
    map.fitBounds(bounds);
  };

  const options = {
    styles: [
      {
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#f5f5f5"
          }
        ]
      },
      {
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#616161"
          }
        ]
      },
      {
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "color": "#f5f5f5"
          }
        ]
      },
      {
        "featureType": "administrative.land_parcel",
        "elementType": "labels",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#bdbdbd"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#eeeeee"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels.text",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "featureType": "poi.business",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#e5e5e5"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#ffffff"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#dadada"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "labels",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#616161"
          }
        ]
      },
      {
        "featureType": "road.local",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "labels",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      },
      {
        "featureType": "transit",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "transit.line",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#e5e5e5"
          }
        ]
      },
      {
        "featureType": "transit.station",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#eeeeee"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#c9c9c9"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      }
    ]
  };


  const customIcon = {
    path: window?.google?.maps?.SymbolPath?.CIRCLE, // Symbol shape (circle in this case)
    fillColor: "#00929f", // Fill color (red)
    fillOpacity: 0.9, // Fill opacity (opaque)
    strokeColor: "#8cccd2", // Stroke color (white)
    strokeWeight: 2, // Stroke weight
    scale: 7, // Size of the marker
  };

  if (loadError) return <div>Error loading map</div>;
  if (!isLoaded) return <div>Loading map...</div>;

  console.log("phardetails", pharDetails, latlng)
  return (

    <Row>

      <div className="panel_top_section  px-4 position-relative">
        <h1>Refill Requests</h1><span className="textgreen poppins-500"> <i className="bi bi-chevron-right"></i> Send to a Pharmacy </span>

      </div>
      <Col className="mt-3 mb-4">
        <div className="refil_send col-sm-12 py-4 pt-0 px-lg-2 mt-4 pt-1">
          <div className="row m-0">
            <div className="col-xl-4 p-1 pb-2">

              <div className="inner_location float-start w-100 bg-white box_shadow p-2 pt-4 radius_15">
                {/* search */}
                {/* <div className="d-flex align-items-center gap-2 px-2 mb-3"> */}

                {/* <div className="input-group bg_gray_500 rounded-5 px-2"> */}
                {/* <span className="input-group-text bg-transparent border-0" id="inputGroupPrepend"><i className="bi bi-search text_gray"></i></span> */}
                {/* <input type="text" className="form-control bg-transparent border-0 shadow-none pl-0 text_gray" placeholder="Search by Address..." id="search" /> */}
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyBrwbVt6abHnoZjzNWYWhYhQLwL45mO2hA"
                  selectProps={{
                    value: place,
                    onChange: handlePlaceChange,
                    placeholder: locationData.streetAddress,
                    // styles:{{"width":50}}
                  }}
                // styles={{}}
                />
                {/* <div className="invalid-feedback">
                                                                  Please choose a username.
                                                            </div> */}
                {/* </div> */}

                {/* </div> */}
                {/* user */}
                <div className="left_refil float-start w-100 custom_scroll">
                  <div className="px-3"><h2 className="fs-5 poppins-600 dark_blue border-bottom m-0 pb-3 pt-2">Pharmacies Nearby</h2></div>
                  {/* loop-start */}
                  {pharDetails?.map((items: any) => {
                    // console.log("itemss", items)
                    return (
                      <div className="user_chat float-start w-100 p-2 py-0 px-3 rounded">
                        <div className="float-start border-bottom w-100 py-4 position-relative chat_group">


                          <div className="user_chat_detail w-100 float-start">
                            <span className="d-block text_base poppins-600 dark_blue pb-1">{items?.name ? items?.name : items?.brand}</span>
                          </div>
                          <div className="address">
                            <ul className="p-0 m-0">

                              <li className="w-100 float-start d-block list-unstyled py-1">
                                <span className="float-start"><img src={location} alt="award" /></span>
                                <div className=" right_box float-start px-2">
                                  <span className="d-block text_sm mb-1 dark_blue fw-normel">{items.address?.street} {items.address?.city} {items.address?.state}</span>

                                  {/* <span className="d-block text_sm mb-1 dark_blue fw-normel">1234 Main Street, Houston, TX 77002</span> */}
                                </div>
                              </li>
                              <li className="w-100 float-start d-block list-unstyled py-1">
                                <span className="float-start"><img src={call} alt="award" /></span>
                                <span className=" right_box float-start px-2">
                                  <a href={`tel:${items?.phone?.number}`} className="text-decoration-none text_sm mb-1 dark_blue">({items.phone?.areaCode}) {items?.phone?.number}</a>
                                </span>
                                <span
                                  className="send_btn bg_dark_blue d-block  px-3 py-1 fs-6 rounded-5 text-uppercase float-end text_sm text-white text-center"
                                  onClick={() => navigate(`/${currentUser}/refilldetail/${locations?.state?.refillId}`, { state: { key: locations?.state?.key, patientId: locations?.state?.patientId, pharmacyName: items?.name ? items?.name : items?.brand } })}
                                >
                                  send
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
                {/* enduser */}
              </div>
            </div>
            <div className="row m-0 col-xl-8 p-1 px-2 pb-2 ">
              <div className="inner_location p-0 float-start w-100 bg-white box_shadow radius_15 ">
                <div id="map">
                  {isLoaded &&
                    //    <LoadScript
                    //    googleMapsApiKey="AIzaSyBrwbVt6abHnoZjzNWYWhYhQLwL45mO2hA"
                    //  >
                    <GoogleMap
                      mapContainerStyle={{ width: '100%', height: '580px' }}
                      center={{
                        lat: latlng[0]?.lat,
                        lng: latlng[0]?.lng
                        // lat:37.0902,
                        // lng:95.7129
                      }}
                      // onLoad={handleOnLoad}
                      // onClick={() => setActiveMarker(null)}
                      zoom={13}
                      options={options}
                    // icon={customIcon}
                    // onLoad={onLoad}
                    // onUnmount={onUnmount}
                    >
                      {latlng.map((point: any, index: any) => {
                        console.log("pointss", point)
                        return (

                          <>
                            <Marker key={index} position={point} icon={customIcon} />
                          </>
                        )
                      })}
                      {/* {activeMarker === id ? (
                                                                        <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                                                                              <div>{name}</div>
                                                                        </InfoWindow>
                                                                  ) : null} */}

                    </GoogleMap>
                    // </LoadScript>
                  }
                  {/* <img className="radius_15 w-100" src={map} alt="map" /> */}
                  {/* <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d61428.417818685964!2d-47.94048907413692!3d-15.78938834782067!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sPlano%20Piloto!5e0!3m2!1spt-BR!2sbr!4v1569520090696!5m2!1spt-BR!2sbr" width="100%" height="595" className="radius_15 map"></iframe> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default RefillSend;