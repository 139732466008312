import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export default function CustomToolTip({children, title}: any) {
    const renderTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {title}
        </Tooltip>
    );

    return (
        <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
        >
            {children}
        </OverlayTrigger>
    );
}

