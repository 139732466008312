import axios from "axios";
import { END_POINT } from "../config/constants";

// const API_URL = "http://localhost:5000/api/adminsettings/";
const API_URL = `${END_POINT}adminsettings`




export const getalladmin = () => {
  return axios
    .get(API_URL, {

    })
    .then((response) => {


      return response?.data;
    });
};

export const getoneadmin = (id: string) => {
  return axios
    .get(API_URL + id, {

    })
    .then((response) => {


      return response?.data;
    });
};

export const deleteoneadmin = (id: any) => {
  return axios
    .delete(API_URL + id, {

    })
    .then((response) => {


      return response?.data;
    });
};

export const updateadminsetting = (id: string, values: any) => {
  return axios
    .put(API_URL + id, {
      values

    })
    .then((response) => {


      return response?.data;
    });
};

export const updateformfield = (id: string, dragdroptable: any) => {
  return axios
    .put(API_URL + +id, {
      dragdroptable

    })
    .then((response) => {


      return response?.data;
    });
};

export const createadminsetting = (values: any) => {
  return axios
    .post(API_URL, {
      values

    })
    .then((response) => {


      return response?.data;
    });
};

export const addSetting = (settings: any) => {
  return axios
    .post(`${API_URL}/add-setting`, {
      label: settings.settingLabel.value,
      key: settings.settingKey.value,
      value: settings.settingValue.value
    })
    .then((response) => {
      return response?.data;
    });
};

export const getAllSettings = () => {
  return axios.get(`${API_URL}/get-setting`).then((response) => {
    return response?.data;
  });
};

export const updateSetting = (data: any) => {
  return axios
    .put(`${API_URL}/update-setting`, {
      data
    })
    .then((response) => {
      return response?.data;
    });
};

export const getOneSetting = (key: string) => {
  return axios.get(`${API_URL}/get-one-setting/${key}`).then((response) => {
    return response?.data;
  });
};

export const createSettings = (settings: any) => {
  return axios
    .post(`${API_URL}/create-settings`, {
      label: settings.settingLabel.value,
      key: settings.settingKey.value,
      value: settings.settingValue.value,
      settingsFor: settings.settingFor.value
    })
    .then((response) => {
      return response?.data;
    });
};

export const getAllUserSettings = () => {
  return axios.get(`${API_URL}/get-all-user-settings`).then((response) => {
    return response?.data;
  });
};

export const updateUserSettings = (data: any) => {
  return axios
    .put(`${API_URL}/update-user-settings`, {
      data
    })
    .then((response) => {
      return response?.data;
    });
};

export const getProfileSettings = (user: any) => {
  return axios.get(`${API_URL}/get-profile-settings?user=${user}`).then((response) => {
    return response?.data;
  });
};

export const saveUserSettings = (data: any) => {
  return axios
    .post(`${API_URL}/save-user-settings`, {
      data
    })
    .then((response) => {
      return response?.data;
    });
};

export const getOneUserSettings = () => {
  return axios.get(`${API_URL}/get-one-user-settings`).then((response) => {
    return response?.data;
  });
};





