import AppointmentImage from "../../assets/images/body/appointment.webp"
import total_image from "../../assets/images/body/total-image.webp"
import { IoIosArrowForward } from "react-icons/io";
import user from "../../assets/images/message/user1.jpg";
import girl from "../../assets/images/icons/girl.jpg";
import multiple from "../../assets/images/icons/multiple.png";
import activity from "../../assets/images/icons/activity.jpg";
import user1 from "../../assets/images/users/user1.jpg";

import {
  Row,
  Col,
} from "reactstrap";
import { useState, useEffect, useContext, useRef } from "react";
import { getChatUsers } from "../../services/messages-service";
import { RoleContext, AlertContext } from "../../utils/contexts/RoleContext";
import { useNavigate } from "react-router-dom";
import constants from "../../config/constants";
import { getAppointmentsPercentage, onePatientBookingAppointment } from "../../services/scheduleappointment-service";
import { SOCKET_DOMAIN } from "../../config/constants";
import { getOnePatient } from "../../services/patient-service";
import { longDateGenerator } from "../../utils/utils";
import { createChatUser } from "../../services/messages-service";
import Chart from 'chart.js/auto'
import { getCurrentUser } from "../../services/auth.service";
import { getAllRefillRequest, updateRefillStatus } from "../../services/refillRequest-service";

const days: any = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const DoctorDashboard = () => {
  const [chatUsers, setChatUsers] = useState<any>([]);
  const [appointmentsInfo, setAppointmentsInfo] = useState<any>({});
  const [patientDetail, setPatientDetail] = useState<any>({});
  const [refillRequests, setRefillRequests] = useState([]);


  const currentUser = useContext(RoleContext);
  const navigate = useNavigate();
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstance = useRef<Chart | null>(null);
  const toast: any = useContext(AlertContext)

  useEffect(() => {
    fetchChatUsers();
    fetchAppointmentsPercentage();
    getRefillRequests();
    document.title = `${constants.metaTitle}Doctor - Dashboard`;

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
        chartInstance.current = null;
      }
    };
  }, [chartInstance]);

  async function updateOneRefillstatus(id: number, status: number) {
    const response = await updateRefillStatus(id, status);
    if (response?.success) {
      toast.setShowToast(response?.message, "success");
      getRefillRequests();
    } else {
      toast.setShowToast("Something went wrong. Please try again later!", "danger");
    }
  }

  async function getRefillRequests() {
    const response = await getAllRefillRequest();
    if (response?.success) {
      const refilRequests = response?.allrefill?.rows;
      const filteredRequests = refilRequests.filter((item: any) => item.status == 0)?.slice(0, 4);
      filteredRequests?.forEach((refillItem: any) => {
        let medications = "";
        const medicationArray = refillItem.refill_medications
        medicationArray.forEach((item: any, index: number) => {
          medications += `${item.medication}${index == medicationArray?.length - 1 ? "" : ", "}`
        });
        refillItem.medication = medications
      });
      setRefillRequests(filteredRequests);
    }
  }

  async function fetchChatUsers() {
    const response = await getChatUsers();
    if (response?.success) {
      setChatUsers(response?.data?.slice(0, 3));
    }
  }

  async function fetchAppointmentsPercentage() {
    const response = await getAppointmentsPercentage();
    if (response?.success) {
      const upcomingPatients: any = [];
      const todaysAppointments = response?.data?.appointmentsToday?.map((item: any) => {
        let splittedTime = item?.time?.split(":")
        let startTime = new Date(new Date(item?.date)?.setHours(splittedTime[0]))?.setMinutes(splittedTime[1]);
        item?.doctor?.schedule_appointments?.forEach((element: any) => {
          if (element.day == days[new Date().getDay()]) {
            const duration = Number(element?.consultation_duration);
            let endTime = startTime + duration * 60 * 1000;
            if (Date.now() > startTime && Date.now() < endTime) {
              item.appointmentTime = "OnGoing"
            } else if (Date.now() < startTime) {
              item.appointmentTime = item?.time
              upcomingPatients.push(item?.patientId)
            } else {
              item.appointmentTime = item?.time
            }
          }
        });
        return item;
      });
      if (upcomingPatients[0]) {
      getNextPatientDetail(upcomingPatients[0]);
        getLastAppointmentOfPatient(upcomingPatients[0])
      }
      setAppointmentsInfo(response?.data);
      createChart(response?.data.patients);
    }
  }

  async function getNextPatientDetail(id: any) {
    getOnePatient(id).then(
      (response) => {
        let patientData = { ...response?.result }
        patientData?.userdata?.forEach((patient: any) => {
          if (patient?.fieldKey === 'gender') {
            patientData.gender = patient?.fieldValue?.replace(/^"(.*)"$/, '$1');
          } else if (patient?.fieldKey === 'dateOfBirth') {
            patientData.dateOfBirth = patient?.fieldValue?.replace(/^"(.*)"$/, '$1');
          } else if (patient?.fieldKey === 'address') {
            patientData.address = patient?.fieldValue?.replace(/^"(.*)"$/, '$1');
          }
        });
        setPatientDetail((prevState: any) => ({ ...prevState, ...patientData }));
      },
      (error) => {
        console.log("error", error)
      }
    );
  }


  async function getLastAppointmentOfPatient(id: any) {
    onePatientBookingAppointment(id, getCurrentUser()?.id).then(
      (response) => {
        setPatientDetail((prevState: any) => ({
          ...prevState,
          lastAppointment: response?.appointmentBooking[response?.appointmentBooking?.length - 2],
          currentAppointment: response?.appointmentBooking[response?.appointmentBooking?.length - 1]
        }));
      },
      (error) => {
        console.log("error", error)
      }
    );
  }

  async function createChatUsers(id: number) {
    const response = await createChatUser(id);
    navigate(`/${currentUser}/messages`, { state: { chatRoomId: response?.data?.room } });
  }

  function createChart(patientData: any) {
    const currentYear = new Date().getFullYear();

    // Filter data to include only entries from the current year
    const filteredData = patientData?.filter((item: { createdAt: string | number | Date; }) => {
      const year = new Date(item.createdAt)?.getFullYear();
      return year === currentYear;
    });

    const aggregatedData: any = {};


    filteredData?.forEach((item: any) => {
      const month = convertDateToMonthAbbreviation(item?.createdAt);
      const count = 1;

      if (!aggregatedData[month]) {
        aggregatedData[month] = 0;
      }


      aggregatedData[month] += count;

    });

    const labels = Object.keys(aggregatedData);
    const data = labels.map((month) => aggregatedData[month]);


    if (chartInstance.current) {
      chartInstance.current?.destroy();
      chartInstance.current = null;
    }


    if (chartRef.current) {
      const myChartRef = chartRef.current?.getContext("2d");

      if (myChartRef) {
        const gradient = myChartRef?.createLinearGradient(0, 0, 0, myChartRef.canvas.height);
        gradient?.addColorStop(0, '#a8edf3');
        // gradient.addColorStop(0.5, '#a8edf3');
        gradient?.addColorStop(1, '#00929f');

        const backgroundGradient = myChartRef?.createLinearGradient(0, 0, 0, myChartRef.canvas.height);
        backgroundGradient?.addColorStop(0, '#a8edf3');
        // backgroundGradient.addColorStop(0.5, '#a8edf3');
        backgroundGradient?.addColorStop(1, '#ffffff');

        chartInstance.current = new Chart(myChartRef, {
          type: "line",
          data: {
            labels: labels,
            datasets: [
              {
                label: "Patient ",
                data: data,
                fill: true,


                // backgroundColor: 'rgba(75, 192, 192, 0.2)',
                // borderColor: 'rgb(75, 192, 192)',

                backgroundColor: backgroundGradient,
                borderColor: gradient,
                borderWidth: 2,
                tension: 0.3,
              },
            ],
          },



          options: {
            responsive: true,
            // maintainAspectRatio: false,
            plugins: {
              tooltip: {
                backgroundColor: 'rgba(75, 192, 192, 0.8)',
                // borderColor: 'rgb(0, 0, 0)', 
                borderWidth: 1,
                titleColor: 'rgb(255, 255, 255)',
                bodyColor: 'rgb(255, 255, 255)'
              },
              //   legend: {
              //     display: false
              // },
            },
            // animations: {
            //   tension: {
            //     duration: 1000,
            //     easing: 'linear', 
            //     from: 1,
            //     to: 0,
            //     loop: true
            //   }
            // },

          }
        });
      }
    }
  }

  const convertDateToMonthAbbreviation = (dateString: string | number | Date) => {
    const date = new Date(dateString);
    return date?.toLocaleString('en-US', { month: 'short' });
  };

  return (
    <Row>
      <Col className="mt-3 mb-4">
        <div className="admin_top">
          <div className="row my-2">
            <div className="col-12 col-md-6 col-xxl-3 px-2 py-2">
              <div className="billing_sec">
                <div className="billing_sec_top">
                  <span><img src={total_image} alt="logo-image" /></span>
                  <p>Total Patients</p>
                </div>
                <strong>{appointmentsInfo?.patientsDetails?.ratio}<span>{appointmentsInfo?.patientsDetails?.percentage}%</span></strong>
                <p>{appointmentsInfo?.patientsDetails?.message}</p>
                <a className="clickable" onClick={() => navigate(`/${currentUser}/patient-list`)}>See Details <IoIosArrowForward /></a>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xxl-3 px-2 py-2">
              <div className="billing_sec">
                <div className="billing_sec_top">
                  <span><img src={AppointmentImage} alt="logo-image" /></span>
                  <p>Appointments</p>
                </div>
                <strong>{appointmentsInfo?.appointmentsDetails?.ratio}<span>{appointmentsInfo?.appointmentsDetails?.percentage}%</span></strong>
                <p>{appointmentsInfo?.appointmentsDetails?.message}</p>
                <a className="clickable" onClick={() => navigate(`/${currentUser}/appointment`)}>See Details <IoIosArrowForward /></a>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xxl-3 px-2 py-2">
              <div className="col-sm-12 billing_sec two">
                <div className="col-sm-12 recentappoint_sec">
                  <p>Refill Requests</p>
                  <a onClick={() => navigate(`/${currentUser}/refill-request`)}>View All</a>
                </div>
                <div className="col-sm-12 recentappoint_wrap">
                  {refillRequests?.map((item: any) => {
                    return (
                  <div className="col-sm-12 recentappoint_doctor px-3">
                    <div className="recentappoint_name">
                          <span>{item.user?.firstName?.slice(0, 1)}</span>
                          <p>{item.user?.firstName + " " + item.user?.lastName}</p>
                          <strong>{item.medication}</strong>
                    </div>
                    <div className="float-end">
                          <button
                            className="check_btn mx-1"
                            type="button"
                            onClick={() => updateOneRefillstatus(item.id, 1)}
                          >
                            <i className="bi bi-check-lg" />
                          </button>
                          <button
                            className="close_btn"
                            type="button"
                            onClick={() => updateOneRefillstatus(item.id, 2)}
                          >
                            <i className="bi bi-x-lg" />
                          </button>
                    </div>
                  </div>
                    )
                  })}
                  {/* <div className="col-sm-12 recentappoint_doctor px-3">

                    <div className="recentappoint_name">
                      <span>mb</span>
                      <p>Mia Brown</p>
                      <strong>Renalix</strong>

                    </div>
                    <div className="float-end">
                      <button className="check_btn mx-1" type="button"><i className="bi bi-check-lg"></i></button>
                      <button className="close_btn" type="button"><i className="bi bi-x-lg"></i></button>
                    </div>
                  </div>
                  <div className="col-sm-12 recentappoint_doctor px-3">

                    <div className="recentappoint_name">
                      <span>sl</span>
                      <p>Sam Lee</p>
                      <strong>Osteoflex</strong>

                    </div>
                    <div className="float-end">
                      <button className="check_btn mx-1" type="button"><i className="bi bi-check-lg"></i></button>
                      <button className="close_btn" type="button"><i className="bi bi-x-lg"></i></button>
                    </div>
                  </div>
                  <div className="col-sm-12 recentappoint_doctor px-3">

                    <div className="recentappoint_name">
                      <span>sl</span>
                      <p>Ben Taylor</p>
                      <strong>Immunovex</strong>

                    </div>
                    <div className="float-end">
                      <button className="check_btn mx-1" type="button"><i className="bi bi-check-lg"></i></button>
                      <button className="close_btn" type="button"><i className="bi bi-x-lg"></i></button>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xxl-3 px-2 py-2">
              <div className="col-sm-12 billing_sec two">
                <div className="col-sm-12 recentappoint_sec">
                  <p>Messages</p>
                  <a
                    className="clickable"
                    onClick={() => navigate(`/${currentUser}/messages`)}
                  >
                    View All
                  </a>
                </div>
                <div className="col-sm-12 recentappoint_wrap">
                  {chatUsers.length > 0 ?
                    <>
                      {chatUsers.map((item: any) => {
                        return (
                          <div className="col-sm-12 recentappoint_part position-relative">
                            <h4><i className="bi bi-chat-text"></i></h4>
                            <div className="recentappoint_name mes">
                              <strong>{item.name}</strong>
                              <p>
                                {item.lastMessage
                                  && item.lastMessageType === "text"
                                  ? item.lastMessage
                                  : item.lastMessageType}
                              </p>
                            </div>
                            <div className="three_dots">
                              <i className="bi bi-three-dots-vertical clickable"></i>
                              <div
                                // className="drop_chat drop_list m-0 p-0 bg-white p-2  text-left clickable"
                                className="clickable chat_more drop_list m-0 p-0 bg-white p-2  text-left"
                                onClick={() => navigate(`/${currentUser}/messages`, { state: { chatRoomId: item.room } })}
                              >
                                View Messages
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </>
                    :
                    <div className="text-center mt-3">
                      No data found.
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row admin_bottom">
          <div className="col-lg-12 col-xl-6 p-2">
            <div className="inner_location float-start w-100 bg-white box_shadow  py-4 radius_15">
              <div className="m-0 border-bottom p-2 px-4 d-flex justify-content-between align-items-center pb-4 pt-0">
                <span className="poppins-600 text_18">Today’s Appointments </span>
                <a className="float-end  textgreen text_xs clickable" onClick={() => navigate(`/${currentUser}/appointment`)}>View all</a>
              </div>
              {/* user */}
              <div className="left_user">
                {appointmentsInfo.appointmentsToday?.length > 0 ?
                  <>
                    {appointmentsInfo.appointmentsToday?.map((item: any) => {
                      return (
                        <div className="user_chat float-start w-100 p-2 py-0 px-4">
                          <div className="float-start border-bottom w-100 py-2 py-lg-4 chat_group row justify-content-between align-items-center ">
                            <div className="col-lg-9 d-flex p-0">
                              <div className="user_chat_img float-start">
                                <img
                                  className="rounded-circle"
                                  src={(item?.patient?.profile != 0 && item?.patient?.profile != null) ? `${SOCKET_DOMAIN}/profile/${item?.patient?.profile}` : user1}
                                  alt="user"
                                />
                              </div>
                              <div className="user_chat_detail px-2 pt-1 float-start ">
                                <span className="d-block fs-5 poppins-600 dark_blue">{item.patientName}</span>
                                <p className="m-0 text_xs text_gray fs-6">{item.reason}</p>
                              </div>
                            </div>
                            <div className="float-end col-lg-3 p-0 ">
                              <span className={`d-block text-end poppins-600 ${item.appointmentTime === "OnGoing" ? "textgreen" : "dark_blue"}`}>{item.appointmentTime}</span>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </>
                  :
                  <div className="text-center mt-3">
                    No data found.
                  </div>
                }
              </div>
            </div>
          </div>
          {/* next-patient-detail */}
          <div className="col-lg-12 col-xl-6 p-2">
            <div className="inner_location float-start w-100 bg-white box_shadow  p-4 radius_15">
              <div className="m-0 p-2 px-2 d-flex justify-content-between align-items-center pt-0 pb-3 pb-lg-4">
                <span className="poppins-600 text_18">Next Patient Details</span>
              </div>
              {Object.keys(patientDetail)?.length > 0 ?
                <>
                  <div className="patient_image d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex align-items-center">
                      <div className="user_chat_img patient_chat_img text-center  text-lg-start col-sm-3 float-start p-0 pb-lg-0 pb-2">
                        <img className="rounded-circle" src={user1} alt="user" />
                      </div>
                      <div className="user_chat_detail px-3 pt-1 float-start ">
                        <span className="d-block fs-5 poppins-600 textgreen">{patientDetail?.firstName + " " + patientDetail?.lastName}</span>
                        <p className="m-0 text_xs text_gray fs-6">{patientDetail?.address ? patientDetail?.address : ""}</p>
                      </div>
                    </div>
                  </div>
                  <div className="pat_group py-1 row">
                    <div className="col-6 col-sm-4 row_1 px-1 py-1 py-lg-0">
                      <span className="d-block text_sm text_gray ">Birth Date</span>
                      <p className="m-0 poppins-500 dark_blue fs-6">{patientDetail?.dateOfBirth ? longDateGenerator(patientDetail?.dateOfBirth) : "-"}</p>
                    </div>
                    <div className="col-6 col-sm-4 row_1 px-1 py-1 py-lg-0">
                      <span className="d-block text_sm text_gray ">Gender</span>
                      <p className="m-0 poppins-500 dark_blue fs-6">{patientDetail?.gender ? patientDetail?.gender : "-"}</p>
                    </div>
                    <div className="col-6 col-sm-4 row_1 px-1 py-1 py-lg-0">
                      <span className="d-block text_sm text_gray ">Diagnosis</span>
                      <p className="m-0 poppins-500 dark_blue fs-6">{patientDetail?.currentAppointment?.reason}</p>
                    </div>
                  </div>
                  <div className="pat_group py-1 row">
                    <div className="col-6 col-sm-4 row_1 px-1 py-1 py-lg-0">
                      <span className="d-block text_sm text_gray ">Last Appointment</span>
                      <p className="m-0 poppins-500 dark_blue fs-6">{patientDetail.lastAppointment?.date ? longDateGenerator(patientDetail.lastAppointment?.date) : "-"}</p>
                    </div>
                    <div className="col-6 col-sm-4 row_1 px-1 py-1 py-lg-0">
                      <span className="d-block text_sm text_gray ">Register Date</span>
                      <p className="m-0 poppins-500 dark_blue fs-6">{longDateGenerator(patientDetail.createdAt)}</p>
                    </div>
                  </div>
                  <div className="patient_enquiery align-items-center row justify-content-between mt-3">
                    <div className="col-sm-4 p-0 py-1 py-lg-0">
                      {patientDetail?.allowCall ?
                        <a className="bg_gradiant w-100 d-block text-white text_sm py-2 px-3 rounded-1" href={`tel:${patientDetail.phoneNumber}`}><span className="d-inline-block px-1"><i className="bi bi-telephone-fill"></i></span>{patientDetail.phoneNumber}</a>
                        :
                        <a className="bg_gradiant w-100 d-block text-white text_sm py-2 px-3 rounded-1"><span className="d-inline-block px-1"><i className="bi bi-telephone-fill"></i></span>{patientDetail.phoneNumber}</a>

                      }
                    </div>
                    <div className="col-sm-4 p-0 py-1 py-lg-0">
                      <button className="bg-white w-100 text_sm py-1 px-3 rounded-1 textgreen d-flex align-items-center" type="button" onClick={() => navigate(`/${currentUser}/medical-records`)}>
                        <span className="d-inline-block px-1 fs-6"><i className="bi bi-file-earmark"></i></span>Medical Records
                      </button>
                    </div>
                    <div className="col-sm-3 p-0 py-1 py-lg-0">
                      <button className="bg-white w-100 text_sm py-1 px-3 rounded-1 textgreen d-flex align-items-center" type="button" onClick={() => createChatUsers(patientDetail.id)}>
                        <span className="d-inline-block px-1 fs-6"><i className="bi bi-chat-text"></i></span> message
                      </button>
                    </div>
                  </div>
                </>
                :
                <div className="text-center">
                  No data found.
                </div>
              }
            </div>
            {/* bo-chart */}
            <div className="pt-3 float-start w-100">
              <div className="inner_location float-start w-100 bg-white box_shadow   radius_15">
                <div className="m-0 border-bottom d-flex gap-3 align-items-center p-4 py-3">
                  <span><img src={multiple} alt="user" /></span><span className="poppins-600 text_18">Patient Activity</span>
                </div>
                <div className="p-3">
                  <canvas ref={chartRef} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default DoctorDashboard;
