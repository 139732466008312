import LogoDark from "../assets/images/logos/logo.webp";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <Link to="/">
      <img src={LogoDark} alt="logo-image" />
    </Link>
  );
};

export default Logo;
