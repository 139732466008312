import {
  Row,
  Col,
} from "reactstrap";

import { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { RoleContext, HospitalContext } from "../../../utils/contexts/RoleContext";
import { bookingNotificationUpdate, getOnlineAppointments } from "../../../services/scheduleappointment-service";
import { decidePages } from "../../../utils/utils";
import Pagination from "../../../components/Pagination";
import DeleteModal from "../../../components/DeleteModal";
import { deleteOneBooking } from "../../../services/scheduleappointment-service";
import SearchInput from "../../../components/SearchInput";
import constants from "../../../config/constants";
import { capitalizeFirstLetter } from "../../../utils/utils";
import CustomToolTip from "../../../components/CustomToolTip";

const Telehealth = () => {

  const navigate = useNavigate();
  const currentUser = useContext(RoleContext);
  const hospitalContext: any = useContext(HospitalContext);

  const [bookings, setBookings] = useState([]);
  const [bookingsCopy, setBookingsCopy] = useState([]);
  const [more, setMore] = useState('');
  const [pages, setPages] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [showSearch, setShowSearch] = useState(false);



  useEffect(() => {
    getBookingData(pageNumber);
    document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Telehealth`;
  }, [currentUser, hospitalContext?.selectedHospital?.id]);

  const getBookingData = (page?: number) => {
    const pageCount = page != undefined ? page : 1;
    getOnlineAppointments(pageCount, hospitalContext?.selectedHospital?.id).then(
      (response) => {
        const bookingData = response?.data?.rows;
        setBookings(bookingData);
        setBookingsCopy(bookingData);
        const pages = decidePages(response?.data?.count);
        setPages(pages);
        setPageNumber(pageCount);
      },
      (error) => {
        console.log("Err", error)
      }
    );
  }

  function handleTrash(id: string) {
    setShowDeleteModal(true);
    setDeleteId(id)
  }

  async function deleteBooking(id: any) {
    deleteOneBooking(id).then(
      (response) => {
        console.log("responesss", response);
        if (response.success) {
          setBookings(bookings.filter((item: any) => item.id !== id))
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }
  function handleNavigation() {
    if (currentUser === "admin") {
      navigate(`/${currentUser}/hospital`, { state: { what: "appointment" } });
    } else {
      navigate(`/${currentUser}/schedule_appointment`)
    }
  }

  return (
    <Row>
      <div className="panel_top_section position-relative border-0">
        <h1>Telehealth</h1>
        <div className="grid_icons d-flex gap-1 align-items-center float-end">
          <button
            type="button"
            className="btn_gradian"
            onClick={handleNavigation}
          >
            + Schedule a Telehealth
          </button>
          <div className="grid_list_icons  d-flex align-items-center gap-2">
            <SearchInput
              visible={showSearch}
              data={bookingsCopy}
              setData={(value: any) => setBookings(value)}
            />
            <span
              className="filter_icons fs-3 px-1"
              onClick={() => setShowSearch(!showSearch)}
            >
              <i className="bi bi-funnel-fill"></i>
            </span>
          </div>
        </div>
      </div>
      <Col>
        {bookings?.length > 0 ?
          <div className="row d-flex table-responsive m-0">
            <table >
              <thead>
                <tr className="list_head_new text-start bg_green_table">
                  <th className="text_base px-2 px-xl-4 py-4">Patient Name</th>
                  <th className="text_base px-2 px-xl-4 py-4">
                    {currentUser === "hospital" ? "Assigned Doctor" : "Diagnosis"}
                  </th>
                  <th className="text_base px-2 px-xl-4 py-4">Date</th>
                  <th className="text_base px-2 px-xl-4 py-4">Time </th>
                  <th className="text_base px-2 px-xl-4 py-4 text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {bookings.map((item: any) => {
                  return (
                    <tr
                      className="list_detail_new text-start bg-white border-bottom cursor-pointer"
                      onClick={() => {
                        navigate(`/${currentUser}/appointment/view/${item.id}`, { state: { key: item.id, doctorId: item.doctorId } })
                      }}
                    >
                      <td className="text_base px-2 px-xl-4 py-4">{item.patientName}</td>
                      <td className="text_base px-2 px-xl-4 py-4"> <span className="col-lg-12 col-xl-8">{currentUser === "hospital" ? item.doctorName : item.reason}</span></td>
                      <td className="text_base px-2 px-xl-4 py-4 poppins-500">{item.date}</td>
                      <td className="text_base px-2 px-xl-4 py-4">{item.time}</td>
                      <td className="text_base px-2 px-xl-4 py-4 text-center" onClick={(e) => e.stopPropagation()}>
                        {more !== item.id ?
                          <span
                            className="dost_btn bg_gray_light text-center py-1 fs-5 px-2 rounded-circle cursor-pointer"
                            onClick={() => setMore(item.id)}
                          >
                            <i className="bi bi-three-dots-vertical"></i>
                          </span>
                          :
                          <span className="action text-center" >
                            <CustomToolTip title="Edit">
                            <button
                              className="edit_btn"
                              onClick={() => navigate(`/${currentUser}/schedule_appointment`, { state: { key: item.id, doctorId: item.doctorId } })}
                            >
                              <i className="bi bi-pen"></i>
                            </button>
                            </CustomToolTip>
                            {currentUser === "hospital" &&
                              <CustomToolTip title="Delete">
                            <button
                              className="delete_btn_act"
                              onClick={() => handleTrash(item.id)}
                            >
                              <i className="bi bi-trash"></i>
                            </button>
                              </CustomToolTip>
                            }
                            <CustomToolTip title="Info">
                              <button
                                className="exclamation_btn"
                                onClick={() => {
                                  navigate(`/${currentUser}/appointment/view/${item.id}`, { state: { key: item.id, doctorId: item.doctorId } })
                                }}
                              >
                                <i className="bi bi-exclamation-circle" />
                              </button>
                            </CustomToolTip>
                          </span>
                        }
                      </td>
                    </tr>
                  )
                })}
              </tbody>
              {pages.length > 1 &&
                <tfoot>
                  <Pagination
                    pages={pages}
                    currentPage={pageNumber}
                    onClickPageNumber={(page: any) => {
                      setPageNumber(page);
                      getBookingData(page);
                    }}
                  />
                </tfoot>
              }
            </table>

          </div>
          :
          <div style={{ textAlign: 'center' }}>No data found</div>
        }
        <DeleteModal
          show={showDeleteModal}
          handleYes={() => {
            deleteBooking(deleteId);
            setDeleteId('');
            setShowDeleteModal(false)
          }}
          handleClose={() => setShowDeleteModal(false)}
          body="Do you really want to delete this appointment?"
          head="Delete Location"
        />
      </Col>
    </Row>
  );
};

export default Telehealth;