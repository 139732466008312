import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme

import * as AuthService from "../../../services/auth.service";

import { useContext, useEffect, useMemo, useState } from 'react';
import { deleteoneform, getalldoctors } from '../../../services/form-service';

import { ColDef } from 'ag-grid-community';
import { RoleContext } from '../../../utils/contexts/RoleContext';
import { useNavigate } from 'react-router-dom';
import DeleteModal from "../../../components/DeleteModal";
import Pagination from "../../../components/Pagination";
import { decidePages } from "../../../utils/utils";
import constants from "../../../config/constants";
import { capitalizeFirstLetter } from "../../../utils/utils";
import Spinner from 'react-bootstrap/Spinner';

interface IRow {
    mission: string;
    company: string;
    location: string;
    date: string;
    time: string;
    rocket: string;
    price: number;
    successful: boolean;
}



function FormDashboard() {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [deleteId, setDeleteId] = useState<string>("")
    const [rowData, setRowData] = useState([]);
    const [pages, setPages] = useState<any>([]);
    const [pageNumber, setPageNumber] = useState(1);

    const currentUser = useContext(RoleContext);
    const navigate = useNavigate();

    // Column Definitions: Defines & controls grid columns.
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [fieldId, setFieldId] = useState("")
    const [currentuser, setCurrentUser] = useState<string>("");
    const [colDefs, setColDefs] = useState<ColDef[]>([
        { field: "FormName" },
        { field: "FormLink" },
        { field: "FormStatus" },
        { field: "FormLink" },
        { field: "FormStatus" },

        {
            field: "FieldUpdate", cellRenderer: totalValueRenderer,
            cellRendererParams: {
                role: AuthService.getCurrentUser()?.role
            }
        },
        {
            field: "FieldDelete", cellRenderer: deleteForm,
        }
    ]);
    const [more, setMore] = useState();
    const [showDeleteModal, setShowDeleteModal] = useState(false)




    useEffect(() => {
        getDoctorDetails();
        const value = AuthService.getCurrentUser();
        setCurrentUser(value?.role);
        document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Form Dashboard`;
    }, [currentUser])


    const getDoctorDetails = (page?: any) => {
        const pageCount = page != undefined ? page : 1;
        setLoading(true);
        getalldoctors(pageCount).then(
            (data) => {
                setRowData(data?.allDoctors?.rows);
                const pages = decidePages(data?.allDoctors?.count);
                setPages(pages);
                setPageNumber(pageCount);
                setLoading(false);
            },
            (error) => {
                const resMessage =
                    (error?.response &&
                        error?.response?.data &&
                        error?.response?.data?.message) ||
                    error.message ||
                    error.toString();
                setLoading(false);
                setMessage(resMessage);
            }
        );









    }

    const DeleteAllForms = (id: string) => {

        deleteoneform(id).then(
            () => {
                navigate(`/${currentUser}/manageform`)
                handleClose();

            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setLoading(false);
                setMessage(resMessage);
            }
        );



    }
    function totalValueRenderer(props: any) {




        return (
            <span>

                <button className='btn btn-primary'
                    onClick={() => navigate(`/${props?.role}/drag`, { state: { key: props.data.formId } })}
                >Show Form</button>
            </span>
        );
    };

    function deleteForm(props: any) {

        console.log("fieldiddd", props)


        return (
            <span>

                <button className='btn btn-primary'
                    onClick={() => {
                        handleShow();
                        setDeleteId(props.data.formId)
                    }}
                >Delete Form</button>
            </span>
        );
    };

    return (
        <div className="p-2">
            <div className="d-flex justify-content-end mb-10">
                <button className='btn_gradian' onClick={() => navigate(`/${currentUser}/formupdate`)}>Add New Form</button>
            </div>
            <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                <div className="row d-flex table-responsive">
                    <table>
                        <thead>
                            <tr className="list_head">
                                <th>Form Name</th>
                                <th>Form Link</th>
                                <th>Hospital Name</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!loading ?
                                <>
                                    {rowData?.length > 0 ?
                                        <>
                            {rowData.map((item: any, index: number) => {
                                return (
                                    <tr className="list_detail" key={index}>
                                        <td>{item.formName}</td>
                                        <td>{item.formLinkTo}</td>
                                        <td>{item.user ? item.user.firstName + " " + item.user.lastName : "Created by Admin"}</td>
                                        <td>
                                            {more !== item.formId ?
                                                <span className="dost_btn" onClick={() => setMore(item.formId)}>
                                                    <i className="bi bi-three-dots-vertical"></i>
                                                </span>
                                                :
                                                <span className="action" >
                                                    <button
                                                        className="edit_btn"
                                                        onClick={() => navigate(`/${currentUser}/drag`, { state: { key: item.formId } })}
                                                    >
                                                        <i className="bi bi-eye"></i>
                                                    </button>
                                                    <button
                                                        className="delete_btn_act"
                                                        onClick={() => {
                                                            setShowDeleteModal(true);
                                                            setDeleteId(item.formId);
                                                        }}
                                                    >
                                                        <i className="bi bi-trash"></i>
                                                    </button>
                                                </span>
                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                                        </>
                                        :
                                        <tr className="list_detail">
                                            <td colSpan={10}>No data found</td>
                                        </tr>
                                    }
                                </>
                                :
                                <tr className="list_detail">
                                    <td colSpan={10}><Spinner /></td>
                                </tr>
                            }
                        </tbody>
                        {pages.length > 1 &&
                            <tfoot>
                                <Pagination
                                    pages={pages}
                                    currentPage={pageNumber}
                                    onClickPageNumber={(page: any) => {
                                        setPageNumber(page);
                                        getDoctorDetails(page)
                                    }}
                                />
                            </tfoot>
                        }
                    </table>
                </div>
            </div>
            <DeleteModal
                show={showDeleteModal}
                handleYes={() => {
                    DeleteAllForms(deleteId)
                    setShowDeleteModal(false)
                }}
                handleClose={() => setShowDeleteModal(false)}
                body="Do you really want to delete this form?"
                head="Delete Form"
            />
        </div>
    );
}
export default FormDashboard