import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme

import * as AuthService from "../../../services/auth.service";

import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { deleteoneuser, getalluser, getRoles, getClusters } from "../../../services/user.service";

import { AgGridReact } from 'ag-grid-react'; // React Grid Logic
import { ColDef } from 'ag-grid-community';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import { RoleContext } from '../../../utils/contexts/RoleContext';
import { useNavigate, createSearchParams } from 'react-router-dom';
import userEvent from '@testing-library/user-event';
import IUser from "../../../types/user.type";
import { Row, Col } from "reactstrap";
import Pagination from "../../../components/Pagination";
import { decidePages } from "../../../utils/utils";
import { Spinner } from "react-bootstrap";
import PhoneNumber from "../../../components/PhoneNumber";
// import { roleElements } from "aria-query";

// import { getCurrentUser } from '../../services/auth.service';


interface IRow {
    mission: string;
    company: string;
    location: string;
    date: string;
    time: string;
    rocket: string;
    price: number;
    successful: boolean;
}

function GetProfile() {
    const currentUser = useContext(RoleContext);

    const [show, setShow] = useState(false);
    const [dropDownValue, setDropDownValues] = useState("")
    const [HospitalValue, setHospitalValue] = useState("")
    const [more, setMore] = useState();
    const [role, setRole] = useState('-1');
    const [hospital, setHospital] = useState('-1');
    const [pageNumber, setPageNumber] = useState(1)
    const [popup, showPopup] = useState(false)




    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [deleteId, setDeleteId] = useState("")




    const navigate = useNavigate()


    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            filter: true,
            cellDataType: false,
        };
    }, []);



    const [rowData, setRowData] = useState<any>([])
    const [users, setUsers] = useState([])
    const [hospitalData, setHospitalData] = useState<IRow[]>([])
    const [currentuser, setCurrentUser] = useState<string>("");
    const [userRoles, setUserRoles] = useState([]);
    const [pages, setPages] = useState<any>([])
    const [search, setSearch] = useState<any>(null)


    const [loading, setLoading] = useState(false);


    useEffect(() => {
        const value = AuthService.getCurrentUser();
        setCurrentUser(value?.role)
        getRolesOfUsers();
        getHospitals();
        // handlePagination(1);
        // setPageNumber(1)

    }, []);

    useEffect(() => {
        getAllUsers();
    }, [role, hospital])

    const getRolesOfUsers = () => {
        getRoles().then((res) => {
            if (res?.success) {
                setUserRoles(res?.data)
            }
        })
    }

    const getHospitals = () => {
        getClusters("2").then((res) => {
            setHospitalData(res?.data?.rows)
        })
    }


    const getAllUsers = async (page?: number) => {
        setLoading(true);
        const pageCount = page != undefined ? page : 1;
        // const searchQuery = search != null ? search : "";
        getalluser(pageCount, role, hospital, search).then(
            (data) => {
                setLoading(false);
                if (data?.success) {
                    setRowData(data?.allUsers?.rows)
                    // setPagination(data?.allUsers?.count);
                    setUsers(data?.allUsers?.rows);
                    const pages = decidePages(data?.allUsers?.count);
                    setPages(pages);
                    setPageNumber(pageCount);
                }
            },
            (error) => {
                console.log("Error", error)
                setLoading(false);
            }
        );





    }

    const showForm = (id: any) => {
        // setShow(true)
        handleShow()
        setDeleteId(id)
    }

    const DeleteAllForms = (id: any) => {
        console.log("deltyerer")

        deleteoneuser(id).then(
            () => {
                navigate(`/${currentUser}/getprofile`)
                handleClose();
                getAllUsers();
                // window.location.reload();
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setLoading(false);
            }
        );





    }


    function navigator(props: any) {
        navigate(`/${props.prop1}/editprofile`, { state: { key: props.data.id } })
    }

    const onSearchChange = (e: any) => {
        setSearch(e.target.value);
        if (e.target.value === null || e.target.value === "") {
            getAllUsers()
        }
        else {
            const targetvalue = e.target.value;
            // console.log("uesrsss",targetvalue)
            // setSearch(targetvalue);
            // // getAllUsers(pageNumber);


            const values = rowData.filter((items: any, i: any) => Object.values(items).join(" ").toLowerCase().match(targetvalue.toLowerCase()));
            setUsers(values)
                        }


    }

    const handleRoleChange = (e: any) => {
        setRole(e.target.value);
    }

    const handleHospitalChange = (e: any) => {
        setHospital(e.target.value);
    }


    return (
        <Row>
            <div className="panel_top_section position-relative border-0 billing-container">
                <h1 className="d-block mt-2">Manage Users</h1>
                    <div className="row m-0 py-3 col-xl-9 ms-auto justify-content-end">
                        <div className="col-xl-3 mb-2 p-0 me-xl-2">
                    <label htmlFor="search_input" className="form-label poppins-500">Search</label>
                            <input type="text" className="form-control" onChange={onSearchChange} placeholder="Search..." />
                </div>
                        <div className="col-xl-3 mb-2 p-0 me-xl-2">
                            <label className="form-label poppins-500">Role</label>
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        onChange={handleRoleChange}
                        value={role}
                    >
                                <option value="-1">Select All</option>
                                {userRoles.map((role: { roleId: number, roleName: string }) => {
                                    if (role.roleId != 1) {
                            return (
                                            <option value={role.roleId} className="text-capitalize">{role.roleName}</option>
                            )
                                    }
                        })}
                    </select>
                </div>

                        <div className="col-xl-3 mb-2 p-0 me-xl-2">
                    <label className="form-label poppins-500" >Hospital List</label>
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        onChange={handleHospitalChange}
                    >
                                <option value="-1">Select All</option>
                        {hospitalData?.map((item: any) => {
                            // console.log("itemss",item)
                            return (
                                        <option value={item.id} className="text-capitalize">{item.firstName + " " + item.lastName}</option>
                            )
                        })}
                    </select>
                </div>
                        {/* <div className='col-xl-2 mb-2 p-0'>
                    <label className="form-label poppins-500" ></label>
                    <button
                        className="bg_gradiant border-0 fs-6 text-white mt-2 mt-xl-4 px-4 py-2 w-100 rounded-2"
                        onClick={() => navigate({
                            pathname: `/${currentUser}/hospital`
                        })}
                    >
                        Add User
                    </button>
                </div> */}
                </div>
                {/* </div> */}
            </div>
            <Col>
                <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <div className="row d-flex table-responsive">
                        <table>
                            <thead>
                                <tr className="list_head">
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Email</th>
                                    <th>Phone number</th>
                                    <th>Role</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users?.length > 0 ?
                                    <>
                                        {users.map((doctor: any, index: number) => {
                                    return (
                                        <tr className="list_detail" key={index + doctor.firstName}>
                                            <td> <p> {doctor.firstName}</p></td>
                                            <td> <p>{doctor.lastName}</p></td>
                                                    <td><a href={`mailto:${doctor.email}`}>{doctor.email}</a></td>
                                                    <td> <PhoneNumber phoneNumber={doctor.phoneNumber} allowCall={doctor.allowCall} /></td>
                                                    <td className="text-capitalize">{doctor.role?.roleName}</td>
                                            <td>
                                                {more !== doctor.id ?
                                                    <span className="dost_btn" onClick={() => setMore(doctor.id)}>
                                                        <i className="bi bi-three-dots-vertical"></i>
                                                    </span>
                                                    :
                                                    <span className="action" >
                                                        <button className="edit_btn"
                                                                    onClick={() => {
                                                                        navigate(`/${currentUser}/edit-user/${doctor?.id}`, { state: { key: doctor.id } });
                                                                      }}
                                                        >
                                                            <i className="bi bi-pen"></i></button>
                                                        <button className="delete_btn_act" onClick={() => showForm(doctor.id)}><i className="bi bi-trash"></i></button>
                                                        <button className="exclamation_btn position-relative" ><i className="bi bi-exclamation-circle " onClick={() => {
                                                            showPopup(!popup)
                                                        }}></i>
                                                            <ul className="drop_list m-0 p-0 bg-white p-2  text-left">
                                                                <li><a>{doctor.firstName}</a></li>
                                                                        <li><a href={`mailto:${doctor.email}`}>{doctor.email}</a></li>
                                                                        <li> <PhoneNumber phoneNumber={doctor.phoneNumber} allowCall={doctor.allowCall} /></li>
                                                                        <li>{doctor.role?.roleName}</li>
                                                            </ul>
                                                            {/* } */}
                                                        </button>
                                                    </span>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}
                                    </>
                                    :
                                    <tr className="list_detail">
                                        <td colSpan={10}>
                                            {!loading ? "No data found" : <Spinner />}
                                        </td>
                                    </tr>
                                }
                            </tbody>
                            {pages?.length > 1 &&
                                <tfoot>
                                    <Pagination
                                        pages={pages}
                                        currentPage={pageNumber}
                                        onClickPageNumber={(page: any) => {
                                            setPageNumber(page);
                                            getAllUsers(page)
                                        }}
                                    />
                                </tfoot>
                            }
                        </table>
                    </div>
                </div>
            </Col>

            <Modal show={show} onHide={() => handleClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete User</Modal.Title>
                </Modal.Header>
                <Modal.Body>Do you want delete this User ?</Modal.Body>
                <Modal.Footer>
                    <button className="bg_gray_500 rounded border-0 py-2 px-4" onClick={() => handleClose()}>
                        Cancel
                    </button>
                    <button className="bg_gradiant text-white rounded border-0 py-2 px-4" onClick={() => DeleteAllForms(deleteId)}>
                        Delete
                    </button>
                </Modal.Footer>
            </Modal>
        </Row>
    );
}
export default GetProfile