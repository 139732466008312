import React, { useEffect, useState, useContext } from 'react';

import IUser from '../../types/user.type';
import { IoIosArrowForward } from "react-icons/io";
import { bill_image } from "../../assets/config/constants"
import { doctor_image } from "../../assets/config/constants"
import { getallDoctor } from "../../services/doctor-service";
import { getallpatient, getIncreasedPatientPercentage } from '../../services/patient-service';
import { total_image } from "../../assets/config/constants";
import { RoleContext, HospitalContext } from "../../utils/contexts/RoleContext";
import { useNavigate, createSearchParams } from 'react-router-dom';
import { ImagePath, decideUserNavigation, getCurrentUser } from '../../services/auth.service';
import { getAllBookingAppointment, getLastAppointmentOfUser } from '../../services/scheduleappointment-service';
import { getorders } from '../../services/billing-service';
import { longDateGenerator } from '../../utils/utils';
import { createChatUser, getChatUsers } from '../../services/messages-service';
import user1 from "../../assets/images/users/user1.jpg";
import constants from '../../config/constants';
import { capitalizeFirstLetter } from '../../utils/utils';


function Dashboard() {
    const [doctors, setDoctors] = useState([]);
    const [patients, setPatients] = useState([]);
    const [allPatients, setAllPatients] = useState([]);
    const [search, setSearch] = useState([]);
    const [chatUsers, setChatUsers] = useState<any>([]);
    const [allBooking, setAllBooking] = useState<any[]>([]);
    const [paymentDetails, setPaymentDetails] = useState<any>({});
    const [patientsInfo, setPatientsInfo] = useState<any>({});

    const navigate = useNavigate()
    const currentUser = useContext(RoleContext);
    const hospitalContext: any = useContext(HospitalContext);

    useEffect(() => {
        getAllDoctors();
        getAllPatients();
        fetchChatUsers();
        getBookingData();
        getAllOrders();
        getPatientPercentage();
        document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Dashboard`;
        if (hospitalContext?.selectedHospital?.id == -1 && currentUser === "admin") {
            navigate(`/${currentUser}/Dashboard`)
        }
    }, [currentUser, hospitalContext?.selectedHospital?.id]);



    async function getPatientPercentage() {
        let hospitalId = currentUser === "hospital" ? getCurrentUser()?.id : hospitalContext.selectedHospital?.id;
        const response = await getIncreasedPatientPercentage(hospitalId);
        if (response?.success) {
            setPatientsInfo(response?.data);
        }
    }

    async function getAllOrders() {
        const response = await getorders("undefined", "undefined", hospitalContext?.selectedHospital?.id);
        if (response?.success) {
            setPaymentDetails(response?.paymentDetails)
        }
    }

    const getBookingData = () => {
        getAllBookingAppointment(1, "undefined", "undefined", "undefined", hospitalContext?.selectedHospital?.id).then(
            (response) => {
                if (response?.success) {
                    const bookingData = response?.appointmentBooking?.rows;
                    setAllBooking(bookingData?.slice(0, 3));
                }
            },
            (error) => {
                console.log("error", error)
            }
        );
    }

    async function getAllDoctors() {
        getallDoctor(1, hospitalContext?.selectedHospital?.id).then((res) => {
            if (res?.success) {
                setDoctors(res?.result?.slice(0, 4));
            }
        })
            .catch((e) => console.log("error", e))
    }

    async function fetchChatUsers() {
        const response = await getChatUsers();
        if (response?.success) {
            setChatUsers(response?.data?.slice(0, 3));
        }
    }



    const getAllPatients = () => {
        getallpatient(1, hospitalContext?.selectedHospital?.id).then(
            async (response) => {
                if (response?.success) {
                    let patientData = response?.result?.slice(0, 5);
                    const arr: any = []
                    var check: any;
                    check = await Promise.all(
                        patientData?.map(async (patient: any) => {
                            const obj: any = {
                                ...patient
                            }
                            patient?.userdata?.forEach((item: any) => {
                                if (item?.fieldKey === "gender") {
                                    obj.gender = JSON.parse(item?.fieldValue);
                                }
                            });
                            const lastAppointmentOfUser = await getLastAppointmentOfUser(patient?.id);
                            if (lastAppointmentOfUser?.data == null) {
                                obj.assignedDoctor = "-";
                            } else {
                                obj.assignedDoctor = lastAppointmentOfUser?.data?.doctorName;
                            }
                            arr.push(obj);
                            return check
                        })
                    );
                    setPatients(arr);
                    setAllPatients(arr)
                }
            },
            (error) => {
                console.log("error", error)
            }
        );
    }

    const handleSearch = (e: any) => {
        const { name, type, value } = e.target;
        setSearch(value);
        if (value === "") {
            setPatients(allPatients)
        } else {
            const values = allPatients?.filter((items: any, i: number) => Object.values(items)?.join(" ")?.toLowerCase()?.match(value?.toLowerCase()));
            setPatients(values)
        }
    }

    async function createChatUsers(id: number) {
        const response = await createChatUser(id);
        navigate(`/${currentUser}/messages`, { state: { chatRoomId: response.data.room } });
    }

    return (
        <div className="middlepart_top">
            <div className="row my-2">
                <div className="col-12 col-md-6 col-xxl-3 px-2 py-2">
                    <div className="billing_sec">
                        <div className="billing_sec_top">
                            <span><img src={bill_image} alt="logo-image" /></span>
                            <p>Billing / Payments</p>
                        </div>
                        <strong>$ {paymentDetails.paymentReceivedToday}
                            {paymentDetails.paymentReceivedToday != 0 &&
                                <span>{paymentDetails.paymentReceivedToday != 0 && paymentDetails.receivedTodayPercentage + " %"}</span>
                            }
                        </strong>
                        <p>Payment Received Today</p>
                        <a
                            className='clickable'
                            onClick={() => navigate(`/${currentUser}/billing`)}
                        >
                            See Details <IoIosArrowForward />
                        </a>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-xxl-3 px-2 py-2">
                    <div className="billing_sec">
                        <div className="billing_sec_top">
                            <span><img src={total_image} alt="logo-image" /></span>
                            <p>Total Patients</p>
                        </div>
                        <strong>
                            {patientsInfo.ratio}
                            {patientsInfo.ratio !== 0 &&
                                <span>
                                    {patientsInfo.percentage + " %"}
                                </span>
                            }
                        </strong>
                        <p>{patientsInfo.message}</p>
                        <a
                            className='clickable'
                            onClick={() => navigate(`/${currentUser}/patient-list`)}
                        >
                            See Details <IoIosArrowForward />
                        </a>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-xxl-3 px-2 py-2">
                    <div className="col-sm-12 billing_sec two">
                        <div className="col-sm-12 recentappoint_sec">
                            <p>Recent Appointments</p>
                            <a
                                className='clickable'
                                onClick={() => navigate(`/${currentUser}/appointment`)}
                            >
                                View All
                            </a>
                        </div>
                        <div className="col-sm-12 recentappoint_wrap">
                            {allBooking?.length > 0 ?
                                <>
                                    {allBooking?.map((item: any) => {
                                        return (
                                            <div className="col-sm-12 recentappoint_part position-relative">
                                                <h4>{item.patientName?.slice(0, 1)}</h4>
                                                <div className="recentappoint_name">
                                                    <strong>{item.patientName}</strong>
                                                    <p>Diagnosis: {item.reason}</p>
                                                </div>
                                                <span>{item.time}</span>
                                                <div className="three_dots">
                                                    <i className="bi bi-three-dots-vertical clickable"></i>
                                                    <div
                                                        // className="drop_chat drop_list m-0 p-0 bg-white p-2  text-left clickable"
                                                        className="clickable chat_more drop_list m-0 p-0 bg-white p-2  text-left"
                                                        onClick={() => navigate(`/${currentUser}/schedule_appointment`, { state: { key: item.id, doctorId: item.doctorId } })}
                                                    >
                                                        Edit
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </>
                                :
                                <div style={{ textAlign: 'center' }}>
                                    No data found.
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-xxl-3 px-2 py-2">
                    <div className="col-sm-12 billing_sec two">
                        <div className="col-sm-12 recentappoint_sec">
                            <p>Messages</p>
                            <a
                                className="clickable"
                                onClick={() => navigate(`/${currentUser}/messages`)}
                            >
                                View All
                            </a>
                        </div>
                        <div className="col-sm-12 recentappoint_wrap">
                            {chatUsers.length > 0 ?
                                <>
                                    {chatUsers?.map((item: any) => {
                                        return (
                                            <div className="col-sm-12 recentappoint_part position-relative">
                                                <h4><i className="bi bi-chat-text"></i></h4>
                                                <div className="recentappoint_name mes">
                                                    <strong>{item.name}</strong>
                                                    <p>
                                                        {item.lastMessage
                                                            && item.lastMessageType === "text"
                                                            ? item.lastMessage
                                                            : item.lastMessageType}
                                                    </p>
                                                </div>
                                                <div className="three_dots">
                                                    <i className="bi bi-three-dots-vertical clickable"></i>
                                                    <div
                                                        // className="drop_chat drop_list m-0 p-0 bg-white p-2  text-left clickable"
                                                        className="clickable chat_more drop_list m-0 p-0 bg-white p-2  text-left"
                                                        onClick={() => navigate(`/${currentUser}/messages`, { state: { chatRoomId: item.room } })}
                                                    >
                                                        View Messages
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </>
                                :
                                <div style={{ textAlign: 'center' }}>
                                    No data found.
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="row my-4">
                <div className=" col-12 col-xxl-8 dashcharts_wrap px-2">

                </div>
                <div className="col-sm-4 dashcalendar_wrap px-2">

                </div>
            </div>
            <div className="row my-2">
                <div className="col-12 col-xxl-8 dashpatient_wrap px-2 py-2">
                    <div className="patientsname_sec">
                        <div className="col-sm-12 patientsname_top row m-0 mb-2">
                            <div className="col-sm-4 pb-2 patientsname_top_left d-flex p-0 justify-content-between align-items-center">
                                <h2>Patients</h2>
                                <a className='clickable' onClick={() => navigate(`/${currentUser}/patient-list`)}>View All</a>
                            </div>
                            <div className="col-sm-7 patientsname_top_right p-0 row m-0 pb-2">
                                <div className="col-xl-6 patientsearch_form p-0 mb-2">
                                    <input
                                        className="w-100"
                                        type="text"
                                        placeholder="Search..."
                                        name="search"
                                        value={search}
                                        onChange={handleSearch}
                                    />
                                </div>
                                <div className="col-xl-6 text-end p-0  pb-2">
                                    <a className="d-inline-block clickable"
                                        onClick={() =>
                                            navigate(`/${currentUser}/add-user/5`, { state: 5 })
                                        }
                                    >+ New Patient</a>
                                </div>
                            </div>
                        </div>
                        {patients.length > 0 ?
                            <div className="col-sm-12 patientstable_sec table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th scope="col">No</th>
                                            <th scope="col">Created Date</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Gender</th>
                                            <th scope="col">Assigned Doctor</th>
                                            <th scope="col">Action</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-group-divider">
                                        {patients?.map((patient: any, index: number) => {
                                            return (
                                                <tr key={index + patient.firstName}>
                                                    <td><input type="radio"></input></td>
                                                    <td>{index + 1}</td>
                                                    <td>{longDateGenerator(patient.createdAt)}</td>
                                                    <td>{patient.firstName + " " + patient.lastName}</td>
                                                    <td>{patient.gender}</td>
                                                    <td>{patient.assignedDoctor}</td>
                                                    <td>
                                                        <button
                                                            onClick={() => createChatUsers(patient.id)}
                                                        ><i className="bi bi-chat-text"></i></button>
                                                        <button>
                                                            {patient.allowCall ?
                                                            <a href={`tel:${patient.phoneNumber}`}>
                                                                <i className="bi bi-telephone" />
                                                            </a>
                                                                :
                                                                <span
                                                                style={{opacity: 0.5}}
                                                                    // onClick={() => alert("This user doesn't want to receive phone call")}
                                                                >
                                                                    <i className="bi bi-telephone" />
                                                                </span>
                                                            }
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                                No data found.
                            </div>
                        }
                    </div>
                </div>
                <div className=" col-12 col-xxl-4 dashdoctor_wrap px-2 py-2">
                    <div className="douctorlist_sec">
                        <div className="douctorlist_head">
                            <h4>Doctors List</h4>
                            <a className='clickable' onClick={() => navigate(`/${currentUser}/doctordetaillist`)}>View All</a>
                        </div>
                        <div className="douctorlist_wrap">
                            {doctors?.length > 0 ?
                                <>
                            {doctors?.map((doctor: any, index) => {
                                return (
                                    <div
                                        className="douctorlist_part clickable" key={`${index}`}
                                        onClick={() => navigate(`/${currentUser}/doctor-detail/${doctor.id}`)}
                                    >
                                        <span>
                                            <img
                                                src={doctor?.profile != 0 ? ImagePath + 'profile/' + doctor?.profile : user1}
                                                alt="profile-image"
                                            />
                                        </span>
                                        <div className="douctorlist_name">
                                            <strong>Dr. {doctor?.firstName + " " + doctor?.lastName}</strong>
                                            {doctor?.user_form_data?.map((item: any) => {
                                                return (
                                                    item.formFieldKey === "specialtyDepartment" &&
                                                    <p>{item.formFieldValue}</p>
                                                )
                                            })}

                                        </div>
                                        <IoIosArrowForward />
                                    </div>
                                )
                            })}
                                </>
                                :
                                <div className='text-center mb-3'>
                                    No data found.
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard