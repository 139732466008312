
import profile from "../../../assets/images/doctor/profile.png";
import {
  Card,
  Row,
  Col,
  CardTitle,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import React, { useContext, useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { RoleContext, AlertContext, RoleUpdateContext } from "../../../utils/contexts/RoleContext";
import { deleteoneuser, getoneuser } from "../../../services/user.service";
import { getCurrentUser, changepassword, checkPassword, sendOTPMail, changeCredentials } from "../../../services/auth.service";
import DeleteModal from "../../../components/DeleteModal";
import { capitalizeFirstLetter } from "../../../utils/utils";
import EditProfile from "../../adminHospital/user/EditProfile";
import OtherSettings from "./OtherSettings";
import PaymentSettings from "./PaymentSettings";
import PaymentIcon from "../../../assets/images/icons/payment.webp";
import TimeZones from "../../../assets/timezone.json"
import { saveUserSettings, getOneUserSettings } from "../../../services/adminsetting-service";
import { logout } from "../../../services/auth.service";
import { socket } from "../../../index";
import OtpInput from 'react-otp-input';

const DoctorPasswordSetting = () => {

  const location = useLocation();
  const currentUser = useContext(RoleContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState<any>({
    currentEmail: {
      value: "",
      error: "",
      label: "Current Email"
    },
    newEmail: {
      value: "",
      error: "",
      label: "New Email"
    }
  });
  const [showEmail, setShowEmail] = useState(true);
  const [otp, setOtp] = useState("");
  const [currentPassowrd, setCurrentPassword] = useState<any>("");
  const [newPassword, setNewPassword] = useState<any>("");
  const [confirmPassword, setConfirmPassword] = useState<any>("");
  const [emailErr, setEmailErr] = useState<string>("");
  const [currentPasswordErr, setCurrentPasswordErr] = useState<string>("");
  const [newPasswordErr, setNewPasswordErr] = useState<string>("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState<string>("");
  const [err, setErr] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [deleteAccountModal, setDeleteAccountModal] = useState(false);
  const [content, setContent] = useState("Password");
  const [informations, setInformations] = useState<any>({
    clinicDescription: {
      label: "Clinic Description",
      type: "text",
      value: "",
      error: ""
    },
    languages: {
      label: "Languages",
      type: "select",
      value: "",
      error: "",
      options: [
        {
          text: "English",
          value: "English"
        }
      ]
    },
    timeZone: {
      label: "Time Zone",
      type: "select",
      value: "",
      error: "",
      options: TimeZones
    },
    websiteURL: {
      label: "Website URL",
      type: "text",
      value: "",
      error: ""
    }
  });

  const toast: any = useContext(AlertContext);
  const roleUpdate: any = useContext(RoleUpdateContext)

  useEffect(() => {
    // fetchUserDetails();
    fetchOneUserSettings();
  }, []);

  async function fetchOneUserSettings() {
    const response = await getOneUserSettings();
    if (response?.success) {
      const obj: any = { ...informations };
      response?.data?.forEach((item: any) => {
        if (informations[item.settingsKey]) {
          informations[item.settingsKey].value = item.settingsValue
        }
      });
      setInformations(obj);
    }
  }

  async function fetchUserDetails() {
    const response = await getoneuser(getCurrentUser()?.id);
    if (response?.success) {
      setEmail(response?.allUsers?.email)
    }
  }


  const handlePassword = async () => {
    let user = getCurrentUser();
    // const { firstname, lastname, email, password, phonenumber } = formValue;
    setEmailErr("")
    setCurrentPasswordErr("")
    setNewPasswordErr("")
    setConfirmPasswordErr("")
    if (email === "") {
      setEmailErr("please enter email")
    }
    else if (currentPassowrd === "") {
      setCurrentPasswordErr("please enter current password")
    }
    else if (newPassword === "") {
      setNewPasswordErr("please enter new password")
    }
    else if (confirmPassword === "") {
      setConfirmPasswordErr("please enter confirm password")
    }
    else if (newPassword != confirmPassword) {
      setErr("password mismatch")
    } else if (!await verifyPassword()) {
      setErr("Your current password is incorrect")
    }
    else {
      changepassword(newPassword, user.id).then(
        (data) => {
          setTimeout(() => {
            // navigate(route);
            toast.setShowToast(data.message, "success");
          }, 1000)
          if (currentUser === "patient") {
            navigate(`/${currentUser}/appointment`);
          } else {
            navigate(`/${currentUser}/dashboard`);
          }
          // window.location.reload();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          // setLoading(false);
          // setMessage(resMessage);
        }
      );
    }
  };

  const verifyPassword = async () => {
    const response = await checkPassword(currentPassowrd, getCurrentUser()?.id);
    return response?.data
  }

  const handleDelete = async () => {
    let user = getCurrentUser();
    deleteoneuser(user?.id).then(
      (response) => {
        if (response?.success) {
          logOut();
        }
      },
      (error) => {
        console.log(error)
      }
    );
  }

  const logOut = () => {
    socket.emit("user_status", {
      status: "OFFLINE",
      id: getCurrentUser()?.id
    });
    logout();
    roleUpdate(undefined);
    navigate(`/${currentUser}/login`)
    // window.location.reload();
  };

  function handleChange(e: any) {
    const { name, value } = e.target;
    console.log(name, value)
    setInformations((prevState: any) => ({ ...prevState, [name]: { ...prevState[name], value } }))
  }


  async function handleSubmit() {
    const obj: any = {};
    for (let key in informations) {
      if (informations[key].value !== "") {
        obj[key] = informations[key].value
      }
    }
    const response = await saveUserSettings(obj);
    if (response.success) {
      toast.setShowToast("Setting saved successfully!", "success")
    } else {
      toast.setShowToast("Something went wrong on our side. Please try again later!", "danger")
    }
    window.scrollTo(0, 0);
  }

  function handleEmailChange(e: any) {
    const { name, value } = e.target;
    setEmail((prevState: any) => ({ ...prevState, [name]: { ...prevState[name], value, error: "" } }))
  }

  function validateEmail() {
    let emailSuccess = true;
    for (let key in email) {
      if (email[key].value === "") {
        setEmail((prevState: any) => ({ ...prevState, [key]: { ...prevState[key], error: "This field is required" } }));
        emailSuccess = false;
      }
    }
    return emailSuccess;
  }

  async function sendOTP(otpFor: string) {
    if (validateEmail()) {
      const response = await sendOTPMail(email.currentEmail.value, email.newEmail.value, "email")
      if(response?.success) {
        toast.setShowToast(response?.data, "success");
        setShowEmail(false);
        setOtp("");
      } else {
        toast.setShowToast(response?.data, "danger");
      }
    }
  }

  async function handleEmailSubmit() {
    setShowEmail(true);
    const response = await changeCredentials(otp, "email");
    if(response?.success) {
      toast.setShowToast(response.data, "success");
      setTimeout(() => {
        logOut();
      }, 3000)
    } else {
      toast.setShowToast(response.data, "danger")
    }
  }


  return (
    <Row>
      <div className="panel_top_section border-0 pb-0 pt-2">
        <h1>Settings</h1>
      </div>
      <Col className="mt-3 mb-4">
        <div className="setting_form card_box col-sm-12 col-xl-12 mx-auto py-4 px-lg-4 mt-4 row">
          <div className="sidebar_left col-xl-4 border-end px-2 px-lg-0 mt-4">
            <button className={`${content === "Password" && "active"} d-flex align-items-center col-12 col-lg-9 bg-transparent text-start border-0 fs-6 px-2 py-1 rounded-pill mb-2`} type="button"
              onClick={() => setContent("Password")}
            >
              <span className="d-inline-block px-2 fs-4"><i className="bi bi-shield-lock-fill" /></span>
              {currentUser !== "hospital" ? "Password Settings" : "General Settings"}
            </button>
            <button className={`${content === "Profile" && "active"} d-flex align-items-center col-12 col-lg-9 bg-transparent text-start border-0 fs-6 px-2 py-1 rounded-pill mb-2`} type="button"
              onClick={() => setContent("Profile")}
            ><span className="d-inline-block px-2 fs-4"><i className="bi bi-person-fill-gear"></i></span>{`${capitalizeFirstLetter(currentUser)} Profile`}</button>
            {currentUser === "hospital" &&
              <button className={`${content === "Payment" && "active"} d-flex align-items-center col-12 col-lg-9 bg-transparent text-start border-0 fs-6 px-2 py-1 rounded-pill mb-2`} type="button"
                onClick={() => setContent("Payment")}
              >
                <span className="d-inline-block px-2 fs-4">
                  <img src={PaymentIcon} alt="payment-icon" style={{ height: "25px", width: "25px" }} />
                </span>Payment Connections</button>
            }
            <button className={`${content === "Other" && "active"} d-flex align-items-center col-12 col-lg-9 bg-transparent text-start border-0 fs-6 px-2 py-1 rounded-pill mb-2`} type="button"
              onClick={() => setContent("Other")}
            ><span className="d-inline-block px-2 fs-4"><i className="bi bi-gear"></i></span>Other Settings</button>
          </div>
          <div className="settging_right px-2 px-xl-0 col-xl-8 mt-3 pb-lg-4 mb-lg-4">
            <h2 className="poppins-400 fw-normel fs-4 dark_blue px-4 mx-2 mb-4">{content === "Payment" ? "Payment Connections" : `${content} Settings`}</h2>
            {/* Login Credentials */}
            {content === "Password" ?
              <>
                <div className="row justify-content-around  mb-4 pb-2 px-lg-4">
                  <div className="col-lg-12 col-xl-4">
                    <p className="text-black-50 fw-medium poppins-500">Login Credentials</p>
                  </div>
                  <div className="col-lg-12 col-xl-8">
                    <div>
                      <span className="fw-bolder">Change Email:</span>
                      {showEmail ?
                        <div className="mt-2 row">
                          {Object.keys(email)?.map((key) => {
                            return (
                              <div className="col-lg-12 col-xl-6 mb-3">
                                <span>{email[key].label}</span>
                                <span style={{ color: "red" }}>*</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={email[key].value}
                                  onChange={handleEmailChange}
                                  name={key}
                                />
                                {
                                  email[key].error ?
                                    <div >
                                      <p style={{ color: "red" }}>{email[key].error}</p>
                                    </div>
                                    :
                                    ""
                                }
                              </div>
                            )
                          })}
                        </div>
                        :
                        <div className="my-3">
                          <OtpInput
                            value={otp}
                            onChange={(e) => setOtp(e)}
                            numInputs={6}
                            renderInput={(props) => <input    {...props}
                            />}
                            containerStyle={{ display: 'flex', justifyContent: "space-around" }}
                            inputStyle={{ width: "15%", height: "60px", borderRadius: "10px", border: "1px solid #00929f" }}
                          />
                        </div>
                      }
                      <div className="col-lg-12 col-xl-12 mb-3 mt-1 text-end">
                        {showEmail ?
                          <button
                            className="bg_gradiant text-white border-0 fs-5 px-4 py-2 rounded-3"
                            type="button"
                            onClick={() => sendOTP("email")}
                          >
                            Send OTP
                          </button>
                          :
                          <button
                            className="bg_gradiant text-white border-0 fs-5 px-4 py-2 rounded-3"
                            type="button"
                            onClick={handleEmailSubmit}
                          >
                            Change Email
                          </button>
                        }
                      </div>
                    </div>
                    <div>
                      <span className="fw-bolder">Change Password:</span>
                      <div className="mt-2 row">
                        <div className="col-lg-12 col-xl-6 mb-3">
                          <span>Current Password</span>
                          <span style={{ color: "red" }}>*</span>
                          <input
                            type="password"
                            className="form-control"
                            id="password"
                            value={currentPassowrd}
                            onChange={(e) => { setCurrentPasswordErr(""); setCurrentPassword(e.target.value) }}
                          />
                          {
                            currentPasswordErr ?
                              <div >
                                <p style={{ color: "red" }}>{currentPasswordErr}</p>

                              </div> : ""
                          }
                        </div>
                        <div className="col-lg-12 col-xl-6 mb-3">
                          <span>New Password</span>
                          <span style={{ color: "red" }}>*</span>
                          <input
                            type="password"
                            className="form-control"
                            id="password_new"
                            value={newPassword}
                            onChange={(e) => { setNewPasswordErr(""); setNewPassword(e.target.value) }}
                          />
                          {
                            newPasswordErr ?
                              <div >
                                <p style={{ color: "red" }}>{newPasswordErr}</p>

                              </div> : ""
                          }
                        </div>
                        <div className="col-lg-12 col-xl-6 mb-3">
                          <span>Confirm Password</span>
                          <span style={{ color: "red" }}>*</span>
                          <input
                            type="password"
                            className="form-control"
                            id="confirm_password"
                            value={confirmPassword}
                            onChange={(e) => { setConfirmPasswordErr(""); setConfirmPassword(e.target.value) }}
                          />
                          {confirmPasswordErr ?
                            <div >
                              <p style={{ color: "red" }}>{confirmPasswordErr}</p>

                            </div> : ""
                          }
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-xl-12 mb-3 mt-1 text-end">
                      {
                        err ?
                          <div >
                            <p style={{ color: "red" }}>{err}</p>

                          </div> : ""
                      }
                      <button className="bg_gradiant text-white border-0 fs-5 px-4 py-2 rounded-3" type="button" onClick={() => setShowModal(true)}>Change</button>
                    </div>
                    <div className="col-lg-12 col-xl-12 mb-3 mt-1 ">
                      <p
                        className="text-black-50 fw-medium"
                      >
                        Delete Account
                      </p>
                      <p className="d-flex text-black-50 col-xl-8 float-start mb-0"><span className="d-inline-block mx-1"><i className="bi bi-exclamation-circle "></i></span>By deleting your account, you'll lose
                        access to all associated data and
                        content.</p>
                      <button
                        className="border-0 bg-transparent textgreen p-0 float-end"
                        type="button"
                        onClick={() => setDeleteAccountModal(true)}
                      >
                        Delete Account
                      </button>
                    </div>
                  </div>
                </div>
                {currentUser === "hospital" &&
                  <>
                    <div className="row justify-content-around  mb-4 pb-4 pt-4 border-top px-lg-4">
                      <div className="col-lg-12 col-xl-4">
                        <p className="text-black-50 fw-medium poppins-500">Informations</p>
                      </div>
                      <div className="row  col-lg-12 col-xl-8">
                        {Object.keys(informations)?.map((key: any) => {
                          return (
                            <div className="col-lg-12 mb-3">
                              <span>{informations[key]?.label}</span>
                              {informations[key]?.type === "select" ?
                                <select
                                  className="form-select mt-1"
                                  value={informations[key]?.value}
                                  onChange={handleChange}
                                  name={key}
                                >
                                  <option value="">Select...</option>
                                  {informations[key]?.options?.map((option: any) => {
                                    return (
                                      <option value={option.value}>{option.text}</option>
                                    )
                                  })}
                                </select>
                                :
                                <input
                                  type="text"
                                  className="form-control mt-1"
                                  value={informations[key]?.value}
                                  onChange={handleChange}
                                  name={key}
                                />
                              }
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    <div className="row border-top pt-4">
                      <div className="col-lg-12 col-xl-4">
                      </div>
                      <div className="form_bottom_btn d-flex justify-content-end">
                        <button className="delete_btn" type="button" onClick={() => navigate(-1)}>Cancel</button>
                        <button
                          className="btn_gradian"
                          type="button"
                          onClick={handleSubmit}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </>
                }
              </>
              :
              content === "Profile" ?
                <EditProfile />
                :
                content === "Payment" ?
                  <PaymentSettings />
                  :
                  <OtherSettings />
            }
          </div>
        </div>
      </Col>
      <DeleteModal
        show={showModal}
        handleYes={() => {
          handlePassword();
          setShowModal(false);
        }}
        handleClose={() => setShowModal(false)}
        body="Do you really want to change the password?"
        head="Change Password"
        buttonName="Proceed"
      />
      <DeleteModal
        show={deleteAccountModal}
        handleYes={() => {
          handleDelete();
          setDeleteAccountModal(false);
        }}
        handleClose={() => setDeleteAccountModal(false)}
        body="Do you really want to delete your Account?"
        head="Delete Account"
        buttonName="Proceed"
      />
    </Row>
  );
};

export default DoctorPasswordSetting;
