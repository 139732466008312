import authHeader from "./auth-header";
import axios from "axios";
import { END_POINT } from "../config/constants";

// const APIS_URL = "http://localhost:5000/api/staff/";
const APIS_URL = `${END_POINT}staff/`

export const getallStaff = (page?: number,hospitalId?: number) => {
  return axios
    .get(`${APIS_URL}get_staff?roleId=4&page=${page}&hospitalId=${hospitalId}`, {
   
    })
    .then((response) => {
    

      return response?.data;
    });
};
  
  export const deleteStaffData = (id: any) => {
    return axios
      .delete(APIS_URL + `staff_delete/${id}`, {
     
      })
      .then((response) => {
      
  
        //  response?.data;
        window.location.reload();
      });
  };
  
  
  export const getoneuser = async (key:string) => {
    const response = await axios
          .get(APIS_URL + "getOneUser/" + key, {});
      return response?.data;
  };
  
  export const updateprofile = async (key:string,values:object) => {
    const response = await axios
          .put(APIS_URL + "updateprofile/" + key, {
              values
          });
      return response?.data;
  };

  export const importStaffs = ( values: any) => {
    return axios
      .post(APIS_URL + "import-staffs" , {
        values, 
      })
      .then((response) => {
        return response?.data;
      });
  };

