import doctor_imagem from "../assets/images/doctor/doctorm.jpg";
import Calendar from 'react-calendar';
import './ScheduleAppointment.css';
import Select from 'react-select';
import 'react-calendar/dist/Calendar.css';
import {
  Card,
  Row,
  Col,
  CardTitle,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import React, { useContext, useEffect, useState } from "react";

import { getallDoctor } from "../../../services/doctor-service";
import { getallpatient } from "../../../services/patient-service";
import { getDoctorAppointment, doctorBookingFormCreate, oneDoctorBookingFormUpdate, doctorBookingFormUpdate } from "../../../services/scheduleappointment-service";
// import { MomentInput, DurationInputArg1 } from "moment";
import moment from 'moment';
import { Value } from "sass";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { getBookingsOfOneDoctor } from "../../../services/scheduleappointment-service";
import { AlertContext, RoleContext } from "../../../utils/contexts/RoleContext";
import { getCurrentUser } from "../../../services/auth.service";
import Spinner from 'react-bootstrap/Spinner';
import constants from "../../../config/constants";
import { capitalizeFirstLetter } from "../../../utils/utils";

interface DoctorAvailability {
  doctorName: string,
  patientName: string,
  reason: string,
  type: string,
}

interface Doctor {
  id: number;
  firstName: string;
  lastName: string;
  speciality: string;
}
interface Patient {
  id: number;
  firstName: string;
  lastName: string;
  speciality: string;
}

const currentTime = new Date();


const ScheduleAppointment = () => {
  const { doctorName } = useParams()
  
  const navigate = useNavigate();
  const location = useLocation();
  const [doctors, setDoctors] = useState<Doctor[]>([]);
  const [patients, setPatients] = useState<Patient[]>([])
  const [selectedDoctorId, setSelectedDoctorId] = useState<number | null>(null);
  const [selectedPatientId, setSelectedPatientId] = useState<number | null>(null);
  const [selectedDate, setSelectedDate] = useState<any>(new Date());
  const [day, setDay] = useState<any>();
  const [newDate, setNewDate] = useState<string>("");
  const [currentDate, setCurrentDate] = useState<number>();
  const [currentMonth, setCurrentMonth] = useState<string>("");
  const [appointmentTime, setAppointmentTime] = useState<any[]>([])
  const [selectedTime, setSelectedTime] = useState(null);
  const [limit, setLimit] = useState(7);
  const [showCalendar, setShowCalendar] = useState(false);
  const [filteredDoctors, setFilteredDoctors] = useState<Doctor[]>([]);
  const [filteredPatient, setFilteredPatient] = useState<Patient[]>([]);
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchInputPatient, setSearchInputPatient] = useState<string>("");
  const [selectedDoctor, setSelectedDoctor] = useState<Doctor | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpenPatient, setIsDropdownOpenPatient] = useState(false);
  const [notificationCount, setNotificationCount] = useState<any>(0);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedSuggestionIndexDoctor, setSelectedSuggestionIndexDoctor] = useState<number>(-1);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState<number>(-1);

  //doctorspeciality
  const [searchInputDoctorSpeciality, setSearchInputDoctorSpeciality] = useState<string>("");
  const [selectedSuggestionIndexSpeciality, setSelectedSuggestionIndexSpeciality] = useState<number>(-1);
  const [isDropdownOpenDoctorSpeciality, setIsDropdownOpenDoctorSpeciality] = useState(false);
  const [filteredSpeciality, setFilteredSpeciality] = useState<Patient[]>([]);
  const [searchInputSpeciality, setSearchInputSpeciality] = useState<string>("");
  const [selectedSpecialityId, setSelectedSpecialityId] = useState<number | null>(null);
  const [filterSpecialityDoctor, setFilterSpecialityDoctor] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);




  //
  const [formData, setFormData] = useState<DoctorAvailability>({
    doctorName: "",
    patientName: "",
    reason: "",
    type: "",
  });

  const [validationMessages, setValidationMessages] = useState<any>({
    doctorName: "",
    patientName: "",
    reason: "",
    type: "",
    calendar: "",
    appointmentTime: "",
    speciality: ""
  });
  const [validationMessagesnew, setValidationMessagesnew] = useState<any>({
    calendar: "",
    appointmentTime: ""
  });
  const [previousBookings, setPreviousBookings] = useState<any>([]);
  const toast: any = useContext(AlertContext);
  const currentUser = useContext(RoleContext)



  useEffect(() => {
    getUsersDoctor();
    getUserspatient();
  }, []);

  useEffect(() => {
    if (location?.state?.doctorName) {
      setSearchInput(location?.state?.doctorName)
    }
    // console.log('location' , location.state)
    if (location.state?.key) {
      oneDoctorBookingFormUpdate(location?.state?.key).then(
        (response) => {
          const appointmentData = response?.appointmentBooking

          if (Array.isArray(appointmentData) && appointmentData?.length > 0) {
            // Assuming appointmentData is an array of appointments
            appointmentData?.forEach((appointment) => {
              const dateObject = new Date(appointment?.date);
              const options: Intl.DateTimeFormatOptions = { weekday: 'short' };
              const selectedDay = dateObject?.toLocaleDateString('en-US', options);
              console.log(selectedDay);
              const [year, month, day] = appointment?.date?.split('-').map(Number);
              setSelectedDoctorId(appointment?.doctorId);
              setSelectedPatientId(appointment?.patientId);
              setSearchInputPatient(appointment?.patientName);
              setFormData({ ...formData, reason: appointment?.reason, type: appointment?.type });
              setSearchInput(appointment?.doctorName);
              setSelectedDate(new Date(year, month - 1, day));
              setSelectedTime(appointment?.time);
              setDay(selectedDay)
            });
          } else {
            console.error('No appointment data found for the specified ID');
          }
        }
      )
      handleDoctorClick(location?.state?.doctorId)
      setIsEdit(true);
      document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Edit Appoitment - ${location?.state?.key}`;
    } else {
      document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Schedule Appoitment`;
    }

    if (currentUser === "doctor") {
      const doctor = getCurrentUser()
      handleDoctorClick(doctor.id, doctor.firstname, doctor.lastname);
      setFormData((prevState: any) => ({...prevState, type: "online"}))
    }
  }, [location?.state?.key, currentUser]);


  const getUserspatient = () => {
    getallpatient("undefined", location.state?.hospitalId).then(
      (response) => {
        const patientData = response?.result;
        setPatients(patientData)
        // setFilteredPatient(patientData)
      },
      (error) => {
        const resMessage =
          (error?.response &&
            error?.response.data &&
            error?.response.data.message) ||
          error?.message ||
          error?.toString();
      }
    );
  }

  const getUsersDoctor = async () => {

    getallDoctor("undefined", location.state?.hospitalId).then(
      (response) => {
        const doctorData = response?.result;
        setDoctors(doctorData)
        // setFilteredDoctors(doctorData)
        // console.log('mmmm', response.result)  

      },
      (error) => {
        console.log("Error", error)

        // setLoading(false);
        // setMessage(resMessage);
      }
    );
  }





  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    // console.log('handlechange', e.target.value)
    setFormData({ ...formData, [name]: value });
    setValidationMessages({ ...validationMessages, [name]: '' });
    // setValidationMessages({ ...validationMessages, [name]: '' });
  };


  const handleChangePatient = async (e: any) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchInputPatient(searchValue);
    setSelectedSuggestionIndex(-1);
    if (!searchValue.trim()) {
      setValidationMessages({ ...validationMessages, patientName: 'Please enter a patient name' });
      // setFilteredPatient([]);
    } else {
      setValidationMessages({ ...validationMessages, patientName: '' });
    }

    if (searchValue.trim() === '') {
      // setPatients(filteredPatient); 
      setIsDropdownOpenPatient(false);

      return;
    }

    const [firstName, lastName] = searchValue.split(' ');

    const filtered = patients.filter(
      (patient) =>
        patient.firstName.toLowerCase().includes(firstName) ||
        patient.lastName.toLowerCase().includes(lastName)
    );
    setFilteredPatient(filtered);

    setIsDropdownOpenPatient(true);


  };

  const handleChangeSpeciality = async (e: any) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchInputDoctorSpeciality(searchValue);
    setSelectedSuggestionIndexSpeciality(-1);
    if (!searchValue.trim()) {
      setValidationMessages({ ...validationMessages, speciality: 'Please enter a speciality' });
      // setFilteredPatient([]);
    } else {
      setValidationMessages({ ...validationMessages, speciality: '' });
    }

    if (searchValue.trim() === '') {
      // setPatients(filteredPatient); 
      setIsDropdownOpenDoctorSpeciality(false);

      return;
    }
    console.log("serachvalue", searchValue)
    // const [firstName, lastName] = searchValue.split(' ');

    const array: any = []

    doctors.map((item: any) => {
      console.log("tiemsss", item)

      item.user_form_data?.map((items: any) => {
        if (items.formFieldKey === "specialtyDepartment") {
          let obj = {
            id: item.id,
            firstName: item.firstName,
            lastName: item.lastName,
            speciality: items.formFieldValue
          }
          array.push(obj)
        }
      })

    })
    console.log("array", array)
    setFilterSpecialityDoctor(array)
    const filtered = array.filter(
      (patient: any) =>
        patient.speciality.toLowerCase().includes(searchValue)
      //  ||
      //   patient.lastName.toLowerCase().includes(lastName)
    );
    console.log("filtererer", filtered)
    setFilteredSpeciality(filtered);

    setIsDropdownOpenDoctorSpeciality(true);


  };




  const handleChange = async (e: any) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchInput(searchValue);
    setSelectedSuggestionIndexDoctor(-1);
    if (!searchValue.trim()) {
      setShowCalendar(false);
      setValidationMessages({ ...validationMessages, doctorName: 'Please enter a doctor name' });
    } else {
      setValidationMessages({ ...validationMessages, doctorName: '' });

    }
    if (searchValue.trim() === '') {
      // setPatients(filteredPatient); 
      setIsDropdownOpen(false);
      return;
    }

    const [firstName, lastName] = searchValue.split(' ');
    if (currentUser != "patient") {

      const filtered = doctors.filter(
        (doctor) =>
          doctor.firstName.toLowerCase().includes(firstName) ||
          doctor.lastName.toLowerCase().includes(lastName)
      );
      // setDoctors(filtered);
      setFilteredDoctors(filtered)
      setIsDropdownOpen(true);
    }
    else {
      const filtered = filterSpecialityDoctor.filter(
        (doctor: any) =>
          doctor.firstName.toLowerCase().includes(firstName) ||
          doctor.lastName.toLowerCase().includes(lastName)
      );
      // setDoctors(filtered);
      setFilteredDoctors(filtered)
      setIsDropdownOpen(true);
    }


    // const selectedId = parseInt(e.target.value, 10);
    // console.log('selectedId', e.target.value)
    // setSelectedDoctorId(selectedId);
    // setShowCalendar(true);
    // try {
    //   const response = await getDoctorAppointment(selectedId, limit);
    //   // console.log("vvvv", response.appointmentAvailability);
    //   const mergedObject = response.appointmentAvailability.reduce((result:any, obj: any) => {
    //             const { doc_id, day, from_hours, to_hours,consultation_duration } = obj;
    //             const existingEntry = result.find((entry: { doc_id: any; }) => entry.doc_id === doc_id);

    //             if (existingEntry) {
    //               const existingDay = existingEntry.days.find((d: { day: any; }) => d.day === day);
    //               if (existingDay) {
    //                 existingDay.timeSlots.push({from_hours, to_hours,consultation_duration});
    //               } else {
    //                 existingEntry.days.push({ day, timeSlots: [{from_hours, to_hours,consultation_duration}] });
    //               }
    //             } else {
    //               result.push({ doc_id, days: [{ day, timeSlots: [{from_hours, to_hours,consultation_duration}] }] });
    //             }

    //             return result;
    //           }, []);
    //           // console.log("mergedObject", mergedObject);
    //           mergedObject.forEach((item: any)=>{
    //               scheduleAppointmentDates(item.days,item.doc_id);
    //           })
    //   // setAppointmentTime(response);
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const scheduleAppointmentDates = (workingdays: any[], docId: any) => {
    let i = 0;
    const arr = [];
    while (i < 7) {
      const obj: any = {};
      let today = new Date();
      today.setDate(today.getDate() + i);
      obj.day = today.toString().split(" ")[0];
      obj.date = today.getDate();
      obj.month = today.toString().split(" ")[1];
      obj.monthNumber = today.getMonth() + 1;
      obj.year = today.getFullYear();
      obj.bookTimeString = `${obj.year}-${obj.monthNumber < 10 ? "0" + obj.monthNumber : obj.monthNumber}-${obj.date < 10 ? "0" + obj.date : obj.date}`;
      obj.docId = docId;

      // Initialize an array to store the day's time slots
      const timeStops: { time: any; booked: boolean; }[] = [];

      workingdays.forEach((workingDay: any) => {
        // console.log('workingDay.day', obj.day)
        if (workingDay.day === obj.day) {
          workingDay.timeSlots.forEach((workingTime: any) => {
            const startTime = moment(workingTime.from_hours, 'HH:mm')
            const endTime = moment(workingTime.to_hours, 'HH:mm').subtract(workingTime.consultation_duration, 'minutes');

            if (endTime.isBefore(startTime)) {
              endTime.add(1, 'day');
            }

            while (startTime <= endTime) {
              // const timeobj = (new moment(startTime).format('HH:mm'));
              const timeobj = moment(startTime).format('HH:mm');
              const time = { time: timeobj, booked: false };
              timeStops.push(time);
              startTime.add(workingTime.consultation_duration, 'minutes');
            }
          });
        }
      });


      obj.timing = timeStops;

      arr.push(obj);
      i++
    }
    setAppointmentTime(arr);
    return arr;
  }


  // console.log('setAppointmentTime', appointmentTime)
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (currentUser != "patient") {
      if (!searchInputPatient.trim()) {
        setValidationMessages({ ...validationMessages, patientName: 'Please enter a patient name' });
        return;
      }
    }

    if (!formData.reason.trim()) {
      setValidationMessages({ ...validationMessages, reason: 'Please enter a reason' });
      return;
    }
    if (!searchInput.trim()) {
      setValidationMessages({ ...validationMessages, doctorName: 'Please enter a doctor name' });
      return;
    }
    if (!formData.type) {
      setValidationMessages({ ...validationMessages, type: 'Please select a type' });
      return;
    }
    if (!selectedTime) {
      setValidationMessages({ ...validationMessages, appointmentTime: 'Please select an appointment time' });
      return;
    }

    setLoading(true);

    try {
      let response;


      if (isEdit) {

        let user = getCurrentUser();
        const datenew: any = new Date(selectedDate);
        const mnth: string = ("0" + (datenew.getMonth() + 1)).slice(-2);
        const day: string = ("0" + datenew.getDate()).slice(-2);
        const formattedDate: string = `${datenew.getFullYear()}-${mnth}-${day}`;

        const doctorId = selectedDoctorId;
        const doctorName = searchInput;
        const patientId = currentUser != "patient" ? selectedPatientId : user.id;
        const patientName = currentUser != "patient" ? searchInputPatient : user.firstname + user.lastname;
        const date = formattedDate;
        const time = selectedTime;
        const reason = formData.reason;
        const type = formData.type;
        const status = notificationCount;
        const Id = location.state.key
        response = await doctorBookingFormUpdate(Id, doctorId, doctorName, patientId, patientName, date, time, reason, status, type)
        setLoading(false);
      } else {
        let user = getCurrentUser();
        console.log(
          'uersererer', user
        )
        const doctorId = selectedDoctorId;
        const doctorName = searchInput;
        // const patientId = selectedPatientId;
        // const patientName = searchInputPatient;
        const patientId = currentUser != "patient" ? selectedPatientId : user.id;
        const patientName = currentUser != "patient" ? searchInputPatient : user.firstname + user.lastname;
        const date = selectedDate;
        const time = selectedTime;
        const reason = formData.reason;
        const type = formData.type;
        const status = notificationCount;
        console.log("patinetdetails", patientId, patientName)
        response = await doctorBookingFormCreate(doctorId, doctorName, patientId, patientName, date, time, reason, status, type, location.state?.hospitalId);
        setLoading(false);
      }
      //   const response = await axios.post(`${API_URL_Booking}doctor_booking_form`, bookingtable);
      console.log(response.data);
      if (response.status === 200) {
        // alert(isEdit ? 'Appointment updated' : 'Appointment created');
        let message = isEdit ? 'Appointment updated Successfully' : 'Appointment created Successfully'
        navigate(`/${currentUser}/appointment`);
        setTimeout(() => toast.setShowToast(message, "success"), 1000)
      } else {
        alert('Internal Server Error')
      }
      //    setFormData({
      //     email: '',
      //     firstName: '',
      //     lastName: '',
      //     dob: '',
      //     sex: '',
      // });

    } catch (error) {
      setLoading(false);
      console.error('Error submitting form:', error);
    }
  };

  // const isDateDisabled = (date: Date) => {
  //   const currentDate = new Date();
  //   return date < new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
  // };


  const isDateDisabledDate = (date: Date) => {
    const currentDate = new Date();
    return date < new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) && date?.getTime() != new Date(selectedDate)?.getTime();
    // return date < currentDate;
  };

  const isDateDisabledMonth = (date: Date) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    const targetYear = date.getFullYear();
    const targetMonth = date.getMonth();

    return targetYear === currentYear && targetMonth < currentMonth;
  };

  const isDateDisabledYear = (date: Date) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const targetYear = date.getFullYear();

    return targetYear < currentYear;
  };

  const isDateDisabledDecade = (date: Date): boolean => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const targetYear = date.getFullYear();

    return Math.floor(currentYear / 10) > Math.floor(targetYear / 10);
  };

  const isDateDisabledCentury = (date: Date): boolean => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const targetYear = date.getFullYear();

    return Math.floor(currentYear / 100) > Math.floor(targetYear / 100);
  };

  const tileDisabled = ({ date, view }: { date: Date, view: string }) => {
    switch (view) {
      case 'month':
        return isDateDisabledDate(date)
      // return isDateDisabledMonth(date);
      case 'year':
        return isDateDisabledMonth(date);
      // return isDateDisabledYear(date);
      case 'decade':
        return isDateDisabledYear(date);
      // return isDateDisabledDecade(date);
      case 'century':
        return isDateDisabledDecade(date);
      // return isDateDisabledCentury(date);
      default:
        return isDateDisabledDate(date);
    }
  };




  // const tileDisabled = ({ date }: { date: Date }) => isDateDisabled(date);

  const handleCalenderDateChange = (date: any) => {
    const fromday = date.toString().split(' ')[0];
    const currmonth = date.toString().split(' ')[1];
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const curdate = date.getDate().toString().padStart(2, '0');
    const dates = `${year}-${month}-${curdate}`;
    setSelectedDate(dates);
    setDay(fromday);
    setCurrentDate(curdate);
    setCurrentMonth(currmonth);
    setSelectedTime(null)
  };


  const handleButtonClick = (time: any) => {
    if (!time.trim()) {
      setValidationMessages({ ...validationMessages, appointmentTime: 'Please select an appointment time' });
    } else {
      setValidationMessages({ ...validationMessages, appointmentTime: '' });
    }
    setSelectedTime(time);
  };


  const handlePatientClick = (firstName: string, lastName: string, id: number) => {
    setSearchInputPatient(`${firstName} ${lastName}`)
    setIsDropdownOpenPatient(false);
    setSelectedPatientId(id);
    setSelectedSuggestionIndex(-1);
    // setSelectedPatientIndex(-1);
  }

  const handleDoctorSpecialityClick = (firstName: string, lastName: string, id: number, speciality: string) => {
    setSearchInputDoctorSpeciality(`${speciality}`)
    setIsDropdownOpenDoctorSpeciality(false);
    setSelectedSpecialityId(id);
    setSelectedSuggestionIndexSpeciality(-1);
    // setSelectedPatientIndex(-1);
  }

  // const handleDoctorClick = async ( id: number,firstName?: string, lastName?: string) => {
  //   setSearchInput(`${firstName} ${lastName}`);
  //   console.log('id', id)
  //   setSelectedDoctorId(id);
  //   setShowCalendar(true);
  //   setIsDropdownOpen(false);
  //   setSelectedSuggestionIndexDoctor(-1);
  //   try {
  //     const response = await getDoctorAppointment(id, limit);
  //     // console.log("vvvv", response.appointmentAvailability);
  //     const mergedObject = response.appointmentAvailability.reduce((result:any, obj: any) => {
  //               const { doc_id, day, from_hours, to_hours,consultation_duration } = obj;
  //               const existingEntry = result.find((entry: { doc_id: any; }) => entry.doc_id === doc_id);

  //               if (existingEntry) {
  //                 const existingDay = existingEntry.days.find((d: { day: any; }) => d.day === day);
  //                 if (existingDay) {
  //                   existingDay.timeSlots.push({from_hours, to_hours,consultation_duration});
  //                 } else {
  //                   existingEntry.days.push({ day, timeSlots: [{from_hours, to_hours,consultation_duration}] });
  //                 }
  //               } else {
  //                 result.push({ doc_id, days: [{ day, timeSlots: [{from_hours, to_hours,consultation_duration}] }] });
  //               }

  //               return result;
  //             }, []);
  //             // console.log("mergedObject", mergedObject);
  //             mergedObject.forEach((item: any)=>{
  //                 scheduleAppointmentDates(item.days,item.doc_id);
  //             })
  //     // setAppointmentTime(response);
  //   } catch (error) {
  //     console.log(error);
  //   }

  // };


  const handleDoctorClick = async (id: number, firstName?: string, lastName?: string) => {
    try {
      setSearchInput(`${firstName} ${lastName}`);
      setSelectedDoctorId(id);
      setShowCalendar(true);
      setIsDropdownOpen(false);
      setSelectedSuggestionIndexDoctor(-1);
      fetchDoctorAppointment(id);
      fetchPreviousBookings(id);
      handleCalenderDateChange(new Date());
    } catch (error) {
      console.error(error);
    }
  };

  const handleKeyDowndoctor = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case 'ArrowDown':
        handleArrowDown();
        break;
      case 'ArrowUp':
        handleArrowUp();
        break;
      case 'Enter':
        handleEnter();
        break;
      default:
        break;
    }
  };

  const handleArrowDown = () => {
    setSelectedSuggestionIndexDoctor(prevIndex => Math.min(prevIndex + 1, filteredDoctors.length - 1));
  };

  const handleArrowUp = () => {
    setSelectedSuggestionIndexDoctor(prevIndex => Math.max(prevIndex - 1, 0));
  };

  const handleEnter = async () => {
    if (selectedSuggestionIndexDoctor !== -1) {
      const selectedDoctor = filteredDoctors[selectedSuggestionIndexDoctor];
      setSelectedDoctorId(selectedDoctor.id);
      setSearchInput(`${selectedDoctor.firstName} ${selectedDoctor.lastName}`);
      setShowCalendar(true);
      setIsDropdownOpen(false);
      setSelectedSuggestionIndexDoctor(-1);
      fetchPreviousBookings(selectedDoctor.id);
      handleCalenderDateChange(new Date())
      fetchDoctorAppointment(selectedDoctor.id);
    }
  };

  const fetchDoctorAppointment = async (id: number) => {
    try {
      const response = await getDoctorAppointment(id, limit);
      const mergedObject = mergeAppointmentAvailability(response.appointmentAvailability);
      if (mergedObject?.length > 0) {
      mergedObject.forEach((item: any) => scheduleAppointmentDates(item.days, item.doc_id));
      } else {
        setAppointmentTime([])
      }
    } catch (error) {
      console.error(error);
    }
  };

  async function fetchPreviousBookings(id: any) {
    const response = await getBookingsOfOneDoctor(id);
    // setPreviousBookings(response.data);
    const bookings = response.data
    bookings.forEach((item: any) => {
      const splittedTime = item.time.split(":");
      let selectedDateTime = new Date(item.date);
      selectedDateTime.setHours(Number(splittedTime[0]));
      selectedDateTime.setMinutes(Number(splittedTime[1]));
      item.timeMs = selectedDateTime.getTime()
    })
    setPreviousBookings(bookings);
  }

  const mergeAppointmentAvailability = (appointmentAvailability: any[]) => {
    return appointmentAvailability.reduce((result, obj) => {
      const { doc_id, day, from_hours, to_hours, consultation_duration } = obj;
      const existingEntry = result.find((entry: { doc_id: any; }) => entry.doc_id === doc_id);

      if (existingEntry) {
        const existingDay = existingEntry.days.find((d: { day: any; }) => d.day === day);
        if (existingDay) {
          existingDay.timeSlots.push({ from_hours, to_hours, consultation_duration });
        } else {
          existingEntry.days.push({ day, timeSlots: [{ from_hours, to_hours, consultation_duration }] });
        }
      } else {
        result.push({ doc_id, days: [{ day, timeSlots: [{ from_hours, to_hours, consultation_duration }] }] });
      }

      return result;
    }, []);
  };





  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        setSelectedSuggestionIndex((prevIndex) => (prevIndex < filteredPatient.length - 1 ? prevIndex + 1 : prevIndex));
        break;
      case 'ArrowUp':
        event.preventDefault();
        setSelectedSuggestionIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
        break;
      case 'Enter':
        event.preventDefault();
        if (selectedSuggestionIndex !== -1) {
          setSearchInputPatient(`${filteredPatient[selectedSuggestionIndex].firstName} ${filteredPatient[selectedSuggestionIndex].lastName}`);
          setIsDropdownOpenPatient(false);
          setSelectedPatientId(filteredPatient[selectedSuggestionIndex].id);
          setSelectedSuggestionIndex(-1);
        }
        break;
      default:
        break;
    }
  };

  function disableTime(time: string) {
    const splittedTime = time.split(":");
    let selectedDateTime = new Date(selectedDate);
    selectedDateTime.setHours(Number(splittedTime[0]));
    selectedDateTime.setMinutes(Number(splittedTime[1]));
    // console.log("selectedDateTime", currentTime.getTime(), selectedDateTime.getTime())
    if (currentTime.getTime() > selectedDateTime.getTime()) {
      return true;
    } else {
      let disable = false;
      previousBookings.forEach((item: any) => {
        if (item.date == selectedDate && item.time == time) {
          disable = true;
        }
      });
      return disable;
    }
  }

  return (
    <Row>
      <div className="panel_top_section">
        <h1>{isEdit ? "Edit Appointment" : "Schedule an Appointment"}</h1>
        {location?.state?.key &&
          <span className="textgreen poppins-500"><i className="bi bi-chevron-right"></i>{searchInputPatient} - #{location?.state?.key}</span>
        }
        {/* <h1>Appointments</h1> <span className="textgreen poppins-500"><i className="bi bi-chevron-right"></i> Schedule an Appointment </span>  */}
      </div>
      <Col>
        <div className="row m-0 schedule_payment col-lg-11 mt-4 pt-2">
          <div className="col-xl-6 p-1">
            <h2 className="fs-5 poppins-600 mb-3">Schedule Details</h2>
            <div className="schedule_payment_inner bg-white radius_15 px-4 py-4">
              {/* <div className="row_1 mt-3 px-lg-3 position-relative">
                <span className="poppins-600 textgreen">Speciality</span>

                <input
                  type="text"
                  value={searchInputDoctorSpeciality}
                  onChange={handleChangeSpeciality}
                  onKeyDown={handleKeyDown}
                  className="form-control select_drop_down shadow-none text_gray rounded-0 border-0 border-bottom mb-3"
                />
                <div className="text-danger">{validationMessages.speciality}</div>



                {isDropdownOpenDoctorSpeciality && (
                  <div>
                    {filteredSpeciality.length === 0 ? (
                      <div className="text_gray">No record found</div>
                    ) : (
                      <ul className="patient-dropdown open pb-2 rounded-bottom border">
                        {filteredSpeciality.map((patient, index) => (
                          <li
                            key={index}
                            className={index === selectedSuggestionIndexSpeciality ? 'selected' : ''}
                            style={{
                              padding: '10px 20px',
                              cursor: 'pointer',
                              backgroundColor: index === selectedSuggestionIndexSpeciality ? '#b0d7db' : ''
                            }}
                            onClick={() => handleDoctorSpecialityClick(patient.firstName, patient.lastName, patient.id, patient.speciality)}
                          >
                            {`${patient.speciality} `}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )}



              </div> */}
              {currentUser != "patient" &&
                <div className="row_1 mt-3 px-lg-3 position-relative">
                  <span className="poppins-600 textgreen">Patient</span>

                  <input
                    type="text"
                    value={searchInputPatient}
                    onChange={handleChangePatient}
                    onKeyDown={handleKeyDown}
                    className="form-control select_drop_down shadow-none text_gray rounded-0 border-0 border-bottom mb-3"
                  />
                  <div className="text-danger">{validationMessages.patientName}</div>



                  {isDropdownOpenPatient && (
                    <div>
                      {filteredPatient.length === 0 ? (
                        <div className="text_gray">No record found</div>
                      ) : (
                        <ul className="patient-dropdown open pb-2 rounded-bottom border">
                          {filteredPatient.map((patient, index) => (
                            <li
                              key={index}
                              className={index === selectedSuggestionIndex ? 'selected' : ''}
                              style={{
                                padding: '10px 20px',
                                cursor: 'pointer',
                                backgroundColor: index === selectedSuggestionIndex ? '#b0d7db' : ''
                              }}
                              onClick={() => handlePatientClick(patient.firstName, patient.lastName, patient.id)}
                            >
                              {`${patient.firstName} ${patient.lastName}`}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  )}



                </div>
              }
              <div className="row_1 px-lg-3">
                <span className="poppins-600 textgreen">Reasons</span>
                <input name="reason" value={formData.reason} onChange={handleInputChange} type="text" placeholder="" className="form-control shadow-none rounded-0 border-0 border-bottom mb-3" id="reason" />
                <div className="text-danger">{validationMessages.reason}</div>
              </div>
              {currentUser !== "doctor" &&
              <div className="row_1 mb-4 px-lg-3 position-relative">
                <span className="poppins-600 textgreen">Doctor</span>


                <input
                  type="text"
                  value={searchInput}
                  onChange={handleChange}
                  // onKeyDown={(e) => handleKeyDownDoctor( e, id)}
                  onKeyDown={handleKeyDowndoctor}
                  className="form-control select_drop_down shadow-none text_gray rounded-0 border-0 border-bottom mb-3"
                // disabled={location?.state?.doctorId ? true : false}
                />
                <div className="text-danger">{validationMessages.doctorName}</div>


                {isDropdownOpen && (
                  <div>
                    {filteredDoctors.length === 0 ? (
                      <div className="text_gray">No record found</div>
                    ) : (
                      <ul className="doctor-dropdown open pb-2 rounded-bottom border">
                        {filteredDoctors.map((doctor, index) => (
                          <li
                            key={index}
                            className={index === selectedSuggestionIndexDoctor ? 'selected' : ''}
                            style={{
                              padding: '10px 20px',
                              cursor: 'pointer',
                              backgroundColor: index === selectedSuggestionIndexDoctor ? '#b0d7db' : ''
                            }}
                            onClick={() => handleDoctorClick(doctor.id, doctor.firstName, doctor.lastName)}
                          >
                            {`${doctor.firstName} ${doctor.lastName}`}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )}
              </div>
              }

              <div className="row_1 mb-4 px-lg-3">
                <span className="poppins-600 textgreen">Type</span>
                <select name="type" value={formData.type} onChange={handleInputChange} className="form-select select_drop_down shadow-none text_gray rounded-0 border-0 border-bottom mb-3 clickable" aria-label="Default select example">
                  <option>Select</option>
                  <option value='offline'>In Clinic</option>
                  <option value='online'>Telehealth</option>

                </select>
                <div className="text-danger">{validationMessages.type}</div>
              </div>
            </div>
          </div>
          {/* calendar */}
          {showCalendar && (
            <div className="col-xl-6 p-1 calender_block">
              <h2 className="fs-5 poppins-600 mb-3">Select a Date</h2>

              <Calendar
                onChange={handleCalenderDateChange}
                value={selectedDate}
                tileDisabled={tileDisabled}
              />
              {/* <div className="text-danger">{validationMessages.calendar}</div> */}
              {/* {validationMessages.calendar && <div className="text-danger">{validationMessages.calendar}</div>} */}
            </div>
          )}
          {/* Select a Time */}
          {/* {selectedDoctorId && */}
          <div className="col-xl-12 p-1 mt-4">
            <h4 className="fs-5 poppins-600 mb-3">Schedule Details</h4>
            <div className="date_btn bg-white radius_15 px-4 py-4">

              {/* {appointmentTime?.timingarr?.map((item: any) => (
                    item.day === day && item.bookTimeString === selectedDate ? (
                    item.timing.map((time: any) => (
                      <button type="button" key={time.time} value={time.time} 
                      className={time.time === selectedTime ? 'active' : ''}
                      onClick={() => handleButtonClick(time.time, item.day, item.date, item.month )}
                      >{time.time}</button>
                    ))
                  ) : null
              ))} */}

              {/* {appointmentTime?.map((item: any) => (
                    item.day === day && item.bookTimeString <= selectedDate ? (
                      item.timing.length > 0 ? ( 
                    item.timing.map((time: any, index: number) => (
                      <button type="button" key={index} value={time.time} 
                      className={time.time === selectedTime ? 'active' : ''}
                      onClick={() => handleButtonClick(time.time)}
                      >{time.time}</button>
                    ))
                    ) : (
                      <div style={{ color: 'red' }}>No available time slots</div> // Message when there are no time slots
                  )
                    ) : null
              ))} */}

              {isEdit ? (
                appointmentTime?.length > 0 ? (
                appointmentTime?.map((item: any) => (
                  item.day === day ? (
                    item.timing.length > 0 ? (
                      item.timing.map((time: any, index: number) => (
                        <button
                          type="button"
                          key={index}
                          value={time.time}
                          className={time.time === selectedTime ? 'active' : disableTime(time.time) ? "time-disabled" : ""}
                          onClick={() => handleButtonClick(time.time)}
                          disabled={disableTime(time.time)}
                        >
                          {time.time}
                        </button>
                      ))
                    ) : (
                        <div style={{ color: 'red' }}>No time slots available.</div>
                    )
                  ) : null
                  )))
                  :
                  <div style={{ color: 'red' }}>
                    {selectedDoctorId ? "No time slots available." : "Please select the doctor to view their time slots."}
                  </div>
              ) : (
                appointmentTime?.length > 0 ? (
                appointmentTime?.map((item: any) => (
                  item.day === day && item.bookTimeString <= selectedDate ? (
                    item.timing.length > 0 ? (
                      item.timing.map((time: any, index: number) => (
                        <button
                          type="button"
                          key={index}
                          value={time.time}
                          className={time.time === selectedTime ? 'active' : disableTime(time.time) ? "time-disabled" : ""}
                          onClick={() => handleButtonClick(time.time)}
                          disabled={disableTime(time.time)}
                        >
                          {time.time}
                        </button>
                      ))
                    ) : (
                      <div style={{ color: 'red' }}>No available time slots</div>
                    )
                  ) : null
                  )))
                  :
                  <div style={{ color: 'red' }}>
                    {selectedDoctorId ? "No time slots available." : "Please select the doctor to view their time slots."}
                  </div>
              )}




              {/* <button type="button">08.00 AM</button>
                      <button type="button">08.00 AM</button>
                      <button type="button">08.00 AM</button>
                      <button className="active" type="button">09.30 AM</button>
                      <button type="button">10.30 AM</button>
                      <button type="button">10.30 AM</button>
                      <button type="button">11.00 AM</button> */}
            </div>
            <div className="text-danger">{validationMessages.appointmentTime}</div>
          </div>
          {/* } */}

          <div className="row col-xl-12 align-items-center p-1 m-0 mt-2 ">
            <p className="col-lg-6 fs-5 p-0 d-inline-block m-0">
              {(currentMonth && currentDate && day && selectedTime) &&
                `You selected ${currentMonth} ${currentDate}th ${day} at ${selectedTime}`
              }
            </p>

            {/* <p className="col-lg-6 fs-5 p-0 d-inline-block m-0">You selected February 14th Wednesday at 09.30 AM.</p> */}
            <div className="form_bottom_btn col-lg-6 p-0 d-flex justify-content-end pt-2">
              <button className="delete_btn" type="button"
                onClick={(e) => {
                  navigate(`/${currentUser}/appointment`)
                }}
              >Cancel</button>
              {/* <button className="btn_gradian" type="button" onClick={handleSubmit}  >SCHEDULE AN APPOINTMENT</button> */}
              <button className="btn_gradian" type="button"
                onClick={(e) => {
                  handleSubmit(e);
                  //  navigate("/hospital/appointment")
                }}
              >
                {isEdit ? "UPDATE AN APPOINTMENT" : "SCHEDULE AN APPOINTMENT"}
                <span className="ms-2">
                  {loading &&
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  }
                </span>
              </button>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ScheduleAppointment;
