import * as AuthService from "../../../services/auth.service";

import React, { useContext, useEffect, useState } from 'react'
import {createformdata, getformdetail} from '../../../services/form-service';
import { useLocation, useNavigate } from "react-router-dom";

import { RoleContext } from '../../../utils/contexts/RoleContext';
import axios from 'axios';
import constants from "../../../config/constants";
import { capitalizeFirstLetter } from "../../../utils/utils";

const FormFields = () => {
  const location = useLocation();
  const navigate=useNavigate();
  const [dragAllData, setDragAllData] = useState<any>([]);
  const [fieldvalue, setFieldvalue] = useState<any> ([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const currentUser = useContext(RoleContext);
  const [currentuser,setCurrentUser]=useState<String>("")

  
  useEffect(()=>{
    const value = AuthService.getCurrentUser();
    setCurrentUser(value?.role)
    formdisplay()
    // formalldata()
    document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Form Display`;
  },[currentUser])
  const formdisplay = async() => {

    getformdetail(location?.state?.key).then(
      (data) => {
       
    setDragAllData(data)
        
          
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
      }
    );
  
   
  };


  const handleChange = (event:any,i:any,formId:any) => {
  
    const { name, value, id, field_key } = event.target; 
    setFieldvalue({ ...fieldvalue,[name]: {value: value, id: id, fieldId: i,form_id:formId} });
 
  };

 
  
  const handleSubmit = async () => {
    // event.preventDefault();
  
    // const response = await axios.post(`${endPoint}dragdrop/user_form_data`, fieldvalue);

    createformdata(fieldvalue).then(
      (data) => {
        navigate(`/${currentUser}/manageform`)
       
    // setDragAllData(data)
 
        
          
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
      }
    );
  };

  
  return (
    // <form onSubmit={onsubmit}>
    <div className='form_container'>
    {dragAllData.map((item:any, i:any) => {
      // console.oog("itemssss",item)
      return(
         <div key={i} className='form_label'>
         <div className='form_field'> 
         {item.type === 'radio' ? (
                <div >
                <label>{item.label}</label>
                <div className='radio_container'>
                {item.defaultValue.map((data:any,) => {
                  return(
                    <div >
                    <input type={item.type} className='form_radio'/> 
                  <span>{data.label}</span> 
                    </div>
                  )
                })
                }
                </div>
                </div>
             
              ) : ( 
                <div>
          
          {item.type === 'textarea' ? (
            <div>
            <div>
            <label>{item.label}</label>
            </div>
            <textarea  data-type={item.type} className='form_textarea' name = {item.key} onChange={(event)=>handleChange(event,i,item.form_id)} />
            </div>
            ) : (
              <div> 
                        {item.type === 'button' ? (
                          <div className='button_box'> 
                          <input id={item.id} type={item.label} className='form_button'
                          
                           onClick={() => { 
                            handleSubmit()
                          }}
                          /> 
                        </div>
                        ) : (
                          <div>
                          <div>
                          <label>{item.label}</label>
                          </div>
                          <input id={item.id} list={item.field_key} type={item.type} className='form_input' name = {item.key}  value={item.value} 
                          onChange={(e)=>{handleChange(e,item.field_key,item.form_id)}} /> 
                          </div>
                          )}</div>
                         
                      )}
                  </div>
                  ) }
         </div>
         </div>
      )
    })}
</div>
// </form>

  )
}

export default FormFields



  