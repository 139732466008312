
import locationImg from "../../../assets/images/location/location.png";
import link from "../../../assets/images/location/link.png";
import call from "../../../assets/images/location/call.png";
import map from "../../../assets/images/location/map.jpg";
import {
  Row,
  Col
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { RoleContext } from "../../../utils/contexts/RoleContext";
import { useContext, useState, useEffect } from 'react';
import { getLocations, deleteLocation } from "../../../services/location-service";
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import DeleteModal from "../../../components/DeleteModal";
import Pagination from "../../../components/Pagination";
import { decidePages } from "../../../utils/utils";
import Spinner from 'react-bootstrap/Spinner';
import CustomToolTip from "../../../components/CustomToolTip";
import { useCookie } from "../../../components/useCookie";
import constants from "../../../config/constants";
import { capitalizeFirstLetter } from "../../../utils/utils";


export default function Locations() {
  const [view, setView] = useState('grid');
  const [limit, setLimit] = useState(10);
  const [locations, setLocations] = useState([]);
  const [more, setMore] = useState('');
  const [deleteId, setDeleteId] = useState("");
  const [showsearch, setShowSearch] = useState(false);
  const [search, setSearch] = useState('');
  const [locationsCopy, setLocationsCopy] = useState([]);
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [pages, setPages] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [recordsToBeDeleted, setRecordsToBeDeleted] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const [displayView, setDisplayView] = useCookie("displayView");

  const navigate = useNavigate();
  const currentUser = useContext(RoleContext);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBrwbVt6abHnoZjzNWYWhYhQLwL45mO2hA",
    nonce: "map",
    libraries: ["places"],
  });

  // const handleScroll = () => {

  //   const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight

  //   if (bottom) {
  //     setLimit(limit + 10)
  //   }
  // };


  useEffect(() => {
    getHospitalLocations(pageNumber);
    setPageNumber(1);
    // window.addEventListener('scroll', handleScroll, {
    //   passive: true
    // });

    // return () => {
    //   window.removeEventListener('scroll', handleScroll);
    // };
  }, [view]);

  useEffect(() => {
    setView(displayView);
  }, [displayView]);

  useEffect(() => {
    document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Locations`;
  }, [currentUser])

  async function getHospitalLocations(page?: number) {
    setLoading(true);
    const pageCount = page != undefined ? page : 1;
    const locations = await getLocations(pageCount);
    if (locations?.success) {
      const pages = decidePages(locations?.data?.count);
    setPages(pages);
    setPageNumber(pageCount)
      setLocations(locations?.data?.rows);
      setLocationsCopy(locations?.data?.rows);
    }
    setLoading(false);
  }

  function handleMore(id: string) {
    setMore(id);
  }

  function navigateToEdit(id: string) {
    // navigate(`/${currentUser}/edit-location`, { state: { id: id } })
    navigate(`/${currentUser}/edit-location/${id}`, { state: { id: id } });

  }

  async function deleteOneLocation(id: string) {
    const data = await deleteLocation(id);
    if (data.success) {
      getHospitalLocations();
    }
  }

  function handleTrash(id: string) {
    setShowDeleteModal(true);
    setDeleteId(id)
  }

  function onSearchChange(e: any) {
    setSearch(e.target.value);
    const values = locationsCopy.filter((items: any) => Object.values(items).join(" ").toLowerCase().match(e.target.value.toLowerCase()));
    setLocations(values);
  }

  function handleRadioChange(item: any) {
    const deleteArray = [...recordsToBeDeleted];
    const check = deleteArray.find(((element: any) => element.id == item.id));
    if (check === undefined) {
      setRecordsToBeDeleted((prevState: any) => ([...prevState, item]))
    } else {
      setRecordsToBeDeleted((prevState: any) => (prevState.filter((element: any) => element.id != item.id)));
    }
  }

  function decideStatus(item: any) {
    let decideCheck = false
    recordsToBeDeleted.forEach((element: any) => {
      if (element.id == item.id) {
        decideCheck = true;
        return false;
      }
    })
    return decideCheck;
  }

  return (
    <>
      {!loading ?
        <Row>
          <div className="panel_top_section">
            <h1>Locations</h1>
            <div className="grid_icons d-flex gap-3 align-items-center float-end">
              <button
                type="button"
                className="btn_gradian"
                onClick={() => {
                  if (currentUser === 'admin') {
                    navigate(`/${currentUser}/hospital`, { state: { what: 'location' } })
                  } else {
                    navigate(`/${currentUser}/add-location`)
                  }
                }}
              >
                + Add New Location
              </button>
              <div className="grid_list_icons d-flex align-items-center gap-2 nav nav-pills1" id="pills-tab" role="tablist">
                <span className="list_icons fs-2 nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true"
                  onClick={() => {
                    setView("list");
                    setDisplayView("list");
                  }}
                >
                  <i className="bi bi-list-ul"></i>
                </span >
                <span className="grid_icons fs-3 nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false"
                  onClick={() => {
                    setDisplayView("grid");
                    setView("grid")
                  }}
                >
                  <i className="bi bi-grid-fill"></i>
                </span>
                {recordsToBeDeleted.length > 0 &&
                  <button
                    type="button"
                    className="delet_patiens"
                    onClick={() => setShowDeleteModal(true)}
                  >
                    <i className="bi bi-trash"></i>
                  </button>
                }
                {showsearch &&
                  <span>
                    <input
                      type="text"
                      className="form-control"
                      id="search_input"
                      onChange={(e) => onSearchChange(e)}
                      value={search}
                      placeholder="Search..."
                    />
                  </span>
                }
                <span
                  className="filter_icons fs-3"
                  onClick={() => setShowSearch(!showsearch)}
                >
                  <i className="bi bi-funnel-fill" />
                </span>
              </div>

            </div>
          </div>
          <Col className="mt-3 mb-4">
                {view === 'grid' ?
                  <div className="add_location col-sm-12 py-4 px-lg-2 mt-2">
                    <div className="row m-0">
                      {locations?.map((location: any) => {
                        return (
                          <div className="col-lg-6 p-1 pb-2">
                            <div className="inner_location float-start w-100 bg-white box_shadow p-3 pt-4 radius_15">
                              <div className="three_dots float-end position-relative">
                                <button
                                  className="bg-transparent border-0 float-end p-0 exclamation_btn"
                                  type="button"
                                  onClick={() => handleMore(location.id)}
                                >
                                  <i className="bi bi-three-dots-vertical"></i>
                                </button>
                                {/* {more === location.id && */}
                                <ul className="drop_list m-0 p-0 bg-white p-2  text-left">
                                  <li className="clickable" onClick={() => navigateToEdit(location.id)}><a >Edit</a></li>
                                  <li className="clickable" onClick={() => handleTrash(location.id)}><a>Delete</a></li>
                                </ul>
                                {/* } */}
                              </div>
                              <h2 className="d-inline-block fs-5 textgreen poppins-600 mb-3">{location.locationName}</h2>
                              <div className="float-start w-full location_detail_grid">
                                <ul className="float-start w-100 p-0 m-0">
                                  <li className="w-100 float-start d-block list-unstyled py-1">
                                    <span className="float-start"><img src={locationImg} alt="award" /></span>
                                    <div className="col-sm-10 right_box float-start px-3">
                                      <h5 className="poppins-600 fs-6 dark_blue mb-1">Address</h5>
                                      <p className="text_sm mb-1">{location.streetAddress}</p>
                                    </div>
                                  </li>
                                  <li className="w-100 float-start d-block list-unstyled py-1">
                                    <span className="float-start"><img src={call} alt="call" /></span>
                                    <div className="col-sm-10 right_box float-start px-3">
                                      <h5 className="poppins-600 fs-6 dark_blue mb-1">Phone </h5>
                                      <a className="text_sm mb-1 text-decoration-none dark_blue" href="tel:(713) 555-1234">{location.phoneNumber}</a>
                                    </div>
                                  </li>
                                  <li className="col-lg-12 col-xl-6 float-start d-block list-unstyled py-1">
                                    <span className="float-start"><img src={link} alt="mail" /></span>
                                    <div className="col-sm-10 right_box float-start px-3">
                                      <h5 className="poppins-600 fs-6 dark_blue mb-1">Website</h5>
                                      <a className="text_sm mb-1 text-decoration-none textgreen" href={`mailto:${location.emailAddress}`}>{location.emailAddress}</a>
                                    </div>
                                  </li>
                                  <li className="col-sm-12 col-lg-12 col-xl-6 float-start d-block list-unstyled py-1">
                                    <button
                                      type="button"
                                      className="btn_gradian veiw_location float-xl-end m-0"
                                      onClick={() =>
                                        // navigate(`/${currentUser}/location-detail`, { state: { id: location.id } })
                                        navigate(`/${currentUser}/location-detail/${location?.id}`, { state: { id: location.id } })
                                      }
                                    >View Location</button>
                                  </li>
                                </ul>
                              </div>
                              {/* map */}
                              <div className="location_map float-start w-100 mt-3">
                                {isLoaded &&
                                  <GoogleMap
                                    mapContainerStyle={{ width: '100%', height: '300px' }}
                                    center={{
                                      lat: location.latitude,
                                      lng: location.longitude
                                    }}
                                    zoom={10}
                                  // onLoad={onLoad}
                                  // onUnmount={onUnmount}
                                  >
                                    { /* Child components, such as markers, info windows, etc. */}
                                    <></>
                                  </GoogleMap>
                                }

                                {/* <img width="100%" height="200" className="img-fluid w-100 radius_15" src={map} alt="map" /> */}

                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    {pages?.length > 1 &&
                      <div>
                        <Pagination
                          pages={pages}
                          currentPage={pageNumber}
                          onClickPageNumber={(page: any) => {
                            setPageNumber(page);
                            getHospitalLocations(page);
                          }}
                          view={view}
                        />
                      </div>
                    }
                  </div>
                  :
                  <div className="row d-flex table-responsive m-0">
                    <table >
                      <thead>
                        <tr className="list_head_new text-start bg_green_table">
                          <th className="text_base px-2 px-xl-3 py-4"><span className="d-block float-start px-1 bg-transparent">
                            <input
                              className="float-start form-check-input"
                              type="checkbox"
                              name="name"
                              id="name"
                              checked={recordsToBeDeleted.length === locations.length ? true : false}
                              onChange={() => {
                                if (recordsToBeDeleted.length === locations.length) {
                                  setRecordsToBeDeleted([]);
                                } else {
                                  const data = [...locations];
                                  setRecordsToBeDeleted(data);
                                }
                              }}
                            />
                          </span>
                            <label className="p-0 bg-transparent  px-3" htmlFor="name">Location Name</label>
                          </th>
                          <th className="text_base px-2 px-xl-3 py-4">Address</th>
                          <th className="text_base px-2 px-xl-3 py-4">Phone</th>
                          <th className="text_base px-2 px-xl-3 py-4">Website </th>
                          <th className="text_base px-2 px-xl-3 py-4">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                    {locations?.length > 0 ?
                      <>
                        {locations?.map((location: any) => {
                          return (
                            <tr
                              className="list_detail_new text-start bg-white border-bottom cursor-pointer"
                              onClick={() =>
                                navigate(`/${currentUser}/location-detail/${location?.id}`, { state: { id: location.id } })
                              }
                            >
                              <td className="text_base px-2 px-xl-3 py-4">
                                <div className="d-flex">
                                  <span className="px-1 d-block float-start">
                                    <input
                                      className="float-start form-check-input"
                                      type="checkbox"
                                      name="name"
                                      onChange={() => {
                                        handleRadioChange(location);
                                      }}
                                      checked={decideStatus(location)}
                                    />
                                  </span>
                                  <label className="poppins-500" htmlFor="name2">{location.locationName}</label>
                                </div>
                              </td>
                              <td className="text_base px-2 px-xl-3 py-4"> <span className="col-lg-12 col-xl-6">{location.streetAddress}</span></td>
                              <td className="text_base px-2 px-xl-3 py-4"> <a className="d-block text_base textgreen text-decoration-none" href={`tel:${location.phoneNumber}`}>{location.phoneNumber}</a></td>
                              <td className="text_base px-2 px-xl-3 py-4"> <a className="d-block text_base textgreen text-decoration-none" href="#">{location.emailAddress}</a></td>
                              <td 
                              className="text_base px-2 px-xl-3 py-4"
                              onClick={(e) => e.stopPropagation()}
                              >
                                {more !== location.id ?
                                  <span
                                    className="dost_btn bg_gray_light text-center py-1 fs-5 px-2 rounded-circle"
                                    onClick={() => handleMore(location.id)}
                                  >
                                    <i className="bi bi-three-dots-vertical"></i>
                                  </span>
                                  :
                                  <span className="action" >
                                    <CustomToolTip title="Edit">
                                    <button
                                      className="edit_btn"
                                      onClick={() => navigateToEdit(location.id)}
                                    >
                                      <i className="bi bi-pen"></i>
                                    </button>
                                    </CustomToolTip>
                                    <CustomToolTip title="Delete">
                                    <button
                                      className="delete_btn_act"
                                      onClick={() => handleTrash(location.id)}
                                    >
                                      <i className="bi bi-trash"></i>
                                    </button>
                                    </CustomToolTip>
                                    <CustomToolTip title="Info">
                                    <button 
                                    className="exclamation_btn" 
                                    onClick={() =>
                                      // navigate(`/${currentUser}/location-detail`, { state: { id: location.id } })
                                      navigate(`/${currentUser}/location-detail/${location?.id}`, { state: { id: location.id } })
                                    }
                                    >
                                      <i className="bi bi-exclamation-circle"></i>
                                      {/* <ul className="drop_list m-0 p-0 bg-white p-2  text-left">
                                    <li><a>{location.locationName}</a></li>
                                  </ul> */}
                                    </button>
                                    </CustomToolTip>
                                  </span>
                                }

                              </td>
                            </tr>
                          )
                        })}
                      </>
                      :
                      <tr className="list_detail">
                        <td colSpan={10}>No data found</td>
                      </tr>
                    }
                      </tbody>
                      {pages?.length > 1 &&
                        <tfoot>
                          <Pagination
                            pages={pages}
                            currentPage={pageNumber}
                            onClickPageNumber={(page: any) => {
                              setPageNumber(page);
                              getHospitalLocations(page);
                            }}
                          />
                        </tfoot>
                      }
                    </table>
                  </div>
                }

            <DeleteModal
              show={showDeleteModal}
              handleYes={() => {
                if (deleteId) {
                  deleteOneLocation(deleteId);
                  setDeleteId('');
                  setRecordsToBeDeleted((prevState: any) => (prevState.filter((item: any) => item.id != deleteId)))
                } else {
                  recordsToBeDeleted.forEach((item: any) => {
                    deleteOneLocation(item.id);
                  })
                }
                setShowDeleteModal(false)
              }}
              handleClose={() => setShowDeleteModal(false)}
              body="Do you really want to delete this loaction?"
              head="Delete Location"
            />
          </Col>
        </Row>
        :
        <div className='m-5 justify-content-center d-flex'>
          <Spinner />
        </div>
      }
    </>
  );
};  