import {
    Row,
    Col,
} from "reactstrap";

import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getOneDoctor, } from "../../../services/doctor-service";
import { createprescription, getonprescription } from "../../../services/prescription-service";
import { RoleContext, AlertContext } from "../../../utils/contexts/RoleContext";
import { isArrayTypeNode } from "typescript";
import { getallpatient } from "../../../services/patient-service";
import { createOneRefillrequest, getOneRefill, deleteRefill, sendRefill } from "../../../services/refillRequest-service";
import { createPharmacyDetails } from "../../../services/refillRequest-service";
import constants from "../../../config/constants";
import { capitalizeFirstLetter, longDateGenerator } from "../../../utils/utils";
import DeleteModal from "../../../components/DeleteModal";

const RefilDetail = () => {

    const location = useLocation();
    const [medicationDetail, setMedicationDetail] = useState<any>([]);
    const [patients, setPatients] = useState<any>({})
    const [dob, setDob] = useState<any>("");
    const [disease, setDisease] = useState<any>("");
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [refillStatus, setRefillStatus] = useState(0);

    const navigate = useNavigate();
    const currentUser = useContext(RoleContext);
    const toast: any = useContext(AlertContext);
    const { refillId } = useParams()

    useEffect(() => {
        // if (location?.state?.key) {
        //     setMedicationDetail(location?.state?.key)
        //     getDoctor(location?.state?.patientId);
        // }
        fetchOneRefill();
        document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Refill Detail`;
    }, [currentUser]);

    async function fetchOneRefill() {
        const response = await getOneRefill(refillId);
        if (response?.success) {
            if (response?.data?.patientId) {
                getDoctor(response?.data?.patientId);
            }
            setMedicationDetail(response?.data?.refill_medications);
            setDisease(response?.data?.booking_appointment?.reason)
            setRefillStatus(response?.data?.status);
        }
    }

    async function proceedRefill() {
        const response = await sendRefill(refillId, {});
        if (response?.success) {
            navigate(`/${currentUser}/refill-request`)
            toast.setShowToast(response?.message, "success")
        } else {
            toast.setShowToast("Something went wrong. Please try again later!", "danger")
        }
    }

    const getDoctor = async (value: any) => {
        // console.log("location?.state?.key", location?.state?.key)
        getOneDoctor(value).then(
            (data) => {

                const allDoctor = data?.allDoctors;


                // console.log("doctor", allDoctor);
                const userForm: any = {};
                // getCurrentDoctor(data.allDoctors)
                let array: any = [];
                let document: any = [];
                setPatients(allDoctor)
                allDoctor?.userdata?.map((item: any) => {

                    if (item?.fieldKey === "dateOfBirth") {
                        // obj.dob = item.JSON.parse(item.fieldValue)
                        // setPatients({ ...patients, ['dob']: JSON.parse(item.fieldValue) })
                        setDob(JSON.parse(item?.fieldValue))
                    }

                })
                // allDoctor?.user_form_data?.map((item: any) => {
                //     if (item?.formFieldKey === "disease") {
                //         // setPatients({ ...patients, ['diagonis']: item.formFieldValue })
                //         setDisease(item?.formFieldValue);
                //     }
                // })




                // setPatients({ ...patients, allDoctor })




            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                // setLoading(false);
                // setMessage(resMessage);
            }
        );


    };


    const createpharmacy = (e?: any) => {
        // console.log("placess", place)
        createPharmacyDetails(e).then(
            (data) => {

                const allStoreDetails = data.result;
                console.log("storedetails", allStoreDetails);

                // navigate(-1);
                // setTimeout(() => {
                //   // navigate(route);
                //   toast.setShowToast(data.message, "success");
                // }, 1000);




            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                // setLoading(false);
                // setMessage(resMessage);
            }
        );

    }


    const createOnePrescription = async () => {
        createOneRefillrequest(medicationDetail, location?.state?.medication).then(
            (data) => {


                // console.log("dtueiruerer", data);
                if (data.message) {

                    navigate(`/${currentUser}/refill-request`);
                    // navigate(-1);
                    setTimeout(() => {

                        toast.setShowToast(data.message, "success");
                    }, 1000);

                }
            },
            (error) => {

                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();


                // navigate(-1);
                // setTimeout(() => {
                //     // navigate(route);
                //     toast.setShowToast("added successfully", "success");
                // }, 1000);
            }
        );
    };

    async function handleDelete() {
        const response = await deleteRefill(refillId);
        if (response?.success) {
            toast.setShowToast(response?.data, "success");
            navigate(`/${currentUser}/refill-request`)
        } else if (response?.code == 201) {
            toast.setShowToast(response?.data, "danger");
        } else {
            toast.setShowToast("Something went wrong. Please try again later.", "danger");
        }
    }

    return (
        <Row>
            <div className="panel_top_section">
                <h1>Refill Requests </h1> <span className="textgreen poppins-500 invoice-header-text"><i className="bi bi-chevron-right"></i> Refill Request Detail </span>
                {refillStatus == 0 &&
                    <div className="grid_icons d-flex gap-1 align-items-center float-end">
                        <button
                            type="button"
                            className="dark_btn text-white py-2 rounded-3 mx-1 px-2"
                            // onClick={() => navigate(`/${currentUser}/addrefill-medication`, { state: { medicationValue: medicationDetail, patientId: location?.state?.patientId } })}
                            onClick={() => navigate(`/${currentUser}/update-refill-medication/${refillId}`)}
                        >
                            <span className="px-2 pl-0"><i className="bi bi-pen" /></span>Edit</button>
                        <div className="grid_list_icons icons_patients  d-flex align-items-center gap-2">
                            <button
                                type="button"
                                className="delet_patiens"
                                onClick={() => {
                                    setShowDeleteModal(true);
                                }}
                            >
                                <i className="bi bi-trash" />
                            </button>
                        </div>
                    </div>
                }
            </div>
            <Col className="mt-3 mb-4">
                <div className="add_location col-sm-12 py-4 px-lg-2 mt-2">
                    <div className="row m-0 justify-content-center">
                        <div className="col-xl-6 p-1 pb-2">
                            <div className="inner_location float-start w-100 bg-white box_shadow p-4 radius_15">
                                {/* Appointment Details */}
                                <div className="float-start w-100 pb-4 px-xl-3">
                                    <h4 className="px-1 textgreen poppins-600 fs-5  border-bottom pb-4 mb-3">Request Details</h4>
                                    {/* <div className="pat_group py-2 d-flex justify-content-between">
                                        <div className="col-sm-4 row_1 px-1">
                                            <span className="d-block text_sm text_gray ">Medication Name</span>
                                        </div>
                                        <div className="col-sm-4 row_1 px-1">
                                            <span className="d-block text_sm text_gray ">Prescription Number</span>
                                        </div>
                                        <div className="col-sm-4 row_1 px-1">
                                            <span className="d-block text_sm text_gray ">Refill Date</span>
                                        </div>
                                    </div>
                                    {medicationDetail?.length > 0 && medicationDetail?.map((item: any) => {
                                        return (
                                            <div className="pat_group py-2 d-flex justify-content-between">
                                                <div className="col-sm-4 row_1 px-1">
                                                    <p className="m-0 poppins-500 dark_blue fs-6">{item?.medication}</p>
                                                </div>
                                                <div className="col-sm-4 row_1 px-1">
                                                    <p className="m-0 poppins-500 dark_blue fs-6">{item?.medicationId}</p>
                                                </div>
                                                <div className="col-sm-4 row_1 px-1">
                                                    <p className="m-0 poppins-500 dark_blue fs-6">{longDateGenerator(new Date(item.createdAt))}</p>
                                                </div>
                                            </div>
                                        )
                                    })} */}
                                    <div className="row d-flex table-responsive">
                                        <table>
                                            <thead>
                                                <tr className="">
                                                    <th>Medication Name</th>
                                                    <th>Prescription Number</th>
                                                    <th>Refill Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {medicationDetail?.length > 0 && medicationDetail?.map((item: any) => {
                                                    return (
                                                        <tr>
                                                            <td>{item?.medication}</td>
                                                            <td>{item?.medicationId}</td>
                                                            <td>{longDateGenerator(new Date(item.createdAt))}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* patient information */}
                                <div className="float-start w-100 pb-4 px-xl-3">
                                    <h4 className="px-1 textgreen poppins-600 fs-5  border-bottom pb-4 mb-3">Patient Information</h4>
                                    <div className="pat_group py-2 p d-flex justify-content-between">
                                        <div className="col-sm-4 row_1 px-1">
                                            <span className="d-block text_sm text_gray ">Full Name</span>
                                            <p className="m-0 poppins-500 dark_blue fs-6">{patients?.firstName + patients?.lastName}</p>
                                        </div>
                                        <div className="col-sm-4 row_1 px-1">
                                            <span className="d-block text_sm text_gray ">Birth Date</span>
                                            {/* <p className="m-0 poppins-500 dark_blue fs-6">19 April 1997</p> */}
                                            <p className="m-0 poppins-500 dark_blue fs-6">{dob

                                            }</p>

                                        </div>
                                        <div className="col-sm-4 row_1 px-1">
                                            <span className="d-block text_sm text_gray ">Diagonis</span>
                                            <p className="m-0 poppins-500 dark_blue fs-6">{disease}</p>
                                        </div>
                                    </div>
                                    <div className="pat_group py-2 d-flex justify-content-between">
                                        <div className="col-sm-4 row_1 px-1">
                                            <span className="d-block text_sm text_gray ">Phone</span>
                                            <p className="m-0 poppins-500 dark_blue fs-6">{patients?.phoneNumber}</p>
                                        </div>
                                        <div className="col-sm-8  row_1 px-1">
                                            <span className="d-block text_sm text_gray ">Email</span>
                                            <p className="m-0 poppins-500 dark_blue fs-6 text-break">{patients?.email}</p>
                                        </div>
                                    </div>
                                </div>
                                {/* select-pharmacy */}
                                {refillStatus == 1 ?
                                    <div>
                                        <div className="float-start w-100 pb-2 px-xl-3" onClick={() => navigate(`/${currentUser}/refillsend`, { state: { key: location?.state?.key, patientId: location?.state?.patientId, refillId: refillId } })}>
                                            {/* <div className="col-lg-12 col-xl-6 mb-3"> */}
                                            {location?.state?.pharmacyName === undefined ?
                                                <button className=" text-black rounded-3 poppins-500 py-2 w-100 fs-4  border-0 mb-2" type="button" onClick={() => navigate(`/${currentUser}/refillsend`)}>Select Pharmacy</button>
                                                :
                                                <input type="text" className="form-control" id="name" name="lastName" defaultValue={location?.state?.pharmacyName} disabled />
                                            }
                                        </div>
                                        {location?.state?.pharmacyName !== undefined &&
                                            <div className="start_telehealth float-start text-center w-100 mt-4 px-xl-3 ">
                                                <button className="bg_gradiant text-white rounded-3 poppins-500 py-2 w-100 fs-4 text-uppercase border-0 mb-2" type="button"
                                                    disabled={location?.state?.pharmacyName === undefined ? true : false}
                                                    // onClick={() => { location?.state?.pharmacyName === undefined ? createpharmacy() : createOnePrescription() }}
                                                    onClick={proceedRefill}
                                                >
                                                    SEND TO A PHARMACY
                                                </button>
                                                <button type="button" className="bg-transparent border-0 text_gray cancel_btn" onClick={() => navigate(-1)}>Cancel</button>
                                            </div>
                                        }
                                    </div>
                                    :
                                    <div className="px-xl-3">
                                        <h4 className="px-1 textgreen poppins-600 fs-5  border-bottom pb-4 mb-3">Refill Status</h4>
                                        <p className={`m-0 poppins-500 fs-6 text-break ${refillStatus == 0 ? "text-secondary" : refillStatus == 2 ? "text-danger" : "textgreen"}`}>
                                            {refillStatus == 0 ?
                                                "This refill request hasn't been approved by the respective Doctor."
                                                :
                                                refillStatus == 2 ?
                                                    "This refill request has been rejected by the respective Doctor."
                                                    :
                                                    "This refill request has been sent to Pharmacy."
                                            }
                                        </p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
            <DeleteModal
                show={showDeleteModal}
                handleYes={() => {
                    handleDelete();
                    setShowDeleteModal(false);
                }}
                handleClose={() => setShowDeleteModal(false)}
                body="Do you really want to delete this data?"
                head="Delete Refill Request"
            />
        </Row>
    );
};

export default RefilDetail;