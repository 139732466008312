import card from "../../../assets/images/billing/card.png";

import {
    Row,
    Col,
} from "reactstrap";
import { createPayment } from "../../../services/billing-service"
import { useState, useEffect, useContext } from "react";
import { getallpatient } from "../../../services/patient-service";
import { makePayment, getInvoiceDetails, getHospitalInvoiceDetails, payHospitalSubscription } from "../../../services/billing-service";
import { error } from "console";
import { useParams, useNavigate } from "react-router-dom";
import { RoleContext } from "../../../utils/contexts/RoleContext";
import { getOneSetting } from "../../../services/adminsetting-service";
import { useCreditCardValidator, images } from 'react-creditcard-validator';
import Spinner from 'react-bootstrap/Spinner';
import constants from "../../../config/constants";
import { capitalizeFirstLetter } from "../../../utils/utils";

interface Patient {
    id: number;
    firstName: string;
    lastName: string;
    email: string
}

const Payment = () => {

    const [searchInputPatient, setSearchInputPatient] = useState("");
    const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState<number>(-1);
    const [isDropdownOpenPatient, setIsDropdownOpenPatient] = useState(false);
    const [validationMessages, setValidationMessages] = useState<any>({
        doctorName: "",
        patientName: "",
        reason: "",
        type: "",
        calendar: "",
        appointmentTime: ""
    });
    const [filteredPatient, setFilteredPatient] = useState<Patient[]>([]);
    const [patients, setPatients] = useState<Patient[]>([]);
    const [selectedPatientId, setSelectedPatientId] = useState<number | null>(null);
    const [address, setAddress] = useState<any>({
        name: "",
        address: "",
        phone: "",
        city: "",
        email: "",
        zipCode: ""
    });
    const [formData, setFormData] = useState<any>({
        services: {
            value: "",
            error: ""
        },
        amount: {
            value: "10.00",
            error: ""
        },
        paymentMethod: {
            value: "creditCard",
            error: ""
        }
    });
    const [creditCard, setCreditCard] = useState<any>({
        name: "",
        number: "",
        expiry: "",
        cvv: ""
    });
    const [error, setError] = useState({
        amount: "",
        creditCard: {
            name: "",
            number: "",
            expiry: "",
            cvv: ""
        },
        address: {
            name: "",
            address: "",
            phone: "",
            city: "",
            email: "",
            zipCode: ""
        }
    });
    const [amountPayable, setAmountPayable] = useState({
        serviceAmount: 10.00,
        tax: 0,
        discount: 0,
        totalAmount: 0
    });
    const [order, setOrder] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const {
        getCardNumberProps,
        getCardImageProps,
        getCVCProps,
        getExpiryDateProps,
        meta: { erroredInputs }
    } = useCreditCardValidator({ expiryDateValidator: expDateValidate });

    const { invoiceId }: any = useParams();
    const currentUser = useContext(RoleContext);
    const navigate = useNavigate();


    function expDateValidate(month: string, year: string) {
        if (Number(year) > 2035) {
            return 'Expiry Date Year cannot be greater than 2035';
        }
        return;
    }

    useEffect(() => {
        if (currentUser === "patient") {
        fetchInvoiceDetails();
            // totalAmountCalculator();
        } else {
            fetchHospitalInvoiceDetails();
        }
        document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - New Payment`;
    }, [currentUser]);

    async function totalAmountCalculator() {
        const discountRes = await getOneSetting('discount');
        const taxRes = await getOneSetting('tax');
        const tax = (Number(taxRes?.data?.settingsValue) * amountPayable?.serviceAmount) / 100;
        const discount = amountPayable?.serviceAmount * (Number(discountRes?.data?.settingsValue) / 100);
        const totalAmount = amountPayable?.serviceAmount + tax - discount;
        setAmountPayable({ ...amountPayable, tax: Number(tax?.toFixed(2)), discount: Number(discount?.toFixed(2)), totalAmount });
    }

    async function fetchInvoiceDetails() {
        const response = await getInvoiceDetails(invoiceId);
        setOrder(response?.order)
        const order = response?.order;
        setFormData((prevState: any) => ({ ...prevState, amount: { ...prevState["amount"], value: response?.data?.totalAmount } }));
        setAmountPayable((prevState: any) => ({ ...prevState, tax: order?.totalTax, discount: order?.totalDiscount, totalAmount: order?.totalAmount, serviceAmount: order?.totalAmount - order?.totalTax + order?.totalDiscount }));
    }

    async function fetchHospitalInvoiceDetails() {
        const response = await getHospitalInvoiceDetails(invoiceId);
        if (response?.success) {
            const invoiceDetail = response?.data;
            // setFormData((prevState: any) => ({ ...prevState, amount: { ...prevState["amount"], value: response?.data?.grandTotal } }))
            setAmountPayable((prevState: any) => ({ ...prevState, totalAmount: invoiceDetail?.grandTotal, tax: invoiceDetail.totalTax }))
            setOrder((prevState: any) => ({ ...prevState, service: invoiceDetail?.invoiceTitle }))
        }
    }

    console.log("form dta", formData)

    function handleFormData(e: any) {
        const { name, value } = e.target;
        setFormData((prevState: any) => ({ ...prevState, [name]: { ...prevState[name], value } }));
        setError((prevState) => ({ ...prevState, [name]: "" }))
    }


    const getUserspatient = () => {
        getallpatient().then(
            (response) => {
                const patientData = response.result;
                setPatients(patientData)
                // setFilteredPatient(patientData)
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
    }






    const createOnePayment = async () => {
        const response = await createPayment();
        console.log("res", response);
    }

    const handleChangePatient = async (e: any) => {
        const searchValue = e.target.value.toLowerCase();
        setSearchInputPatient(searchValue);
        setSelectedSuggestionIndex(-1);
        if (!searchValue.trim()) {
            setValidationMessages({ ...validationMessages, patientName: 'Please enter a patient name' });
            // setFilteredPatient([]);
        } else {
            setValidationMessages({ ...validationMessages, patientName: '' });
        }

        if (searchValue.trim() === '') {
            // setPatients(filteredPatient); 
            setIsDropdownOpenPatient(false);

            return;
        }

        const [firstName, lastName] = searchValue.split(' ');

        const filtered = patients.filter(
            (patient) =>
                patient.firstName.toLowerCase().includes(firstName) ||
                patient.lastName.toLowerCase().includes(lastName) ||
                patient.id == searchValue ||
                patient.email.toLowerCase().includes(searchValue)
        );
        console.log("filt", filtered)
        setFilteredPatient(filtered);

        setIsDropdownOpenPatient(true);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        switch (event.key) {
            case 'ArrowDown':
                event.preventDefault();
                setSelectedSuggestionIndex((prevIndex) => (prevIndex < filteredPatient.length - 1 ? prevIndex + 1 : prevIndex));
                break;
            case 'ArrowUp':
                event.preventDefault();
                setSelectedSuggestionIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
                break;
            case 'Enter':
                event.preventDefault();
                if (selectedSuggestionIndex !== -1) {
                    setSearchInputPatient(`${filteredPatient[selectedSuggestionIndex].firstName} ${filteredPatient[selectedSuggestionIndex].lastName}`);
                    setIsDropdownOpenPatient(false);
                    setSelectedPatientId(filteredPatient[selectedSuggestionIndex].id);
                    setSelectedSuggestionIndex(-1);
                    setBillingAddress(filteredPatient[selectedSuggestionIndex])
                }
                break;
            default:
                break;
        }
    };

    const handlePatientClick = (patient: any) => {
        setSearchInputPatient(`${patient.firstName} ${patient.lastName}`)
        setIsDropdownOpenPatient(false);
        setSelectedPatientId(patient.id);
        setSelectedSuggestionIndex(-1);
        setBillingAddress(patient);
    }

    function setBillingAddress(patient: any) {
        var address: string = "";
        patient.userdata.map((patient: any) => {
            if (patient.fieldKey == "address") {
                address = JSON.parse(patient.fieldValue)
            }
        })
        setAddress({
            name: patient.firstName + " " + patient.lastName,
            phone: patient.phoneNumber,
            email: patient.email,
            address: address
        })
    }


    function handleAddressChange(e: any) {
        const { name, value } = e.target;
        setAddress((prevState: any) => ({ ...prevState, [name]: value }));
        setError((prevState) => ({ ...prevState, address: { ...prevState.address, [name]: "" } }));
    }

    function handleCreditCard(e: any) {
        const { name, value } = e.target;
        setCreditCard((prevState: any) => ({ ...prevState, [name]: value }));
        setError((prevState) => ({ ...prevState, creditCard: { ...prevState.creditCard, [name]: "" } }));
    }

    async function handleChargePatient() {
        let validationSuccess = true;
        // if (formData.amount.value == "") {
        //     setError((prevState) => ({ ...prevState, amount: "Please Enter an amount" }));
        //     validationSuccess = false;
        // }
        for (let key in creditCard) {
            if (creditCard[key] == "") {
                setError((prevState) => ({ ...prevState, creditCard: { ...prevState.creditCard, [key]: "This field is required" } }));
                validationSuccess = false;
            }
        }
        for (let key in address) {
            if (address[key] == "") {
                setError((prevState) => ({ ...prevState, address: { ...prevState.address, [key]: "This field is required" } }));
                validationSuccess = false;
            }
        }
        if (validationSuccess) {
            setLoading(true);
            const obj = {
                paymentType: formData.paymentMethod.value,
                amount: amountPayable.totalAmount,
                paymentDetail: {},
                billingAddress: address
            }
            if (formData.paymentMethod.value === "creditCard") {
                let paymentDetail: any = {
                    ACCT: creditCard.number?.replace(/ /g, ""),
                    EXPDATE: creditCard.expiry?.replace(" / ", ""),
                    CVV2: creditCard.cvv,
                    TENDER: "C",
                    BILLTOFIRSTNAME: creditCard.name.split(" ")[0]
                }
                if (creditCard.name.split(" ")[1] != undefined) {
                    paymentDetail.BILLTOLASTNAME = creditCard.name.split(" ")[1]
                }
                obj.paymentDetail = paymentDetail;
            }
            if (currentUser === "patient") {
            const response = await makePayment(obj, invoiceId);
                if (response?.data?.success) {
                    alert(`Payment Successfull with transaction Id ${response?.data.data.transactionId}`);
                navigate(`/patient/billing`)
            } else {
                alert(`Payment failed`)
            }
                setLoading(false);
            } else {
                const response = await payHospitalSubscription(obj, invoiceId);
                if (response?.data?.success) {
                    alert(`Payment Successfull with transaction Id ${response.data.data.transactionId}`);
                    navigate(`/hospital/subscription`)
                } else {
                    alert(`Payment failed`)
                }
                setLoading(false);
            }
        }
    }


    return (
        <Row>
            <div className="panel_top_section position-relative border-0 px-4">
                <h1 className="clickable" onClick={() => navigate(-1)}>Payments</h1> <span className="textgreen poppins-500"><i className="bi bi-chevron-right"></i> Payment Detail </span>
            </div>
            <Col>
                {/* <div className="row m-0 mt-4">
                    <div className="col-xl-7 px-0 px-lg-3 pb-4">
                        <div className="float-start w-100 bg-white box_shadow p-4 pt-4 radius_15">
                            <div className="row col-lg-12 col-xl-9   p-lg-3">
                                <div className="row_1 mb-4">
                                    <h4 className="dark_blue poppins-700 fs-5 mb-3">Treatments Detail</h4>
                                    <div className="col-xl-12">
                                        <span className="d-block text_sm text_gray mb-2">Specify the Services Provided</span>
                                        <input
                                            type="text"
                                            placeholder="Services"
                                            className="form-control"
                                            id="treatment"
                                            value={formData.services.value}
                                            onChange={handleFormData}
                                            name="services"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="row row_1 mb-4 m-0 p-0">
                                    <h4 className="dark_blue poppins-700 fs-5 mb-3">Payment Details</h4>
                                    <div className="col-lg-12 col-xl-6">
                                        <span className="d-block text_sm text_gray mb-2">Amount</span>
                                        <input
                                            type="text"
                                            placeholder="Amount"
                                            className="form-control"
                                            id="amount"
                                            value={formData.amount.value}
                                            onChange={handleFormData}
                                            name="amount"
                                        />
                                        <div className="text-danger">{error.amount}</div>
                                    </div>
                                    <div className="col-lg-12 col-xl-6">
                                        <span className="d-block text_sm text_gray mb-2">Payment Method</span>
                                        <select
                                            className="form-select dark_blue"
                                            aria-label="Default select example"
                                            value={formData.paymentMethod.value}
                                            onChange={handleFormData}
                                            name="paymentMethod"
                                        >
                                            <option value="creditCard">Credit Card</option>
                                        </select>
                                    </div>
                                </div>
                                {formData.paymentMethod.value === "creditCard" &&
                                    <div className="row row_1 mb-4 m-0 p-0">
                                        <h4 className="dark_blue poppins-700 fs-5 mb-3">Credit Card Details</h4>
                                        <div className="col-xl-12 mb-3">
                                            <span className="d-block text_sm text_gray mb-2">Cardholder Name</span>
                                            <input
                                                type="text"
                                                placeholder="Cardholder name"
                                                className="form-control"
                                                id="name2"
                                                value={creditCard.name}
                                                onChange={handleCreditCard}
                                                name="name"
                                            />
                                            <div className="text-danger">{error.creditCard.name}</div>
                                        </div>
                                        <div className="col-xl-6">
                                            <span className="d-block text_sm text_gray mb-2">Card Number</span>
                                            <input
                                                type="text"
                                                placeholder="**** **** **** ****"
                                                className="form-control"
                                                id="card_number"
                                                value={creditCard.number}
                                                onChange={handleCreditCard}
                                                name="number"
                                            />
                                            <div className="text-danger">{error.creditCard.number}</div>
                                        </div>
                                        <div className="row col-xl-6 m-0 p-0">
                                            <div className="col-sm-6">
                                                <span className="d-block text_sm text_gray mb-2">Date</span>
                                                <input
                                                    type="text"
                                                    placeholder="1/24"
                                                    className="form-control"
                                                    id="Date"
                                                    value={creditCard.expiry}
                                                    onChange={handleCreditCard}
                                                    name="expiry"
                                                />
                                                <div className="text-danger">{error.creditCard.expiry}</div>
                                            </div>
                                            <div className="col-sm-6">
                                                <span className="d-block text_sm text_gray mb-2">CCV</span>
                                                <input
                                                    type="text"
                                                    placeholder="000"
                                                    className="form-control"
                                                    id="ccv"
                                                    value={creditCard.cvv}
                                                    onChange={handleCreditCard}
                                                    name="cvv"
                                                />
                                                <div className="text-danger">{error.creditCard.cvv}</div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="row row_1 mb-2 m-0 p-0">
                                    <h4 className="dark_blue poppins-700 fs-5 mb-3">Billing Address</h4>
                                    <div className="row_1 float-start col-lg-6">
                                        <span className="d-block text_xs text_gray ">Full Name</span>
                                        <input
                                            type="text"
                                            placeholder="Name"
                                            className="form-control"
                                            id="card_number"
                                            value={address.name}
                                            onChange={handleAddressChange}
                                            name="name"
                                        />
                                        <div className="text-danger">{error.address.name}</div>
                                    </div>
                                    <div className="row_1 float-start col-lg-6">
                                        <span className="d-block text_xs text_gray ">Address</span>
                                        <input
                                            type="text"
                                            placeholder="Address"
                                            className="form-control"
                                            id="card_number"
                                            value={address.address}
                                            onChange={handleAddressChange}
                                            name="address"
                                        />
                                        <div className="text-danger">{error.address.address}</div>
                                    </div>
                                    <div className="row_1 float-start col-lg-6">
                                        <span className="d-block text_xs text_gray ">Phone </span>
                                        <input
                                            type="text"
                                            placeholder="Phone Number"
                                            className="form-control"
                                            id="card_number"
                                            value={address.phone}
                                            onChange={handleAddressChange}
                                            name="phone"
                                        />
                                        <div className="text-danger">{error.address.phone}</div>
                                    </div>
                                    <div className="row_1 float-start col-lg-6">
                                        <span className="d-block text_xs text_gray ">City </span>
                                        <input
                                            type="text"
                                            placeholder="City"
                                            className="form-control"
                                            id="card_number"
                                            value={address.city}
                                            onChange={handleAddressChange}
                                            name="city"
                                        />
                                        <div className="text-danger">{error.address.city}</div>
                                    </div>
                                    <div className="row_1 float-start col-lg-6">
                                        <span className="d-block text_xs text_gray ">Email </span>
                                        <input
                                            type="text"
                                            placeholder="Email"
                                            className="form-control"
                                            id="card_number"
                                            value={address.email}
                                            onChange={handleAddressChange}
                                            name="email"
                                        />
                                        <div className="text-danger">{error.address.email}</div>
                                    </div>
                                    <div className="row_1 float-start col-lg-6">
                                        <span className="d-block text_xs text_gray ">ZIP Code </span>
                                        <input
                                            type="text"
                                            placeholder="Zip code"
                                            className="form-control"
                                            id="card_number"
                                            value={address.zipCode}
                                            onChange={handleAddressChange}
                                            name="zipCode"
                                        />
                                        <div className="text-danger">{error.address.zipCode}</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 px-0 px-lg-3 pb-4">
                        <div className="float-start w-100 bg-white box_shadow pb-4 p-lg-4 pt-4 radius_15">
                            <div className="float-start w-100 demo_card">
                                <div className="card_image">
                                    <img src={card} alt="card" />
                                </div>
                                <div className="card_details">
                                    <span className="card_type text-white d-block card_name text-uppercase fs-5 poppins-700">Visa</span>
                                    <div className="card_name d-flex justify-content-between">
                                        <div className="card_number">
                                            <span className="text-white d-block fs-4 mb-2">{creditCard.name}</span>
                                            <span className="text-white d-block">****  ****  ****  1234</span>
                                        </div>
                                        <div className="valid">
                                            <span className="text-white d-block text_sm valid mb-2">VALID</span>
                                            <span className="text-white d-block valid_num">01/24</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h2 className="textgreen text-center poppins-700 mb-4 pb-2">PAYMENT SUMMARY</h2>
                            <div className="payment_top border-bottom px-4">
                                <div className="d-flex justify-content-between pb-4">
                                    <div className="col-lg-6">
                                        <span className="d-block fs-6 text_gray ">Services Provided</span>
                                        <p className="m-0 poppins-600 dark_blue fs-6">Allergy Test</p>
                                    </div>
                                    <div className="col-lg-6">
                                        <p className="m-0 poppins-500 dark_blue fs-5 float-end">$ {formData.amount.value}</p>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between pb-4">
                                    <div className="col-lg-6">
                                        <span className="d-block fs-6 text_gray ">Discounts & Offers</span>
                                    </div>
                                    <div className="col-lg-6">
                                        <p className="m-0 poppins-500 dark_blue fs-5 float-end">$ 0.00</p>
                                    </div>
                                </div>
                            </div>
                            <div className="payment_top pb-2 px-4 pt-4 ">
                                <div className="d-flex justify-content-between pb-2">
                                    <div className="col-lg-6">
                                        <span className="d-block fs-6 text_gray ">Tax</span>
                                    </div>
                                    <div className="col-lg-6">
                                        <p className="m-0 poppins-500 dark_blue fs-5 float-end">$ 0.00</p>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between pb-2">
                                    <div className="col-lg-6">
                                        <span className="d-block fs-6 dark_blue poppins-700 ">Total</span>
                                    </div>
                                    <div className="col-lg-6">
                                        <p className="m-0 poppins-700 dark_blue fs-5 float-end">$ {formData.amount.value}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="float-start w-100 payment_button px-4 mt-3">
                                <button
                                    className="my-1 border-0 charge_patient bg_dark_blue  poppins-500 text-white w-100  px-3"
                                    type="button"
                                    // onClick={createOnePayment}
                                    onClick={
                                        //   async () => {
                                        //   const res = await makePayment();
                                        //   console.log("ree", res)
                                        // }
                                        handleChargePatient
                                    }
                                >
                                    Pay
                                </button>
                            </div>

                        </div>
                    </div>
                </div> */}
                <div className="add_location col-sm-12 py-4 px-lg-2 mt-2">
                    <div className="row m-0 justify-content-center">
                        <div className="col-xl-6 p-1 pb-2">
                            <div className="inner_location float-start w-100 bg-white box_shadow p-4 radius_15">
                                <div className="float-start w-100 pb-3 px-xl-3 border-bottom">
                                    <h4 className="px-1 dark_blue poppins-700 fs-5">Payment Details</h4>
                                    <div className="col-sm-12 row_1 px-1">
                                        <label className="d-block text_sm text_gray ">Cardholder Name</label>
                                        <input
                                            type="text"
                                            placeholder="Cardholder Name"
                                            className="form-control mt-1"
                                            id="name2"
                                            value={creditCard.name}
                                            onChange={handleCreditCard}
                                            name="name"
                                        />
                                        <div className="text-danger">{error.creditCard.name}</div>
                                    </div>
                                    <div className="pat_group py-2 d-flex justify-content-between">
                                        <div className="col-sm-6 row_1 px-1">
                                            <label className="d-block text_sm text_gray ">Card Number</label>
                                            <div className="position-relative">
                                                <input
                                                    // type="text"
                                                    // placeholder="**** **** **** ****"
                                                    className="form-control mt-1"
                                                    // id="card_number"
                                                    value={creditCard.number}
                                                    // onChange={handleCreditCard}
                                                    // name="number"
                                                    max={16}
                                                    {...getCardNumberProps({ onChange: (e) => handleCreditCard(e), name: "number", id: "card_number" })}
                                                />
                                                <div className="position-absolute" style={{ bottom: "13px", right: "10px" }}>
                                                    <svg {...getCardImageProps({ images })} />
                                                </div>
                                            </div>
                                            <div className="text-danger">{error.creditCard.number}</div>
                                        </div>
                                        <div className="col-sm-3 row_1 px-1">
                                            <label className="d-block text_sm text_gray ">Date</label>
                                            <input
                                                // type="text"
                                                // placeholder="1/24"
                                                className="form-control mt-1"
                                                // id="Date"
                                                value={creditCard.expiry}
                                                // onChange={handleCreditCard}
                                                // name="expiry"
                                                {...getExpiryDateProps({ onChange: (e) => handleCreditCard(e), name: "expiry", id: "Date" })}
                                            />
                                            <div className="text-danger">{error.creditCard.expiry}</div>
                                        </div>
                                        <div className="col-sm-3 row_1 px-1">
                                            <label className="d-block text_sm text_gray ">cvv</label>
                                            <input
                                                // type="text"
                                                // placeholder="000"
                                                className="form-control mt-1"
                                                // id="ccv"
                                                // value={creditCard.cvv}
                                                // onChange={handleCreditCard}
                                                // name="cvv"
                                                value={creditCard.cvv}
                                                {...getCVCProps({ onChange: (e) => handleCreditCard(e), name: "cvv", placeholder: "CVV" })}
                                            />
                                            <div className="text-danger">{error.creditCard.cvv}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="float-start w-100 pb-3 px-xl-3 border-bottom mt-3">
                                    <h4 className="px-1 dark_blue poppins-700 fs-5">Payment Summary</h4>
                                    <div className="py-2 p d-flex justify-content-between flex-wrap">
                                        <div className="col-sm-6 row_1 px-1">
                                            <span className="d-block text_sm text_gray ">Services Provided</span>
                                            <p className="m-0 poppins-500 dark_blue fs-6">{order.service}</p>
                                        </div>
                                        <div className="col-sm-6 row_1 px-1">
                                            <span className="d-block text_sm text_gray ">Discount & Offers</span>
                                            <p className="m-0 poppins-500 dark_blue fs-6">$ {amountPayable.discount}</p>
                                        </div>
                                        <div className="col-sm-6 row_1 px-1">
                                            <span className="d-block text_sm text_gray ">Tax</span>
                                            <p className="m-0 poppins-500 dark_blue fs-6">$ {amountPayable.tax}</p>
                                        </div>
                                        <div className="col-sm-6 row_1 px-1">
                                            <span className="d-block text_sm text_gray ">Total Amount</span>
                                            <p className="m-0 poppins-500 dark_blue fs-6">$ {amountPayable.totalAmount}</p>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="float-start w-100 pb-0 px-xl-3">
                                    <h4 className="px-1 textgreen poppins-600 fs-5  border-bottom pb-4 mb-3">Doctor Information</h4>
                                    <div className="pat_group py-2 p d-flex justify-content-between">
                                        <div className="col-sm-4 row_1 px-1">
                                            <span className="d-block text_sm text_gray ">Full Name</span>
                                            <p className="m-0 poppins-500 dark_blue fs-6">fflkfk</p>
                                        </div>
                                        <div className="col-sm-8 row_1 px-1">
                                            <span className="d-block text_sm text_gray ">Specialty</span>
                                            <p className="m-0 poppins-500 dark_blue fs-6">lfk</p>
                                        </div>
                                    </div>
                                    <div className="pat_group py-2 d-flex justify-content-between">
                                        <div className="col-sm-4 row_1 px-1">
                                            <span className="d-block text_sm text_gray ">Phone</span>
                                            <p className="m-0 poppins-500 dark_blue fs-6">ffm</p>
                                        </div>
                                        <div className="col-sm-8  row_1 px-1">
                                            <span className="d-block text_sm text_gray ">Email</span>
                                            <p className="m-0 poppins-500 dark_blue fs-6 text-break">cfki</p>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="row float-start w-100 pb-3 px-xl-3 mt-3">
                                    <h4 className="dark_blue poppins-700 fs-5 mb-3">My Billing Address</h4>
                                    <div className="row_1 float-start col-lg-6 mb-3">
                                        <span className="d-block text_xs text_gray mb-2">Full Name</span>
                                        <input
                                            type="text"
                                            placeholder="Name"
                                            className="form-control"
                                            id="card_number"
                                            value={address.name}
                                            onChange={handleAddressChange}
                                            name="name"
                                        />
                                        <div className="text-danger">{error.address.name}</div>
                                    </div>
                                    <div className="row_1 float-start col-lg-6 mb-3">
                                        <span className="d-block text_xs text_gray mb-2">Address</span>
                                        <input
                                            type="text"
                                            placeholder="Address"
                                            className="form-control"
                                            id="card_number"
                                            value={address.address}
                                            onChange={handleAddressChange}
                                            name="address"
                                        />
                                        <div className="text-danger">{error.address.address}</div>
                                    </div>
                                    <div className="row_1 float-start col-lg-6 mb-3">
                                        <span className="d-block text_xs text_gray mb-2">Phone </span>
                                        <input
                                            type="text"
                                            placeholder="Phone Number"
                                            className="form-control"
                                            id="card_number"
                                            value={address.phone}
                                            onChange={handleAddressChange}
                                            name="phone"
                                        />
                                        <div className="text-danger">{error.address.phone}</div>
                                    </div>
                                    <div className="row_1 float-start col-lg-6 mb-3">
                                        <span className="d-block text_xs text_gray mb-2">City </span>
                                        <input
                                            type="text"
                                            placeholder="City"
                                            className="form-control"
                                            id="card_number"
                                            value={address.city}
                                            onChange={handleAddressChange}
                                            name="city"
                                        />
                                        <div className="text-danger">{error.address.city}</div>
                                    </div>
                                    <div className="row_1 float-start col-lg-6 mb-3">
                                        <span className="d-block text_xs text_gray mb-2">Email </span>
                                        <input
                                            type="text"
                                            placeholder="Email"
                                            className="form-control"
                                            id="card_number"
                                            value={address.email}
                                            onChange={handleAddressChange}
                                            name="email"
                                        />
                                        <div className="text-danger">{error.address.email}</div>
                                    </div>
                                    <div className="row_1 float-start col-lg-6 mb-3">
                                        <span className="d-block text_xs text_gray mb-2">ZIP Code </span>
                                        <input
                                            type="text"
                                            placeholder="Zip code"
                                            className="form-control"
                                            id="card_number"
                                            value={address.zipCode}
                                            onChange={handleAddressChange}
                                            name="zipCode"
                                        />
                                        <div className="text-danger">{error.address.zipCode}</div>
                                    </div>
                                </div>
                                <div className="start_telehealth float-start text-center w-100 mt-4 px-xl-3 ">
                                    <button
                                        className={`${order.orderStatus == 1 ? "bg-secondary" : "bg_gradiant"} text-white rounded-3 poppins-500 py-2 w-100 fs-4 text-uppercase border-0 mb-2`}
                                        type="button"
                                        onClick={handleChargePatient}
                                        disabled={loading}
                                        style={{ opacity: loading ? 0.5 : 1 }}
                                    >
                                        Make Payment
                                        <span className="ms-2">
                                            {loading &&
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            }
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default Payment;