import React, { useEffect, useRef } from 'react';
import './VideoCall.css'

// import '../App.css'

export default function Track({ track }) {
    
    const trackRef = useRef();

    useEffect(() => {
        if (track !== null) {
            const child = track.attach();
            trackRef.current.classList.add(track.kind);
            trackRef.current.appendChild(child)
        }
    }, [])

    return (
        <div className="track" ref={trackRef}>
        </div>
    )
} 


