import card from "../../../assets/images/billing/card.png";

import {
  Row,
  Col,
} from "reactstrap";
import Payment from "../../../components/Payment";
import { createPayment } from "../../../services/billing-service"
import { useState, useEffect, useContext } from "react";
import { getallpatient } from "../../../services/patient-service";
import { makePayment } from "../../../services/billing-service";
import { getPendingAppointments } from "../../../services/scheduleappointment-service";
import { makePaymentThroughHospital, requestPayment } from "../../../services/billing-service";
import { RoleContext } from "../../../utils/contexts/RoleContext";
import { useLocation, useNavigate } from "react-router-dom";
import { requestPaymentOtherServices, chargePaymentOtherServices } from "../../../services/billing-service";
import Spinner from 'react-bootstrap/Spinner';
import { getOneSetting } from "../../../services/adminsetting-service";
import { useCreditCardValidator, images } from 'react-creditcard-validator';
import CustomToolTip from "../../../components/CustomToolTip";
import { getCurrentUser } from "../../../services/auth.service";
import constants from "../../../config/constants";
import { capitalizeFirstLetter } from "../../../utils/utils";


interface Patient {
  id: number;
  firstName: string;
  lastName: string;
  email: string
}

const BillingPayment = () => {

  const [searchInputPatient, setSearchInputPatient] = useState("");
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState<number>(-1);
  const [isDropdownOpenPatient, setIsDropdownOpenPatient] = useState(false);
  const [validationMessages, setValidationMessages] = useState<any>({
    doctorName: "",
    patientName: "",
    reason: "",
    type: "",
    calendar: "",
    appointmentTime: ""
  });
  const [filteredPatient, setFilteredPatient] = useState<Patient[]>([]);
  const [patients, setPatients] = useState<Patient[]>([]);
  const [selectedPatientId, setSelectedPatientId] = useState<number | null>(null);
  const [appointments, setAppointments] = useState<any>([]);
  const [selectedAppointment, setSelectedAppointment] = useState<any>(null);
  const [address, setAddress] = useState<any>({
    name: "",
    address: "",
    phone: "",
    city: "",
    email: "",
    zipCode: ""
  });
  const [formData, setFormData] = useState<any>({
    appointment: {
      value: "",
      error: ""
    },
    services: {
      value: "",
      error: ""
    },
    amount: {
      value: "10.00",
      error: ""
    },
    treatmentDetail: {
      value: "",
      error: ""
    },
    paymentMethod: {
      value: "creditCard",
      error: ""
    },
  });
  const [creditCard, setCreditCard] = useState<any>({
    name: "",
    number: "",
    expiry: "",
    cvv: ""
  });
  const [error, setError] = useState({
    patient: "",
    appointment: "",
    amount: "",
    creditCard: {
      name: "",
      number: "",
      expiry: "",
      cvv: ""
    },
    address: {
      name: "",
      address: "",
      phone: "",
      city: "",
      email: "",
      zipCode: ""
    }
  });
  const [tab, setTab] = useState("request");
  const [serviceTab, setServiceTab] = useState("appointments");
  const [loading, setLoading] = useState(false);
  const [amountPayable, setAmountPayable] = useState({
    serviceAmount: 10.00,
    tax: 0,
    discount: 0,
    totalAmount: 0
  });
  const currentUser = useContext(RoleContext);
  const navigate = useNavigate();
  const location = useLocation();

  const {
    getCardNumberProps,
    getCardImageProps,
    getCVCProps,
    getExpiryDateProps,
    meta: { erroredInputs }
  } = useCreditCardValidator({ expiryDateValidator: expDateValidate });

  function expDateValidate(month: string, year: string) {
    if (Number(year) > 2035) {
      return 'Expiry Date Year cannot be greater than 2035';
    }
    return;
  }

  useEffect(() => {
    getUserspatient();
    // totalAmountCalculator();
    if (location.state?.patientId) {
      setSearchInputPatient(location.state.patientName);
      setSelectedPatientId(location.state.patientId);
      fetchAppointmentsOfUser(location.state.patientId);
    }
    document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - New Payment`;
  }, [location?.state?.patientId, currentUser]);

  // useEffect(() => {
  //   if(location?.state?.patientId) {
  //     fetchAppointmentsOfUser();
  //   }
  // }, [selectedPatientId, location?.state?.patientId]);

  async function totalAmountCalculator(amount?: number) {
    const discountRes = await getOneSetting('discount');
    const taxRes = await getOneSetting('tax');
    const serviceAmount = amount ? Number(amount) : Number(amountPayable?.serviceAmount);
    const tax = (Number(taxRes?.data?.settingsValue) * serviceAmount) / 100;
    const discount = serviceAmount * (Number(discountRes?.data?.settingsValue) / 100);
    const totalAmount = serviceAmount + tax - discount;
    setAmountPayable((prevState: any) => ({ ...prevState, tax: Number(tax?.toFixed(2)), discount: Number(discount?.toFixed(2)), totalAmount, serviceAmount }));
  }

  async function fetchAppointmentsOfUser(patientId?: any) {
    const response = await getPendingAppointments(patientId);
    if (location?.state?.patientId) {
      const appointment = response?.data?.find((item: any) => item?.order?.orderId == location?.state?.orderId);
      setSelectedAppointment(appointment);
      const order = appointment?.order;
      setFormData((prevState: any) => ({ ...prevState, appointment: { ...prevState.appointment, value: appointment?.id }, amount: { ...prevState.amount, value: order?.totalAmount } }));
      setAmountPayable((prevState: any) => ({ ...prevState, tax: order?.totalTax, discount: order?.totalDiscount, totalAmount: order?.totalAmount, serviceAmount: order?.totalAmount - order?.totalTax + order?.totalDiscount }));
    }
    setAppointments(response?.data);
  }

  function handleFormData(e: any) {
    const { name, value } = e.target;
    setFormData((prevState: any) => ({ ...prevState, [name]: { ...prevState[name], value } }));
    if (name === "appointment") {
      const appointment = appointments.find((appointment: any) => appointment.id == value);
      const order = appointment?.order;
      setSelectedAppointment(appointment);
      setFormData((prevState: any) => ({ ...prevState, amount: { ...prevState.amount, value: appointment?.order?.totalAmount } }));
      setAmountPayable((prevState: any) => ({ ...prevState, tax: order?.totalTax, discount: order?.totalDiscount, totalAmount: order?.totalAmount, serviceAmount: order?.totalAmount - order?.totalTax + order?.totalDiscount }));
    }
    if (name === "amount" && serviceTab != "appointments") {
      totalAmountCalculator(value)
    }
    setError((prevState) => ({ ...prevState, [name]: "" }))
  }


  const getUserspatient = () => {
    getallpatient("undefined", location.state?.hospitalId).then(
      (response) => {
        const patientData = response?.result;
        setPatients(patientData)
        // setFilteredPatient(patientData)
      },
      (error) => {
        const resMessage =
          (error?.response &&
            error?.response?.data &&
            error?.response?.data?.message) ||
          error?.message ||
          error?.toString();
      }
    );
  }

  const handleChangePatient = async (e: any) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchInputPatient(searchValue);
    setSelectedSuggestionIndex(-1);
    if (!searchValue.trim()) {
      setError({ ...error, patient: 'Please enter a patient name' });
      // setFilteredPatient([]);
    } else {
      setError({ ...error, patient: '' });
    }

    if (searchValue.trim() === '') {
      // setPatients(filteredPatient); 
      setIsDropdownOpenPatient(false);

      return;
    }

    const [firstName, lastName] = searchValue.split(' ');

    const filtered = patients.filter(
      (patient) =>
        patient.firstName.toLowerCase().includes(firstName) ||
        patient.lastName.toLowerCase().includes(lastName) ||
        patient.id == searchValue ||
        patient.email.toLowerCase().includes(searchValue)
    );
    setFilteredPatient(filtered);

    setIsDropdownOpenPatient(true);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        setSelectedSuggestionIndex((prevIndex) => (prevIndex < filteredPatient.length - 1 ? prevIndex + 1 : prevIndex));
        break;
      case 'ArrowUp':
        event.preventDefault();
        setSelectedSuggestionIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
        break;
      case 'Enter':
        event.preventDefault();
        if (selectedSuggestionIndex !== -1) {
          setSearchInputPatient(`${filteredPatient[selectedSuggestionIndex].firstName} ${filteredPatient[selectedSuggestionIndex].lastName}`);
          setIsDropdownOpenPatient(false);
          setSelectedPatientId(filteredPatient[selectedSuggestionIndex].id);
          setSelectedSuggestionIndex(-1);
          fetchAppointmentsOfUser(filteredPatient[selectedSuggestionIndex].id)
          // setBillingAddress(filteredPatient[selectedSuggestionIndex])
        }
        break;
      default:
        break;
    }
  };

  const handlePatientClick = (patient: any) => {
    setSearchInputPatient(`${patient.firstName} ${patient.lastName}`)
    setIsDropdownOpenPatient(false);
    setSelectedPatientId(patient.id);
    fetchAppointmentsOfUser(patient.id)
    setSelectedSuggestionIndex(-1);
    // setBillingAddress(patient);
  }

  function setBillingAddress(patient: any) {
    var address: string = "";
    patient.userdata.map((patient: any) => {
      if (patient.fieldKey == "address") {
        address = JSON.parse(patient.fieldValue)
      }
    })
    setAddress({
      name: patient.firstName + " " + patient.lastName,
      phone: patient.phoneNumber,
      email: patient.email,
      address: address
    });
  }


  function handleAddressChange(e: any) {
    const { name, value } = e.target;
    setAddress((prevState: any) => ({ ...prevState, [name]: value }));
    setError((prevState) => ({ ...prevState, address: { ...prevState.address, [name]: "" } }));
  }

  function handleCreditCard(e: any) {
    const { name, value } = e.target;
    setCreditCard((prevState: any) => ({ ...prevState, [name]: value }));
    setError((prevState) => ({ ...prevState, creditCard: { ...prevState.creditCard, [name]: "" } }));
  }


  async function handleChargePatient() {
    let validationSuccess = true;
    if (selectedPatientId == null) {
      setError((prevState) => ({ ...prevState, patient: "Please Enter a patient name" }));
      validationSuccess = false;
    }
    if (serviceTab === "appointments" && selectedAppointment == null) {
      setError((prevState) => ({ ...prevState, appointment: "Please Select an appointment or service" }));
      validationSuccess = false;
    }
    if (formData.amount.value == "") {
      setError((prevState) => ({ ...prevState, amount: "Please Enter an amount" }));
      validationSuccess = false;
    }
    if (serviceTab === "services" && formData.services.value == "") {
      setError((prevState) => ({ ...prevState, appointment: "Please Select an appointment or service" }));
    }
    if (formData.paymentMethod.value === "creditCard") {
    for (let key in creditCard) {
      if (creditCard[key] == "") {
        setError((prevState) => ({ ...prevState, creditCard: { ...prevState.creditCard, [key]: "This field is required" } }));
        validationSuccess = false;
      }
    }
    }

    for (let key in address) {
      if (address[key] == "") {
        setError((prevState) => ({ ...prevState, address: { ...prevState.address, [key]: "This field is required" } }));
        validationSuccess = false;
      }
    }
    if (validationSuccess) {
      setLoading(true);
      const obj = {
        paymentType: formData.paymentMethod.value,
        amount: formData.amount.value,
        paymentDetail: {},
        billingAddress: address
      }
      if (formData.paymentMethod.value === "creditCard") {
        let paymentDetail: any = {
          ACCT: creditCard.number?.replace(/ /g, ""),
          EXPDATE: creditCard.expiry?.replace(" / ", ""),
          CVV2: creditCard.cvv,
          TENDER: "C",
          BILLTOFIRSTNAME: creditCard.name.split(" ")[0]
        }
        if (creditCard.name.split(" ")[1] != undefined) {
          paymentDetail.BILLTOLASTNAME = creditCard.name.split(" ")[1]
        }
        obj.paymentDetail = paymentDetail;
      }
      if (serviceTab === "appointments") {
        const response = await makePaymentThroughHospital(obj, selectedAppointment.order.invoiceId);
        setLoading(false);
        if (response.data.success) {
          alert(`Payment Successfull with transaction Id ${response.data.data.transactionId}`);
          navigate(`/${currentUser}/billing`);
        } else {
          alert(`Payment failed with transaction Id ${response.data.data.transactionId}`)
        }
      } else {
        const response = await chargePaymentOtherServices(selectedPatientId, formData.amount.value, (formData.services.value + "-" + formData.treatmentDetail.value), obj, location?.state?.hospitalId);
        setLoading(false);
        if (response.data.success) {
          alert(`Payment Successfull with transaction Id ${response.data.data.transactionId}`);
          navigate(`/${currentUser}/billing`);
        } else {
          alert(`Payment failed with transaction Id ${response.data.data.transactionId}`)
        }
      }
    }
  }

  async function handleRequestPayment() {
    let validationSuccess = true;
    if (selectedPatientId == null) {
      setError((prevState) => ({ ...prevState, patient: "Please Enter a patient name" }));
      validationSuccess = false;
    }
    if (serviceTab === "appointments" && selectedAppointment == null) {
      setError((prevState) => ({ ...prevState, appointment: "Please Select an appointment" }));
      validationSuccess = false;
    }
    if (serviceTab === "services" && formData.services.value == "") {
      setError((prevState) => ({ ...prevState, appointment: "Please Select an appointment or service" }));
    }
    if (validationSuccess) {
      setLoading(true);
      if (serviceTab === "appointments") {
        const response = await requestPayment(selectedAppointment.patientId, selectedAppointment.order.invoiceId);
        setLoading(false);
        if (response.data.success) {
          alert("payment mail has been sent to the user");
          navigate(`/${currentUser}/billing`);
        } else {
          alert(`Something went wrong on our side. Please try again later`);
        }
      } else {
        const response = await requestPaymentOtherServices(selectedPatientId, amountPayable.serviceAmount, (formData.services.value + "-" + formData.treatmentDetail.value), location?.state?.hospitalId);
        setLoading(false);
        if (response.data.success) {
          alert("payment mail has been sent to the user");
          navigate(`/${currentUser}/billing`);
        } else {
          alert(`Something went wrong on our side. Please try again later`);
        }
      }
    }
  }

  function handleTabs(tab: string) {
    setTab(tab);
  }


  return (
    <Row>
      <div className="panel_top_section position-relative border-0 px-4">
        <h1 className="clickable" onClick={() => navigate(-1)}>Billing/ Payments</h1> <span className="textgreen poppins-500"><i className="bi bi-chevron-right"></i> Process Payment </span>
      </div>
      <Col>
        <div className="row m-0 mt-4">
          <div className="col-xl-7 px-0 px-lg-3 pb-4">
            <div className="float-start w-100 bg-white box_shadow p-4 pt-4 radius_15">
              <div className="payment_tab">
                {/* <ul className="nav nav-tabs nav-fill mb-3" >
                  <li
                    className={`nav-item clickable`}
                    role="presentation"
                    onClick={() => handleTabs("request")}
                  >
                    <a
                      className={`nav-link ${tab == "request" && "active"}`}
                      id="ex2-tab-1"
                      data-bs-toggle="tab"
                      role="tab"
                      aria-controls="ex2-tabs-1"
                      aria-selected="true"
                    >
                      Request Payment
                    </a>
                  </li>
                  <li
                    className="nav-item clickable"
                    role="presentation"
                    onClick={() => handleTabs("charge")}
                  >
                    <a
                      className={`nav-link ${tab == "charge" && "active"}`}
                      id="ex2-tab-2"
                      data-bs-toggle="tab"
                      role="tab"
                      aria-controls="ex2-tabs-2"
                      aria-selected="true"
                    >
                      Charge Patient
                    </a>
                  </li>
                </ul> */}
                <ul className="d-flex justify-content-around" >
                  <li
                    className={`clickable`}
                    role="presentation"
                    onClick={() => handleTabs("request")}
                  >
                    <a
                      className={`nav-link ${tab == "request" && "active"}`}
                      id="ex2-tab-1"
                      data-bs-toggle="tab"
                      role="tab"
                      aria-controls="ex2-tabs-1"
                      aria-selected="true"
                    >
                      Request Payment
                    </a>
                  </li>
                  <li
                    className="clickable"
                    role="presentation"
                    onClick={() => handleTabs("charge")}
                  >
                    <a
                      className={`nav-link ${tab == "charge" && "active"}`}
                      id="ex2-tab-2"
                      data-bs-toggle="tab"
                      role="tab"
                      aria-controls="ex2-tabs-2"
                      aria-selected="true"
                    >
                      Charge Patient
                    </a>
                  </li>
                </ul>
              </div>
              <div className="row col-lg-12 col-xl-12   p-lg-3">
                {/* Choose Patient */}
                <div className="row_1 mb-3 position-relative">
                  <h4 className="dark_blue poppins-700 fs-5 mb-3">1. Choose Patient </h4>
                  <span className="d-block text_sm text_gray mb-2">Full Name or Patient ID</span>
                  <input
                    type="text"
                    value={searchInputPatient}
                    onChange={handleChangePatient}
                    onKeyDown={handleKeyDown}
                    className="form-control select_drop_down shadow-none text_gray rounded-0 border-0 border-bottom mb-3"
                  />
                  <div className="text-danger">{error.patient}</div>

                  {isDropdownOpenPatient && (
                    <div>
                      {filteredPatient.length === 0 ? (
                        <div className="text_gray">No record found</div>
                      ) : (
                        <ul className="patient-dropdown open pb-2 rounded-bottom border">
                          {filteredPatient.map((patient, index) => (
                            <li
                              key={index}
                              className={index === selectedSuggestionIndex ? 'selected' : ''}
                              style={{
                                padding: '10px 20px',
                                cursor: 'pointer',
                                backgroundColor: index === selectedSuggestionIndex ? '#b0d7db' : ''
                              }}
                              onClick={() => handlePatientClick(patient)}
                            >
                              {`${patient.firstName} ${patient.lastName}`}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  )}

                  {/* <select className="form-select dark_blue" aria-label="Default select example">
                    <option>Lucy Black</option>
                    <option value="1">option</option>
                    <option value="2">option</option>
                  </select> */}
                </div>
                {/* Appointment Detail */}
                <div className="row_1 mb-4">
                  {/* <h4 className="dark_blue poppins-700 fs-5 mb-3">2.
                    <span className="">Appointment Detail</span>
                    <span className="ms-1 me-1">(Or)</span>
                    <span>Services</span>
                  </h4> */}

                  {/* <div className="d-flex">
                    <h5 className="dark_blue poppins-700 fs-5 mb-3 clickable" onClick={() => {
                      setServiceTab('appointments');
                      setFormData((prevState: any) => ({ ...prevState, ["treatmentDetail"]: { ...prevState["treatmentDetail"], value: "" } }))
                    }}
                    >
                      2. Appointment Detail
                    </h5>
                    <span className="me-2">(Or)</span>
                    <h5 className="dark_blue poppins-700 fs-5 mb-3 clickable"
                      onClick={() => {
                        setServiceTab('services')
                      }}
                    >Services</h5>
                  </div> */}
                  <div className="service_tab">
                    <ul>
                      <h5 className="dark_blue poppins-700 fs-5 me-2">2.</h5>
                      <li
                        className={`clickable`}
                        role="presentation"
                        onClick={() => {
                          setServiceTab('appointments');
                          setFormData((prevState: any) => ({ ...prevState, ["treatmentDetail"]: { ...prevState["treatmentDetail"], value: "" } }))
                        }}
                      >
                        <a
                          className={`nav-link fs-5 ${serviceTab == "appointments" && "active"}`}
                          id="ex2-tab-1"
                          data-bs-toggle="tab"
                          role="tab"
                          aria-controls="ex2-tabs-1"
                          aria-selected="true"
                        >
                          Appointment Detail
                        </a>
                      </li>
                      <span className="ms-1 me-1">(OR)</span>
                      <li
                        className="clickable"
                        role="presentation"
                        onClick={() => {
                          setServiceTab('services')
                        }}
                      >
                        <a
                          className={`nav-link fs-5 ${serviceTab != "appointments" && "active"}`}
                          id="ex2-tab-2"
                          data-bs-toggle="tab"
                          role="tab"
                          aria-controls="ex2-tabs-2"
                          aria-selected="true"
                        >
                          Services
                        </a>
                      </li>
                    </ul>
                  </div>
                  {serviceTab === "appointments" ?
                    < div className="col-xl-12">
                      <span className="d-block text_sm text_gray mb-2">Select the appointment you want to pay for.</span>
                      <select
                        className="form-select dark_blue"
                        aria-label="Default select example"
                        value={formData.appointment.value}
                        onChange={handleFormData}
                        name="appointment"
                      >
                        {appointments.length > 0 ?
                          <>
                            <option value="-1">Select...</option>
                            {appointments.map((appointment: any) => {
                              return (
                                <option value={appointment.id}>Appointment for {appointment.reason} at {appointment.date} {appointment.time}</option>
                              )
                            })}
                          </>
                          :
                          <option value="-1">No Data</option>
                        }
                      </select>
                      <div className="text-danger">{error.appointment}</div>
                    </div>
                    :
                    < div className="col-xl-12">
                      <span className="d-block text_sm text_gray mb-2">Select the Service you want to pay for.</span>
                      <select
                        className="form-select dark_blue"
                        aria-label="Default select example"
                        value={formData.services.value}
                        onChange={handleFormData}
                        name="services"
                      >
                        <option value="-1">Select</option>
                        <option value="Lab Test">Lab Test</option>
                        <option value="Scan">Scan</option>
                      </select>
                      <div className="text-danger">{error.appointment}</div>
                    </div>
                  }
                </div>

                {/* Treatments Detail */}
                <div className="row_1 mb-4">
                  <h4 className="dark_blue poppins-700 fs-5 mb-3">3. Treatments Detail</h4>
                  <div className="col-xl-12">
                    <span className="d-block text_sm text_gray mb-2">Specify the Services Provided</span>
                    <input
                      type="text"
                      placeholder="Services"
                      className="form-control"
                      id="treatment"
                      value={serviceTab === "appointments" ? selectedAppointment?.reason : formData.treatmentDetail.value}
                      onChange={handleFormData}
                      name="treatmentDetail"
                      disabled={serviceTab === "appointments" ? true : false}
                    />
                  </div>
                </div>
                {tab === "charge" &&
                  <>
                    {/* Payment Details */}
                    <div className="row row_1 mb-4 m-0 p-0">
                      <h4 className="dark_blue poppins-700 fs-5 mb-3">4. Payment Details</h4>
                      <div className="col-lg-12 col-xl-12">
                        <span className="d-block text_sm text_gray mb-2">Amount</span>
                        <input
                          type="text"
                          placeholder="Amount"
                          className="form-control"
                          id="amount"
                          value={formData.amount.value}
                          onChange={handleFormData}
                          name="amount"
                        />
                        <div className="text-danger">{error.amount}</div>
                      </div>
                      <div className="col-lg-12 col-xl-12 mt-3">
                        <span className="d-block text_sm text_gray mb-2">Payment Method</span>
                        <div className="d-flex flex-wrap">
                          <CustomToolTip title="Pay through credit card" placement="left">
                            <div
                              className={`payment-method me-3 rounded ${formData.paymentMethod.value === "creditCard" ? "app-border" : ""}`}
                              onClick={() => handleFormData({ target: { name: "paymentMethod", value: "creditCard" } })}
                            >
                              <i className="bi bi-credit-card fs-1" />
                            </div>
                          </CustomToolTip>
                          <CustomToolTip title="Pay through cash" placement="right">
                            <div
                              className={`payment-method rounded ${formData.paymentMethod.value === "Cash" ? "app-border" : ""}`}
                              onClick={() => handleFormData({ target: { name: "paymentMethod", value: "Cash" } })}
                            >
                              <i className="bi bi-cash fs-1" />
                            </div>
                          </CustomToolTip>
                        </div>
                        {/* <select
                          className="form-select dark_blue"
                          aria-label="Default select example"
                          value={formData.paymentMethod.value}
                          onChange={handleFormData}
                          name="paymentMethod"
                        >
                          <option value="creditCard">Credit Card</option>
                        </select> */}
                      </div>
                    </div>
                    {/* Credit Card Details */}
                    {formData.paymentMethod.value === "creditCard" ?
                      <div className="row row_1 mb-4 m-0 p-0">
                        <h4 className="dark_blue poppins-700 fs-5 mb-3">Credit Card Details</h4>
                        <div className="col-xl-12 mb-3">
                          <span className="d-block text_sm text_gray mb-2">Cardholder Name</span>
                          <input
                            type="text"
                            placeholder="Cardholder Name"
                            className="form-control"
                            id="name2"
                            value={creditCard.name}
                            onChange={handleCreditCard}
                            name="name"
                          />
                          <div className="text-danger">{error.creditCard.name}</div>
                        </div>
                        <div className="col-xl-6">
                          <span className="d-block text_sm text_gray mb-2">Card Number</span>
                          <div className="position-relative">
                            <input
                              // type="text"
                              // placeholder="**** **** **** ****"
                              className="form-control"
                              // id="card_number"
                              value={creditCard.number}
                              // onChange={handleCreditCard}
                              // name="number"
                              max={16}
                              {...getCardNumberProps({ onChange: (e) => handleCreditCard(e), name: "number", id: "card_number" })}
                            />
                            <div className="position-absolute" style={{ bottom: "13px", right: "10px" }}>
                              <svg {...getCardImageProps({ images })} />
                            </div>
                          </div>
                          <div className="text-danger">{error.creditCard.number}</div>
                        </div>
                        <div className="row col-xl-6 m-0 p-0">
                          <div className="col-sm-6">
                            <span className="d-block text_sm text_gray mb-2">Date</span>
                            <input
                              // type="text"
                              // placeholder="1/24"
                              className="form-control"
                              // id="Date"
                              value={creditCard.expiry}
                              // onChange={handleCreditCard}
                              // name="expiry"
                              {...getExpiryDateProps({ onChange: (e) => handleCreditCard(e), name: "expiry", id: "Date" })}
                            />
                            <div className="text-danger">{error.creditCard.expiry}</div>
                          </div>
                          <div className="col-sm-6">
                            <span className="d-block text_sm text_gray mb-2">CCV</span>
                            <input
                              // type="text"
                              // placeholder="000"
                              className="form-control"
                              // id="ccv"
                              // value={creditCard.cvv}
                              // onChange={handleCreditCard}
                              // name="cvv"
                              value={creditCard.cvv}
                              {...getCVCProps({ onChange: (e) => handleCreditCard(e), name: "cvv", placeholder: "CVV" })}
                            />
                            <div className="text-danger">{error.creditCard.cvv}</div>
                          </div>
                        </div>
                      </div>
                      :
                      <div className="mb-4">
                        Cash collected by
                        <span className="fw-bolder ms-2">
                          {getCurrentUser()?.firstname + " " + getCurrentUser()?.lastname}
                        </span>
                      </div>
                    }
                    {/* Billing Address */}
                    <div className="row row_1 mb-2 m-0 p-0">
                      <h4 className="dark_blue poppins-700 fs-5 mb-3">5. Billing Address</h4>
                      <div className="row_1 float-start col-lg-6 mb-3">
                        <span className="d-block text_xs text_gray mb-2">Full Name</span>
                        <input
                          type="text"
                          placeholder="Name"
                          className="form-control"
                          id="card_number"
                          value={address.name}
                          onChange={handleAddressChange}
                          name="name"
                        />
                        <div className="text-danger">{error.address.name}</div>
                      </div>
                      <div className="row_1 float-start col-lg-6 mb-3">
                        <span className="d-block text_xs text_gray mb-2">Address</span>
                        <input
                          type="text"
                          placeholder="Address"
                          className="form-control"
                          id="card_number"
                          value={address.address}
                          onChange={handleAddressChange}
                          name="address"
                        />
                        <div className="text-danger">{error.address.address}</div>
                      </div>
                      <div className="row_1 float-start col-lg-6 mb-3">
                        <span className="d-block text_xs text_gray mb-2">Phone </span>
                        <input
                          type="text"
                          placeholder="Phone Number"
                          className="form-control"
                          id="card_number"
                          value={address.phone}
                          onChange={handleAddressChange}
                          name="phone"
                        />
                        <div className="text-danger">{error.address.phone}</div>
                        {/* <p className="m-0 poppins-500 dark_blue fs-6">123456789</p> */}
                      </div>
                      <div className="row_1 float-start col-lg-6 mb-3">
                        <span className="d-block text_xs text_gray mb-2">City </span>
                        <input
                          type="text"
                          placeholder="City"
                          className="form-control"
                          id="card_number"
                          value={address.city}
                          onChange={handleAddressChange}
                          name="city"
                        />
                        <div className="text-danger">{error.address.city}</div>
                        {/* <p className="m-0 poppins-500 dark_blue fs-6">Dallas</p> */}
                      </div>
                      <div className="row_1 float-start col-lg-6 mb-3">
                        <span className="d-block text_xs text_gray mb-2">Email </span>
                        <input
                          type="text"
                          placeholder="Email"
                          className="form-control"
                          id="card_number"
                          value={address.email}
                          onChange={handleAddressChange}
                          name="email"
                        />
                        <div className="text-danger">{error.address.email}</div>
                        {/* <p className="m-0 poppins-500 dark_blue text-break fs-6">lucy.black@example.com</p> */}
                      </div>
                      <div className="row_1 float-start col-lg-6 mb-3">
                        <span className="d-block text_xs text_gray mb-2">ZIP Code </span>
                        <input
                          type="text"
                          placeholder="Zip code"
                          className="form-control"
                          id="card_number"
                          value={address.zipCode}
                          onChange={handleAddressChange}
                          name="zipCode"
                        />
                        <div className="text-danger">{error.address.zipCode}</div>
                      </div>
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
          <div className="col-xl-5 px-0 px-lg-3 pb-4">
            <div className="float-start w-100 bg-white box_shadow pb-4 p-lg-4 pt-4 radius_15">
              <div className="float-start w-100 demo_card">
                <div className="card_image">
                  <img src={card} alt="card" />
                </div>
                <div className="card_details">
                  <span className="card_type text-white d-block card_name text-uppercase fs-5 poppins-700">Visa</span>
                  <div className="card_name d-flex justify-content-between">
                    <div className="card_number">
                      <span className="text-white d-block fs-4 mb-2">{creditCard.name}</span>
                      <span className="text-white d-block">****  ****  ****  1234</span>
                    </div>
                    <div className="valid">
                      <span className="text-white d-block text_sm valid mb-2">VALID</span>
                      <span className="text-white d-block valid_num">01/24</span>
                    </div>
                  </div>
                </div>
              </div>
              <h2 className="textgreen text-center poppins-700 mb-4 pb-2">PAYMENT SUMMARY</h2>
              <div className="payment_top border-bottom px-4">
                <div className="d-flex justify-content-between pb-4">
                  <div className="col-lg-6">
                    <span className="d-block fs-6 text_gray ">Services Provided</span>
                    <p className="m-0 poppins-600 dark_blue fs-6">{serviceTab === "appointments" ? selectedAppointment?.reason : formData.treatmentDetail.value}</p>
                  </div>
                  <div className="col-lg-6">
                    <p className="m-0 poppins-500 dark_blue fs-5 float-end">$ {amountPayable.serviceAmount?.toFixed(2)}</p>
                  </div>
                </div>
                {/* Discounts & Offers */}
                <div className="d-flex justify-content-between pb-4">
                  <div className="col-lg-6">
                    <span className="d-block fs-6 text_gray ">Discounts & Offers</span>
                  </div>
                  <div className="col-lg-6">
                    <p className="m-0 poppins-500 dark_blue fs-5 float-end">$ {amountPayable.discount?.toFixed(2)}</p>
                  </div>
                </div>
              </div>
              {/* tax/total */}
              <div className="payment_top pb-2 px-4 pt-4 ">
                <div className="d-flex justify-content-between pb-2">
                  <div className="col-lg-6">
                    <span className="d-block fs-6 text_gray ">Tax</span>
                  </div>
                  <div className="col-lg-6">
                    <p className="m-0 poppins-500 dark_blue fs-5 float-end">$ {amountPayable.tax?.toFixed(2)}</p>
                  </div>
                </div>
                {/* Discounts & Offers */}
                <div className="d-flex justify-content-between pb-2">
                  <div className="col-lg-6">
                    <span className="d-block fs-6 dark_blue poppins-700 ">Total</span>
                  </div>
                  <div className="col-lg-6">
                    <p className="m-0 poppins-700 dark_blue fs-5 float-end">$ {amountPayable.totalAmount?.toFixed(2)}</p>
                  </div>
                </div>
              </div>
              {/* payment-button */}
              <div className="float-start w-100 payment_button px-4 mt-3">
                {tab === "request" ?
                  <button
                    className="my-1 border-0  payment_request  poppins-500 text-white w-100  px-3"
                    type="button"
                    onClick={handleRequestPayment}
                    disabled={loading}
                    style={{ opacity: loading ? 0.5 : 1 }}
                  >
                    SEND REQUEST
                    <span className="ms-2">
                      {loading &&
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      }
                    </span>
                  </button>
                  :
                  <button
                    className="my-1 border-0 charge_patient bg_dark_blue poppins-500 text-white w-100  px-3"
                    type="button"
                    disabled={loading}
                    style={{ opacity: loading ? 0.5 : 1 }}
                    // onClick={createOnePayment}
                    onClick={handleChargePatient}
                  >
                    CHARGE PATIENT
                    <span className="ms-2">
                      {loading &&
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      }
                    </span>
                  </button>
                }
              </div>

            </div>
          </div>
        </div>
      </Col>
    </Row >
  );
};

export default BillingPayment;