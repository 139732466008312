import React, { useContext, useEffect, useState } from 'react'
import { createadminsetting, deleteoneadmin, getalladmin, getoneadmin, updateadminsetting } from '../../../services/subscription-service';
import { useLocation, useNavigate } from 'react-router-dom'

import { Dropdown } from 'react-bootstrap';
import { RoleContext } from '../../../utils/contexts/RoleContext';

function EditSubscriptionSales() {

  const location = useLocation();
  const currentUser = useContext(RoleContext);




  const [err, setErr] = useState<string>("")
  const [values, setValues] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
const [dropDownValue, setDropDownValues] = useState("")

  const navigate = useNavigate();
  // const history = useHistory()

  useEffect(() => {
    getUserDetails(location?.state?.key);
  }, [])


  const getUserDetails = (key:string) => {
    getoneadmin(key).then(
        (data) => {
            const user = data.subscription;
// setDropDownValues(data.admin.setting_type)
          setValues({ ...user })
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
          setLoading(false);
          setMessage(resMessage);
        }
      );





 

  }

  const handleSelect = (event: any) => {
    setDropDownValues(event)
  
  };

  const FormOnChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setValues({ ...values, [name]: value });
  }



  const clickSubmit = (e: any) => {
    e.preventDefault();

    // values.setting_type = dropDownValue

    updateadminsetting(location?.state?.key, values).then(
        (data) => {
            // navigate(location?.state?.path);
            navigate(`/${currentUser}/sales/subscriptionsales`)
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
          setLoading(false);
          setMessage(resMessage);
        }
      );

  

  }
  return (
    <div className="container pt-5">
    
    
      {
        err ?
          <div className="alert alert-danger alert-dismissible fade show" role="alert">
            <strong>{err}</strong>
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div> : ""
      }


      <form onSubmit={clickSubmit} className='form-grid'>
      <div style={{ fontWeight: "bold", fontSize: 22 }}>Edit Payment</div>

        <div className="form-group">
          <label className="text-muted">OrderId</label>
          <input type="number" className="form-control" name='OrderId' defaultValue={values.orderId} onChange={FormOnChange} disabled />
        </div>
        <div className="form-group">
          <label className="text-muted">Order Date</label>
          <input type="text" className="form-control" name='order_date' defaultValue={values.order_date} onChange={FormOnChange} disabled />
        </div>
        <div className="form-group">
          <label className="text-muted">Hospital Name</label>
          <input type="text" className="form-control" name='hospital_name' defaultValue={values.hospital_name} onChange={FormOnChange} disabled/>
        </div>
        <div className="form-group">
          <label className="text-muted">Payment Status</label>
          <input type="text" className="form-control" name='payment_status' defaultValue={values.payment_status} onChange={FormOnChange} disabled />
        </div>
        <div className="form-group">
          <label className="text-muted">Invoice Number</label>
          <input type="number" className="form-control" name='invoice_number' defaultValue={values.invoice_number} onChange={FormOnChange} disabled />
        </div>
        <div className="form-group">
          <label className="text-muted">Total Amount</label>
          <input type="number" className="form-control" name='total_amount' defaultValue={values.total_amount} onChange={FormOnChange} />
        </div>
         
      



                <button className="btn btn-primary">
          Submit
        </button>
                    
      </form>
    </div>
  )
}

export default EditSubscriptionSales

