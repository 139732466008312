import * as AuthService from "../../../services/auth.service";

import { useContext, useEffect, useState } from 'react'

import Dropdown from 'react-bootstrap/Dropdown';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { RoleContext } from '../../../utils/contexts/RoleContext';
import { getHospitals } from '../../../services/user.service';
import { useNavigate } from 'react-router-dom';
import constants from "../../../config/constants";
import { capitalizeFirstLetter } from "../../../utils/utils";

function FormUpdate() {

    // const {state,dispatch} = useContext(AuthContext)
    const [dropDownValue, setDropDownValues] = useState("")

    const [phoneNumber, setPhoneNumber] = useState("")
    const [err, setErr] = useState("")
    const navigate = useNavigate();
    const [formName, setFormName] = useState("")
    const [users, setUsers] = useState<any>([])
    const [item, setItems] = useState<any>([])
    const [hospitalId, setHospitalId] = useState(0)
    const [currentuser, setCurrentUser] = useState<String>("");
    const [isChecked, setIsChecked] = useState(true);
    const currentUser = useContext(RoleContext);
    const [error, setError] = useState<any>({
        formName: "",
        dropDownValue: ""
    });
    // const history = useHistory()


    useEffect(() => {
        const value = AuthService?.getCurrentUser();
        setCurrentUser(value?.role);
        getAllHospitals();
        document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Form Update`;
    }, [currentUser]);

    useEffect(() => {
        if (isChecked) {
            setHospitalId(0);
        }
    }, [isChecked])

    async function getAllHospitals() {
        getHospitals().then((response) => {
            setItems(response?.data)
        })
            .catch(e => console.log(e));
    }


    const clickSubmit = async (e: any) => {
        e.preventDefault();
        const check = await handleValidation();
        if (check) {
        navigate(`/${currentUser}/drag`, { state: { value1: formName, value: dropDownValue, hospitalid: hospitalId } })
        }
    }

    const handleSelect = (event: any) => {
        setDropDownValues(event)

    };

    const handleOnSearch = (string: any, results: any) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.

    }

    const handleOnHover = (result: any) => {
        // the item hovered

        // setHospitalId(result?.id)
    }

    const handleOnSelect = (item: any) => {
        // the item selected

        setHospitalId(item?.id)
    }

    const handleOnFocus = () => {

    }

    const handleValidation = () => {
        let validationSuccess = true;
        if (formName == "") {
            setError({ formName: "Form Name is required" });
            validationSuccess = false
            return false
        } else if (dropDownValue == "") {
            setError({ dropDownValue: "Form Link is required" });
            validationSuccess = false
            return false
        }
        return validationSuccess
    }


    return (
        <div className="container pt-5">
            <div style={{ fontWeight: "bold", fontSize: 23, marginBottom: 40 }}>Form Details</div>
            {
                err ?
                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                        <strong>{err}</strong>
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div> : ""
            }


            <form onSubmit={clickSubmit} className='form-grid'>
                <div className="form-group">
                    <label className="text-muted">Form Name</label>
                    <input type="text" className="form-control mt-2" value={formName} onChange={(e) => {
                        setFormName(e.target.value);
                        setError({ formName: "" })
                    }} />
                </div>
                {error.formName !== "" &&
                    <div className="text-danger">{error.formName}</div>
                }
                {currentUser === "admin" &&
                    <>
                        <div className="field-container">
                            <input
                                name="generatePassword"
                                type="checkBox"
                                checked={isChecked}
                                onChange={() => setIsChecked(!isChecked)}
                            />
                            <span> Common for All hospitals </span>
                        </div>
                        {!isChecked &&
                            <div className="form-group mt-3">
                    <label className="text-muted">Hospital Name</label>
                                <div className="mt-2">
                    <ReactSearchAutocomplete
                        items={item}
                        onSearch={handleOnSearch}
                        onHover={handleOnHover}
                        onSelect={handleOnSelect}
                        onFocus={handleOnFocus}
                        autoFocus
                    />
                </div>
                            </div>
                        }
                    </>
                }
                {/* {hospitalId !== "" ? */}
                <div className="form-group mt-3">
                    <label className="text-muted">Form Link</label>
                    <select className="form-select mt-2" aria-label="Default select example" onChange={e => {
                        setDropDownValues(e.target.value);
                        setError({ dropDownValue: "" })
                    }}
                    >
                        <option value="-1">Select...</option>
                        <option value="Doctors Profile">Doctors Profile</option>
                        <option value="Patients Profile">Patients Profile</option>
                        <option value="Staffs Profile">Staffs Profile</option>
                        <option value="Hospitals Profile">Hospitals Profile</option>
                    </select>
                    {error.dropDownValue !== "" &&
                        <div className="text-danger">{error.dropDownValue}</div>
                    }
                    {/* <Dropdown
                        onSelect={handleSelect}
                    >
                        <Dropdown.Toggle id="dropdown-basic" style={{ width: "25%" }}>
                            {dropDownValue === "" ? "select link" : dropDownValue}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item eventKey={"Doctors Profile"} >Doctors Profile</Dropdown.Item>
                            <Dropdown.Item eventKey={"Patients Profile"}>Patients Profile</Dropdown.Item>
                            <Dropdown.Item eventKey={"Staffs Profile"}>Staffs Profile</Dropdown.Item>
                            <Dropdown.Item eventKey={"Hospitals Profile"}>Hospitals Profile</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown> */}
                    {/* <input  type="text" className="form-control" value={lastName} onChange={(e)=>setLastName(e.target.value)} /> */}
                </div>
                {/* // : null} */}

                <div className="form-group mt-4">
                    <button className="btn_gradian">
                    Save
                </button>
                </div>

            </form>
        </div>
    )
}

export default FormUpdate

