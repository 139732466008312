import receive from "../../../assets/images/billing/receive.png";
import refund from "../../../assets/images/billing/refund.png";
import money from "../../../assets/images/billing/money.png";
import {
  Row,
  Col,
} from "reactstrap";
import { useContext, useEffect, useState } from "react";
import { RoleContext, HospitalContext } from "../../../utils/contexts/RoleContext";
import { useNavigate } from "react-router-dom";
import { getorders, getOneInvoice, getOneOrder } from "../../../services/billing-service";
import { longDateGenerator, getDateOnly } from "../../../utils/utils";
import { decidePages } from "../../../utils/utils";
import Pagination from "../../../components/Pagination";
import SearchInput from "../../../components/SearchInput";
import DeleteModal from "../../../components/DeleteModal";
import { deleteOrder } from "../../../services/billing-service";
import Spinner from 'react-bootstrap/Spinner';
import CustomToolTip from "../../../components/CustomToolTip";
import constants from "../../../config/constants";
import { capitalizeFirstLetter } from "../../../utils/utils";
import { usePDF } from 'react-to-pdf';
import Logo from "../../../layouts/Logo";

const Billing = () => {
  const [orders, setOrders] = useState([]);
  const [ordersCopy, setOrdersCopy] = useState([]);
  const [pages, setPages] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [showSearch, setShowSearch] = useState(false);
  const [recordsToBeDeleted, setRecordsToBeDeleted] = useState<any>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [more, setMore] = useState();
  const [paymentDetails, setPaymentDetails] = useState<any>({});
  const [paymentStatus, setPaymentStatus] = useState(-1);
  const [loading, setLoading] = useState(false);
  const { toPDF, targetRef } = usePDF({ filename: 'invoice.pdf' });
  const [show, setShow] = useState(false);
  const [order, setOrder] = useState<any>({});
  const [address, setAddress] = useState<any>({});
  const [transaction, setTransaction] = useState<any>({});
  const [appointment, setAppointment] = useState<any>({});
  const [user, setUser] = useState<any>({});

  const currentUser = useContext(RoleContext);
  const navigate = useNavigate();
  const hospitalContext: any = useContext(HospitalContext);

  useEffect(() => {
    getAllOrders();
    document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Billings`;
  }, [currentUser, hospitalContext?.selectedHospital?.id, paymentStatus]);

  function handleFilter̥Change(e: any) {
    const { value } = e.target;
    setPaymentStatus(value);
    getAllOrders(1, value)
  }

  async function getAllOrders(page?: number, status?: number) {
    setLoading(true);
    const pageCount = page != undefined ? page : 1;
    const response = await getorders(pageCount, paymentStatus, hospitalContext?.selectedHospital?.id);
    if (response?.success) {
      setOrders(response?.data?.rows);
      setOrdersCopy(response?.data?.rows);
      setPaymentDetails(response?.paymentDetails)
    }
    setLoading(false);
    const pages = decidePages(response?.data?.count);
    setPages(pages);
    setPageNumber(pageCount);
  }

  async function deleteOneOrder(id: string) {
    const response = await deleteOrder(id);
    if (response.success) {
      getAllOrders();
    }
  }

  function handleRadioChange(item: any) {
    const deleteArray = [...recordsToBeDeleted];
    const check = deleteArray.find(((element: any) => element.orderId == item.orderId));
    if (check === undefined) {
      setRecordsToBeDeleted((prevState: any) => ([...prevState, item]))
    } else {
      setRecordsToBeDeleted((prevState: any) => (prevState.filter((element: any) => element.orderId != item.orderId)));
    }
  }

  function decideStatus(item: any) {
    let decideCheck = false
    recordsToBeDeleted.forEach((element: any) => {
      if (element.orderId == item.orderId) {
        decideCheck = true;
        return false;
      }
    })
    return decideCheck;
  }

  function handleTrash(id: string) {
    setShowDeleteModal(true);
    setDeleteId(id)
  }

  async function getInvoice(item: any) {
    const response = await getOneInvoice(item.invoiceId);
    if (response.success) {
      navigate(`/patient/payment/${response.data?.maskedInvoice}`)
    } else {
      alert("Something went wrong on our side. Please try again later.")
    }
  }

  async function fetchOneOrder(orderId: number) {
    setLoading(true);
    const response = await getOneOrder(orderId);
    console.log("resoskj", response)
    if (response.success) {
      if (response.data?.billingAddresses?.length > 0) {
        const billingAddress = [...response.data.billingAddresses];
        const lastBillingAddress = billingAddress[billingAddress.length - 1];
        setAddress(lastBillingAddress);
      }
      if (response.data?.transactions?.length > 0) {
        const transaction = [...response.data.transactions];
        const lastTransactions = transaction[transaction.length - 1];
        setTransaction(lastTransactions);
      }
      if (response.data?.user) {
        const userData = { ...response.data?.user };
        response.data?.user?.userdata?.forEach((item: any) => {
          if (item.fieldKey === "address") {
            userData.address = JSON.parse(item.fieldValue);
          }
          if (item.fieldKey === "city") {
            userData.city = JSON.parse(item.fieldValue);
          }
          if (item.fieldKey === "zipCode") {
            userData.zipCode = JSON.parse(item.fieldValue);
          }
        });
        setUser(userData);
      }

      setOrder(response.data);
      setAppointment(response.data.booking_appointment);
    }
    setLoading(false);
  }

  function handleNavigation() {
    if (currentUser === "admin") {
      navigate(`/${currentUser}/hospital`, { state: { what: "newPayment" } });
    } else {
      navigate(`/${currentUser}/billing-payment`)
    }
  }

  return (
    <>
      {!loading ?
        <Row>
          <div className="panel_top_section position-relative border-0 billing-container">
            {currentUser != "staff" &&
              <h1 className="d-block mt-2">{currentUser !== "patient" ? "Billing/ Payments" : "Payments"}</h1>}
            <div className="col-sm-12">
              {currentUser === "staff" &&
                <h5 className="d-block mt-2">Billing/ Payments</h5>}
              {(currentUser != "staff" && currentUser != "patient") &&
                <div className="row m-0 col-xl-12 float-start dashboard_box mt-4 pt-2">
                  <div className="col-xl-4 payment_box p-1">
                    <div className="payment_box_inner float-start w-100 bg_green radius_15 px-3 py-4">
                      <div className="payment_receive float-start w-100 pb-2">
                        <span className="d-inline-block icons"><img src={receive} alt="receive" /></span>
                        <p className="d-inline-block m-0 poppins-600 text-white text_sm px-1 pr-0"> Total Payment Received</p>
                      </div>
                      <div className="box_price float-start w-100 d-flex gap-3 align-items-center py-1">
                        <h2 className="text-white m-0 fs-1 poppins-600">$ {paymentDetails.totalPaymentReceived?.toFixed(2)}</h2>
                        <span className="text-white rounded-2 text_sm percentage p-1 px-2"><i className="bi bi-graph-up-arrow px-1"></i> {paymentDetails.totalReceivedPercentage}%</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 payment_box p-1">
                    <div className="payment_box_inner float-start w-100 bg_dark_blue radius_15 px-3 py-4">
                      <div className="payment_receive float-start w-100 pb-2">
                        <span className="d-inline-block icons"><img src={refund} alt="receive" /></span>
                        <p className="d-inline-block m-0 poppins-600 text-white text_sm px-1 pr-0"> Received Today </p>
                      </div>
                      <div className="box_price float-start w-100 d-flex gap-3 align-items-center py-1">
                        <h2 className="text-white m-0 fs-1 poppins-600">$ {paymentDetails.paymentReceivedToday?.toFixed(2)}</h2>
                        <span className="text-white rounded-2 text_sm bg_gray p-1 px-2"><i className="bi bi-graph-up-arrow px-1"></i>{paymentDetails.receivedTodayPercentage}%</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 payment_box p-1">
                    <div className="payment_box_inner float-start w-100 bg_green_500 radius_15 px-3 py-4">
                      <div className="payment_receive float-start w-100 pb-2">
                        <span className="d-inline-block icons"><img src={money} alt="receive" /></span>
                        <p className="d-inline-block m-0 poppins-600 text-white text_sm px-1 pr-0">  Total Balance </p>
                      </div>
                      <div className="box_price float-start w-100 d-flex gap-3 align-items-center py-1">
                        <h2 className="text-white m-0 fs-1 poppins-600">$ {paymentDetails.totalBalance?.toFixed(2)}</h2>
                        <span className="text-white rounded-2 text_sm percentage p-1 px-2"><i className="bi bi-graph-up-arrow px-1"></i>{paymentDetails.balancePercentage}%</span>
                      </div>
                    </div>
                  </div>
                </div>
              }

              {currentUser !== "patient" &&
                <div className={`col-sm-${showSearch ? "8" : "5"} grid_icons d-flex gap-1 pt-2 justify-content-around align-items-center float-end billing-filter`}>
                  <div>
                    <SearchInput
                      visible={true}
                      data={ordersCopy}
                      setData={(value: any) => setOrders(value)}
                    />
                  </div>
                  <button
                    type="button"
                    className="btn_gradian dark_btn"
                    onClick={handleNavigation}
                  >New Payment</button>
                  {showSearch &&
                    <div className="d-flex align-items-center justify-content-end">
                      <span className="me-1">Payment Status: </span>
                      <select
                        className="p-1 form-select"
                        onChange={handleFilter̥Change}
                        value={paymentStatus}
                        defaultValue={-1}
                      >
                        <option value={-1}>All</option>
                        <option value={0}>Pending</option>
                        <option value={1}>Completed</option>
                        <option value={2}>Failed</option>
                      </select>
                    </div>
                  }
                  <div className="grid_list_icons icons_patients  d-flex align-items-center gap-2">
                    {recordsToBeDeleted.length > 0 &&
                      <button
                        type="button"
                        className="bg_dark_blue text-white px-3 py-2 rounded-3"
                        onClick={() => setShowDeleteModal(true)}
                      >
                        <i className="bi bi-trash"></i>
                      </button>
                    }

                    <span
                      className="filter_icons fs-3 px-1"
                      onClick={() => setShowSearch(!showSearch)}
                    >
                      <i className="bi bi-funnel-fill"></i>
                    </span>
                  </div>
                </div>
              }
            </div>
          </div>
          <Col>
                {currentUser !== "patient" ?
                  <div className="row d-flex table-responsive m-0">
                    <table>
                      <thead>
                        <tr className="list_head billing_head">
                      <th>
                        <span className="d-block float-start px-1 bg-transparent">
                            <input
                              className="float-start form-check-input"
                              type="checkbox"
                          checked={(recordsToBeDeleted.length === orders.length && orders.length > 0) ? true : false}
                              onChange={() => {
                                if (recordsToBeDeleted.length === orders.length) {
                                  setRecordsToBeDeleted([]);
                                } else {
                                  const data = [...orders];
                                  setRecordsToBeDeleted(data);
                                }
                              }}
                            />
                        </span>
                        Invoice Number
                      </th>
                          <th>
                            <label className="p-0 bg-transparent px-2">Patient Name</label>
                          </th>
                          <th>Total Amount</th>
                          <th>Payment Received</th>
                          <th>Balance</th>
                          <th>Status</th>
                          <th>Billing Date</th>
                          <th>Due Date</th>
                            <th>Actions</th>
                        </tr>
                      </thead>

                      <tbody>
                    {orders.length > 0 ?
                      <>
                        {orders.map((order: any) => {
                          return (
                            <tr
                              className="list_detail billing_detail"
                              onClick={() => navigate(`/${currentUser}/order-detail/${order.orderId}`)
                              }
                            >
                              {/* <td></td> */}
                              <td>
                                <span className="px-1 d-block float-start">
                                <input
                                  className="float-start form-check-input"
                                  type="checkbox"
                                  onChange={() => {
                                    handleRadioChange(order);
                                  }}
                                  checked={decideStatus(order)}
                                />
                                </span>
                                {order.invoiceId ? order.invoice?.invoiceId : "-"}
                              </td>
                              <td><label className="px-2">{order.patientName}</label>
                              </td>
                              <td><span className="fw-bold">$ {order.totalAmount?.toFixed(2)}</span></td>
                              <td>$ {order.amountPaid?.toFixed(2)}</td>
                              <td>$ {(order.totalAmount - order.amountPaid)?.toFixed(2)}</td>
                              <td>
                                <span
                                  className="po_completed p-2 py-1 rounded-5 text_sm"
                                  style={{ backgroundColor: order.orderStatus == 0 || order.orderStatus == 11 ? "orange" : order.orderStatus == 1 ? "green" : "red", color: 'white' }}
                                >
                                  {order.orderStatus == 0 || order.orderStatus == 11 ? "Pending" : order.orderStatus == 1 ? "Completed" : "Failed"}
                                </span>
                              </td>
                              <td>{longDateGenerator(order.orderDate)}</td>
                              <td><span>{longDateGenerator(order.orderDueDate)}</span>
                              </td>
                              <td onClick={(e) => e.stopPropagation()}>
                                  {more !== order.orderId ?
                                    <span className="dost_btn" onClick={() => setMore(order.orderId)}>
                                      <i className="bi bi-three-dots-vertical"></i>
                                    </span>
                                    :
                                      <span className="action" >
                                    {currentUser === "hospital" &&
                                      <>
                                        {(order.orderStatus == 1 || order.orderStatus == 11) &&
                                          <CustomToolTip title="View">
                                          <button
                                            onClick={() => {
                                            navigate(`/${currentUser}/billing-detail/${order.orderId}`)
                                          }

                                          }> <i className="bi bi-eye"></i>
                                          </button>
                                          </CustomToolTip>
                                        }
                                        <CustomToolTip title="Delete">
                                        <button
                                          className="delete_btn_act"
                                          onClick={() => handleTrash(order.orderId)}
                                        >
                                          <i className="bi bi-trash"></i>
                                        </button>
                                        </CustomToolTip>
                                      </>
                                    }
                                    <CustomToolTip title="Info">
                                    <button
                                      className="delete_btn_act"
                                      onClick={() => navigate(`/${currentUser}/order-detail/${order.orderId}`)
                                      }
                                    >
                                      <i className="bi bi-exclamation-circle"></i>
                                    </button>
                                    </CustomToolTip>

                                    {(order.orderStatus != 1 && order.orderStatus != 2) &&
                                      <CustomToolTip title="Pay">
                                      <button
                                        className="delete_btn_act"
                                        onClick={() => navigate(`/${currentUser}/billing-payment`, { state: { patientId: order.userId, orderId: order.orderId, patientName: order.patientName } })
                                        }
                                      >
                                        <i className="bi bi-credit-card"></i>
                                      </button>
                                      </CustomToolTip>
                                    }
                                  </span>
                                  }

                                </td>
                            </tr>
                          )
                        })}
                      </>
                      :
                      <tr className="list_detail">
                        <td colSpan={10}>No data found</td>
                      </tr>
                    }
                      </tbody>
                      {pages?.length > 1 &&
                        <tfoot>
                          <Pagination
                            pages={pages}
                            currentPage={pageNumber}
                            onClickPageNumber={(page: any) => {
                              setPageNumber(page);
                              getAllOrders(page, paymentStatus);
                            }}
                          />
                        </tfoot>
                      }
                    </table>
                  </div>
                  :
                  <div className="row d-flex table-responsive m-0">
                    <table>
                      <thead>
                        <tr className="list_head billing_head">
                          <th>Invoice Number</th>
                          <th>Total Amount</th>
                          <th>Billing Date</th>
                          <th>Payment Status</th>
                          <th>Due Date</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                    {orders.length > 0 ?
                      <>
                        {orders.map((order: any) => {
                          return (
                            <tr
                              className="list_detail billing_detail"
                              onClick={() => navigate(`/${currentUser}/order-detail/${order.orderId}`)
                              }
                            >
                              <td>{order.invoiceId ? order.invoice?.invoiceId : "-"}</td>
                              <td><span className="fw-bold">$ {order.totalAmount?.toFixed(2)}</span></td>
                              <td>{longDateGenerator(order.orderDate)}</td>
                              <td>
                                <span
                                  className="po_completed p-2 py-1 rounded-5 text_sm"
                                  style={{ backgroundColor: order.orderStatus == 0 || order.orderStatus == 11 ? "orange" : order.orderStatus == 1 ? "green" : "red", color: 'white' }}
                                >
                                  {order.orderStatus == 0 || order.orderStatus == 11 ? "Pending" : order.orderStatus == 1 ? "Completed" : "Failed"}
                                </span>
                              </td>

                              <td>
                                <span>{longDateGenerator(order.orderDueDate)}</span>
                              </td>
                              <td onClick={(e) => e.stopPropagation()}>
                                {more !== order.orderId ?
                                  <span className="dost_btn" onClick={() => setMore(order.orderId)}>
                                    <i className="bi bi-three-dots-vertical"></i>
                                  </span>
                                  :
                                <span className="action" >
                                    <CustomToolTip title="Payment Detail">
                                    <button
                                      onClick={() => {
                                          navigate(`/${currentUser}/order-detail/${order.orderId}`)
                                    }}
                                    > <i className="bi bi-exclamation-circle"></i>
                                  </button>
                                    </CustomToolTip>
                                    {order.orderStatus == 1 &&
                                      <CustomToolTip title="Print">
                                  <button className="delete_btn_act"
                                          onClick={() => {
                                            fetchOneOrder(order.orderId);
                                            setShow(true);
                                            document.body.style.overflow = "hidden";
                                            setTimeout(() => {
                                              toPDF();
                                            }, 1000)
                                            setTimeout(() => {
                                              setShow(false);
                                              document.body.style.overflow = "scroll";
                                              setOrder({});
                                              setAppointment({});
                                              setAddress({});
                                              setUser({});
                                              setTransaction({})
                                            }, 2000)
                                          }}
                                  >
                                      <i className="bi bi-printer"></i>
                                    </button>
                                    </CustomToolTip>
                                    }
                                    {(order.orderStatus != 1 && order.orderStatus != 2) &&
                                      <CustomToolTip title="Pay">
                                      <button
                                        onClick={() => {
                                          getInvoice(order);
                                        }}
                                      >
                                        <i className="bi bi-credit-card" />
                                      </button>
                                      </CustomToolTip>
                                    }
                                </span>
                                }
                              </td>
                            </tr>
                          )
                        })}
                      </>
                      :
                      <tr className="list_detail">
                        <td colSpan={10}>No data found</td>
                      </tr>
                    }
                      </tbody>
                      {pages?.length > 1 &&
                        <tfoot>
                          <Pagination
                            pages={pages}
                            currentPage={pageNumber}
                            onClickPageNumber={(page: any) => {
                              setPageNumber(page);
                              getAllOrders(page, paymentStatus);
                            }}
                          />
                        </tfoot>
                      }
                    </table>
                  </div>
                }
            {show &&
              <div ref={targetRef}>
                <div className="p-5 bg-white">
                  <div className="d-flex justify-content-between align-item-center border-bottom border-4 border-bottom-success p-4">
                    <div>
                      <Logo />
                    </div>
                    <div>
                      <h4>Invoice</h4>
                    </div>
                    <div>
                      <p>Invoice Number: {order?.invoice?.invoiceId}</p>
                      <p>Date: {getDateOnly(new Date(order?.invoice?.createdAt))}</p>
                    </div>
                  </div>
                  <div className="d-flex pt-5">
                    <div className="w-50">
                      <h5 className="mb-2">Invoice From</h5>
                      <p className="mt-1">{address?.name}</p>
                      <p className="mt-1">{address?.email}</p>
                      <p className="mt-1">{`${address?.address}, ${address?.city}, ${address?.pinCode}.`}</p>
                      <p className="mt-1">{address?.phoneNumber}</p>
                    </div>
                    <div className="w-50">
                      <h5 className="mb-2">Invoice To</h5>
                      <p className="mt-1">{user?.firstName + " " + user.lastName}</p>
                      <p className="mt-1">{user?.email}</p>
                      <p className="mt-1">{`${user?.address ? user?.address : "" + user?.city ? ", " + user?.city + ", " : "" + user?.zipCode ? ", " + user?.zipCode + ", " : ""}`}</p>
                      <p className="mt-1">{address?.phoneNumber}</p>
                    </div>
                  </div>
                  <div className="mt-5 row d-flex">
                    <table>
                      <thead>
                        <tr className="list_head">
                          <th>Service Provided</th>
                          {appointment != null ?
                            <>
                              <th>Appointment Date</th>
                              <th>Appointment Time</th>
                            </>
                            :
                            <>
                              <th>Service Type</th>
                            </>
                          }
                          <th>Payment Date</th>
                          <th>Quantity</th>
                          <th>Item Price</th>
                          <th>Total Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="list_detail">
                          <td>{appointment != null ? `Appointment - ${order.service}` : order.service?.split("-")[0]}</td>
                          {appointment != null ?
                            <>
                              <td>{appointment.date}</td>
                              <td>{appointment.time}</td>
                            </>
                            :
                            <>
                              <td>{order.service?.split("-")[1]}</td>
                            </>
                          }
                          <td>{getDateOnly(new Date(transaction.createdAt))}</td>
                          <td>1</td>
                          <td>$ {(order.totalAmount - (order.totalTax - order.totalDiscount))?.toFixed(2)}</td>
                          <td>$ {order.totalAmount?.toFixed(2)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="mt-5 d-flex justify-content-end">
                    <div className="col-lg-4">
                      <div className="d-flex justify-content-between mt-2">
                        <p className="fw-bolder">Sub Total :</p>
                        <p>$ {(order.totalAmount - (order.totalTax - order.totalDiscount))?.toFixed(2)}</p>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <p className="fw-bolder">Tax :</p>
                        <p>$ {order.totalTax?.toFixed(2)}</p>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <p className="fw-bolder">Discount :</p>
                        <p>$ {order.totalDiscount?.toFixed(2)}</p>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <p className="fw-bolder">Total Amount :</p>
                        <p>$ {order.totalAmount?.toFixed(2)}</p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 text-center fw-bold fs-5">
                    Thank You for choosing us!
                  </div>
                </div>
              </div>
            }
          </Col>
          <DeleteModal
            show={showDeleteModal}
            handleYes={() => {
              if (deleteId) {
                deleteOneOrder(deleteId);
                setDeleteId("");
                setRecordsToBeDeleted((prevState: any) => (prevState.filter((item: any) => item.orderId != deleteId)))
              } else {
                recordsToBeDeleted.forEach((item: any, index: number) => {
                  deleteOneOrder(item.orderId);
                });
              }
              setShowDeleteModal(false);
            }}
            handleClose={() => setShowDeleteModal(false)}
            body="Do you really want to delete this Detail?"
            head="Delete Order"
          />
        </Row >
        :
        <div className='m-5 justify-content-center d-flex'>
          <Spinner />
        </div>
      }
    </>
  );
};

export default Billing;