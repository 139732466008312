import doctor_imagem from "../assets/images/doctor/doctorm.jpg";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {
  Card,
  Row,
  Col,
  CardTitle,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import React, { useContext, useEffect, useState } from "react";

import { getallDoctor } from "../../../services/doctor-service";
import { getallpatient } from "../../../services/patient-service";
import { getDoctorAppointment, oneDoctorBookingFormUpdate, bookingNotificationUpdate } from "../../../services/scheduleappointment-service";
// import { MomentInput, DurationInputArg1 } from "moment";
import moment from 'moment';
import { Value } from "sass";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { RoleContext, AlertContext } from "../../../utils/contexts/RoleContext";
import { createInvoice } from "../../../services/billing-service";
import Spinner from 'react-bootstrap/Spinner';
import constants from "../../../config/constants";
import { capitalizeFirstLetter } from "../../../utils/utils";

interface DoctorAvailability {
  doctorName: string,
  patientName: string,
  reason: string,
}

interface Doctor {
  id: number;
  firstName: string;
  lastName: string;
}
interface Patient {
  id: number;
  firstName: string;
  lastName: string;
}


const AppointmentView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [doctors, setDoctors] = useState<Doctor[]>([]);
  const [patients, setPatients] = useState<Patient[]>([])
  const [selectedDoctorId, setSelectedDoctorId] = useState<number | null>(null);
  const [selectedPatientId, setSelectedPatientId] = useState<number | null>(null);
  const [selectedDate, setSelectedDate] = useState<any>();
  const [day, setDay] = useState<any>();
  const [newDate, setNewDate] = useState<string>("");
  const [currentDate, setCurrentDate] = useState<number>();
  const [currentMonth, setCurrentMonth] = useState<string>("");
  const [appointmentTime, setAppointmentTime] = useState<any[]>([])
  const [selectedTime, setSelectedTime] = useState(null);
  const [limit, setLimit] = useState(7);
  const [showCalendar, setShowCalendar] = useState(false);
  const [filteredDoctors, setFilteredDoctors] = useState<Doctor[]>([]);
  const [filteredPatient, setFilteredPatient] = useState<Patient[]>([]);
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchInputPatient, setSearchInputPatient] = useState<string>("");
  const [selectedDoctor, setSelectedDoctor] = useState<Doctor | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpenPatient, setIsDropdownOpenPatient] = useState(false);
  const [notificationCount, setNotificationCount] = useState<any>(0);
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState<DoctorAvailability>({
    doctorName: "",
    patientName: "",
    reason: "",
  });
  const [appointmentStatus, setAppointmentStatus] = useState(0);
  const [orderId, setorderId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isAccept, setIsAccept] = useState(true);
  const [cancelRequested, setCancelrequested] = useState(false)

  const currentUser = useContext(RoleContext);
  const toast: any = useContext(AlertContext);
  const { appointmentId }: any = useParams();

  useEffect(() => {
    if (appointmentId !== undefined) {
      setLoading(true);
      oneDoctorBookingFormUpdate(appointmentId).then(
        (response) => {
          setLoading(false);
          const appointmentData = response?.appointmentBooking;
          if (Array.isArray(appointmentData) && appointmentData?.length > 0) {
            // Assuming appointmentData is an array of appointments
            appointmentData?.forEach((appointment) => {
              let dateObject = new Date(appointment?.date);
              const time = appointment?.time;
              const splittedTime = time?.split(":");
              dateObject.setHours(Number(splittedTime[0]));
              dateObject.setMinutes(Number(splittedTime[1]));
              const currentDate = new Date().getTime();
              if (currentDate > dateObject?.getTime()) {
                setIsAccept(false);
              } else {
                setIsAccept(true);
              }

              let cancelRequested = false;
              appointment?.notifications?.forEach((item: any) => {
                if (item?.notificationLinkTo == "cancel-appointment") {
                  cancelRequested = true;
                }
              });

              setCancelrequested(cancelRequested);

              // dateObject.setHours()
              const options: Intl.DateTimeFormatOptions = { weekday: 'short' };
              const selectedDay = dateObject?.toLocaleDateString('en-US', options);
              const [year, month, day] = appointment?.date?.split('-').map(Number);
              setSelectedDoctorId(appointment?.doctorId);
              setSelectedPatientId(appointment?.patientId);
              setSearchInputPatient(appointment?.patientName);
              setFormData({ ...formData, reason: appointment?.reason });
              setSearchInput(appointment?.doctorName);
              setSelectedDate(new Date(year, month - 1, day));
              setSelectedTime(appointment?.time);
              setDay(selectedDay);
              setAppointmentStatus(appointment?.status);
              handleDoctorClick(appointment?.doctorId)
              setorderId(appointment?.orderId)
            });
          } else {
            console.error('No appointment data found for the specified ID');
          }
        }
      )
      // setIsEdit(true);
    }
    document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - View Appointment - ${appointmentId}`;
  }, [currentUser, appointmentId]);

  // async function checkCancelAppointment() {

  // }

  const scheduleAppointmentDates = (workingdays: any[], docId: any) => {
    let i = 0;
    const arr = [];
    while (i < 7) {
      const obj: any = {};
      let today = new Date();
      today.setDate(today.getDate() + i);
      obj.day = today.toString().split(" ")[0];
      obj.date = today.getDate();
      obj.month = today.toString().split(" ")[1];
      obj.monthNumber = today.getMonth() + 1;
      obj.year = today.getFullYear();
      obj.bookTimeString = `${obj.year}-${obj.monthNumber < 10 ? "0" + obj.monthNumber : obj.monthNumber}-${obj.date < 10 ? "0" + obj.date : obj.date}`;
      obj.docId = docId;

      // Initialize an array to store the day's time slots
      const timeStops: { time: any; booked: boolean; }[] = [];

      workingdays.forEach((workingDay: any) => {
        if (workingDay.day === obj.day) {
          workingDay.timeSlots.forEach((workingTime: any) => {
            const startTime = moment(workingTime.from_hours, 'HH:mm')
            const endTime = moment(workingTime.to_hours, 'HH:mm').subtract(workingTime.consultation_duration, 'minutes');

            if (endTime.isBefore(startTime)) {
              endTime.add(1, 'day');
            }

            while (startTime <= endTime) {
              // const timeobj = (new moment(startTime).format('HH:mm'));
              const timeobj = moment(startTime).format('HH:mm');
              const time = { time: timeobj, booked: false };
              timeStops.push(time);
              startTime.add(workingTime.consultation_duration, 'minutes');
            }
          });
        }
      });


      obj.timing = timeStops;

      arr.push(obj);
      i++
    }
    setAppointmentTime(arr);
    return arr;

  }


  const isDateDisabled = (date: Date) => {
    return date.getTime() == selectedDate.getTime() ? false : true;
  };

  const tileDisabled = ({ date }: { date: Date }) => isDateDisabled(date);



  const handleDoctorClick = async (id: number, firstName?: string, lastName?: string) => {
    // setSearchInput(`${firstName} ${lastName}`);
    // console.log('id', id)
    setSelectedDoctorId(id);
    setShowCalendar(true);
    setIsDropdownOpen(false);
    try {
      const response = await getDoctorAppointment(id, limit);
      // console.log("vvvv", response.appointmentAvailability);
      const mergedObject = response.appointmentAvailability.reduce((result: any, obj: any) => {
        const { doc_id, day, from_hours, to_hours, consultation_duration } = obj;
        const existingEntry = result.find((entry: { doc_id: any; }) => entry.doc_id === doc_id);

        if (existingEntry) {
          const existingDay = existingEntry.days.find((d: { day: any; }) => d.day === day);
          if (existingDay) {
            existingDay.timeSlots.push({ from_hours, to_hours, consultation_duration });
          } else {
            existingEntry.days.push({ day, timeSlots: [{ from_hours, to_hours, consultation_duration }] });
          }
        } else {
          result.push({ doc_id, days: [{ day, timeSlots: [{ from_hours, to_hours, consultation_duration }] }] });
        }

        return result;
      }, []);
      // console.log("mergedObject", mergedObject);
      mergedObject.forEach((item: any) => {
        scheduleAppointmentDates(item.days, item.doc_id);
      })
      // setAppointmentTime(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    console.log("hi")
    e.preventDefault();

    try {

      const newStatus = 1;
      const Id = appointmentId;
      generateInvoice();
      const response = await bookingNotificationUpdate(Id, newStatus)
      if (response.status === 200) {
        // alert('Appointment confirmed');
        navigate(`/${currentUser}/appointment`);
        setTimeout(() => {
          toast.setShowToast("Appointment confirmed", "success")
        }, 1000)
      } else {
        alert('Internal Server Error')
      }


    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };
  const generateInvoice = async () => {
    const response = await createInvoice(orderId, selectedPatientId);
    // console.log("response", response);
  }

  const handleSubmitCancel = async (e: React.FormEvent) => {
    e.preventDefault();

    try {

      const newStatus = 2;
      const Id = appointmentId
      const response = await bookingNotificationUpdate(Id, newStatus)


      if (response.status === 200) {
        setTimeout(() => {
          toast.setShowToast("Appointment Canceled", "success")
        }, 1000)
        navigate(`/${currentUser}/appointment`)
      } else {
        alert('Internal Server Error')
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };


  return (
    <>
      {!loading ?
    <Row>

      <div className="panel_top_section">
        {/* <h1>View Appointment</h1> */}
            <h1>Appointments</h1> 
            <span className="textgreen poppins-500"><i className="bi bi-chevron-right"></i> View Appointment </span>
            <span className="textgreen poppins-500"><i className="bi bi-chevron-right"></i> {searchInputPatient} - #{appointmentId} </span>
      </div>
      <Col>
        <div className="row m-0 schedule_payment col-lg-11 mt-4 pt-2">
          <div className="col-xl-6 p-1">
            <h2 className="fs-5 poppins-600 mb-3">Schedule Details</h2>
            <div className="schedule_payment_inner bg-white radius_15 px-4 py-4">
              <div className="row_1 mt-3 px-lg-3">
                <span className="poppins-600 textgreen">Patient</span>


                <input
                  // name="doctors" 
                  list="patient"
                  value={searchInputPatient}
                  //   onChange={handleChangePatient} 

                  type="text" placeholder="Search for a patient..."
                  className="form-control select_drop_down shadow-none text_gray rounded-0 border-0 border-bottom mb-3"
                />

                {/* {isDropdownOpenPatient && ( 
                         <div id="patient" className="select_drop_down shadow-none rounded-0 pl-0 border-0 border-bottom mb-3 clickable">
                          
                          { patients.map((patient: any) => (
                            <div key= {patient.id} onClick={() => handlePatientClick(patient.firstName, patient.lastName, patient.id)} 
                            // className={index === selectedPatientIndex ? 'selected' : ''}
                           >
                              {patient.firstName + ' ' + patient.lastName}
                              
                              </div>
                          ))}
                        </div>
                         )} */}
              </div>
              <div className="row_1 px-lg-3">
                <span className="poppins-600 textgreen">Reasons</span>
                <input name="reason" value={formData.reason}
                  // onChange={handleInputChange} 
                  type="text" placeholder="" className="form-control shadow-none rounded-0 border-0 border-bottom mb-3" id="reason" />
              </div>
              <div className="row_1 mb-4 px-lg-3">
                <span className="poppins-600 textgreen">Doctor</span>


                <input
                  // name="doctors" 
                  list="doctors"
                  value={searchInput}
                  //   onChange={handleChange} 
                  type="text" placeholder="Search for a doctor..."
                  className="form-control select_drop_down shadow-none text_gray rounded-0 border-0 border-bottom mb-3"
                />

                {/* {isDropdownOpen && ( 
                         <div id="doctors" className="select_drop_down shadow-none rounded-0 pl-0 border-0 border-bottom mb-3 clickable">
                          
                          { doctors.map((doctor: any) => (
                            <div key= {doctor.id} onClick={() => handleDoctorClick(doctor.id, doctor.firstName, doctor.lastName)} >
                              {doctor.firstName + ' ' + doctor.lastName}
                              
                              </div>
                          ))}
                        </div>
                         )} */}
              </div>
            </div>
          </div>
          {/* calendar */}
          {showCalendar && (
            <div className="col-xl-6 p-1 calender_block">
              <h2 className="fs-5 poppins-600 mb-3">Select a Date</h2>
                  {selectedDate &&
              <Calendar
                // onChange={handleCalenderDateChange}
                value={selectedDate}
                tileDisabled={tileDisabled}
              />
                  }
            </div>
          )}
          {/* Select a Time */}
          <div className="col-xl-12 p-1 mt-4">
            <h4 className="fs-5 poppins-600 mb-3">Schedule Details</h4>
            <div className="date_btn bg-white radius_15 px-4 py-4">

              {appointmentTime?.map((item: any) => (
                item.day === day ? (
                  item.timing.length > 0 ? (
                    item.timing.map((time: any, index: number) => (
                      <button
                        type="button"
                        key={index}
                        value={time.time}
                            disabled={true}
                        className={time.time === selectedTime ? 'active' : ''}
                      // onClick={() => handleButtonClick(time.time)}
                      >
                        {time.time}
                      </button>
                    ))
                  ) : (
                    <div style={{ color: 'red' }}>No available time slots</div>
                  )
                ) : null
              ))}





            </div>
          </div>
          <div className="row col-xl-12 align-items-center p-1 m-0 mt-2 ">
                {/* <p className="col-lg-6 fs-5 p-0 d-inline-block m-0">You selected {currentMonth} {currentDate}th {day} at {selectedTime}</p> */}
                {(currentUser === "doctor" || currentUser === "staff" || currentUser === "hospital") &&
                  <>
                    {(cancelRequested && appointmentStatus != 2) ?
                      <div className="form_bottom_btn col-lg-12 p-0 d-flex justify-content-end pt-2">
                        <button className="btn_gradian" type="button"
                          onClick={(e) => {
                            // handleCancelAppointment()
                            handleSubmitCancel(e);
                          }}
                        >Cancel Appointment</button>
                        {/* <button
                        className={isAccept ? "btn_gradian" : "btn_gradian_disabled"}
                        type="button"
                        onClick={(e) => {
                          handleSubmit(e);
                        }}
                        // disabled={appointmentStatus != 0 ? true : false}
                        disabled={!isAccept}
                      >ACCEPT AN APPOINTMENT</button> */}
                      </div>
                      :
                      <div className="form_bottom_btn col-lg-12 p-0 d-flex justify-content-end pt-2">
                        {appointmentStatus == 1 ?
                          <p className="text-success">This appointment has been approved.</p>
                          :
                          appointmentStatus == 2 ?
                            <p className="text-danger">This appointment has been denied.</p>
                            :
                            isAccept ?
                              <>
                <button className="delete_btn" type="button"
                  onClick={(e) => {
                    handleSubmitCancel(e);
                    //    navigate("/hospital/appointment")
                  }}
                >DENY</button>
                <button
                                  className={isAccept ? "btn_gradian" : "btn_gradian_disabled"}
                  type="button"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                                  // disabled={appointmentStatus != 0 ? true : false}
                                  disabled={!isAccept}
                >ACCEPT AN APPOINTMENT</button>
                              </>
                              :
                              <p className="text-danger">This appointment has been expired.</p>
                        }
                      </div>
                    }
                  </>
                }
          </div>
        </div>
      </Col>
    </Row>
        :
        <div className='m-5 justify-content-center d-flex'>
          <Spinner />
        </div>
      }
    </>
  );
};

export default AppointmentView;
