import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  Toast,
} from "reactstrap";
import React, { useContext, useEffect, useState } from "react";
import { createDoctor, getOneDoctor, createOneHospital } from "../../../services/doctor-service";
import { getFormData, getformdetail } from "../../../services/form-service";
import { useLocation, useNavigate, useSearchParams, useParams } from "react-router-dom";

import { RoleContext, AlertContext } from "../../../utils/contexts/RoleContext";
import { getCurrentUser } from "../../../services/auth.service";
import profile from "../../../assets/images/doctor/profile.png";
import { Dropdown, ProgressBar } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import constants from "../../../config/constants";
import { capitalizeFirstLetter } from "../../../utils/utils";


const roles = [
  {
    label: "Select...",
    value: "select/null"
  },
  {
    label: "Doctor",
    value: "Doctors Profile/3",
    roleId: "3"
  },
  {
    label: "Patient",
    value: "Patients Profile/5",
    roleId: "5"
  },
  {
    label: "Staff",
    value: "Staffs Profile/4",
    roleId: "4"
  },
  // {
  //   label: "Hospital",
  //   value: "Hospitals Profile/2",
  //   roleId: "2"

  // }
]


const AddUser = () => {

  const [htmlFormUsers, sethtmlFormUsers] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [dragAllData, setDragAllData] = useState<any>([]);
  const [currentDoctor, getCurrentDoctor] = useState<any>({});
  const [dragEmpData, setDragEmpData] = useState<any>([]);
  const [personalData, setPersonalData] = useState<any>("");
  const [getvalues, setGetvalues] = React.useState<any>({});
  const [formUsers, getFormUsers] = React.useState<any>([]);
  const [dropDownValue, setDropDownValues] = useState("");
  const [roleId, setRoleId] = useState<any>("");
  const [getImage, setImage] = useState<any>("");
  const [generatePassword, setGeneratePassword] = useState(true)
  const navigate = useNavigate();
  const currentUser = useContext(RoleContext);
  const toast: any = useContext(AlertContext);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [roleName, setRoleName] = useState<string>("")
  const [progress, setProgress] = useState<any>()
  const [attachfile, setAttachedFile] = useState<any>(null)
  const [fileName, setFileName] = useState<any>('');
  const { role }: any = useParams();
  const [roleProfile, setRoleProfile] = useState<string>("");

  // useEffect(() => {
  //   // console.log("deireuriererr",searchParams.get('hospitalId'))
  //   // if (searchParams.get('profile') !== "unknown") {
  //   //   console.log("heirere",searchParams.get('profile'));
  //   //   getPersonalUsers(searchParams.get('profile'));
  //   //   setRoleId(searchParams.get('roleId'))
  //   if (roleProfile !== "unknown") {
  //     setRoleId(role)
  //     getPersonalUsers(roleProfile);
  //   }
  //   // if (location?.state?.key != undefined) {
  //   //   getDoctor();
  //   // }
  //   // createOneDoctor();
  // }, []);



  useEffect(() => {

    if (role !== null) {
      switch (role) {
        case '3':
          setRoleProfile('Doctors Profile');
          break;
        case '5':
          setRoleProfile("Patients Profile");
          break;
        case '4':
          setRoleProfile("Staffs Profile");
          break;
        case '2':
          setRoleProfile("Hospitals Profile");
          break;
        default:
          setRoleProfile("");
          break;
      }


      if (roleProfile !== "unknown" && roleProfile !== "") {
        setRoleId(role);
        getPersonalUsers(roleProfile);
      }
    }
    document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Add User`;
  }, [role, roleProfile, currentUser]);

  const handleSelect = (event: any) => {

    const profile = event.split("/")[0];
    const roleId = event.split("/")[1];

    setRoleName(profile)
    setDropDownValues(event);
    if (roleProfile !== "select") {
      getPersonalUsers(roleProfile);
      setRoleId(role);
    } else {
      sethtmlFormUsers([]);
    }
  };



  const FormOnChange = (event: any) => {
    const user = getCurrentUser();
    const { name, value, id } = event.target;
    const formType = event?.target?.id?.split("/")[3];
    const formName = event?.target?.id?.split("/")[2]
    const formId = event?.target?.id?.split("/")[1]
    const fieldId = event?.target?.id?.split("/")[0]
    // const name = event.target.name;
    // const value = event.target.value;
    // const obj = {
    //   "field_id": event.target.id,
    //   "field_key": event.target.name,
    //   "field_value": event.target.value
    // };
    // setGetvalues({ ...getvalues, [name]: value, "field_id": event.target.id });
    setGetvalues((prevState: any) => ({
      ...prevState, [formName]: { ...prevState[formName], [name]: { id: fieldId, value: value, formId: formId, formType } }
    }))
  }

  const getPersonalUsers = async (Profile: string | null) => {
    getFormData(Profile).then(
      (data) => {
        sethtmlFormUsers(data?.result)
      },
      (error) => {
        const resMessage =
          (error?.response &&
            error?.response?.data &&
            error?.response?.data?.message) ||
          error?.message ||
          error?.toString();

        setLoading(false);
        setMessage(resMessage);
      }
    );


  };








  const createOneDoctor = async () => {
    setLoading(true);
    console.log("getvaluess", getvalues['Basic Information']?.email)
    if (getvalues['Basic Information']?.firstName == undefined) {
      alert("please fill the firstname")
    } else if (getvalues['Basic Information']?.lastName == undefined) {
      alert("please fill the lastname")
    } else if (getvalues['Basic Information']?.email) {
      if (!validateEmail(getvalues['Basic Information']?.email?.value)) {
        alert("please fill the email")
      }
    } else if (getvalues['Basic Information']?.phoneNumber) {
      if (!validatePhone(getvalues['Basic Information']?.phoneNumber?.value)) {
        alert("please fill the phonenumber")
      }
    }
    // else {
    console.log("dreiruer")
    const formValues = { ...getvalues }
    for (let key in formValues) {
      for (let i in formValues[key]) {
        formValues[key][i].roleId = roleId
      }
    }

    console.log("formvaluess", formValues)
    createDoctor(formValues, location?.state?.hospitalId, generatePassword).then(
      (data) => {
        setLoading(false);
        let route: any;
        switch (roleProfile) {
          case "Doctors Profile":
            route = `/${currentUser}/doctordetaillist`;
            break;

          case "Patients Profile":
            route = `/${currentUser}/patient-list`;
            break;

          case "Staffs Profile":
            route = `/${currentUser}/stafflist`;
            break;
          case "Hospitals Profile":
            route = `/${currentUser}/getprofile`;
            break;
          case "unknown":
            route = `/${currentUser}/getprofile`;
            break;

          default:

            route = `/${currentUser}/doctordetaillist`;
        }
        // const route = searchParams.get('profile') === 'Doctors Profile' ? `/${currentUser}/doctordetaillist` :

        if (data.message) {

          navigate(route);
          setTimeout(() => {
            // navigate(route);
            toast.setShowToast(data.message, "success");
          }, 1000);
          // toast.setShowToast(data.message);
        } else {
          toast.setShowToast(data.data, "danger");
        }
      },
      (error) => {
        setLoading(false);
        let route: any;
        switch (roleProfile) {
          case "Doctors Profile":
            route = `/${currentUser}/doctordetaillist`;
            break;

          case "Patients Profile":
            route = `/${currentUser}/patient-list`;
            break;

          case "Staffs Profile":
            route = `/${currentUser}/stafflist`;
            break;
          case "Hospitals Profile":
            route = `/${currentUser}/getprofile`;
            break;
          case "unknown":
            route = `/${currentUser}/getprofile`;
            break;

          default:

            route = `/${currentUser}/doctordetaillist`;
        }
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
        // toast.setShowToast("added failed", "danger");
        navigate(route);
        setTimeout(() => {
          // navigate(route);
          toast.setShowToast("added successfully", "success");
        }, 1000);
      }
    );
  };

  const createHospital = async () => {
    console.log("get values", getvalues)
    if (getvalues['Basic Information']?.firstName == undefined) {
      alert("please fill the firstname")
    } else if (getvalues['Basic Information']?.lastName == undefined) {
      alert("please fill the lastname")
    } else if (getvalues['Basic Information']?.email) {
      if (!validateEmail(getvalues['Basic Information']?.email?.value)) {
        alert("please fill the email")
      }
    } else if (getvalues['Basic Information']?.phoneNumber) {
      if (!validatePhone(getvalues['Basic Information']?.phoneNumber?.value)) {
        alert("please fill the phonenumber")
      }
    }

    const formValues = { ...getvalues }
    for (let key in formValues) {
      for (let i in formValues[key]) {
        formValues[key][i].roleId = roleId
      }
    }

    createOneHospital(formValues, generatePassword).then(
      (data) => {
        let route: any;
        switch (roleProfile) {
          case "Doctors Profile":
            route = `/${currentUser}/doctordetaillist`;
            break;

          case "Patients Profile":
            route = `/${currentUser}/patient-list`;
            break;

          case "Staffs Profile":
            route = `/${currentUser}/stafflist`;
            break;
          case "Hospitals Profile":
            route = `/${currentUser}/getprofile`;
            break;
          case "unknown":
            route = `/${currentUser}/getprofile`;
            break;

          default:
            route = `/${currentUser}/doctordetaillist`;
        }
        // const route = searchParams.get('profile') === 'Doctors Profile' ? `/${currentUser}/doctordetaillist` :

        if (data.success) {
          navigate(route);
          setTimeout(() => {
            toast.setShowToast("Hospital has been created.", "success");
          }, 1000);
        } else {
          toast.setShowToast(data.data, "danger");
        }
      },
      (error) => {
        let route: any;
        switch (roleProfile) {
          case "Doctors Profile":
            route = `/${currentUser}/doctordetaillist`;
            break;

          case "Patients Profile":
            route = `/${currentUser}/patient-list`;
            break;

          case "Staffs Profile":
            route = `/${currentUser}/stafflist`;
            break;
          case "Hospitals Profile":
            route = `/${currentUser}/getprofile`;
            break;
          case "unknown":
            route = `/${currentUser}/getprofile`;
            break;

          default:

            route = `/${currentUser}/doctordetaillist`;
        }
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
        // toast.setShowToast("added failed", "danger");
        navigate(route);
        setTimeout(() => {
          // navigate(route);
          toast.setShowToast("added successfully", "success");
        }, 1000);
      }
    );
  }

  const validateEmail = (email: string) => {
    console.log("emailssss", email)
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email)
  };

  const validatePhone = (phone: string) => {
    var phoneNum = phone.replace(/[^\d]/g, '');
    if (phoneNum.length > 6 && phoneNum.length < 11) {
      return true;
    }
    return false
  };

  const handleFileUpload = (post: any, id: any, formid: any, formname: any) => {
    const formName = formname
    const formId = formid
    const fieldId = id
    if (post.target.files && post.target.files.length > 0) {
      const filearray: any = []
      const fileBase64: any = []
      const result: any = []
      for (let i = 0; i < post.target.files.length; i++) {
        console.log("postssss", post.target.files[i])
        let file = post.target.files[i];


        filearray.push(file.name)
        setFileName(filearray);

        let reader = new FileReader();
        if (file) {
          const maxSize = 5 * 1024 * 1024;
          if (file.size > maxSize) {
            alert('Can not upload this file. Max Size is 5MB');
            post.target.value = '';
          } else {


            const fileSize = file.size;
            const chunkSize = 1024 * 1024; // 1MB chunk size (you can adjust this as needed)
            const chunks = Math.ceil(fileSize / chunkSize);
            let uploadedChunks = 0;
            for (let i = 0; i < chunks; i++) {
              const start = i * chunkSize;
              const end = Math.min(fileSize, start + chunkSize);


              uploadedChunks++;
              //  const percentage= Math.floor((uploadedChunks * 100) / chunks)
              const percentage = Math.min(100, Math.round((uploadedChunks / chunks) * 100));
              // console.log("rpogieruer", percentage)
              setProgress(percentage)

            }



            reader.readAsDataURL(file);
            reader.onload = () => {
              // console.log("filess", file, reader)
              let obj = {
                fileName: file.name,
                result: reader.result
              }
              fileBase64.push(obj)
              // console.log("filebaseee", fileBase64)
              if (attachfile === null) {
                setAttachedFile(fileBase64);
              }
              else {
                const valueset = attachfile?.concat(fileBase64)
                setAttachedFile(valueset);
              }
              // setAttachedFile(reader.result);
              // let obj={}
              const results = { result: reader.result, fileName: file.name.split('.')[1] };

              result.push(results)
              // console.log("resultsss", result)
              // setGetvalues((prevState: any) => ({
              //   ...prevState, ['Basic Information']: { ...prevState['Basic Information'], result }
              // }))
              setGetvalues((prevState: any) => ({
                ...prevState, [formName]: { ...prevState[formName], [post.target.name]: { id: fieldId, value: result, formId: formId } }
              }))

            };
          }
        }
      }
    }
  };

  // console.log("fileamee", attachfile)

  const handleImageUpload = (post: any) => {
    let images: any = [];
    if (post.target.files && post.target.files.length > 0) {
      for (let i = 0; i < post.target.files.length; i++) {
        // setImage(post.target.files[i].name)
        let file = post.target.files[i];
        let reader = new FileReader();
        if (file) {
          reader.readAsDataURL(file);
          reader.onload = () => {
            images.push({ source: reader.result });
            // setImages([...images]);
            // setGetvalues({ ...getvalues, ['profile']: images });
            setImage(reader.result)
            setGetvalues((prevState: any) => ({
              ...prevState, ['Basic Information']: { ...prevState['Basic Information'], images }
            }))
          };
        }
      }
    }
  };

  let user: string;

  switch (roleProfile) {
    case "Doctors Profile":
      user = 'Doctor';
      break;

    case "Patients Profile":
      user = "Patient";
      break;

    case "Staffs Profile":
      user = "Staff";
      break;
    case "Hospitals Profile":
      user = "Hospital";
      break;

    default:
      user = "";
      break;

  }


  const handleDownload = (indexes?: any) => {
    attachfile.map((item: any, index: number) => {

      if (index === indexes) {
        const link = document.createElement('a');
        link.href = item?.result;
        link.download = item?.fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    })

  };

  const handleDeleteDocument = (indexes?: any) => {
    // console.log("Dteireuuerer")
    //     attachfile.map((imtess:any,indexs:any)=>{
    // //       console.log("Dtjer",indexs===index)
    // // if(indexs===index){
    // //   // setAttachedFile("");
    // // }
    //     })
    setAttachedFile(attachfile.filter((item: any, index: number) => index !== indexes))

    // setFileName("")

  };

  return (
    <Row>
      <div className="panel_top_section">
        <h1>Add {user} </h1>
      </div>
      <Col className="mt-3 mb-4">
        <div className="add_doctor_form card_box col-sm-12 col-xl-9 mx-auto py-4 px-lg-4 mt-4">
          <div className="add_doctor_fom px-4">
            <div className="row justify-content-around border-bottom mb-4 pb-4">
              <div className="col-lg-12 col-xl-4 d-flex align-items-center">
                <p className=" text-black-50 fw-medium">Profile</p>
              </div>
              <div className="col-lg-12 col-xl-8">
                <div className="profile_image">
                  <div className="col-2 col-sm-2 col-lg-2 choose_file d-inline-block">
                    <span id="profile-image-upload"> <img src={getImage !== "" ? getImage : profile} alt="profile" className="clickable rounded-circle" onClick={(e) => handleImageUpload(e)} /></span>
                  </div>
                  <input type="file" id="profile" name="profile" accept="image/*" hidden onChange={(e) => {
                    handleImageUpload(e);
                  }}></input>
                  <label htmlFor="profile" className="d-inline-block m-0  px-2 px-xl-4 clickable">Upload Profile Photo</label>
                </div>
              </div>
            </div>

            {/* personal-inoformation */}
            <div className="row justify-content-around border-bottom mb-4 pb-4">
              <div className="col-lg-12 col-xl-4">
                <p className="text-black-50 fw-medium">Personal Information</p>
              </div>
              <div className="row  col-lg-12 col-xl-8">
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="firsname" className="form-label">First Name<span className="required-color">*</span></label>
                  <input type="text" className="form-control" id={`1/10/Basic Information/${roleProfile}`} onChange={FormOnChange} name="firstName" defaultValue={currentDoctor?.firstName} required />
                </div>
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="Name" className="form-label">Last Name<span className="required-color">*</span></label>
                  <input type="text" className="form-control" id={`2/10/Basic Information/${roleProfile}`} onChange={FormOnChange} name="lastName" defaultValue={currentDoctor?.lastName} required />
                </div>
                {roleProfile !== 'Patients Profile' &&
                  <>
                    <div className="col-lg-12 col-xl-6 mb-3">
                      <label htmlFor="phone" className="form-label">Phone<span className="required-color">*</span></label>
                      <input type="text" className="form-control" id={`3/10/Basic Information/${roleProfile}`} onChange={FormOnChange} name="phoneNumber" defaultValue={currentDoctor?.phoneNumber} required />
                    </div>
                    <div className="col-lg-12 col-xl-6 mb-3">
                      <label htmlFor="email" className="form-label">Email<span className="required-color">*</span></label>
                      <input type="text" className="form-control" id={`4/10/Basic Information/${roleProfile}`} onChange={FormOnChange} name="email" defaultValue={currentDoctor?.email} required />
                    </div>
                  </>
                }

                {htmlFormUsers?.map((keys: any) => {
                  if (keys.formName === "Personal Information") {
                    return (
                      <>
                        {keys.user_form_fields.map((item: any) => {
                          return (
                            <>
                              {item.type === "select" ?
                                <div className="col-lg-12 col-xl-6 mb-3">
                                  <label htmlFor="phone" className="form-label">{item.label}</label>
                                  <select className="form-select" aria-label="Default select example" name={item.fieldKey} id={`${item.id + "/" + item.formId + "/" + keys.formName + "/" + keys.formType}`} onChange={e => FormOnChange(e)}  >
                                    <option value="-1">Select...</option>
                                    {JSON.parse(item.defaultValue)?.map((item: any, index: number) => {
                                      return (
                                        <option value={item.label} key={index + item.value}>{item.label}</option>
                                      )
                                    })}
                                  </select>
                                </div> : null}
                              {item.type == "textfield" ?
                                <div className="col-lg-12 col-xl-6 mb-3">
                                  <label htmlFor="license" className="form-label">{item.label}</label>
                                  <input type={item?.type} className="form-control" id={`${item.id + "/" + item.formId + "/" + keys.formName + "/" + keys.formType}`} onChange={FormOnChange} name={item.fieldKey} />
                                </div> : null}

                              {item.type === "datetime" ?
                                <div className="col-lg-12 col-xl-6 mb-3">
                                  <label htmlFor="phone" className="form-label">{item.label}</label>
                                  <input type="date" className="form-control" id={`${item.id + "/" + item.formId + "/" + keys.formName + "/" + keys.formType}`} onChange={FormOnChange} name={item.fieldKey} />
                                </div> : null}

                              {item.type === "textarea" ?
                                <div className="col-sm-12 mb-3 textarea">
                                  <label htmlFor="address" className="form-label">{item.label}</label>
                                  <textarea className="textarea_input form-control" id={`${item.id + "/" + item.formId + "/" + keys.formName + "/" + keys.formType}`} onChange={FormOnChange} name={item.fieldKey}></textarea>
                                </div> : null}
                            </>
                          )
                        })}
                      </>
                    )
                  }
                })}

                {roleProfile === 'unknown' &&
                  <div className="col-lg-12 col-xl-6 mb-3">
                    {/* <label htmlFor="phone" className="form-label">{item.label}</label> */}
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      // name={item.fieldKey} 
                      // id={`${item.id + "/" + item.formId + "/" + keys.formName}`} 
                      onChange={e => handleSelect(e.target.value)}
                      value={dropDownValue}
                    >
                      <option value="-1">Select..</option>
                      {roles.map((role, index: number) => <option value={role.value} key={index + role.value}>{role.label}</option>
                      )}
                      {/* {JSON.parse(item.defaultValue)?.map((item: any, index: number) => {
                        return (
                          <option value={item.value} selected key={index + item.value}>{item.label}</option>
                        )
                      })} */}
                    </select>
                  </div>
                }
                <div className="field-container">
                  <input
                    name="generatePassword"
                    type="checkBox"
                    checked={generatePassword}
                    onChange={() => setGeneratePassword(!generatePassword)}
                  />
                  <span> Generate Password </span>
                </div>
                {!generatePassword &&
                  <>
                    <div className="col-lg-12 col-xl-6 mb-3">
                      <label htmlFor="phone" className="form-label">Password</label>
                      <input type="text" className="form-control" id={`5/10/Basic Information/${roleProfile}`} onChange={FormOnChange} name="password" />
                    </div>
                    <div className="col-lg-12 col-xl-6 mb-3">
                      <label htmlFor="phone" className="form-label">Confirm Password</label>
                      <input type="text" className="form-control" id={`6/10/Basic Information/${roleProfile}`} onChange={FormOnChange} name="confirmPassword" />
                    </div>
                  </>
                }
              </div>
            </div>

            {
              roleProfile === "Patients Profile" &&
              <div className="row justify-content-around border-bottom mb-4 pb-4">
                <div className="col-lg-12 col-xl-4">
                  <p className="text-black-50 fw-medium">Contact Information</p>
                </div>
                <div className="row  col-lg-12 col-xl-8">
                  <div className="col-lg-12 col-xl-6 mb-3">
                    <label htmlFor="phone" className="form-label">Phone</label>
                    <input type="text" className="form-control" id={`3/10/Basic Information/${roleProfile}`} onChange={FormOnChange} name="phoneNumber" defaultValue={currentDoctor?.phoneNumber} />
                  </div>
                  <div className="col-lg-12 col-xl-6 mb-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input type="text" className="form-control" id={`4/10/Basic Information/${roleProfile}`} onChange={FormOnChange} name="email" defaultValue={currentDoctor?.email} />
                  </div>
                </div>
              </div>
            }

            {htmlFormUsers?.map((keys: any, index: number) => {
              if (keys.formName !== "Personal Information") {
                return (
                  <div className="row justify-content-around border-bottom mb-4 pb-4" key={index + keys.formName}>
                    <div className="col-lg-12 col-xl-4">
                      <p className="text-black-50 fw-medium">{keys.formName}</p>
                    </div>
                    <div className="row  col-lg-12 col-xl-8">
                      {keys.user_form_fields.map((item: any) => {
                        return (
                          <>
                            {item.type === "select" ?
                              <div className="col-lg-12 col-xl-6 mb-3">
                                <label htmlFor="phone" className="form-label">{item.label}</label>

                                <select
                                  className="form-select"
                                  aria-label="Default select example"
                                  name={item.fieldKey}
                                  id={`${item.id + "/" + item.formId + "/" + keys.formName + "/" + keys.formType}`}
                                  onChange={e => FormOnChange(e)}
                                >
                                  <option value="-1">Select...</option>
                                  {JSON.parse(item.defaultValue)?.map((item: any, index: number) => {
                                    return (
                                      <option value={item.label} key={index + item.value}>{item.label}</option>
                                    )
                                  })}

                                </select>


                              </div> : null}
                            {item.type == "textfield" ?
                              <div className="col-lg-12 col-xl-6 mb-3">
                                <label htmlFor="license" className="form-label">{item.label}</label>
                                <input type={item?.type} className="form-control" id={`${item.id + "/" + item.formId + "/" + keys.formName + "/" + keys.formType}`} onChange={FormOnChange} name={item.fieldKey} />
                              </div> : null}

                            {item.type === "datetime" ?
                              <div className="col-lg-12 col-xl-6 mb-3">
                                <label htmlFor="phone" className="form-label">{item.label}</label>
                                <input type="date" className="form-control" id={`${item.id + "/" + item.formId + "/" + keys.formName + "/" + keys.formType}`} onChange={FormOnChange} name={item.fieldKey} />
                              </div> : null}

                            {item.type === "textarea" ?
                              <div className="col-sm-12 mb-3 textarea">
                                <label htmlFor="address" className="form-label">{item.label}</label>
                                <textarea className="textarea_input form-control" id={`${item.id + "/" + item.formId + "/" + keys.formName + "/" + keys.formType}`} onChange={FormOnChange} name={item.fieldKey}></textarea>
                              </div> : null}
                          </>
                        )
                      })}
                    </div>
                  </div>
                )
              }
            })}



            {/* Document Uploads*/}
            {htmlFormUsers?.map((keys: any, index: number) => {
              return (

                keys.user_form_fields.map((item: any) => {
                  return (
                    roleProfile !== "Hospitals Profile" &&
                    (
                      item.type === "file" &&
                      <div className="row justify-content-around border-bottom mb-4 pb-4">
                        <div className="col-lg-12 col-xl-4">
                          <p className=" text-black-50 fw-medium mb-3">{item.label}</p>
                        </div>
                        <div className="row col-lg-12 col-xl-8">
                          <div className="col-xl-5 mb-4">
                            <input type={item.type} multiple id="upload" hidden name={item.fieldKey} onChange={(e) => {
                              handleFileUpload(e, item.id, item.formId, keys.formName);
                            }} accept=".csv, .pdf, .doc, .docx, .xlsx, .xls, text/plain" />
                            <label className="upload_btn" htmlFor="upload"><span className="up_arrow clickable"><i className="bi bi-cloud-arrow-up-fill"></i></span>Upload</label>
                          </div>
                          <div className="col-xl-7">
                            {attachfile != null &&
                              attachfile.map((result: any, index: number) => {
                                return (
                                  <div className="border rounded float-start p-0 d-flex align-items-center">
                                    <span className="fs-4 d-inline-block py-2 px-3  border-end"><i className="bi bi-file-earmark-text-fill"></i></span>
                                    <span className="px-2 text_gray py-2 px-3 ">{result?.fileName}</span>
                                    <button className=" px-3 fs-4 py-2 border-0 bg-transparent border-start" onClick={() => handleDownload(index)}><i className="bi bi-box-arrow-down"></i></button>
                                    <button className=" px-3 fs-4 py-2 border-0 bg-transparent border-start" onClick={() => handleDeleteDocument(index)}><i className="bi bi-x-circle"></i></button>

                                  </div>
                                )
                              })


                            }
                          </div>
                        </div>
                        {(progress != undefined && progress < 100) &&
                          <ProgressBar now={progress} label={`${progress}%`} style={{ width: "50%", marginTop: 10 }} />}

                      </div>

                    )

                  )
                })

              )
            })}

            {/* cancel-and-save*/}
            <div className="row  mb-4 pb-4">
              <div className="col-lg-12 col-xl-4">

              </div>
              <div className="form_bottom_btn d-flex justify-content-end">
                <button className="delete_btn" type="button" onClick={() => navigate(-1)}>Cancel</button>
                <button className="btn_gradian" type="button" onClick={() => role == 2 ? createHospital() : createOneDoctor()}>
                  Save
                  <span className="ms-2">
                    {loading &&
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    }
                  </span>
                </button>
              </div>
              {/* {
                message ?
                  <div >
                    <p style={{ color: "red" }}>{message}</p>

                  </div> : ""
              } */}
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default AddUser;