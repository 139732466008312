import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import "../../App.css"

import {
    Col,
    Row,
} from "reactstrap";
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { RoleContext, AlertContext } from '../../utils/contexts/RoleContext';
import SearchInput from '../../components/SearchInput';
import { createChatUser } from '../../services/messages-service';
import Pagination from '../../components/Pagination';
import { decidePages } from '../../utils/utils';
import Spinner from 'react-bootstrap/Spinner';
import CustomToolTip from '../../components/CustomToolTip';
import constants from '../../config/constants';
import { capitalizeFirstLetter } from '../../utils/utils';
import { getBookingsOfOneDoctor, onePatientBookingAppointment } from '../../services/scheduleappointment-service';
import { getCurrentUser } from '../../services/auth.service';
import { getOnePatient } from '../../services/patient-service';
import PhoneNumber from '../../components/PhoneNumber';
import { Console } from 'console';


export default function MyPatients() {
    const currentUser = useContext(RoleContext);
    const navigate = useNavigate()

    const toast: any = useContext(AlertContext);

    // Row Data: The data to be displayed.
    const [rowData, setRowData] = useState([]);
    const [show, setShow] = useState(false);
    const [more, setMore] = useState();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [patients, setPatients] = useState([])
    const [deleteId, setDeleteId] = useState<string>("");
    const [pages, setPages] = useState<any>([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [popup, showPopup] = useState(false);
    const [showDeleteIcon, setShowDeleteIcon] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [recordsToBeDeleted, setRecordsToBeDeleted] = useState<any>([]);
    const [file, setFile] = useState<any>();
    const [array, setArray] = useState<any>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getMyPatients();
        document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Patients`;
    }, [currentUser]);

    async function getMyPatients(page?: number) {
        const pageCount = page != undefined ? page : 1;
        setLoading(true);
        const response = await getBookingsOfOneDoctor(getCurrentUser()?.id);
        if (response?.success) {
            let offset = (pageCount - 1) * 10;
            const patients = response?.patients?.slice(offset, offset + 10);
            let check: any;
            const arr: any = [];
            check = await Promise.all(patients?.map(async (item: any) => {
                const patient = await getOnePatient(item?.patientId);
                const patientData = patient?.result;
                for (let element of patientData?.userdata) {
                    if (element.fieldKey == "address" || element.fieldKey == "streetAddress") {
                        patientData.address = JSON.parse(element?.fieldValue);
                    }
                    if (element.fieldKey == "dateOfBirth") {
                        patientData.dateOfBirth = JSON.parse(element?.fieldValue);
                    }
                }
                const patientAppointments = patientData.booking_appointments;
                patientData["lastAppointment"] = patientAppointments[patientAppointments?.length - 1];
                arr.push(patientData);
                return check;
            }));
            setPatients(arr);
            setRowData(arr);
            const pages = decidePages(response?.patientsCount);
            setPages(pages);
            setPageNumber(pageCount)
        }
        setLoading(false);
    }

    const showForm = (id?: any) => {
        if (id != undefined) {
            console.log("Dtjerere")
            handleShow()
            setDeleteId(id)
        }
        else {
            handleShow()
            setDeleteId(deleteId)
        }
    }


    const handleClick = (roleId: any) => {
        navigate(`/${currentUser}/add-user/${roleId}`, { state: { roleId } });
    }

    function handleRadioChange(item: any) {
        const deleteArray = [...recordsToBeDeleted];
        const check = deleteArray.find(((element: any) => element.id == item.id));
        if (check === undefined) {
            setRecordsToBeDeleted((prevState: any) => ([...prevState, item]))
        } else {
            setRecordsToBeDeleted((prevState: any) => (prevState.filter((element: any) => element.id != item.id)));
        }
    }

    function decideStatus(item: any) {
        let decideCheck = false
        recordsToBeDeleted.forEach((element: any) => {
            if (element.id == item.id) {
                decideCheck = true;
                return false;
            }
        })
        return decideCheck;
    }

    async function createChatUsers(id: number) {
        const response = await createChatUser(id);
        navigate(`/${currentUser}/messages`, { state: { chatRoomId: response.data.room } });
    }


    // Container: Defines the grid's theme & dimensions.
    return (
        <>
            <Row>
                <div className="panel_top_section position-relative">
                    <h1>Patients</h1>
                    <div className="grid_icons d-flex gap-1 align-items-center float-end">
                        <SearchInput
                            visible={true}
                            data={rowData}
                            setData={(value: any) => setPatients(value)}
                        />
                    </div>
                </div>
                <Col>
                    <div className="row d-flex table-responsive">
                        <table>
                            <thead>
                                <tr className="list_head">
                                    <th><span className="d-block float-start p-0 bg-transparent">
                                    </span>
                                        <label className="p-0 bg-transparent" htmlFor="name">Name</label>
                                    </th>
                                    <th>Diagnosis</th>
                                    <th>Date of Birth</th>
                                    <th>Address</th>
                                    <th>Phone number</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>

                            <tbody>
                                {patients.length > 0 ?
                                    <>
                                        {patients?.map((patient: any, index: number) => {
                                            const patientData = patient;
                                            return (
                                                <tr className={`list_detail ${more === patient.id ? 'active' : ''}`} key={index + patient.firstName}
                                                    onClick={() => {
                                                        navigate(`/${currentUser}/patientdetail/${patient?.id}`, { state: { key: patient.id } });
                                                    }}
                                                >

                                                    <td><span className="px-1 d-block float-start">
                                                    </span>
                                                        <label htmlFor="name2">{patient.firstName + " " + patient.lastName}</label>
                                                    </td>
                                                    <td>{patient?.lastAppointment?.reason}</td>
                                                    <td>{patientData.dateOfBirth}</td>
                                                    <td>{patientData.address}</td>
                                                    <td onClick={(e) => e.stopPropagation()}> <PhoneNumber phoneNumber={patient.phoneNumber} allowCall={patient.allowCall} /></td>
                                                    <td>
                                                        <span className="action" >
                                                            <button className="exclamation_btn position-relative" ><i className="bi bi-exclamation-circle " onClick={() => {
                                                                navigate(`/${currentUser}/patientdetail/${patient?.id}`, { state: { key: patient.id } });
                                                                showPopup(!popup);
                                                            }}></i>
                                                                {/* {popup===true && */}
                                                                <ul className="drop_list m-0 p-0 bg-white p-2  text-left">
                                                                    {/* <li><a href="#">Edit</a></li><li><a href="#">Delete</a></li> */}
                                                                    <li><a>{patient.firstName}</a></li>

                                                                    <li><a href="mailto:drethanbrown@mail.com">{patient.email}</a></li>
                                                                    <li><PhoneNumber phoneNumber={patient.phoneNumber} allowCall={patient.allowCall} /></li>
                                                                    <li> {patient.role?.roleName}</li>
                                                                </ul>
                                                                {/* } */}
                                                            </button>
                                                            <CustomToolTip title="chat">
                                                                <button
                                                                    className="exclamation_btn position-relative"
                                                                >
                                                                    <i className="bi bi-chat-text"
                                                                        onClick={(e: any) => {
                                                                            e.stopPropagation();
                                                                            createChatUsers(patient.id)
                                                                        }}
                                                                    ></i>
                                                                </button>
                                                            </CustomToolTip>
                                                            <CustomToolTip title="chat">
                                                                <button
                                                                    className="exclamation_btn position-relative"
                                                                >
                                                                    {patient.allowCall ?
                                                                    <a href={`tel:${patient.phoneNumber}`} className='text-black'>
                                                                        <i className="bi bi-telephone"
                                                                            onClick={(e: any) => {
                                                                                e.stopPropagation();
                                                                            }}
                                                                        />
                                                                    </a>
                                                                        :
                                                                        <a className='text-black' style={{opacity: 0.5}}>
                                                                            <i className="bi bi-telephone"
                                                                                onClick={(e: any) => {
                                                                                    e.stopPropagation();
                                                                                }}
                                                                            />
                                                                        </a>
                                                                    }
                                                                </button>
                                                            </CustomToolTip>
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </>
                                    :
                                    <tr className="list_detail">
                                        <td colSpan={10}>
                                            {!loading ? "No data found" : <Spinner />}
                                        </td>
                                    </tr>
                                }
                            </tbody>

                            {pages.length > 1 &&
                                <tfoot>
                                    <Pagination
                                        pages={pages}
                                        currentPage={pageNumber}
                                        onClickPageNumber={(page: any) => {
                                            setPageNumber(page);
                                            getMyPatients(page);
                                        }}
                                    />
                                </tfoot>
                            }
                        </table>
                    </div>

                    {/* <Modal show={show} onHide={() => handleClose()}>
                            <Modal.Header closeButton>
                                <Modal.Title>Delete Patient</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Do you want delete this patient ?</Modal.Body>
                            <Modal.Footer>

                                <button className="bg_gray_500 rounded border-0 py-2 px-4" onClick={() => handleClose()}>
                                    Cancel
                                </button>
                                <button className="bg_gradiant text-white rounded border-0 py-2 px-4" onClick={() => {
                                    if (deleteId) {
                                        handleDelete(deleteId);
                                        setDeleteId("");
                                        setRecordsToBeDeleted((prevState: any) => (prevState.filter((item: any) => item.id != deleteId)))
                                    } else {
                                        recordsToBeDeleted.forEach((item: any) => {
                                            handleDelete(item.id);
                                        })
                                    }
                                    handleClose();
                                }}>
                                    Delete
                                </button>
                            </Modal.Footer>
                        </Modal> */}
                </Col>

            </Row>
        </>
    );
};