import authHeader from "./auth-header";
import axios from "axios";
import { END_POINT } from "../config/constants";

// const APIS_URL = "http://localhost:5000/api/doctor/";
const APIS_URL = `${END_POINT}doctor/`

export const getallDoctor = (page?: number, hospitalId?: number) => {
  return axios
    .get(`${APIS_URL}get_doctor?roleId=3&page=${page}&hospitalId=${hospitalId}`, {

    })
    .then((response) => {
      return response?.data;
    });
};

export const deleteDoctorData = (id: any) => {
  return axios
    .delete(APIS_URL + `doctor_delete/${id}`, {

    })
    .then((response) => {


      //  response?.data;
      window.location.reload();
    });
};

export const getoneuser = (key: string) => {
  return axios
    .get(APIS_URL + "getOneUser/" + key, {

    })
    .then((response) => {


      return response?.data;
    });
};

export const updateprofile = (key: string, values: object) => {
  return axios
    .put(APIS_URL + "updateprofile/" + key, {
      values
    })
    .then((response) => {


      return response?.data;
    });
};

export const createDoctor = (values: object, hospitalId: string | null, generatePassword: boolean) => {
  console.log("hospitalId", hospitalId)
  return axios
    .post(`${APIS_URL}add_doctor?hospitalId=${hospitalId}&generatePassword=${generatePassword}`, {
      values
    })
    .then((response) => {
      return response?.data;
    });
};

export const updateDoctor = (values: object, id: string | null) => {
  return axios
    .put(APIS_URL + "edit_doctor/" + id, {
      values
    })
    .then((response) => {


      return response?.data;
    });
};

export const getOneDoctor = (key: string | null) => {
  console.log("dereieru", key)
  return axios
    .get(APIS_URL + "getonedoctor/" + key, {

    })
    .then((response) => {


      return response?.data;
    });
};

export const createOneHospital = (values: object, generatePassword: boolean) => {
  return axios
    .post(`${APIS_URL}create-hospital?generatePassword=${generatePassword}`, {
      values
    })
    .then((response) => {
      return response?.data;
    });
};

