import authHeader from "./auth-header";
import axios from "axios";
import axiosRetry from 'axios-retry';
import { END_POINT } from "../config/constants";

// axiosRetry(axios, { 
//   retries: 4, // Number of retries (Defaults to 3) 
//   retryCondition(error: any) {
//     // Conditional check the error status code
//     switch (error.response.status) {
//       case 401:
//         return true; // Retry request with response status code 404 or 429
//       default:
//         return false; // Do not retry the others
//     }
//   },
//   // retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 3000)
// });

// const API_URL = "http://localhost:8080/api/test/";
// const APIS_URL = "http://localhost:5000/api/user/";

const API_URL = `${END_POINT}test/`;
const APIS_URL = `${END_POINT}user/`

export const getPublicContent = () => {
  return axios.get(API_URL + "all");
};

export const getUserBoard = () => {
  return axios.get(API_URL + "user", { headers: authHeader() });
};

export const getModeratorBoard = () => {
  return axios.get(API_URL + "mod", { headers: authHeader() });
};

export const getAdminBoard = () => {
  return axios.get(API_URL + "admin", { headers: authHeader() });
};

export const getalluser = (page?: any, role?: any, hospitalId?: any, search?: any) => {
  return axios
    .get(`${APIS_URL}getusers?page=${page}&search=${search}&role=${role}&hospitalId=${hospitalId}`)
    .then((response) => {
      return response?.data;
    });
};

export const deleteoneuser = (id: any) => {
  return axios
    .delete(APIS_URL + "deleteuser/" + id, {

    })
    .then((response) => {


      return response?.data;
    });
};

export const getoneuser = (key: string) => {
  return axios
    .get(APIS_URL + "getOneUser/" + key, {

    })
    .then((response) => {


      return response?.data;
    });
};

export const updateprofile = (key: string, values: object) => {
  return axios
    .put(APIS_URL + "updateprofile/" + key, {
      values
    })
    .then((response) => {


      return response?.data;
    });
};

export const createUser = (fields: any) => {
  return axios.post(APIS_URL + "create_user", fields);
};

export const getClusters = (roleId: string,page?:any) => {
  return axios
    .get(`${APIS_URL}get-clusters?roleId=${roleId}&page=${page}`)
    .then((response) => {
      return response?.data;
    });
};

export const getRoles = () => {
  return axios
    .get(`${APIS_URL}get-roles`)
    .then((response) => {
      return response?.data;
    });
};

export const getHospitalUsers = (roleId?: number, hospitalId?: number) => {
  return axios
    .get(`${APIS_URL}get-hospital-users?roleId=${roleId}&hospitalId=${hospitalId}`)
    .then((response) => {
      return response?.data;
    });
};

export const getHospitals = () => {
  return axios
    .get(`${APIS_URL}get-hospitals`)
    .then((response) => {
      return response?.data;
    });
};

export const testFile = (data: any) => {
  return axios
    .post(APIS_URL + "test-file", {
      data
    })
    .then((response) => {
      return response?.data;
    });
};


