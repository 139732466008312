import axios from "axios";
import { END_POINT } from "../config/constants";
const API_URL = `${END_POINT}notification/`
// const API_URL = "http://localhost:5000/api/notification/";
// const APIS_URL = "http://localhost:5000/api/patient/";



export const getAllEmailTemplate = () => {
  return axios
    .get(API_URL + "all_email_template", {

    })
    .then((response) => {


      return response.data;
    });
};

export const getAllNotification = () => {
  return axios
    .get(API_URL + "getallnotification")
    .then((response) => {
      return response.data;
    });
};

export const updateprofile = (key: string, values: object) => {
  return axios
    .put(API_URL + "updateprofile/" + key, {
      values,

    })
    .then((response) => {
      return response.data;
    });
};

export const changeNotificationStatus = (id: string) => {
  return axios.put(`${API_URL}change-notification-status/${id}`)
    .then((response) => {
      return response.data;
    });
}

export const updatePushToken = (token:string) => {
  return axios.put(`${API_URL}update-push-token`,{
    pushToken: token
  })
    .then((response) => {
      return response.data;
    });
}

export const sendNotification = (id:string) => {
  console.log("oppp id", id)
  return axios.post(`${API_URL}/send-notification`,{
    id
  })
    .then((response) => {
      return response.data;
    });
}


