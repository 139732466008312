import doctor_image from "../../../assets/images/doctor/doctor-image1.jpg";

import {
  Card,
  Row,
  Col,
  CardTitle,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import { getallpatient } from "../../../services/patient-service";
import React, { useContext, useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { RoleContext } from "../../../utils/contexts/RoleContext";
import constants from "../../../config/constants";
import { capitalizeFirstLetter, groupBy, longDateGenerator } from "../../../utils/utils";
import { getBookingsOfOneDoctor, onePatientBookingAppointment } from "../../../services/scheduleappointment-service";
import { getOnePatient, getDoctorsQuestionnaire, getQuestionnaireAnswers } from "../../../services/patient-service";
import { getCurrentUser } from "../../../services/auth.service";
import { decidePages } from "../../../utils/utils";
import { getFormDetails } from "../../../services/form-service";
import Spinner from 'react-bootstrap/Spinner';
import Pagination from "../../../components/Pagination";

const DoctorQues = () => {

  const location = useLocation();
  const currentUser = useContext(RoleContext);
  const navigate = useNavigate();
  const [patients, setPatients] = useState<any>([]);
  const [pages, setPages] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("")

  useEffect(() => {
    getMyPatients();
    document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Doctor Questionnaire`;
  }, [currentUser]);

  async function getMyPatients(page?: number) {
    const pageCount = page != undefined ? page : 1;
    setLoading(true);
    const response = await getBookingsOfOneDoctor(getCurrentUser()?.id);
    if (response?.success) {
      let check: any;
      const arr: any = [];
      check = await Promise.all(response?.patients?.map(async (item: any) => {
        const patient = await getOnePatient(item?.patientId);
        const patientData = patient?.result;
        for (let element of patientData?.userdata) {
          if (element?.fieldKey == "address" || element?.fieldKey == "streetAddress") {
            patientData.address = JSON.parse(element?.fieldValue);
          }
          if (element.fieldKey == "dateOfBirth") {
            patientData.dateOfBirth = JSON.parse(element?.fieldValue);
          }
        }
        const patientAppointments = await onePatientBookingAppointment(item?.patientId, getCurrentUser()?.id);
        patientData["lastAppointment"] = patientAppointments?.appointmentBooking[patientAppointments?.appointmentBooking?.length - 1];

        const answers = await getQuestionnaireAnswers(getCurrentUser()?.id, item?.patientId);
        const grouped = groupBy(answers?.data, "formId");
        // patientData.grouped = grouped;
        if (Object.keys(grouped)?.length > 0) {
          // const arr = [];
          for (let key in grouped) {
            const patientDataCopy = {...patientData}
            // const obj: any = {};
            const formDetail = await getFormDetails(Number(key));
            // obj.formName = formDetail?.data?.formName;
            // obj.formId = key
            // obj.answers = grouped[key];
            grouped[key]?.forEach((answer: any, index: number) => {
              patientDataCopy.lastUpdatedAt = new Date(answer.updatedAt);
              if (index != 0 && new Date(answer.updatedAt) > patientDataCopy.lastUpdatedAt) {
                patientDataCopy.lastUpdatedAt = new Date(answer.updatedAt);
              }
            })
            patientDataCopy.formName = formDetail?.data?.formName;
            patientDataCopy.formId = key;
            arr.push(patientDataCopy);
          }
          // patientData.questionnaires = arr;
        }
        // arr.push(patientData);
        return check;
      }));
      let offset = (pageCount - 1) * 10;
      const filteredPatients = arr?.filter((item: any) => item.formName);
      setPatients(filteredPatients?.slice(offset, offset + 10));
      setRowData(filteredPatients?.slice(offset, offset + 10));
      const pages = decidePages(response?.patientsCount);
      setPages(pages);
      setPageNumber(pageCount)
    }
    setLoading(false);
  }

  function onSearchChange(e: any) {
    setSearch(e.target.value);
    const values = rowData.filter((items: any) => Object.values(items).join(" ").toLowerCase().match(e.target.value.toLowerCase()));
    setPatients(values);
  }


  const getUsers = () => {
    getallpatient().then(
      (response) => {
        console.log('patientresponse', response.result)
        const patientData = response.result;
        setPatients(patientData);
        setRowData(patientData);
        // setRowData(patientData);
        // setPagination(patientData);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        // setLoading(false);
        // setMessage(resMessage);
      }
    );
  }

  const handlePagination = (pageNumber: number) => {
    setPageNumber(pageNumber);
    setPatients(rowData.slice(pageNumber * 10 - 9, pageNumber * 10 + 1))
    setPagination(rowData);
  }

  const setPagination = (data: any) => {
    let pageLength: number = Math.floor(data.length / 10) + 1;
    const arr = [];
    for (let i = 1; i < pageLength + 1; i++) {
      arr.push(i)
    }
    // setpages(arr);
  }
  return (
    <Row>
      <div className="panel_top_section border-0 position-relative my-lg-3">
        <h1>Questionnaires</h1>
        <div className="patient_search col-12 col-md-4 d-flex gap-1 align-items-center float-end">
          <div className="input-group bg-white rounded-5 box_shadow">
            <span className="input-group-text bg-transparent border-0" id="inputGroupPrepend"><i className="bi bi-search"></i></span>
            <input
              type="text"
              className="form-control bg-transparent border-0 shadow-none pl-0"
              placeholder="Search message..."
              id="search"
              onChange={onSearchChange}
              value={search}
            />
            <div className="invalid-feedback">Please choose a username.</div>
          </div>
        </div>
      </div>
      <Col>
        <div className="row d-flex table-responsive m-0">
          <table >
            <thead>
              <tr className="list_head_new text-start bg_green_table">
                <th className="text_base px-2 px-xl-4 py-4">Name</th>
                <th className="text_base px-2 px-xl-4 py-4">Diagnosis</th>
                <th className="text_base px-2 px-xl-4 py-4">Date of Birth</th>
                <th className="text_base px-2 px-xl-4 py-4">Questionnaire Title</th>
                <th className="text_base px-2 px-xl-4 py-4">Last Updated </th>
                <th className="text_base px-2 px-xl-4 py-4 text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {patients?.length > 0 ?
                <>
                  {patients?.map((item: any) => {
                    const rowSpan = item?.questionnaires?.length
                    return (
                      <tr className="list_detail_new text-start bg-white border-bottom">
                        <td className="text_base px-2 px-xl-4 py-4">{item?.firstName + " " + item?.lastName}</td>
                        <td className="text_base px-2 px-xl-4 py-4 poppins-500"> <span className="col-lg-12 col-xl-8">{item?.lastAppointment?.reason}</span></td>
                        <td className="text_base px-2 px-xl-4 py-4">{item?.dateOfBirth ? longDateGenerator(item?.dateOfBirth) : "-"}</td>
                        <td className="text_base px-2 px-xl-4 py-4">{item?.formName}</td>
                        <td className="text_base px-2 px-xl-4 py-4 poppins-500">{longDateGenerator(item?.lastUpdatedAt)}</td>
                        <td className="text_base px-2 px-xl-4 py-4 text-center"
                          onClick={() => navigate(`/${currentUser}/quesdetails`, { state: { formId: item.formId, patientId: item.id, reason: item?.lastAppointment?.reason, formName: item.formName } })}
                        >
                          <span className="dost_btn bg_gray_light text-center py-1 fs-5 px-2 rounded-circle"><i className="bi bi-exclamation-circle"></i></span>
                        </td>
                      </tr>
                    )
                  })}
                </>
                :
                <tr className="list_detail">
                  <td colSpan={10}>
                    {!loading ? "No data found" : <Spinner />}
                  </td>
                </tr>
              }
            </tbody>
            {pages?.length > 1 &&
              <tfoot>
                <Pagination
                  pages={pages}
                  currentPage={pageNumber}
                  onClickPageNumber={(page: any) => {
                    setPageNumber(page);
                    getMyPatients(page);
                  }}
                />
              </tfoot>
            }

          </table>
          {/* <table>
            <tr>
              <th>Name</th>
              <th>Diagnosis</th>
              <th>Questionnaire Title</th>
              <th>Last Updated</th>
            </tr>
            <tr>
              <td rowSpan={2}>Vic</td>
              <td rowSpan={2}>General</td>
              <td>Test</td>
              <td>july</td>
              <tr>
                <td>Another Test</td>
                <td>Aug</td>
              </tr>
            </tr>
            <tr>
              <td>February</td>
              <td>$80</td>
            </tr>
          </table> */}
        </div>
      </Col>
    </Row>
  );
};

export default DoctorQues;
