
import profile from "../../../assets/images/doctor/profile.png";
import {
    Card,
    Row,
    Col,
    CardTitle,
    CardBody,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
} from "reactstrap";
import React, { useContext, useEffect, useState } from 'react';
import { createSearchParams, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getallpatient } from "../../../services/patient-service";
import { createDoctor, getOneDoctor, updateDoctor } from "../../../services/doctor-service";
import { createMedicalRecord, editMedicalRecord } from "../../../services/medicalrecord-service";
import { RoleContext, AlertContext, HospitalContext } from "../../../utils/contexts/RoleContext";
import { SOCKET_DOMAIN } from "../../../config/constants";
import constants from "../../../config/constants";
import { capitalizeFirstLetter } from "../../../utils/utils";
import { getallDoctor } from "../../../services/doctor-service";
import { getPatientAppointments } from "../../../services/scheduleappointment-service";

const EditMedical = () => {

    const [patients, setPatients] = useState<any>({});
    const [dropDownValue, setDropDownValues] = useState<any>("");
    const [htmlFormUsers, sethtmlFormUsers] = useState<any>({});
    const [getvalues, setGetvalues] = React.useState<any>({});
    const [attachfile, setAttachedFile] = useState<any>(null)
    const toast: any = useContext(AlertContext);
    const [fileName, setFileName] = useState<any>([]);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [deleteValues, setDeleteValues] = useState<any>([]);
    const [serviceType, setServiceType] = useState("-1");
    const [service, setService] = useState<any>({
        serviceName: "",
        provider: ""
    });
    const [appointments, setAppointments] = useState<any>([]);
    const [doctors, setDoctors] = useState([]);
    // const [selectedAppointment, setSelectedAppointment] = useState<any>(null);
    const [error, setError] = useState({
        dropDownValue: "",
        serviceName: "",
        provider: "",
        appointment: "",
        serviceType: ""
    });

    const currentUser = useContext(RoleContext);
    const {patientId}: any  = useParams();
    const location = useLocation();

    useEffect(() => {
        getDoctor();
        document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Edit Medical Records`;
        if (currentUser !== "doctor") {
            fetchDoctors();
        }
    }, [currentUser]);

    const getDoctor = async () => {
        setDropDownValues(patientId)
        getOneDoctor(patientId).then(
            (data) => {

                const allDoctor = data?.allDoctors;



                const userForm: any = {};
                // getCurrentDoctor(data.allDoctors)
                let array: any = [];
                let document: any = [];
                allDoctor?.medicalrecords?.map((item: any) => {
                    let obj: any = {}
                    const documents = JSON.parse(item?.documents)
                    let splittedDocument = documents?.split("/");
                    obj.fileName = splittedDocument[splittedDocument?.length - 1];
                    obj.note = item?.note;
                    obj.fileId = item?.id
                    document.push(obj)
                });
                setAttachedFile(document);
                // let oneRecord = allDoctor?.medicalrecords[0];
                // if (oneRecord.serviceName === "Appointment") {
                //     setService({
                //         appointmentId: oneRecord.appointmentId,
                //         serviceName: oneRecord.serviceName,
                //         provider: oneRecord.provider
                //     })
                // } else {
                //     setService({
                //         serviceName: oneRecord.serviceName,
                //         provider: oneRecord.provider
                //     })
                // }



                allDoctor?.user_form_data?.map((item: any) => {
                    let obj: any = {}
                    // obj[item.formFieldKey] = item.formFieldValue
                    obj.fieldKey = item?.formFieldKey
                    obj.fieldValue = item?.formFieldValue
                    obj.formId = item?.formId
                    obj.id = item?.user_form_field?.id
                    obj.label = item?.user_form_field?.label
                    obj.type = item?.user_form_field?.type
                    obj.defaultValue = item?.user_form_field?.defaultValue

                    // obj.field = objs
                    if (userForm[item?.user_form?.formName] === undefined) {
                        userForm[item?.user_form?.formName] = [obj]
                    } else {
                        userForm[item?.user_form?.formName] = [...userForm[item?.user_form?.formName], obj]
                    }




                    // obj.field = objs
                    array.push(obj)
                    setGetvalues((prevState: any) => ({
                        ...prevState, [item?.user_form?.formName]: { ...prevState[item?.user_form?.formName], [item?.formFieldKey]: { id: item?.user_form_field?.id, value: item?.formFieldValue, formId: item?.formId } }
                    }))
                })

                sethtmlFormUsers(userForm)

                setPatients(allDoctor);
            },
            (error) => {
                console.log("error", error)

                // setLoading(false);
                // setMessage(resMessage);
            }
        );


    };
    const handleFileUpload = (post: any) => {

        // const formName = formname
        // const formId = formid
        // const fieldId = id
        if (post.target.files && post.target.files.length > 0) {
            const filearray: any = []
            const fileBase64: any = []
            const result: any = []
            for (let i = 0; i < post.target.files.length; i++) {
                let file = post.target.files[i];


                filearray.push(file.name)
                // setFileName(filearray);

                let reader = new FileReader();
                if (file) {
                    const maxSize = 5 * 1024 * 1024;
                    if (file.size > maxSize) {
                        alert('Can not upload this file. Max Size is 5MB');
                        post.target.value = '';
                    } else {


                        const fileSize = file.size;
                        const chunkSize = 1024 * 1024; // 1MB chunk size (you can adjust this as needed)
                        const chunks = Math.ceil(fileSize / chunkSize);
                        let uploadedChunks = 0;
                        for (let i = 0; i < chunks; i++) {
                            const start = i * chunkSize;
                            const end = Math.min(fileSize, start + chunkSize);


                            uploadedChunks++;
                            //  const percentage= Math.floor((uploadedChunks * 100) / chunks)
                            const percentage = Math.min(100, Math.round((uploadedChunks / chunks) * 100));
                            // setProgress(percentage)

                        }



                        reader.readAsDataURL(file);
                        reader.onload = () => {
                            let obj = {
                                fileName: file.name,
                                result: reader.result
                            }
                            fileBase64.push(obj)
                            if (attachfile === null) {
                                setAttachedFile(fileBase64);
                            }
                            else {
                                const valueset = attachfile?.concat(fileBase64)
                                setAttachedFile(valueset);
                            }
                            // setAttachedFile(reader.result);
                            // let obj={}
                            const results = { result: reader.result, fileName: file.name.split('.')[1], folderName: 'medicalrecord', originalName: file.name.split('.')[0] };

                            result.push(results)
                            // setGetvalues((prevState: any) => ({
                            //   ...prevState, ['Basic Information']: { ...prevState['Basic Information'], result }
                            // // }))
                            // setPatients((prevState: any) => ({
                            //     ...prevState, ["document"]: result
                            // }))
                            setFileName([...fileName, result])
                        };
                    }
                }
            }
        }
    };

    //   const handleSelect = (event: any) => {
    //     sethtmlFormUsers({});
    //     const patientvalue = event.target.value

    //     setDropDownValues(patientvalue);
    //     getDoctor(event.target.value);

    //   };
    const getUsers = () => {
        getallpatient().then(
            (response) => {
                const patientData = response.result;
                setPatients(patientData);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                // setLoading(false);
                // setMessage(resMessage);
            }
        );
    }


    const handleDownload = (indexes?: any) => {
        attachfile.map((item: any, index: number) => {
            if (index === indexes) {
                const link = document.createElement('a');
                link.href = item.result ? item.result : `${SOCKET_DOMAIN}/medicalrecord/${patientId}/${item.fileName}`;
                link.download = item?.fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        })

    };

    const handleDeleteDocument = (indexes?: any) => {
        //     attachfile.map((imtess:any,indexs:any)=>{
        // // if(indexs===index){
        // //   // setAttachedFile("");
        // // }
        //     })
        setAttachedFile(attachfile.filter((item: any, index: number) => item.fileId !== indexes))
        let obj = {
            deleteValue: indexes
        }
        setDeleteValues([...deleteValues, obj])
        // patientValue();


        // setFileName("")

    };
    useEffect(() => {
        setPatients({ ...patients, ['attachfile']: deleteValues })
    }, [deleteValues])
    // const patientValue=async()=>{
    //   await  setPatients({ ...patients, ['attachfile']: deleteValues })
    // }

    const FormOnChange = (event: any) => {
        const { name, value, id } = event.target;
        const formId = event?.target?.id?.split("/")[1]
        const fieldId = event?.target?.id?.split("/")[0]
        const formName = event?.target?.id?.split("/")[2]
        // const name = event.target.name;
        // const value = event.target.value;
        // const obj = {
        //   "field_id": event.target.id,
        //   "field_key": event.target.name,
        //   "field_value": event.target.value
        // };
        // setGetvalues({ ...getvalues, [name]: value, "field_id": event.target.id });
        setGetvalues((prevState: any) => ({
            ...prevState, [formName]: { ...prevState[formName], [name]: { id: JSON.parse(fieldId), value: value, formId: formId } }
        }))
    }

    function validate() {
        let validationSuccess = true
        if (dropDownValue == "") {
            validationSuccess = false
            setError((prevState: any) => ({ ...prevState, dropDownValue: "Please select a patient" }))
        }
        if (serviceType === "-1") {
            validationSuccess = false
            setError((prevState: any) => ({ ...prevState, serviceType: "Please select a service type" }))
        }
        if (serviceType === "Appointment") {
            if (!service.appointmentId) {
                setError((prevState: any) => ({ ...prevState, appointment: "Please select an appointment" }));
                validationSuccess = false
            }
        } else {
            if (!service.serviceName) {
                setError((prevState: any) => ({ ...prevState, serviceName: "Service name is required" }));
                validationSuccess = false
            }
            if (!service.provider && currentUser !== "doctor") {
                setError((prevState: any) => ({ ...prevState, provider: "Please select a provider" }));
                validationSuccess = false
            }
        }
        return validationSuccess;
    }

    const createOneDoctor = async () => {
        if (await validate()) {
        const formValues = { ...patients }
        formValues.document = fileName

            editMedicalRecord(formValues, formValues?.id, service).then(
            (data) => {
                    navigate(`/${currentUser}/medical-records`);
                setTimeout(() => {
                    // navigate(route);
                    toast.setShowToast(data.message, "success");
                }, 1000);

                // const checkvalue = data.formData;

                // sethtmlFormUsers(data)
                // formdisplay2(data.formId);
                // navigate(`/${currentUser}/doctordetailist`)



            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();


            }
        );
        }
    };

    function handleService(e: any) {
        const { value } = e.target
        setServiceType(value);
        if (value === "Appointment") {
            fetchAppointments();
        } else {
            setAppointments([]);
        }
        setService({});
        setError((prevState: any) => ({ ...prevState, serviceType: "" }))
    }

    async function fetchAppointments() {
        const response = await getPatientAppointments(dropDownValue);
        if (response?.success) {
            setAppointments(response.data);
        }
    }

    function handleServiceChange(e: any) {
        const { name, value } = e.target;
        if (name === "appointment") {
            const appointment = appointments.find((appointment: any) => appointment.id == value);
            setService({
                appointmentId: value,
                provider: appointment.doctorId,
                serviceName: "Appointment"
            })
        } else {
            setService((prevState: any) => ({ ...prevState, [name]: value }))
        }
        setError((prevState: any) => ({ ...prevState, [name]: "" }))
    }

    const fetchDoctors = async (page?: any) => {
        const response = await getallDoctor("undefined", location.state?.hospitalId);
        setDoctors(response.result)
    }

    return (

        <Row>

            <div className="panel_top_section">
                <h1>Edit Medical Record</h1>

            </div>
            <Col className="mt-3 mb-4">
                <div className="add_doctor_form card_box col-sm-12 col-xl-9 mx-auto py-4 px-lg-4 mt-4">
                    <div className="add_doctor_fom px-4">
                        <div className="row justify-content-around border-bottom mb-4 pb-4">
                            <div className="col-lg-12 col-xl-4 d-flex align-items-center">
                                <p className=" text-black-50 fw-medium">Patient Name</p>
                            </div>
                            <div className="col-lg-12 col-xl-8">
                                <div className="col-lg-12 col-xl-6 mb-3">
                                    <input type="text" className="form-control" id="firstname" disabled value={patients.firstName + " " + patients.lastName} />
                                    {/* <label htmlFor="phone" className="form-label">Gender</label> */}

                                </div>
                            </div>
                        </div>
                        {dropDownValue !== "" &&
                            <div className="row justify-content-around border-bottom mb-4 pb-4">
                                <div className="col-lg-12 col-xl-4 d-flex align-items-center">
                                    <p className=" text-black-50 fw-medium">Services</p>
                                </div>
                                <div className="col-lg-12 col-xl-8">
                                    <div className="col-lg-12 col-xl-6 mb-3">
                                        <select
                                            className="form-select"
                                            aria-label="Default select example"
                                            value={serviceType}
                                            onChange={handleService}
                                        >
                                            <option value="-1">Select</option>
                                            <option value="Appointment">Appointment</option>
                                            <option value="Other Services">Other services</option>
                                        </select>
                                        {error.serviceType &&
                                            <p className="mt-2 text-danger">{error.serviceType}</p>
                                        }
                                    </div>
                                    {serviceType === "Other Services" &&
                                        <div className="col-xl-12 row">
                                            <div className="col-xl-6">
                                                <label>Service Name</label>
                                                <input
                                                    className="form-control mt-2"
                                                    value={service.serviceName}
                                                    name="serviceName"
                                                    onChange={handleServiceChange}
                                                />
                                                {error.serviceName &&
                                                    <p className="mt-2 text-danger">{error.serviceName}</p>
                                                }
                                            </div>
                                            <div className="col-xl-6">
                                                <label>Service Provider</label>
                                                <select
                                                    className="form-select mt-2"
                                                    value={service.provider}
                                                    name="provider"
                                                    onChange={handleServiceChange}
                                                >
                                                    <option value="-1">Select...</option>
                                                    {doctors.map((item: any) => {
                                                        return (
                                                            <option value={item.id}>{item.firstName + " " + item.lastName}</option>
                                                        )
                                                    })}
                                                </select>
                                                {error.provider &&
                                                    <p className="mt-2 text-danger">{error.provider}</p>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {serviceType === "Appointment" &&
                                        <div className="col-xl-6">
                                            {appointments?.length > 0 ?
                                                <select
                                                    className="form-control mt-2"
                                                    defaultValue="-1"
                                                    name="appointment"
                                                    onChange={handleServiceChange}
                                                    value={service.appointmentId}
                                                >
                                                    <option value="-1">Select...</option>
                                                    {appointments.map((appointment: any) => {
                                                        return (
                                                            <option value={appointment.id}>Appointment for {appointment.reason} at {appointment.date} {appointment.time}</option>
                                                        )
                                                    })}
                                                </select>
                                                :
                                                <p className="mt-2 text-danger">No appointments found.</p>
                                            }
                                            {error.appointment &&
                                                <p className="mt-2 text-danger">{error.appointment}</p>
                                            }
                                        </div>
                                    }
                                    <div>
                                    </div>
                                </div>
                            </div>
                        }
                        {/* personal-inoformation */}
                        {/* <div className="row justify-content-around border-bottom mb-4 pb-4">
              <div className="col-lg-12 col-xl-4">
                <p className="text-black-50 fw-medium">Personal Information</p>
              </div>
              <div className="row  col-lg-12 col-xl-8">
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="firsname" className="form-label">First Name</label>
                  <input type="text" className="form-control" id="firstname" />
                </div>
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="Name" className="form-label">Last Name</label>
                  <input type="text" className="form-control" id="lastname" />
                </div>
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="phone" className="form-label">Gender</label>
                  <select className="form-select" aria-label="Default select example">
                    <option selected>Select</option>
                    <option value="1">Male</option>
                    <option value="2">Female</option>
                  </select>
                </div>
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="phone" className="form-label">Date of Birth</label>
                  <input type="date" className="form-control" id="phone" />
                </div>
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="phone" className="form-label">Phone</label>
                  <input type="text" className="form-control" id="phone" />
                </div>
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="email" className="form-label">Email</label>
                  <input type="text" className="form-control" id="email" />
                </div>
                <div className="col-sm-12 mb-3 textarea">
                  <label htmlFor="address" className="form-label">Address</label>
                  <textarea className="textarea_input form-control" id="address"></textarea>
                </div>
              </div>
            </div> */}
                        <div className="row justify-content-around ">
                            {Object.keys(htmlFormUsers)?.map((keys: any) => {
                                if (keys !== "Personal Information" && keys !== "Patient Document" && keys !== "undefined") {
                                    return (
                                        <div className="row justify-content-around border-bottom mb-4 pb-4">
                                            <div className="col-lg-12 col-xl-4">
                                                <p className="text-black-50 fw-medium">{keys}</p>
                                            </div>
                                            <div className="row col-lg-12 col-xl-8">
                                                {htmlFormUsers[keys].map((item: any) => {
                                                    return (
                                                        <>
                                                            {item.type === "select" ?
                                                                <div className="col-lg-12 col-xl-6 mb-3">
                                                                    <label htmlFor="phone" className="form-label">{item.label}</label>

                                                                    <select className="form-select" aria-label="Default select example" disabled name={item.fieldKey} id={`${item.id + "/" + item.formId + "/" + keys}`}
                                                                        onChange={e => FormOnChange(e)}
                                                                        defaultValue={item.fieldValue} >
                                                                        {JSON.parse(item.defaultValue)?.map((item: any) => {
                                                                            return (
                                                                                <option value={item.value} selected>{item.label}</option>
                                                                            )
                                                                        })}

                                                                    </select>


                                                                </div> : null}
                                                            {item.type == "textfield" ?
                                                                <div className="col-lg-12 col-xl-6 mb-3">
                                                                    <label htmlFor="license" className="form-label">{item.label}</label>
                                                                    <input type={item?.type} disabled className="form-control" id={`${item.id + "/" + item.formId + "/" + keys}`}
                                                                        onChange={FormOnChange}
                                                                        name={item.fieldKey} defaultValue={item.fieldValue} />
                                                                </div> : null}

                                                            {item.type === "datetime" ?
                                                                <div className="col-lg-12 col-xl-6 mb-3">
                                                                    <label htmlFor="phone" className="form-label">{item.label}</label>
                                                                    <input type="date" disabled className="form-control" id={`${item.id + "/" + item.formId + "/" + keys}`}
                                                                        onChange={FormOnChange}
                                                                        name={item.fieldKey} defaultValue={item.fieldValue} />
                                                                </div> : null}

                                                            {item.type === "textarea" ?
                                                                <div className="col-sm-12 mb-3 textarea">
                                                                    <label htmlFor="address" className="form-label">{item.label}</label>
                                                                    <textarea className="textarea_input form-control" disabled id={`${item.id + "/" + item.formId + "/" + keys}`}
                                                                        onChange={FormOnChange}
                                                                        name={item.fieldKey} defaultValue={item.fieldValue}></textarea>
                                                                </div> : null}

                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>


                        <div className="row justify-content-around border-bottom mb-4 pb-4">
                            <div className="col-lg-12 col-xl-4">
                                <p className=" text-black-50 fw-medium">Document Uploads</p>
                            </div>
                            <div className="row col-lg-12 col-xl-8">
                                <div className="col-sm-12">
                                    {/* <div className="col-xl-5 mb-4"> */}
                                    <input type="file" multiple id="upload" hidden name="file upload" onChange={(e) => {
                                        handleFileUpload(e);
                                    }} accept=".csv, .pdf, .doc, .docx, .xlsx, .xls, .png, .jpeg, .jpg, text/plain" />
                                    <label className="upload_btn" htmlFor="upload"><span className="up_arrow clickable"><i className="bi bi-cloud-arrow-up-fill"></i></span>Upload</label>
                                    {/* </div> */}
                                    {/* <input type="file" id="upload" hidden />
                  <label className="upload_btn" htmlFor="upload"><span className="up_arrow"><i className="bi bi-cloud-arrow-up-fill"></i></span>Upload</label> */}
                                </div>
                                <div className="col-xl-9 mt-2 p-0">
                                    {attachfile != null &&
                                        attachfile.map((result: any, index: number) => {
                                            return (
                                                <div className="border w-100 rounded float-start p-0 d-flex align-items-center my-1">
                                                    <span className="fs-4 d-inline-block py-2 px-1  py-2 px-lg-3 border-end"><i className="bi bi-file-earmark-text-fill"></i></span>
                                                    <span className="px-2 text_gray py-2 px-3 text-truncate " style={{ maxWidth: 250 }}>{result?.fileName}</span>
                                                    <button className="  fs-4 py-2 px-2  py-2 px-lg-3 border-0 bg-transparent border-start" onClick={() => handleDownload(index)}><i className="bi bi-box-arrow-down"></i></button>
                                                    <button className="  fs-4 py-2 px-2  py-2 px-lg-3 border-0 bg-transparent border-start" onClick={() => handleDeleteDocument(result.fileId)}><i className="bi bi-x-circle"></i></button>

                                                </div>
                                            )
                                        })


                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 mb-3 textarea">
                            <label htmlFor="address" className="form-label">Note</label>
                            {/* {attachfile!=null && attachfile.map((item:any)=>{
                            return( */}
                            <textarea className="textarea_input form-control"

                                // onChange={(event) => setGetvalues((prevState: any) => ({
                                //     ...prevState, ["note"]: event?.target?.value
                                // }))}
                                defaultValue={attachfile != null && attachfile[0]?.note}
                                onChange={(event) => setPatients((prevState: any) => ({
                                    ...prevState, ["note"]: event.target.value
                                }))}
                            ></textarea>
                            {/* )
                            })} */}
                        </div>


                        {/* canceland-save*/}
                        <div className="row  mb-4 pb-4">
                            <div className="col-lg-12 col-xl-4">

                            </div>
                            <div className="form_bottom_btn d-flex justify-content-end">
                                <button className="delete_btn" type="button" onClick={() => navigate(-1)}>Cancel</button>
                                <button className="btn_gradian" type="button" onClick={() => createOneDoctor()}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default EditMedical;