
import profile from "../../../assets/images/doctor/profile.png";
import {
    Card,
    Row,
    Col,
    CardTitle,
    CardBody,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
} from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import SignaturePad from "react-signature-canvas";
import SignatureCanvas from "react-signature-canvas";
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams, useParams } from "react-router-dom";
import { getOneDoctor, } from "../../../services/doctor-service";
import { createprescription, getonprescription } from "../../../services/prescription-service";
import { RoleContext, AlertContext } from "../../../utils/contexts/RoleContext";
import { isArrayTypeNode } from "typescript";
import { getallpatient } from "../../../services/patient-service";
import { createOneRefillrequest, getOneRefill, updateOneRefill } from "../../../services/refillRequest-service";
import constants from "../../../config/constants";
import { capitalizeFirstLetter } from "../../../utils/utils";

const AddRefillMedication = () => {
    const currentUser = useContext(RoleContext);
    const [dataURL, setDataURL] = React.useState<string | null>(null);
    const [searchParams] = useSearchParams();
    const [medications, setMedication] = useState<any>("");
    const [strength, setStrength] = React.useState<any>("");
    const [frequency, setFrequency] = useState("");
    const [patients, setPatients] = useState([]);
    const [prescription, setPrescription] = useState<any>([]);
    const [more, setMore] = useState();
    const [medicationDetail, setMedicationDetail] = useState<any>([])
    const [previousPrescription, setPreviousPrescription] = useState<any>([]);
    const [medicationList, setMedicationList] = useState<any>([]);
    const [prescriptionDetail, setPrescriptionDetail] = useState<any>({});

    const toast: any = useContext(AlertContext);
    const navigate = useNavigate();
    const [inputCount, setInputCount] = useState(1);
    const [inputs, setInputs] = useState<any[]>([{ medication: '', strength: '', frequency: '' }]);
    let padRef = React.useRef<SignatureCanvas>(null);
    const location = useLocation();
    const [checked, setChecked] = useState(false);
    const { refillId } = useParams();



    useEffect(() => {
        // getUsers();

        // if (location?.state?.medicationValue != undefined) {
        //     setPreviousPrescription(location?.state?.medicationValue)
        //     getEditPrescription(location?.state?.medicationValue[0]?.prescriptionId)
        // }
        if (refillId) {
            fetchOneRefill();
        } else {
            getOnePrescription(location?.state?.key)
        }
        document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Add Refill Medication`;
        // Add the event listener only once
        // document.addEventListener('click', handleClick);

    }, [currentUser, refillId]);




    const getEditPrescription = async (value: any) => {
        // console.log("va8leirer", value)

        getonprescription(value).then(
            (data) => {

                const allDoctor = data.allPrescription;
                // console.log("geuserss", allDoctor);


                // setPrescription(allDoctor);
                const array1: any = []
                allDoctor?.map((item: any) => {

                    item?.medications?.map((medication: any) => {
                        let obj: any = {
                            medication: medication.medication,
                            frequency: medication.frequency,
                            strength: medication.strength,
                            medicationId: medication.medicationId,
                            id: medication.id,
                            checked: false,
                            prescriptionId: item.prescriptionId,
                            appointmentId: item.appointmentId
                        }
                        array1.push(obj)
                    })
                    // setPrescription(array);

                })

                allDoctor?.map((item: any) => {
                    const array: any = []
                    const medicationdetailvalue: any = []

                    item?.medications?.map((medication: any, index: number) => {
                        // let obj: any = {};

                        // obj.medication = medication.medication;
                        // obj.frequency = medication.frequency;
                        // obj.strength = medication.strength;
                        // obj.medicationId = medication.medicationId;
                        // obj.id = medication.id;

                        // obj.prescriptionId = medication.prescriptionId;
                        // obj.appointmentId = medication.appointmentId;


                        location?.state?.medicationValue?.forEach((defaultValue: any) => {
                            console.log("deraultvalue", defaultValue)
                            // console.log("indesreer", defaultValue.medicationDetails.id === medication.id ,defaultValue.checked)
                            // if (defaultValue.checked===true) {
                            //     obj.checked = true
                            // }
                            // else {
                            //     obj.checked = false
                            // }

                            // setChecked(!checked)


                            // console.log("checkingsss", defaultValue.medicationId === medication.medicationId, defaultValue, medication)
                            // if(index!=defaultValue.sno){
                            //     console.log("checkingsss",defaultValue.medicationDetails.id === medication.id,defaultValue.medicationDetails.id,medication)
                            let obj: any = {
                                medication: medication.medication,
                                frequency: medication.frequency,
                                strength: medication.strength,
                                medicationId: medication.medicationId,
                                id: medication.id,
                                checked: defaultValue.medicationDetails.id === medication.id ? true : false,
                                prescriptionId: item.prescriptionId,
                                appointmentId: item.appointmentId
                            }

                            array.push(obj)

                            // }
                            // console.log("locationsss", checkstatus, medication)
                            let objs = {
                                prescriptionId: defaultValue.prescriptionId,
                                appointmentId: defaultValue.appointmentId,
                                medicationDetails: defaultValue.medicationDetails,
                                medicationId: defaultValue.medicationDetails.id,

                                patientId: location?.state?.patientId,
                                checked: defaultValue.checked
                            }
                            medicationdetailvalue.push(objs)

                            // } 
                        })
                        const medicationduplicate = medicationdetailvalue.filter((obj: any, index: any, self: any) =>
                            index === self.findIndex((item: any) => item['medicationId'] === obj['medicationId'])
                        );
                        setMedicationDetail(medicationduplicate)

                        const arrayvalue = array.filter((obj: any, index: any, self: any) =>
                            index === self.findIndex((item: any) => item['id'] === obj['id'] && item.checked === true)
                        );
                        const connectarray = arrayvalue.concat(array1)

                        const setvalue = connectarray.filter((obj: any, index: any, self: any) =>
                            index === self.findIndex((item: any) => item['id'] === obj['id'])
                        );
                        console.log("arayvale", arrayvalue, array, array1, connectarray, setvalue, medicationdetailvalue)


                        setPrescription(setvalue);

                    })


                })




            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

            }
        );


    };





    // console.log("prescription", previousPrescription, prescription)



    const createOnePrescription = async () => {
        // console.log("getlvuaerer", medicationDetail);



        createOneRefillrequest(medicationDetail, medications).then(
            (data) => {


                // console.log("dtueiruerer", data);
                if (data.message) {

                    navigate(`/${currentUser}/refill-request`);
                    setTimeout(() => {

                        toast.setShowToast(data.message, "success");
                    }, 1000);

                }
            },
            (error) => {

                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();


                // navigate(-1);
                // setTimeout(() => {
                //     // navigate(route);
                //     toast.setShowToast("added successfully", "success");
                // }, 1000);
            }
        );



    };

    async function fetchOneRefill() {
        const response = await getOneRefill(refillId);
        if (response?.success) {
            const refillMedications = response?.data?.refill_medications;
            const prescription = response?.data?.prescription;
            const medications = prescription?.medications;
            setPrescriptionDetail(prescription);
            // const array = [];
            for (let medication of medications) {
                for (let refillMedication of refillMedications) {
                    if (refillMedication.medicationId == medication.id) {
                        // array.push(medication);
                        medication.checked = true;
                        medication.strength = refillMedication.strength;
                        medication.frequency = refillMedication.frequency;
                        break;
                    }
                }
            }
            setPrescription(medications);
            // setMedicationList(array);
        }
    }

    const getOnePrescription = async (value: any) => {
        // console.log("va8leirer", value)

        getonprescription(value).then(
            (data) => {

                const allDoctor = data.allPrescription;
                console.log("allDoctor", allDoctor)
                allDoctor?.map((item: any) => {
                    const array: any = []
                    item?.medications?.map((medication: any) => {
                        let obj: any = {
                            medication: medication.medication,
                            frequency: medication.frequency,
                            strength: medication.strength,
                            medicationId: medication.medicationId,
                            id: medication.id,
                            checked: false,
                            prescriptionId: item.prescriptionId,
                            appointmentId: item.appointmentId
                        }
                        array.push(obj)
                    })
                    setPrescription(array);
                    setPrescriptionDetail(item);
                })




            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

            }
        );
    };




    // const setMedicalDetails = (prescriptionId: number, appointmentId: number, medication: any, checkstatus: any, sno: any) => {
    //     // console.log("prescriptionid", prescriptionId, appointmentId, medication)
    //     // const array = []
    //     setMedication(medications === "" || medications === null ? medication.medication : medications + ',' + medication.medication)
    //     setStrength(strength === "" || strength === null ? medication.strength : strength + ',' + medication.strength)
    //     setFrequency(frequency === "" || frequency === null ? medication.frequency : frequency + ',' + medication.frequency)
    //     console.log("chedkstatusss", checkstatus)
    //     // console.log("locationsss", checkstatus===true)
    //     if (checkstatus === true) {
    //         let obj = {
    //             prescriptionId: prescriptionId,
    //             appointmentId: appointmentId,
    //             medicationDetails: medication,

    //             patientId: location?.state?.patientId,
    //             checked: checkstatus,
    //             sno: sno
    //         }
    //         // array.push(obj)
    //         setMedicationDetail([...medicationDetail, obj])
    //         // setChecked(true)
    //     }
    //     else {
    //         setChecked(true)
    //         const array: any = []
    //         let obj = {
    //             prescriptionId: prescriptionId,
    //             appointmentId: appointmentId,
    //             medicationDetails: medication,
    //             medicationId: medication.id,
    //             medicationName: medication.frequency,

    //             patientId: location?.state?.patientId,
    //             checked: checkstatus,
    //             sno: sno
    //         }
    //         array.push(obj)
    //         const checking = medicationDetail.concat(array)
    //         console.log("arraysss", array, checking)
    //         // // array.push(obj)
    //         const array1: any = []
    //         checking?.map((item: any) => {

    //             // console.log("itemsss",item.checked,item.medicationId!=medication.id,item.medicationId!=medication.id || item.checked===false )
    //             if (item.medicationId != medication.id) {
    //                 console.log("itemsss", item)
    //                 array1.push(item)
    //             }
    //         })

    //         setMedicationDetail(array1)
    //         // medicationdtailvalue(checking);
    //         //  setChecked(true)
    //         //  console.log("medicationdetails",medicationDetail)
    //         // const arrayvalue = medicationDetail.filter((obj: any, index: any, self: any) =>
    //         //     index === self.findIndex((item: any) =>  item['medicationId']===obj['medicationId'])
    //         // );
    //         // setMedicationDetail(arrayvalue)
    //         // console.log("arrayvalueee",arrayvalue)
    //         // const arrayvalue = checking.filter((filtvalue: any) => filtvalue.checked != false ) 
    //         // console.log("arrayvalueee", arrayvalue, medicationDetail,checking)
    //         // setMedicationDetail(arrayvalue)
    //         // setChecked(false)
    //     }



    // }

    const setMedicalDetails = (prescriptionId: number, appointmentId: number, medication: any, checkstatus: any, sno: any) => {
        let medicationArray = [];
        if (checkstatus === true) {
            let obj = {
                prescriptionId: prescriptionId,
                appointmentId: appointmentId,
                medicationDetails: medication,
                patientId: location?.state?.patientId,
                checked: checkstatus,
                sno: sno,
                medicationId: medication.id
            }
            medicationArray = [...medicationDetail, obj]
            // setMedicationDetail([...medicationDetail, obj])
        } else {
            medicationArray = medicationDetail.filter((item: any) => item.medicationId != medication.id)
            // setMedicationDetail(medicationDetail.filter((item: any) => item.medicationId != medication.id))
        }
        setMedicationDetail(medicationArray);
        let medications = "";
        medicationArray?.forEach((item: any, index: number) => {
            console.log("item.medicationDetails?.medication", item.medicationDetails?.medication)
            medications += `${item.medicationDetails?.medication}${index == medicationArray?.length - 1 ? "" : ","}`
        });
        setMedication(medications)
        // else {
        //     setChecked(true)
        //     const array: any = []
        //     let obj = {
        //         prescriptionId: prescriptionId,
        //         appointmentId: appointmentId,
        //         medicationDetails: medication,
        //         medicationId: medication.id,
        //         medicationName: medication.frequency,

        //         patientId: location?.state?.patientId,
        //         checked: checkstatus,
        //         sno: sno
        //     }
        //     array.push(obj)
        //     const checking = medicationDetail.concat(array)
        //     console.log("arraysss", array, checking)
        //     const array1: any = []
        //     checking?.map((item: any) => {
        //         if (item.medicationId != medication.id) {
        //             console.log("itemsss", item)
        //             array1.push(item)
        //         }
        //     })
        //     setMedicationDetail(array1)
        // }
    }




    const medicationdtailvalue = (value?: any) => {
        console.log("medicationdetail", medicationDetail, value)
        const arrayvalue = value.filter((obj: any, index: any, self: any) =>
            index === self.findIndex((item: any) => item['medicationId'] === obj['medicationId'])
        );
        const checking = arrayvalue.filter((filtvalue: any) => filtvalue.checked != false)
        console.log("arrayvalue", arrayvalue, value, checking)
        setMedicationDetail(checking)
    }

    // console.log("gueiruergetvalues", medicationDetail)

    const handleCheckboxChange = (id: any) => {
        setPrescription((prevCheckboxes: any) =>
            prevCheckboxes.map((checkbox: any, index: number) =>
                // console.log("checkbosss",checkbox.medicationId===id,checkbox.medicationId,id)


                // {
                index === id ? { ...checkbox, checked: !checkbox.checked } : checkbox
                // index === id && setChecked(!checked);
                // }
            )

        );
        // setChecked(true)
    };

    const createOneRefill = async () => {
        const medicationList = prescription.filter((item: any) => item.checked);
        const obj = {
            prescriptionId: prescriptionDetail.prescriptionId,
            appointmentId: prescriptionDetail.appointmentId,
            medicationDetails: medicationList,
            patientId: location?.state?.patientId,
            reason: location?.state?.reason ? location?.state?.reason : "Refill"
        }
        if (medicationList?.length === 0) {
            toast.setShowToast("Please select any of the medications below to proceed", "danger")
        } else {
            createOneRefillrequest(obj).then(
                (response) => {
                    if (response.success) {
                        navigate(`/${currentUser}/refill-request`);
                        setTimeout(() => {
                            toast.setShowToast(response.message, "success");
                        }, 1000);
                    }
                },
                (error) => {
                    console.log("error", error);
                }
            );
        }
    };


    const updateRefill = async () => {
        const medicationList = prescription.filter((item: any) => item.checked);
        if (medicationList?.length === 0) {
            toast.setShowToast("Please select any of the medications below to proceed", "danger")
        } else {
            updateOneRefill(refillId, medicationList).then(
                (response) => {
                    if (response?.success) {
                        navigate(`/${currentUser}/refill-request`);
                        setTimeout(() => {
                            toast.setShowToast(response?.message, "success");
                        }, 1000);
                    } else {
                        toast.setShowToast(response?.message, "danger");
                        setTimeout(() => {
                            navigate(`/${currentUser}/refill-request`);
                        }, 1000);
                    }
                },
                (error) => {
                    console.log("error", error)
                }
            );
        }
    };

    function handleMediCationChange(medication: any) {
        // const medicationCheck = medicationList.find((item: any) => item.id === medication.id);
        // if (medicationCheck) {
        //     setMedicationList(medicationList.filter((item: any) => item.id !== medication.id))
        // } else {
        //     setMedicationList([...medicationList, medication])
        // }
        setPrescription(prescription.map((item: any) => {
            const itemCopy = { ...item }
            if (itemCopy.id === medication.id) {
                itemCopy.checked = !itemCopy.checked
            }
            return itemCopy;
        }))
    }

    function decideCheck(data: any) {
        let checked = false;
        let foundItem = medicationList.find((item: any) => item.id == data.id);
        if (foundItem) {
            checked = true;
        }
        return checked;
    }

    function handleChange(e: any, prescriptionItem: any) {
        const { name, value } = e.target;
        setPrescription(prescription.map((item: any) => {
            if (item.id == prescriptionItem.id) {
                item[name] = value
            }
            return item;
        }))
    }

    return (
        <Row>
            <div className="panel_top_section">
                <h1>Medication Lists</h1>
            </div>
            <Col className="mt-3 mb-4">
                <div className="add_doctor_form card_box col-sm-12 col-xl-9 mx-auto py-4 px-lg-4 mt-4">
                    <div className="add_doctor_fom px-4">
                        <div className="row justify-content-around border-bottom mb-4 pb-4">
                            <div className="row  col-lg-12 col-xl-12">
                                <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                    <div className="row d-flex table-responsive">
                                        <table>
                                            <thead>
                                                <tr className="list_head">
                                                    <th>Select</th>
                                                    <th>S.No</th>
                                                    <th>Medication</th>
                                                    <th>Strength</th>
                                                    <th>Frequency</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {prescription.length > 0 && prescription?.map((prescription: any, index: number) => {
                                                    return (
                                                        <tr className="list_detail" key={index + prescription?.prescriptionId + prescription?.appointmentId + prescription?.id}>
                                                            <td key={prescription.medicationId}>
                                                                <input
                                                                    type="checkbox"
                                                                    // checked={decideCheck(prescription)}
                                                                    checked={prescription.checked}
                                                                    onChange={() => {
                                                                        // setMedicalDetails(prescription?.prescriptionId, prescription?.appointmentId, prescription, !prescription.checked, index);
                                                                        // handleCheckboxChange(index);
                                                                        handleMediCationChange(prescription);
                                                                    }}
                                                                />
                                                            </td>
                                                            <td><p>{index + 1}</p></td>
                                                            <td>
                                                                <p>{prescription?.medication}</p>
                                                            </td>
                                                            <td>
                                                                <input
                                                                    value={prescription?.strength}
                                                                    className="form-control"
                                                                    name="strength"
                                                                    onChange={(e) => handleChange(e, prescription)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    value={prescription?.frequency}
                                                                    className="form-control"
                                                                    name="frequency"
                                                                    onChange={(e) => handleChange(e, prescription)}
                                                                />
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* canceland-save*/}
                        <div className="row  mb-4 pb-4">
                            {/* <div className="col-lg-12 col-xl-4">
                            </div> */}
                            <div className="form_bottom_btn d-flex justify-content-end">
                                <button className="delete_btn" type="button" onClick={() => navigate(-1)}>Cancel</button>
                                {/* <button className="btn_gradian" type="button" onClick={() => createOnePrescription()}>Add new refill</button>
                                 */}

                                <button
                                    className="btn_gradian"
                                    type="button"
                                    onClick={() => {
                                        if (!refillId) {
                                            createOneRefill()
                                        } else {
                                            updateRefill()
                                        }
                                        // navigate(`/${currentUser}/refilldetail`, { state: { key: medicationDetail, patientId: location?.state?.patientId, medication: medications } })
                                    }}
                                >
                                    {!refillId ? "Add New Refill" : "Update Refill"}
                                </button>
                                {/* <button className="btn_gradian" type="button" onClick={() => navigate(`/${currentUser}/refilldetail`, { state: { key: medicationDetail, patientId: location?.state?.patientId, medication: medications } })}>update refill</button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default AddRefillMedication;