import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";

import { Container } from "reactstrap";
import Header from "./Header";
import Middlepart from "./Middlepart";
import Sidebar from "./Sidebar";
import Alert from 'react-bootstrap/Alert';
import Toast from 'react-bootstrap/Toast';
import { useState, useEffect, useContext } from "react";
import { AlertContext, NotificationContext, HospitalContext, RoleContext } from "../utils/contexts/RoleContext";
import { getAllNotification } from "../services/notification-service";
import { socket } from "../index";
import { getCurrentUser } from "../services/auth.service";
import { getOneDoctor } from "../services/doctor-service";
import { getClusters, getHospitals } from "../services/user.service";
import { useNavigate } from "react-router-dom";


// import MiddlePart from "./Middlepart";


const FullLayout = () => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationCount, setNotificationCount] = useState<any>(0);
  const [notifications, setNotifications] = useState<any>([]);
  const [successValue, setSuccessValue] = useState("");
  const [myProfile, setMyProfile] = useState<any>({});
  const [searchOn, setSearchOn] = useState(false);
  const [hospitals, setHospitals] = useState([]);
  const [selectedHospital, setSelectedHospital] = useState<any>();

  const navigate = useNavigate();
  const currentUser = useContext(RoleContext);

  useEffect(() => {
    getNotifications();
    getDoctor();
    getHospital();
    socket.on(getCurrentUser()?.id, (data: any) => {
      getNotifications();
    });

    return () => {
      socket.off(getCurrentUser()?.id);
    };
  }, []);

  useEffect(() => {
    const redirectPath = localStorage.getItem("redirectPath")
    if (redirectPath) {
      // window.location.href = JSON.parse(redirectPath);
      const path = redirectPath
      navigate(path)
      // console.log("JSON.parse(redirectPath)",path)
    }
  }, [currentUser])

  const getHospital = async () => {
    const response = await getHospitals();
    if (response?.success) {
      const hospitals = response?.data;
      setHospitals(hospitals)
      // console.log("hospitals", hospitals[0]?.id)
      setSelectedHospital({ id: -1, name: "Select All..." })
    }
  }

  const getNotifications = () => {
    getAllNotification().then(
      (data) => {
        setNotificationCount(data?.unreadCount);
        setNotifications((data?.unreadMessages?.concat(data?.notify?.rows))?.concat(data?.questionnaireRequests));
      },
      (error) => {
        console.log("Err", error)

        // setLoading(false);
        // setMessage(resMessage);
      }
    );

  }


  const getDoctor = async () => {
    const user = getCurrentUser()
    getOneDoctor(user?.id).then(
      (data) => {
        const response = data?.allDoctors;
        setMyProfile(response);
      },
      (error) => {
        console.log("Err", error)
      }
    );
  };

  return (
    <main>
      <AlertContext.Provider value={{
        showToast: true,
        setShowToast: (message: string, value: string) => {
          setSuccessValue(value)
          setShow(true);
          setMessage(message);
          setTimeout(() => setShow(false), 5000)
        }
      }}>
        <NotificationContext.Provider
          value={{
            count: notificationCount,
            setCount: (value: any) => setNotificationCount(value),
            notifications: notifications,
            setNotifications: () => getNotifications(),
            profile: myProfile,
            setProfile: () => getDoctor(),
            clearAll: () => setNotifications([]),
            searchOn: searchOn,
            setSearchOn: (value: boolean) => setSearchOn(value)
          }}
        >
          <HospitalContext.Provider
            value={{
              hospitals: hospitals,
              setHospital: (id: number) => setSelectedHospital(id),
              selectedHospital: selectedHospital
            }}
          >
            <div className="pageWrapper d-lg-flex">
              {/********Sidebar**********/}
              <aside className="sidebarArea iboost-box-shadow" id="sidebarArea">
                <Sidebar />
              </aside>
              {/********Content Area**********/}
              <div className="contentArea">
                {/********header**********/}
                <Header />
                {show &&

                  <Toast className={successValue === "success" ? "float-start end-0 position-absolute show bg-success z-3" : "float-start end-0 position-absolute show bg-danger z-3"}>
                    <Toast.Header className={`show ${successValue === "success" ? "bg-success" : "bg-danger"}`}>

                      <strong className="me-auto text-white">Success Message</strong>

                    </Toast.Header>
                    <div aria-live="polite" aria-atomic="true" className="d-flex justify-content-center align-items-center w-100 mb-10 text-white bg-transparent">
                      <div className="fade toast show  bg-transparent" role="alert" aria-live="assertive" aria-atomic="true">
                        <div className="fade toast show bg-transparent p-4 text-white">
                          {message}
                          {/* test mesasge */}
                        </div>
                      </div>
                    </div>
                  </Toast>
                }


                {/********Middle Content**********/}
                <Container className={`p-4 wrapper ${searchOn && "mt-3"}`} fluid>
                  <Middlepart />
                </Container>

              </div>
            </div>
          </HospitalContext.Provider>
        </NotificationContext.Provider>
      </AlertContext.Provider>
    </main>
  );
};

export default FullLayout;