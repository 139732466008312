import {
  Row,
  Col
} from "reactstrap";
import React, { useContext, useEffect, useState } from 'react';
import { createSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { getallpatient } from "../../../services/patient-service";
import { createDoctor, getOneDoctor, updateDoctor } from "../../../services/doctor-service";
import { createMedicalRecord } from "../../../services/medicalrecord-service";
import { RoleContext, AlertContext } from "../../../utils/contexts/RoleContext";
import constants from "../../../config/constants";
import { capitalizeFirstLetter, removeDuplicates } from "../../../utils/utils";
import { getPatientAppointments, getBookingsOfOneDoctor } from "../../../services/scheduleappointment-service";
import { getallDoctor } from "../../../services/doctor-service";
import { getCurrentUser } from "../../../services/auth.service";

const AddMedical = () => {

  const [patients, setPatients] = useState([]);
  const [dropDownValue, setDropDownValues] = useState("");
  const [htmlFormUsers, sethtmlFormUsers] = useState<any>({});
  const [getvalues, setGetvalues] = React.useState<any>({});
  const [attachfile, setAttachedFile] = useState<any>(null)
  const toast: any = useContext(AlertContext);
  const [fileName, setFileName] = useState<any>([]);
  const navigate = useNavigate();
  const currentUser = useContext(RoleContext);
  const [serviceType, setServiceType] = useState("-1");
  const [service, setService] = useState<any>({
    serviceName: "",
    provider: ""
  });
  const [appointments, setAppointments] = useState<any>([]);
  const [doctors, setDoctors] = useState([]);
  // const [selectedAppointment, setSelectedAppointment] = useState<any>(null);
  const [error, setError] = useState({
    dropDownValue: "",
    serviceName: "",
    provider: "",
    appointment: "",
    serviceType: ""
  });

  const location = useLocation();

  useEffect(() => {
    if (currentUser === "doctor") {
      myPatients();
    } else {
    getUsers();
    }
    document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Add Medical Records`;
    if (currentUser !== "doctor") {
      fetchDoctors();
    }
  }, [currentUser]);


  async function myPatients() {
    const response = await getBookingsOfOneDoctor(getCurrentUser().id);
    if (response?.success) {
      const patients = removeDuplicates(response?.data, "patientId")?.map((item: any) => {
        return {id: item?.patientId, firstName: item?.patientName?.split(" ")[0], lastName: item?.patientName?.split(" ")[1]}
      });
      setPatients(patients)
    }
  }

  const getDoctor = async (value: any) => {
    getOneDoctor(value).then(
      (data) => {
        const allDoctor = data.allDoctors;
        const userForm: any = {};
        let array: any = [];
        allDoctor?.user_form_data?.map((item: any) => {
          let obj: any = {}
          // obj[item.formFieldKey] = item.formFieldValue
          obj.fieldKey = item.formFieldKey
          obj.fieldValue = item.formFieldValue
          obj.formId = item.formId
          obj.id = item?.user_form_field?.id
          obj.label = item?.user_form_field?.label
          obj.type = item?.user_form_field?.type
          obj.defaultValue = item?.user_form_field?.defaultValue

          if (userForm[item?.user_form?.formName] === undefined) {
            userForm[item?.user_form?.formName] = [obj]
          } else {
            userForm[item?.user_form?.formName] = [...userForm[item?.user_form?.formName], obj]
          }
          array.push(obj)
          setGetvalues((prevState: any) => ({
            ...prevState, [item?.user_form?.formName]: { ...prevState[item?.user_form?.formName], [item?.formFieldKey]: { id: item?.user_form_field?.id, value: item?.formFieldValue, formId: item?.formId } }
          }))
        })

        sethtmlFormUsers(userForm)
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        // setLoading(false);
        // setMessage(resMessage);
      }
    );


  };
  const handleFileUpload = (post: any) => {
    // const formName = formname
    // const formId = formid
    // const fieldId = id
    if (post.target.files && post.target.files.length > 0) {
      const filearray: any = []
      const fileBase64: any = []
      const result: any = []
      for (let i = 0; i < post.target.files.length; i++) {
        let file = post.target.files[i];


        filearray.push(file.name)
        // setFileName(filearray);

        let reader = new FileReader();
        if (file) {
          const maxSize = 5 * 1024 * 1024;
          if (file.size > maxSize) {
            alert('Can not upload this file. Max Size is 5MB');
            post.target.value = '';
          } else {


            const fileSize = file.size;
            const chunkSize = 1024 * 1024; // 1MB chunk size (you can adjust this as needed)
            const chunks = Math.ceil(fileSize / chunkSize);
            let uploadedChunks = 0;
            for (let i = 0; i < chunks; i++) {
              const start = i * chunkSize;
              const end = Math.min(fileSize, start + chunkSize);


              uploadedChunks++;
              //  const percentage= Math.floor((uploadedChunks * 100) / chunks)
              const percentage = Math.min(100, Math.round((uploadedChunks / chunks) * 100));
              // setProgress(percentage)

            }



            reader.readAsDataURL(file);
            reader.onload = () => {
              let obj = {
                fileName: file.name,
                result: reader.result
              }
              fileBase64.push(obj)
              if (attachfile === null) {
                setAttachedFile(fileBase64);
              }
              else {
                const valueset = attachfile?.concat(fileBase64)
                setAttachedFile(valueset);
              }
              // setAttachedFile(reader.result);
              // let obj={}
              const results = { result: reader.result, fileName: file.name.split('.')[1], folderName: 'medicalrecord', originalName: file.name.split('.')[0] };

              result.push(results)
              setFileName([...fileName, result])
              // 
              // setGetvalues((prevState: any) => ({
              //   ...prevState, ['Basic Information']: { ...prevState['Basic Information'], result }
              // }))
              // setGetvalues((prevState: any) => ({
              //   ...prevState, ["document"]: { ...prevState['document'], result }
              // }))

            };
          }
        }
      }
    }
  };

  const handleSelect = (event: any) => {
    setError((prevState: any) => ({ ...prevState, dropDownValue: "" }))
    sethtmlFormUsers({});
    setAttachedFile(null);
    const patientvalue = event.target.value

    setDropDownValues(patientvalue);
    getDoctor(event.target.value);
    setService({});
    setAppointments([]);
    setServiceType("-1")
  };
  const getUsers = () => {
    getallpatient("undefined", location.state?.hospitalId).then(
      (response) => {
        const patientData = response?.result;
        setPatients(patientData);
      },
      (error) => {
        console.log("error", error)

        // setLoading(false);
        // setMessage(resMessage);
      }
    );
  }


  const handleDownload = (indexes?: any) => {
    attachfile.map((item: any, index: number) => {

      if (index === indexes) {
        const link = document.createElement('a');
        link.href = item?.result;
        link.download = item?.fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    })

  };

  const handleDeleteDocument = (indexes?: any) => {
    //     attachfile.map((imtess:any,indexs:any)=>{
    // // if(indexs===index){
    // //   // setAttachedFile("");
    // // }
    //     })
    setAttachedFile(attachfile.filter((item: any, index: number) => index !== indexes))

    // setFileName("")

  };

  const FormOnChange = (event: any) => {
    const { name, value, id } = event.target;
    const formId = event?.target?.id?.split("/")[1]
    const fieldId = event?.target?.id?.split("/")[0]
    const formName = event?.target?.id?.split("/")[2]
    // const name = event.target.name;
    // const value = event.target.value;
    // const obj = {
    //   "field_id": event.target.id,
    //   "field_key": event.target.name,
    //   "field_value": event.target.value
    // };
    // setGetvalues({ ...getvalues, [name]: value, "field_id": event.target.id });
    setGetvalues((prevState: any) => ({
      ...prevState, [formName]: { ...prevState[formName], [name]: { id: JSON.parse(fieldId), value: value, formId: formId } }
    }))
  }

  function validate() {
    let validationSuccess = true
    if (dropDownValue == "") {
      validationSuccess = false
      setError((prevState: any) => ({ ...prevState, dropDownValue: "Please select a patient" }))
    }
    if (serviceType === "-1") {
      validationSuccess = false
      setError((prevState: any) => ({ ...prevState, serviceType: "Please select a service type" }))
    }
    if (serviceType === "Appointment") {
      if (!service.appointmentId) {
        setError((prevState: any) => ({ ...prevState, appointment: "Please select an appointment" }));
        validationSuccess = false
      }
    } else {
      if (!service.serviceName) {
        setError((prevState: any) => ({ ...prevState, serviceName: "Service name is required" }));
        validationSuccess = false
      }
      if (!service.provider && currentUser !== "doctor") {
        setError((prevState: any) => ({ ...prevState, provider: "Please select a provider" }));
        validationSuccess = false
      }
    }
    return validationSuccess;
  }

  const createOneDoctor = async () => {
    if (await validate()) {
    const formValues = { ...getvalues }
      formValues.document = fileName
    // for (let key in formValues) {
    //     for (let i in formValues[key]) {
    //         formValues[key][i].roleId = roleId
    //     }
    // }
      const serviceValue = { ...service }
      if (currentUser === "doctor") {
        serviceValue.provider = getCurrentUser().id
      }
      createMedicalRecord(formValues, dropDownValue, serviceValue).then(
      (data) => {
          navigate(`/${currentUser}/medical-records`);
        setTimeout(() => {
          // navigate(route);
          toast.setShowToast(data.message, "success");
        }, 1000);

      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();


      }
    );
    }
  };

  function handleService(e: any) {
    const { value } = e.target
    setServiceType(value);
    if (value === "Appointment") {
      fetchAppointments();
    } else {
      setAppointments([]);
    }
    setService({});
    setError((prevState: any) => ({ ...prevState, serviceType: "" }))
  }

  async function fetchAppointments() {
    const response = await getPatientAppointments(dropDownValue);
    if (response?.success) {
      setAppointments(response.data);
    }
  }

  function handleServiceChange(e: any) {
    const { name, value } = e.target;
    if (name === "appointment") {
      const appointment = appointments.find((appointment: any) => appointment.id == value);
      setService({
        appointmentId: value,
        provider: appointment.doctorId,
        serviceName: "Appointment"
      })
    } else {
      setService((prevState: any) => ({ ...prevState, [name]: value }))
    }
    setError((prevState: any) => ({ ...prevState, [name]: "" }))
  }

  const fetchDoctors = async (page?: any) => {
    const response = await getallDoctor("undefined", location.state?.hospitalId);
    setDoctors(response.result)
  }

  return (
    <Row>
      <div className="panel_top_section">
        <h1>Add New Medical Record</h1>

      </div>
      <Col className="mt-3 mb-4">
        <div className="add_doctor_form card_box col-sm-12 col-xl-9 mx-auto py-4 px-lg-4 mt-4">
          <div className="add_doctor_fom px-4">
            <div className="row justify-content-around border-bottom mb-4 pb-4">
              <div className="col-lg-12 col-xl-4 d-flex align-items-center">
                <p className=" text-black-50 fw-medium">Patient list</p>
              </div>
              <div className="col-lg-12 col-xl-8">
                <div className="col-lg-12 col-xl-6 mb-3">
                  {/* <label htmlFor="phone" className="form-label">Gender</label> */}
                  <select className="form-select" aria-label="Default select example" value={dropDownValue} onChange={(e) => handleSelect(e)}>
                    <option >Select</option>
                    {patients?.map((item: any) => {
                      return (
                        <option selected value={item.id}>{item.firstName} {item.lastName}</option>
                      )
                    })}

                  </select>
                  {error.dropDownValue &&
                    <p className="mt-2 text-danger">{error.dropDownValue}</p>
                  }
                </div>
              </div>
            </div>
            {dropDownValue !== "" &&
              <div className="row justify-content-around border-bottom mb-4 pb-4">
                <div className="col-lg-12 col-xl-4 d-flex align-items-center">
                  <p className=" text-black-50 fw-medium">Services</p>
                </div>
                <div className="col-lg-12 col-xl-8">
                  <div className="col-lg-12 col-xl-6 mb-3">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      value={serviceType}
                      onChange={handleService}
                    >
                      <option value="-1">Select</option>
                      <option value="Appointment">Appointment</option>
                      <option value="Other Services">Other services</option>
                    </select>
                    {error.serviceType &&
                      <p className="mt-2 text-danger">{error.serviceType}</p>
                    }
                  </div>
                  {serviceType === "Other Services" &&
                    <div className="col-xl-12 row">
                      <div className="col-xl-6">
                        <label>Service Name</label>
                        <input
                          className="form-control mt-2"
                          value={service.serviceName}
                          name="serviceName"
                          onChange={handleServiceChange}
                        />
                        {error.serviceName &&
                          <p className="mt-2 text-danger">{error.serviceName}</p>
                        }
                      </div>
                      {currentUser !== "doctor" &&
                        <div className="col-xl-6">
                          <label>Service Provider</label>
                          <select
                            className="form-select mt-2"
                            value={service.provider}
                            name="provider"
                            onChange={handleServiceChange}
                          >
                            <option value="-1">Select...</option>
                            {doctors.map((item: any) => {
                              return (
                                <option value={item.id}>{item.firstName + " " + item.lastName}</option>
                              )
                            })}
                          </select>
                          {error.provider &&
                            <p className="mt-2 text-danger">{error.provider}</p>
                          }
                        </div>
                      }
                    </div>
                  }
                  {serviceType === "Appointment" &&
                    <div className="col-xl-6">
                      {appointments?.length > 0 ?
                        <select
                          className="form-control mt-2"
                          defaultValue="-1"
                          name="appointment"
                          onChange={handleServiceChange}
                          value={service.appointmentId}
                        >
                          <option value="-1">Select...</option>
                          {appointments.map((appointment: any) => {
                            return (
                              <option value={appointment.id}>Appointment for {appointment.reason} at {appointment.date} {appointment.time}</option>
                            )
                          })}
                        </select>
                        :
                        <p className="mt-2 text-danger">No appointments found.</p>
                      }
                      {error.appointment &&
                        <p className="mt-2 text-danger">{error.appointment}</p>
                      }
                    </div>
                  }
                  <div>
                  </div>
                </div>
              </div>
            }
            {/* personal-inoformation */}
            {/* <div className="row justify-content-around border-bottom mb-4 pb-4">
              <div className="col-lg-12 col-xl-4">
                <p className="text-black-50 fw-medium">Personal Information</p>
              </div>
              <div className="row  col-lg-12 col-xl-8">
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="firsname" className="form-label">First Name</label>
                  <input type="text" className="form-control" id="firstname" />
                </div>
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="Name" className="form-label">Last Name</label>
                  <input type="text" className="form-control" id="lastname" />
                </div>
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="phone" className="form-label">Gender</label>
                  <select className="form-select" aria-label="Default select example">
                    <option selected>Select</option>
                    <option value="1">Male</option>
                    <option value="2">Female</option>
                  </select>
                </div>
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="phone" className="form-label">Date of Birth</label>
                  <input type="date" className="form-control" id="phone" />
                </div>
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="phone" className="form-label">Phone</label>
                  <input type="text" className="form-control" id="phone" />
                </div>
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="email" className="form-label">Email</label>
                  <input type="text" className="form-control" id="email" />
                </div>
                <div className="col-sm-12 mb-3 textarea">
                  <label htmlFor="address" className="form-label">Address</label>
                  <textarea className="textarea_input form-control" id="address"></textarea>
                </div>
              </div>
            </div> */}
            <div className="row justify-content-around ">
              {Object.keys(htmlFormUsers)?.map((keys: any) => {
                if (keys !== "Personal Information" && keys !== "Patient Document" && keys !== "undefined") {
                  return (
                    <div className="row justify-content-around border-bottom mb-4 pb-4">
                      <div className="col-lg-12 col-xl-4">
                        <p className="text-black-50 fw-medium">{keys}</p>
                      </div>
                      <div className="row col-lg-12 col-xl-8">
                        {htmlFormUsers[keys].map((item: any) => {
                          return (
                            <>
                              {item.type === "select" ?
                                <div className="col-lg-12 col-xl-6 mb-3">
                                  <label htmlFor="phone" className="form-label">{item.label}</label>

                                  <select className="form-select" aria-label="Default select example" disabled name={item.fieldKey} id={`${item.id + "/" + item.formId + "/" + keys}`}
                                    onChange={e => FormOnChange(e)}
                                    defaultValue={item.fieldValue} >
                                    {JSON.parse(item.defaultValue)?.map((item: any) => {
                                      return (
                                        <option value={item.value} selected>{item.label}</option>
                                      )
                                    })}

                                  </select>


                                </div> : null}
                              {item.type == "textfield" ?
                                <div className="col-lg-12 col-xl-6 mb-3">
                                  <label htmlFor="license" className="form-label">{item.label}</label>
                                  <input type={item?.type} disabled className="form-control" id={`${item.id + "/" + item.formId + "/" + keys}`}
                                    onChange={FormOnChange}
                                    name={item.fieldKey} defaultValue={item.fieldValue} />
                                </div> : null}

                              {item.type === "datetime" ?
                                <div className="col-lg-12 col-xl-6 mb-3">
                                  <label htmlFor="phone" className="form-label">{item.label}</label>
                                  <input type="date" disabled className="form-control" id={`${item.id + "/" + item.formId + "/" + keys}`}
                                    onChange={FormOnChange}
                                    name={item.fieldKey} defaultValue={item.fieldValue} />
                                </div> : null}

                              {item.type === "textarea" ?
                                <div className="col-sm-12 mb-3 textarea">
                                  <label htmlFor="address" className="form-label">{item.label}</label>
                                  <textarea className="textarea_input form-control" disabled id={`${item.id + "/" + item.formId + "/" + keys}`}
                                    onChange={FormOnChange}
                                    name={item.fieldKey} defaultValue={item.fieldValue}></textarea>
                                </div> : null}

                            </>
                          )
                        })}
                      </div>
                    </div>
                  )
                }
              })}
            </div>


            <div className="row justify-content-around border-bottom mb-4 pb-4">
              <div className="col-lg-12 col-xl-4">
                <p className=" text-black-50 fw-medium">Document Uploads</p>
              </div>
              <div className="row col-lg-12 col-xl-8">
                <div className="col-sm-12">
                  {/* <div className="col-xl-5 mb-4"> */}
                  <input type="file" multiple id="upload" hidden name="file upload" onChange={(e) => {
                    handleFileUpload(e);
                  }} accept=".csv, .pdf, .doc, .docx, .xlsx, .xls, .png, .jpeg, .jpg, text/plain" />
                  <label className="upload_btn" htmlFor="upload"><span className="up_arrow clickable"><i className="bi bi-cloud-arrow-up-fill"></i></span>Upload</label>
                  {/* </div> */}
                  {/* <input type="file" id="upload" hidden />
                  <label className="upload_btn" htmlFor="upload"><span className="up_arrow"><i className="bi bi-cloud-arrow-up-fill"></i></span>Upload</label> */}
                </div>
                <div className="col-xl-7">
                  {attachfile != null &&
                    attachfile.map((result: any, index: number) => {
                      return (
                        <div className="border rounded float-start p-0 d-flex align-items-center">
                          <span className="fs-4 d-inline-block py-2 px-3  border-end"><i className="bi bi-file-earmark-text-fill"></i></span>
                          <span className="px-2 text_gray py-2 px-3 ">{result?.fileName}</span>
                          <button className=" px-3 fs-4 py-2 border-0 bg-transparent border-start" onClick={() => handleDownload(index)}><i className="bi bi-box-arrow-down"></i></button>
                          <button className=" px-3 fs-4 py-2 border-0 bg-transparent border-start" onClick={() => handleDeleteDocument(index)}><i className="bi bi-x-circle"></i></button>

                        </div>
                      )
                    })


                  }
                </div>
              </div>
            </div>
            <div className="col-sm-12 mb-3 textarea">
              <label htmlFor="address" className="form-label">Note</label>
              <textarea className="textarea_input form-control"

                onChange={(event) => setGetvalues((prevState: any) => ({
                  ...prevState, ["note"]: event?.target?.value
                }))}
              ></textarea>
            </div>
            {/* canceland-save*/}
            <div className="row  mb-4 pb-4">
              <div className="col-lg-12 col-xl-4">

              </div>
              <div className="form_bottom_btn d-flex justify-content-end">
                <button className="delete_btn" type="button" onClick={() => navigate(`/${currentUser}/medical-records`)}>Cancel</button>
                <button className="btn_gradian" type="button" onClick={() => createOneDoctor()}>Save</button>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default AddMedical;