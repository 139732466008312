import * as Yup from "yup";

import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import ckeditor, { CKEditor } from '@ckeditor/ckeditor5-react';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import IEmail from "../../../types/email.type";
import { RoleContext } from "../../../utils/contexts/RoleContext";
import { register } from "../../../services/emailtemplates-service";
import { useNavigate } from 'react-router-dom';

const CreateEmailTemplates: React.FC = () => {
  const [successful, setSuccessful] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [content, setContent] = useState<string>('');
  const navigate=useNavigate()
  const initialValues: IEmail = {
    subjecttitle: "",
    keyword: ""
  };
  const currentUser = useContext(RoleContext);

  const validationSchema = Yup.object().shape({
    subjecttitle: Yup.string()
      .test(
        "len",
        "The subject title must be between 3 and 20 characters.",
        (val: any) =>
          val &&
          val.toString().length >= 3 &&
          val.toString().length <= 20
      )
      .required("This field is required!"),
      keyword: Yup.string()
      .test(
        "len",
        "The keyword must be between 3 and 20 characters.",
        (val: any) =>
          val &&
          val.toString().length >= 3 &&
          val.toString().length <= 20
      )
      .required("This field is required!"),
    
    //   content: Yup.string()
    //   .test(
    //     "len",
    //     "The content must be between 6 and 40 characters.",
    //     (val: any) =>
    //       val &&
    //       val.toString().length >= 6 &&
    //       val.toString().length <= 40
    //   )
    //   .required("This field is required!"),
   
  });

  const handleRegister = (formValue: IEmail) => {
    const { subjecttitle, keyword } = formValue;
    register(subjecttitle, keyword, content).then(
      (response) => {
        // setMessage(response.data.message);
        setSuccessful(true);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        // setMessage(resMessage);
        setSuccessful(false);
      }
      
    );
    navigate(`/${currentUser}/email_templates`)
  };


  return (
    <div className="col-md-12">
      <div className="card email-container">
      <div>
    <button className='btn btn-primary'   onClick={()=>navigate(`/${currentUser}/email_templates`)}>
            Back
          </button>
    </div>
      <div style={{ fontWeight: "bold", fontSize: 23, marginBottom: 40 }}>Email Template Content</div> 
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleRegister}
        >
          <Form>
            {!successful && (
              <div>
                <div className="form-group">
                  <label htmlFor="subjecttitle"> Subject Title </label>
                  <Field name="subjecttitle" type="text" className="form-control" />
                  <ErrorMessage
                    name="subjecttitle"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="keyword"> Key Word </label>
                  <Field name="keyword" type="text" className="form-control" />
                  <ErrorMessage
                    name="keyword"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="content"> content </label>
                  <CKEditor 
                  editor={ClassicEditor}
                  
                  data={content}
                  
                  
                  onReady={ editor => {
                      // You can store the "editor" and use when it is needed.
                      // console.log( 'Editor is ready to use!', editor );
                  } }
                  onChange={ ( editor,event ) => {
                    //   formsubmission(event)
                    setContent(event.getData())
                    // console.log("events",event.getData())
                  } }
                  onBlur={ ( event, editor ) => {
                      // console.log( 'Blur.', editor.getData() );
                  } }
                  onFocus={ ( event, editor ) => {
                      console.log( 'Focus.', editor.getData() );
                  } }


                  
                //   onInit ={editor => {

                //   }}
                  />
                  {/* <Field name="content" type="text" className="form-control" /> */}
                  <ErrorMessage
                    name="content"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>

               


                <div className="form-group">
                  <button type="submit" className="btn btn-primary btn-block">Save</button>
                </div>
              </div>
            )}

            {/* {message && (
              <div className="form-group">
                <div
                  className={
                    successful ? "alert alert-success" : "alert alert-danger"
                  }
                  role="alert"
                >
                  {message}
                </div>
              </div>
            )} */}
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default CreateEmailTemplates;