import { Button, Nav, NavItem } from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { AiOutlineInsertRowLeft } from "react-icons/ai";
import Logo from "./Logo";
import { RoleContext, NotificationContext, RoleUpdateContext, HospitalContext } from "../utils/contexts/RoleContext";
import { useContext, useState } from "react";
import user1 from "../assets/images/users/user1.jpg";
import { getCurrentUser, ImagePath } from "../services/auth.service";
import { socket } from "../index"
import { logout } from "../services/auth.service";
import Calendar from "../assets/images/sidebar/calendar.webp";
import RefillRequest from "../assets/images/sidebar/refillrequest.webp";
import Settings from "../assets/images/sidebar/settings.webp";
import Doctor from "../assets/images/sidebar/stethoscope.webp";
import Payment from "../assets/images/sidebar/payment.webp";
import Logout from "../assets/images/sidebar/logout.webp";
import Chat from "../assets/images/sidebar/chat.webp";
import MedicalRecord from "../assets/images/sidebar/document.webp";
import Patients from "../assets/images/sidebar/patients.webp";
import Questionnaires from "../assets/images/sidebar/questionnaires.webp";
import Telehealth from "../assets/images/sidebar/telehealth.webp";
import Dashboard from "../assets/images/sidebar/dashboard.webp";
import Location from "../assets/images/sidebar/location.webp";


export const hospital = [
  {
    title: "Dashboard",
    href: "/hospital/Dashboard",
    // icon: "bi bi-grid",
    image: Dashboard
  },
  {
    title: "Doctors",
    href: "/hospital/doctordetaillist",
    // icon: "bi bi-bell",
    image: Doctor
  },
  {
    title: "Patients",
    href: "/hospital/patient-list",
    // icon: "bi bi-patch-check",
    image: Patients
  },
  {
    title: "Staffs",
    href: "/hospital/stafflist",
    icon: "bi bi-person-workspace",
  },
  {
    title: "Locations",
    href: "/hospital/locations",
    // icon: "bi bi-geo-alt",
    image: Location
  },
  {
    title: "Manage form",
    href: "/hospital/manageform",
    icon: "bi bi-file-earmark-text",
  },
  {
    title: "Billing / Payments",
    href: "/hospital/billing",
    // icon: "bi bi-hdd-stack",
    image: Payment
  },
  {
    title: "Medical Records",
    href: "/hospital/medical-records",
    // icon: "bi bi-file-earmark",
    image: MedicalRecord
  },
  {
    title: "Appointments",
    href: "/hospital/appointment",
    // icon: "bi bi-columns",
    image: Calendar
  },
  {
    title: "Messages",
    href: "/hospital/messages",
    // icon: "bi bi-chat-text",
    image: Chat
  },
  {
    title: "Telehealth",
    href: "/hospital/telehealth",
    // icon: "bi bi-textarea-resize",
    image: Telehealth
  },
  {
    title: "Refill Requests",
    href: "/hospital/refill-request",
    // icon: "bi bi-link",
    image: RefillRequest
  },
  {
    title: "My Subscriptions",
    href: "/hospital/subscription", 
    icon: "bi bi-receipt",
  },
  // {
  //   title: "Questionnaire",
  //   href: "/hospital/questionnaire-forms",
  //   icon: "bi bi-list-ul",
  // },
  // {
  //   title: "Settings",
  //   href: "/hospital/settings",
  //   icon: "bi bi-gear",
  // }
];

export const admin: any = [
  {
    title: "Dashboard",
    href: "/admin/Dashboard",
    // icon: "bi bi-grid",
    image: Dashboard
  },
  {
    title: "Users",
    href: "/admin/getprofile",
    icon: "bi bi-person",
  },
  {
    title: "Hospitals",
    href: "/admin/hospital",
    icon: "bi bi-hospital",
    subMenus: [
      {
        title: "Doctors",
        href: "/admin/doctordetaillist",
        // icon: "bi bi-bell",
        image: Doctor
      },
      {
        title: "Patients",
        href: "/admin/patient-list",
        // icon: "bi bi-patch-check",
        image: Patients
      },
      {
        title: "Staffs",
        href: "/admin/stafflist",
        icon: "bi bi-person-workspace",
      },
    ]
  },
  {
    title: "Locations",
    href: "/admin/locations",
    // icon: "bi bi-geo-alt",
    image: Location
  },
  {
    title: "Manage form",
    href: "/admin/manageform",
    icon: "bi bi-file-earmark-text",
  },
  {
    title: "Billing / Payments",
    href: "/admin/billing",
    // icon: "bi bi-hdd-stack",
    image: Payment
  },
  {
    title: "Medical Records",
    href: "/admin/medical-records",
    // icon: "bi bi-file-earmark",
    image: MedicalRecord
  },
  {
    title: "Appointments",
    href: "/admin/appointment",
    // icon: "bi bi-columns",
    image: Calendar
  },
  {
    title: "Messages",
    href: "/admin/messages",
    // icon: "bi bi-chat-text",
    image: Chat
  },
  {
    title: "Telehealth",
    href: "/admin/telehealth",
    // icon: "bi bi-textarea-resize",
    image: Telehealth
  },
  {
    title: "Refill Requests",
    href: "/admin/refill-request",
    // icon: "bi bi-link",
    image: RefillRequest
  },
  {
    title: "Invoices",
    href: "/admin/view-invoices",
    icon: "bi bi-receipt",
  },
  {
    title: "Manage Settings",
    href: "/admin/manage-settings",
    icon: "bi bi-gear-wide-connected",
  },
  // {
  //   title: "Questionnaire",
  //   href: "/admin/questionnaire-forms",
  //   icon: "bi bi-list-ul",
  // },
  // {
  //   title: "Settings",
  //   href: "/admin/settings",
  //   icon: "bi bi-gear",
  // }
];

// const patient = [
//   {
//     title: "Dashboard",
//     href: "/patient/Dashboard",
//     icon: "bi bi-grid",
//   },
//   {
//     title: "My Appointments",
//     href: "/patient/my-appointments",
//     icon: "bi bi-bell",
//   },
//   {
//     title: "My billings",
//     href: "/patient/billings",
//     icon: "bi bi-geo-alt",
//   },
//   {
//     title: "Messages",
//     href: "/patient/messages",
//     icon: "bi bi-chat-text",
//   },
//   {
//     title: "Patient Questionnaire",
//     href: "/patient/patient-questionnaire",
//     icon: "bi bi-list-ul",
//   }
// ];

export const patient = [
  // {
  //   title: "Dashboard",
  //   href: "/patient/Dashboard",
  //   icon: "bi bi-grid",
  // },
  // {
  //   title: "Patients",
  //   href: "/patient/patient-list",
  //   icon: "bi bi-patch-check",
  // },
  {
    title: "My Appointments",
    href: "/patient/appointment",
    // icon: "bi bi-bell",
    image: Calendar
  }, 
  {
    title: "My Doctors",
    href: "/patient/doctorlist",
    // icon: "bi bi-person",
    image: Doctor
  },
  {
    title: "Refill Requests",
    href: "/patient/refill-request",
    // icon: "bi bi-link",
    image: RefillRequest
  },
  {
    title: "Payments",
    href: "/patient/billing",
    // icon: "bi bi-coin",
    image: Payment
  },
  {
    title: "Messages",
    href: "/patient/messages",
    // icon: "bi bi-chat-text",
    image: Chat
  },
  {
    title: "Medical Records",
    href: "/patient/medical-records",
    // icon: "bi bi-file-earmark",
    image: MedicalRecord
  },
  // {
  //   title: "Patient Questionnaire",
  //   href: "/patient/patient-appointments",
  //   icon: "bi bi-list-ul",
  // },
  // {
  //   title: "Settings",
  //   href: "/patient/profile-settings",
  //   icon: "bi bi-gear",
  // }
 
  // {
  //   title: "Telehealth",
  //   href: "/patient/telehealth",
  //   icon: "bi bi-textarea-resize",
  // },

  // {
  //   title: "Questionnaire",
  //   href: "/patient/patient-questionnaire",
  //   icon: "bi bi-list-ul",
  // }
  // {
  //   title: "Prescriptions",
  //   href: "/doctor/prescriptionlist",
  //   icon: "bi bi-bell",
  // }
];

export const doctor = [
  {
    title: "Dashboard",
    href: "/doctor/Dashboard",
    // icon: "bi bi-grid",
    image: Dashboard
  },
  {
    title: "Patients",
    href: "/doctor/my-patients",
    // icon: "bi bi-patch-check",
    image: Patients
  },
  {
    title: "Medical Records",
    href: "/doctor/medical-records",
    // icon: "bi bi-file-earmark",
    image: MedicalRecord
  },
  {
    title: "My Appointments",
    href: "/doctor/appointment",
    // icon: "bi bi-bell",
    image: Calendar
  },
  {
    title: "My Availability",
    href: "/doctor/availability",
    icon: "bi bi-calendar-check",
  },
  {
    title: "Messages",
    href: "/doctor/messages",
    // icon: "bi bi-chat-text",
    image: Chat
  },
  {
    title: "Telehealth",
    href: "/doctor/telehealth",
    // icon: "bi bi-textarea-resize",
    image: Telehealth
  },
  {
    title: "Refill Requests",
    href: "/doctor/refill-request",
    // icon: "bi bi-link",
    image: RefillRequest
  },
  // {
  //   title: "Questionnaires",
  //   href: "/doctor/questionnaires",
  //   icon: "bi bi-list-ul",
  // },
  // {
  //   title: "Settings",
  //   href: "/doctor/profile-settings",
  //   icon: "bi bi-gear",
  // }
  // {
  //   title: "Prescriptions",
  //   href: "/doctor/prescriptionlist",
  //   icon: "bi bi-bell",
  // }
];

export const staff = [
  {
    title: "Dashboard",
    href: "/staff/staff-dashboard",
    // icon: "bi bi-grid",
    image: Dashboard
  },
  {
    title: "Doctors",
    href: "/staff/doctordetaillist",
    // icon: "bi bi-bell",
    image: Doctor
  },
  {
    title: "Patients",
    href: "/staff/patient-list",
    // icon: "bi bi-people",
    image: Patients
  },
  {
    title: "Locations",
    href: "/staff/locations",
    // icon: "bi bi-geo-alt",
    image: Location
  },
  {
    title: "Billing / Payments",
    href: "/staff/billing",
    // icon: "bi bi-coin",
    image: Payment
  },
  {
    title: "Appointments",
    href: "/staff/appointment",
    // icon: "bi bi-calendar",
    image: Calendar
  },
  {
    title: "Messages",
    href: "/staff/messages",
    // icon: "bi bi-chat-text",
    image: Chat
  },
  {
    title: "Refill Requests",
    href: "/staff/refill-request",
    // icon: "bi bi-link",
    image: RefillRequest
  }
];


const Sidebar = () => {
  const [subMenu, setSubMenu] = useState(false);
  const roleContext = useContext(RoleContext);
  const roleUpdate: any = useContext(RoleUpdateContext)
  const notification: any = useContext(NotificationContext);
  const hospitalContext: any = useContext(HospitalContext);
  const navigation = roleContext === "admin" ? admin : roleContext === "hospital" ? hospital : roleContext === "doctor" ? doctor : roleContext === "staff" ? staff : patient;

  const showMobilemenu = () => {
    document.getElementById("sidebarArea")?.classList.toggle("showSidebar");
  };
  let location = useLocation();
  const navigate = useNavigate();

  const logOut = () => {
    socket.emit("user_status", {
      status: "OFFLINE",
      id: getCurrentUser()?.id
    });
    logout();
    roleUpdate(undefined);
    navigate(`/${roleContext}/login`)
    // window.location.reload();
  };

  const mobileMenu = [
    {
      title: "My Account",
      href: `/${roleContext}/my-profile/${getCurrentUser()?.id}`,
      icon: false,
    },
    {
      title: "Edit profile",
      href: `/${roleContext}/editprofile/${getCurrentUser()?.id}`,
      icon: "bi bi-pen",
    }
  ]

  const centerContent = [
    {
      title: 'Questionnaires',
      href: `/${roleContext}/${roleContext === "patient" ? "patient-appointments" : roleContext === "doctor" ? "questionnaires" : "questionnaire-forms"}`,
      // icon: "bi bi-list-ul",
      image: Questionnaires
    },
    {
      title: 'Settings',
      // href: `/${roleContext}/${roleContext === "admin" ? "settings" : "profile-settings"}`,
      href: `/${roleContext}/settings`,
      // icon: "bi bi-gear",
      image: Settings
    }
  ]

  return (
    <div className="p-3">
      <div className="d-flex align-items-center justify-content-center pt-4 pb-2">
        <Logo />
        <span className="ms-auto d-lg-none">
          <Button
            close
            size="sm"
            className="ms-auto d-lg-none"
            onClick={() => showMobilemenu()}
          ></Button>
        </span>
      </div>
      <div className="pt-4 mt-2">
        <Nav vertical className="sidebarNav">
          <div className="mobile-menu d-lg-none">
            {mobileMenu?.map((navi: any, index: number) => {
              return (
                <NavItem
                  key={index}
                  className={`sidenav-bg `}
                  onClick={showMobilemenu}
                >
                  <Link
                    to={navi.href}
                    className={
                      location.pathname === navi.href
                        ? "sidebar-active text-white nav-link py-3"
                        : "nav-link text-secondary py-3"
                    }
                  >
                    {navi.icon ?
                      <i className={`${navi.icon} me-3`}></i>
                      :
                      <img
                        src={(notification?.profile?.profile != "0" && notification?.profile?.profile != null) ? `${ImagePath}` + 'profile/thumb/small_' + notification?.profile?.profile : user1}
                        alt="profile"
                        className="rounded-circle me-2"
                        width="25"
                      />
                    }
                    <span className="d-inline-block">{navi.title}</span>
                  </Link>
                </NavItem>
              )
            })}
          </div>
          {navigation?.map((navi: any, index: number) => (
            <div>
            <NavItem key={index}
              className={`sidenav-bg `}
                onClick={() => {
                  showMobilemenu()
                  if (navi.title === "Hospitals") {
                  setSubMenu(!subMenu)
                  }
                }}
            >
              <Link
                  to={(navi?.title !== "Hospitals" || hospitalContext.selectedHospital?.id == -1) ? navi.href : "/admin/hospital-dashboard"}
                className={
                  location.pathname === navi.href
                    ? "sidebar-active text-white nav-link py-3"
                    : "nav-link text-secondary py-3"
                }
              >
                  {navi.icon ?
                <i className={`${navi.icon} me-3`}></i>
                    :
                    <span className={location.pathname === navi.href ? "white-image" : "blue-image"}>
                      <img className="me-3 mb-1" src={navi.image} width={17} height={17} />
                    </span>
                  }
                <span className="d-inline-block">{navi.title}</span>
                  {(navi.title === "Hospitals" && hospitalContext.selectedHospital?.id != -1) &&
                    <span className="ms-2" style={{ fontSize: "11px" }}>{hospitalContext.selectedHospital?.name}</span>
                  }
                  {navi.title === "Hospitals" &&
                    <span className="float-end">
                      <i className={`bi bi-chevron-${subMenu ? "up" : "down"}`}></i>
                    </span>
                  }
              </Link>
            </NavItem>
              {subMenu &&
                <div>
              {navi?.subMenus?.map((item: any) => {
                return (
                  <NavItem key={index}
                    className={`sidenav-bg ms-3`}
                    onClick={showMobilemenu}
                  >
                    <Link
                      to={item.href}
                      className={
                        location.pathname === item.href
                          ? "sidebar-active text-white nav-link py-3"
                          : "nav-link text-secondary py-3"
                      }
                    >
                          {item.icon ?
                      <i className={`${item.icon} me-3`}></i>
                            :
                            <span className={location.pathname === item.href ? "white-image" : "blue-image"}>
                              <img className="me-3 mb-1" src={item.image} width={17} height={17} />
                            </span>
                          }
                      <span className="d-inline-block">{item.title}</span>
                    </Link>
                  </NavItem>
                )
              })}
                </div>
              }
            </div>
          ))}
          {/* <NavItem
            className={
              `sidenav-bg mt-5 bt-1`}>
            <span
              onClick={logOut}
              className={
                location.pathname === `/${roleContext}/logout`
                  ? "sidebar-active text-white nav-link py-3"
                  : "nav-link text-secondary py-3"
              }
            >
              <i className={`bi bi-box-arrow-right me-3`}></i>
              <span className="d-inline-block">Logout</span>
            </span>
          </NavItem> */}
          <div className="mt-4 border-top pt-4">
            {centerContent.map((nav: any, index: number) => {
              if (roleContext !== "staff" || nav.title !== "Questionnaires") {
              return (
                <NavItem key={index}
                  className={`sidenav-bg`}
                  onClick={showMobilemenu}
                >
                  <Link
                    to={nav.href}
                    className={
                      location.pathname === nav.href
                        ? "sidebar-active text-white nav-link py-3"
                        : "nav-link text-secondary py-3"
                    }
                  >
                    {nav.icon ?
                      <i className={`${nav.icon} me-3`}></i>
                      :
                      <span className={location.pathname === nav.href ? "white-image" : "blue-image"}>
                        <img className="me-3 mb-1" src={nav.image} width={17} height={17} />
                      </span>
                    }
                    <span className="d-inline-block">{nav.title}</span>
                  </Link>
                </NavItem>
              )
              }
            })}
          </div>
          <NavItem
            className={
              `sidenav-bg mt-5`}>
            <span
              onClick={logOut}
              className={
                location.pathname === `/${roleContext}/logout`
                  ? "sidebar-active text-white nav-link py-3"
                  : "nav-link text-secondary py-3"
              }
            >
              {/* <i className={`bi bi-box-arrow-right me-3`}></i> */}
              <span className={location.pathname === `/${roleContext}/logout` ? "white-image" : "blue-image"}>
                <img className="me-3 mb-1" src={Logout} width={17} height={17} />
              </span>
              <span className="d-inline-block">Logout</span>
            </span>
          </NavItem>
        </Nav>
      </div>
    </div>
  );
};

export default Sidebar;
