import {
  Row,
  Col,
} from "reactstrap";
import { useState, useEffect, useContext } from "react";
import { addLocation, getOneLocation, updateLocation } from "../../../services/location-service";
import { Country, State, City, ICountry } from 'country-state-city';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { RoleContext, AlertContext } from "../../../utils/contexts/RoleContext";
import Select from 'react-select';
import GooglePlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
import { FolderPath } from "../../../services/auth.service";
import Spinner from 'react-bootstrap/Spinner';
import constants from "../../../config/constants";
import { capitalizeFirstLetter } from "../../../utils/utils";

const Facilities = [
  {
    label: "option1",
    value: "option1",
  },
  {
    label: "option2",
    value: "option2",
  }
];

const MedicalServices = [
  {
    label: "option1",
    value: "option1",
  },
  {
    label: "option2",
    value: "option2",
  }
]

const SpecializedProcedures = [
  {
    label: "option1",
    value: "option1",
  },
  {
    label: "option2",
    value: "option2",
  }
]

const medicalEquipment = [
  {
    label: "option1",
    value: "option1",
  },
  {
    label: "option2",
    value: "option2",
  }
]

const Infrastructure = [
  {
    label: "option1",
    value: "option1",
  },
  {
    label: "option2",
    value: "option2",
  }
]

const AddLocation = () => {
  const [locationData, setLocationData] = useState<any>({
    locationName: "",
    streetAddress: "",
    city: "",
    state: "",
    // stateCode: "",
    country: "",
    // countryCode: "",
    // latitude: 0,
    // longitude: 0,
    pincode: "",
    phoneNumber: "",
    emailAddress: "",
    faxNumber: ""
  });
  const [otherDetails, setOtherdetails] = useState<any>({
    rooms: "",
    numberOfBeds: "",
    doctors: "",
    nurses: "",
    staffs: "",
    facilities: "",
    medicalServices: "",
    specializedProcedures: "",
    medicalEquipment: "",
    infrastructure: ""
  });
  const [file, setFile] = useState<any>(null);
  const [countries, setCountries] = useState<any>([]);
  const [states, setStates] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const [place, setPlace] = useState();
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = useContext(RoleContext);
  const Alert: any = useContext(AlertContext);
  const { locationId }: any = useParams();

  const handleDownload = (indexes?: any) => {
    // attachfile.map((item: any, index: number) => {

    //   if (index === indexes) {
    const link = document.createElement('a');
    link.href = file.result;
    link.download = file.file;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    //   }
    // })

  };

  const handleDeleteDocument = (indexes?: any) => {
    // console.log("Dteireuuerer")
    //     attachfile.map((imtess:any,indexs:any)=>{
    // //       console.log("Dtjer",indexs===index)
    // // if(indexs===index){
    // //   // setAttachedFile("");
    // // }
    //     })
    // setAttachedFile(attachfile.filter((item: any, index: number) => index !== indexes))
    setFile(null);
    // setFileName("")

    // setFileName("")

  };


  useEffect(() => {
    setCountries(Country?.getAllCountries());
    if (locationId) {
      getSingleLocation();
      document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Edit Location`;
    } else { 
      document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Add Location`;
    }
  }, [currentUser]);


  function handlePlaceChange(e: any) {
    const { label, value } = e;
    geocodeByPlaceId(value.place_id)
      .then((results: any) => {
        getLatLng(results[0]).then((data: any) =>
          setLocationData((prevState: any) => ({ ...prevState, latitude: data.lat, longitude: data.lng }))
        );
        const details = results[0];
        let city: string = "",
          country: string = "",
          state: string = "",
          pincode: string = "",
          administrative_area_level_1: string = "",
          administrative_area_level_1_short_code = "",
          administrative_area_level_2: string = "",
          administrative_area_level_2_short_code = "",
          locality: string = "",
          administrative_area_level_3: string = "",
          stateCode: "",
          countryCode: "";
        details.address_components.map((item: any) => {
          item.types.map((res: any) => {
            if (res == "locality") {
              locality = item.long_name
            }
            if (res == "country") {
              country = item.long_name;
              countryCode = item.short_name;
            }
            if (res == "administrative_area_level_1") {
              administrative_area_level_1 = item.long_name;
              administrative_area_level_1_short_code = item.short_name
            }
            if (res == "administrative_area_level_2") {
              administrative_area_level_2 = item.long_name;
              administrative_area_level_2_short_code = item.short_name
            }
            if (res == "administrative_area_level_3") {
              administrative_area_level_3 = item.long_name
            }
            if (res == "postal_code") {
              pincode = item.long_name
            }
          });
        });
        state = administrative_area_level_1 !== "" ? administrative_area_level_1 : administrative_area_level_2;
        city = administrative_area_level_3 !== "" ? administrative_area_level_3 : locality;
        setLocationData((prevState: any) => ({
          ...prevState,
          city,
          state,
          country,
          pincode,
          streetAddress: label,
          countryCode,
          stateCode: administrative_area_level_1_short_code !== "" ? administrative_area_level_1_short_code : administrative_area_level_2_short_code
        }));
        setPlace(e);
        // if (pincode === "") {
        //   alert("Select the exact location");
        // } else {
        //   setLocationData((prevState: any) => ({ ...prevState, city, state, country, pincode, streetAddress: label }));
        //   setPlace(e);
        // }
      })
      .catch((error: any) => console.error(error));
  }

  async function getSingleLocation() {
    const locationData = await getOneLocation(locationId);
    const otherDetails = locationData?.data?.locationdatum;
    if (otherDetails !== null) {
      for (let key in otherDetails) {
        if (key === "infrastructure" || key === "medicalServices" || key === "medicalEquipment" || key === "specializedProcedures" || key === "facilities") {
          otherDetails[key] = JSON.parse(otherDetails[key])
        }
      }
      if (otherDetails?.document !== null) {
        // const filePath = `${FolderPath}${otherDetails?.document}`;
        const splitFiles = otherDetails?.document?.split('/');
        const fileName = splitFiles[splitFiles?.length - 1]
        setFile({ file: fileName, result: fileName });
      }
      setOtherdetails(otherDetails)
    }
    setLocationData(locationData?.data);
    setStates(State.getStatesOfCountry(locationData?.data?.countryCode));
    setCities(City.getCitiesOfState(locationData?.data?.countryCode, locationData?.data?.stateCode));
  }

  function handleChange(e: any) {
    let { name, value } = e.target;
    if (name === "country") {
      const countries = Country.getAllCountries();
      for (let i = 0; i < countries.length; i++) {
        if (countries[i].name === value) {
          setLocationData((prevState: any) => ({ ...prevState, countryCode: countries[i].isoCode, state: "", city: "" }));
          setStates(State.getStatesOfCountry(countries[i].isoCode));
        }
      }
    } else if (name === "state") {
      const states = State.getStatesOfCountry(locationData.countryCode)
      for (let i = 0; i < states.length; i++) {
        if (states[i].name === value) {
          setLocationData((prevState: any) => ({ ...prevState, stateCode: states[i].isoCode }));
          setCities(City.getCitiesOfState(locationData.countryCode, states[i].isoCode));
        }
      }
    } else if (name === "city") {
      const cities = City.getCitiesOfState(locationData.countryCode, locationData.stateCode);
      for (let i = 0; i < cities.length; i++) {
        if (cities[i].name === value) {
          setLocationData((prevState: any) => ({ ...prevState, latitude: cities[i].latitude, longitude: cities[i].longitude }));
        }
      }
    }

    if (name === "phoneNumber") {
      value = formatPhoneNumber(value)
    }

    setLocationData((prevState: any) => ({ ...prevState, [name]: value }));
  }

  function formatPhoneNumber(phoneNumberString: any) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }

  function handleOtherChange(e: any) {
    const { name, value } = e.target;
    setOtherdetails((prevState: any) => ({ ...prevState, [name]: value }));
  }

  function validate() {
    for (let key in locationData) {
      if (locationData[key] === "") {
        return false
      }
    }
    return true
  }

  async function addLocationData() {
    setLoading(true);
    const validationSuccess = await validate();
    if (validationSuccess) {
      const data = await addLocation(locationData, otherDetails, file, location?.state?.hospitalId);
      if (data.success) {
        navigate(`/${currentUser}/locations`);
        setTimeout(() => {
          Alert.setShowToast("Location added successfully", "success")
        }, 2000)
      }
    }
    else {
      alert("Please fill all the required fields")
    }
    setLoading(false);
  }

  async function updateLocationData() {
    setLoading(true);
    const validationSuccess = await validate();
    if (validationSuccess) {
      const data = await updateLocation(location.state?.id, locationData, otherDetails, file);
      if (data.success) {
        navigate(`/${currentUser}/locations`);
        setTimeout(() => {
          Alert.setShowToast("Location Updated successfully", "success");
        }, 3000)
      }
    }
    else {
      alert("Please fill all the required fields")
    }
    setLoading(false);
  }

  const handleFileUpload = (post: any) => {
    if (post.target.files?.length > 0) {
      for (let i = 0; i < post.target.files.length; i++) {
        let file = post.target.files[i];
        let reader = new FileReader();
        if (file) {
          const maxSize = 5 * 1024 * 1024;
          if (file.size > maxSize) {
            alert('Can not upload this file. Max Size is 5MB');
            post.target.value = '';
          } else {
            reader.readAsDataURL(file);
            reader.onload = () => {
              const result = { result: reader.result, fileName: file.name.split('.')[1], file: file.name };
              setFile(result)
              // setGetvalues((prevState: any) => ({
              //   ...prevState, ['Basic Information']: { ...prevState['Basic Information'], result }
              // }))
              // console.log(
              //   '🚀 ~ file: multiple-quote.js ~ line 92 ~ handleFileUpload ~ reader.result',
              //   reader.result,
              // );
            };
          }
        }
      }
    }
  };

  function handleMultiSelect(data: any, action: any) {
    const { name } = action;
    setOtherdetails((prevState: any) => ({ ...prevState, [name]: data }))
  }


  return (
    <Row>
      <div className="panel_top_section">
        <h1>{location.state?.id ? "Edit" : "Add New"} Location</h1>
      </div>
      <Col className="mt-3 mb-4">
        <div className="add_doctor_form card_box col-sm-12 col-xl-9 mx-auto py-4 px-lg-4 mt-4">
          <div className="add_doctor_fom px-4">
            <div className="row justify-content-around border-bottom mb-4 pb-4">
              <div className="col-lg-12 col-xl-4 d-flex align-items-center">
                <p className=" text-black-50 fw-medium">Location Name: <span className="required-color">*</span></p>
              </div>
              <div className="row col-lg-12 col-xl-8">
                <div className="col-sm-12 location_input">
                  <input
                    type="text"
                    className="form-control"
                    id="location"
                    value={locationData.locationName}
                    name="locationName"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            {/* Address */}
            <div className="row justify-content-around border-bottom mb-4 pb-4">
              <div className="col-lg-12 col-xl-4">
                <p className="text-black-50 fw-medium">Address</p>
              </div>
              <div className="row  col-lg-12 col-xl-8">
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="st_address" className="form-label">Street Address<span className="required-color">*</span></label>
                  <GooglePlacesAutocomplete
                    apiKey="AIzaSyBrwbVt6abHnoZjzNWYWhYhQLwL45mO2hA"
                    selectProps={{
                      value: place,
                      onChange: handlePlaceChange,
                      placeholder: locationData.streetAddress
                    }}
                  />
                  {/* <input
                    type="text"
                    className="form-control"
                    id="st_address"
                    value={locationData.streetAddress}
                    name="streetAddress"
                    onChange={handleChange}
                  /> */}
                </div>

                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="phone" className="form-label">Country<span className="required-color">*</span></label>
                  {/* <select
                    className="form-select"
                    aria-label="Default select example"
                    name="country"
                    value={locationData.country}
                    onChange={handleChange}
                  >
                    <option value="-1">Select</option>
                    {countries.map((country: ICountry) => {
                      return (
                        <option value={country.name}>{country.name}</option>
                      )
                    })}
                  </select> */}
                  <input value={locationData.country} disabled className="form-control" />
                </div>

                {/* {locationData.country && */}
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label className="form-label">State/Province<span className="required-color">*</span></label>
                  {/* <select
                    className="form-select"
                    aria-label="Default select example"
                    name="state"
                    value={locationData.state}
                    onChange={handleChange}
                  >
                    <option value="-1">Select</option>
                    {states.map((state: any) => {
                      return (
                        <option value={state.name}>{state.name}</option>
                      )
                    })}
                  </select> */}
                  <input value={locationData.state} disabled className="form-control" />
                </div>
                {/* } */}

                {/* {locationData.country && locationData.state && */}
                < div className="col-lg-12 col-xl-6 mb-3">
                  <label className="form-label">City<span className="required-color">*</span></label>
                  {/* <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={handleChange}
                    name="city"
                    value={locationData.city}
                    defaultValue={locationData.city}
                  >
                    <option value="-1">Select</option>
                    {cities.map((city: any) => {
                      return (
                        <option
                          value={city.name}
                        >{city.name}</option>
                      )
                    })}
                  </select> */}
                  <input value={locationData.city} className="form-control" />
                </div>
                {/* } */}

                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="zop_code" className="form-label">ZIP Code<span className="required-color">*</span></label>
                  <input
                    type="number"
                    className="form-control"
                    id="zop_code"
                    value={locationData.pincode}
                    name="pincode"
                    onChange={handleChange}
                  />
                </div>

              </div>
            </div>
            {/* Contact Information*/}
            <div className="row justify-content-around border-bottom mb-4 pb-4">
              <div className="col-lg-12 col-xl-4">
                <p className="text-black-50 fw-medium">Contact Information</p>
              </div>
              <div className="row  col-lg-12 col-xl-8">
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="phone" className="form-label">Phone Number<span className="required-color">*</span></label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    value={locationData.phoneNumber}
                    name="phoneNumber"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="email" className="form-label">Email Address<span className="required-color">*</span></label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    value={locationData.emailAddress}
                    name="emailAddress"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="fax" className="form-label">Fax Number<span className="required-color">*</span></label>
                  <input
                    type="text"
                    className="form-control"
                    id="fax"
                    value={locationData.faxNumber}
                    name="faxNumber"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            {/* Hospital / ClinicDetail*/}
            <div className="row justify-content-around border-bottom mb-4 pb-4">
              <div className="col-lg-12 col-xl-4">
                <p className="text-black-50 fw-medium">Hospital / Clinic Details</p>
              </div>
              <div className="row  col-lg-12 col-xl-8">
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="rooms" className="form-label">Rooms</label>
                  <input
                    type="text"
                    className="form-control"
                    id="rooms"
                    name="rooms"
                    value={otherDetails?.rooms}
                    onChange={handleOtherChange}
                  />
                </div>
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="beds" className="form-label">Number of Beds</label>
                  <input
                    type="text"
                    className="form-control"
                    id="beds"
                    name="numberOfBeds"
                    value={otherDetails?.numberOfBeds}
                    onChange={handleOtherChange}
                  />
                </div>
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="doctors_c" className="form-label">Doctors</label>
                  <input
                    type="text"
                    className="form-control"
                    id="doctors_c"
                    name="doctors"
                    value={otherDetails?.doctors}
                    onChange={handleOtherChange}
                  />
                </div>
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="nurses" className="form-label">Nurses</label>
                  <input
                    type="text"
                    className="form-control"
                    id="nurses"
                    name="nurses"
                    value={otherDetails?.nurses}
                    onChange={handleOtherChange}
                  />
                </div>
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="staffs" className="form-label">Other Staff</label>
                  <input
                    type="text"
                    className="form-control"
                    id="staffs"
                    name="staffs"
                    value={otherDetails?.staffs}
                    onChange={handleOtherChange}
                  />
                </div>
                <div className="col-lg-12 col-xl-6 mb-3">
                  <label htmlFor="status" className="form-label">Facilities and Amenities</label>
                  <Select
                    isMulti
                    name="facilities"
                    options={Facilities}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handleMultiSelect}
                    value={otherDetails?.facilities}
                  />
                  {/* <select
                    className="form-select"
                    aria-label="Default select example"
                    name="facilities"
                    value={otherDetails.facilities}
                    onChange={handleOtherChange}
                  >
                    <option value="-1">Select</option>
                    <option value="test1">test1</option>
                    <option value="test2">test2</option>
                  </select> */}
                </div>
              </div>
            </div>
            {/* Medical Services*/}
            <div className="row justify-content-around border-bottom mb-4 pb-4">
              <div className="col-lg-12 col-xl-4">
                <p className=" text-black-50 fw-medium">Medical Services</p>
              </div>
              <div className="row col-lg-12 col-xl-8">
                <div className="col-lg-12 col-xl-12 mb-3">
                  <label htmlFor="status" className="form-label">Medical Services Offered</label>
                  <Select
                    isMulti
                    name="medicalServices"
                    options={MedicalServices}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handleMultiSelect}
                    value={otherDetails?.medicalServices}
                  />
                  {/* <select
                    className="form-select"
                    aria-label="Default select example"
                    name="medicalServices"
                    value={otherDetails.medicalServices}
                    onChange={handleOtherChange}
                  >
                    <option value="-1">Select</option>
                    <option value="option1">option1</option>
                    <option value="option2">option2</option>
                  </select> */}
                </div>
                <div className="col-lg-12 col-xl-12 mb-3">
                  <label htmlFor="status" className="form-label">Specialized Procedures Available</label>
                  {/* <select
                    className="form-select"
                    aria-label="Default select example"
                    name="specializedProcedures"
                    value={otherDetails.specializedProcedures}
                    onChange={handleOtherChange}
                  >
                    <option value="-1">Select</option>
                    <option value="option1">option1</option>
                    <option value="option2">option2</option>
                  </select> */}
                  <Select
                    isMulti
                    name="specializedProcedures"
                    options={SpecializedProcedures}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handleMultiSelect}
                    value={otherDetails?.specializedProcedures}
                  />
                </div>
              </div>
            </div>
            {/* Technology and Equipment*/}
            <div className="row justify-content-around border-bottom mb-4 pb-4">
              <div className="col-lg-12 col-xl-4">
                <p className=" text-black-50 fw-medium">Technology and Equipment </p>
              </div>
              <div className="row col-lg-12 col-xl-8">
                <div className="col-lg-12 col-xl-12 mb-3">
                  <label htmlFor="status" className="form-label">Medical Equipment Available</label>
                  {/* <select
                    className="form-select"
                    aria-label="Default select example"
                    name="medicalEquipment"
                    value={otherDetails.medicalEquipment}
                    onChange={handleOtherChange}
                  >
                    <option selected>Select</option>
                    <option value="option1">option1</option>
                    <option value="option2">option2</option>
                  </select> */}
                  <Select
                    isMulti
                    name="medicalEquipment"
                    options={medicalEquipment}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handleMultiSelect}
                    value={otherDetails?.medicalEquipment}
                  />
                </div>
                <div className="col-lg-12 col-xl-12 mb-3">
                  <label htmlFor="status" className="form-label">Information Technology Infrastructure</label>
                  {/* <select
                    className="form-select"
                    aria-label="Default select example"
                    name="infrastructure"
                    value={otherDetails.infrastructure}
                    onChange={(e) => {
                      const value= e.target.value
                      console.log("value", value)
                    }}
                    multiple
                  >
                    <option value="-1">Select</option>
                    <option value="option1">option1</option>
                    <option value="option2">option2</option>
                  </select> */}
                  <Select
                    isMulti
                    name="infrastructure"
                    options={Infrastructure}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handleMultiSelect}
                    value={otherDetails?.infrastructure}
                  />
                </div>

              </div>
            </div>
            {/* Document Uploads*/}
            <div className="row justify-content-around border-bottom mb-4 pb-4">
              <div className="col-lg-12 col-xl-4">
                <p className=" text-black-50 fw-medium">Document Uploads</p>
              </div>
              <div className="row col-lg-12 col-xl-8">
                <div className="col-sm-12">
                  <input
                    type="file"
                    id="upload"
                    hidden
                    onChange={handleFileUpload}
                  // multiple
                  />
                  <label className="upload_btn" htmlFor="upload"><span className="up_arrow"><i className="bi bi-cloud-arrow-up-fill"></i></span>Upload</label>
                </div>
                <div className="col-xl-12 mt-3">
                  {file != null &&
                    <div className="border w-100 rounded float-start p-0 d-flex align-items-center">
                      <span className="fs-4 d-inline-block py-2 px-3  border-end"><i className="bi bi-file-earmark-text-fill"></i></span>
                      <span className="px-2 text_gray py-2 px-3 text-truncate ">{file.file}</span>
                      <button
                        className=" px-3 fs-4 py-2 border-0 bg-transparent border-start"
                        onClick={() => handleDownload()}
                      >
                        <i className="bi bi-box-arrow-down"></i>
                      </button>
                      <button
                        className=" px-3 fs-4 py-2 border-0 bg-transparent border-start"
                        onClick={() => handleDeleteDocument()}
                      >
                        <i className="bi bi-x-circle"></i>
                      </button>
                    </div>
                  }
                </div>
              </div>

            </div>
            {/* canceland-save*/}
            <div className="row  mb-4 pb-4">
              <div className="col-lg-12 col-xl-4">

              </div>
              <div className="form_bottom_btn d-flex justify-content-end">
                <button
                  className="delete_btn"
                  type="button"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </button>
                <button className="btn_gradian" type="button" onClick={() => {
                  if (location.state?.id) {
                    updateLocationData();
                  } else {
                    addLocationData()
                  }
                }}>
                  Save
                  <span className="ms-2">
                    {loading &&
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    }
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row >
  );
};

export default AddLocation;