import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { RoleContext, AlertContext } from "../../../utils/contexts/RoleContext";
import Pagination from "../../../components/Pagination";
import Spinner from 'react-bootstrap/Spinner';
import { getalldoctors } from "../../../services/form-service";
import { decidePages } from "../../../utils/utils";
import CustomToolTip from "../../../components/CustomToolTip";
import UsersModal from "../../../components/UsersModal";
import { getHospitalUsers } from "../../../services/user.service";
import { linkDoctors } from "../../../services/patient-service";

export default function QuestionnaireDisplay() {
    const [pages, setPages] = useState<any>([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [more, setMore] = useState();
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [doctors, setDoctors] = useState([]);
    const [doctorsLinked, setDoctorsLinked] = useState([]);
    const [formId, setFormId] = useState(0);

    const navigate = useNavigate();
    const currentUser = useContext(RoleContext);
    const toast: any = useContext(AlertContext)

    useEffect(() => {
        getQuestionnaires();
        // fetchDoctors();
    }, []);

    async function fetchDoctors(hospitalId: number) {
        const response = await getHospitalUsers(3, hospitalId);
        if (response?.success) {
            setDoctors(response?.data)
        }
    }

    const getQuestionnaires = (page?: any) => {
        const pageCount = page != undefined ? page : 1;
        setLoading(true)
        getalldoctors(pageCount, "questionnaire").then(
            (data) => {
                setRowData(data?.allDoctors?.rows);
                const pages = decidePages(data?.allDoctors?.count);
                setPages(pages);
                setPageNumber(pageCount);
                setLoading(false)
            },
            (error) => {
                const resMessage =
                    (error?.response &&
                        error?.response?.data &&
                        error?.response?.data?.message) ||
                    error?.message ||
                    error?.toString();

                setLoading(false);
            }
        );
    }

    function handleLink(data: any) {
        fetchDoctors(data.hospitalId)
        setShowPopup(true);
        setFormId(data.formId);
        if (data?.doctorsLinked) {
            setDoctorsLinked(JSON.parse(data?.doctorsLinked));
        } else {
            setDoctorsLinked([])
        }
    }

    function handleChange(data: any) {
        const linkedDoctors: any = [...doctorsLinked]
        let exist = linkedDoctors.find((item: any) => item.id == data.id);
        if (exist) {
            setDoctorsLinked(doctorsLinked.filter((filteredItem: any) => filteredItem.id != data.id))
        } else {
            linkedDoctors.push({ id: data.id, name: data.name })
            setDoctorsLinked(linkedDoctors)
        }
    }

    async function handleSubmit() {
        setShowPopup(false);
        const response = await linkDoctors(doctorsLinked, formId)
        if (response?.success) {
            getQuestionnaires();
            toast.setShowToast(response.data, "success")
        } else {
            toast.setShowToast("Something went wrong. Please try again later!", "danger")
        }
    }

    function handleClick() {
        if(currentUser === "admin") {
            navigate(`/${currentUser}/hospital`, { state: { what: "questionnaire" } });
        } else {
            navigate(`/${currentUser}/questionnaire`)
        }
    }


    return (
        <div>
            <div style={{ justifyContent: "flex-end", display: "flex", marginBottom: 10 }}>
                <button className='btn_gradian' onClick={handleClick}>Add New Questionnaire</button>
            </div>
            <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                <div className="row d-flex ">
                    <table>
                        <thead>
                            <tr className="list_head">
                                <th>Form Name</th>
                                {currentUser === "admin" &&
                                    <th>Hospital</th>
                                }
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!loading ?
                                <>
                                    {rowData?.length > 0 ?
                                        <>
                                            {rowData.map((item: any, index: number) => {
                                                return (
                                                    <tr className="list_detail" key={index}>
                                                        <td>{item.formName}</td>
                                                        {currentUser === "admin" &&
                                                            <td>{item.user?.firstName + " " + item.user?.lastName}</td>
                                                        }
                                                        <td>
                                                            {more !== item.formId ?
                                                                <span className="dost_btn" onClick={() => setMore(item.formId)}>
                                                                    <i className="bi bi-three-dots-vertical"></i>
                                                                </span>
                                                                :
                                                                <span className="action" >
                                                                    <CustomToolTip title="Link doctors">
                                                                        <button
                                                                            className="edit_btn"
                                                                            onClick={() => handleLink(item)}
                                                                        // onClick={() => navigate(`/${currentUser}/drag`, { state: { key: item.formId } })}
                                                                        >
                                                                            <i className="bi bi-link-45deg" />
                                                                        </button>
                                                                    </CustomToolTip>
                                                                    {/* <button
                                                                        className="delete_btn_act"
                                                                        onClick={() => {
                                                                            // setShowDeleteModal(true);
                                                                            // setDeleteId(item.formId);
                                                                        }}
                                                                    >
                                                                        <i className="bi bi-trash"></i>
                                                                    </button> */}
                                                                </span>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </>
                                        :
                                        <tr className="list_detail">
                                            <td colSpan={10}>No data found</td>
                                        </tr>
                                    }
                                </>
                                :
                                <tr className="list_detail">
                                    <td colSpan={10}><Spinner /></td>
                                </tr>
                            }
                        </tbody>
                        {pages?.length > 1 &&
                            <tfoot>
                                <Pagination
                                    pages={pages}
                                    currentPage={pageNumber}
                                    onClickPageNumber={(page: any) => {
                                        setPageNumber(page);
                                        getQuestionnaires(page)
                                    }}
                                />
                            </tfoot>
                        }
                    </table>
                </div>
            </div>
            <UsersModal
                show={showPopup}
                handleClose={() => setShowPopup(false)}
                data={doctors}
                handleClick={handleSubmit}
                chatUsers={false}
                doctorsLinked={doctorsLinked}
                handleChange={handleChange}
            />
            {/* <DeleteModal
                show={showDeleteModal}
                handleYes={() => {
                    DeleteAllForms(deleteId)
                    setShowDeleteModal(false)
                }}
                handleClose={() => setShowDeleteModal(false)}
                body="Do you really want to delete this form?"
                head="Delete Form"
            /> */}
        </div >
    )
}