import * as AuthService from "../../services/auth.service";
import * as Yup from "yup";

import { ErrorMessage, Field, Form, Formik } from "formik";
import { NavigateFunction, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useState } from "react";

import OtpInput from 'react-otp-input';
import { RoleContext } from "../../utils/contexts/RoleContext";
import axios from "axios";
import { getCurrentUser } from "../../services/auth.service";
import { login, sendOTP } from "../../services/auth.service";
// import { logo } from "../config/constants";
// import { shield } from "../config/constants";
// import { shield2 } from "../config/constants";
import logo from "../../images/signup/logo-image.webp";
import shield from "../../images/signup/signup-shieldimage.webp";
import shield2 from "../../images/signup/signup-shieldimage2.webp";
import { RoleUpdateContext, AlertContext } from "../../utils/contexts/RoleContext";
import { getMessaging, getToken } from "firebase/messaging";
import { messaging } from "../../firebase";
import { updatePushToken } from "../../services/notification-service";
import { socket } from "../../index";
import { Spinner } from "reactstrap";
import { metaTitle } from "../../config/constants";
import { capitalizeFirstLetter } from "../../utils/utils";

type Props = {};

const Login: React.FC<Props> = () => {
  let navigate: NavigateFunction = useNavigate();
  // const currentUser = useContext(RoleContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [otpCount, setOtpCount] = useState(0)
  const [otpMethod, setOtpMethod] = useState("password")
  const [otp, setOtp] = useState("");
  const [Email, setEmail] = useState<any>('');
  const [checkOtp, setCheckOtp] = useState<any>('');
  const [buttonDisable, setButtonDisable] = useState(false)
  const [passwordErr, setPasswordErr] = useState("")
  const [emailErr, setEmailErr] = useState("")
  const [tab, setTab] = useState(0);
  const [password, setPasword] = useState("")
  const [err, setErr] = useState("")
  const [otpErr, setOtpErr] = useState("")
  const [role, setRole] = useState<any>("");
  const [currentUser, setCurrentUser] = useState("");
  const [cmsurl, setcmsurl] = useState<any>("");
  const [remember, setRemember] = useState(false);
  const [code, setCode] = useState<any>("");
  const [showPassword, setShowPassword] = useState(false);
  const [disableOTP, setDisableOTP] = useState(false);

  const roleUpdate: any = useContext(RoleUpdateContext);
  const toast: any = useContext(AlertContext)

  const initialValues: {
    email: string;
    password: string;
    otp: string;
  } = {
    email: "",
    password: "",
    otp: ""
  };

  useEffect(() => {
    const rememberEmail: any = localStorage.getItem("rememberEmail");
    setEmail(JSON.parse(rememberEmail))
    setOtp("");
    const settingvalue: any = localStorage.getItem("role");
    const value = AuthService?.getCurrentUser();
    setCurrentUser(value?.role)

    setRole(JSON.parse(settingvalue))

    const url = window.location.pathname;

    const cmsUrl = url?.split("/")[1];
    // const cmsUrls = url.split("/");
    // localStorage.setItem("role", JSON.stringify(cmsUrls[1]))

    setcmsurl(cmsUrl);
    // userNavigationDecider();
    document.title = `${metaTitle}${capitalizeFirstLetter(cmsUrl)} - Login`;
  }, []);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("This field is required!"),
    password: otpMethod === "password" ? Yup.string().required("This field is required!") : Yup.string().notRequired(),
    otp: otpMethod === "otp" ? Yup.string().required("This field is required!") : Yup.string().notRequired(),
  });

  async function updateFCMToken(token: string) {
    const response = await updatePushToken(token);
  }

  const handleLogin = () => {
    // const { email, otp } = formValue;
    // setEmail(email);

    setMessage("");
    setLoading(true);
    //   if(checkOtp!=otps && otpMethod==="otp"){
    //     setErr("incorrect otp")
    // }
    // else if(otps===""){
    //   setErr("please enter otp")
    // }
    // else{

    // if (otps === "") {
    //   setErr("please enter otp")
    // }

    login(Email, password, otp, cmsurl).then(
      (data) => {
        setLoading(false);
        if (data.message) {
          setErr(data.message);
          setButtonDisable(data?.buttondisable);
        } else {
          getCurrentToken();
          socket.emit("user_status", {
            status: "IDLE",
            id: data.id
          });
          if (remember) {
            localStorage.setItem("rememberEmail", JSON.stringify(Email));
          }
          // const lastVisitedURL = localStorage.getItem("lastVisitedURL")
          // if (lastVisitedURL) {

          //   window.location.href = JSON.parse(lastVisitedURL);
          // } else {
          //   // navigate(`/${data?.role}/dashboard`); 
          //   if (data?.role === 'staff') {
          //     navigate(`/${data?.role}/staff-dashboard`);
          //     // window.location.reload();
          //   } else if (data?.role === 'patient') {
          //     navigate(`/${data?.role}/appointment`);
          //   } else {
          //     navigate(`/${data?.role}/dashboard`);
          //     // window.location.reload();
          //   }
          //   roleUpdate(data.role)
          // }
          const redirectPath = localStorage.getItem("redirectPath")
          if (redirectPath) {
            const path = redirectPath
            navigate(path)
            window.location.href = path;
            // console.log("JSON.parse(redirectPath)",path)
          } else {
            // navigate(`/${data?.role}/dashboard`); 
            if (data?.role === 'staff') {
              navigate(`/${data?.role}/staff-dashboard`);
              // window.location.reload();
            } else if (data?.role === 'patient') {
              navigate(`/${data?.role}/appointment`);
            } else {
              navigate(`/${data?.role}/dashboard`);
              // window.location.reload();
            }
            roleUpdate(data.role)
          }
          localStorage.setItem("user", JSON.stringify(data));
          localStorage.removeItem("redirectPath")
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
      }
    );
    // }
  };

  function getCurrentToken() {
    getToken(messaging, { vapidKey: "BNYXlpc5WtTZPeyLFevo7H2ED4jCYnLIdleWZMnouxg91fIeE8M6zFi9xwbEv5XxcYpV485Ps2Cil7Nr8G46mjI" })
      .then((currentToken) => {
        if (currentToken) {
          updateFCMToken(currentToken)
          // setPushToken(currentToken)
          // Send the token to your server and update the UI if necessary
          // ...
        } else {
          // Show permission request UI
          console.log(
            "No registration token available. Request permission to generate one."
          );
          // ...
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
        // ...
      });
  }

  function requestPermission() {
    console.log("Requesting permission...");
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("Notification permission granted.");
      }
    });
  }

  // if (retryLoadToken.current >= 3) {
  //   alert("unable to load token, refresh the browser");
  //   console.info(
  //     `%cPush Notifications issue - unable to load token after 3 retry`,
  //     "color: green; background: #c7c7c7; padding: 8px; font-size: 20px"
  //   );
  // }



  const validation = (e: any) => {
    setEmailErr("");
    setPasswordErr("")
    e.preventDefault();
    if (otpMethod === "otp") {
      if (Email != "" && !isNaN(Email) && otp != "") {
        handleLogin();
      } else {
        if (Email != "" && !isNaN(Email)) {
          setEmailErr("please enter valid Phone number")
        }
        if (otp == "") {
          setOtpErr("please enter otp")
        }
      }
    } else {
      if (Email != "" && password != "") {
        handleLogin();
      } else {
        if (password === "") {
          setPasswordErr("please enter password")
        }
        if (Email === "") {
          setEmailErr("please enter valid Email")
        }
      }
    }
    // if (Email != "" && (otpMethod === "otp" ? (password == "" && otp != "") : (password != "" && otp === ""))) {
    //   handleLogin();
    // }
    // else {
    //   if (otpMethod === "otp" && otp === "") {
    //     setOtpErr("please enter otp")
    //   }
    //   if (password === "") {
    //     setPasswordErr("please enter password")
    //   }
    //   if (otpMethod === "password" && password === "") {
    //     setPasswordErr("please enter password")
    //   }

    //   if (Email === "") {
    //     setEmailErr("please enter email or Phone number")
    //   }
    //   // setErr("please fill all the fields")


    // }
  }


  const mainSender = (e: any) => {
    e.preventDefault();
    setOtpCount(otpCount + 1)
    if (otpCount === 2) {
      setOtpMethod("password")
    }
    return axios
      .post("http://localhost:5000/api/auth/" + "mail", {
        Email,

      })
      .then((data) => {

        setCheckOtp(data.data?.otp)

        // return response.data;
      });


  }

  // return (
  //   <div className="col-md-12">


  //     <div className="card card-container">
  //       <img
  //         src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
  //         alt="profile-img"
  //         className="profile-img-card"
  //       />
  //       <p style={{ alignSelf: "center" }}>LogIn</p>
  //       <Formik
  //         initialValues={initialValues}
  //         validationSchema={validationSchema}
  //         onSubmit={handleLogin}
  //       >
  //         <Form>
  //           <div className="form-group">
  //             <label htmlFor="email">email or phone number</label>
  //             <Field name="email" type="text" className="form-control" />
  //             <ErrorMessage
  //               name="email"
  //               component="div"
  //               className="alert alert-danger"
  //             />
  //           </div>
  //           <div style={{ flexDirection: "row", textAlign: "center", display: "flex" }}>
  //             <p onClick={() => setOtpMethod("password")} >password Login ?</p>
  //             <p style={{ marginLeft: 20 }}>(or)</p>
  //             <p style={{ marginLeft: 20 }} onClick={() => otpCount === 3 ? null : setOtpMethod("otp")} color={otpCount === 3 ? "grey" : "glack"}>otp Login ?</p>
  //           </div>
  //           {otpMethod === 'password' ?
  //             <div className="form-group">
  //               <label htmlFor="password">Password</label>
  //               <Field name="password" type="password" className="form-control" />
  //               <ErrorMessage
  //                 name="password"
  //                 component="div"
  //                 className="alert alert-danger"
  //               />
  //             </div> : <>
  //               {otpCount === 3 ?
  //                 <div className="form-group">
  //                   <label htmlFor="password">Password</label>
  //                   <Field name="password" type="password" className="form-control" />
  //                   <ErrorMessage
  //                     name="password"
  //                     component="div"
  //                     className="alert alert-danger"
  //                   />
  //                 </div>
  //                 :
  //                 <>
  //                   <button onClick={() => mainSender()} className="btn btn-primary" disabled={otpCount === 3 ? true : false} style={{ marginTop: 5 }}>Send OTP</button>
  //                   {/* {err? <p>please enter otp</p>:null} */}
  //                   <div className="form-group">
  //                     <label htmlFor="otp">otp</label>
  //                     {/* <Field name="otp" type="string" className="form-control" /> */}
  //                      <OtpInput
  //                       value={otps}
  //                       onChange={(e) => setOtp(e)}
  //                       numInputs={4}
  //                       shouldAutoFocus={true}
  //                       renderSeparator={<span>-</span>}
  //                       renderInput={(props) => <input disabled={otpCount === 3 ? true : false}   {...props}
  //                       />}
  //                       containerStyle={{ width: "100%" }}
  //                       inputStyle={{ width: "50%" }}
  //                     />
  //                     <ErrorMessage
  //                       name="otp"
  //                       component="div"
  //                       className="alert alert-danger"
  //                     />
  //                   </div>
  //                   {/* <div className="form-group">

  //                   <label htmlFor="otp">otp</label>
  //                     <Field name="otp" type="number" className="form-control" />
  //                     {/* <input type="number" className="form-control" value={password} onChange={(e) => setPasword(e.target.value)} /> */}
  //                   {/* <OtpInput
  //                       value={otps}
  //                       onChange={(e) => setOtp(e)}
  //                       numInputs={4}
  //                       shouldAutoFocus={true}
  //                       renderSeparator={<span>-</span>}
  //                       renderInput={(props) => <input disabled={otpCount === 3 ? true : false}   {...props}
  //                       />}
  //                       containerStyle={{ width: "100%" }}
  //                       inputStyle={{ width: "50%" }}
  //                     /> */}

  //                   {/* <ErrorMessage
  //                     name="otp"
  //                     component="div"
  //                     className="alert alert-danger"
  //                   />
  //                 </div> */} 
  //             </>
  //           }
  //         </>}

  //         <div className="form-group">
  //           <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
  //             {loading && (
  //               <span className="spinner-border spinner-border-sm"></span>
  //             )}
  //             <span>Login</span>
  //           </button>
  //         </div>

  //         {message && (
  //           <div className="form-group">
  //             <div className="alert alert-danger" role="alert">
  //               {message}
  //             </div>
  //           </div>
  //         )}
  //       </Form>
  //     </Formik>
  //   </div>
  //   </div >
  // );

  async function handleSendOTP() {
    setLoading(true);
    if (Email != "" && !isNaN(Email)) {
      const response = await sendOTP(Email, 91, cmsurl);
      setLoading(false);
      if (response?.success) {
        setDisableOTP(false);
        // toast.setShowToast("Please enter OTP and Click Login", "success")
        alert("Please enter OTP and Click Login")
      } else {
        setEmailErr("No user found with this phone number")
        // alert("Please enter OTP and Click Login")
        // toast.setShowToast("User not found", "success")
      }
    } else {
      setEmailErr("please enter valid Phone number")
    }
  }

  return (
    <div className="signuppage_wrapper">

      <div className="container">
        {role == "admin" ?
          <div className="col-sm-12 signuppage_logo text-center mb-4 pb-4">
            <a href="#">
              <img src={logo} alt="logo-image" />
            </a>
          </div>
          :
          <div className="col-sm-12 signuppage_logo mb-4 pb-4 ms-5">
            <a href="#">
              <img src={logo} alt="logo-image" />
            </a>
          </div>
        }

        <div className="col-sm-12 signuppage_section login_page">
          {role !== "admin" ?
            <div className="col-sm-5 signuppage_left_part">
              {/* <div className="signuppage_logo">
                <a href="#">
                  <img src={logo} alt="logo-image" />
                </a>
              </div> */}
              <h1>Health Insights at Your Fingertips!</h1>
              <div className="signuppage_left_content">
                <div className="signuppage_left_content_image">
                  <img src={shield} alt="shield-image" />
                </div>
                <div className="signuppage_left_box">
                  <h4>Fast & Efficient</h4>
                  <p>
                    Revolutionize your healthcare experience with our Fast &
                    Efficient dashboard – where speed meets precision and every
                    click counts!
                  </p>
                </div>
              </div>
              <div className="signuppage_left_content">
                <div className="signuppage_left_content_image">
                  <img src={shield2} alt="shield-image" />
                </div>
                <div className="signuppage_left_box">
                  <h4>Real-Time Data Access</h4>
                  <p>
                    Stay ahead of the curve, make informed choices, and transform
                    healthcare delivery with up-to-the-minute insights at your
                    fingertips!
                  </p>
                </div>
              </div>
            </div> : null}
          <div className="col-sm-5 signuppage_right_part">
            {role != "admin" &&
              <ul>
                <li
                  onClick={() => {
                    setTab(0);
                  }}
                  className={tab === 0 ? "active clickable" : "clickable"}
                >
                  <a >LOGIN</a>
                </li>
                <li
                  onClick={() => {
                    setTab(1);
                  }}
                  className={tab === 1 ? "active clickable" : "clickable"}
                >
                  <a>Join a Telehealth</a>
                </li>
              </ul>}
            {tab === 0 ? (
              <form onSubmit={validation}>

                <div className="loginform_sec">
                  <h4>Welcome Back,</h4>
                  <h2>Log In!</h2>
                  <form>
                    <div className="col-sm-12 form-group email">
                      {role != "admin" ?
                        <>
                          <span>E-mail address (or) Mobile Number</span>
                          <span style={{ color: "red" }}>*</span>
                        </>
                        :
                        <>
                          <span>E-mail address</span>
                          <span style={{ color: "red" }}>*</span>
                        </>
                      }

                      <input
                        type="text"
                        className="form-control mt-2"
                        name="phone"
                        placeholder={role != "admin" ? "E-mail adress (or) mobile number" : "E-mail adress "}
                        value={Email}
                        onChange={(e) => { setEmailErr(""); setEmail(e.target.value) }}
                      />
                      {
                        emailErr ?
                          <div >
                            <p style={{ color: "red" }}>{emailErr}</p>
                          </div>
                          :
                          ""
                      }
                    </div>
                    {/* {role != "admin" &&
                      <div style={{ flexDirection: "row", textAlign: "center", display: "flex" }}>
                        <p onClick={() => setOtpMethod("password")} >password Login ?</p>
                        <p style={{ marginLeft: 20 }}>(or)</p>
                        <p style={{ marginLeft: 20 }} onClick={() => otpCount === 3 ? null : setOtpMethod("otp")} color={otpCount === 3 ? "grey" : "glack"}>Otp Login ?</p>
                      </div>} */}
                    {/* {otpMethod === 'password' ? */}
                    <div className="col-sm-12 form-group mt-2">
                      <div className="d-flex justify-content-between">
                        <div>
                          <span>{otpMethod === "password" ? "Password" : "OTP"}</span>
                          <span style={{ color: "red" }}>*</span>
                        </div>
                        {role !== "admin" &&
                          <div
                            className="align-items-center d-flex clickable"
                            onClick={() => {
                              if (otpMethod === "otp") {
                                setOtpMethod("password")
                                setDisableOTP(false)
                              } else {
                                setDisableOTP(true)
                                setOtpMethod("otp")
                              }
                            }}
                          >
                            <span style={{
                              fontSize: "11px",
                              borderBottomColor: "#00929f",
                              borderBottomWidth: "1px",
                              borderBottomStyle: "solid"
                            }}
                              className="textgreen"
                            >
                              {otpMethod === "otp" ? "Switch Password" : "Switch OTP"}

                            </span>
                            <span className="ms-2 textgreen">
                              <i className="bi bi-arrow-right"></i>
                            </span>
                          </div>
                        }
                      </div>
                      {otpMethod === "password" ?
                        <div className="position-relative  otp-animation">
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control mt-2"
                            name="lname"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => {
                              setPasswordErr("");
                              setPasword(e.target.value)
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter")
                                validation(e);
                            }}
                          />
                          <div
                            className="position-absolute clickable"
                            style={{
                              right: "20px",
                              top: '10px'
                            }}
                          >
                            <i
                              className={`bi ${showPassword ? "bi-eye" : "bi-eye-slash"} fs-5 text-secondary`}
                              onClick={() => setShowPassword(!showPassword)}
                            />
                          </div>
                        </div>
                        :
                        <div className="mt-3  otp-animation">
                          <OtpInput
                            value={otp}
                            onChange={(e) => setOtp(e)}
                            numInputs={6}
                            // shouldAutoFocus={true}
                            // renderInput={(props) => <input disabled={otpCount === 3 ? true : false}   {...props}
                            // />}
                            renderInput={(props) => <input disabled={disableOTP}   {...props}
                            />}
                            containerStyle={{ display: 'flex', justifyContent: "space-around" }}
                            inputStyle={{ width: "15%", height: "60px", borderRadius: "10px", border: "1px solid #00929f", opacity: disableOTP ? 0.5 : 1 }}
                          />
                          <div className="mt-3">
                            <span className="resend-otp">
                              Don't get the OTP?
                            </span>
                            <span
                              className="ms-2 textgreen resend-otp"
                              style={{
                                borderBottomColor: "#00929f",
                                borderBottomWidth: "1px",
                                borderBottomStyle: "solid"
                              }}
                              onClick={handleSendOTP}
                            >
                              Resend
                            </span>
                          </div>
                        </div>
                      }
                      {
                        passwordErr ?
                          <div >
                            <p style={{ color: "red" }}>{passwordErr}</p>

                          </div> : ""
                      }

                    </div>
                    {/* :
                      <>
                        {otpCount === 3 ?
                          <div className="col-sm-12 form-group">
                            <span>Password</span>
                            <span style={{ color: "red" }}>*</span>
                            <input
                              type="password"
                              className="form-control"
                              name="lname"
                              placeholder="Password"
                              value={password}
                              onChange={(e) => setPasword(e.target.value)}
                            />
                            {
                              passwordErr ?
                                <div >
                                  <p style={{ color: "red" }}>{passwordErr}</p>

                                </div> : ""
                            }

                          </div>
                          :
                          <>
                            <button onClick={mainSender} className="btn btn-primary" disabled={otpCount === 3 ? true : false} style={{ marginTop: 5 }} >Send OTP</button>
                            <div className="form-group">

                              <span className="text-muted">Otp</span>
                              <span style={{ color: "red" }}>*</span>
                              <OtpInput
                                value={otp}
                                onChange={(e) => setOtp(e)}
                                numInputs={4}
                                shouldAutoFocus={true}
                                renderSeparator={<span>-</span>}
                                renderInput={(props) => <input disabled={otpCount === 3 ? true : false}   {...props}
                                />}
                                containerStyle={{ width: "150%" }}
                                inputStyle={{ width: "15%" }}
                              />
                              {
                                otpErr ?
                                  <div >
                                    <p style={{ color: "red" }}>{otpErr}</p>

                                  </div> : ""
                              }
                            </div>
                          </>
                        }
                      </>
                    } */}
                    <div className="col-sm-12 forgetpass_sec">
                      <div className="round">
                        <input
                          type="checkbox"
                          checked={remember}
                          id="checkbox"
                          onChange={() => setRemember(!remember)}
                        />
                        <label htmlFor="checkbox"></label>
                        <span>Remember me</span>
                      </div>
                      <a onClick={() => navigate(`/${cmsurl}/forgotpassword`)} className="textgreen clickable">Forget Password?</a>
                    </div>
                    <div className="col-sm-12 signup_btn">
                      <button type="button" id="form_cancel">
                        Cancel
                      </button>
                      {disableOTP ?
                        <button type="button" id="form_signup" onClick={handleSendOTP}>
                          Send OTP
                          <span className="ms-2">
                            {loading &&
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            }
                          </span>
                        </button>
                        :
                        <button type="button" id="form_signup" onClick={validation}>
                          Login
                          <span className="ms-2">
                            {loading &&
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            }
                          </span>
                        </button>
                      }


                    </div>
                    {
                      err ?
                        <div >
                          <p style={{ color: "red" }}>{err}</p>

                        </div> : ""
                    }
                  </form>
                </div>
              </form>
            ) : (
              <div className="telehealthform_sec">
                <h2>Connect Anytime, Anywhere!</h2>
                <input
                  type="text"
                  className="form-control"
                  name="lname"
                  placeholder="Code"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
                <p style={{ marginTop: 10 }}>
                  Please enter the code given to you by your provider to start
                  the visit.
                </p>
                <button type="button" id="form_signup" onClick={() => navigate(`/${cmsurl}/tele-detail/${code}`)} className="mt-3">
                  Next
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="col-sm-12 termsprivacy_section">
          <p>
            By login you agree to our <a href="#">Terms of Service</a> and{" "}
            <a href="#">Privacy Policy.</a>
          </p>
        </div>
      </div >
    </div >
  );
};

export default Login;
