import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

import React, { useContext, useEffect, useRef, useState } from "react";

import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import Modal from "react-bootstrap/Modal";
import { RoleContext } from "../../../utils/contexts/RoleContext";
import { getAllEmailTemplate } from "../../../services/emailtemplates-service";
import { useNavigate } from "react-router-dom";

// Row Data Interface
interface IRow {
  subjecttitle: string;
  key: string;
  Action: boolean;
  Content: string;
}

const check = "<h1>Hi there</h1>";

// Create new GridExample component
const EmailTemplates = () => {
  const navigate = useNavigate();
  const currentUser = useContext(RoleContext);
  // Row Data: The data to be displayed.
  const [rowData, setRowData] = useState<IRow[]>([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [deleteId, setDeleteId] = useState<string>("");

  const [preview, setPreview] = useState<any>();
  const parentRef = useRef();

  // Column Definitions: Defines & controls grid columns.
  const [colDefs] = useState<ColDef[]>([
    { field: "SubjectTitle" },
    { field: "Key" },
    { field: "Action", cellRenderer: totalValueRenderer },
    { field: "PreView", cellRenderer: previewValueRenderer },
  ]);

  // Fetch data & update rowData state
  useEffect(() => {
    getAllEmailTemplate().then(
      (response) => {
        const emailTemplateData = response.result;
        const arr = [];

        for (let i = 0; i < emailTemplateData.length; i++) {
          let obj: any = {};

          obj.SubjectTitle = emailTemplateData[i].subject_title;
          obj.Key = emailTemplateData[i].key;
          obj.Id = emailTemplateData[i].id;
          obj.Content = emailTemplateData[i].content;

          //   obj.RoleId=patientData[i].roleId

          // setFieldId(workingHoursData[i].id)
          arr.push(obj);
        }
        setRowData(arr);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }, []);

  function totalValueRenderer(props: any) {
    // setFieldId(props.data.Id)

    return (
      <span>
        <button
          className="btn btn-primary"
          // onClick={() => navigate('/edit')}
          onClick={() =>
            navigate(`/${currentUser}/edit_email_templates`, { state: { key: props.data.Id } })
          }
        >
          Edit
        </button>
      </span>
    );
  }

  function previewValueRenderer(props: any) {
    // setFieldId(props.data.Id)

    return (
      <span>
        <button
          className="btn btn-primary"
          // onClick={() => navigate('/edit')}
          onClick={() => {
            setPreview(props.data.Content);
            handleShow();
          }}
        >
          PreView
        </button>
      </span>
    );
  }


  // Container: Defines the grid's theme & dimensions.
  return (
    <div className="email_template_grid">
      <div
        className="ag-theme-quartz"
        style={{ height: 500, marginTop: 30, marginLeft: 50 }}
      >
        {/* <div style={{ fontWeight: "bold", fontSize: 23, marginBottom: 40 }}>Manage Patient</div> */}
        <div>
          <button
            className="btn btn-primary"
            onClick={() => navigate(`/${currentUser}/create_email_templates`)}
          >
            Create Email Templates
          </button>
        </div>
        {/* <div>
    <button className='btn btn-primary'   onClick={()=>navigate('/')}>
            Cancel
          </button>
    </div> */}
        {/* The AG Grid component */}
        <div className="ag-header-container">
          {/* <div style={containerStyle}>
      <div
        style={gridStyle}
        className={
          "ag-theme-quartz"
        }
      > */}

          <AgGridReact
            rowData={rowData}
            columnDefs={colDefs}
            sideBar={"filters"}
            // defaultColDef={defaultColDef}
            //  onGridReady={onGridReady}
          />
          {/* </div> */}
          {/* </div> */}
        </div>
        <Modal show={show} onHide={() => handleClose()}>
          <Modal.Header>
            <Modal.Title>PreView Model</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div dangerouslySetInnerHTML={{ __html: preview }}></div>
          </Modal.Body>
          {/* {rowData.map((item) => {
            return (
                <Modal.Body>
                  <div dangerouslySetInnerHTML={{ __html: preview }}></div>
                </Modal.Body>
            );
          })} */}

          <Modal.Footer>
            <button onClick={() => handleClose()}>Cancel</button>
            {/* <button 
          
          onClick={()=>deletePatientData(deleteId)}
          >
            Delete
          </button> */}
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};
export default EmailTemplates;