import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

import React, { useContext, useEffect, useRef, useState } from "react";
import { deleteEmailSettingData, getAllEmailSetting } from "../../../../services/emailsetting-service";

import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import Modal from "react-bootstrap/Modal";
import { RoleContext } from "../../../../utils/contexts/RoleContext";
import { useNavigate } from "react-router-dom";

// Row Data Interface
interface IRow {
    fromemail: string,
    toemail: string,
    authuser: string,
    authpassword: string,
    port:any,
    secure: any,
}


// Create new GridExample component
const EmailSettings = () => {
  const navigate = useNavigate();
  const currentUser = useContext(RoleContext);
  // Row Data: The data to be displayed.
  const [rowData, setRowData] = useState<IRow[]>([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [deleteId, setDeleteId] = useState<string>("");


  // Column Definitions: Defines & controls grid columns.
  const [colDefs] = useState<ColDef[]>([
    { field: "FromEmail" },
    { field: "ToEmail" },
    { field: "AuthUser" },
    { field: "AuthPassword" },
    { field: "Port" },
    { field: "Field Update", cellRenderer: totalValueRenderer },
    { field: "Field Delete", cellRenderer: deleteForm },
  ]);

  // Fetch data & update rowData state
  useEffect(() => {
    getAllEmailSetting().then(
      (response) => {
        const emailSettingData = response.result;
        const arr = [];

        for (let i = 0; i < emailSettingData.length; i++) {
          let obj: any = {};

          obj.FromEmail = emailSettingData[i].from_email;
          obj.ToEmail = emailSettingData[i].to_email;
          obj.AuthUser = emailSettingData[i].auth_user;
          obj.AuthPassword = emailSettingData[i].auth_password;
          obj.Port = emailSettingData[i].port;
          obj.Id = emailSettingData[i].id;
        //   obj.Content = emailSettingData[i].content;

          //   obj.RoleId=patientData[i].roleId

          // setFieldId(workingHoursData[i].id)
          arr.push(obj);
        }
        setRowData(arr);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }, []);

  function totalValueRenderer(props: any) {
    // setFieldId(props.data.Id)

    return (
      <span>
        <button
          className="btn btn-primary"
          // onClick={() => navigate('/edit')}
          onClick={() =>
            navigate(`/${currentUser}/edit_email_setting`, { state: { key: props.data.Id } })
          }
        >
          Edit
        </button>
      </span>
    );
  }

  function deleteForm (props:any, id: any)  {
        
   
    
        return (
          <div>
         <span>
            <button className='btn btn-primary' 
            onClick={() => {
              handleShow();
              setDeleteId(props.data.Id)
            }
          }
           
            >Delete</button>
            </span>
            </div>
        );
      };


  // Container: Defines the grid's theme & dimensions.
  return (
    <div className="email-container.card">
      <div
        className="ag-theme-quartz"
        style={{ height: 500, marginTop: 30, marginLeft: 50 }}
      >
        {/* <div style={{ fontWeight: "bold", fontSize: 23, marginBottom: 40 }}>Manage Patient</div> */}
        <div>
          <button
            className="btn btn-primary"
            onClick={() => navigate(`/${currentUser}/create_email_setting`)}
          >
            Create Email Setting
          </button>
        </div>
        {/* <div>
    <button className='btn btn-primary'   onClick={()=>navigate('/')}>
            Cancel
          </button>
    </div> */}
        {/* The AG Grid component */}
        <div className="ag-header-container">
          {/* <div style={containerStyle}>
      <div
        style={gridStyle}
        className={
          "ag-theme-quartz"
        }
      > */}

          <AgGridReact
            rowData={rowData}
            columnDefs={colDefs}
            sideBar={"filters"}
            // defaultColDef={defaultColDef}
            //  onGridReady={onGridReady}
          />
          {/* </div> */}
          {/* </div> */}
        </div>
        <Modal show={show} onHide={()=>handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you want delete this form ?</Modal.Body>
        <Modal.Footer>
          <button  onClick={()=>handleClose()}>
            Cancel
          </button>
          <button 
          
          onClick={()=>deleteEmailSettingData(deleteId)}
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>
      </div>
    </div>
  );
};
export default EmailSettings;
