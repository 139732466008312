import doctor_imagem from "../../../assets/images/doctor/doctorm.jpg";
import award from "../../../assets/images/doctor/awardgreen.png";
import certificate from "../../../assets/images/doctor/certificategreen.png";
import location from "../../../assets/images/doctor/locationgreen.png";
import mail from "../../../assets/images/doctor/maigreenl.png";
import call from "../../../assets/images/doctor/callgreen.png";

import {
    Row,
    Col,
} from "reactstrap";
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { getOneDoctor } from "../../../services/doctor-service";
import { ImagePath } from "../../../services/auth.service";
import profile from "../../../assets/images/doctor/profile.png";
import { RoleContext } from "../../../utils/contexts/RoleContext";
import { createChatUser } from "../../../services/messages-service";
import DeleteModal from "../../../components/DeleteModal";
import { deleteoneuser } from "../../../services/user.service";
import { AlertContext } from "../../../utils/contexts/RoleContext";
import user1 from "../../../assets/images/users/user1.jpg";
import constants from "../../../config/constants";
import { capitalizeFirstLetter } from "../../../utils/utils";


const DoctorDetail = () => {
    const navigate = useNavigate();
    const { doctorId }: any = useParams();
    const locations = useLocation();
    const [patientDetail, setPatientDetail] = useState<any>({});
    const [show, setShow] = useState(false);
    const [deleteId, setDeleteId] = useState<string>("");
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const currentUser = useContext(RoleContext);
    const toast: any = useContext(AlertContext);


    useEffect(() => {
        getUsers();
        document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Doctor's Detail`;
    }, [currentUser]);

    const getUsers = () => {
        getOneDoctor(doctorId).then(
            (response) => {
                let patientData = { ...response?.allDoctors }

                // const date = new Date(patientData.createdAt);
                // const year = date.getFullYear();
                // const month = String(date.getMonth() + 1).padStart(2, "0"); 
                // const day = String(date.getDate()).padStart(2, "0");
                // const formattedDate = `${year}-${month}-${day}`;
                // setRegisterDate(formattedDate)
                patientData?.userdata?.forEach((patient: any) => {
                    if (patient?.fieldKey === 'gender') {
                        patientData.gender = patient?.fieldValue?.replace(/^"(.*)"$/, '$1');
                    } else if (patient?.fieldKey === 'dateOfBirth') {
                        patientData.dateOfBirth = patient?.fieldValue?.replace(/^"(.*)"$/, '$1');
                    } else if (patient?.fieldKey === 'height') {
                        patientData.height = patient?.fieldValue?.replace(/^"(.*)"$/, '$1');
                    } else if (patient?.fieldKey === 'weight') {
                        patientData.weight = patient?.fieldValue?.replace(/^"(.*)"$/, '$1');
                    } else if (patient?.fieldKey === 'memberStatus') {
                        patientData.memberStatus = patient?.fieldValue?.replace(/^"(.*)"$/, '$1');
                    } else if (patient?.fieldKey === 'address') {
                        patientData.address = patient?.fieldValue?.replace(/^"(.*)"$/, '$1');
                    }
                })
                patientData?.user_form_data?.forEach((patient: any) => {
                    if (patient?.formFieldKey === "specialtyDepartment") {
                        patientData.specialtyDepartment = patient?.formFieldValue?.replace(/^"(.*)"$/, '$1');
                    } else if (patient?.formFieldKey === 'biography') {
                        patientData.biography = patient?.formFieldValue?.replace(/^"(.*)"$/, '$1');
                    } else if (patient?.formFieldKey === 'medicalSchoolAttended') {
                        patientData.medicalSchoolAttended = patient?.formFieldValue?.replace(/^"(.*)"$/, '$1');
                    } else if (patient?.formFieldKey === "jobTitlePosition") {
                        patientData.jobTitlePosition = patient?.formFieldValue?.replace(/^"(.*)"$/, '$1');
                    }
                })
                setPatientDetail(patientData);
            },
            (error) => {
                console.log("error", error)

            }
        );
    }

    async function createChatUsers(id: number) {
        const response = await createChatUser(id);
        navigate(`/${currentUser}/messages`, { state: { chatRoomId: response.data.room } });
    }

    const handleDelete = async (id: any) => {
        deleteoneuser(id).then(
            (response) => {
                navigate(`/${currentUser}/doctordetaillist`);
                setTimeout(() => {
                    toast.setShowToast("Doctor has been deleted successfully", "success");
                }, 1000);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setTimeout(() => {
                    toast.setShowToast("Error while deleting this doctor. Please try again later.", "danger");
                }, 1000);
            }
        );
    }

    return (
        <Row>
            <div className="panel_top_section">
                <h1>Doctors</h1>
                {currentUser === "patient" ?
                    <div className="grid_icons d-flex gap-3 align-items-center float-end">
                        <button type="button" className="btn_gradian" onClick={() => navigate(`/${currentUser}/patient-appointment`, { state: { doctorId: patientDetail.id, doctorFirstName: patientDetail.firstName, doctorLastName: patientDetail.lastName, specialty: patientDetail.specialtyDepartment } })}>+ Schedule an Appointment With This Doctor</button>
                    </div>
                    :
                    <div className="grid_icons d-flex gap-3 align-items-center float-end">
                        <button
                            type="button"
                            className="btn_gradian"
                            onClick={() => navigate(`/${currentUser}/add-user/3`, { state: { roleId: "3" } })}
                        >
                            + Add a Doctor
                        </button>
                    </div>
                }
            </div>
            <Col>
                <div className="doctore_detail_wrap col-sm-12 row m-0 px-lg-2 px-xl-4 py-4 my-4">
                    <div className="col-lg-12 col-xl-4 doctor_left_block mb-4">
                        <div className="doctor_detail_image mb-4 ">
                            <img src={patientDetail?.profile == 0 ? user1 : ImagePath + 'profile/' + patientDetail.profile} alt="doctor_image" />
                        </div>
                        <div className="doctor_detail_page">
                            <ul>
                                <li >
                                    <span className="float-start"><img src={award} alt="award" /></span>
                                    <div className="right_box float-start">
                                        <h5>Education</h5>
                                        {/* <p>University of Atlanta School of Medicine</p> */}
                                        <p>{patientDetail?.medicalSchoolAttended}</p>
                                    </div>
                                </li>
                                <li>
                                    <span className="float-start"><img src={certificate} alt="certificate" /></span>
                                    <div className="right_box float-start">
                                        <h5>Certifications</h5>
                                        {/* <p>Advanced Cardiac Life Support (ACLS) Basic Life Support (BLS) </p> */}
                                        <p>{patientDetail?.jobTitlePosition}</p>
                                    </div>
                                </li>
                                <li>
                                    <span className="float-start"><img src={location} alt="certificate" /></span>
                                    <div className="right_box float-start">
                                        <h5>Location</h5>
                                        {/* <p>One Glenlake Parkway Suite 701, Atlanta, GA 30328</p> */}
                                        <p>{patientDetail?.address}</p>
                                    </div>
                                </li>
                                <li>
                                    <span className="float-start"><img src={mail} alt="mail" /></span>
                                    <div className="right_box float-start">
                                        <h5>Email</h5>
                                        {/* <a href="mailto:drethanbrown@mail.com">drethanbrown@mail.com</a> */}
                                        <a href={`mailto:${patientDetail?.email}`}>{patientDetail?.email}</a>
                                    </div>
                                </li>
                                <li>
                                    <span className="float-start"><img src={call} alt="call" /></span>
                                    <div className="right_box float-start">
                                        <h5>Phone </h5>
                                        {patientDetail?.allowCall ?
                                        <a href={`tel:${patientDetail?.phoneNumber}`}>{patientDetail?.phoneNumber}</a>
                                            :
                                            <a>{patientDetail?.phoneNumber}</a>
                                        }
                                    </div>
                                </li>
                            </ul>

                        </div>
                    </div>
                    <div className="col-lg-12 col-xl-8 doctor_right_block px-4">
                        <div className="row doctor_right_top pb-2">
                            <div className="col-sm-6 col-left">
                                {/* <h1>Dr. Ethan Brown</h1> */}
                                <h1>Dr. {patientDetail?.firstName}{patientDetail?.lastName}</h1>
                                <div className="profossional">
                                    {/* <span className="text-center">orthopedist</span> */}
                                    <span className="text-center">{patientDetail.specialtyDepartment}</span>
                                </div>
                            </div>
                            <div className="col-sm-6 col-right p-1">
                                {currentUser != "patient" &&
                                    <button
                                        type="button"
                                        onClick={() => navigate(`/${currentUser}/telehealth`)}
                                    >
                                        <i className="bi bi-camera-video" />
                                    </button>
                                }
                                <button
                                    type="button"
                                >
                                    {patientDetail?.allowCall ?
                                    <a href={`tel:${patientDetail?.phoneNumber}`}>
                                        <i className="bi bi-telephone" />
                                    </a>
                                        :
                                        <a>
                                            <i className="bi bi-telephone" />
                                        </a>
                                    }

                                </button>
                                <button
                                    type="button"
                                    onClick={() => createChatUsers(patientDetail.id)}
                                >
                                    <i className="bi bi-chat-text" />
                                </button>
                                {currentUser != "patient" &&
                                    <div className="dots_btn d-inline-block three_dots">
                                        <button type="button"><i className="bi bi-three-dots-vertical"></i></button>
                                        <ul className="drop_list m-0 p-0 bg-white p-2  text-left">
                                            <li
                                                className="clickable"
                                                onClick={() => {
                                                    navigate(`/${currentUser}/edit-user/${patientDetail?.id}`, { state: { key: patientDetail.id } });
                                                }}
                                            >
                                                <a >Edit</a>
                                            </li>
                                            <li
                                                className="clickable"
                                                onClick={() => setShowDeleteModal(true)}
                                            >
                                                <a>Delete</a>
                                            </li>
                                        </ul>
                                    </div>
                                }
                            </div>
                        </div>
                        <p>{patientDetail?.biography}</p>
                        {/* <p>Dr. Ethan Brown, a native of Atlanta, Georgia, is a dedicated cardiologist renowned for his commitment to patient care and medical excellence. Graduating with top honors from the University of Atlanta School of Medicine, Dr. Brown completed his residency specializing in cardiology. He holds certifications in Advanced Cardiac Life Support (ACLS) and Basic Life Support (BLS), demonstrating his dedication to providing life-saving interventions. Dr. Brown's personalized approach to patient care, coupled with his clinical expertise, has earned him a reputation as a trusted expert in the field. He remains actively involved in medical research and education, contributing to advancements in cardiovascular health. Dr. Brown's unwavering commitment to excellence continues to make a profound impact on the lives of his patients.</p> */}
                        {/* <p>In addition to his clinical responsibilities, Dr. Brown is actively involved in medical education and research, contributing to the advancement of cardiovascular medicine through his scholarly pursuits. He has authored numerous articles in peer-reviewed journals and has presented his research findings at national and international conferences, further solidifying his reputation as a thought leader in the field of cardiology.</p> */}
                    </div>
                </div>
                <DeleteModal
                    show={showDeleteModal}
                    handleYes={() => {
                        handleDelete(patientDetail.id);
                        setShowDeleteModal(false);
                    }}
                    handleClose={() => setShowDeleteModal(false)}
                    body="Do you really want to delete this Doctor?"
                    head="Delete Doctor"
                />
            </Col>
        </Row>
    );
};

export default DoctorDetail;
