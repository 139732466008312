import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyAOhPGt7194DmW-J_Er4gipXq37IhdzrA0",
    authDomain: "healthcare-6c4c5.firebaseapp.com",
    projectId: "healthcare-6c4c5",
    storageBucket: "healthcare-6c4c5.appspot.com",
    messagingSenderId: "799698482009",
    appId: "1:799698482009:web:2b110f9bed7b3ba6749248",
    measurementId: "G-EDXP0EDQCQ"
  };

// initializeApp(firebaseConfig);
const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);