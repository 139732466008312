const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

export function decidePages(data: any) {
    const arr = [];
    let pageLength: number = Math.floor(data / 11) + 1;
    for (let i = 1; i < pageLength + 1; i++) {
        arr.push(i)
    }
    return arr;
}

export function longDateGenerator(date:any) {
    const dateString = new Date(date);
    const longDate = dateString.getDate() + " " + months[dateString.getMonth()] + ", " + dateString.getFullYear();
    return longDate;
}

export function RailwayTimeGenerator() {
    const dateString = new Date();
    const railwayTime = ("0" + dateString.getHours()).slice(-2) + ":" + ("0" + dateString.getMinutes()).slice(-2);
    return railwayTime;
}

export function getDateOnly(d:any){
    return `${d.getFullYear()}-${("0" + (d.getMonth() + 1)).slice(-2)}-${("0" + d.getDate()).slice(-2)}`;
}

export function createRoom() {
    let roomName = "";
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < 8) {
        roomName += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return roomName;
}

export function removeDuplicates(data: any, field: any) {
    
    return data?.filter((value: any, index: any, self: any) =>
        index === self.findIndex((t: any) => (
          t[field] === value[field]
        ))
      );
}

export function capitalizeFirstLetter(string: any) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function groupBy (xs: any, key: any) {
    return xs?.reduce(function (rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || [])?.push(x);
        return rv;
    }, {});
};

