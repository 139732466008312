import axios from "axios";
import { END_POINT } from "../config/constants";

// const API_URL = "http://localhost:5000/api/subscription/";
const API_URL = `${END_POINT}subscription/`




export const getalladmin = () => {
    return axios
      .get(API_URL , {
      
      })
      .then((response) => {
     
  
        return response?.data;
      });
  };

  export const getoneadmin = (id:string) => {
    return axios
      .get(API_URL +id, {
      
      })
      .then((response) => {
     
  
        return response?.data;
      });
  };

  export const deleteoneadmin = (id:any) => {
    return axios
      .delete(API_URL +id, {
      
      })
      .then((response) => {
     
  
        return response?.data;
      });
  };

  export const updateadminsetting = (id:string,values:any) => {
    return axios
      .put(API_URL +id, {
        values
      
      })
      .then((response) => {
     
  
        return response?.data;
      });
  };

  export const updateformfield = (id:string,dragdroptable:any) => {
    return axios
      .put(API_URL + +id, {
        dragdroptable
      
      })
      .then((response) => {
     
  
        return response?.data;
      });
  };

  export const createadminsetting = (values:any) => {
    return axios
      .post(API_URL , {
        values
      
      })
      .then((response) => {
     
  
        return response?.data;
      });
  };

 

  

 