import * as AuthService from "../services/auth.service";

import { Route, Routes } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import AddUser from "../pages/adminHospital/user/AddUser";
import AddHospitalUser from "../pages/hospitalpanel/AddHospitalUser";
// import AddUser from "../pages/adminpanel/manageuser/AddUser";
import ChangePassword from "../pages/common/ChagePassword";
import CreateEmailSettings from "../pages/adminHospital/settings/emailSettings/CreateEmailSettings";
import CreateEmailTemplates from "../pages/adminHospital/emailTemplate/CreateEmailTemplates";
import Dashboard from "../pages/common/Dashboard";
import Doctors from "../pages/hospitalpanel/doctor/Doctors";
import DragandDrop from "../pages/adminHospital/manageform/DragandDrop";
import EditUser from "../pages/adminHospital/user/EditUser";
import EditEmailSettings from "../pages/adminHospital/settings/emailSettings/EditEmailSettings";
import EditEmailTemplate from "../pages/adminHospital/emailTemplate/EditEmailTmeplate";
import EditGeneralSetting from "../pages/adminHospital/settings/EditGeneralSetting";
// import EditProfile from "../pages/adminpanel/manageuser/EditProfile";
// import EditStaff from "../pages/hospitalpanel/staff/EditStaff";
import EditSubscriptionSales from "../pages/adminpanel/subscriptionSales/EditSubscriptionSales";
import EmailSettings from "../pages/adminHospital/settings/emailSettings/EmailSettings";
import EmailTemplates from "../pages/adminHospital/emailTemplate/EmailTemplates";
import ForgotPassword from "../pages/common/ForgotPassword";
import FormDashboard from "../pages/adminHospital/manageform/FormDashboard";
import FormNew from "../pages/adminHospital/manageform/FormFields";
import FormUpdate from "../pages/adminHospital/manageform/FormUpdate";
import FullLayout from "../layouts/FullLayout";
import GeneralSetting from "../pages/adminHospital/settings/GeneralSettings";
import GeneralSettings from "../pages/adminHospital/settings/AddGeneralSettings";
import GetProfile from "../pages/adminpanel/manageuser/GetProfile";
import Home from "../pages/common/Home";
import IUser from '../types/user.type';
import { IconContext } from 'react-icons';
import Login from "../pages/common/Login";
import PatientForm from "../pages/hospitalpanel/patient/PatientForm";
import PaymentSetting from "../pages/adminHospital/settings/PaymentSetting";
import Profile from "../pages/common/Profile";
import React from "react";
import Register from "../pages/common/Register";
// import ResetPassword from "../components/ResetPassword";
import { RoleContext } from "../utils/contexts/RoleContext";
import StaffForm from "../pages/hospitalpanel/staff/StaffForm";
import SubscriptionSales from "../pages/adminpanel/subscriptionSales/SubscriptionSales";
import Hospital from "../pages/adminpanel/manageuser/Hospital";
import Locations from "../pages/adminHospital/location/Locations";
import AddLocation from "../pages/adminHospital/location/AddLocation";
import LocationDetail from "../pages/adminHospital/location/LocationDetail";
import Medical from "../pages/hospitalpanel/medicalRecords/Medical";
import AddMedical from "../pages/hospitalpanel/medicalRecords/AddMedicalRecords";
import Telehealth from "../pages/adminHospital/telehealth/Telehealth";
import TelehealthAppointment from "../pages/adminHospital/telehealth/TelehealthAppointment";
import TelehealthDetail from "../pages/adminHospital/telehealth/TelehealthDetail";
import TelehealthVideo from "../pages/adminHospital/telehealth/TelehealthVideo";
import Appointment from "../pages/common/appointment/Appointment";
import AppointmentDetail from "../pages/common/appointment/AppointmentDetail";
import ScheduleAppointment from "../pages/common/appointment/ScheduleAppointment";
import AppointmentView from "../pages/common/appointment/AppointmentView";
import EditMedical from "../pages/hospitalpanel/medicalRecords/EditMedicalRecords";
import AddPrescription from "../pages/common/prescription/AddPrescription";
import PrescriptionList from "../pages/common/prescription/prescriptionList";
import Refill from "../pages/common/RefillRequest/refill";
import AddRefillRequest from "../pages/common/RefillRequest/AddRefillrequest";
import AddRefillMedication from "../pages/common/RefillRequest/AddRefillMedication";
import RefilDetail from "../pages/common/RefillRequest/refillDetails";
import Questionnaire from "../pages/common/questionnaire/Questionnaire";
import PatientQuestionnaire from "../pages/common/questionnaire/patientQuestionnaire";
import RefillSend from "../pages/common/RefillRequest/refillSend";
import Billing from "../pages/common/billing/Billing";
import BillingDetail from "../pages/common/billing/BillingDetail";
// import AddPrescription from "../pages/common/prescription/AddPrescription";
// import PrescriptionList from "../pages/common/prescription/prescriptionList";
// import DynamicForm from "../pages/common/prescription/example";
// import Refill from "../pages/common/RefillRequest/refill";
// import AddRefillRequest from "../pages/common/RefillRequest/AddRefillrequest";

import PatientDetail from "../pages/hospitalpanel/patient/PatientDetail";
import StaffDashboard from "../pages/common/StaffDashboard";
import BillingPayment from "../pages/common/billing/BillingPayment";
import Payment from "../pages/common/billing/Payment";
import DoctorQues from "../pages/common/questionnaire/DoctorQues";
import DoctorQuesDetail from "../pages/common/questionnaire/DoctorQuesDetail";
import MessagesSecure from "../pages/common/messages/MessagesSecure";
import Messages from "../pages/common/messages/Messages";
import RecordCheck from "../pages/common/messages/RecordCheck";
import DoctorSettingProfile from "../pages/common/settings/DoctorSettingProfile";
import DoctorPasswordSetting from "../pages/common/settings/DoctorPasswordSetting";
import DoctorsGrid from "../pages/patientpanel/doctors/Doctorsgrid";
import DoctorDetail from "../pages/patientpanel/doctors/DoctorDetail";
import EditProfile from "../pages/adminHospital/user/EditProfile";
import DoctorAvailabilityForm from "../pages/doctorpanel/DoctorAvailabilityForm";
import DoctorAvailability from "../pages/doctorpanel/DoctorAvailability";
import AppointmentDisplay from "../pages/common/questionnaire/AppointmentDisplay";
import DoctorDashboard from "../pages/common/DoctorDashboard";
import PatientAppointment from "../pages/common/appointment/PatientAppointment";
import MyProfile from "../pages/adminHospital/user/MyProfile";
import AdminSettings from "../pages/adminpanel/AdminSettings";
import OrderDetail from "../pages/common/billing/OrderDetail";
import QuestionnaireDisplay from "../pages/common/questionnaire/QuestionnaireDisplay";
import MyPatients from "../pages/doctorpanel/MyPatients";
import AdminDashboard from "../pages/common/AdminDashboard";
import InvoicesView from "../pages/adminpanel/subscriptionSales/InvoicesView";
import AddInvoice from "../pages/adminpanel/subscriptionSales/AddInvoice";
import Subscription from "../pages/hospitalpanel/Subscription";
import InvoicesInfo from "../pages/hospitalpanel/InvoicesInfo";
import ManageSettings from "../pages/adminpanel/ManageSettings";
import NewMedication from "../pages/common/RefillRequest/NewMedication";

export default function Middlepart() {
    const currentUser = useContext(RoleContext);
    return (
        <Routes>
            <Route path={`/hospital/dashboard`} element={<Dashboard />} />
            <Route path={`/admin/hospital-dashboard`} element={<Dashboard />} />
            <Route path="/patient/login" element={<Home />} />
            {/* <Route path="/home" element={<Home />} /> */}
            <Route path={`/${currentUser}/profile`} element={<Profile />} />
            {/* <Route path={`/${cmsurl}/resetpassword`} element={<ResetPassword />} /> */}
            <Route path={`/${currentUser}/getprofile`} element={<GetProfile />} />
            <Route path={`/${currentUser}/editprofile`} element={<EditProfile />} />
            <Route path={`/${currentUser}/changepassword`} element={<ChangePassword />} />
            <Route path={`/${currentUser}/manageform`} element={<FormDashboard />} />
            <Route path={`/${currentUser}/drag`} element={<DragandDrop />} />
            <Route path={`/${currentUser}/formupdate`} element={<FormUpdate />} />
            <Route path={`/${currentUser}/form`} element={<FormNew />} />
            <Route path={`/${currentUser}/settings/generalsetting`} element={<GeneralSetting />} />
            {/* <Route path={`/${currentUser}/add_user`} element={<AddUser />} /> */}
            <Route path={`/${currentUser}/settings/add_generalsetting`} element={<GeneralSettings />} />
            <Route path={`/${currentUser}/settings/edit_setting`} element={<EditGeneralSetting />} />
            <Route path={`/${currentUser}/settings/paymentsetting`} element={<PaymentSetting />} />
            <Route path={`/${currentUser}/email_templates`} element={<EmailTemplates />} />
            <Route path={`/${currentUser}/create_email_templates`} element={<CreateEmailTemplates />} />
            <Route path={`/${currentUser}/edit_email_templates`} element={<EditEmailTemplate />} />
            <Route path={`/${currentUser}/email_setting`} element={<EmailSettings />} />
            <Route path={`/${currentUser}/create_email_setting`} element={<CreateEmailSettings />} />
            <Route path={`/${currentUser}/edit_email_setting`} element={<EditEmailSettings />} />
            <Route path={`/${currentUser}/sales/subscriptionsales`} element={<SubscriptionSales />} />
            <Route path={`/${currentUser}/sales/editsubscriptionsales`} element={<EditSubscriptionSales />} />
            <Route path={`/${currentUser}/edit-user/:doctorId`} element={<EditUser />} />
            <Route path={`/${currentUser}/patient-list`} element={<PatientForm />} />
            <Route path={`/${currentUser}/stafflist`} element={<StaffForm />} />
            <Route path={`/${currentUser}/add_hospital_user`} element={<AddHospitalUser />} />
            <Route path={`/${currentUser}/doctordetaillist`} element={<Doctors />} />
            <Route path={`/${currentUser}/add-user/:role`} element={<AddUser />} />
            <Route path={`/${currentUser}/hospital`} element={<Hospital />} />
            <Route path={`/${currentUser}/locations`} element={<Locations />} />
            <Route path={`/${currentUser}/add-location`} element={<AddLocation />} />
            <Route path={`/${currentUser}/edit-location/:locationId`} element={<AddLocation />} />
            <Route path={`/${currentUser}/location-detail/:locationId`} element={<LocationDetail />} />
            <Route path={`/${currentUser}/medical-records`} element={<Medical />} />
            <Route path={`/${currentUser}/addmedical-records`} element={<AddMedical />} />
            <Route path={`/${currentUser}/telehealth`} element={<Telehealth />} />
            <Route path={`/${currentUser}/tele-appointment`} element={<TelehealthAppointment />} />
            <Route path={`/${currentUser}/tele-detail/:roomId`} element={<TelehealthDetail />} />
            <Route path={`/${currentUser}/tele-video`} element={<TelehealthVideo />} />
            <Route path={`/${currentUser}/appointment`} element={<Appointment />} />
            <Route path={`/${currentUser}/appointment/:date`} element={<Appointment />} />
            <Route path={`/${currentUser}/appointment-detail/:appointmentId`} element={<AppointmentDetail />} />
            <Route path={`/${currentUser}/schedule_appointment`} element={<ScheduleAppointment />} />
            <Route path={`/${currentUser}/appointment/view/:appointmentId`} element={<AppointmentView />} />
            <Route path={`/${currentUser}/edit-medical-records/:patientId`} element={<EditMedical />} />
            <Route path={`/${currentUser}/addprescription`} element={<AddPrescription />} />
            <Route path={`/${currentUser}/prescriptionlist/:appointmentId`} element={<PrescriptionList />} />
            <Route path={`/${currentUser}/refill-request`} element={<Refill />} />
            <Route path={`/${currentUser}/addrefill-request`} element={<AddRefillRequest />} />
            <Route path={`/${currentUser}/addrefill-medication`} element={<AddRefillMedication />} />
            <Route path={`/${currentUser}/update-refill-medication/:refillId`} element={<AddRefillMedication />} />
            <Route path={`/${currentUser}/refilldetail/:refillId`} element={<RefilDetail />} />
            <Route path={`/${currentUser}/questionnaire`} element={<Questionnaire />} />
            <Route path={`/${currentUser}/patient-questionnaire`} element={<PatientQuestionnaire />} />

            <Route path={`/${currentUser}/patientdetail/:patientId`} element={<PatientDetail />} />
            <Route path={`/${currentUser}/staff-dashboard`} element={<StaffDashboard />} />
            <Route path={`/${currentUser}/refillsend`} element={<RefillSend />} />
            <Route path={`/${currentUser}/billing`} element={<Billing />} />
            <Route path={`/${currentUser}/billing-detail/:orderId`} element={<BillingDetail />} />
            <Route path={`/${currentUser}/billing-payment`} element={<BillingPayment />} />
            <Route path={`/${currentUser}/payment/:invoiceId`} element={<Payment />} />
            <Route path={`/${currentUser}/questionnaires`} element={<DoctorQues />} />
            <Route path={`/${currentUser}/quesdetails`} element={<DoctorQuesDetail />} />
            <Route path={`/${currentUser}/messages`} element={<Messages />} />
            <Route path={`/${currentUser}/secure-messages`} element={<MessagesSecure />} />
            <Route path={`/${currentUser}/record`} element={<RecordCheck />} />
            <Route path={`/${currentUser}/profile-settings`} element={<DoctorSettingProfile />} />
            <Route path={`/${currentUser}/doctorlist`} element={<DoctorsGrid />} />
            <Route path={`/${currentUser}/doctor-detail/:doctorId`} element={<DoctorDetail />} />
            <Route path={`/${currentUser}/editprofile/:doctorId`} element={<EditProfile />} />
            <Route path={`/${currentUser}/availability`} element={<DoctorAvailability />} />
            <Route path={`/patient/patient-appointments`} element={<AppointmentDisplay />} />
            <Route path={`/doctor/dashboard`} element={<DoctorDashboard />} />
            <Route path={`/patient/patient-appointment`} element={<PatientAppointment />} />
            <Route path={`/${currentUser}/my-profile/:doctorId`} element={<MyProfile />} />
            <Route path={`/admin/settings`} element={<AdminSettings />} />
            <Route path={`/${currentUser}/order-detail/:orderId`} element={<OrderDetail />} />
            <Route path={`/${currentUser}/questionnaire-forms`} element={<QuestionnaireDisplay />} />
            <Route path={`/doctor/my-patients`} element={<MyPatients />} />
            <Route path={`/admin/dashboard`} element={<AdminDashboard />} />
            <Route path={`/admin/view-invoices`} element={<InvoicesView />} />
            <Route path={`/admin/add-invoice`} element={<AddInvoice />} />
            <Route path={`/admin/update-invoice/:invoiceId`} element={<AddInvoice />} />
            <Route path={`/hospital/subscription`} element={<Subscription />} />
            <Route path={`/${currentUser}/invoice-info/:invoiceId`} element={<InvoicesInfo />} />
            <Route path={`/${currentUser}/manage-settings`} element={<ManageSettings />} />
            <Route path={`/patient/settings`} element={<DoctorPasswordSetting />} />
            <Route path={`/doctor/settings`} element={<DoctorPasswordSetting />} />
            <Route path={`/staff/settings`} element={<DoctorPasswordSetting />} />
            <Route path={`/hospital/settings`} element={<DoctorPasswordSetting />} />
            <Route path={`/${currentUser}/add-medication`} element={<NewMedication />} />
            {/* <Route path={`/${currentUser}/addprescription`} element={<AddPrescription />} />
            <Route path={`/${currentUser}/prescriptionlist`} element={<PrescriptionList />} />
            <Route path={`/${currentUser}/refill-request`} element={<Refill />} />
            <Route path={`/${currentUser}/addrefill-request`} element={<AddRefillRequest />} /> */}

        </Routes>
    )
}