import user from "../../../assets/images/message/user1.jpg";
import patient from "../../../assets/images/message/patient.jpg";
import post1 from "../../../assets/images/message/post1.jpg";
import post2 from "../../../assets/images/message/post2.jpg";
import post3 from "../../../assets/images/message/post3.jpg";
import post4 from "../../../assets/images/message/post4.jpg";
import { Row, Col } from "reactstrap";
import { useEffect, useState, useContext, useRef } from "react";
import { RoleContext } from "../../../utils/contexts/RoleContext";
import { useNavigate, useLocation } from "react-router-dom";
import { getAppointmentUsers } from "../../../services/scheduleappointment-service";
import UsersModal from "../../../components/UsersModal";
import { createRoom } from "../../../utils/utils";
import { getMessages } from "../../../services/messages-service";
import {
      createChatUser,
      getChatUsers,
      updateSeenStatus,
      saveMessage,
      getUnreadMessages,
} from "../../../services/messages-service";
import FileRenderer from "../../../components/FileRenderer";
import { io } from "socket.io-client";
import { getCurrentUser } from "../../../services/auth.service";
import { RailwayTimeGenerator, getDateOnly } from "../../../utils/utils";
import { getOneDoctor } from "../../../services/doctor-service";
import { longDateGenerator } from "../../../utils/utils";
import MediaModal from "../../../components/MediaModal";
import { getImages } from "../../../services/messages-service";
import RecordCheck from "./RecordCheck";
import { useAudioRecorder } from "react-audio-voice-recorder";
import CustomAudioPlayer from "../../../components/AudioPlayer";
import SearchInput from "../../../components/SearchInput";
import { sendNotification } from "../../../services/notification-service";
import DeleteModal from "../../../components/DeleteModal";
import { SOCKET_DOMAIN } from "../../../config/constants";
import { socket } from "../../../index";
import { getHospitalUsers } from "../../../services/user.service";
import { clearChat } from "../../../services/messages-service";
import user1 from "../../../assets/images/users/user1.jpg";
import Spinner from 'react-bootstrap/Spinner';
import ImagePreview from "../../../components/ImagePreview";
import constants from "../../../config/constants";
import { capitalizeFirstLetter } from "../../../utils/utils";
import { getClusters } from "../../../services/user.service";



const Messages = () => {
      const [users, setUsers] = useState([]);
      const [usersCopy, setUsersCopy] = useState([]);
      const [chatUsers, setChatUsers] = useState<any>([]);
      const [chatUsersCopy, setChatUsersCopy] = useState<any>([]);
      const [showPopup, setShowPopup] = useState(false);
      const [showMedia, setShowMedia] = useState("");
      const [room, setRoom] = useState("");
      const [currentChat, setCurrentChat] = useState<any>({});
      const [chats, setChats] = useState<any>({});
      const [showPreview, setShowPreview] = useState(false);
      const [unReadMessages, setUnreadMessages] = useState<any>({});
      const [previewData, setPreviewData] = useState({});
      const [message, setMessage] = useState("");
      const [showPatientInfo, setShowPatientInfo] = useState(false);
      const [typing, setTyping] = useState(false);
      const [userInfo, setUserInfo] = useState<any>([]);
      const [images, setImages] = useState<any>([]);
      const [documents, setDocuments] = useState<any>([]);
      const [tab, setTab] = useState("visible");
      const [currentUserId, setCurrentUserId] = useState("");
      const [media, setMedia] = useState<any>([]);
      const [recording, setRecording] = useState(false);
      const [audioSource, setAudioSource] = useState<any>();
      const [deleteRecording, setDeleteRecording] = useState(false);
      const [search, setSearch] = useState("");
      const {
            startRecording,
            stopRecording,
            togglePauseResume,
            recordingBlob,
            isRecording,
            isPaused,
            recordingTime,
            mediaRecorder,
      } = useAudioRecorder();
      const [unreadCount, setUnreadCount] = useState(0);
      const [showDeleteModal, setShowDeleteModal] = useState(false);
      const [loading, setLoading] = useState(false);
      const [showChatWindow, setShowChatWindow] = useState(false);

      const unreadMessagesRef = useRef(null);
      const currentUser = useContext(RoleContext);
      const navigate = useNavigate();
      const chatWindowRef: any = useRef(null);
      const location = useLocation();


      useEffect(() => {
            if (currentUser === "patient" || currentUser === "doctor") {
                  fetchAppointmentUsers();
            } else if (currentUser === "admin") {
                  getDoctorsPatients()
            } else {
                  fetchHospitalUsers();
            }
            // fetchChatUsers();
            setCurrentUserId(getCurrentUser()?.id);
            document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Messages`;
      }, [currentUser]);

      useEffect(() => {
            socket.emit("user_status", {
                  status: "ONLINE",
                  id: getCurrentUser()?.id
            });
            return () => {
                  socket.emit("user_status", {
                        status: "IDLE",
                        id: getCurrentUser()?.id
                  });
            }
      }, [])

      useEffect(() => {
            if (!recordingBlob) return;
            const url = URL.createObjectURL(recordingBlob);
            // setAudioSource(url);
            if (!deleteRecording) {
                  handleAudioUpload(url, recordingBlob);
            }
            // handleFormData(recordingBlob)
      }, [recordingBlob, deleteRecording]);

      async function getDoctorsPatients() {
            const docRes = await getClusters('3');
            const doctors = docRes?.data?.rows;
            const patientRes = await getClusters('5');
            const patients = patientRes?.data?.rows;
            const users = doctors.concat(patients)?.map((item: any) => {
                  item.name = item.firstName + " " + item.lastName;
                  return item;
            });
            setUsers(users?.filter((item: any) => item.roleId === 5))
            setUsersCopy(users);
      }

      async function fetchHospitalUsers() {
            const response = await getHospitalUsers();
            if (response?.success) {
                  setUsers(response?.data?.filter((item: any) => item.roleId === 5));
                  setUsersCopy(response?.data);
            }
      }

      function getTime(time: number) {
            const minutes = Math.floor(time / 60);
            const seconds = time % 60;
            const hours = Math.floor(time / 3600);
            time = time - hours * 3600;
            const finalTime =
                  str_pad_left(minutes, "0", 2) + ":" + str_pad_left(seconds, "0", 2);
            return finalTime;
      }

      function str_pad_left(string: number, pad: string, length: number) {
            return (new Array(length + 1).join(pad) + string).slice(-length);
      }

      async function fetchChatUsers(roomId?: string) {
            setLoading(true);
            const response = await getChatUsers();
            if (response?.success) {
                  if (roomId) {
                        const currentChatUser = response.data.find((item: any) => item.room === roomId);
                        setupCurrentUser(currentChatUser);
                  }
                  setChatUsers(response.data);
                  setChatUsersCopy(response.data);
            }
            setLoading(false);
      }

      useEffect(() => {
            fetchChatUsers(location?.state?.chatRoomId);
      }, [location?.state?.chatRoomId]);

      useEffect(() => {
            let user = getCurrentUser();
            socket.on(`user_added_${user.id}`, (data: any) => {
                  fetchChatUsers();
            });

            return () => {
                  socket.off(`user_added_${user.id}`);
            };
      }, []);

      async function fetchAppointmentUsers() {
            const response = await getAppointmentUsers();
            if (response?.success) {
                  setUsers(response?.data?.filter((item: any) => {
                        switch (currentUser) {
                              case "doctor":
                                    return item.roleId === 5;
                              
                              case "patient":
                                    return item.roleId === 3;
                        }
                  }));
                  setUsersCopy(response?.data);
            }
      }

      async function handleClick(item: any) {
            const response = await createChatUser(item.id);
            if (response?.success) {
                  fetchChatUsers(response?.data.room);
                  socket.emit("user_added", { toId: response.data.receiverId });
            }
            setShowPopup(false);
      }

      useEffect(() => {
            if (room) {
                  getAllMessages();
                  getAllUnreadMessages();
            }

            // socket.on("connect_error", (err: any) => {
            //       // the reason of the error, for example "xhr poll error"
            //       console.log(err.message);

            //       // some additional description, for example the status code of the initial HTTP response
            //       console.log(err.description);

            //       // some additional context, for example the XMLHttpRequest object
            //       console.log(err.context);
            // });
      }, [room]);

      useEffect(() => {
            socket.on("status", (data) => {
                  let userStatus = "OFFLINE"
                  data?.forEach((item: any) => {
                        if (item.id == currentChat.receiver) {
                              userStatus = item.userStatus;
                        }
                  })
                  setUserInfo((prevState: any) => ({ ...prevState, userStatus }))
            })

            return () => {
                  socket.off("user_status");
            }
      }, [currentChat])

      useEffect(() => {
            socket.on(`all_messages_${getCurrentUser()?.id}`, (data: any) => {
                  setChatUsers(chatUsers?.map((item: any) => {
                        const itemCopy = { ...item };
                        if (item.room == data.roomId) {
                              if (room != data.roomId) {
                                    itemCopy.unreadCount += 1;
                              }
                              itemCopy.lastMessage = data.messageType === "text" ? data.message : "Media"
                              itemCopy.lastMessageTime = data.sentTime
                        }
                        return itemCopy;
                  }));
            })

            return () => {
                  socket.off(`all_messages${getCurrentUser()?.id}`);
            }
      }, [chatUsers, room])

      useEffect(() => {
            document.addEventListener("visibilitychange", () => {
                  // it could be either hidden or visible
                  setTab(document.visibilityState);
                  // if(document.visibilityState === "visible") {
                  //       getAllUnreadMessages();
                  // }
                  // const user = getCurrentUser();
                  let userStatus = "IDLE";
                  if (document.visibilityState == "visible" && location.pathname.split("/")[2] === "messages") {
                        userStatus = "ONLINE";
                        if (room !== "") {
                              let payload = {
                                    roomId: room,
                                    acknowledgeId: getCurrentUser().id,
                              }
                              socket.emit("bulk_messages_received", payload);
                              //commented on 17.06.24
                              for (let key in unReadMessages) {
                                    unReadMessages[key].forEach((item: any) => {
                                          updateReadStatus(item.chatId, 2);
                                    });
                              }
                        }
                  }
                  socket.emit("user_status", {
                        status: userStatus,
                        id: getCurrentUser()?.id
                  });
                  // unReadMessages.forEach((item: any) => {
                  //   updateReadStatus(item.chatId, 2);
                  //   socket.emit("message_received", {
                  //     chatId: item.chatId,
                  //     readStatus: 2,
                  //     roomId: room,
                  //     acknowledgeId: user.id,
                  //     sentDate: item.sentDate,
                  //   });
                  // });

            });
            return () => {
                  document.removeEventListener("visibilitychange", () =>
                        console.log("removed")
                  );
            };
      }, [unReadMessages, room]);

      async function getAllMessages() {
            let user = getCurrentUser();
            const response = await getMessages(room);
            const readMessages: any = [];
            // const unReadMessages: any = [];
            const images: any = [];
            const documents: any = [];
            const messagesObj = { ...response.data };
            Object.keys(messagesObj).forEach((key: any) => {
                  const obj: any = {
                        date: key,
                        messages: [],
                  };
                  const messgaesArr: any = [];
                  messagesObj[key].forEach((item: any) => {
                        if (item.message === null) {
                              item.source = item.message_document?.source;
                              item.fileName = item.message_document?.fileName;
                              item.ext = item.message_document?.extension;
                              item.size = item.message_document?.size;
                              if (item.message_document?.type == "image") {
                                    images.push(item);
                              } else if (item.message_document?.type == "file") {
                                    documents.push(item);
                              }
                        }
                        if (item.fromId == user.id) {
                              item.pos = 0;
                              // readMessages.push(item);
                        } else {
                              item.pos = 1;
                              // readMessages.push(item);
                              // if (item.readStatus == 0) {
                              //       updateReadStatus(item.chatId, 1);
                              //       socket.emit("message_received", {
                              //             chatId: item.chatId,
                              //             readStatus: 1,
                              //             roomId: room,
                              //             acknowledgeId: user.id
                              //       });
                              //       unReadMessages.push(item);
                              // } else {
                              //       readMessages.push(item);
                              // }
                        }
                        messgaesArr.push(item);
                  });
                  messagesObj[key] = messgaesArr;
                  // readMessages.push(obj);
            });
            setChats(messagesObj);
            setImages((prevState: any) => [...prevState, ...images]);
            setDocuments((prevState: any) => [...prevState, ...documents]);
      }

      async function getAllUnreadMessages() {
            const images: any = [];
            const documents: any = [];
            let user = getCurrentUser();
            const unreadMessages: any = [];
            const response = await getUnreadMessages(room);
            const messagesObj = { ...response.data };
            const payload: any = {
                  roomId: room,
                  acknowledgeId: user.id,
                  messages: []
            }
            Object.keys(messagesObj).forEach((key: any) => {
                  const messgaesArr: any = [];
                  messagesObj[key]?.forEach((item: any) => {
                        const obj: any = {
                              chatId: item.chatId,
                              readStatus: 2,
                              sentDate: item.sentDate,
                        }
                        if (item.message === null) {
                              item.source = item.message_document?.source;
                              item.fileName = item.message_document?.fileName;
                              item.ext = item.message_document?.extension;
                              item.size = item.message_document?.size;
                              if (item.message_document?.type == "image") {
                                    images.push(item);
                              } else if (item.message_document?.type == "file") {
                                    documents.push(item);
                              }
                        }
                        payload.messages.push(obj);

                        item.pos = 1;
                        updateReadStatus(item.chatId, 2);
                        messgaesArr.push(item);
                  });
                  messagesObj[key] = messgaesArr;
            });
            if (payload?.messages?.length > 0) {
                  socket.emit("bulk_messages_received", payload)
            }
            setUnreadCount(response.count);
            setUnreadMessages(messagesObj);
            setImages((prevState: any) => [...prevState, ...images]);
            setDocuments((prevState: any) => [...prevState, ...documents]);
      }

      useEffect(() => {
            let user = getCurrentUser();
            socket.on(`bulk_chat_receive_${room}`, (data) => {
                  if (user.id !== data.acknowledgeId) {
                        const messageObj = { ...chats };
                        for (let key in messageObj) {
                              const messageArr = messageObj[key]?.map((item: any) => {
                                    const itemCopy = { ...item };
                                    itemCopy.readStatus = 2;
                                    return itemCopy;
                              });
                              messageObj[key] = messageArr;
                        }
                        setChats(messageObj);
                  }
            });

            return () => {
                  socket.off(`bulk_chat_receive_${room}`);
            };
      }, [room, chats]);

      useEffect(() => {
            socket.on(room, (data) => {
                  let user = getCurrentUser();
                  if (data.fromId != user.id) {
                        if (tab === "hidden" || unreadCount > 0) {
                              if (unReadMessages[data.sentDate] == undefined) {
                                    setUnreadMessages((prevState: any) => ({ ...prevState, [data.sentDate]: [data] }))
                              } else {
                                    setUnreadMessages((prevState: any) => ({
                                          ...prevState,
                                          [data.sentDate]: [...prevState[data.sentDate], data],
                                    }));
                              }
                              setUnreadCount(unreadCount + 1);
                        } else {
                              if (chats[data.sentDate] == undefined) {
                                    setChats((prevState: any) => ({ ...prevState, [data.sentDate]: [data] }))
                              } else {
                                    setChats((prevState: any) => ({
                                          ...prevState,
                                          [data.sentDate]: [...prevState[data.sentDate], data],
                                    }));
                              }
                        }
                        handleAcknowledgement(
                              data,
                              room == data.roomId && tab === "visible" ? 2 : 1
                        );
                  }
            });

            return () => {
                  socket.off(room);
            };
      }, [room, unReadMessages, tab, unreadCount, chats]);


      function handleAcknowledgement(data: any, readStatus: number) {
            socket.emit("message_received", {
                  chatId: data.chatId,
                  readStatus,
                  roomId: data.roomId,
                  acknowledgeId: currentUserId,
                  sentDate: data.sentDate,
            });
      }

      useEffect(() => {
            let user = getCurrentUser();
            socket.on(`chat_receive_${room}`, (data) => {
                  if (data.acknowledgeId != user.id) {
                        updateReadStatus(data.chatId, data.readStatus);
                        const messagesObj = { ...chats };
                        const messagesArr = [...messagesObj[data.sentDate]];
                        messagesObj[data.sentDate] = messagesArr.map((item: any) => {
                              const chatObj = { ...item };
                              if (item.chatId == data.chatId) {
                                    chatObj.readStatus = data.readStatus;
                              }
                              return chatObj;
                        });
                        setChats(messagesObj);
                        // setChats((prevState: any) => prevState[data.sentDate].map((item: any) => {
                        //       const chatObj = { ...item }
                        //       if (item.chatId == data.chatId) {
                        //             chatObj.readStatus = data.readStatus
                        //       }
                        //       return chatObj;
                        // }));
                  }
            });
            return () => {
                  socket.off(`chat_receive_${room}`);
            };
      }, [chats, room]);

      useEffect(() => {
            socket.on(`typing_${room}`, (data: any) => {
                  let user = getCurrentUser();
                  if (data.fromId != user.id) {
                        setTyping(true);
                        setTimeout(() => {
                              setTyping(false);
                        }, 3000);
                  }
            });

            return () => {
                  socket.off(room);
            };
      }, [room]);

      useEffect(() => {
            if (chatWindowRef.current) {
                  chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
            }
      }, [chats, unReadMessages, room]);

      async function updateReadStatus(id: string, status: number) {
            const response = await updateSeenStatus(id, status);
      }

      function handleSendMessage() {
            if (message.trim()) {
                  const messageObj = {
                        message,
                        roomId: room,
                        pos: 0,
                        fromId: getCurrentUser().id,
                        toId: Number(currentChat.receiver),
                        messageType: "text",
                        sentTime: RailwayTimeGenerator(),
                        sentDate: getDateOnly(new Date()),
                        readStatus: 0,
                        chatId: Date.now(),
                  };
                  saveOneMessage(messageObj);
                  handleMessageStructure(messageObj)
                  handleEmit(messageObj);
            }
            setMessage("");
      }

      function handleMessageStructure(messageObj: any) {
            if (unreadCount > 0) {
                  const unReadMessagesObj = { ...unReadMessages };
                  const readMessagesObj = { ...chats };
                  for (let key in unReadMessagesObj) {
                        if (readMessagesObj[key] === undefined) {
                              readMessagesObj[key] = [...unReadMessagesObj[key], messageObj];
                        } else {
                              readMessagesObj[key] = [...readMessagesObj[key], ...unReadMessagesObj[key], messageObj];
                        }
                  }
                  setChats(readMessagesObj);
                  setUnreadMessages({});
                  setUnreadCount(0);
            } else {
                  handleLocalUpdate(messageObj)
            }
      }

      function handleLocalUpdate(messageObj: any) {
            let todayDate = getDateOnly(new Date());
            if (chats[todayDate] == undefined) {
                  setChats((prevState: any) => ({ ...prevState, [todayDate]: [messageObj] }))
            } else {
                  setChats((prevState: any) => ({
                        ...prevState,
                        [todayDate]: [...prevState[todayDate], messageObj],
                  }));
            }
      }

      const handleFileUpload = (post: any) => {
            let todayDate = getDateOnly(new Date());
            let files = [];
            if (post.target.files && post.target.files.length > 0) {
                  for (let i = 0; i < post.target.files.length; i++) {
                        let file = post.target.files[i];
                        let type = "file";
                        if (file.type.split("/")[0] === "image") {
                              type = "image";
                        }
                        const fileName = file.name;
                        let reader = new FileReader();
                        const fileExt =
                              fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length) ||
                              fileName;
                        if (file) {
                              reader.readAsDataURL(file);
                              reader.onloadend = () => {
                                    const fileObj = {
                                          fileSource: file,
                                          source: reader.result,
                                          fileType: file.type,
                                          fileName: file.name,
                                          size: file.size,
                                          ext: fileExt,
                                          message: null,
                                          roomId: room,
                                          pos: 0,
                                          fromId: getCurrentUser().id,
                                          toId: Number(currentChat.receiver),
                                          messageType: type,
                                          sentTime: RailwayTimeGenerator(),
                                          sentDate: getDateOnly(new Date()),
                                          readStatus: 0,
                                          chatId: Date.now(),
                                    };
                                    handleMessageStructure(fileObj);
                                    let fileObjCopy = { ...fileObj };
                                    fileObjCopy.source = "";
                                    handleEmit(fileObjCopy);
                              };
                        }
                  }
            }
      };

      function handleAudioUpload(url: string, blob: any) {
            let extension = blob.type.split(";")[0].split("/")[1];
            const messageObj = {
                  message: null,
                  source: url,
                  audioData: blob,
                  roomId: room,
                  pos: 0,
                  fromId: getCurrentUser().id,
                  toId: Number(currentChat.receiver),
                  messageType: "audio",
                  sentTime: RailwayTimeGenerator(),
                  sentDate: getDateOnly(new Date()),
                  readStatus: 0,
                  chatId: Date.now(),
                  ext: extension,
                  size: blob.size,
            };
            handleMessageStructure(messageObj);
            handleEmit(messageObj);
            setAudioSource("");
      }

      function handleEmit(messageObj: any) {
            socket.emit("message", {
                  socketID: socket.id,
                  ...messageObj,
            });
            socket.emit("all_messages", {
                  socketID: socket.id,
                  ...messageObj,
            });
      }

      async function saveOneMessage(payload: any) {
            const response = await saveMessage(payload);
            // console.log("res", response);
      }

      async function getPatientInfo(id: any) {
            const response = await getOneDoctor(id);
            const userData: any = {
                  name: response.allDoctors.firstName + " " + response.allDoctors.lastName,
                  email: response.allDoctors.email,
                  phoneNumber: response.allDoctors.phoneNumber,
                  userStatus: response.allDoctors.userStatus
            };
            response.allDoctors.userdata.forEach((item: any) => {
                  if (item.fieldKey === "dateOfBirth") {
                        userData.dob = longDateGenerator(new Date(item.fieldValue));
                  }
            });
            response.allDoctors.user_form_data.forEach((item: any) => {
                  if (item.formFieldKey === "specialtyDepartment") {
                        userData.specialty = item.formFieldValue;
                  }
            });
            setUserInfo(userData);
      }

      async function handleSeeAll(type: string) {
            setShowMedia(type);
            const arr: any = [];
            // if (type === "images") {
            const response = await getImages(room, type);
            if (response.success) {
                  response.data.forEach((item: any) => {
                        item.source = item.message_document?.source;
                        item.fileName = item.message_document?.fileName;
                        item.ext = item.message_document?.extension;
                        item.size = item.message_document?.size;
                        arr.push(item);
                  });
            }
            setMedia(arr);
      }

      function handleSearch(e: any) {
            setSearch(e.target.value);
            const values = chatUsersCopy.filter((items: any) =>
                  Object.values(items)
                        .join(" ")
                        .toLowerCase()
                        .match(e.target.value.toLowerCase())
            );
            setChatUsers(values);
      }

      async function clearChats() {
            const response = await clearChat(room);
            if (response.success) {
                  getAllMessages();
            }
      }

      function setupCurrentUser(user: any) {
            setRoom(user.room);
            setCurrentChat(user);
            getPatientInfo(user.receiver);
            document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Messgaes - ${user.name}`;
      }

      return (
            <>
                  {!loading ?
                        <Row>
                              <div className="panel_top_section border-0 px-4 position-relative pb-0 d-flex align-items-center justify-content-between">
                                    <h1>Messages</h1>
                                    <div className="grid_icons d-lg-flex gap-1 align-items-center float-end">
                                          <button
                                                type="button"
                                                className="btn_gradian full_width"
                                                onClick={() => navigate(`/${currentUser}/secure-messages`)}
                                          >
                                                Secure Messages
                                          </button>
                                    </div>
                              </div>
                              <Col className="mt-3 mb-4">
                                    <div className="message_box col-sm-12 py-4 pt-0 px-lg-2 ">
                                          <div className="row m-0">
                                                <div className={`col-xl-4 p-1 pb-2 ${showChatWindow && "d-none d-xl-block"}`}>
                                                      <div className="inner_location float-start w-100 bg-white box_shadow p-2 pt-4 radius_15">
                                                            {/* search */}
                                                            <div className="d-flex align-items-center gap-2 px-2 mb-3">
                                                                  <div className="input-group bg_gray_500 rounded">
                                                                        <span
                                                                              className="input-group-text bg-transparent border-0"
                                                                              id="inputGroupPrepend"
                                                                        >
                                                                              <i className="bi bi-search"></i>
                                                                        </span>
                                                                        <input
                                                                              type="text"
                                                                              className="form-control bg-transparent border-0 shadow-none pl-0"
                                                                              placeholder="Search..."
                                                                              id="search"
                                                                              onChange={handleSearch}
                                                                              value={search}
                                                                        />
                                                                        <div className="invalid-feedback">
                                                                              Please choose a username.
                                                                        </div>
                                                                  </div>
                                                                  <div className="add_button">
                                                                        <button
                                                                              className="border-0 fs-5 py-1 px-3 rounded bg_green text-white btn btn-primary"
                                                                              type="button"
                                                                              onClick={() => setShowPopup(true)}
                                                                              data-bs-toggle="modal"
                                                                              data-bs-target="#myModal"
                                                                        >
                                                                              <i className="bi bi-plus-lg" />
                                                                        </button>
                                                                  </div>
                                                            </div>
                                                            {/* user */}
                                                            <div className="left_user custom_scroll">
                                                                  {chatUsers.length > 0 ? (
                                                                        <>
                                                                              {chatUsers.map((item: any) => {
                                                                                    return (
                                                                                          <div
                                                                                                className="user_chat float-start w-100 p-2 py-0 px-3 rounded clickable"
                                                                                                onClick={() => {
                                                                                                      // setRoom(item.room);
                                                                                                      // setCurrentChat(item);
                                                                                                      // getPatientInfo(item.receiver);
                                                                                                      setupCurrentUser(item);
                                                                                                      setChatUsers(
                                                                                                            chatUsers.map((user: any) => {
                                                                                                                  const obj = { ...user };
                                                                                                                  if (item.room == user.room) {
                                                                                                                        obj.unreadCount = 0;
                                                                                                                  }
                                                                                                                  return obj;
                                                                                                            })
                                                                                                      );
                                                                                                      setShowChatWindow(true);
                                                                                                }}
                                                                                          >
                                                                                                <div className="float-start border-bottom w-100 py-3 chat_group">
                                                                                                      <div className="user_chat_img float-start">
                                                                                                            <img
                                                                                                                  className="rounded"
                                                                                                                  src={(item.profile != 0 && item.profile != null) ? `${SOCKET_DOMAIN}/profile/${item.profile}` : user1}
                                                                                                                  alt="user"
                                                                                                            />
                                                                                                      </div>
                                                                                                      <div className="user_chat_detail px-3 float-start">
                                                                                                            <span className="d-block text_base poppins-600 dark_blue pb-1">
                                                                                                                  {item.name}
                                                                                                            </span>
                                                                                                            <p className="m-0 text_xs text_gray">
                                                                                                                  {item.lastMessage
                                                                                                                        ? item.lastMessageType === "text"
                                                                                                                              ? item.lastMessage
                                                                                                                              : item.lastMessageType
                                                                                                                        :
                                                                                                                        "Tap to initiate chat"
                                                                                                                  }
                                                                                                            </p>
                                                                                                      </div>
                                                                                                      <div className="float-end chat_count">
                                                                                                            <span className="chat_time d-block text-end text_gray">
                                                                                                                  {item.lastMessageTime}
                                                                                                            </span>
                                                                                                            {item.unreadCount != 0 &&
                                                                                                                  item.lastMessageFrom !=
                                                                                                                  getCurrentUser()?.id && (
                                                                                                                        <span className="count d-block float-end text_sm text-white text-center">
                                                                                                                              {item.unreadCount}
                                                                                                                        </span>
                                                                                                                  )}
                                                                                                      </div>
                                                                                                </div>
                                                                                          </div>
                                                                                    );
                                                                              })}
                                                                        </>
                                                                  ) : (
                                                                        <div style={{ textAlign: "center" }}> No data found.</div>
                                                                  )}
                                                            </div>
                                                            {/* enduser */}
                                                      </div>
                                                </div>
                                                <div className={`row m-0 col-xl-8 p-1 px-2 pb-2 ${!showChatWindow && "d-none d-xl-block"}`}>
                                                      <div className="inner_location p-0 float-start w-100 bg-white box_shadow radius_15 d-flex">
                                                            {room ? (
                                                                  <>
                                                                        <div
                                                                              className={`${showPatientInfo ? "col-8 d-none d-xl-block" : "col-12"
                                                                                    }  border-end float-start  p-4 px-0 pt-3 pb-0 position-relative`}
                                                                        >
                                                                              {/* chat-top */}
                                                                              <div className="chat_top float-start  border-bottom col-12 col-xl-12  mb-4">
                                                                                    <div className="float-start w-100 py-3 px-4 pr-0 chat_group pt-0 pb-2">
                                                                                          <div
                                                                                                className="float-start me-2 mt-2"
                                                                                                onClick={() => setShowChatWindow(false)}
                                                                                          >
                                                                                                <i className="bi bi-arrow-left d-xl-none"></i>
                                                                                          </div>
                                                                                          <div className="user_chat_img float-start position-relative">
                                                                                                <img className="rounded"
                                                                                                      src={(currentChat.profile != 0 && currentChat.profile != null) ? `${SOCKET_DOMAIN}/profile/${currentChat.profile}` : user1}
                                                                                                      alt="user" />
                                                                                                <span className={`online1 position-absolute ${userInfo?.userStatus === "ONLINE" ? "online-bubble" : userInfo?.userStatus === "IDLE" ? "idle-bubble" : "offline-bubble"}`}></span>
                                                                                          </div>
                                                                                          <div className="user_chat_detail px-3 float-start">
                                                                                                <span
                                                                                                      className="d-block text_base poppins-600 dark_blue clickable"
                                                                                                      onClick={() => {
                                                                                                            setShowPatientInfo(!showPatientInfo);
                                                                                                      }}
                                                                                                >
                                                                                                      {currentChat.name}
                                                                                                </span>

                                                                                                <p className={`m-0 text_15 ${userInfo.userStatus === "ONLINE" ? "textgreen" : userInfo?.userStatus === "IDLE" ? "idle-text" : "offline-text"} poppins-600`}>
                                                                                                      {typing ?
                                                                                                            "Typing..."
                                                                                                            :
                                                                                                            userInfo?.userStatus
                                                                                                      }
                                                                                                </p>

                                                                                          </div>
                                                                                          <div className="three_dots float-end col-right  chat_tools">
                                                                                                {/*<button type="button">
                              <i className="bi bi-camera-video"></i>
                            </button>
                            <button type="button">
                              <i className="bi bi-telephone"></i>
                          </button>*/}
                                                                                                <button
                                                                                                      type="button"
                                                                                                // onClick={() => setShowDeleteModal(true)}
                                                                                                // onClick={sendOneNotification}
                                                                                                >
                                                                                                      <i className="bi bi-three-dots-vertical"></i>
                                                                                                </button>
                                                                                                <div
                                                                                                      className="drop_chat drop_list m-0 p-0 bg-white p-2  text-left clickable"
                                                                                                      onClick={() => setShowDeleteModal(true)}
                                                                                                >
                                                                                                      Clear Your Chats
                                                                                                </div>
                                                                                          </div>
                                                                                    </div>
                                                                              </div>
                                                                              {/* chat-message */}

                                                                              <div
                                                                                    className="chat_message custom_scroll float-end w-100 px-2"
                                                                                    ref={chatWindowRef}
                                                                              >
                                                                                    <div className="read-messages">
                                                                                          {Object.keys(chats).map((key: any) => {
                                                                                                return (
                                                                                                      <>
                                                                                                            <div
                                                                                                                  className="d-flex justify-content-center"
                                                                                                                  style={{ clear: "both" }}
                                                                                                            >
                                                                                                                  {longDateGenerator(key)}
                                                                                                            </div>
                                                                                                            {chats[key].map((item: any) => {
                                                                                                                  return (
                                                                                                                        <div
                                                                                                                              className={`col-10 ${item.pos == "0"
                                                                                                                                    ? "float-end"
                                                                                                                                    : "float-start"
                                                                                                                                    } `}
                                                                                                                        >
                                                                                                                              <div
                                                                                                                                    className={`${item.pos == "0"
                                                                                                                                          ? "chat_send  float-end bg_light_green"
                                                                                                                                          : "float-start  chat_receive  bg_gray_500"
                                                                                                                                          } px-3 py-2 my-1`}
                                                                                                                              >
                                                                                                                                    {item.messageType === "text" ? (
                                                                                                                                          <p className="m-0 text_14 dark_blue">
                                                                                                                                                {item.message}
                                                                                                                                          </p>
                                                                                                                                    ) : item.messageType === "image" ? (
                                                                                                                                          <img
                                                                                                                                                src={item.source}
                                                                                                                                                alt="image"
                                                                                                                                                style={{
                                                                                                                                                      width: "100px",
                                                                                                                                                      height: "100px",
                                                                                                                                                }}
                                                                                                                                                className="d-block clickable"
                                                                                                                                                onClick={() => {
                                                                                                                                                      setPreviewData(item);
                                                                                                                                                      setShowPreview(true);
                                                                                                                                                }}
                                                                                                                                          />
                                                                                                                                    ) : item.messageType === "file" ? (
                                                                                                                                          <FileRenderer
                                                                                                                                                fileData={item}
                                                                                                                                                pos={item.pos}
                                                                                                                                          />
                                                                                                                                    ) : (
                                                                                                                                          <CustomAudioPlayer
                                                                                                                                                source={item.source}
                                                                                                                                          />
                                                                                                                                    )}
                                                                                                                                    <span className="float-end px-1 text_sm text_gray">
                                                                                                                                          {item.sentTime}
                                                                                                                                    </span>
                                                                                                                                    {item.pos == "0" && (
                                                                                                                                          <span
                                                                                                                                                className={`check_doubt d-block float-end text_14 ${item.readStatus == 2 &&
                                                                                                                                                      "textgreen"
                                                                                                                                                      }  text-center`}
                                                                                                                                          >
                                                                                                                                                {item.readStatus == 1 ||
                                                                                                                                                      item.readStatus == 2 ? (
                                                                                                                                                      <i className="bi bi-check2-all"></i>
                                                                                                                                                ) : (
                                                                                                                                                      <i className="bi bi-check2"></i>
                                                                                                                                                )}
                                                                                                                                          </span>
                                                                                                                                    )}
                                                                                                                              </div>
                                                                                                                        </div>
                                                                                                                  );
                                                                                                            })}
                                                                                                      </>
                                                                                                );
                                                                                          })}
                                                                                    </div>
                                                                                    {unreadCount > 0 && (
                                                                                          <div
                                                                                                className="unread-messages"
                                                                                                style={{ clear: "both" }}
                                                                                                id="unread-messages"
                                                                                                ref={unreadMessagesRef}
                                                                                          >
                                                                                                <div
                                                                                                      style={{ textAlign: "center", fontSize: "12px" }}
                                                                                                >
                                                                                                      {unreadCount} Unread Messages
                                                                                                </div>
                                                                                                {Object.keys(unReadMessages).map((key: any) => {
                                                                                                      return (
                                                                                                            <>
                                                                                                                  {unReadMessages[key].map((item: any) => {
                                                                                                                        return (
                                                                                                                              <div
                                                                                                                                    className={`col-10 ${item.pos == "0"
                                                                                                                                          ? "float-end"
                                                                                                                                          : "float-start"
                                                                                                                                          } `}
                                                                                                                              >
                                                                                                                                    <div
                                                                                                                                          className={`${item.pos == "0"
                                                                                                                                                ? "chat_send  float-end bg_light_green"
                                                                                                                                                : "float-start  chat_receive  bg_gray_500"
                                                                                                                                                } px-3 py-2 my-1`}
                                                                                                                                    >
                                                                                                                                          {item.messageType === "text" ? (
                                                                                                                                                <p className="m-0 text_14 dark_blue">
                                                                                                                                                      {item.message}
                                                                                                                                                </p>
                                                                                                                                          ) : item.messageType === "image" ? (
                                                                                                                                                <img
                                                                                                                                                      src={item.source}
                                                                                                                                                      alt="image"
                                                                                                                                                      style={{
                                                                                                                                                            width: "100px",
                                                                                                                                                            height: "100px",
                                                                                                                                                      }}
                                                                                                                                                      className="d-block clickable"
                                                                                                                                                      onClick={() => {
                                                                                                                                                            setPreviewData(item);
                                                                                                                                                            setShowPreview(true);
                                                                                                                                                      }}
                                                                                                                                                />
                                                                                                                                          ) : item.messageType === "file" ? (
                                                                                                                                                <FileRenderer
                                                                                                                                                      fileData={item}
                                                                                                                                                      pos={item.pos}
                                                                                                                                                />
                                                                                                                                          ) : (
                                                                                                                                                <CustomAudioPlayer
                                                                                                                                                      source={item.source}
                                                                                                                                                />
                                                                                                                                          )}
                                                                                                                                          <span className="float-end px-1 text_sm text_gray">
                                                                                                                                                {item.sentTime}
                                                                                                                                          </span>
                                                                                                                                          {item.pos == "0" && (
                                                                                                                                                <span
                                                                                                                                                      className={`check_doubt d-block float-end text_14 ${item.readStatus == 2 &&
                                                                                                                                                            "textgreen"
                                                                                                                                                            }  text-center`}
                                                                                                                                                >
                                                                                                                                                      {item.readStatus == 1 ||
                                                                                                                                                            item.readStatus == 2 ? (
                                                                                                                                                            <i className="bi bi-check2-all"></i>
                                                                                                                                                      ) : (
                                                                                                                                                            <i className="bi bi-check2"></i>
                                                                                                                                                      )}
                                                                                                                                                </span>
                                                                                                                                          )}
                                                                                                                                    </div>
                                                                                                                              </div>
                                                                                                                        );
                                                                                                                  })}
                                                                                                            </>
                                                                                                      );
                                                                                                })}
                                                                                          </div>
                                                                                    )}
                                                                              </div>
                                                                              {/* type-message */}
                                                                              <div className="type_message mt-3 row w-100 pe-3 ps-1 py-3 border-top m-0">
                                                                                    {!isRecording ? (
                                                                                          <div className="row w-100">
                                                                                                <div className="col-9 px-2 type_message_input">
                                                                                                      <input
                                                                                                            className="border-0 bg_gray_500 text_gray w-100 py-2 px-4 rounded-5 "
                                                                                                            type="text"
                                                                                                            id="type_message"
                                                                                                            placeholder="Type a message"
                                                                                                            value={message}
                                                                                                            onChange={(e) => {
                                                                                                                  setMessage(e.target.value);
                                                                                                                  socket.emit("typing", {
                                                                                                                        fromId: getCurrentUser().id,
                                                                                                                        roomId: room,
                                                                                                                  });
                                                                                                            }}
                                                                                                            onKeyDown={(e) => {
                                                                                                                  if (e.key === "Enter" && !e.shiftKey) {
                                                                                                                        e.preventDefault();
                                                                                                                        handleSendMessage();
                                                                                                                  }
                                                                                                            }}
                                                                                                      />
                                                                                                </div>
                                                                                                <div className="col-3 p-0 fs-4 d-flex justify-content-center other_icons text-end">
                                                                                                      <button
                                                                                                            className="border-0 bg-white textgreen"
                                                                                                            type="button"
                                                                                                      >
                                                                                                            <input
                                                                                                                  hidden
                                                                                                                  type="file"
                                                                                                                  onChange={handleFileUpload}
                                                                                                                  id="upload"
                                                                                                            // multiple
                                                                                                            />
                                                                                                            <label className="" htmlFor="upload">
                                                                                                                  <span className="clickable">
                                                                                                                        <i className="bi bi-paperclip" />
                                                                                                                  </span>
                                                                                                            </label>
                                                                                                      </button>
                                                                                                      <button
                                                                                                            className="border-0 fs-4 textgreen bg-white"
                                                                                                            type="button"
                                                                                                            onClick={() => {
                                                                                                                  startRecording();
                                                                                                            }}
                                                                                                      >
                                                                                                            <i className="bi bi-mic-fill"></i>
                                                                                                      </button>
                                                                                                      {/* <RecordCheck
                                                                                    recording={recording}
                                                                                    handleRecording={(recording: boolean) => setRecording(recording)}
                                                                              /> */}
                                                                                                      <button
                                                                                                            className="border-0 bg-white textgreen"
                                                                                                            type="button"
                                                                                                            onClick={() => handleSendMessage()}
                                                                                                      >
                                                                                                            <i className="bi bi-telegram" />
                                                                                                      </button>
                                                                                                </div>
                                                                                          </div>
                                                                                    ) : (
                                                                                          <div className="d-flex justify-content-end align-items-center">
                                                                                                <button
                                                                                                      type="button"
                                                                                                      className="border-0 fs-4 textgreen bg-white"
                                                                                                      onClick={() => {
                                                                                                            setDeleteRecording(true);
                                                                                                            stopRecording();
                                                                                                      }}
                                                                                                >
                                                                                                      <i className="bi bi-trash"></i>
                                                                                                </button>
                                                                                                <p className="text_xs">
                                                                                                      <span className="red_dot d-inline-block bg-danger p-1 border-0 rounded-circle"></span>{" "}
                                                                                                      Recording
                                                                                                </p>
                                                                                                <p className="px-2">{getTime(recordingTime)}</p>
                                                                                                {/* <div className='px-3'>
                                                                                          <p className="text_xs"><span className="red_dot d-inline-block bg-danger p-1 border-0 rounded-circle"></span> Recording</p>
                                                                                          <p className="text_xs">{getTime(recordingTime)}</p>
                                                                                    </div> */}
                                                                                                <div>
                                                                                                      {isPaused ? (
                                                                                                            <button
                                                                                                                  className="border-0 textgreen fs-4 bg-white"
                                                                                                                  type="button"
                                                                                                                  onClick={() => {
                                                                                                                        togglePauseResume();
                                                                                                                  }}
                                                                                                            >
                                                                                                                  <i className="bi bi-play" />
                                                                                                            </button>
                                                                                                      ) : (
                                                                                                            <button
                                                                                                                  className="border-0 textgreen fs-4 bg-white"
                                                                                                                  type="button"
                                                                                                                  onClick={() => {
                                                                                                                        togglePauseResume();
                                                                                                                  }}
                                                                                                            >
                                                                                                                  <i className="bi bi-pause" />
                                                                                                            </button>
                                                                                                      )}
                                                                                                </div>
                                                                                                <button
                                                                                                      className="border-0 textgreen fs-4 bg-white"
                                                                                                      type="button"
                                                                                                      onClick={() => {
                                                                                                            setDeleteRecording(false);
                                                                                                            stopRecording();
                                                                                                      }}
                                                                                                >
                                                                                                      <i className="bi bi-telegram" />
                                                                                                </button>
                                                                                          </div>
                                                                                    )}
                                                                              </div>
                                                                        </div>
                                                                        {showPatientInfo && (
                                                                              <div
                                                                                    className={`col-12 col-xl-4 float-start px-2  p-0 pb-1`}
                                                                              >
                                                                                    <div className="patient_info  py-4">
                                                                                          <span
                                                                                                className="close py-4 border-bottom  fs-5 d-block dark_blue poppins-600 pt-2 clickable"

                                                                                          >
                                                                                                <i onClick={(e: any) => setShowPatientInfo(false)}
                                                                                                      className="bi bi-x-circle-fill text_gray px-2" />
                                                                                                {currentChat.role} Info
                                                                                          </span>
                                                                                    </div>
                                                                                    <div className="chat_height custom_scroll">
                                                                                          <div className="patient_info_profile ">
                                                                                                <div className="patietnt_img text-center position-relative">
                                                                                                      <img
                                                                                                            src={(currentChat.profile != 0 && currentChat.profile != null) ? `${SOCKET_DOMAIN}/profile/${currentChat.profile}` : user1}
                                                                                                            alt="patient" />
                                                                                                      <span className={`online position-absolute ${userInfo?.userStatus === "ONLINE" ? "online-bubble" : userInfo?.userStatus === "IDLE" ? "idle-bubble" : "offline-bubble"}`}></span>
                                                                                                </div>
                                                                                                <p className="fs-5 d-block dark_blue poppins-600 m-0 mt-3 text-center">
                                                                                                      {userInfo?.name}
                                                                                                </p>
                                                                                                <span className="d-block text_gray text-center">
                                                                                                      {userInfo?.email}
                                                                                                </span>
                                                                                                {currentChat.role === "Patient" ?
                                                                                                      <div className="row_1 mt-2">
                                                                                                            <span className="d-block text_sm text_gray ">
                                                                                                                  Birth Date
                                                                                                            </span>
                                                                                                            <p className="m-0 poppins-500 dark_blue fs-6">
                                                                                                                  {userInfo?.dob}
                                                                                                            </p>
                                                                                                            <span className="d-block text_sm text_gray mt-1">
                                                                                                                  Diagnosis
                                                                                                            </span>
                                                                                                            <p className="m-0 poppins-500 dark_blue fs-6">
                                                                                                                  Asthma
                                                                                                            </p>
                                                                                                      </div>
                                                                                                      :
                                                                                                      <div className="row_1 mt-2">
                                                                                                            <span className="d-block text_sm text_gray ">
                                                                                                                  Specialty
                                                                                                            </span>
                                                                                                            <p className="m-0 poppins-500 dark_blue fs-6">
                                                                                                                  {userInfo?.specialty}
                                                                                                            </p>
                                                                                                            <span className="d-block text_sm text_gray mt-1">
                                                                                                                  Phone Number
                                                                                                            </span>
                                                                                                            <p className="m-0 poppins-500 dark_blue fs-6">
                                                                                                                  {userInfo?.phoneNumber}
                                                                                                            </p>
                                                                                                      </div>
                                                                                                }
                                                                                          </div>
                                                                                          {/* Shared Photos */}
                                                                                          {images.length > 0 &&
                                                                                                <>
                                                                                                      <div className="share_post py-3">
                                                                                                            <h3 className="text_gray poppins-600 fs-6 d-inline-block m-0">
                                                                                                                  Shared Photos
                                                                                                            </h3>
                                                                                                            <a
                                                                                                                  className="textgreen poppins-500 float-end text_14 clickable"
                                                                                                                  onClick={() => handleSeeAll("images")}
                                                                                                            >
                                                                                                                  See All
                                                                                                            </a>
                                                                                                      </div>
                                                                                                      <div className="post_image">
                                                                                                            {images.map((item: any) => {
                                                                                                                  return (
                                                                                                                        <img
                                                                                                                              src={item.source}
                                                                                                                              alt="image"
                                                                                                                              style={{ width: "50px", height: "50px" }}
                                                                                                                              className="d-block clickable"
                                                                                                                              onClick={() => {
                                                                                                                                    setPreviewData(item);
                                                                                                                                    setShowPreview(true);
                                                                                                                              }}
                                                                                                                        />
                                                                                                                  );
                                                                                                            })}
                                                                                                            {/* <span className="d-inline-block p-1"><img src={post1} alt="post" /></span>
                                                                              <span className="d-inline-block p-1"><img src={post2} alt="post" /></span>
                                                                              <span className="d-inline-block p-1"><img src={post3} alt="post" /></span>
                                                                              <span className="d-inline-block p-1"><img src={post4} alt="post" /></span> */}
                                                                                                      </div>
                                                                                                </>
                                                                                          }
                                                                                          {/* Shared file */}
                                                                                          {documents.length > 0 &&
                                                                                                <>
                                                                                                      <div className="share_post py-3">
                                                                                                            <h3 className="text_gray poppins-600 fs-6 d-inline-block m-0">
                                                                                                                  Shared Files
                                                                                                            </h3>
                                                                                                            <a
                                                                                                                  className="textgreen poppins-500 float-end text_14 clickable"
                                                                                                                  onClick={() => handleSeeAll("files")}
                                                                                                            >
                                                                                                                  See All
                                                                                                            </a>
                                                                                                      </div>
                                                                                                      {documents.map((item: any) => {
                                                                                                            return (
                                                                                                                  <div className="bg_gray_500 d-inline-block py-2 w-100 rounded-3 px-2 text_14 my-1">
                                                                                                                        <span className="fs-5 text_gray">
                                                                                                                              <i className="bi bi-file-earmark-text"></i>
                                                                                                                        </span>{" "}
                                                                                                                        {item.fileName}
                                                                                                                  </div>
                                                                                                            );
                                                                                                      })}
                                                                                                </>
                                                                                          }
                                                                                          {/* <div className="post_pdf ">
                                                                              <div className="bg_gray_500 d-inline-block py-2 w-100 rounded-3 px-2 text_14 my-1">
                                                                                    <span className="fs-5 text_gray"><i className="bi bi-file-earmark-text"></i></span> Allergy Test Result.pdf
                                                                              </div>
                                                                        </div>
                                                                        <div className="bg_gray_500 d-inline-block py-2 w-100 rounded-3 px-2 text_14 my-1">
                                                                              <span className="fs-5 text_gray"><i className="bi bi-file-earmark-text"></i></span> Checkup Result.pdf
                                                                        </div>

                                                                        <div className="bg_gray_500 d-inline-block py-2 w-100 rounded-3 px-2 text_14 my-1">
                                                                              <span className="fs-5 text_gray"><i className="bi bi-file-earmark-text"></i></span> Prescription.pdf
                                                                        </div> */}
                                                                                    </div>
                                                                              </div>
                                                                        )}
                                                                  </>
                                                            ) : (
                                                                  <div style={{ margin: "auto", textAlign: "center" }}>
                                                                        Select a chat to continue conversation
                                                                  </div>
                                                            )}
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </Col>
                              <UsersModal
                                    show={showPopup}
                                    handleClose={() => setShowPopup(false)}
                                    data={users}
                                    handleClick={handleClick}
                                    chatUsers={true}
                                    dataCopy = {usersCopy}
                                    handleTabs = {(users: any) => setUsers(users)}
                              />
                              <MediaModal
                                    show={showMedia}
                                    handleClose={() => setShowMedia("")}
                                    data={media}
                              // handleClick={handleClick}
                              />
                              <DeleteModal
                                    show={showDeleteModal}
                                    handleYes={() => {
                                          clearChats()
                                          setShowDeleteModal(false);
                                    }}
                                    handleClose={() => setShowDeleteModal(false)}
                                    body="Do you really want to clear all chats?"
                                    head="Clear Chat"
                                    buttonName="Proceed"
                              />
                              <ImagePreview
                                    data={previewData}
                                    show={showPreview}
                                    handleClose={() => {
                                          setShowPreview(false);
                                          setPreviewData({});
                                    }}
                              />
                        </Row>
                        :
                        <div className='m-5 justify-content-center d-flex'>
                              <Spinner />
                        </div>
                  }
            </>
      );
};

export default Messages;