import {
      Row,
      Col
} from "reactstrap";
import { useState, useEffect, useContext } from "react";
import { getAppointmentInfo, requestAppointmentCancellation } from "../../../services/scheduleappointment-service";
import { useParams, useNavigate } from "react-router-dom";
import { getOneDoctor } from "../../../services/doctor-service";
import { longDateGenerator } from "../../../utils/utils";
import { getOneOrder } from "../../../services/billing-service";
import { getOnePatientRecords } from "../../../services/medicalrecord-service";
import { SOCKET_DOMAIN } from "../../../config/constants";
import { RoleContext, AlertContext } from "../../../utils/contexts/RoleContext";
import DeleteModal from "../../../components/DeleteModal";
import { getCurrentUser } from "../../../services/auth.service";
import { getOneInvoice } from "../../../services/billing-service";
import constants from "../../../config/constants";
import { capitalizeFirstLetter } from "../../../utils/utils";


const AppointmentDetail = () => {
      const [appointmentDetails, setAppointmentDetails] = useState<any>({});
      const [doctorDetails, setDoctorDetails] = useState<any>({});
      const [billingDetails, setBillingDetails] = useState<any>({});
      const [records, setRecords] = useState([]);
      const [showDeleteModal, setShowDeleteModal] = useState(false);
      const [showCancellation, setShowCancellation] = useState(false);
      const [appointmentDuration, setAppointmentDuration] = useState("");

      const { appointmentId }: any = useParams();
      const navigate = useNavigate();
      const currentUser = useContext(RoleContext);
      const toast: any = useContext(AlertContext)

      useEffect(() => {
            fetchAppointmentInfo();
            fetchMedicalRecords();
            document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - View Appointment - ${appointmentId}`;
      }, [currentUser]);

      async function fetchMedicalRecords() {
            const response = await getOnePatientRecords();
            if (response?.success) {
                  setRecords(response?.data?.map((item: any) => {
                        const splittedDocument = item?.documents?.split("/");
                        item.fileName = splittedDocument[splittedDocument?.length - 1]
                        return item;
                  }));
            }
      }

      async function fetchAppointmentInfo() {
            const response = await getAppointmentInfo(appointmentId);
            if (response?.success) {
                  fetchDoctorInfo(response.data.appointmentDetails.doctorId);
                  fetchOneOrder(response.data.appointmentDetails.orderId);
                  let dateObject = new Date(response.data.appointmentDetails.date);
                  const time = response.data.appointmentDetails.time;
                  const splittedTime = time.split(":");
                  dateObject.setHours(Number(splittedTime[0]));
                  dateObject.setMinutes(Number(splittedTime[1]));
                  const currentDate = new Date().getTime();
                  if (currentDate < dateObject.getTime() && response.data.appointmentDetails.status == 1 && response.data.cancelRequested === false) {
                        setShowCancellation(true);
                  } else {
                        setShowCancellation(false);
                  }
                  response.data.appointmentDetails?.user.user_form_data?.forEach((item: any) => {
                        if (item.formFieldKey === "insuranceProvider") {
                              response.data.appointmentDetails.insuranceProvider = item.formFieldValue;
                        }
                  })
                  setAppointmentDetails(response.data.appointmentDetails);
                  setAppointmentDuration(response.data.doctorDuration)
            }
      }

      async function fetchDoctorInfo(doctorId: string) {
            const response = await getOneDoctor(doctorId);
            if (response?.success) {
                  const itemCopy = { ...response.allDoctors };
                  response.allDoctors.userdata.forEach((element: any) => {
                        if (element.fieldKey === "address") {
                              itemCopy.address = JSON.parse(element.fieldValue);
                        } else if (element.fieldKey === "city") {
                              itemCopy.city = JSON.parse(element.fieldValue);
                        }
                  });
                  response.allDoctors.user_form_data.forEach((element: any) => {
                        if (element.formFieldKey === "specialtyDepartment") {
                              itemCopy.specialty = element.formFieldValue;
                        }
                  });
                  setDoctorDetails(itemCopy);
            }
      }

      async function fetchOneOrder(orderId: number) {
            const response = await getOneOrder(orderId);
            if (response?.success) {
                  setBillingDetails(response.data)
            }
      }

      function handleDownload(item: any) {
            const source = `${SOCKET_DOMAIN}/medicalrecord/${getCurrentUser().id}/${item.fileName}`
            const link = document.createElement('a');
            link.href = source;
            link.download = item.fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
      }

      async function handleCancellation() {
            const response = await requestAppointmentCancellation(appointmentDetails.id, appointmentDetails.doctorId, appointmentDetails.patientId);
            if (response.success) {
                  fetchAppointmentInfo();
                  toast.setShowToast(response.data, "success");
            } else {
                  toast.setShowToast("Something went wrong on our side. Please try again later.", "danger");
            }
      }

      async function getInvoice(item: any) {
            const response = await getOneInvoice(item.invoiceId);
            if (response?.success) {
              navigate(`/patient/payment/${response.data?.maskedInvoice}`)
            } else {
              alert("Something went wrong on our side. Please try again later.")
            }
          }
        

      return (
            <Row>
                  <div className="panel_top_section position-relative">
                        <h1 className="clickable" onClick={() => navigate(-1)}>Appointments</h1> <span className="textgreen poppins-500"><i className="bi bi-chevron-right"></i> Appointment Detail </span>
                        <div className="grid_icons d-lg-flex gap-1 align-items-center float-end">
                              <button
                                    type="button"
                                    className="btn_gradian full_width"
                                    onClick={() => navigate(`/${currentUser}/patient-appointment`)}
                              >
                                    + Schedule an Appointment
                              </button>
                              {showCancellation &&
                                    <button
                                          type="button"
                                          className="full_width border-0 charge_patient bg_dark_blue  poppins-500 text-white p-2 rounded"
                                          onClick={() => setShowDeleteModal(true)}
                                    >
                                          Request Cancellation
                                    </button>
                              }
                              {/* <button type="button" className="dark_btn text-white py-2 rounded-3 mx-1 px-2"> <span className="px-2 pl-0"><i className="bi bi-pen"></i></span>Edit</button>
                <div className="grid_list_icons  d-inline-block align-items-center gap-2">
                    <button type="button" className="delet_patiens"> <i className="bi bi-trash"></i> </button>
                 </div> */}
                        </div>
                  </div>
                  <Col className="mt-3 mb-4">
                        <div className="add_location col-sm-12 py-4 px-lg-2 mt-2">
                              <div className="row m-0">


                                    {/* Patient Information: */}
                                    {/* <div className="col-xl-4 p-1 pb-2">
                          <div className="inner_location float-start w-100 bg-white box_shadow p-4 pt-4 radius_15">
                               <h4 className="dark_blue poppins-600 fs-5 mb-3 x-1">Patient Information:</h4>
                               <div className="pat_group py-2 d-flex justify-content-between">
                                      <div className="row_1 col-sm-4 px-1">
                                            <span className="d-block text_sm text_gray ">Full Name</span>
                                            <p className="m-0 poppins-500 dark_blue fs-6">Lucy Black</p>
                                      </div>
                                      <div className="row_1 col-sm-5 px-1">
                                            <span className="d-block text_sm text_gray ">Birth Date</span>
                                            <p className="m-0 poppins-500 dark_blue fs-6">03 March 2001 </p>
                                      </div>
                                      <div className="row_1 col-sm-3 px-1">
                                            <span className="d-block text_sm text_gray ">Gender</span>
                                            <p className="m-0 poppins-500 dark_blue fs-6">Female</p>
                                      </div>
                                 </div>
                                 <div className="pat_group  py-2 d-flex justify-content-between">
                                      <div className="row_1 col-sm-4 px-1">
                                            <span className="d-block text_sm text_gray ">City</span>
                                            <p className="m-0 poppins-500 dark_blue fs-6">Dallas</p>
                                      </div>
                                      <div className="row_1 col-sm-5 px-1">
                                            <span className="d-block text_sm text_gray ">Address</span>
                                            <p className="m-0 poppins-500 dark_blue fs-6">1234 Elm Street</p>
                                      </div>
                                      <div className="row_1 col-sm-3 px-1 ">
                                            <span className="d-block text_sm text_gray ">ZIP Code</span>
                                            <p className="m-0 poppins-500 dark_blue fs-6">75202</p>
                                      </div>
                                 </div>
                                 <div className="pat_group  py-2 d-flex justify-content-between">
                                      <div className="row_1 px-1 px-lg-0 ">
                                            <span className="d-block text_sm text_gray ">Phone</span>
                                            <p className="m-0 poppins-500 dark_blue fs-6">12345678</p>
                                      </div>
                                      <div className="row_1 px-1 px-lg-0">
                                            <span className="d-block text_sm text_gray ">Email</span>
                                            <p className="m-0 poppins-500 dark_blue fs-6 text-break ">lucy.black@example.com</p>
                                      </div>
                                     
                                 </div>
                          </div>
                    </div>  */}
                                    {/* Appointment Details:: */}
                                    <div className="col-xl-4 p-1 pb-2">
                                          <div className="inner_location float-start w-100 bg-white box_shadow p-4 pt-4 radius_15">
                                                <h4 className="dark_blue poppins-600 fs-5 mb-3 px-1">Appointment Details:</h4>
                                                <div className="pat_group py-2 d-flex justify-content-between">
                                                      <div className="row_1 col-sm-6 px-1 ">
                                                            <span className="d-block text_sm text_gray ">Appointment Date</span>
                                                            <p className="m-0 poppins-500 dark_blue fs-6">{longDateGenerator(appointmentDetails.date)}</p>
                                                      </div>
                                                      <div className="row_1 col-sm-6 px-1">
                                                            <span className="d-block text_sm text_gray ">Appointment Time</span>
                                                            <p className="m-0 poppins-500 dark_blue fs-6">{appointmentDetails.time}</p>
                                                      </div>

                                                </div>
                                                <div className="pat_group  py-2 d-flex justify-content-between">
                                                      <div className="row_1 col-sm-6 px-1">
                                                            <span className="d-block text_sm text_gray ">Treatment</span>
                                                            <p className="m-0 poppins-500 dark_blue fs-6">{appointmentDetails.reason}</p>
                                                      </div>
                                                      <div className="row_1 col-sm-6 px-1">
                                                            <span className="d-block text_sm text_gray ">Duration</span>
                                                            <p className="m-0 poppins-500 dark_blue fs-6">{appointmentDuration}</p>
                                                      </div>

                                                </div>
                                                <div className="pat_group  py-2 d-flex justify-content-between">
                                                      <div className="row_1">
                                                            <span className="d-block text_sm text_gray ">Location</span>
                                                            <p className="m-0 poppins-500 dark_blue fs-6">Texas Medical Center - Houston</p>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                                    {/* Provider Information */}
                                    <div className="col-xl-4 p-1 pb-2">
                                          <div className="inner_location float-start w-100 bg-white box_shadow p-4 pt-4 radius_15">
                                                <h4 className="dark_blue poppins-600 fs-5 mb-3 px-1">Provider Information:</h4>
                                                <div className="pat_group py-2 d-flex justify-content-between">
                                                      <div className="row_1 col-sm-5 px-1">
                                                            <span className="d-block text_sm text_gray ">Provider’s Name</span>
                                                            <p className="m-0 poppins-500 dark_blue fs-6">{doctorDetails?.firstName + " " + doctorDetails?.lastName}</p>
                                                      </div>
                                                      <div className="row_1 col-sm-7 px-1">
                                                            <span className="d-block text_sm text_gray ">Specialty or department </span>
                                                            <p className="m-0 poppins-500 dark_blue fs-6">{doctorDetails.specialty}</p>
                                                      </div>
                                                </div>
                                                <div className="pat_group  py-2 d-flex justify-content-between">
                                                      <div className="row_1 col-sm-4 px-1">
                                                                        <span className="d-block text_sm text_gray ">City</span>
                                                            <p className="m-0 poppins-500 dark_blue fs-6">{doctorDetails.city ? doctorDetails.city : "-"}</p>
                                                      </div>
                                                      <div className="row_1 col-sm-8 px-1">
                                                            <span className="d-block text_sm text_gray ">Address</span>
                                                            <p className="m-0 poppins-500 dark_blue fs-6">{doctorDetails.address}</p>
                                                      </div>
                                                </div>
                                                <div className="pat_group  py-2 d-flex justify-content-between">
                                                      <div className="row_1 col-sm-4 px-1">
                                                            <span className="d-block text_sm text_gray ">Phone</span>
                                                            <p className="m-0 poppins-500 dark_blue fs-6">{doctorDetails?.phoneNumber}</p>
                                                      </div>
                                                      <div className="row_1 col-sm-8 px-1">
                                                            <span className="d-block text_sm text_gray ">Email</span>
                                                            <p className="m-0 poppins-500 dark_blue fs-6 text-break">{doctorDetails?.email}</p>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                                    {/* Billing Information: */}
                                    <div className="col-xl-4 p-1 pb-2">
                                          <div className="inner_location bg_gradiant float-start w-100 bg-white box_shadow p-4 pt-4 radius_15">
                                                <h4 className="dark_blue poppins-600 fs-5 mb-3 px-1 text-white">Billing Information:</h4>
                                                <div className="pat_group py-2 d-flex justify-content-between">
                                                      <div className="row_1 col-sm-5 px-1">
                                                            <span className="d-block text_sm text_gray text-white  ">Billing status</span>
                                                            <p className="m-0 poppins-500 dark_blue fs-6 text-white">{billingDetails.orderStatus == 1 ? "Completed" : billingDetails.orderStatus == 2 ? "Failed" : "Pending"}</p>
                                                      </div>
                                                      <div className="row_1 col-sm-7 px-1">
                                                            <span className="d-block text_sm text_gray text-white">Payment Received</span>
                                                            <p className="m-0 poppins-500 dark_blue fs-6 text-white">${billingDetails.amountPaid}</p>
                                                      </div>
                                                </div>
                                                <div className="pat_group  py-2  justify-content-between">
                                                      <div className="row_1 col-sm-12 px-1 ">
                                                            <span className="d-block text_sm text_gray text-white">Insurance Details</span>
                                                            <p className="m-0 poppins-500 dark_blue fs-6 text-white">{appointmentDetails.insuranceProvider}</p>
                                                      </div>
                                                </div>
                                                <div className="pat_group  py-2  justify-content-between">
                                                      <div className="row_1 col-sm-12 px-1">
                                                            <span className="d-block text_sm text_gray text-white">Insurance Cover</span>
                                                            <p className="m-0 poppins-500 dark_blue fs-6 text-white">{appointmentDetails.insuranceCover ? appointmentDetails.insuranceCover : "-"}</p>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                                    {/* Attachments or Documents: */}
                                    <div className="col-xl-8 p-1 pb-2">
                                          <div className="inner_location float-start w-100 bg-white box_shadow p-4 pt-4 radius_15">
                                                <h4 className="dark_blue poppins-600 fs-5 mb-3 px-1 pb-2">Attachments or Documents:</h4>
                                                {records.length > 0 ?
                                                      <>
                                                            {records.map((item: any) => {
                                                                  return (
                                                <div className="attachment align-items-center row m-0">
                                                      <div className="col-lg-10 bg_light_green_500 px-3 py-2 my-1 rounded-3">
                                                            <span className=" textgreen fs-4"><i className="bi bi-file-earmark-text"></i></span>
                                                                                    <span className="d-inline-block px-1 px-lg-3 poppins-500">{item.fileName}</span>
                                                      </div>
                                                      <div className="upload_delete col-lg-2">
                                                                                    {/* <input id="upload" type="file" className="invisible end-0 position-absolute" /> */}
                                                                                    <label
                                                                                          className="px-1 d-inline-block clickable"
                                                                                          htmlFor="upload"
                                                                                          onClick={() => handleDownload(item)}
                                                                                    >
                                                                                          <i className="textgreen fs-4 bi bi-cloud-arrow-down"></i>
                                                                                    </label>
                                                                                    {/* <label className="px-1 d-inline-block textgreen"><i className="bi bi-trash fs-4"></i></label> */}
                                                      </div>
                                                </div>
                                                                  )
                                                            })}
                                                      </>
                                                      :
                                                      <div className="text-center mt-3">No data found.</div>
                                                }

                                          </div>
                                    </div>
                                    {/* application-status */}
                                    <div className="col-xl-4 p-1 pb-2">
                                          <div className="inner_location float-start w-100 bg-white box_shadow p-4 pt-4 radius_15">
                                                <h4 className="dark_blue poppins-600 fs-5 mb-3 ">Appointment Status:</h4>
                                                <div className="pat_group py-2 ">
                                                      <div className="row_1">
                                                            <span className="d-block text_sm text_gray ">Current status</span>
                                                            <p className="m-0 poppins-500 textgreen fs-6">{appointmentDetails.status == 0 ? "Pending" : appointmentDetails.status == 1 ? "Confirmed" : "Cancelled"}</p>
                                                      </div>
                                                      {((billingDetails.orderStatus == 0 || billingDetails.orderStatus == 11) && appointmentDetails.status == 1) &&
                                                            <div className="mt-2">
                                                                  <button 
                                                                  className="btn_gradian ms-0" 
                                                                  onClick={() => getInvoice(billingDetails)}
                                                                  >
                                                                        Pay Now
                                                                  </button>
                                                            </div>
                                                      }
                                                      {/* <div className="app_content text_15">
                                                            <span className="d-block text_sm text_gray ">Notes</span>
                                                            <p>The patient scheduled this appointment to discuss management strategies for their asthma symptoms. The patient has been experiencing increased shortness of breath and wheezing episodes over the past few weeks, especially during physical activity. Additionally, the patient wants to review their current medication regimen and explore potential adjustments to better control their asthma symptoms.</p>
                                                      </div> */}
                                                </div>

                                          </div>
                                    </div>
                                    {/* medical-history */}
                                    {/* <div className="col-xl-4 p-1 pb-2">
                          <div className="inner_location float-start w-100 bg-white box_shadow p-4 pt-4 radius_15">
                               <h4 className="dark_blue poppins-600 fs-5 mb-3 px-1">Medical History:</h4>
                               <div className="pat_group py-2 d-flex justify-content-between">
                                      <div className="row_1 col-sm-6 px-1">
                                            <span className="d-block text_sm text_gray ">Current Medications </span>
                                            <p className="m-0 poppins-500 dark_blue fs-6">Asmanex</p>
                                      </div>
                                      <div className="row_1 col-sm-6 px-1">
                                            <span className="d-block text_sm text_gray ">Family Medical History</span>
                                            <p className="m-0 poppins-500 dark_blue fs-6">Mother/ Asthma</p>
                                      </div>
                                 </div>
                                 <div className="pat_group  py-2 d-flex justify-content-between">
                                      <div className="row_1 col-sm-5 px-1">
                                            <span className="d-block text_sm text_gray ">Allergies</span>
                                            <p className="m-0 poppins-500 dark_blue fs-6">Smoke</p>
                                      </div>
                                      <div className="row_1 col-sm-7 px-1">
                                            <span className="d-block text_sm text_gray ">Past Medical Conditions</span>
                                            <p className="m-0 poppins-500 dark_blue fs-6">Rhinoplasty</p>
                                      </div>
                                 </div>
                                 <div className="pat_group  py-2 d-flex justify-content-between">
                                      <div className="row_1 treatment col-sm-12 px-1">
                                            <span className="d-block text_sm text_gray pb-1">Ongoing Treatments </span>
                                            <p className="m-0  dark_blue text_sm">inhaled corticosteroids (ICS) and long-acting beta-agonists (LABA), with a short-acting beta-agonist (SABA) for acute symptoms.</p>
                                      </div>
                                    
                                 </div>
                          </div>
                    </div> */}
                              </div>
                        </div>
                  </Col>
                  <DeleteModal
                        show={showDeleteModal}
                        handleYes={() => {
                              handleCancellation();
                              setShowDeleteModal(false)
                        }}
                        handleClose={() => setShowDeleteModal(false)}
                        body="Do you really want to cancel this appointment?"
                        head="Request Cancellation"
                        buttonName="Proceed"
                  />
            </Row>
      );
};

export default AppointmentDetail;
