import { createContext } from "react";

export const RoleContext:any= createContext('');
export const RoleUpdateContext:any= createContext('');
export const AlertContext:any= createContext('');
export const NotificationContext:any= createContext('');
export const AuthorizationContext:any= createContext('');
export const HospitalContext:any= createContext('');


