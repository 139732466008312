import Modal from 'react-bootstrap/Modal';

export default function MediaModal(props: any) {
    function handleDownload() {
        const link = document.createElement('a');
        link.target = "_blank"
        link.href = props.data?.source;
        link.download = props.data?.fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            dialogClassName="modal-90w "
            aria-labelledby="example-custom-modal-styling-title"
            contentClassName="width_100"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Media
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.show === "images" ?
                    <div className='d-flex justify-content-around'>
                        {props.data.map((item: any) => {
                            return (
                                <img
                                    src={item.source}
                                    style={{ height: "100px", width: "100px" }}
                                />
                            )
                        })}
                    </div>
                    :
                    <div>
                        {props.data.map((item: any) => {
                            return (
                                <div className="bg_gray_500 d-inline-block py-2 w-100 rounded-3 px-2 text_14 my-1">
                                    <span className="fs-5 text_gray"><i className="bi bi-file-earmark-text"></i></span> {item.fileName}
                                </div>
                            )
                        })}
                    </div>
                }
                {/* <img
                    src={props.data.source}
                    style={{ height: "450px", width: "450px" }}
                /> */}
            </Modal.Body>
            {/* <Modal.Footer>
                <button className="bg_gray_500 rounded border-0 py-2 px-4" onClick={handleDownload}>
                    <i className="bi bi-download"></i>
                </button>
            </Modal.Footer> */}
        </Modal>
    )

}