// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form_container {
    padding: 2em 5em;
}
.radio_container {
    display: flex;
    gap: 10px;
}
.form_input {
    width: 450px;
    height: 40px;
    border-radius: 5px;
    margin: 0;
    outline: unset;
    border: 1px solid #bab1b1;
}
.button_box {
    display: flex;
    align-items: center;
    justify-content: center;
}
.form_button {
    width: 200px;
    height: 40px;
    border-radius: 5px;
    margin: 10px;
    outline: unset;
    border: 1px solid #bab1b1;
    cursor: pointer;
    color: white;
    background-color: rgb(70, 70, 158);
}
.form_textarea {
    width: 450px;
    height: 100px;
    border-radius: 5px;
    margin: 0;
    outline: unset;
    border: 1px solid #bab1b1; 
}
.form_label {
   padding: 5px; 
}`, "",{"version":3,"sources":["webpack://./src/pages/styles/FormNew.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,SAAS;AACb;AACA;IACI,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,SAAS;IACT,cAAc;IACd,yBAAyB;AAC7B;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,cAAc;IACd,yBAAyB;IACzB,eAAe;IACf,YAAY;IACZ,kCAAkC;AACtC;AACA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,SAAS;IACT,cAAc;IACd,yBAAyB;AAC7B;AACA;GACG,YAAY;AACf","sourcesContent":[".form_container {\r\n    padding: 2em 5em;\r\n}\r\n.radio_container {\r\n    display: flex;\r\n    gap: 10px;\r\n}\r\n.form_input {\r\n    width: 450px;\r\n    height: 40px;\r\n    border-radius: 5px;\r\n    margin: 0;\r\n    outline: unset;\r\n    border: 1px solid #bab1b1;\r\n}\r\n.button_box {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n.form_button {\r\n    width: 200px;\r\n    height: 40px;\r\n    border-radius: 5px;\r\n    margin: 10px;\r\n    outline: unset;\r\n    border: 1px solid #bab1b1;\r\n    cursor: pointer;\r\n    color: white;\r\n    background-color: rgb(70, 70, 158);\r\n}\r\n.form_textarea {\r\n    width: 450px;\r\n    height: 100px;\r\n    border-radius: 5px;\r\n    margin: 0;\r\n    outline: unset;\r\n    border: 1px solid #bab1b1; \r\n}\r\n.form_label {\r\n   padding: 5px; \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
