import React, { useState, useEffect } from 'react';

import Track from './Track';
import './VideoCall.css'
// import '../App.css'

export default function Participant(props) {
    // const existingPublications = Array.from(props.participant.tracks.values());
    // const existingTracks = existingPublications.map(publication => publication.track);
    // const nonNullTracks = existingTracks.filter(track => track !== null)


    const [tracks, setTracks] = useState(Array.from(props.participant.tracks.values()).map(publication => publication.track).filter(track => track !== null))

    useEffect(() => {
        if (!props.localParticipant) {
            props.participant.on('trackSubscribed', track => addTrack(track));
        }
    }, [])


    function addTrack(track) {
        setTracks((previousState) => {
            return (
                [...previousState, track]
            )
        })
    }
        return (
            <div
            className={props.localParticipant === 'true' && props.remoteParticipants !== 0 ? "local-participient" : "remote-participant"}
            // className='remote-participant'
            id={props.participant.identity}>
            {/* <div className="identity">{props.participant.identity}</div> */}
                {
                tracks.map(track => {
                    return (
                        <Track key={track} track={track} local={props.localParticipant} />
                    )
                }
                )
                }
            </div>
        );
    }

// class Participant extends React.Component {
//     constructor(props) {
//         super(props);

//         const existingPublications = Array.from(this.props.participant?.tracks.values());
//         const existingTracks = existingPublications.map(publication => publication.track);
//         const nonNullTracks = existingTracks.filter(track => track !== null)
//         // console.log('existing null',existingTracks);
//         // console.log('non null',nonNullTracks);

//         this.state = {
//             tracks: nonNullTracks
//         }
//     }

//     componentDidMount() {
//         if (!this.props.localParticipant) {
//             this.props.participant.on('trackSubscribed', track => this.addTrack(track));
//         }
//     }

//     addTrack(track) {
//         this.setState({
//             tracks: [...this.state.tracks, track]
//         });
//     }


//     render() {
//         return (
//             <div
//                 className={this.props.localParticipant === 'true' && this.props.remoteParticipants !== 0 ? "local-participient" : "remote-participant"}
//                 id={this.props.participant.identity}
//             >
//                 {
//                     this.state.tracks.map(track =>
//                         <Track key={track} track={track} />)
//                 }
//             </div>
//         );
//     }
// }

// export default Participant;
