import axios from "axios";
import { END_POINT } from "../config/constants";

// const API_URL = "http://localhost:5000/api/emailsetting/";
const API_URL = `${END_POINT}emailsetting/`


export const register = (fromemail: string, toemail: string, authuser: string, authpassword: string, port: any, secure: any) => {
  return axios.post(API_URL + "email_setting_form", {
    fromemail, 
    toemail, 
    authuser, 
    authpassword, 
    port, 
    secure
  });
};

export const getAllEmailSetting = () => {
    return axios
      .get(API_URL + "all_email_setting", {
     
      })
      .then((response) => {
      
  
        return response?.data;
      });
  };

  export const deleteEmailSettingData = (id: any) => {
    return axios
      .delete(API_URL + `email_setting_delete/${id}`, {
     
      })
      .then((response) => {
      
  
        //  response?.data;
        window.location.reload();
      });
  };

  export const getoneuser = (key:string) => {
    return axios
      .get(API_URL + "getOneUser/"+key, {
      
      })
      .then((response) => {
     
  
        return response?.data;
      });
  };
  
  export const updateprofile = (key:string,values:object) => {
    return axios
      .put(API_URL + "updateprofile/"+key, {
      values,
      
      })
      .then((response) => {
     
  
        return response?.data;
      });
  };