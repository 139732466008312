import {
    Row,
    Col
} from "reactstrap";
import { useState, useEffect, useContext } from "react";
import { getOneUserSettings, getProfileSettings, saveUserSettings } from "../../../services/adminsetting-service";
import { AlertContext, RoleContext } from "../../../utils/contexts/RoleContext";
import { useNavigate } from "react-router-dom";

export default function PaymentSettings() {
    const [informations, setInformations] = useState<any>({
        currency: {
            value: "",
            error: ""
        },
        paymentMethod: {
            value: "",
            error: "",
        }
    });

    const toast: any = useContext(AlertContext);
    const currentUser = useContext(RoleContext);
    const navigate = useNavigate();

    useEffect(() => {
        fetchOneUserSettings()
    }, []);


    async function fetchOneUserSettings() {
        const response = await getOneUserSettings();
        if (response?.success) {
            const obj: any = { ...informations };
            response?.data?.forEach((item: any) => {
                if (informations[item.settingsKey]) {
                    informations[item.settingsKey].value = item.settingsValue
                }
            });
            setInformations(obj);
        }
    }


    function handleChange(e: any) {
        const { name, value } = e.target;
        console.log(name, value)
        setInformations((prevState: any) => ({ ...prevState, [name]: { ...prevState[name], value } }))
    }

    async function handleSubmit() {
        const obj: any = {};
        for (let key in informations) {
            if (informations[key].value !== "") {
                obj[key] = informations[key].value
            }
        }
        const response = await saveUserSettings(obj);
        if (response.success) {
          toast.setShowToast("Setting saved successfully!", "success")
        } else {
          toast.setShowToast("Something went wrong on our side. Please try again later!", "danger")
        }
        window.scrollTo(0, 0);
    }

    return (
        <>
            <div className="row justify-content-around border-bottom mb-4 py-4 px-lg-4">
                <div className="col-lg-12 col-xl-4">
                    <p className="text-black-50 fw-medium poppins-500">Currency</p>
                </div>
                <div className="row  col-lg-12 col-xl-8">
                    <div className="col-lg-12 mb-3">
                        <span>Currencies Accepted</span>
                        <select
                            className="form-select mt-1"
                            onChange={handleChange}
                            name="currency"
                            value={informations.currency.value}
                        >
                            <option value={""}>Select</option>
                            <option value={"USD"}>US Dollar</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="row justify-content-around border-bottom mb-4 py-4 px-lg-4">
                <div className="col-lg-12 col-xl-4">
                    <p className="text-black-50 fw-medium poppins-500">Payment Systems</p>
                </div>
                <div className="row  col-lg-12 col-xl-8">
                    <div className="col-lg-12 mb-3">
                        <span>Payment Methods</span>
                        <select
                            className="form-select mt-1"
                            onChange={handleChange}
                            name="paymentMethod"
                            value={informations.paymentMethod.value}
                        >
                            <option value={""}>Select</option>
                            <option value={"PayPal"}>PayPal</option>
                            <option value={"Authorize.net"}>Authorize.net</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 col-xl-4">

                </div>
                <div className="form_bottom_btn d-flex justify-content-end">
                    <button className="delete_btn" type="button" onClick={() => navigate(-1)}>Cancel</button>
                    <button
                        className="btn_gradian"
                        type="button"
                        onClick={handleSubmit}
                    >
                        Save
                    </button>
                </div>
            </div>
        </>
    );
};
