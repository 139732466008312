import { useState, useContext, useEffect } from "react";
import {
    Row,
    Col
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { getOneOrder } from "../../../services/billing-service";
import { getDateOnly } from "../../../utils/utils";
import { usePDF } from 'react-to-pdf';
import Logo from "../../../layouts/Logo";
import Spinner from 'react-bootstrap/Spinner';
import constants from "../../../config/constants";
import { RoleContext } from "../../../utils/contexts/RoleContext";
import { capitalizeFirstLetter } from "../../../utils/utils";


export default function OrderDetail() {
    const [order, setOrder] = useState<any>({});
    const [address, setAddress] = useState<any>({});
    const [transaction, setTransaction] = useState<any>({});
    const [appointment, setAppointment] = useState<any>({});
    const [user, setUser] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const { toPDF, targetRef } = usePDF({ filename: 'invoice.pdf' });
    const [show, setShow] = useState(false)

    const navigate = useNavigate();
    const { orderId }: any = useParams();
    const currentUser = useContext(RoleContext)

    useEffect(() => {
        fetchOneOrder();
        document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Order Detail - ${orderId}`;
    }, [currentUser]);

    async function fetchOneOrder() {
        setLoading(true);
        const response = await getOneOrder(orderId);
        if (response?.success) {
            if (response.data?.billingAddresses?.length > 0) {
                const billingAddress = [...response.data.billingAddresses];
                const lastBillingAddress = billingAddress[billingAddress.length - 1];
                setAddress(lastBillingAddress);
            }
            if (response.data?.transactions?.length > 0) {
                const transaction = [...response.data.transactions];
                const lastTransactions = transaction[transaction.length - 1];
                setTransaction(lastTransactions);
            }
            if (response.data?.user) {
                const userData = { ...response.data?.user };
                response.data?.user?.userdata?.forEach((item: any) => {
                    if (item.fieldKey === "address") {
                        userData.address = JSON.parse(item.fieldValue);
                    }
                    if (item.fieldKey === "city") {
                        userData.city = JSON.parse(item.fieldValue);
                    }
                    if (item.fieldKey === "zipCode") {
                        userData.zipCode = JSON.parse(item.fieldValue);
                    }
                });
                setUser(userData);
            }

            setOrder(response.data);
            setAppointment(response.data.booking_appointment);
        }
        setLoading(false);
    }

    return (
        <>
            {!loading ?
                <Row>
                    <div className="panel_top_section position-relative">
                        <h1
                            className="clickable"
                            onClick={() => navigate(-1)}
                        >
                            Billing
                        </h1>
                        <span className="textgreen poppins-500 order-number"><i className="bi bi-chevron-right"></i> Order Detail </span>
                        <span className="poppins-300 order-number"><i className="bi bi-chevron-right textgreen" />Order Number : #{order?.orderId}</span>
                        <div className="float-right me-xl-5 mt-2 ">
                            <p>Order Status :
                                <span
                                    className="po_completed p-2 py-1 rounded-5 text_sm ms-2"
                                    style={{ backgroundColor: order?.orderStatus == 0 || order?.orderStatus == 11 ? "orange" : order?.orderStatus == 1 ? "green" : "red", color: 'white' }}
                                >
                                    {order?.orderStatus == 0 || order?.orderStatus == 11 ? "Pending" : order?.orderStatus == 1 ? "Completed" : "Failed"}
                                </span>
                                {/* <span style={{ color: order?.orderStatus == 1 ? "green" : order?.orderStatus == 2 ? "red" : "orange" }}>
                                    {order?.orderStatus == 1 ? " Completed" : order?.orderStatus == 2 ? " Cancelled" : " Pending"}
                                </span> */}
                                {order?.orderStatus == 1 &&
                                    <span
                                        className="ms-3 fs-5 clickable"
                                        onClick={() => {
                                            setShow(true);
                                            document.body.style.overflow = "hidden"
                                            setTimeout(() => {
                                                toPDF();
                                            }, 1000)
                                            setTimeout(() => {
                                                setShow(false);
                                                document.body.style.overflow = "scroll"
                                            }, 2000)
                                        }}
                                    >
                                        <i className="bi bi-printer" />
                                    </span>
                                }
                            </p>
                        </div>
                    </div>
                    <Col className="mt-3 mb-4">
                        <div className="add_location col-sm-12 py-4 px-lg-2 mt-2">
                            <div className="row m-0">
                                <div className="col-xl-12 row m-0">
                                    <div className="col-xl-4 p-0">
                                        {/* application-status */}
                                        <div className="col-xl-12 p-1 pb-2">
                                            <div className="inner_location float-start w-100 bg-white box_shadow p-4 pt-4 radius_15">
                                                <h4 className="dark_blue poppins-600 fs-5 mb-3 ">Order Summary:</h4>
                                                <div>
                                                    {/* <div className="d-flex justify-content-between">
                                                <p>Order Number :</p>
                                                <p>#{order?.orderId}</p>
                                            </div> */}
                                                    <div className="d-flex justify-content-between mt-2">
                                                        <p>Order Date :</p>
                                                        <p>{order?.orderDate}</p>
                                                    </div>
                                                    {/* <div className="d-flex justify-content-between mt-2">
                                                <p>Order Status :</p>
                                                <p style={{ color: order?.orderStatus == 1 ? "green" : order?.orderStatus == 2 ? "red" : "orange"}}>
                                                    {order?.orderStatus == 1 ? "Completed" : order?.orderStatus == 2 ? "Cancelled" : "Pending"}
                                                </p>
                                            </div> */}
                                                    <div className="d-flex justify-content-between mt-2">
                                                        <p>Order Updated :</p>
                                                        <p>{getDateOnly(new Date(order?.updatedAt))}</p>
                                                    </div>
                                                    <div className="d-flex justify-content-between mt-2">
                                                        <p>Order Created by :</p>
                                                        <p>{order?.creator?.firstName + " " + order?.creator?.lastName} ({order?.creator?.roleId == 4 ? "staff" : order?.creator?.roleId == 2 ? "Patient" : "Hospital"})</p>
                                                    </div>
                                                    <div className="d-flex justify-content-between mt-2">
                                                        <p>Payment Method :</p>
                                                        <p>{transaction.paymentMethod ? transaction.paymentMethod : "-"}</p>
                                                    </div>
                                                    {transaction.paymentMethod === "Credit Card" &&
                                                        <>
                                                            <div className="d-flex justify-content-between mt-2">
                                                                <p>Card Holder Name :</p>
                                                                <p>{transaction.cardHolder ? transaction.cardHolder : "-"}</p>
                                                            </div>
                                                            <div className="d-flex justify-content-between mt-2">
                                                                <p>Card Number :</p>
                                                                <p>{transaction.cardNumber ? transaction.cardNumber : "-"}</p>
                                                            </div>
                                                        </>
                                                    }
                                                    <div className="d-flex justify-content-between mt-2">
                                                        <p>Transaction ID :</p>
                                                        <p>{transaction.transactionId ? transaction.transactionId : "-"}</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    {/* Billing From Address: */}
                                    <div className="col-xl-4 p-1 pb-2">
                                        <div className="inner_location float-start w-100 bg-white box_shadow p-4 pt-4 radius_15">
                                            <h4 className="dark_blue poppins-600 fs-5 mb-3 x-1">Billing From Address:</h4>
                                            <div className="pat_group py-2 d-flex ">
                                                <div className="row_1 col-sm-6 px-1">
                                                    <span className="d-block text_sm text_gray ">Full Name</span>
                                                    <p className="m-0 poppins-500 dark_blue fs-6">{address?.name ? address?.name : "-"}</p>
                                                </div>
                                                <div className="row_1 col-sm-6 px-1">
                                                    <span className="d-block text_sm text_gray ">Phone</span>
                                                    <p className="m-0 poppins-500 dark_blue fs-6">{address?.phoneNumber ? address?.phoneNumber : "-"}</p>
                                                </div>
                                            </div>
                                            <div className="pat_group py-2 d-flex ">
                                                <div className="row_1 col-sm-6 px-1">
                                                    <span className="d-block text_sm text_gray ">Email</span>
                                                    <p className="m-0 poppins-500 dark_blue text-break">{address?.email ? address?.email : "-"}</p>
                                                </div>
                                                <div className="row_1 col-sm-6 px-1">
                                                    <span className="d-block text_sm text_gray ">Address</span>
                                                    <p className="m-0 poppins-500 dark_blue fs-6">{address?.address ? address?.address : "-"}</p>
                                                </div>
                                            </div>
                                            <div className="pat_group py-2 d-flex ">
                                                <div className="row_1 col-sm-6 px-1">
                                                    <span className="d-block text_sm text_gray ">City</span>
                                                    <p className="m-0 poppins-500 dark_blue fs-6">{address?.city ? address?.city : "-"}</p>
                                                </div>
                                                <div className="row_1 col-sm-6 px-1">
                                                    <span className="d-block text_sm text_gray ">ZIP Code</span>
                                                    <p className="m-0 poppins-500 dark_blue fs-6">{address?.pinCode ? address?.pinCode : "-"}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Billing To Address: */}
                                    <div className="col-xl-4 p-1 pb-2">
                                        <div className="inner_location float-start w-100 bg-white box_shadow p-4 pt-4 radius_15">
                                            <h4 className="dark_blue poppins-600 fs-5 mb-3 x-1">Billing To Address:</h4>
                                            <div className="pat_group py-2 d-flex ">
                                                <div className="row_1 col-sm-6 px-1">
                                                    <span className="d-block text_sm text_gray ">Full Name</span>
                                                    <p className="m-0 poppins-500 dark_blue fs-6">{user?.firstName + " " + user.lastName}</p>
                                                </div>
                                                <div className="row_1 col-sm-6 px-1">
                                                    <span className="d-block text_sm text_gray ">Phone</span>
                                                    <p className="m-0 poppins-500 dark_blue fs-6">{user?.phoneNumber ? user?.phoneNumber : "-"}</p>
                                                </div>
                                            </div>
                                            <div className="pat_group py-2 d-flex ">
                                                <div className="row_1 col-sm-6 px-1">
                                                    <span className="d-block text_sm text_gray ">Email</span>
                                                    <p className="m-0 poppins-500 dark_blue text-break">{user?.email ? user?.email : "-"}</p>
                                                </div>
                                                <div className="row_1 col-sm-6 px-1">
                                                    <span className="d-block text_sm text_gray ">Address</span>
                                                    <p className="m-0 poppins-500 dark_blue fs-6">{user?.address ? user?.address : "-"}</p>
                                                </div>
                                            </div>
                                            <div className="pat_group py-2 d-flex ">
                                                <div className="row_1 col-sm-6 px-1">
                                                    <span className="d-block text_sm text_gray ">City</span>
                                                    <p className="m-0 poppins-500 dark_blue fs-6">{user?.city ? user?.city : "-"}</p>
                                                </div>
                                                <div className="row_1 col-sm-6 px-1">
                                                    <span className="d-block text_sm text_gray ">ZIP Code</span>
                                                    <p className="m-0 poppins-500 dark_blue fs-6">{user?.zipCode ? user?.zipCode : "-"}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Service Details: */}
                                    <div className="col-xl-12 p-1 pb-2">
                                        <div className="inner_location float-start w-100 bg-white box_shadow p-4 pt-4 radius_15">
                                            <h4 className="dark_blue poppins-600 fs-5 mb-3 px-1 pb-2">Order Info:</h4>
                                            <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                                <div className="row d-flex table-responsive">
                                                    <table>
                                                        <thead>
                                                            <tr className="list_head">
                                                                <th>Service Provided</th>
                                                                {appointment != null ?
                                                                    <>
                                                                        <th>Appointment Date</th>
                                                                        <th>Appointment Time</th>
                                                                        <th>Appointment Status</th>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <th>Service Type</th>
                                                                    </>
                                                                }
                                                                <th>Quantity</th>
                                                                <th>Item Price</th>
                                                                <th>Total Amount</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className="list_detail">
                                                                <td>{appointment != null ? `Appointment - ${order?.service}` : order?.service?.split("-")[0]}</td>
                                                                {appointment != null ?
                                                                    <>
                                                                        <td>{appointment.date}</td>
                                                                        <td>{appointment.time}</td>
                                                                        <td>{appointment.status == 0 ? "Pending" : appointment.status == 1 ? "Confirmed" : "Cancelled"}</td>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <td>{order?.service?.split("-")[1]}</td>
                                                                    </>
                                                                }
                                                                <td>1</td>
                                                                <td>$ {(order?.totalAmount - (order?.totalTax - order?.totalDiscount))?.toFixed(2)}</td>
                                                                <td>$ {order?.totalAmount?.toFixed(2)}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Billing Details:: */}
                                <div className="d-flex justify-content-end">
                                    <div className="col-xl-4 p-1 pb-2">
                                        <div className="inner_location float-start w-100 bg-white box_shadow p-4 pt-4 radius_15">
                                            <h4 className="dark_blue poppins-600 fs-5 mb-3 px-1 ">Payment Summary:</h4>
                                            <div>
                                                <div className="d-flex justify-content-between mt-2">
                                                    <p>Sub Total :</p>
                                                    <p>$ {(order?.totalAmount - (order?.totalTax - order?.totalDiscount))?.toFixed(2)}</p>
                                                </div>
                                                <div className="d-flex justify-content-between mt-2">
                                                    <p>Tax :</p>
                                                    <p>$ {order?.totalTax?.toFixed(2)}</p>
                                                </div>
                                                <div className="d-flex justify-content-between mt-2">
                                                    <p>Discount :</p>
                                                    <p>$ {order?.totalDiscount?.toFixed(2)}</p>
                                                </div>
                                                <div className="d-flex justify-content-between mt-2">
                                                    <p>Total Amount :</p>
                                                    <p>$ {order?.totalAmount?.toFixed(2)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {show &&
                                    <div ref={targetRef}>
                                        <div className="p-5 bg-white">
                                            <div className="d-flex justify-content-between align-item-center border-bottom border-4 border-bottom-success p-4">
                                                <div>
                                                    <Logo />
                                                </div>
                                                <div>
                                                    <h4>Invoice</h4>
                                                </div>
                                                <div>
                                                    <p>Invoice Number: {order?.invoice?.invoiceId}</p>
                                                    <p>Date: {getDateOnly(new Date(order?.invoice?.createdAt))}</p>
                                                </div>
                                            </div>
                                            <div className="d-flex pt-5">
                                                <div className="w-50">
                                                    <h5 className="mb-2">Invoice From</h5>
                                                    <p className="mt-1">{address?.name}</p>
                                                    <p className="mt-1">{address?.email}</p>
                                                    <p className="mt-1">{`${address?.address}, ${address?.city}, ${address?.pinCode}.`}</p>
                                                    <p className="mt-1">{address?.phoneNumber}</p>
                                                </div>
                                                <div className="w-50">
                                                    <h5 className="mb-2">Invoice To</h5>
                                                    <p className="mt-1">{user?.firstName + " " + user.lastName}</p>
                                                    <p className="mt-1">{user?.email}</p>
                                                    <p className="mt-1">{`${user?.address ? user?.address : "" + user?.city ? ", " + user?.city + ", " : "" + user?.zipCode ? ", " + user?.zipCode + ", " : ""}`}</p>
                                                    <p className="mt-1">{address?.phoneNumber}</p>
                                                </div>
                                            </div>
                                            <div className="mt-5 row d-flex">
                                                <table>
                                                    <thead>
                                                        <tr className="list_head">
                                                            <th>Service Provided</th>
                                                            {appointment != null ?
                                                                <>
                                                                    <th>Appointment Date</th>
                                                                    <th>Appointment Time</th>
                                                                    {/* <th>Appointment Status</th> */}
                                                                </>
                                                                :
                                                                <>
                                                                    <th>Service Type</th>
                                                                </>
                                                            }
                                                            <th>Payment Date</th>
                                                            <th>Quantity</th>
                                                            <th>Item Price</th>
                                                            <th>Total Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr className="list_detail">
                                                            <td>{appointment != null ? `Appointment - ${order?.service}` : order?.service?.split("-")[0]}</td>
                                                            {appointment != null ?
                                                                <>
                                                                    <td>{appointment.date}</td>
                                                                    <td>{appointment.time}</td>
                                                                    {/* <td>{appointment.status == 0 ? "Pending" : appointment.status == 1 ? "Confirmed" : "Cancelled"}</td> */}
                                                                </>
                                                                :
                                                                <>
                                                                    <td>{order?.service?.split("-")[1]}</td>
                                                                </>
                                                            }
                                                            <td>{getDateOnly(new Date(transaction.createdAt))}</td>
                                                            <td>1</td>
                                                            <td>$ {(order?.totalAmount - (order?.totalTax - order?.totalDiscount))?.toFixed(2)}</td>
                                                            <td>$ {order?.totalAmount?.toFixed(2)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="mt-5 d-flex justify-content-end">
                                                <div className="col-lg-4">
                                                    <div className="d-flex justify-content-between mt-2">
                                                        <p className="fw-bolder">Sub Total :</p>
                                                        <p>$ {(order?.totalAmount - (order?.totalTax - order?.totalDiscount))?.toFixed(2)}</p>
                                                    </div>
                                                    <div className="d-flex justify-content-between mt-2">
                                                        <p className="fw-bolder">Tax :</p>
                                                        <p>$ {order?.totalTax?.toFixed(2)}</p>
                                                    </div>
                                                    <div className="d-flex justify-content-between mt-2">
                                                        <p className="fw-bolder">Discount :</p>
                                                        <p>$ {order?.totalDiscount?.toFixed(2)}</p>
                                                    </div>
                                                    <div className="d-flex justify-content-between mt-2">
                                                        <p className="fw-bolder">Total Amount :</p>
                                                        <p>$ {order?.totalAmount?.toFixed(2)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-5 text-center fw-bold fs-5">
                                                Thank You for choosing us!
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
                :
                <div className='m-5 justify-content-center d-flex'>
                    <Spinner />
                </div>
            }
        </>
    )
}