
import pmc from "../../../assets/images/medical/pmc.png";
import pdf from "../../../assets/images/medical/pdf.png";
import excel from "../../../assets/images/medical/excel.png";
import word from "../../../assets/images/medical/word.png";
import Doc from "../../../assets/images/medical/Doc.png";
import Tex from "../../../assets/imagse/medical/tex.png";
import text from "../../../assets/images/medical/text.png";
import PNG from "../../../assets/images/medical/PNG.png";
import Sql from "../../../assets/images/medical/sql.png";
import Jpeg from "../../../assets/images/medical/JPEG.png";
import Jpg from "../../../assets/images/medical/JPG.png"

import {
  Card,
  Row,
  Col,
  CardTitle,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import React, { useContext, useEffect, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import IUser from '../../../types/user.type';
import Modal from 'react-bootstrap/Modal';
import { RoleContext, HospitalContext } from '../../../utils/contexts/RoleContext';
import { createRoot } from 'react-dom/client';
import { deletePatientData } from "../../../services/patient-service";
import { deleteoneuser } from '../../../services/user.service';
import { getallpatient } from "../../../services/patient-service";
import { getallmedicalrecord, deleteonemedicalrecord, getOnePatientRecords } from "../../../services/medicalrecord-service";
import constants from "../../../config/constants";
import { capitalizeFirstLetter, groupBy } from "../../../utils/utils";
import JSZip from "jszip";
import { SOCKET_DOMAIN } from "../../../config/constants";
import SearchInput from "../../../components/SearchInput";
import Spinner from 'react-bootstrap/Spinner';
import { patient } from "../../../layouts/Sidebar";
import CustomToolTip from "../../../components/CustomToolTip";
import PhoneNumber from "../../../components/PhoneNumber";

const Medical = () => {

  const currentUser = useContext(RoleContext);
  const navigate = useNavigate()
  // Row Data: The data to be displayed.
  const [rowData, setRowData] = useState([]);
  const [show, setShow] = useState(false);
  const [more, setMore] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [patients, setPatients] = useState([])
  const [deleteId, setDeleteId] = useState<string>("");
  const [pages, setpages] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [popup, showPopup] = useState(false);
  const [patientRecords, setPaientRecords] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [loading, setLoading] = useState(false);

  const hospitalContext: any = useContext(HospitalContext);

  const zip = new JSZip();


  useEffect(() => {
    getUsers();
    document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Medical Records`;
  }, [currentUser, hospitalContext.selectedHospital?.id]);

  const showForm = (id?: any) => {
    if (id != undefined) {
      handleShow()
      setDeleteId(id)
    }
    else {
      handleShow()
      setDeleteId(deleteId)
    }


  }

  async function handleZip(patient: any) {
    let check: any;
    check = await Promise.all(patient?.medicalrecords?.map(async (item: any, index: number) => {
      let fileLength = item.documents.split("/")?.length
      const fileName = item.documents.split("/")[fileLength - 1]?.replace(`"`, "")
      console.log("file", fileName)
      const url = `${SOCKET_DOMAIN}/medicalrecord/${patient.id}/${fileName}`
      console.log("url", url)
      const response = await fetch(url);
      const blob = await response.blob();
      zip.file(fileName, blob);

      if (index == patient?.medicalrecords?.length - 1) {
        // Generate the zip file
        const zipData = await zip.generateAsync({
          type: "blob",
          streamFiles: true,
        });
        console.log(zipData);
        // Create a download link for the zip file
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(zipData);
        link.download = "medical_records.zip";
        link.click();
      }
      return check;
    }))
  }


  const handleDelete = async (id: any) => {

    deleteonemedicalrecord(id).then(
      (response) => {
        // const doctorData = response.result;
        getUsers();
        handleClose();
        // setRowData(response.result)

        // navigate("/profile");
        // window.location.reload();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        // setLoading(false);
        // setMessage(resMessage);
      }
    );
  }

  const getUsers = () => {
    setLoading(true);
    getallmedicalrecord(currentUser, hospitalContext.selectedHospital?.id).then(
      (response) => {
        setLoading(false);
        const patientData = response?.result;
        setPatients(patientData);
        setRowData(patientData);
        if (currentUser === "patient") {
          let medicalRecords = patientData[0]?.medicalrecords;
          const groupedRecords = groupBy(medicalRecords, "provider");
          const arr: any = [];
          for (let key in groupedRecords) {
            let records = groupedRecords[key];
            const obj: any = {
              provider: records[0]?.providerName,
              medicalrecords: [],
              lastRecordDate: records[0]?.updatedAt,
              recordType: "",
              id: patientData[0]?.id,
            }
            records?.forEach((item: any) => {
              if (item?.note) {
                obj.recordType = item?.note
              }
              obj.medicalrecords?.push({ documents: item?.documents });
            });
            arr.push(obj);
          }
          setPaientRecords(arr);
        }
      },
      (error) => {
        console.log("error", error)

        // setLoading(false);
        // setMessage(resMessage);
      }
    );
  }

  function handleDownload(patientId: number, fileName: string) {
    console.log("fileName", fileName)
    const url = `${SOCKET_DOMAIN}/medicalrecord/${patientId}/${fileName}`;
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.click();
  }

  function handleNavigation() {
    if (currentUser === "admin") {
      navigate(`/${currentUser}/hospital`, { state: { what: "medicalRecord" } });
    } else {
      navigate({
        pathname: `/${currentUser}/addmedical-records`,
      });
    }
  }

  function handleEdit(patient: any) {
    if (currentUser === "admin") {
      navigate(`/${currentUser}/hospital`, { state: { what: "EditMedicalRecord", patientId: patient.id } });
    } else {
      navigate(`/${currentUser}/edit-medical-records/${patient.id}`)
    }
  }

  return (
    <Row>
      <div className="panel_top_section border-0 position-relative">
        <h1>Medical Records</h1>
        <div className="grid_icons d-flex gap-1 align-items-center float-end">
          {/* {currentUser === "doctor" && */}
          {currentUser != "patient" &&
            <button
              type="button"
              className="btn_gradian"
              // onClick={() => navigate(`/${currentUser}/addstaff`)}
              onClick={handleNavigation}
            >
              + Add New Medical Records
            </button>
          }
          {/* } */}
          {/* <button type="button" className="btn_gradian">+ Add New Patient </button>
                <button type="button" className="btn_gradian dark_btn"> <span className="px-2"><i className="bi bi-download"></i></span>Import Patient</button> */}
          {currentUser !== "patient" &&
            <div className="grid_list_icons icons_patients  d-flex align-items-center gap-2">
              {/* <button type="button" className="delet_patiens"> <i className="bi bi-trash"></i> </button> */}
              <div>
                <SearchInput
                  visible={showSearch}
                  data={rowData}
                  setData={(value: any) => setPatients(value)}
                />
              </div>
              <span
                className="filter_icons fs-3 px-1"
                onClick={() => setShowSearch(!showSearch)}
              >
                <i className="bi bi-funnel-fill" />
              </span>
            </div>
          }

        </div>
      </div>
      <Col>
            {currentUser !== "patient" ?
              <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
            <div className="row d-flex table-responsive">
                  <table>
                    <thead>
                      <tr className="list_head">
                        <th><label className="p-0 bg-transparent" htmlFor="name">Name</label></th>
                        <th>Address</th>
                        <th>Phone number</th>
                        <th>Last Record Date</th>
                        <th>Medical Record Files</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                  {patients?.length > 0 ?
                    <>
                      {patients?.map((patient: any, index: number) => {
                        const date = new Date(patient?.medicalrecords[0]?.updatedAt);
                        const formattedDate = date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
                        return (
                          <>

                            <tr className="list_detail" key={index + patient.firstName}>
                              <td><label htmlFor="name2">{patient.firstName + " " + patient.lastName}</label></td>
                              <td>{patient?.userdata[0]?.fieldValue && JSON.parse(patient?.userdata[0]?.fieldValue)}</td>
                              <td><PhoneNumber phoneNumber={patient.phoneNumber} allowCall={patient.allowCall} /></td>
                              <td className="poppins-600">{formattedDate}</td>
                              <td className="record_img">

                                {patient?.medicalrecords?.map((items: any, index: number) => {
                                  const filename = JSON.parse(items.documents);
                                  const splitfilename = filename.split('.')[1]
                                  const documentName = filename.split('/')[filename.split('/').length - 1]
                                  // const splitfilename="";
                                  // const documentName=""

                                  return (

                                    index < 3 &&
                                    <a onClick={() => handleDownload(patient.id, documentName)}>
                                      {splitfilename === "txt" ?
                                        <a title={documentName} className="px-2 d-inline-block" href="#"><img src={text} alt="pmc" /></a>
                                        :
                                        splitfilename === "docx" || splitfilename === "doc" || splitfilename === "DOC" ? <a title={documentName} className="px-2 d-inline-block"><img src={Doc} alt="pmc" /></a>
                                          : splitfilename === "pdf" || splitfilename === "PDF" ? <a title={documentName} className="px-2 d-inline-block"><img src={pdf} alt="pmc" /></a>
                                            : splitfilename === "word" ? <a title={documentName} className="px-2 d-inline-block"><img src={word} alt="pmc" /></a>
                                              : splitfilename === "png" || splitfilename === "PNG" ? <a title={documentName} className="px-2 d-inline-block"><img src={PNG} alt="pmc" /></a>
                                                : splitfilename === "sql" || splitfilename === "SQL" ? <a title={documentName} className="px-2 d-inline-block"><img src={Sql} alt="pmc" /></a>
                                                  : splitfilename === "jpeg" || splitfilename === "JPEG" ? <a title={documentName} className="px-2 d-inline-block"><img src={Jpeg} alt="pmc" /></a>
                                                    : splitfilename === "jpg" || splitfilename === "JPG" ? <a title={documentName} className="px-2 d-inline-block"><img src={Jpg} alt="pmc" /></a>
                                                      : splitfilename === "csv" || splitfilename === "CSV" ? <a title={documentName} className="px-2 d-inline-block"><img src={pdf} alt="pmc" /></a>
                                                        : null

                                      }
                                      {/* <a className="px-2 d-inline-block" href="#"><img src={pmc} alt="pmc" /></a>
                                <a className="px-2 d-inline-block" href="#"><img src={pdf} alt="pmc" /></a>
                                <a className="px-2 d-inline-block" href="#"><img src={pdf} alt="pmc" /></a> */}
                                    </a>

                                  )
                                })}


                                {patient?.medicalrecords?.length > 3 &&
                                  <button className="exclamation_btn position-relative border-0 p-0 px-2 py-1 radius_15 bg_gradiant text-white rounded-full mx-1" >

                                    <i className="bi bi-plus-lg" onClick={() => {
                                      showPopup(!popup)
                                    }}></i>
                                    {/* {popup===true &&  */}
                                    <ul className="drop_list add_pdf m-0 p-0 bg-white p-2  text-left">
                                      {/* <a className="px-2 d-inline-block" href="#"><img src={pmc} alt="pmc" /></a>
                                  <a className="px-2 d-inline-block" href="#"><img src={pdf} alt="pmc" /></a>
                                  <a className="px-2 d-inline-block" href="#"><img src={pdf} alt="pmc" /></a>
                                  <a className="px-2 d-inline-block" href="#"><img src={word} alt="pmc" /></a>
                                  <a className="px-2 d-inline-block" href="#"><img src={excel} alt="pmc" /></a> */}
                                      {patient?.medicalrecords?.map((items: any) => {
                                        const filename = JSON.parse(items.documents);
                                        const splitfilename = filename.split('.')[1]
                                        const documentName = filename.split('/')[filename.split('/').length - 1]
                                        return (

                                          patient?.medicalrecords?.length >= 3 &&
                                          <a onClick={() => handleDownload(patient.id, documentName)}>
                                            {splitfilename === "txt" ?
                                              <a title={documentName} className="px-2 d-inline-block" href="#"><img src={text} alt="pmc" /></a>
                                              :
                                              splitfilename === "docx" || splitfilename === "doc" || splitfilename === "DOC" ? <a title={documentName} className="px-2 d-inline-block"><img src={Doc} alt="pmc" /></a>
                                                : splitfilename === "pdf" || splitfilename === "PDF" ? <a title={documentName} className="px-2 d-inline-block" ><img src={pdf} alt="pmc" /></a>
                                                  : splitfilename === "word" || splitfilename === "WORD" ? <a title={documentName} className="px-2 d-inline-block" ><img src={word} alt="pmc" /></a>
                                                    : splitfilename === "png" || splitfilename === "PNG" ? <a title={documentName} className="px-2 d-inline-block"><img src={PNG} alt="pmc" /></a>
                                                      : splitfilename === "sql" || splitfilename === "SQL" ? <a title={documentName} className="px-2 d-inline-block"><img src={Sql} alt="pmc" /></a>
                                                        : splitfilename === "jpeg" || splitfilename === "JPEG" ? <a title={documentName} className="px-2 d-inline-block"><img src={Jpeg} alt="pmc" /></a>
                                                          : splitfilename === "jpg" || splitfilename === "JPG " ? <a title={documentName} className="px-2 d-inline-block"><img src={Jpg} alt="pmc" /></a>
                                                            : splitfilename === "csv" || splitfilename === "CSV" ? <a title={documentName} className="px-2 d-inline-block"><img src={pdf} alt="pmc" /></a>
                                                              : null

                                            }
                                            {/* <a className="px-2 d-inline-block" href="#"><img src={pmc} alt="pmc" /></a>
                                <a className="px-2 d-inline-block" href="#"><img src={pdf} alt="pmc" /></a>
                                <a className="px-2 d-inline-block" href="#"><img src={pdf} alt="pmc" /></a> */}
                                          </a>

                                        )
                                      })}
                                    </ul>
                                  </button>
                                }

                                {/* <a className="px-2 d-inline-block" href="#"><img src={pmc} alt="pmc" /></a>
                          <a className="px-2 d-inline-block" href="#"><img src={pdf} alt="pmc" /></a>
                          <a className="px-2 d-inline-block" href="#"><img src={pdf} alt="pmc" /></a>
                          <a className="px-2 d-inline-block" href="#"><img src={word} alt="pmc" /></a>
                          <a className="px-2 d-inline-block" href="#"><img src={excel} alt="pmc" /></a> */}
                              </td>
                              {/* <td><span className="dost_btn"><i className="bi bi-three-dots-vertical"></i></span>
                          <span className="action" >
                            <button className="edit_btn"><i className="bi bi-pen"></i></button>
                            <button className="exclamation_btn"><i className="bi bi-cloud-arrow-up"></i></button>
                            <button className="delete_btn_act"><i className="bi bi-trash"></i></button>
                          </span>
                          
                    </td> */}
                              <td>
                                {more !== patient.id ?
                                  <span className="dost_btn" onClick={() => setMore(patient.id)}>
                                    <i className="bi bi-three-dots-vertical"></i>
                                  </span>
                                  :
                                  <span className="action" >
                                    <CustomToolTip title="Edit">
                                    <button className="edit_btn" >
                                      <i
                                        className="bi bi-pen"
                                          onClick={() => handleEdit(patient)}
                                      />
                                    </button>
                                    </CustomToolTip>
                                    <CustomToolTip title="Download">
                                      <button
                                        className="exclamation_btn"
                                        onClick={() => handleZip(patient)}
                                      >
                                        <i className="bi bi-cloud-arrow-down" />
                                      </button>
                                    </CustomToolTip>
                                    {currentUser != "patient" &&
                                      <CustomToolTip title="Delete">
                                        <button
                                          className="delete_btn_act"
                                          onClick={() => showForm(patient.id)}
                                        >
                                          <i className="bi bi-trash" />
                                        </button>
                                      </CustomToolTip>
                                    }
                                  </span>
                                }
                              </td>
                            </tr>

                          </>
                        )
                      })}
                    </>
                    :
                    <>
                      <tr className="list_detail">
                        {!loading ?
                          <td colSpan={10}>No data found</td>
                          :
                          <td colSpan={10}><Spinner /></td>
                        }
                      </tr>
                      :
                    </>
                  }
                    </tbody>
                    {/* {pages?.length > 1 &&
                <tfoot>
                  <tr className="list_detail pagination_list">
                    <td colSpan={7}>
                      <ul>
                        <li onClick={() => handlePagination(pageNumber - 1)}><a href="#"><i className="bi bi-arrow-left"></i></a></li>
                        {pages.map((page: number) => {
                          return (
                            <li className={pageNumber == page ? "active" : ""} onClick={() => handlePagination(page)}><a>{page}</a></li>
                          )
                        })}
                        <li onClick={() => handlePagination(pageNumber + 1)}><a href="#"><i className="bi bi-arrow-right"></i></a></li>
                      </ul>
                    </td>
                  </tr>
                </tfoot>
              } */}
                  </table>
                </div>
              </div>
              :
              <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
            <div className="row d-flex table-responsive">
                  <table>
                    <thead>
                      <tr className="list_head">
                        <th>Last Record Date</th>
                        <th>Provider</th>
                        <th>Record Type</th>
                        <th>Medical Record Files</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                  {patients?.length > 0 ?
                    <>
                      {patientRecords?.map((patient: any, index: number) => {
                        const date = new Date(patient?.lastRecordDate);
                        const formattedDate = date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
                        return (
                          <>
                            <tr className="list_detail" key={index + patient.firstName}>
                              <td className="poppins-600">{formattedDate}</td>
                              <td>{patient.provider}</td>
                              <td>{patient.recordType}</td>

                              <td className="record_img">

                                {patient?.medicalrecords?.map((items: any, index: number) => {
                                  const filename = JSON.parse(items.documents);
                                  const splitfilename = filename.split('.')[1]
                                  const documentName = filename.split('/')[filename.split('/').length - 1]
                                  // const splitfilename="";
                                  // const documentName=""

                                  return (

                                    index < 3 &&
                                    <a onClick={() => handleDownload(patient.id, documentName)}>
                                      {splitfilename === "txt" ?
                                        <a title={documentName} className="px-2 d-inline-block"><img src={text} alt="pmc" /></a>
                                        :
                                        splitfilename === "docx" || splitfilename === "doc" || splitfilename === "DOC" ? <a title={documentName} className="px-2 d-inline-block"><img src={Doc} alt="pmc" /></a>
                                          : splitfilename === "pdf" || splitfilename === "PDF" ? <a title={documentName} className="px-2 d-inline-block"><img src={pdf} alt="pmc" /></a>
                                            : splitfilename === "word" ? <a title={documentName} className="px-2 d-inline-block"><img src={word} alt="pmc" /></a>
                                              : splitfilename === "png" || splitfilename === "PNG" ? <a title={documentName} className="px-2 d-inline-block"><img src={PNG} alt="pmc" /></a>
                                                : splitfilename === "sql" || splitfilename === "SQL" ? <a title={documentName} className="px-2 d-inline-block"><img src={Sql} alt="pmc" /></a>
                                                  : splitfilename === "jpeg" || splitfilename === "JPEG" ? <a title={documentName} className="px-2 d-inline-block"><img src={Jpeg} alt="pmc" /></a>
                                                    : splitfilename === "jpg" || splitfilename === "JPG" ? <a title={documentName} className="px-2 d-inline-block"><img src={Jpg} alt="pmc" /></a>
                                                      : null

                                      }
                                      {/* <a className="px-2 d-inline-block" href="#"><img src={pmc} alt="pmc" /></a>
                                <a className="px-2 d-inline-block" href="#"><img src={pdf} alt="pmc" /></a>
                                <a className="px-2 d-inline-block" href="#"><img src={pdf} alt="pmc" /></a> */}
                                    </a>

                                  )
                                })}


                                {patient?.medicalrecords?.length > 3 &&
                                  <button className="exclamation_btn position-relative border-0 p-0 px-2 py-1 radius_15 bg_gradiant text-white rounded-full mx-1" >

                                    <i className="bi bi-plus-lg" onClick={() => {
                                      showPopup(!popup)
                                    }}></i>
                                    {/* {popup===true &&  */}
                                    <ul className="drop_list add_pdf m-0 p-0 bg-white p-2  text-left">
                                      {/* <a className="px-2 d-inline-block" href="#"><img src={pmc} alt="pmc" /></a>
                                  <a className="px-2 d-inline-block" href="#"><img src={pdf} alt="pmc" /></a>
                                  <a className="px-2 d-inline-block" href="#"><img src={pdf} alt="pmc" /></a>
                                  <a className="px-2 d-inline-block" href="#"><img src={word} alt="pmc" /></a>
                                  <a className="px-2 d-inline-block" href="#"><img src={excel} alt="pmc" /></a> */}
                                      {patient?.medicalrecords?.map((items: any) => {
                                        const filename = JSON.parse(items.documents);
                                        const splitfilename = filename.split('.')[1]
                                        const documentName = filename.split('/')[filename.split('/').length - 1]
                                        return (

                                          patient?.medicalrecords?.length >= 3 &&
                                          <a onClick={() => handleDownload(patient.id, documentName)}>
                                            {splitfilename === "txt" ?
                                              <a title={documentName} className="px-2 d-inline-block"><img src={text} alt="pmc" /></a>
                                              :
                                              splitfilename === "docx" || splitfilename === "doc" || splitfilename === "DOC" ? <a title={documentName} className="px-2 d-inline-block"><img src={Doc} alt="pmc" /></a>
                                                : splitfilename === "pdf" || splitfilename === "PDF" ? <a title={documentName} className="px-2 d-inline-block"><img src={pdf} alt="pmc" /></a>
                                                  : splitfilename === "word" || splitfilename === "WORD" ? <a title={documentName} className="px-2 d-inline-block"><img src={word} alt="pmc" /></a>
                                                    : splitfilename === "png" || splitfilename === "PNG" ? <a title={documentName} className="px-2 d-inline-block"><img src={PNG} alt="pmc" /></a>
                                                      : splitfilename === "sql" || splitfilename === "SQL" ? <a title={documentName} className="px-2 d-inline-block"><img src={Sql} alt="pmc" /></a>
                                                        : splitfilename === "jpeg" || splitfilename === "JPEG" ? <a title={documentName} className="px-2 d-inline-block"><img src={Jpeg} alt="pmc" /></a>
                                                          : splitfilename === "jpg" || splitfilename === "JPG " ? <a title={documentName} className="px-2 d-inline-block"><img src={Jpg} alt="pmc" /></a>
                                                            : null

                                            }
                                            {/* <a className="px-2 d-inline-block" href="#"><img src={pmc} alt="pmc" /></a>
                                <a className="px-2 d-inline-block" href="#"><img src={pdf} alt="pmc" /></a>
                                <a className="px-2 d-inline-block" href="#"><img src={pdf} alt="pmc" /></a> */}
                                          </a>

                                        )
                                      })}
                                    </ul>
                                  </button>
                                }

                                {/* <a className="px-2 d-inline-block" href="#"><img src={pmc} alt="pmc" /></a>
                          <a className="px-2 d-inline-block" href="#"><img src={pdf} alt="pmc" /></a>
                          <a className="px-2 d-inline-block" href="#"><img src={pdf} alt="pmc" /></a>
                          <a className="px-2 d-inline-block" href="#"><img src={word} alt="pmc" /></a>
                          <a className="px-2 d-inline-block" href="#"><img src={excel} alt="pmc" /></a> */}
                              </td>
                              {/* <td><span className="dost_btn"><i className="bi bi-three-dots-vertical"></i></span>
                          <span className="action" >
                            <button className="edit_btn"><i className="bi bi-pen"></i></button>
                            <button className="exclamation_btn"><i className="bi bi-cloud-arrow-up"></i></button>
                            <button className="delete_btn_act"><i className="bi bi-trash"></i></button>
                          </span>
                          
                    </td> */}
                              <td>
                                <span className="action" >
                                  <CustomToolTip title="Download">
                                  <button
                                    className="exclamation_btn"
                                    onClick={() => handleZip(patient)}
                                  >
                                    <i className="bi bi-cloud-arrow-down" />
                                  </button>
                                  </CustomToolTip>
                                </span>
                              </td>
                            </tr>

                          </>
                        )
                      })}
                    </>
                    :
                    <>
                      <tr className="list_detail">
                        {!loading ?
                          <td colSpan={10}>No data found</td>
                          :
                          <td colSpan={10}><Spinner /></td>
                        }
                      </tr>
                      :
                    </>
                  }
                    </tbody>
                    {/* {pages?.length > 1 &&
                <tfoot>
                  <tr className="list_detail pagination_list">
                    <td colSpan={7}>
                      <ul>
                        <li onClick={() => handlePagination(pageNumber - 1)}><a href="#"><i className="bi bi-arrow-left"></i></a></li>
                        {pages.map((page: number) => {
                          return (
                            <li className={pageNumber == page ? "active" : ""} onClick={() => handlePagination(page)}><a>{page}</a></li>
                          )
                        })}
                        <li onClick={() => handlePagination(pageNumber + 1)}><a href="#"><i className="bi bi-arrow-right"></i></a></li>
                      </ul>
                    </td>
                  </tr>
                </tfoot>
              } */}
                  </table>
                </div>
              </div>
            }
            <Modal show={show} onHide={() => handleClose()}>
              <Modal.Header closeButton>
                <Modal.Title>Delete Patient</Modal.Title>
              </Modal.Header>
              <Modal.Body>Do you want delete this patient ?</Modal.Body>
              <Modal.Footer>

                <button className="bg_gray_500 rounded border-0 py-2 px-4" onClick={() => handleClose()}>
                  Cancel
                </button>
                <button className="bg_gradiant text-white rounded border-0 py-2 px-4" onClick={() => handleDelete(deleteId)}>
                  Delete
                </button>
              </Modal.Footer>
            </Modal>
          </Col>
        {/* <Col>
            <div className="row d-flex table-responsive m-0">
            <table>
                <tr className="list_head">
                  <th><label className="p-0 bg-transparent" htmlFor="name">Name</label></th>
                  <th>Address</th>
                  <th>Phone number</th>
                  <th>Last Record Date</th>
                  <th>Medical Record Files</th>
                  <th>Actions</th>
                </tr>
                <tr className="list_detail">
                    <td><label htmlFor="name2">Lucy Black</label></td>
                    <td>Houston, TX</td>
                    <td> <a href="tel:(123) 456 7890">(123) 456 7890</a></td>
                    <td className="poppins-600">November 12, 2023</td>
                    <td className="record_img">
                        <a className="px-2 d-inline-block" href="#"><img src={pmc} alt="pmc" /></a>
                        <a className="px-2 d-inline-block" href="#"><img src={pdf} alt="pmc" /></a>
                        <a className="px-2 d-inline-block" href="#"><img src={pdf} alt="pmc" /></a>
                        <a className="px-2 d-inline-block" href="#"><img src={word} alt="pmc" /></a>
                        <a className="px-2 d-inline-block" href="#"><img src={excel} alt="pmc" /></a>
                    </td>
                    <td><span className="dost_btn"><i className="bi bi-three-dots-vertical"></i></span>
                          <span className="action" >
                            <button className="edit_btn"><i className="bi bi-pen"></i></button>
                            <button className="exclamation_btn"><i className="bi bi-cloud-arrow-up"></i></button>
                            <button className="delete_btn_act"><i className="bi bi-trash"></i></button>
                          </span>
                          
                    </td>
                </tr>
            
                <tr className="list_detail pagination_list">
                        <td colSpan={7}>
                          <ul>
                            <li><a href="#"><i className="bi bi-arrow-left"></i></a></li>
                            <li className="active"><a href="#">1</a></li>
                            <li><a href="#">2</a></li>
                            <li><a href="#">3</a></li>
                            <li><a href="#">4</a></li>
                            <li><a href="#">5</a></li>
                            <li><a href="#"><i className="bi bi-arrow-right"></i></a></li>
                          </ul>
                        </td>
                </tr>
                </table>
                
            </div>
        </Col> */}
    </Row>
  );
};

export default Medical;