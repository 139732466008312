import { Modal } from "react-bootstrap";
import DoctorAvailabilityForm from "../pages/doctorpanel/DoctorAvailabilityForm";

export default function AvailabilityModal(props: any) {
    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DoctorAvailabilityForm handleClose={props.handleClose} availabilityId={props.availabilityId} handleSave={props.handleSave}/>
            </Modal.Body>
        </Modal>
    )

}