import React, { useContext, useEffect, useState } from 'react'
import { getoneuser, updateprofile } from "../../../../services/emailsetting-service";
import { useLocation, useNavigate } from 'react-router-dom'

import { RoleContext } from '../../../../utils/contexts/RoleContext';

function EditEmailSettings() {

  const location = useLocation();
  const currentUser = useContext(RoleContext);


  const [err, setErr] = useState<string>("")
  const [values, setValues] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [content, setContent] = useState<string>('');
  const navigate = useNavigate();
  // const history = useHistory()

  useEffect(() => {
    getUserDetails(location?.state?.key);
  }, [])


  const getUserDetails = (key:string) => {

    getoneuser(key).then(
        (data) => {
           
            const user = data.allUsers;
          setValues({ ...user });
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
          setLoading(false);
          setMessage(resMessage);
        }
      );





 

  }

  const FormOnChange = (event:any) => {
    const name = event.target.name;
    const value = event.target.value;
    setValues({ ...values, [name]: value });
  }



  const clickSubmit = (e:any) => {
    e.preventDefault();
    

    updateprofile(location?.state?.key,values).then(
        (data) => {
            navigate(`/${currentUser}/email_setting`);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
          setLoading(false);
          setMessage(resMessage);
        }
      );

  

  }
  return (
    <div className="container pt-5">
        <div className="card email-container">
     <div style={{fontWeight:"bold",fontSize:23,marginBottom:40}}>Edit Email Setting</div>
    
      {
        err ?
          <div className="alert alert-danger alert-dismissible fade show" role="alert">
            <strong>{err}</strong>
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div> : ""
      }


      <form onSubmit={clickSubmit} className='form-grid'>

        <div className="form-group">
          <label className="text-muted">From Email</label>
          <input type="email" className="form-control" name='fromemail' defaultValue={values.from_email} onChange={FormOnChange} />
        </div>
        <div className="form-group">
          <label className="text-muted"> To Email</label>
          <input type="email" className="form-control" name='toemail' defaultValue={values.to_email} onChange={FormOnChange} />
        </div>

        <div className="form-group">
          <label className="text-muted"> Auth User</label>
          <input type="text" className="form-control" name='authuser' defaultValue={values.auth_user} onChange={FormOnChange} />
        </div>
        <div className="form-group">
          <label className="text-muted"> Auth Password</label>
          <input type="password" className="form-control" name='authpassword' defaultValue={values.auth_password} onChange={FormOnChange} />
        </div>
        <div className="form-group">
          <label className="text-muted"> Port</label>
          <input type="text" className="form-control" name='port' defaultValue={values.port} onChange={FormOnChange} />
        </div>
        <button className="btn btn-primary">
          Update
        </button>
       
      </form>
      </div>
    </div>
  )
}

export default EditEmailSettings












