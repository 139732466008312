import React, { useContext, useEffect, useState } from 'react'
import { createadminsetting, deleteoneadmin, getalladmin, getoneadmin, updateadminsetting } from '../../../services/adminsetting-service';
import { getoneuser, updateprofile } from "../../../services/user.service";
import { useLocation, useNavigate } from 'react-router-dom'

import { Dropdown } from 'react-bootstrap';
import { RoleContext } from '../../../utils/contexts/RoleContext';

function EditGeneralSetting() {

  const location = useLocation();
  const currentUser = useContext(RoleContext);




  const [err, setErr] = useState<string>("")
  const [values, setValues] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
const [dropDownValue, setDropDownValues] = useState("")

  const navigate = useNavigate();
  // const history = useHistory()

  useEffect(() => {
    getUserDetails(location?.state?.key);
  }, [])


  const getUserDetails = (key:string) => {

    getoneadmin(key).then(
        (data) => {
            const user = data.admin;
setDropDownValues(data.admin.setting_type)
          setValues({ ...user })
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
          setLoading(false);
          setMessage(resMessage);
        }
      );





 

  }

  const handleSelect = (event: any) => {
    setDropDownValues(event)

  };

  const FormOnChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setValues({ ...values, [name]: value });
  }



  const clickSubmit = (e: any) => {
    e.preventDefault();

    values.setting_type = dropDownValue

    updateadminsetting(location?.state?.key, values).then(
        (data) => {
            navigate("/"+currentUser+location?.state?.path);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
          setLoading(false);
          setMessage(resMessage);
        }
      );

  

  }
  return (
    <div className="container pt-5">
    
    
      {
        err ?
          <div className="alert alert-danger alert-dismissible fade show" role="alert">
            <strong>{err}</strong>
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div> : ""
      }


      <form onSubmit={clickSubmit} className='form-grid'>

        {/* <div className="form-group">
          <label className="text-muted"></label>
          <input type="text" className="form-control" name='firstName' defaultValue={values.firstName} onChange={FormOnChange} />
        </div>
        <div className="form-group">
          <label className="text-muted">Last Name</label>
          <input type="text" className="form-control" name='lastName' defaultValue={values.lastName} onChange={FormOnChange} />
        </div>
        <div className="form-group">
          <label className="text-muted">Email</label>
          <input type="email" className="form-control" name='email' defaultValue={values.email} onChange={FormOnChange} />
        </div> */}
          <div className="form-group col-md-6" style={{ width: "50%", margin: "10px 0;" }}  >
                <label style={{ fontWeight: "bold", margin: "10px 0" }}>Setting Label</label>
                <input type="text" name='setting_label' defaultValue={values.setting_label} className="form-control" id="exampleInputEmail1" onChange={(event: any) => FormOnChange(event)}  ></input><br></br>
                <label style={{ fontWeight: "bold", margin: "10px 0" }}>Setting Key</label>
                <input type="text" name='setting_key' defaultValue={values.setting_key} className="form-control" id="exampleInputEmail1" onChange={(event: any) => FormOnChange(event)} ></input><br></br>
                <label style={{ fontWeight: "bold", margin: "10px 0" }}>Setting Value</label>
                <input type="text" name='setting_value' defaultValue={values.setting_value} className="form-control" id="exampleInputEmail1" onChange={(event: any) => FormOnChange(event)} ></input><br></br>


        </div>
        <Dropdown
            onSelect={handleSelect}
          >
            <Dropdown.Toggle id="dropdown-basic" style={{ width: "15%", backgroundColor: "white", color: "black", borderColor: "black", marginLeft: 15 }}>
              {dropDownValue === "" ? "select Types" : dropDownValue}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item eventKey={"General"} >General</Dropdown.Item>
              <Dropdown.Item eventKey={"Payment"}>Payment</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>



                <button className="btn btn-primary">
          Submit
        </button>
                    
      </form>
    </div>
  )
}

export default EditGeneralSetting

