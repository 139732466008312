import user from "../../../assets/images/billing/user.png";
import money_card from "../../../assets/images/billing/money-card.png";
import addressIcon from "../../../assets/images/billing/address.png";
import invoice from "../../../assets/images/billing/invoice.png";
import {
    Row,
    Col,
} from "reactstrap";
import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { getOneOrder } from "../../../services/billing-service";
import { longDateGenerator } from "../../../utils/utils";
import Spinner from 'react-bootstrap/Spinner';
import { RoleContext } from "../../../utils/contexts/RoleContext";
import constants from "../../../config/constants";
import { capitalizeFirstLetter } from "../../../utils/utils";

const BillingDetail = () => {
    const [order, setOrder] = useState<any>({});
    const [address, setAddress] = useState<any>({});
    const [transaction, setTransaction] = useState<any>({});
    const [loading, setLoading] = useState(false);

    const currentUser = useContext(RoleContext);

    const { orderId }: any = useParams();
    useEffect(() => {
        fetchOneOrder();
        document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Billing Detail - ${orderId}`;
    }, [currentUser]);

    async function fetchOneOrder() {
        setLoading(true);
        const response = await getOneOrder(orderId);
        if (response?.success) {
            const billingAddress = [...response.data.billingAddresses];
            const lastBillingAddress = billingAddress[billingAddress.length - 1];
            const transaction = [...response.data.transactions];
            const lastTransactions = transaction[transaction?.length - 1];
            setOrder(response.data);
            setAddress(lastBillingAddress);
            setTransaction(lastTransactions);
        }
        setLoading(false);
    }

    return (
        <>
            {!loading ?
        <Row>
            <div className="panel_top_section position-relative border-0">
                <h1>Billing/ Payments</h1> <span className="textgreen poppins-500"><i className="bi bi-chevron-right"></i> Payment Detail </span>
                <div className="col-sm-12 d-xl-flex justify-content-between align-items-end">
                    <div className="row m-0 col-xl-12 float-start dashboard_box_biling radius_15 mt-4 py-4 py-lg-0">
                        <div className="col-xl-4 payment_box p-1 px-3 py-2 py-lg-4">
                            <div className="payment_box_inner border-r float-start w-100  text-center   py-2 py-lg-4">
                                <div className="payment_receive float-start w-100 pb-2">
                                    <p className="d-inline-block m-0 poppins-600 text-white fs-5 px-1 pr-0"> Total Payment</p>
                                </div>
                                <div className="box_price float-start w-100  py-1">
                                            <h2 className="text-white m-0 fs-1 poppins-600">$ {order?.totalAmount}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 payment_box p-1 px-3  py-2 py-lg-4">
                            <div className="payment_box_inner border-r float-start w-100  text-center   py-2 py-lg-4">
                                <div className="payment_receive float-start w-100 pb-2">
                                    <p className="d-inline-block m-0 poppins-600 text-white fs-5 px-1 pr-0"> Payment Received </p>
                                </div>
                                <div className="box_price float-start w-100  py-1">
                                            <h2 className="text-white m-0 fs-1 poppins-600">$ {order?.amountPaid}</h2>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 payment_box p-1 px-3  py-2 py-lg-4">
                            <div className="payment_box_inner  float-start w-100  text-center   py-2 py-lg-4">
                                <div className="payment_receive float-start w-100 pb-2">
                                    <p className="d-inline-block m-0 poppins-600 text-white fs-5 px-1 pr-0"> Balance </p>
                                </div>
                                <div className="box_price float-start w-100  py-1">
                                            <h2 className="text-white m-0 fs-1 poppins-600">$ {order?.totalAmount - order?.amountPaid} </h2>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-sm-3 grid_icons d-flex gap-1 pt-2 justify-content-around align-items-center float-end">
                  <button type="button" className="btn_gradian dark_btn">Process Payment</button>
                  <div className="grid_list_icons icons_patients  d-flex align-items-center gap-2">
                  <button type="button" className="delet_patiens"> <i className="bi bi-trash"></i> </button>
                    
                    <span className="filter_icons fs-3 px-1"><i className="bi bi-funnel-fill"></i></span>
                    </div>
                </div> */}
                </div>
            </div>
            <Col>
                <div className="row d-flex table-responsive m-0">
                    {/* Patient Details */}
                    <div className="col-xl-6 p-2">
                        <div className="inner_location float-start w-100 bg-white  p-4 pt-4 radius_15">
                            <div className="col-sm-12">
                                <span className="col-sm-1 float-start d-inline-block billing_icons">
                                    <img src={user} alt="user" />
                                </span>
                                <div className="col-sm-11 float-start px-2">
                                    <h4 className="dark_blue   poppins-600 fs-5 pt-2">Patient Details</h4>
                                    <div className="pat_group py-3 d-flex ">
                                        <div className="row_1 col-sm-4 px-1">
                                            <span className="d-block text_sm text_gray ">First Name</span>
                                            <p className="m-0 poppins-500 dark_blue fs-6">{order?.user?.firstName}</p>
                                        </div>
                                        <div className="row_1 col-sm-4 px-1">
                                            <span className="d-block text_sm text_gray ">Last Name</span>
                                            <p className="m-0 poppins-500 dark_blue fs-6">{order?.user?.lastName}</p>
                                        </div>
                                    </div>
                                    <div className="pat_group py-3 d-flex ">
                                        <div className="row_1 col-sm-4 px-1">
                                            <span className="d-block text_sm text_gray ">Phone</span>
                                            <p className="m-0 poppins-500 dark_blue fs-6">{order?.user?.phoneNumber}</p>
                                        </div>
                                        <div className="row_1 col-sm-6 px-1">
                                            <span className="d-block text_sm text_gray ">Email</span>
                                            <p className="m-0 poppins-500 dark_blue fs-6 text-break">{order?.user?.email}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Payment Details */}
                    <div className="col-xl-6 p-2">
                        <div className="inner_location float-start w-100 bg-white  p-4 pt-4 radius_15">
                            <div className="col-sm-12">
                                <span className="col-sm-1 float-start d-inline-block billing_icons">
                                    <img src={money_card} alt="user" />
                                </span>
                                <div className="col-sm-11 float-start px-2">
                                    <h4 className="dark_blue   poppins-600 fs-5 pt-2">Payment Details</h4>
                                    <div className="pat_group py-3 d-flex ">
                                        <div className="row_1 col-sm-4 px-1">
                                            <span className="d-block text_sm text_gray ">Payment Method</span>
                                                    <p className="m-0 poppins-500 dark_blue fs-6">{transaction?.paymentMethod}</p>
                                        </div>
                                        <div className="row_1 col-sm-4 px-1">
                                            <span className="d-block text_sm text_gray ">Transaction ID</span>
                                                    <p className="m-0 poppins-500 dark_blue fs-6">{transaction?.transactionId}</p>
                                        </div>
                                        <div className="row_1 col-sm-4 px-1">
                                            <span className="d-block text_sm text_gray ">Date Received</span>
                                                    <p className="m-0 poppins-500 dark_blue fs-6">{longDateGenerator(transaction?.transactionDate)}</p>
                                        </div>
                                    </div>
                                    <div className="pat_group py-3 d-flex ">
                                        {/* <div className="row_1 col-sm-4 px-1">
                                            <span className="d-block text_sm text_gray ">Cardholder Name</span>
                                            <p className="m-0 poppins-500 dark_blue fs-6">{transaction?.cardHolder}</p>
                                        </div>
                                        <div className="row_1 col-sm-4 px-1">
                                            <span className="d-block text_sm text_gray ">Card Number</span>
                                            <p className="m-0 poppins-500 dark_blue fs-6">{transaction?.cardNumber}</p>
                                        </div> */}
                                        <div className="row_1 col-sm-4 px-1">
                                            <span className="d-block text_sm text_gray ">Transaction Status</span>
                                                    <p className="m-0 poppins-500 dark_blue fs-6">{transaction?.paymentStatus == 1 ? "Completed" : "Failed"}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Billing Address */}
                    <div className="col-xl-6 p-2">
                        <div className="inner_location float-start w-100 bg-white  p-4 pt-4 radius_15">
                            <div className="col-sm-12">
                                <span className="col-sm-1 float-start d-inline-block billing_icons">
                                    <img src={addressIcon} alt="user" />
                                </span>
                                <div className="col-sm-11 float-start px-2">
                                    <h4 className="dark_blue   poppins-600 fs-5 pt-2">Billing Address</h4>
                                    <div className="pat_group py-3 d-flex ">
                                        <div className="row_1 col-sm-4 px-1">
                                            <span className="d-block text_sm text_gray ">Full Name</span>
                                                    <p className="m-0 poppins-500 dark_blue fs-6">{address?.name}</p>
                                        </div>
                                        <div className="row_1 col-sm-4 px-1">
                                            <span className="d-block text_sm text_gray ">Phone</span>
                                                    <p className="m-0 poppins-500 dark_blue fs-6">{address?.phoneNumber}</p>
                                        </div>

                                        <div className="row_1 col-sm-4 px-1">
                                            <span className="d-block text_sm text_gray ">Email</span>
                                                    <p className="m-0 poppins-500 dark_blue text-break">{address?.email}</p>
                                        </div>

                                    </div>
                                    <div className="pat_group py-3 d-flex ">
                                        <div className="row_1 col-sm-4 px-1">
                                            <span className="d-block text_sm text_gray ">Address</span>
                                                    <p className="m-0 poppins-500 dark_blue fs-6">{address?.address}</p>
                                        </div>
                                        <div className="row_1 col-sm-4 px-1">
                                            <span className="d-block text_sm text_gray ">City</span>
                                                    <p className="m-0 poppins-500 dark_blue fs-6">{address?.city}</p>
                                        </div>
                                        <div className="row_1 col-sm-4 px-1">
                                            <span className="d-block text_sm text_gray ">ZIP Code</span>
                                                    <p className="m-0 poppins-500 dark_blue fs-6">{address?.pinCode}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Payment Summary */}
                    <div className="col-xl-6 p-2">
                        <div className="inner_location float-start w-100 bg-white  p-4 pt-4 radius_15">
                            <div className="col-sm-12">
                                <span className="col-sm-1 float-start d-inline-block billing_icons">
                                    <img src={invoice} alt="user" />
                                </span>
                                <div className="col-sm-11 float-start px-2">
                                    <h4 className="dark_blue   poppins-600 fs-5 pt-2">Payment Summary</h4>
                                    <div className="pat_group py-3 d-flex ">
                                        <div className="row_1 col-sm-4 px-1">
                                            <span className="d-block text_sm text_gray ">Services Provided</span>
                                                    <p className="m-0 poppins-500 dark_blue fs-6">{order?.service}</p>
                                        </div>
                                        <div className="row_1 col-sm-4 px-1">
                                            <span className="d-block text_sm text_gray ">Discounts & Offers</span>
                                                    <p className="m-0 poppins-500 dark_blue fs-6">$ {order?.totalTax}</p>
                                        </div>
                                    </div>
                                    <div className="pat_group py-3 d-flex ">
                                        <div className="row_1 col-sm-4 px-1">
                                            <span className="d-block text_sm text_gray ">Tax</span>
                                                    <p className="m-0 poppins-500 dark_blue fs-6">$ {order?.totalDiscount}</p>
                                        </div>
                                        <div className="row_1 col-sm-4 px-1">
                                            <span className="d-block text_sm text_gray ">Total Amount</span>
                                                    <p className="m-0 poppins-600 dark_blue fs-6">$ {order?.totalAmount}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
                :
                <div className='m-5 justify-content-center d-flex'>
                    <Spinner />
                </div>
            }
        </>
    );
};

export default BillingDetail;