import { Row, Col } from "reactstrap";
import SearchInput from "../../components/SearchInput";
import { useState, useContext, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { RoleContext, AlertContext } from "../../utils/contexts/RoleContext";
import { getOneHospitalInvoice } from "../../services/billing-service";
import { usePDF } from 'react-to-pdf';
import Logo from "../../layouts/Logo";
import { getDateOnly } from "../../utils/utils";
import { getOneSetting } from "../../services/adminsetting-service";

export default function InvoicesInfo() {
    const [loading, setLoading] = useState(false);
    const [invoice, setInvoice] = useState<any>({});
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const { toPDF, targetRef } = usePDF({ filename: 'invoice.pdf' });
    const [show, setShow] = useState(false);
    const [address, setAddress] = useState("");


    const navigate = useNavigate();
    const currentUser = useContext(RoleContext);
    const toast: any = useContext(AlertContext);
    const { invoiceId } = useParams()

    useEffect(() => {
        fetchHospitalInvoice();
        getAdminAddress();
    }, []);

    async function fetchHospitalInvoice() {
        const response = await getOneHospitalInvoice(invoiceId);
        if (response?.success) {
            const invoiceDetail = { ...response?.data };
            console.log("invoiceDetail", invoiceDetail)
            invoiceDetail.hospital_transactions?.forEach((item: any) => {
                if (item.paymentStatus == 1) {
                    invoiceDetail.transactionId = item.transactionId;
                    invoiceDetail.transactionDate = getDateOnly(new Date(item.transactionDate));
                }
            });
            setInvoice(invoiceDetail);
        }
    }

    async function getAdminAddress() {
        const response = await getOneSetting("invoice_billing_address");
        if (response?.success) {
            setAddress(response?.data?.settingsValue)
        }
    }

    return (
        <Row>
            <div className="panel_top_section position-relative">
                <h1>Invoice Detail</h1>
                <span className="textgreen poppins-500 invoice-header-text"><i className="bi bi-chevron-right"></i> #{invoice?.invoiceNumber} </span>
                <span
                    className="ms-3 fs-5 clickable float-end mt-2"
                    onClick={() => {
                        setShow(true);
                        document.body.style.overflow = "hidden"
                        setTimeout(() => {
                            toPDF();
                        }, 1000)
                        setTimeout(() => {
                            setShow(false);
                            document.body.style.overflow = "scroll"
                        }, 2000)
                    }}
                >
                    <i className="bi bi-printer" />
                </span>
                {/* <div className="grid_icons d-flex gap-1 align-items-center float-end">
                    <button
                        type="button"
                        className="btn_gradian"
                        onClick={() => navigate(`/${currentUser}/add-invoice`)}
                    >
                        + Add Invoices
                    </button>
                    <div className="grid_list_icons icons_patients  d-flex align-items-center gap-2">
                        <SearchInput
                            visible={showSearch}
                            data={rowData}
                            setData={(value: any) => setInvoices(value)}
                        />
                        <span
                            className="filter_icons fs-3 px-1"
                            onClick={() => setShowSearch(!showSearch)}
                        >
                            <i className="bi bi-funnel-fill" />
                        </span>
                    </div>
                </div> */}
            </div>
            <Col>
                <div>
                    <div className="p-4 bg-white">
                        <div className="d-md-flex d-lg-block d-xl-flex justify-content-between  align-item-center border-bottom border-4 border-bottom-success p-4 m-auto flex-wrap text-center">
                            <div>
                                <Logo />
                            </div>
                            <div className="mt-2 mt-md-0">
                                <h4>Invoice</h4>
                            </div>
                            <div className="tex-align-left">
                                <p className="fw-bolder invoice-header-text">Invoice Number: <span className="fw-lighter">{invoice?.invoiceNumber}</span></p>
                                <p className="fw-bolder invoice-header-text">Date: <span className="fw-lighter">{invoice?.invoiceDate}</span></p>
                            </div>
                        </div>
                        <div className="d-sm-flex pt-5">
                            <div className="w-sm-50 w-100">
                                <h5 className="mb-2">Invoice From</h5>
                                {address?.split(";").map((item, index) => <p className="mt-1">{item}{index == address?.split(";")?.length - 1 ? "." : ","}</p>)}
                            </div>
                            <div className="w-sm-50 mt-sm-0 mt-3 w-100">
                                <h5 className="mb-2">Invoice To</h5>
                                <p className="mt-1">{invoice?.hospitalName}</p>
                                <p className="mt-1">{invoice?.hospitalEmail}</p>
                                <p className="mt-1">{`${invoice?.streetAddress ? invoice?.streetAddress : "" + invoice?.city ? ", " + invoice?.city + ", " : "" + invoice?.pinCode ? ", " + invoice?.pinCode + ", " : ""}`}</p>
                            </div>
                        </div>
                        {invoice?.transactionId &&
                            <div className="mt-5">
                                <p className="fw-bolder">Transaction ID : <span className="fw-lighter">#{invoice?.transactionId}</span></p>
                                <p className="fw-bolder">Transaction Date : <span className="fw-lighter">{invoice?.transactionDate}</span></p>
                            </div>
                        }
                        <div className="mt-5 row d-flex table-responsive">
                            <table>
                                <thead>
                                    <tr className="list_head">
                                        <th>Item Name</th>
                                        <th>Quantity</th>
                                        <th>Price</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(invoice)?.length > 0 ?
                                        <>
                                            {JSON.parse(invoice?.items)?.map((item: any, index: number) => {
                                                return (
                                                    <tr className="list_detail" key={index}>
                                                        <td>{item.itemName}</td>
                                                        <td>{item.quantity}</td>
                                                        <td>$ {item.price?.toFixed(2)}</td>
                                                        <td>$ {item.total?.toFixed(2)}</td>
                                                    </tr>
                                                )
                                            })}
                                            {/* <tr className="list_detail">
                                                <td colSpan={3} className="text-end">Sub Total</td>
                                                <td>$ {Number(invoice?.subTotal)?.toFixed(2)}</td>
                                            </tr>
                                            <tr className="list_detail">
                                                <td colSpan={3} className="text-end">Total Tax</td>
                                                <td>$ {Number(invoice?.totalTax)?.toFixed(2)}</td>
                                            </tr>
                                            <tr className="list_detail">
                                                <td colSpan={3} className="text-end">Grand Total</td>
                                                <td>$ {Number(invoice?.grandTotal)?.toFixed(2)}</td>
                                            </tr> */}
                                        </>
                                        :
                                        <tr className="list_detail">
                                            <td colSpan={5}>No items added yet.</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="mt-5 d-flex justify-content-end">
                            <div className="col-lg-4">
                                <div className="d-flex justify-content-between mt-2">
                                    <p className="fw-bolder">Sub Total :</p>
                                    <p>$ {invoice?.subTotal?.toFixed(2)}</p>
                                </div>
                                <div className="d-flex justify-content-between mt-2">
                                    <p className="fw-bolder">Tax :</p>
                                    <p>$ {invoice?.totalTax?.toFixed(2)}</p>
                                </div>
                                {/* <div className="d-flex justify-content-between mt-2">
                                    <p className="fw-bolder">Discount :</p>
                                    <p>$ {order?.totalDiscount?.toFixed(2)}</p>
                                </div> */}
                                <div className="d-flex justify-content-between mt-2">
                                    <p className="fw-bolder">Grand Total :</p>
                                    <p>$ {invoice?.grandTotal?.toFixed(2)}</p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="mt-5 text-center fw-bold fs-5">
                            Thank You for choosing us!
                        </div> */}
                    </div>
                </div>
                {show &&
                    <div ref={targetRef}>
                        <div className="p-5 bg-white">
                            <div className="d-flex justify-content-between align-item-center border-bottom border-4 border-bottom-success p-4">
                                <div>
                                    <Logo />
                                </div>
                                <div>
                                    <h4>Invoice</h4>
                                </div>
                                <div>
                                    <p>Invoice Number: {invoice?.invoiceNumber}</p>
                                    <p>Date: {invoice?.invoiceDate}</p>
                                </div>
                            </div>
                            <div className="d-flex pt-5">
                                <div className="w-50">
                                    <h5 className="mb-2">Invoice From</h5>
                                    <p className="mt-1">Admin,</p>
                                    <p className="mt-1">iBoost Healthcare</p>
                                    {/* <p className="mt-1">{`${address?.address}, ${address?.city}, ${address?.pinCode}.`}</p>
                            <p className="mt-1">{address?.phoneNumber}</p> */}
                                </div>
                                <div className="w-50">
                                    <h5 className="mb-2">Invoice To</h5>
                                    <p className="mt-1">{invoice?.hospitalName}</p>
                                    <p className="mt-1">{invoice?.hospitalEmail}</p>
                                    <p className="mt-1">{`${invoice?.streetAddress ? invoice?.streetAddress : "" + invoice?.city ? ", " + invoice?.city + ", " : "" + invoice?.pinCode ? ", " + invoice?.pinCode + ", " : ""}`}</p>
                                    {/* <p className="mt-1">{address?.phoneNumber}</p> */}
                                </div>
                            </div>
                            {invoice?.transactionId &&
                                <div className="mt-5">
                                    <p className="fw-bolder">Transaction ID : <span className="fw-lighter">#{invoice?.transactionId}</span></p>
                                    <p className="fw-bolder">Transaction Date : <span className="fw-lighter">{invoice?.transactionDate}</span></p>
                                </div>
                            }
                            <div className="mt-5 row d-flex">
                                <table>
                                    <thead>
                                        <tr className="list_head">
                                            <th>Item Name</th>
                                            <th>Quantity</th>
                                            <th>Price</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(invoice)?.length > 0 ?
                                            <>
                                                {JSON.parse(invoice?.items)?.map((item: any, index: number) => {
                                                    return (
                                                        <tr className="list_detail" key={index}>
                                                            <td>{item.itemName}</td>
                                                            <td>{item.quantity}</td>
                                                            <td>$ {item.price?.toFixed(2)}</td>
                                                            <td>$ {item.total?.toFixed(2)}</td>
                                                        </tr>
                                                    )
                                                })}
                                                <tr className="list_detail">
                                                    <td colSpan={3} className="text-end">Sub Total</td>
                                                    <td>$ {Number(invoice?.subTotal)?.toFixed(2)}</td>
                                                </tr>
                                                <tr className="list_detail">
                                                    <td colSpan={3} className="text-end">Total Tax</td>
                                                    <td>$ {Number(invoice?.totalTax)?.toFixed(2)}</td>
                                                </tr>
                                                <tr className="list_detail">
                                                    <td colSpan={3} className="text-end">Grand Total</td>
                                                    <td>$ {Number(invoice?.grandTotal)?.toFixed(2)}</td>
                                                </tr>
                                            </>
                                            :
                                            <tr className="list_detail">
                                                <td colSpan={5}>No items added yet.</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="mt-5 d-flex justify-content-end">
                                <div className="col-lg-4">
                                    <div className="d-flex justify-content-between mt-2">
                                        <p className="fw-bolder">Sub Total :</p>
                                        <p>$ {invoice?.subTotal?.toFixed(2)}</p>
                                    </div>
                                    <div className="d-flex justify-content-between mt-2">
                                        <p className="fw-bolder">Tax :</p>
                                        <p>$ {invoice?.totalTax?.toFixed(2)}</p>
                                    </div>
                                    {/* <div className="d-flex justify-content-between mt-2">
                                <p className="fw-bolder">Discount :</p>
                                <p>$ {order?.totalDiscount?.toFixed(2)}</p>
                            </div> */}
                                    <div className="d-flex justify-content-between mt-2">
                                        <p className="fw-bolder">Grand Total :</p>
                                        <p>$ {invoice?.grandTotal?.toFixed(2)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 text-center fw-bold fs-5">
                                Thank You for choosing us!
                            </div>
                        </div>
                    </div>
                }
            </Col>
        </Row>
    )
}