import React, { useEffect, useState, useContext, useRef } from 'react';

import IUser from '../../types/user.type';
import { IoIosArrowForward } from "react-icons/io";
import { bill_image } from "../../assets/config/constants"
import { doctor_image } from "../../assets/config/constants"
import { getallDoctor } from "../../services/doctor-service";
import { getallpatient, getIncreasedPatientPercentage } from '../../services/patient-service';
import { total_image } from "../../assets/config/constants";
import { RoleContext, HospitalContext } from "../../utils/contexts/RoleContext";
import { useNavigate, createSearchParams } from 'react-router-dom';
import { ImagePath, decideUserNavigation, getCurrentUser } from '../../services/auth.service';
import { getAllBookingAppointment, getLastAppointmentOfUser } from '../../services/scheduleappointment-service';
import { getorders, getSubscriptionDetails, getHospitalInvoices } from '../../services/billing-service';
import { longDateGenerator } from '../../utils/utils';
import { createChatUser, getChatUsers } from '../../services/messages-service';
import user1 from "../../assets/images/users/user1.jpg";
import constants from '../../config/constants';
import { capitalizeFirstLetter } from '../../utils/utils';
import { getHospitals } from '../../services/user.service';
import { getClusters } from '../../services/user.service';
import Appointment from "../../assets/images/icons/Appointment.webp";
import Chart from 'chart.js/auto';


function AdminDashboard() {
    const [doctors, setDoctors] = useState([]);
    const [patients, setPatients] = useState([]);
    const [allPatients, setAllPatients] = useState([]);
    const [search, setSearch] = useState([]);
    const [chatUsers, setChatUsers] = useState<any>([]);
    const [allBooking, setAllBooking] = useState(0);
    const [paymentDetails, setPaymentDetails] = useState<any>({});
    const [patientsInfo, setPatientsInfo] = useState<any>({});
    const [hospitals, setHospitals] = useState([]);
    const [patientCount, setPatientCount] = useState(0);
    const [subscriptionDetails, setSubscriptionDetails] = useState<any>({});
    const [invoices, setInvoices] = useState([]);

    const navigate = useNavigate()
    const currentUser = useContext(RoleContext);
    const hospitalContext: any = useContext(HospitalContext);
    const chartRef = useRef<HTMLCanvasElement | null>(null);
    const chartInstance = useRef<Chart | null>(null);
    const appointmentsChartRef = useRef<HTMLCanvasElement | null>(null);
    const appointmentsChartInstance = useRef<Chart | null>(null);

    useEffect(() => {
        // getAllDoctors();
        // getAllPatients();
        // fetchChatUsers();
        getBookingData();
        fetchHospitalInvoices();
        // getAllOrders();
        // getPatientPercentage();
        fetchHospitals();
        getAllPatients();
        fetchSubscriptionDetails();
        document.title = `${constants.metaTitle}${capitalizeFirstLetter(currentUser)} - Dashboard`;
        if (hospitalContext?.selectedHospital?.id == -1 && currentUser === "admin") {
            navigate(`/${currentUser}/Dashboard`)
        }

        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
                chartInstance.current = null;
            }
        };
    }, [currentUser]);

    async function fetchHospitalInvoices() {
        const response = await getHospitalInvoices(1);
        if (response?.success) {
            setInvoices(response?.data?.rows?.slice(0,5))
        }
    }

    async function getAllPatients() {
        const response = await getClusters("5");
        if (response?.success) {
            setPatientCount(response?.data?.count)
        }
    }

    async function fetchHospitals() {
        const response = await getHospitals();
        if (response?.success) {
            setHospitals(response?.data);
        }
    }

    async function fetchSubscriptionDetails() {
        const response = await getSubscriptionDetails();
        if (response?.success) {
            setSubscriptionDetails(response?.data)
            console.log(response?.data);
            const options = {
                responsive: true,
                plugins: {
                    tooltip: {
                        backgroundColor: 'rgba(75, 192, 192, 0.8)',
                        borderWidth: 1,
                        titleColor: 'rgb(255, 255, 255)',
                        bodyColor: 'rgb(255, 255, 255)'
                    },
                },
            }
            if (chartInstance.current) {
                chartInstance.current.destroy();
                chartInstance.current = null;
            }
            if (chartRef.current) {
                const myChartRef = chartRef.current?.getContext("2d");

                if (myChartRef) {
                    const gradient = myChartRef?.createLinearGradient(0, 0, 0, myChartRef.canvas.height);
                    gradient?.addColorStop(0, '#a8edf3');
                    gradient?.addColorStop(1, '#00929f');

                    const backgroundGradient = myChartRef?.createLinearGradient(0, 0, 0, myChartRef.canvas.height);
                    backgroundGradient?.addColorStop(0, '#a8edf3');
                    backgroundGradient?.addColorStop(1, '#ffffff');

                    chartInstance.current = new Chart(myChartRef, {
                        type: "line",
                        data: {
                            labels: response?.data?.subscriptionChart?.subMonth,
                            datasets: [
                                {
                                    label: "Subscriptions",
                                    data: response?.data?.subscriptionChart?.subAmount,
                                    fill: true,
                                    backgroundColor: backgroundGradient,
                                    borderColor: gradient,
                                    borderWidth: 2,
                                    tension: 0.3,
                                },
                            ],
                        },
                        options: options
                    });
                }
            }

            if (appointmentsChartInstance.current) {
                appointmentsChartInstance.current.destroy();
                appointmentsChartInstance.current = null;
            }
            if (appointmentsChartRef.current) {
                const myChartRef = appointmentsChartRef.current?.getContext("2d");

                if (myChartRef) {
                    const gradient = myChartRef?.createLinearGradient(0, 0, 0, myChartRef.canvas.height);
                    gradient?.addColorStop(0, '#a8edf3');
                    gradient?.addColorStop(1, '#00929f');

                    const backgroundGradient = myChartRef?.createLinearGradient(0, 0, 0, myChartRef.canvas.height);
                    backgroundGradient?.addColorStop(0, '#a8edf3');
                    backgroundGradient?.addColorStop(1, '#ffffff');

                    appointmentsChartInstance.current = new Chart(myChartRef, {
                        type: "line",
                        data: {
                            labels: response?.data?.subscriptionChart?.subMonth,
                            datasets: [
                                {
                                    label: "Appointments",
                                    data: response?.data?.appointments,
                                    fill: true,
                                    backgroundColor: backgroundGradient,
                                    borderColor: gradient,
                                    borderWidth: 2,
                                    tension: 0.3,
                                },
                            ],
                        },
                        options: options
                    });
                }
            }
        }
    }



    async function getPatientPercentage() {
        let hospitalId = currentUser === "hospital" ? getCurrentUser()?.id : hospitalContext.selectedHospital?.id;
        const response = await getIncreasedPatientPercentage(hospitalId);
        if (response?.success) {
            setPatientsInfo(response?.data);
        }
    }

    async function getAllOrders() {
        const response = await getorders("undefined", "undefined", hospitalContext?.selectedHospital?.id);
        if (response?.success) {
            setPaymentDetails(response?.paymentDetails)
        }
    }

    const getBookingData = () => {
        getAllBookingAppointment().then(
            (response) => {
                if (response?.success) {
                    const bookingData = response?.appointmentBooking?.count;
                    setAllBooking(bookingData);
                }
            },
            (error) => {
                console.log("error", error)
            }
        );
    }

    async function getAllDoctors() {
        getallDoctor(1, hospitalContext?.selectedHospital?.id).then((res) => {
            if (res?.success) {
                setDoctors(res?.result?.slice(0, 4));
            }
        })
            .catch((e) => console.log("error", e))
    }

    async function fetchChatUsers() {
        const response = await getChatUsers();
        if (response?.success) {
            setChatUsers(response?.data?.slice(0, 3));
        }
    }



    // const getAllPatients = () => {
    //     getallpatient(1, hospitalContext?.selectedHospital?.id).then(
    //         async (response) => {
    //             if (response?.success) {
    //                 let patientData = response?.result?.slice(0, 5);
    //                 const arr: any = []
    //                 var check: any;
    //                 check = await Promise.all(
    //                     patientData?.map(async (patient: any) => {
    //                         const obj: any = {
    //                             ...patient
    //                         }
    //                         patient?.userdata?.forEach((item: any) => {
    //                             if (item?.fieldKey === "gender") {
    //                                 obj.gender = JSON.parse(item?.fieldValue);
    //                             }
    //                         });
    //                         const lastAppointmentOfUser = await getLastAppointmentOfUser(patient?.id);
    //                         if (lastAppointmentOfUser?.data == null) {
    //                             obj.assignedDoctor = "-";
    //                         } else {
    //                             obj.assignedDoctor = lastAppointmentOfUser?.data?.doctorName;
    //                         }
    //                         arr.push(obj);
    //                         return check
    //                     })
    //                 );
    //                 setPatients(arr);
    //                 setAllPatients(arr)
    //             }
    //         },
    //         (error) => {
    //             console.log("error", error)
    //         }
    //     );
    // }

    const handleSearch = (e: any) => {
        const { name, type, value } = e.target;
        setSearch(value);
        if (value === "") {
            setPatients(allPatients)
        } else {
            const values = allPatients?.filter((items: any, i: number) => Object.values(items)?.join(" ")?.toLowerCase()?.match(value?.toLowerCase()));
            setPatients(values)
        }
    }

    async function createChatUsers(id: number) {
        const response = await createChatUser(id);
        navigate(`/${currentUser}/messages`, { state: { chatRoomId: response.data.room } });
    }

    return (
        <div className="middlepart_top">
            <div className="row my-2">
                <div className="col-12 col-md-6 col-xxl-3 px-2 py-2">
                    <div className="billing_sec">
                        <div className="billing_sec_top">
                            <span><img src={bill_image} alt="logo-image" /></span>
                            <p>Subscriptions</p>
                        </div>
                        <strong>$ {subscriptionDetails?.totalSubscriptionAmount?.toFixed(2)}
                        </strong>
                        <p>Payment Received Today : <span className='fw-bolder'>$ {subscriptionDetails?.todaySubscriptionAmount?.toFixed(2)}</span></p>
                        <a
                            className='clickable'
                            onClick={() => navigate(`/${currentUser}/view-invoices`)}
                        >
                            See Details <IoIosArrowForward />
                        </a>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-xxl-3 px-2 py-2">
                    <div className="billing_sec">
                        <div className="billing_sec_top">
                            <span className='fs-5 textgreen'><i className="bi bi-hospital"></i></span>
                            <p>Total Hospitals</p>
                        </div>
                        <strong className='strong-text'>
                            {hospitals?.length}
                        </strong>
                        <p></p>
                        <a
                            className='clickable'
                            onClick={() => navigate(`/${currentUser}/hospital`)}
                        >
                            See Details <IoIosArrowForward />
                        </a>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-xxl-3 px-2 py-2">
                    <div className="billing_sec">
                        <div className="billing_sec_top">
                            <span><img src={Appointment} alt="logo-image" /></span>
                            <p>Total Appointments</p>
                        </div>
                        <strong className='strong-text'>
                            {allBooking}
                        </strong>
                        <p></p>
                        <a
                            className='clickable'
                            onClick={() => navigate(`/${currentUser}/appointment`)}
                        >
                            See Details <IoIosArrowForward />
                        </a>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-xxl-3 px-2 py-2">
                    <div className="billing_sec">
                        <div className="billing_sec_top">
                            <span><img src={total_image} alt="logo-image" /></span>
                            <p>Total Patients</p>
                        </div>
                        <strong className='strong-text'>
                            {patientCount}
                        </strong>
                        <p></p>
                        <a
                            className='clickable'
                            onClick={() => navigate(`/${currentUser}/patient-list`)}
                        >
                            See Details <IoIosArrowForward />
                        </a>
                    </div>
                </div>
                {/* <div className="col-12 col-md-6 col-xxl-3 px-2 py-2">
                    <div className="billing_sec">
                        <div className="billing_sec_top">
                            <span className='fs-5 textgreen'><i className="bi bi-hospital"></i></span>
                            <p>Total Hospitals</p>
                        </div>
                        <strong>
                            {hospitals?.length}
                        </strong>
                        <a
                            className='clickable'
                            onClick={() => navigate(`/${currentUser}/hospital`)}
                        >
                            See Details <IoIosArrowForward />
                        </a>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-xxl-3 px-2 py-2">
                    <div className="billing_sec">
                        <div className="billing_sec_top">
                            <span><img src={Appointment} alt="logo-image" /></span>
                            <p>Total Appointments</p>
                        </div>
                        <strong>
                            {allBooking?.length}
                        </strong>
                        <a
                            className='clickable'
                            onClick={() => navigate(`/${currentUser}/appointment`)}
                        >
                            See Details <IoIosArrowForward />
                        </a>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-xxl-3 px-2 py-2">
                    <div className="billing_sec">
                        <div className="billing_sec_top">
                            <span><img src={total_image} alt="logo-image" /></span>
                            <p>Total Patients</p>
                        </div>
                        <strong>
                            {patientCount}
                        </strong>
                        <a
                            className='clickable'
                            onClick={() => navigate(`/${currentUser}/patient-list`)}
                        >
                            See Details <IoIosArrowForward />
                        </a>
                    </div>
                </div> */}

            </div>
            <div className="row my-2 justify-content-between px-2">
                <div className="col-12 col-xxl-6 dashpatient_wrap px-2 py-2">
                    <div className='bg-white rounded'>
                        <div className="m-0 border-bottom d-flex gap-3 align-items-center p-4 py-3">
                            <span className="poppins-600 text_18">Subscriptions</span>
                        </div>
                        <div className="p-3 staff_graph">
                            <canvas ref={chartRef} />
                        </div>
                    </div>
                </div>
                <div className=" col-12 col-xxl-6 dashdoctor_wrap px-2 py-2 mt-4 mt-xxl-0">
                    <div className='bg-white rounded'>
                        <div className="m-0 border-bottom d-flex gap-3 align-items-center p-4 py-3">
                            <span className="poppins-600 text_18">Appointments</span>
                        </div>
                        <div className="p-3 staff_graph">
                            <canvas ref={appointmentsChartRef} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row my-2 justify-content-between px-2">
                <div className="col-12 col-xxl-6 px-2 py-2">
                    <div className="patientsname_sec">
                        <div className="col-sm-12 patientsname_top row m-0 mb-2">
                            <div className="pb-2 patientsname_top_left d-flex p-0 justify-content-between align-items-center">
                                <h2>Recent Invoices</h2>
                                <a className='clickable' onClick={() => navigate(`/${currentUser}/view-invoices`)}>View All</a>
                            </div>
                        </div>
                        {invoices.length > 0 ?
                            <div className="col-sm-12 patientstable_sec table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">S.No.</th>
                                            <th scope="col">Hospital Name</th>
                                            <th scope="col">Subscription Amount</th>
                                            <th scope="col">Invoice Status</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-group-divider">
                                        {invoices?.map((item: any, index: number) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.hospitalName}</td>
                                                    <td>{item.grandTotal}</td>
                                                    <td>
                                                        <span
                                                            className="po_completed p-2 py-1 rounded-5 text_sm"
                                                            style={{ backgroundColor: item.invoiceStatus == 0 || item.invoiceStatus == 11 ? "orange" : item.invoiceStatus == 1 ? "green" : "red", color: 'white' }}
                                                        >
                                                            {item.invoiceStatus == 0 || item.invoiceStatus == 11 ? "Pending" : item.invoiceStatus == 1 ? "Completed" : "Failed"}
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                                No data found.
                            </div>
                        }
                    </div>
                </div>
                <div className=" col-12 col-xxl-6 dashdoctor_wrap px-2 py-2 mt-4 mt-xxl-0">
                    {/* <div className='bg-white rounded'>
                        <div className="m-0 border-bottom d-flex gap-3 align-items-center p-4 py-3">
                            <span className="poppins-600 text_18">Appointments</span>
                        </div>
                        <div className="p-3 staff_graph">
                            <canvas ref={appointmentsChartRef} />
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default AdminDashboard;