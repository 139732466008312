import { useAudioRecorder } from 'react-audio-voice-recorder';
import { useEffect, useState } from 'react';
import CustomAudioPlayer from '../../../components/AudioPlayer';
import { testFile } from '../../../services/user.service';

export default function RecordCheck(props: any) {
  const {
    startRecording,
    stopRecording,
    togglePauseResume,
    recordingBlob,
    isRecording,
    isPaused,
    recordingTime,
    mediaRecorder
  } = useAudioRecorder();
  const [audioSource, setAudioSource] = useState<any>();
  const [playAudio, setPlayAudio] = useState(false);

  function getTime(time: number) {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const hours = Math.floor(time / 3600);
    time = time - hours * 3600;
    const finalTime = str_pad_left(minutes, '0', 2) + ':' + str_pad_left(seconds, '0', 2);
    return finalTime
  }

  function str_pad_left(string: number, pad: string, length: number) {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  }

  // const audio = new Audio(audioSource);

  useEffect(() => {
    if (!recordingBlob) return;
    const url = URL.createObjectURL(recordingBlob);
    setAudioSource(url);
    handleFormData(recordingBlob)
  }, [recordingBlob]);


  function handleFormData(recordingBlob: any) {
    const formData: any = new FormData();
    formData.append('audio_data', recordingBlob);
    for (var key of formData.entries()) {
      console.log(key[0] + ', ' + key[1])
    }
  }

  // useEffect(() => {
  //   audio.addEventListener('ended', () => setPlayAudio(false));

  //   return () => {
  //     audio.removeEventListener('ended', () => setPlayAudio(false));
  //   }
  // }, []);

  // useEffect(() => {
  //   playAudio ? audio.play() : audio.pause()
  // }, [playAudio])

  // const togglePlay = () => {
  //   setPlayAudio(!playAudio);
  // }
  const handleFileUpload = (post: any) => {
    let files = [];
    if (post.target.files && post.target.files.length > 0) {
      for (let i = 0; i < post.target.files.length; i++) {
        let file = post.target.files[i];
        let type = "file";
        if (file.type.split("/")[0] === "image") {
          type = "image";
        }
        const fileName = file.name;
        let reader = new FileReader();
        const fileExt =
          fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length) ||
          fileName;
        if (file) {
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            const fileObj = {
              fileSource: JSON.stringify(file),
              source: reader.result,
              fileType: file.type,
              fileName: file.name,
              size: file.size,
              ext: fileExt,
              message: null,
              pos: 0,
              messageType: type,
              readStatus: 0,
              chatId: Date.now(),
            };
            console.log("file sou", file)
            let fileObjCopy = { ...fileObj };
            fileObjCopy.source = "";
            handleTest(fileObjCopy);
          };
        }
      }
    }
  };

  async function handleTest(fileObj: any) {
    const response = await testFile(fileObj);
    console.log("test er", response)
  }

  return (
    <div className='other_icons'>
      {!isRecording ?
        <>
        < button
          className="border-0 fs-4 textgreen bg-white"
          type="button"
          onClick={() => {
            startRecording();
            props.handleRecording(true)
          }}
        >
          <i className="bi bi-mic-fill"></i>
        </button>
          <input type="file" onChange={handleFileUpload} />
        </>
        :
        <div className='d-flex'>
          <button
            type="button"
            className="border-0 fs-4 textgreen bg-white"
            onClick={() => {
              stopRecording();
            }}
          >
            <i className="bi bi-trash"></i>
          </button>
          <div className='px-2'>
            <p className="text_xs"><span className="red_dot d-inline-block bg-danger p-1 border-0 rounded-circle"></span> Recording</p>
            <p className="text_xs">{getTime(recordingTime)}</p>
          </div>
          <div>
            {isPaused ?
              <button
                className="border-0 textgreen fs-4 bg-white"
                type="button"
                onClick={() => {
                  togglePauseResume()
                }}
              >
                <i className="bi bi-play" />
              </button>
              :
              <button
                className="border-0 textgreen fs-4 bg-white"
                type="button"
                onClick={() => {
                  togglePauseResume()
                }}
              >
                <i className="bi bi-pause" />
              </button>
            }
          </div>
          <button
            className="border-0 textgreen fs-4 bg-white"
            type="button"
            onClick={() => {
              stopRecording();
              props.handleRecording(false)
            }}
          >
            <i className="bi bi-telegram" />
          </button>
        </div>
      }
      {/* {audioSource &&
        <div>
          <CustomAudioPlayer
            source={audioSource}
          />
        </div>
      } */}
    </div >
  )
}