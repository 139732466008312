import axios from "axios";
import { END_POINT } from "../config/constants";

// const API_URL = "http://localhost:5000/api/auth/";

const API_URL = `${END_POINT}auth/`

// export const ImagePath="D:/IboostPatientHealthcare/iboosthealthcare0703/server/uploaded_images/"
// export const ImagePath="http://localhost:5000/uploaded_images/"
export const FolderPath = "https://api.iboostmd.com"
export const ImagePath = `${FolderPath}/`


export const register = (firstname: string, lastname: string, email: string, password: string, phonenumber: any, role: string) => {
  return axios.post(API_URL + "register", {
    firstname,
    lastname,
    email,
    password,


    phonenumber,
    role
  });
};


export const login = (email: string, password: string, otp: string, currentUser: string) => {
  return axios
    .post(API_URL + "login", {
      email,
      password,
      currentUser,
      otp
    })
    .then((response) => {

      if (response?.data?.accessToken) {
        localStorage.setItem("user", JSON.stringify(response?.data));
      }

      return response?.data;
    });
};

export const getoneuser = (url: string) => {
  return axios
    .get(API_URL + "getoneuser/" + url, {

    })
    .then((response) => {


      return response?.data;
    });
};

export const forgotpassword = (email: string) => {
  return axios
    .post(API_URL + "forgot-password", {
      email

    })
    .then((response) => {


      return response?.data;
    });
};

export const resetpassword = (confirmpassword: string, url: string) => {
  return axios
    .post(API_URL + "emailrecover-password", {
      confirmpassword,
      url

    })
    .then((response) => {


      return response?.data;
    });
};

export const logout = () => {
  localStorage.removeItem("user");
};

export const getCurrentUser = () => {
  const userStr = localStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);

  return null;
};

export const changepassword = (confirmpassword: string, id: number) => {
  return axios
    .put(API_URL + "changepassword/" + id, {
      confirmpassword,
    })
    .then((response) => {


      return response?.data;
    });
};

export const createUser = (fields: any) => {
  return axios.post(API_URL + "create_user", fields);
};

export const switchUser = (email: string, roleId: number, roleName: string, token: any) => {
  return axios
    .post(API_URL + "switchuser", {
      email,
      roleId,
      roleName,
      token
    })
    .then((response) => {

      if (response?.data?.accessToken) {
        localStorage.setItem("user", JSON.stringify(response?.data));
      }

      return response?.data;
    });
};

export const decideUserNavigation = () => {
  return axios
    .get(`${API_URL}decide-user-navigation`)
    .then((response) => {
      return response?.data;
    });
};

export const sendOTP = (phone: number, countryNumber: any, currentUser: string) => {
  return axios
    .post(API_URL + "send-otp", {
      phone,
      countryNumber,
      currentUser
    })
    .then((response) => {
      return response?.data;
    });
};

export const checkPassword = (currentPassword: string, id: number) => {
  return axios
    .put(`${API_URL}check-password/${id}`, {
      currentPassword
    })
    .then((response) => {
      return response?.data;
    });
};

export const sendOTPMail = (currentValue: string, newValue: string, otpFor: string) => {
  return axios
    .post(`${API_URL}send-otp-email`, {
      currentValue,
      newValue,
      otpFor
    })
    .then((response) => {
      return response?.data;
    });
};

export const changeCredentials = (otp: any, otpFor: string) => {
  return axios
    .post(`${API_URL}change-credentials`, {
      otp,
      otpFor
    })
    .then((response) => {
      return response?.data;
    });
};
